<template>
	<div>
		<!-- Import Schema -->
		<div class="form-group row" :class="{invalid: details.importSchema.$error}">
			<label for="input-schema-input" class="label-control col-md-3 required">Import Schema</label>
			<div class="col-md-5">
				<b-form-select
					id="input-schema-input"
					v-model="details.importSchema.$model"
					:options="importSchemas"
				></b-form-select>
				<validation-messages v-model="details.importSchema" name="import schema"></validation-messages>
			</div>
		</div>

		<!-- Mapping -->
		<div
			class="form-group row"
			v-show="details.importSchema.$model === 'Mapping'"
			:class="{invalid: details.mappingId.$error}"
		>
			<label for="mapping-input" class="label-control col-md-3 required">Mapping</label>
			<div class="col-md-5">
				<b-form-select id="mapping-input" v-model="details.mappingId.$model" :options="mappings"></b-form-select>
				<validation-messages v-model="details.mappingId" name="mapping"></validation-messages>
			</div>
		</div>

		<!-- Bank Account -->
		<bank-account-select v-model="details.bankAccountId" :bankAccounts="bankAccounts"></bank-account-select>

		<!-- HSM Certificate -->
		<div
			class="form-group row"
			:class="{invalid: details.certificateId.$error, warn: !selectedHSMCert.valid}"
		>
			<label for="hsm-input" class="label-control col-md-3 required">HSM Certificate</label>
			<div class="col-md-5">
				<b-form-select id="hsm-input" v-model="details.certificateId.$model" :options="hsmCertificates"></b-form-select>
				<validation-messages v-model="details.certificateId" name="HSM certificates"></validation-messages>
				<span class="validation-messages" v-if="!selectedHSMCert.valid">
					<small class="form-text small">Certificate is expired</small>
				</span>
			</div>
		</div>

		<!-- Individual Payment Limit -->
		<div class="form-group row" :class="{invalid: details.paymentLimit.$error}">
			<label for="payment-limit-input" class="col-form-label col-md-3">Individual Payment Limit (£)</label>
			<div class="col-md-3">
				<input
					id="payment-limit-input"
					type="number"
					class="form-control"
					v-model.trim="details.paymentLimit.$model"
				/>
				<!-- Validation -->
				<validation-messages v-model="details.paymentLimit" name="Individual payment limit">
					<small
						class="form-text small"
						v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
					>individual item limit can only be in whole pounds</small>
				</validation-messages>
				<small class="form-text text-muted">Set to 0 for no limit</small>
			</div>
		</div>

		<!-- Submission  Limit -->
		<div class="form-group row" :class="{invalid: details.submissionLimit.$error}">
			<label for="submission-limit" class="col-form-label col-md-3">Submission Limit (£)</label>
			<div class="col-md-3">
				<input
					id="submission-limit"
					type="number"
					class="form-control"
					v-model.trim="details.submissionLimit.$model"
				/>
				<!-- Validation -->
				<validation-messages v-model="details.submissionLimit" name="Submission payment limit">
					<small
						class="form-text small"
						v-if="details.paymentLimit.wholepounds != undefined && !details.paymentLimit.wholepounds"
					>submission item limit can only be in whole pounds</small>
				</validation-messages>
				<small class="form-text text-muted">Set to 0 for no limit</small>
			</div>
		</div>

		<!-- Segregate Group Roles -->
		<div class="form-group row">
			<label for="segregateGroupRoles" class="col-form-label col-md-3">Segregate Group Roles</label>
			<div class="col-md-3">
				<p-check
					label-id="segregateGroupRoles"
					class="p-switch p-fill"
					color="primary"
					:disabled="disabled"
					v-model="details.segregateGroupRoles.$model"
				></p-check>
			</div>
		</div>
		
    <!-- Delete Payment Data -->
    <delete-payment-data :groupDetails="details" :disabled="disabled"></delete-payment-data>
	</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import bankAccountSelect from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/BankAccountSelect'
import deletePaymentData from '@/Components/Platform/Group/EditGroupTypeDetails/EditingComponents/DeletePaymentData'

export default {
	props: ['details', 'disabled', 'status'],
	components: {
		bankAccountSelect,
		deletePaymentData
	},
	computed: {
		hasHsm () {
			return this.$store.getters.hasLicence('LicenceHSM')
		},
		selectedHSMCert () {
			var cert = this.hsmCertificates.find(c => c.value === this.details.certificateId.$model)

			if (cert == null) {
				return { valid: true }
			}

			return cert
		}
	},
	data () {
		return {
			hsmCertificates: [],
			importSchemas: [
				{ value: 'None', text: 'None' },
				{ value: 'Mapping', text: 'Mapping' }
			],
			bankAccounts: [],
			mappings: []
		}
	},
	async created () {
		if (!this.hasHsm) {
			this.details.tokenType.$model = 'SmartCard'
		}

		try {
			this.$Progress.start()
			var results = await Promise.allSettled([
				axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`, { showerror: true, errormessage: 'Failed to get bank accounts' }),
				axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get customer certificates' }),
				axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingTitles/bankline`, { showerror: true, errormessage: 'Failed to get bacs mappings' })
			])

			// Bank accounts
			this.bankAccounts = results[0].value.data.map(bankAccount => {
				return { value: bankAccount.bankAccountId, text: bankAccount.reference }
			})


			// Certificates
			this.hsmCertificates = results[1].value.data.map(certificate => {
				var validTo = moment(certificate.validTo)

				var valid = true
				if (moment().isAfter(validTo)) {
					valid = false
				}

				return { value: certificate.certificateId, text: certificate.friendlyName, valid }
			})

			if (this.status === 'Create') {
				this.hsmCertificates = this.hsmCertificates.filter(c => c.valid)
			}

			// Mappings
			this.mappings = results[2].value.data.mappings.map(mapping => {
				return { value: mapping._id, text: mapping.title }
			})
		} catch {
			this.$Progress.fail()
		} finally {
			this.$Progress.finish()
		}
	}
}

</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>
