<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Jobs
          <help-icon :docPath="'/bacsbureau/jobs/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field =='buttons'">
              <span class="sr-only">Create Submission Buttons</span>
            </span>
            <span v-else>{{props.column.label}}</span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                <span class="sr-only">Clear filters</span>
              </span>
              <span class="sr-only">Clear filters</span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
              <span class="sr-only">Refresh the data in the table</span>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i>
              <span class="sr-only">Export the contents of the table</span>
            </b-button>
            <b-button
              @click.prevent="createJob"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Create Bureau Job'"
            >
              <i class="fa fa-plus pointer dimmedIcon"></i>
              <span class="sr-only">Create Bureau Job</span>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'buttons'">
              <b-button
                @click="runJob(props.row.bureauJobId)"
                class="btn-block"
                variant="success"
                v-if="props.row.canCreateSubmission && canSubmit && props.row.bureauSubmissionId === '00000000-0000-0000-0000-000000000000'"
              >
                <i class="fa fa-play mr-2"></i>Create Submission
              </b-button>
              <b-button
                @click="GoToSubmissionLastScreen(props.row.bureauSubmissionId)"
                variant="warning"
                class="btn-block"
                v-if="props.row.canCreateSubmission && canSubmit && props.row.bureauSubmissionId !== '00000000-0000-0000-0000-000000000000'"
              >
                <i class="fa fa-play mr-2"></i>Go To Submission
              </b-button>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <b-btn id="addJobButton" type="link" variant="primary" @click="createJob">
          <i class="fa fa-plus mr-2"></i>Create Bureau Job
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Ca from 'vuejs-datepicker/dist/locale/translations/ca'
import papa from 'papaparse'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import roles from '@/Assets/Constants/roles'
import Utility from '@/Assets/Mixins/Utility'

export default {
  mixins: [tableFilterMixin, Utility],
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Job ID',
          field: 'bureauJobId',
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name'
          },
          sortable: true
        },
        {
          label: 'Group',
          field: 'groupName',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Group'
          }
        },
        {
          label: 'Group Id',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Number Of Job Details',
          field: 'numberOfBureauCustomers',
          sortable: false
        },
        {
          label: 'Last Run',
          field: 'lastRun',
          type: 'date',
          sortable: true,
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      }
    }
  },

  computed: {
    ...mapGetters(['selectedCustomer']),
    canSubmit () {
      return this.$store.getters.isInRole('SubmitBureauJobs')
    },
    systemUser () {
      return this.$store.getters.isInRole(roles.SystemUser)
    }
  },
  async mounted () {
    await this.GetBureauLicenseCheck()
  },
  created () {
    this.load()
  },
  methods: {
    createJob () {
      this.$router.push({ name: 'CreateJob', params: {} })
    },
    onRowClick (params) {
      // this.$router.push({ name: 'ManageJob', params: { id: params.row.bureauJobId } })

      // this.$store.commit('setBureauJob', params.row)
      // this.$router.push({ name: 'ManageJob', params: { id: params.row.bureauJobId } })

      // if (this.$route.meta.target === 'managejob') {
      //         this.$router.push({ name: 'ManageJob', params: { id: params.row.bureauJobId } })
      //       } else if (this.$route.meta.target === 'runjob') {
      //         this.$router.push({ name: 'JobSubmissionDetails' })
      //       }
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons') {
        this.$router.push({ name: 'ManageJob', params: { id: event.row.bureauJobId } })
      }
    },
    async runJob (jobId) {
      // swal in here
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getisbureaujobinprogress/' + jobId, { showload: true })
      if (response.data.toastType === 1 && response.data.status === 'Action') {
        var SubmissionReference = response.data.errorText

        const swalResult = await swal.fire({
          title: 'Submission In Progress',
          html: 'This bureau job is already in progress with the submission reference <strong>' + SubmissionReference + '</strong>. Would you like to cancel or go to the submission in progress?',
          icon: 'warning',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Go To Submission',
          cancelButtonText: 'Cancel'
        })
        if (swalResult.isConfirmed) {
          await this.GoToSubmissionLastScreen(response.data.id)
        }
      } else {
        this.$router.push({ name: 'AutomatedBACSDetailSelection', params: { id: jobId } })
      }
    },
    async GoToSubmissionLastScreen (BureauSubmissionId) {
      this.isTableLoading = true
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureausubmission/' + BureauSubmissionId, {})

      if (response.data.bureauSubmissionId === '00000000-0000-0000-0000-000000000000') {
        this.$toastr.e('Bureau Submission has changed state or no longer exists. Please retry.', 'Bureau Submission')
        await this.load()
      } else {
        switch (response.data.lastScreenState) {
          case 'BureauManualBacsAddInitialFiles':
            this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: BureauSubmissionId } })
            break
          case 'BureauManualFPSAddInitialFiles':
            this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: BureauSubmissionId } })
            break
          case 'BureauManualBacsImporting':
            this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauManualFPSImporting':
            this.$router.push({ name: 'ManualFPSImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauAutoBacsImporting':
            this.$router.push({ name: 'ImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauAutoFPSImporting':
            this.$router.push({ name: 'ImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauBacsViewPayments':
            this.$router.push({ name: 'PaymentDetails', params: { id: BureauSubmissionId } })
            break
          case 'BureauFPSViewPayments':
            this.$router.push({ name: 'PaymentDetailsFPS', params: { id: BureauSubmissionId } })
            break
          case 'BureauActionsPage':
            var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${BureauSubmissionId}`, { showload: true })

            if (actionUrl.data) {
              this.$router.replace(returnRouteObject(actionUrl.data))
            } else {
              this.autoReturnToHomePage = true
              this.$router.push('/')
            }
            break
        }
      }
    },

    load: _.debounce(async function () {
      try {
        this.isTableLoading = true
        var params = { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer }
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauJob/loadwithgroupuser`,
          {
            params,
            showerror: true,
            errormessage: 'Failed to load bureau jobs'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch (e) {

      } finally {
        this.isTableLoading = false
      }
    }, 500),
    async exportTable () {
      try {
        var params = { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer, forExport: true }
        params.perPage = this.totalRecords
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauJob/loadwithgroupuser`,
          {
            params,
            showerror: true,
            errormessage: 'Failed to load bureau jobs'
          })
        const fileName = 'manage-jobs.csv'
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) {
        this.$toastr.e('Could not export table')
      }
    }
  }
}

</script>
