import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
import licence from '@/Assets/Constants/licence'
import axios from 'axios'
import { vueInstance } from '@/main'

// Actions
const UserDetails = () => import(/* webpackChunkName: "platform-action-user" */ '@/Components/Platform/Actions/Types/User/UserDetails')
const GroupDetails = () => import(/* webpackChunkName: "platform-action-group" */ '@/Components/Platform/Actions/Types/Group/GroupDetails')
const BankAccountDetails = () => import(/* webpackChunkName: "platform-action-bankaccount" */ '@/Components/Platform/Actions/Types/BankAccount/BankAccountDetails')
const BureauCustomerActionDetails = () => import(/* webpackChunkName: "platform-action-bankaccount" */ '@/Components/Platform/Actions/Types/BureauCustomer/BureauCustomerActionDetails')
const AntiFraudListDetails = () => import(/* webpackChunkName: "platform-action-antifraudlist" */ '@/Components/Platform/Actions/Types/AntiFraud/AntiFraudListDetails')
const InfoAction = () => import(/* webpackChunkName: "platform-actionInfo" */ '@/Assets/Components/Actions/InfoAction')

// Customer
const CustomerEdit = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/CustomerEdit')
const CustomerEmails = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Emails')
const CustomerAudits = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Audits/CustomerAudits')
const CustomerAuditDetails = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Audits/CustomerAuditDetails')
const CustomerLicence = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Licence/EditCustomerLicence')
const ReadOnlyCustomerLicence = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Licence/ReadOnlyCustomerLicence')
const CustomerImport = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/CustomerImport')
const OnboardingUrls = () => import(/* webpackChunkName: "platform-onboarding" */ '@/Components/Platform/Customer/OnboardingUrls')
const AgentDetails = () => import(/* webpackChunkName: "platform-agent-details" */ '@/Components/Platform/Customer/Agent/AgentDetails')

// Customer Certificates
const CustomerCertificates = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Certificates/ManageCustomerCertificates')
const CustomerCertificateCreate = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Certificates/CustomerCertificateCreate')

// Customer Dashboard
const EditCustomerDashboard = () => import(/* webpackChunkName: "platform-customer" */ '@/Components/Platform/Customer/Dashboard/EditCustomerDashboard')

// Users
const UserEdit = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/UserEdit')
const ListUsers = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/ListUsers')
const ImportUsers = () => import(/* webpackChunkName: "platform-user" */ '@/Components/Platform/User/ImportUsers')

// Service Accounts
const ListServiceAccounts = () => import(/* webpackChunckName: "platform-serviceaccount" */ '@/Components/Platform/ServiceAccounts/ListServiceAccounts')
const ServiceAccountEdit = () => import(/* webpackChunckName: "platform-serviceaccount" */ '@/Components/Platform/ServiceAccounts/ServiceAccountEdit')

// Groups
const GroupEdit = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/GroupEdit')
const ListGroups = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/ListGroups')
const ImportGroups = () => import(/* webpackChunkName: "platform-group" */ '@/Components/Platform/Group/ImportGroups')

// Bank Account
const BankAccountEdit = () => import(/* webpackChunkName: "platform-bankaccount" */ '@/Components/Platform/BankAccount/BankAccountEdit')
const ListBankAccounts = () => import(/* webpackChunkName: "platform-bankaccount" */ '@/Components/Platform/BankAccount/ListBankAccounts')

// Security Policy
const UserAccountsSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/UserAccounts')
const BankAccountSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/BankAccounts')
const BureauCustomerSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/BureauCustomers')
const PaymentGroupsSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PaymentGroups')
const LoginSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/LoginPolicy')
const InactivityTimeoutPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/InactivityTimeout')
const DayTimeRestrictionsPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/DayTimeRestrictions')
const IPAddressRestrictionsPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/IPAddressRestrictions')
const PasswordComplexityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PasswordComplexity')
const PasswordExpirationPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PasswordExpiration')
const IncorrectPasswordPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/IncorrectPassword')
const PaymentApprovalPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/PaymentApproval')
const AntiFraudListSecurityPolicy = () => import(/* webpackChunkName: "platform-securitypolicy" */ '@/Components/Platform/SecurityPolicy/Pages/AntiFraudLists')

// Usb Tokens
const AssignUsbToken = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/AssignUsbToken')
const ListUsbTokens = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/ListUsbTokens')
const ListExpiringUsbTokens = () => import(/* webpackChunkName: "platform-token" */ '@/Components/Platform/UsbTokens/ListExpiringUsbTokens')

// GDPR
const GDPRSearch = () => import(/* webpackChunkName: "platform-search" */ '@/Components/Platform/GDPR/GDPRSearch')
const DeleteGroupData = () => import(/* webpackChunkName: "platform-search" */ '@/Components/Platform/GDPR/DeleteGroupData')

// System
const ManageHsm = () => import(/* webpackChunkName: "platform-hsm" */ '@/Components/Platform/System/ManageHsm/ManageHsm')
const ListHsm = () => import(/* webpackChunkName: "platform-hsm" */ '@/Components/Platform/System/ManageHsm/ListHsm')
const MaintenanceWindowsList = () => import(/* webpackChunkName: "platform-maintenancewindow" */ '@/Components/Platform/System/MaintenanceWindows/MaintenanceWindowsList')
const MaintenanceWindowEdit = () => import(/* webpackChunkName: "platform-maintenancewindow" */ '@/Components/Platform/System/MaintenanceWindows/MaintenanceWindowEdit')
const EISCDImport = () => import(/* webpackChunkName: "platform-EISCD" */ '@/Components/Platform/System/Validate/EISCDImport')
const WeightTableImport = () => import(/* webpackChunkName: "platform-WeightTable" */ '@/Components/Platform/System/Validate/WeightTableImport')
const DevOpsDashboard = () => import(/* webpackChunkName: "platform-devops" */ '@/Components/Platform/System/DevOps/Dashboard')
const CertificateReport = () => import(/* webpackChunkName: "platform-certificate-report" */ '@/Components/Platform/System/Certificates/CertificatesReport')


// Reports
const SystemReport = () => import(/* webpackChunkName: "platform-systemreport" */ '@/Components/Platform/Reports/System/SystemReport')
const UsageReport = () => import(/* webpackChunkName: "platform-usagereport" */ '@/Components/Platform/Reports/Usage/UsageReport')
const ActionReport = () => import(/* webpackChunkName: "platform-actionreport" */ '@/Components/Platform/Reports/Actions/ActionsReport')
const BillingReport = () => import(/* webpackChunkName: "platform-billingreport" */ '@/Components/Platform/Reports/Billing/BillingReport')
const ValidateReport = () => import(/* webpackChunkName: "platform-validatereport" */ '@/Components/Platform/Reports/Validate/ValidateReport')
const SsoReport = () => import(/* webpackChunkName: "platform-ssoreport" */ '@/Components/Platform/Reports/Sso/SsoReport')


// Email Functionality
const Bounces = () => import(/* webpackChunkName: "platform-emailbounces" */ '@/Components/Platform/EmailAdministration/Bounces')
const BounceReport = () => import(/* webpackChunkName: "platform-emailbounces" */ '@/Components/Platform/EmailAdministration/BounceReport')
const SetUsers = () => import(/* webpackChunkName: "platform-emailmonitoring" */ '@/Components/Platform/System/EmailMonitoring/SetUsers')
const ThresholdAddresses = () => import(/* webpackChunkName: "platform-payer-threshold" */ '@/Components/Platform/PayerThreshold/SetEmailAddresses')

// Onboarding Reporting
const OnboardingApiUsage = () => import(/* webpackChunkName: "platform-onboarding-report" */ '@/Components/Platform/Onboarding/OnboardingApiUsage')

// News Stories
const ListStories = () => import(/* webpackChunkName: "platform-manage-news" */ '@/Components/Platform/System/News/ListNewsStories')
const EditStory = () => import(/* webpackChunkName: "platform-manage-news" */ '@/Components/Platform/System/News/EditNewsStory')
const News = () => import(/* webpackChunkName: "platform-news" */ '@/Assets/Components/Dashboard/Widgets/General/News')

// Anti-fraud lists
const ListAntiFraudLists = () => import(/* webpackChunkName: "platform-anti-fraud-list" */ '@/Components/Platform/AntiFraud/ListAntiFraudLists')
const AntiFraudListEdit = () => import(/* webpackChunkName: "platform-anti-fraud-list-edit" */ '@/Components/Platform/AntiFraud/AntiFraudListEdit')

// Set Threshold Emails
const SftpRequests = () => import(/* webpackChunkName: "platform-sftp-requests" */ '@/Components/Platform/SftpAdministration/SftpRequests')
const ViewSftpRequest = () => import(/* webpackChunkName: "platform-sftp-requests" */ '@/Components/Platform/SftpAdministration/ViewRequest')

// Bureau Licensing
const BureauLicensing = () => import(/* webpackChunkName: "platform-bureau-licensing" */ '@/Components/Platform/Reports/BureauUsageReport/LicenseUsage')

// define your routes here
export default [
	// #region Customer
	{
		name: 'CustomerCreate',
		path: '/admin/customers/create',
		component: CustomerEdit,
		meta: {
			title: 'Create Customer',
			auth: roles.CreateCustomer
		},
		props: { status: 'Create' }
	},
	{
		name: 'CustomerEdit',
		path: '/admin/customers/edit/:paygateId',
		component: CustomerEdit,
		meta: {
			title: 'Edit Customer',
			auth: roles.CustomerAdministrator
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		name: 'CustomerDetails',
		path: '/admin/customers/details',
		component: CustomerEdit,
		meta: {
			title: 'Customer Details',
			auth: roles.CustomerAdministrator
		},
		props: () => ({ status: 'Edit' })
	},
	{
		name: 'AgentDetails',
		path: '/admin/customers/agent',
		component: AgentDetails,
		meta: {
			title: 'Agent Details',
			auth: roles.AgentConfigure,
			licence: [licence.agent]
		}
	},
	{
		name: 'CustomerEmail',
		path: '/admin/customers/emails',
		component: CustomerEmails,
		meta: {
			title: 'Customer Emails',
			auth: roles.CustomerAdministrator
		},
		props: () => ({ paygateId: Store.getters.selectedCustomer, status: 'Edit' })
	},
	{
		name: 'EditCustomerLicence',
		path: '/admin/customers/licence',
		component: CustomerLicence,
		meta: {
			title: 'Edit Customer Licence',
			auth: roles.SystemUser
		}
	},
	{
		name: 'ReadOnlyCustomerLicence',
		path: '/admin/customer/licence',
		component: ReadOnlyCustomerLicence,
		meta: {
			title: 'Customer Licence',
			auth: roles.CustomerAdministrator
		}
	},
	{
		name: 'CustomerCertificates',
		path: '/admin/customer/certificates',
		component: CustomerCertificates,
		meta: {
			title: 'Customer Certificates',
			auth: [roles.CustomerAdministrator, roles.SystemUser],
			licence: licence.hsm
		}
	},
	{
		name: 'CreateCustomerCertificate',
		path: '/admin/customer/certificate/create',
		component: CustomerCertificateCreate,
		meta: {
			title: 'Create Customer Certificate',
			auth: roles.SystemUser,
			licence: licence.hsm
		}
	},
	{
		name: 'CustomerAudits',
		path: '/admin/customers/auditlogs',
		component: CustomerAudits,
		meta: {
			title: 'Customer Audit Logs',
			auth: roles.ViewAuditLog
		}
	},
	{
		path: '/admin/customers/import',
		component: CustomerImport,
		meta: {
			title: 'Customer Import',
			auth: roles.ImportCustomer
		}
	},
	{
		name: 'AuditDetails',
		path: '/admin/customers/auditlog/:auditId',
		component: CustomerAuditDetails,
		meta: {
			title: 'Customer Audit Details',
			auth: roles.ViewAuditLog
		},
		props: true
	},
	{
		name: 'CustomerDashboard',
		path: '/admin/customers/dashboard',
		component: EditCustomerDashboard,
		meta: {
			title: 'Customer Dashboard',
			auth: roles.CustomerAdministrator
		}
	},
	{
		name: 'CustomerOnboarding',
		path: 'admin/customers/onboarding',
		component: OnboardingUrls,
		meta: {
			title: 'Customer Allowed Urls',
			auth: roles.CustomerAdministrator
		}
	},
	// #endregion

	// #region Users
	{
		name: 'UserCreate',
		path: '/admin/users/create',
		component: UserEdit,
		meta: {
			title: 'Create User',
			auth: [roles.ManageUsers, roles.ViewUsers]
		},
		props: { status: 'Create' }
	},
	{
		name: 'UserEdit',
		path: '/admin/users/edit/:userId',
		component: UserEdit,
		meta: {
			title: 'Edit User',
			auth: [roles.ManageUsers, roles.ViewUsers]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		name: 'UserDetails',
		path: '/admin/users/details',
		component: UserDetails,
		meta: {
			title: 'User Details'
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	{
		name: 'Users',
		path: '/admin/users/list',
		component: ListUsers,
		meta: {
			title: 'Users',
			auth: [roles.ManageUsers, roles.ViewUsers]
		}
	},
	{
		name: 'ImportUsers',
		path: '/admin/users/import',
		component: ImportUsers,
		meta: {
			title: 'Import Users',
			auth: [roles.ManageUsers]
		}
	},
	// #endregion

	// #region Service Accounts
	{
		name: 'ServiceAccounts',
		path: '/admin/serviceaccounts/list',
		component: ListServiceAccounts,
		meta: {
			title: 'Service Accounts',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		name: 'ServiceAccountCreate',
		path: '/admin/serviceaccounts/create',
		component: ServiceAccountEdit,
		meta: {
			title: 'Create Service Account',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		},
		props: (route) => ({ status: 'Create' })
	},
	{
		name: 'ServiceAccountEdit',
		path: '/admin/serviceaccounts/edit/:serviceAccountId',
		component: ServiceAccountEdit,
		meta: {
			title: 'Edit Service Account',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		name: 'ServiceAccountUserEdit',
		path: '/admin/serviceaccounts/edit/roles/:userId',
		component: UserEdit,
		meta: {
			title: 'Edit Service Account',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	// #endregion

	// #region Groups
	{
		name: 'GroupCreate',
		path: '/admin/groups/create',
		component: GroupEdit,
		meta: {
			title: 'Create Group',
			auth: [roles.AddGroups]
		},
		props: { status: 'Create' }
	},
	{
		name: 'GroupEdit',
		path: '/admin/groups/edit/:groupId',
		component: GroupEdit,
		meta: {
			title: 'Edit Group',
			auth: [roles.GroupAdministrator, roles.SystemUser]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' }),
		beforeEnter: async (to, from, next) => {
			var shouldContinue = true
			if (to.params.groupId) {
				try {
					var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/IsGroupAdmin/${to.params.groupId}`, { showerror: true, errormessage: 'Group failed to load' })
					shouldContinue = response.data
					if (!shouldContinue) {
						vueInstance.$snapbar.e('You are not a group administrator for that group')
					}
				} catch {
					shouldContinue = false
				}
			}
			if (from.matched.length === 0 && !shouldContinue) {
				next({ name: 'Dashboard' })
			} else {
				next(shouldContinue)
			}
		}
	},
	{
		name: 'GroupDetails',
		path: '/admin/groups/details',
		component: GroupDetails,
		meta: {
			title: 'Group Details'
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	{
		name: 'Groups',
		path: '/admin/groups/list',
		component: ListGroups,
		meta: {
			title: 'Groups',
			auth: [roles.AddGroups, roles.GroupAdministrator, roles.SystemUser]
		}
	},
	{
		name: 'ImportGroups',
		path: '/admin/groups/import',
		component: ImportGroups,
		meta: {
			title: 'Import Groups',
			auth: [roles.GroupAdministrator, roles.SystemUser]
		}
	},
	// #endregion

	// #region Bank Accounts
	{
		name: 'BankAccountCreate',
		path: '/admin/bankaccounts/create',
		component: BankAccountEdit,
		meta: {
			title: 'Create Bank Account',
			auth: [roles.ManageBanks]
		},
		props: { status: 'Create' }
	},
	{
		name: 'BankAccountEdit',
		path: '/admin/bankaccounts/edit/:id',
		component: BankAccountEdit,
		meta: {
			title: 'Edit Bank Account',
			auth: [roles.ViewBanks, roles.ManageBanks]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		name: 'BankAccountDetails',
		path: '/admin/bankaccounts/details',
		component: BankAccountDetails,
		meta: {
			title: 'Bank Account Details'
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	{
		name: 'BankAccounts',
		path: '/admin/bankaccounts/list',
		component: ListBankAccounts,
		meta: {
			title: 'Bank Accounts',
			auth: [roles.ViewBanks, roles.ManageBanks]
		}
	},
	// #endregion

	// #region Bureau
	{
		name: 'BureauCustomerActionDetails',
		path: '/admin/bureaucustomers/details',
		component: BureauCustomerActionDetails,
		meta: {
			title: 'Bureau Customer Details'
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	// #endregion

	// #region Security Policy
	{
		path: '/admin/securitypolicy/useraccounts',
		component: UserAccountsSecurityPolicy,
		meta: {
			title: 'Security Policy User Accounts',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/bankaccounts',
		component: BankAccountSecurityPolicy,
		meta: {
			title: 'Security Policy Bank Accounts',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/bureaucustomers',
		component: BureauCustomerSecurityPolicy,
		meta: {
			title: 'Security Policy Bureau Customer',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/paymentgroups',
		component: PaymentGroupsSecurityPolicy,
		meta: {
			title: 'Security Policy Payment Groups',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/login',
		component: LoginSecurityPolicy,
		meta: {
			title: 'Security Policy Login',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/inactivitytimeout',
		component: InactivityTimeoutPolicy,
		meta: {
			title: 'Security Policy Inactivity Timeout',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/timerestrictions',
		component: DayTimeRestrictionsPolicy,
		meta: {
			title: 'Security Policy Time Restrictions',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/ipaddressrestrictions',
		component: IPAddressRestrictionsPolicy,
		meta: {
			title: 'Security Policy IP Address Restrictions',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/passwordcomplexity',
		component: PasswordComplexityPolicy,
		meta: {
			title: 'Security Policy Password Complexity',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/passwordexpiry',
		component: PasswordExpirationPolicy,
		meta: {
			title: 'Security Policy Password Expiration',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/incorrectpassword',
		component: IncorrectPasswordPolicy,
		meta: {
			title: 'Security Policy Incorrect Password',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/paymentapproval',
		component: PaymentApprovalPolicy,
		meta: {
			title: 'Security Policy Payment Approval',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	{
		path: '/admin/securitypolicy/antifraudlists',
		component: AntiFraudListSecurityPolicy,
		meta: {
			title: 'Security Policy Anti-Fraud Lists',
			auth: [roles.CustomerAdministrator, roles.SystemUser]
		}
	},
	// #endregion

	// #region Maintenance Windows

	{
		name: 'MaintenanceWindowList',
		path: '/admin/system/maintenancewindows',
		component: MaintenanceWindowsList,
		meta: {
			title: 'Maintenance Windows',
			auth: [roles.SystemUser]
		}
	},
	{
		name: 'MaintenanceWindowCreate',
		path: '/admin/system/maintenancewindow/create',
		component: MaintenanceWindowEdit,
		meta: {
			title: 'Create Maintenance Window',
			auth: [roles.SystemUser]
		},
		props: { status: 'Create' }
	},
	{
		name: 'MaintenanceWindowEdit',
		path: '/admin/system/maintenancewindow/edit/:id',
		component: MaintenanceWindowEdit,
		meta: {
			title: 'Edit Maintenance Window',
			auth: [roles.SystemUser]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},

	// #endregion

	// #region HSM
	{
		path: '/admin/system/listhsm',
		component: ListHsm,
		meta: {
			title: "HSM's",
			auth: roles.SystemUser
		}
	},
	{
		name: 'ManageHsm',
		path: '/admin/system/managehsm',
		component: ManageHsm,
		meta: {
			title: 'Manage HSM',
			auth: roles.SystemUser
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	// #endregion

	// #region USB Tokens
	{
		name: 'ListUsbTokens',
		path: '/admin/usbtokens',
		component: ListUsbTokens,
		meta: {
			title: 'USB Tokens',
			auth: roles.CustomerAdministrator,
			licence: [licence.mfa_usb_tokens]
		}
	},
	{
		name: 'AssignUsbToken',
		path: '/admin/usbtokens/assign',
		component: AssignUsbToken,
		meta: {
			title: 'Assign USB Token',
			auth: roles.CustomerAdministrator,
			licence: [licence.mfa_usb_tokens]
		}
	},
	{
		name: 'ListExpiringUsbTokens',
		path: '/admin/usbtokens/expiring',
		component: ListExpiringUsbTokens,
		meta: {
			title: 'Expiring USB Tokens',
			auth: roles.CustomerAdministrator,
			licence: [licence.mfa_usb_tokens]
		}
	},

	// #endregion

	{
		name: 'GDPRSearch',
		path: '/admin/GDPR/Search',
		component: GDPRSearch,
		meta: {
			title: 'GDPR Search',
			auth: roles.GDPRAdministrator
		}
	},

	{
		name: 'DeleteGroupData',
		path: '/admin/GDPR/DeleteGroupData',
		component: DeleteGroupData,
		meta: {
			title: 'Delete Group Data',
			auth: roles.GDPRAdministrator
		}
	},

	{
		path: '/admin/reports/systemreport',
		component: SystemReport,
		meta: {
			title: 'System Report',
			auth: [roles.CustomerReports]
		}
	},
	{
		path: '/admin/reports/usagereport',
		component: UsageReport,
		meta: {
			title: 'Usage Report',
			auth: [roles.CustomerReports]
		}
	},
	{
		path: '/admin/reports/actionsreport',
		component: ActionReport,
		meta: {
			title: 'Actions Report',
			auth: [roles.ActionsReports]
		}
	},
	{
		name: 'BillingReportAllCustomers',
		path: '/admin/reports/billingreport',
		component: BillingReport,
		meta: {
			title: 'Billing Report',
			auth: roles.Finance
		}
	},
	{
		name: 'BillingReport',
		path: '/admin/reports/billingreport/:id',
		component: BillingReport,
		meta: {
			title: 'Billing Report',
			auth: roles.Finance
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	{
		path: '/admin/reports/validatereport',
		component: ValidateReport,
		meta: {
			title: 'Validate Report',
			auth: roles.Finance
		}
	},
	{
		path: '/admin/reports/ssoreport',
		component: SsoReport,
		meta: {
			title: 'SSO Report',
			auth: roles.SystemUser
		}
	},
	{
		path: '/admin/email/bounces',
		component: Bounces,
		meta: {
			title: 'Email Bounces',
			auth: roles.SystemUser
		}
	},
	{
		path: '/admin/email/bounces/:bounceId',
		props: true,
		component: BounceReport,
		meta: {
			title: 'Email Bounces',
			auth: roles.SystemUser
		}
	},
	{
		path: '/actions/info',
		component: InfoAction,
		props: true,
		meta: {}
	},
	{
		path: '/admin/system/emailmonitoring',
		component: SetUsers,
		props: true,
		meta: {
			title: 'Email Monitoring'
		}
	},
	{
		path: '/admin/system/onboardingapiusage',
		component: OnboardingApiUsage,
		props: true,
		meta: {
			title: 'Onboarding Api Usage'
		}
	},
	{
		path: '/admin/system/stories',
		component: ListStories,
		props: true,
		meta: {
			title: 'Manage News Stories',
			auth: roles.Marketing
		}
	},
	{
		name: 'CreateNewsStory',
		path: '/admin/system/story/Create',
		component: EditStory,
		meta: {
			title: 'Create News Story',
			auth: roles.Marketing
		},
		props: { status: 'Create' }
	},
	{
		name: 'EditNewsStory',
		path: '/admin/system/story/:id',
		component: EditStory,
		meta: {
			title: 'Edit News Story',
			auth: roles.Marketing
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		path: '/news/:id',
		component: News,
		props: true,
		meta: {
			title: 'View News Stories'
		}
	},
	// #region Anti-Fraud List
	{
		name: 'AntiFraudLists',
		path: '/admin/antifraud/list',
		component: ListAntiFraudLists,
		meta: {
			title: 'Anti-Fraud Lists',
			auth: [roles.ManageAntiFraud]
		}
	},
	{
		name: 'AntiFraudListCreate',
		path: '/admin/antifraud/create',
		component: AntiFraudListEdit,
		meta: {
			title: 'Create Anti-Fraud List',
			auth: [roles.ManageAntiFraud]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Create' })
	},
	{
		name: 'AntiFraudListEdit',
		path: '/admin/antifraud/edit/:id',
		component: AntiFraudListEdit,
		meta: {
			title: 'Edit Anti-Fraud List',
			auth: [roles.ManageAntiFraud]
		},
		props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
	},
	{
		name: 'AntiFraudListDetails',
		path: '/admin/antifraud/details',
		component: AntiFraudListDetails,
		meta: {
			title: 'Anti-Fraud List Details'
		},
		props: (route) => ({ ...route.params, ...route.query })
	},
	// endregion
	{
		name: 'PayerThresholdEmails',
		path: '/admin/payerthreshold/emails',
		component: ThresholdAddresses,
		meta: {
			title: 'Set Payer Threshold Outgoing Emails',
			auth: [roles.CustomerAdministrator]
		}
	},
	{
		path: '/admin/system/ImportEISCD',
		component: EISCDImport,
		meta: {
			title: 'EISCD Import',
			auth: roles.SystemUser
		}
	},
	{
		path: '/admin/system/ImportWeightTable',
		component: WeightTableImport,
		meta: {
			title: 'Weight Table Import',
			auth: roles.SystemUser
		}
	},
	{
		path: '/admin/system/sftprequests',
		component: SftpRequests,
		meta: {
			title: 'Sftp Requests',
			auth: roles.SystemUser
		}
	},
	{
		path: '/admin/system/sftprequests/:requestId',
		props: true,
		component: ViewSftpRequest,
		meta: {
			title: 'View Request',
			auth: roles.SystemUser
		}
	},

	{
		path: '/admin/reports/bureauusagereport',
		props: true,
		component: BureauLicensing,
		meta: {
			title: 'Bureau Usage Report',
			auth: roles.SystemUser
		}
	},
	{
		name: 'CertificateReport',
		path: '/admin/system/certificatereport',
		props: true,
		component: CertificateReport,
		meta: {
			title: 'Certificate Report',
			auth: roles.SystemUser
		}
	}

]
