<template>
    <form @submit.prevent="!isLoading && validate()">
        <div class="idb-block">
            <div class="idb-block-title">Confirmation Of Payer Validation</div>
            <div class="idb-block-content">
                <div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="nameInput" class="required">Name</label>
							<div class="input-group mb-3">
								<input id="nameInput" placeholder="Full Name" type="text" v-model="name" class="form-control" />
								<div class="input-group-append">
									<span class="input-group-text">
										<div v-if="!nameValidated && !nameValidating">
											<i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
										</div>
										<div v-if="nameValidating" class="spinner-border spinner-border-sm" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<div v-if="!nameValidating && nameValidated">
											<i v-if="nameValid" class="fa fa-check text-success"></i>
											<span v-if="nameValid" class="d-none">Valid</span>
											<i v-if="!nameValid" class="fa fa-times text-danger"></i>
											<span v-if="!nameValid" class="d-none">Invalid</span>
										</div>
									</span>
								</div>
							</div>
                            <small v-if="nameValidated" :class="textClass">{{ translatedCode }}</small>
						</div>
					</div>
				</div>
                <div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="sortCodeInput" class="required">Sort Code</label>
							<div class="input-group mb-3">
								<the-mask
                                    id="sortCodeInput"
									placeholder="Sort Code"
									:mask="['##-##-##']"
									required
									:guide="true"
									ref="sortcode"
									type="text"
									class="form-control"
									v-model="sortCode"
								/>
								<div class="input-group-append">
									<span class="input-group-text">
										<div v-if="!bankValidated && !bankValidating">
											<i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
										</div>
										<div v-if="bankValidating" class="spinner-border spinner-border-sm" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<div v-if="!bankValidating && bankValidated">
											<i v-if="bankValid" class="fa fa-check text-success"></i>
											<span v-if="bankValid" class="d-none">Valid</span>
											<i v-if="!bankValid" class="fa fa-times text-danger"></i>
											<span v-if="!bankValid" class="d-none">Invalid</span>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label class="required" for="accountNumberInput">Account Number</label>
							<div class="input-group mb-3">
								<the-mask  id="accountNumberInput"
									placeholder="Account Number"
									:mask="['########']"
									required
									ref="accountNumber"
									type="text"
									class="form-control"
									v-model="accountNumber"
								/>
								<div class="input-group-append">
									<span class="input-group-text">
										<div v-if="!bankValidated && !bankValidating">
											<i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
										</div>
										<div v-if="bankValidating" class="spinner-border spinner-border-sm" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<div v-if="!bankValidating && bankValidated">
											<i v-if="bankValid" class="fa fa-check text-success"></i>
											<span v-if="bankValid" class="d-none">Valid</span>
											<i v-if="!bankValid" class="fa fa-times text-danger"></i>
											<span v-if="!bankValid" class="d-none">Invalid</span>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div class="row">
					<div class="col-md-6">
						<div class="form-group">
                            <label for="accountTypeSelect">Account Type</label>
                            <select id="accountTypeSelect" class="form-control" v-model="accountType">
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                            </select>
						</div>
					</div>
				</div>
            </div>
            <div class="idb-block-footer">
                <button type="submit" class="btn btn-primary" :disabled="isLoading || !canSubmit">Validate</button>
                <button
                type="button"
                class="btn btn-outline-primary ml-3"
                @click="clear"
                :disabled="isLoading"
                >Clear</button>
            </div>
        </div>
      <bulkControl :upload-type="2" />
    </form>
</template>
<script>
  import { TheMask } from 'vue-the-mask'
  import axios from 'axios'
  import loading from '@/Assets/Mixins/LoadingMixin'
  import bulkControl from "@/Components/Validate/Bulk/BulkUploadControl.vue";
  export default {
    name: 'copValidation',
    mixins: [ loading ],
    components: {
      bulkControl,
        TheMask
    },
    data () {
      return {
        bankValid: false,
        bankValidated: false,
        bankValidating: false,
        nameValid: false,
        nameValidated: false,
        nameValidating: false,
        accountNumber: '',
        sortCode: '',
        name: '',
        loading: false,
        accountType: 'Personal',
        matchQuality: '',
        matchLevel: '',
        reasonCode: ''
      }
    },
    computed: {
      translatedCode () {
        let retVal = ''
        switch(this.reasonCode) {
          case 'MATC':
            retVal = 'The account name provided is a match.'
            break
          case 'ANNM':
            retVal = 'The account name provided is not the same as the name held on the Account.'
            break
          case 'MBAM':
            retVal = 'The account name provided is not the same as the name held on the Account. It\'s a close match.'
            break
          case 'BANM':
            retVal = 'This is a Business Account not a Personal one. The account name provided is not the same, it\'s a close match.'
            break
          case 'PANM':
            retVal = 'This is a Personal Account not a Business one. The account name provided is not the same, it\'s a close match.'
            break
          case 'AC01':
            retVal = 'The account details supplied do not exist. Please check details with recipient.'
            break
          case 'IVCR':
            retVal = 'The secondary reference details supplied were not found. Please check details with recipient.'
            break
          case 'ACNS':
            retVal = 'Unable to check the name as the type of account is not supported for CoP checks. This response is also received if the account expects a secondary reference but one was not included.'
            break
          case 'OPTO':
            retVal = 'Unable to check the name as the account holder has opted out of the CoP scheme.'
            break
          case 'CASS':
            retVal = 'The Account has been switched, please contact the recipient for updated Account details.'
            break
          case 'SCNS':
            retVal = 'The request has been routed to a responder who does not own the sort code. This would only normally happen due to an issue with the CoP directory, not anything in the request message. This is also the message we return if we cannot find the sort code in the directory to route the request to the right responder.'
            break
          case 'SECMISS':
            retVal = 'The account details provided require a Secondary Reference value before name can be checked. Please check with recipient.'
            break
          case 'FOURHUN':
            retVal = 'Unable to check the name, try again later.'
            break
          case 'HTTPERR':
            retVal = 'Unable to check the name, try again later.'
            break
          case 'TOOMANY':
            retVal = 'Too Many Requests to Partner Banks - Please try again later'
            break
          case 'NOROUTE':
            retVal = 'Unable to find a Participant Bank to handle this request'
            break
        }
        return retVal
      },
      canSingle (){
        return this.$store.getters.isInRole('ValidateCoP')
      },
      canBulk(){
        return this.$store.getters.isInRole('ValidateCoPBulk')
      },
      canSubmit () {
          return this.sortCode.length === 6 && this.accountNumber.length === 8 && this.name !== ''
      },
      textClass () {
        let retVal = ''
        switch (this.matchLevel) {
          case 'No Match':
            retVal = 'text-danger'
            break
          case 'Close':
            retVal = 'text-warning'
            break
          default:
            retVal = 'text-success'
            break
        }
        return retVal
      }
    },
    methods: {
      async validate () {
        this.bankValidated = false
        this.nameValidated = false
        this.bankValidating = true
        this.nameValidating = true

        let request = {
            sortCode: this.sortCode,
            accountNumber: this.accountNumber,
            accountType: this.accountType,
            customerName: this.name
        }

        let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}cop`, request)
        this.nameValid = response.data.nameMatchResult !== 'No Match'
        this.bankValid = response.data.nameMatchResult !== 'No Match'
        this.matchQuality = response.data.resultText
        this.matchLevel = response.data.nameMatchResult
        this.bankValidated = true
        this.nameValidated = true
        this.bankValidating = false
        this.nameValidating = false
        this.reasonCode = response.data.reasonCode
      },
      clear () {
          this.accountType = 'Personal'
          this.name = ''
          this.sortCode = ''
          this.accountNumber = ''
      }
    }
  }
</script>
