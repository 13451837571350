<template>
  <div id="importingBanklinePayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div v-show="!browserBackButtonClicked" class="idb-block">
          <div class="idb-block-title">
            <h3>Importing Bankline payments, please wait...</h3>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
                  <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import groupTypes from '@/Assets/Constants/groupTypes'

export default {
  data: () => ({
    submissionData: {},
    groupId: '',
    max: 100,
    progress: 0,
    counter: 0,
    banklineGroup: {},
    groupBankAccount: {},
    getTaskProgressUrl: '',
    endTaskUrl: '',
    browserBackButtonClicked: false
  }),

  methods: {
    async getBanklineSubmission () {
      var response = await axios.get(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/submission/get?submissionId=` + this.submissionData.submissionId)
      if (response.data !== null) {
        this.$router.push('/')
      }
    }
  },

  mounted () {
    this.submissionData = this.$store.getters.submissionData
    // this.getBanklineSubmission()
    this.banklineGroup = this.$store.getters.bacsGroup
    this.groupBankAccount = this.$store.getters.groupBankAccount

    // Use BACS methods to get progress.
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionData.submissionId
    this.browserBackButtonClicked = this.$store.getters.browserBackButtonClicked

    if (!this.browserBackButtonClicked) {
      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        importedFileName: this.submissionData.filename,
        groupId: this.submissionData.groupId,
        processingDateStr: this.submissionData.processingDate,
        importSchema: this.banklineGroup.groupTypeDetails.importSchema,
        mappingId: this.banklineGroup.groupTypeDetails.mappingId,
        originatorAccountName: this.groupBankAccount.reference,
        originatorAccountNumber: this.groupBankAccount.accountNumber,
        originatorSortCode: this.groupBankAccount.sortCode,
        usedFtpFileBrowser: this.submissionData.usedFtpFileBrowser
      })

      axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/startFileImport/',
        data: json
      })

      var id = setInterval(() => {
        return axios.get(this.getTaskProgressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (response.data >= 100) {
              clearInterval(id)
              return axios.get(this.endTaskUrl, {
              })
                .then(() => {
                  setTimeout(async () => {
                    await this.$store.dispatch('getImportFileResponse', this.submissionData.submissionId)
                    this.$router.push('/bankline/banklinepayments/viewImportedBanklinePayments')
                  }, 1000)
                })
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    } else {
      this.$store.dispatch('setBrowserBackButtonClicked', false)
      this.$router.push('/payments/fasterpayments/' + this.submissionData.createPage + '?changeSubmission=true')
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.progress >= 100 || !to.path.endsWith('viewImportedPayments')) {
      next()
    }
  }
}
</script>
