import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'
import roles from "@/Assets/Constants/roles";

export default [
  {
    menu_title: 'UK Bank Account',
    menu_icon: 'ti-check-box',
    path: '/validate/Validation/BankAccount',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_modcheck) && Store.getters.isInRoles(['ValidateUKBankAccount', roles.SystemUser])
    },
    title: 'UK Bank Account Validation'
  },
  {
    menu_title: 'IBAN',
    menu_icon: 'ti-check',
    path: '/validate/Validation/IBAN',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && Store.getters.isInRoles(['ValidateIBAN', roles.SystemUser])
    },
    title: 'International Bank Account Number Validation'
  },
  {
    menu_title: 'Address',
    menu_icon: 'ti-home',
    path: '/validate/valdation/address',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && false
    },
    title: 'Address Validation'
  },
  {
    menu_title: 'Person',
    menu_icon: 'ti-user',
    path: '/validate/valdation/person',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && false
    },
    title: 'Person Validation'
  },
  {
    menu_title: 'Confirmation of Payer',
    menu_icon: 'ti-credit-card',
    path: '/validate/valdation/cop',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_cop) && Store.getters.isInRoles(['ValidateCoPBulk', 'ValidateCoP', roles.SystemUser])
    },
    title: 'Confirmation of Payer Validation'
  },
  {
    menu_title: 'Phone',
    menu_icon: 'ti-mobile',
    path: '/validate/valdation/phone',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && false
    },
    title: 'Phone Validation'
  },
  {
    menu_title: 'Email',
    menu_icon: 'ti-email',
    path: '/validate/valdation/email',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban) && false
    },
    title: 'Email Validation'
  },
  {
    menu_title: 'Bulk Validation',
    menu_icon: 'ti-exchange-vertical',
    path: '/validate/valdation/bulk',
    showMethod: async () => {
      return (Store.getters.hasLicence(licence.validate_cop)) && Store.getters.isInRoles(['ValidateCoPBulk', roles.SystemUser])
    },
    title: 'Bulk Validation using an Uploaded File'
  }
]
