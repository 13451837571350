<template>
	<div>
		<mappingMetaDataPopup
			v-if="showMetaDataPopup"
			@close="showMetaDataPopup = false; closeMetaDataPopup(true)"
			@cancel="showMetaDataPopup = false; closeMetaDataPopup(false)"
		>
			<h3 slot="header">Mapping Metadata</h3>
		</mappingMetaDataPopup>
		<modal
			v-if="showModal"
			@close="showModal = false; closeCfgPopup(true)"
			@cancel="showModal = false; closeCfgPopup(false)"
		>
			<h3 slot="header">Node Configuration</h3>
		</modal>
		<fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()"></fileBrowser>
		<b-modal
			ref="modal-validation"
			scrollable
			centered
			size="huge"
			hide-footer
			@hidden="cancelValidationModal"
			title="Mapping Validation"
		>
			<div>
				<vue-good-table
					:columns="validation_columns"
					:rows="validationResponse"
					:lineNumbers="true"
					:search-options="{ enabled: true }"
					:pagination-options="{
            enabled: true,
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: true,
            rowsPerPageLabel: 'Messages per page',
          }"
					styleClass="vgt-table striped bordered table-hover"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'level'">
							<b-badge v-if="props.row.level == 'Invalid'" variant="danger">{{props.row.level}}</b-badge>
							<b-badge v-else-if="props.row.level === 'Warning'" variant="warning">{{props.row.level}}</b-badge>
							<b-badge v-else-if="props.row.level === 'Info'" variant="info">{{props.row.level}}</b-badge>
							<span v-else>{{props.row.level}}</span>
						</span>
						<span
							v-else-if="props.column.field === 'createdAt'"
						>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
						<span
							v-else-if="props.column.field === 'updatedAt'"
						>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
						<span v-else-if="props.column.field === 'buttons'">
							<b-button
								@click.prevent="viewWorkflowEvent(props)"
								variant="link"
								class="pointer"
								title="View Workflow Event"
								v-b-popover.hover.top.d500="'Drill down into this workflow event.'"
							>
								<span class="text-secondary">
									<i class="glyphicon ti-list"></i>
								</span>
							</b-button>
						</span>
						<span v-else>{{props.formattedRow[props.column.field]}}</span>
					</template>
				</vue-good-table>
			</div>
			<b-button class="mt-3" variant="primary" @click="hideValidationModal">Close</b-button>
		</b-modal>
		<!-- Sandbox Modal -->
		<b-modal ref="my-modal" scrollable centered size="huge" hide-footer :title="executionStatus">
			<div v-if="showSpinner" class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
			</div>
			<div>
				<b-tabs content-class="mt-3">
					<b-tab title="Mapped Data " active>
						<div class="sandboxtable">
							<vue-good-table
								ref="vgtmappedData"
								:columns="data_columns"
								:rows="rows"
								:pagination-options="{
                  enabled: true,
                  perPage: 10,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  rowsPerPageLabel: 'items per page',
                }"
								:lineNumbers="false"
								:search-options="{ enabled: true }"
								styleClass="vgt-table striped bordered table-hover"
							>
								<template slot="table-row" slot-scope="props">
									<span style="font-size: 11pt;">{{props.formattedRow[props.column.field]}}</span>
								</template>
								<template slot="table-column" slot-scope="props">
									<span style="font-size: 11pt;">{{props.column.label}}</span>
								</template>
							</vue-good-table>
						</div>
					</b-tab>
					<b-tab>
						<template slot="title">
							Logs
							<b-badge pill class="ml-2" variant="primary">{{sandboxData.numMessages}}</b-badge>
						</template>
						<vue-good-table
							:columns="log_columns"
							:rows="log_rows"
							:lineNumbers="true"
							:search-options="{ enabled: true }"
							:pagination-options="{
              enabled: true,
              perPage: 10,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: true,
              rowsPerPageLabel: 'Logs per page',
            }"
							styleClass="vgt-table striped bordered table-hover"
						>
							<template slot="table-row" slot-scope="props">
								<span
									v-if="props.column.field == 'datetime'"
								>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
								<span v-else>{{props.formattedRow[props.column.field]}}</span>
							</template>
						</vue-good-table>
					</b-tab>
				</b-tabs>
			</div>
			<b-button class="mt-3" variant="primary" @click="hideExecutionModal">Close</b-button>
		</b-modal>
		<div>
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<div class="idb-block" ref="blockwidth">
					<div class="idb-block-title">
						<h2>
							Mapping Designer
							<span v-if="title !== ''">:</span>
							{{title}}
							<span class="pull-right">
								<a :href="`${docUrl}/automation/paygate-mapping/mapping-designer/`" target="_blank">
									<i class="far fa-question-circle"></i>
								</a>
							</span>
							<favourite-icon></favourite-icon>
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="alert alert-warning" role="alert" v-if="ie11">
							<h4 class="alert-heading">
								<i class="fas fa-exclamation-triangle mr-2"></i>Internet Explorer 11 Detected
							</h4>
							<p>Some features of the Mapping Designer may not work correctly on very old, unmaintained browsers such as Internet Explorer 11. Please consider using a modern browser such as Microsoft Edge, Google Chrome or Mozilla Firefox.</p>
							<hr />
							<p class="mb-0">
								Follow this
								<a :href="`${docUrl}/troubleshooting/ie11/`" target="_blank">link</a> for more details.
							</p>
						</div>

						<b-navbar ref="navbarref" toggleable="md" variant="light" v-if="!hideUI  && isLicenced">
							<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
							<b-collapse is-nav id="nav_collapse">
								<b-navbar-nav>
									<b-nav-item-dropdown :disabled="isLoading" text="File">
										<b-dropdown-item href="#" @click.prevent="menuNewMapping">
											<i class="far fa-file mr-2 text-secondary"></i>New
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item href="#" @click.prevent="menuLoadMapping">
											<i class="fas fa-file-import mr-2 text-secondary"></i>Load
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuSaveMapping">
											<i class="fas fa-save mr-2 text-secondary"></i>Save
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuSaveAsMapping">
											<i class="far fa-save mr-2 text-secondary"></i>Save As
										</b-dropdown-item>
									</b-nav-item-dropdown>
									<b-nav-item-dropdown :disabled="isLoading" text="Edit">
										<b-dropdown-item href="#" @click.prevent="menuUndo">
											<span>
												<i class="fas fa-undo font-lite mr-2 text-secondary"></i>Undo
											</span>
											<span class="float-right text-sml text-muted">Ctrl+Z</span>
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuRedo">
											<span>
												<i class="fas fa-redo font-lite mr-2 text-secondary"></i>Redo
											</span>
											<span class="float-right text-muted">Ctrl+Y</span>
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item @click="doCut">
											<span>
												<i class="fas fa-cut font-lite mr-2 text-secondary"></i>Cut
											</span>
											<span class="float-right text-muted">Ctrl+X</span>
										</b-dropdown-item>
										<b-dropdown-item @click="doCopy">
											<span>
												<i class="fas fa-copy font-lite mr-2 text-secondary"></i>Copy
											</span>
											<span class="float-right text-muted">Ctrl+C</span>
										</b-dropdown-item>
										<b-dropdown-item @click="doPaste">
											<span>
												<i class="fas fa-paste font-lite mr-2 text-secondary"></i>Paste
											</span>
											<span class="float-right text-muted">Ctrl+V</span>
										</b-dropdown-item>
									</b-nav-item-dropdown>
									<b-nav-item-dropdown :disabled="isLoading" text="Mapping">
										<b-dropdown-item href="#" @click.prevent="menuMoveOrigin">
											<i class="fas fa-map-marker font-lite mr-2 text-secondary"></i>Move to Origin
										</b-dropdown-item>
										<b-dropdown-item v-if="id" href="#" @click.prevent="menuMappingDetail">
											<i class="fas fa-list font-lite mr-2 text-secondary"></i>Mapping Detail
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuMetaData">
											<i class="fas fa-database font-lite mr-2 text-secondary"></i>Mapping Metadata
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuDesignerSettings">
											<i class="fas fa-cog font-lite mr-2 text-secondary"></i>Designer Settings
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item href="#" @click.prevent="menuClearCurrentDiagram">
											<i class="fas fa-eraser font-lite mr-2 text-secondary"></i>Clear
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item href="#" @click.prevent="menuMappingAutomap">
											<i class="fas fa-link font-lite mr-2 text-secondary"></i>Auto-map
										</b-dropdown-item>
										<b-dropdown-item href="#" @click.prevent="menuMappingValidate">
											<i class="fas fa-check font-lite mr-2 text-secondary"></i>Validate Mapping
										</b-dropdown-item>
									</b-nav-item-dropdown>
									<b-nav-item-dropdown :disabled="isLoading" text="Nodes">
										<b-dropdown-item href="#" @click.prevent="menuAddImporter">Importer</b-dropdown-item>

										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-header id="header1">Operators</b-dropdown-header>
										<b-dropdown-item href="#" @click="menuStringOperator">String Operators</b-dropdown-item>
										<b-dropdown-item href="#" @click="menuDateOperator">Date/Time Operators</b-dropdown-item>
										<b-dropdown-item href="#" @click="menuNumberOperator">Number Operators</b-dropdown-item>
										<b-dropdown-item href="#" @click="menuDataOperator">Data Operators</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-header id="header1">Payment Connectors</b-dropdown-header>
										<b-dropdown-item href="#" @click="menuBacsConnector()">BACS / FPS</b-dropdown-item>
										<b-dropdown-item href="#" @click="menuBacsReports()">BACS / FPS Reports</b-dropdown-item>
										<b-dropdown-header id="header1">Common Nodes</b-dropdown-header>
										<b-dropdown-item href="#" @click="menuCommonConstant()">Constant</b-dropdown-item>
									</b-nav-item-dropdown>
									<b-nav-item class="p-6"></b-nav-item>
									<b-nav-item
										:disabled="isLoading"
										href="#"
										v-b-popover.hover.top.d500="'Undo'"
										@click.prevent="menuUndo"
									>
										<i class="fas fa-undo text-secondary"></i>
									</b-nav-item>
									<b-nav-item
										:disabled="isLoading"
										href="#"
										v-b-popover.hover.top.d500="'Redo'"
										@click.prevent="menuRedo"
									>
										<i class="fas fa-redo text-secondary"></i>
									</b-nav-item>
									<b-nav-item
										href="#"
										:disabled="isLoading"
										v-b-popover.hover.top.d500="'Validate Mapping'"
										id="validate"
										@click.prevent="menuMappingValidate"
									>
										<i
											class="fa fa-vial text-secondary"
											:class="{
                        // 'text-danger': validationlevel === 'Invalid',
                        // 'text-warning': validationlevel === 'Warning',
                        // 'text-info': validationlevel === 'Info',
                        // 'text-success': validationlevel === 'Valid'
                      }"
										></i>
									</b-nav-item>
									<b-nav-item
										:disabled="isLoading"
										href="#"
										v-b-popover.hover.top.d500="'Test the Mapping'"
										@click.prevent="menuExecuteMapping"
									>
										<i class="fas fa-play text-secondary"></i>
									</b-nav-item>
									<b-nav-item
										:disabled="isLoading"
										href="#"
										v-b-popover.hover.top.d500="'Remove Selected'"
										id="remove"
										@click="menuRemoveItem"
									>
										<i class="fas fa-times text-secondary"></i>
									</b-nav-item>
								</b-navbar-nav>
								<!-- <b-navbar-nav class="ml-auto">
                  <b-nav-form>
                    <p-check name="check" class="p-switch" color="primary" v-model="sandboxMode">Sandbox Mode</p-check>
                  </b-nav-form>
								</b-navbar-nav>-->
								<b-navbar-nav class="ml-auto">
									<b-nav-form>
										<input
											v-b-popover.hover.top.d500="'Override importer path'"
											class="form-control form-control-sm mr-2"
											placeholder="Override importer path"
											size="45"
											v-model="selectedFilePath"
											type="text"
										/>
										<button
											:disabled="isLoading"
											v-b-popover.hover.top.d500="'Open file browser'"
											@click.prevent="btnBrowseFile"
											class="btn btn-outline-secondary btn-sm"
										>...</button>
									</b-nav-form>
								</b-navbar-nav>
							</b-collapse>
						</b-navbar>
						<canvas
							v-if="!hideUI && isLicenced"
							id="image-canvas"
							ref="imagecanvas"
							:width="canvasWidth"
							:height="canvasHeight"
							style="background-color:#ffffff; display:block;"
						></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as diagram from './Engine/mappingDesigner.js'
import modal from './modal-template.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import mappingMetaDataPopup from './MappingMetaDataPopup.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
import roles from '@/Assets/Constants/roles'

export default {
	components: {
		modal,
		mappingMetaDataPopup,
		fileBrowser: fileBrowser,
		swal
	},
	mixins: [loading],
	data () {
		return {
			showSpinner: true,
			isLicenced: true,
			executionStatus: 'Executing Mapping',
			ie11: false,
			isIe: false,
			config: {},
			validationlevel: '',
			validationResponse: {},
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			darkMode: false,
			sandboxMode: true,
			hideUI: false,
			sandboxData: {
				timeTaken: 0,
				executionId: '',
				numRows: 0,
				numMessages: 0
			},
			data_columns: [],
			isVgtLoading: false,
			data_rows: [],
			canvasWidth: 200,
			canvasHeight: 1000,
			showMetaDataPopup: false,
			paygateId: '',
			showModal: false,
			id: '',
			title: '',
			currentDiagramType: 'mapping',
			isNewDiagram: true,
			selectedFilePath: '',
			magneticLinksToggle: false,
			showFileBrowser: false,
			formData: {
			},
			schema: [],
			mappingMetaData: {
				title: '',
				description: '',
				author: '',
				dateCreated: '',
				type: 'bacs',
				numExecution: 0,
				lastExecuted: '',
				tags: []
			},
			log_columns: [
				{
					label: 'Id',
					field: '_id',
					hidden: true
				},
				{
					label: 'Date',
					field: 'datetime',
					filterOptions: {
						enabled: false
					}
				},
				{
					label: 'Message',
					field: 'message',
					filterOptions: {
						enabled: false
					}
				}
			],
			log_row_data: [],
			validation_columns: [
				{
					label: 'Rule No.',
					field: 'ruleId',
					hidden: false
				},
				{
					label: 'Validation Issue',
					field: 'ruleTitle'
				},
				{
					label: 'Message',
					field: 'message'
				},
				{
					label: 'Level',
					field: 'level'
				}
			],
			savedMappingMetadata: null
		}
	},
	computed: {
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		},
		rows: function () {
			return this.data_rows
		},
		log_rows: function () {
			return this.log_row_data
		},
		selectedNode () {
			return this.$store.state.mapping.selectedNode
		},
		nodeUI () {
			return this.$store.state.mapping.selectedNode.ui.schema
		},
		showPopup () {
			return this.$store.state.mapping.showMappingNodeCfgModelPopup
		},
		canvas: function () {
			return this.$refs.imagecanvas
		},
		ctx: function () {
			return this.canvas.getContext('2d')
		},
		...mapGetters({
			getClaim: 'getClaim',
			getClaims: 'getClaims'
		}),
		username () {
			return this.getClaim('username').value
		},
		saveMapping () {
			console.log('Computed: saveMapping')
			return this.$store.state.mapping.saveMapping
		}
	},
	created: async function () {
		this.$snapbar.hide()
		await this.loadLicence()
		this.paygateId = this.$store.getters.getClaim('paygate_id').value
		this.$store.commit('setIsMappingDirty', false)
		await this.getDesignerSettings()
		// Theme
		if (this.$store.getters.getClaim('theme').value === 'dark') {
			this.darkMode = true
		} else {
			this.darkMode = false
		}
		this.isIe11()
		// let ie = this.detectIe()
		// console.log('IE: ', ie)
	},
	mounted () {
		diagram.initCanvas()
		this.id = this.$route.params.id
		if (!this.id) {
			this.isNewDiagram = true
			this.mappingMetaData.author = this.username
		} else {
			this.isNewDiagram = false
			this.loadMappingIntoCanvas(this.id)
		}
		window.addEventListener('resize', this.handleResize)
		this.handleResize()
	},
	destroyed () {
		diagram.destroyClipboard()
	},
	methods: {
		isIe11 () {
			this.ie11 = !!window.MSInputMethodContext && !!document.documentMode
			console.log('ie11: ', this.ie11)
		},
		detectIe () {
			// Detect IE and Edge
			const ua = window.navigator.userAgent

			const msie = ua.indexOf('MSIE ')
			if (msie > 0) {
				// IE 10 or older => return version number
				return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
			}

			const trident = ua.indexOf('Trident/')
			if (trident > 0) {
				// IE 11 => return version number
				const rv = ua.indexOf('rv:')
				return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
			}

			const edge = ua.indexOf('Edge/')
			if (edge > 0) {
				// Edge (IE 12+) => return version number
				return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
			}
			// other browser
			return false
		},
		isCorvidAdmin () {
			return this.$store.getters.isInRole(roles.SystemUser)
		},
		getApplicationTheme () {
			return 'Dark'
		},
		async menuTest () {
			console.log('TEST')
			let res
			try {
				res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}test`, {})
			} catch (e) {
				console.log(e.response.data)
			}
			console.log(res)
		},
		async menuExecuteMapping () {
			this.$snapbar.hide()
			if (!this.id) {
				await this.$snapbar.w('Mappings must be saved before they can be executed.  Save the mapping and try again.')
				return
			} else {
				// Autosave the mapping before executing
				await this.menuSaveMapping()
			}

			// Validate the mapping
			console.log('Validate the mapping')
			const validateResponse = await this.menuMappingValidate(false)
			console.log(validateResponse)
			if (!validateResponse.valid) {
				// It failed validation and so we're not going to execute the mapping
				return
			}

			console.log('Clear any previous results')
			// Clear any previous results
			this.data_columns = []
			this.data_rows = []
			this.log_row_data = []
			this.sandboxData = {
				timeTaken: 0,
				executionId: '',
				numRows: 0,
				numMessages: 0
			}

			// Execute the mapping in sandbox mode.  Show the results in a modal.
			this.$refs['my-modal'].show()
			console.log('mappingRequest')
			const mappingRequest = {
				mappingId: this.id,
				sandbox: true
			}
			console.log(mappingRequest)

			if (this.overridePath) {
				mappingRequest.overridePath = this.overridePath
			}

			// If use has added an override path in the UI
			console.log(this.selectedFilePath)
			console.log('this.selectedFilePath')
			if (this.selectedFilePath) {
				mappingRequest.overridePath = this.selectedFilePath
				console.log('overridePath set: ' + mappingRequest.overridePath)
			}

			console.log('mappingRequest')
			console.log(mappingRequest)

			// 1 Call the mapping (sync for the moment)
			let mappingResponse
			console.log(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/execute`)
			try {
				mappingResponse = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/execute`, mappingRequest, { showload: true })
				this.showSpinner = false
			} catch (e) {
				if (e.response && e.response.data && e.response.data.result && e.response.data.result.messages) {
					this.$snapbar.e('Error running mapping.  The mapping was not successful')

					await this.getLogData(e.response.data.result.executionId)

					this.sandboxData = {
						timeTaken: e.response.data.result.metadata.timeTaken,
						executionId: e.response.data.result.executionId
					}
					this.sandboxData.numMessages = this.log_row_data.length
				} else if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to execute mappings.')
				} else {
					this.$snapbar.e(`Error running mapping.  The mapping did not successfully complete. ${e.message}`)
				}
				this.showSpinner = false
				return
			}

			// console.log('mappingResponse')
			// console.log(mappingResponse)
			if (mappingResponse) {
				if (mappingResponse.data) {
					if (mappingResponse.data.result.success) {
						// Mapped Data Tab

						switch (mappingResponse.data.result.metadata.destinationType) {
							case 'BACSBURCUSTDEST':
								this.data_columns = [
									{
										label: 'Compan Name',
										field: 'companyname'
									},
									{
										label: 'Contact First Name',
										field: 'contactfirstname'
									},
									{
										label: 'Contact Surname',
										field: 'contactsurname'
									},
									{
										label: 'Address 1',
										field: 'addressline1'
									},
									{
										label: 'Address 2',
										field: 'addressline2'
									},
									{
										label: 'Address 3',
										field: 'addressline3'
									},
									{
										label: 'Address 4',
										field: 'addressline4'
									},
									{
										label: 'Address 5',
										field: 'addressline5'
									},
									{
										label: 'Postcode',
										field: 'addresspostcode'
									},
									{
										label: 'Tel 1',
										field: 'telephone'
									},
									{
										label: 'Tel 2',
										field: 'telephone2'
									},
									{
										label: 'Website URL',
										field: 'websiteurl'
									},
									{
										label: 'Email',
										field: 'emailaddress'
									},
									{
										label: 'SUN',
										field: 'serviceusernumber'
									},
									{
										label: 'Payment Limit',
										field: 'paymentlimit'
									},
									{
										label: 'Submission Limit',
										field: 'submissionlimit'
									},
									{
										label: 'Contra Narrative',
										field: 'contranarrative'
									},
									{
										label: 'Bank Reference',
										field: 'bankreference'
									},
									{
										label: 'Bank Name',
										field: 'bankname'
									},
									{
										label: 'Bank Address 1',
										field: 'bankaddress1'
									},
									{
										label: 'Bank Address 2',
										field: 'bankaddress2'
									},
									{
										label: 'Bank Address 3',
										field: 'bankaddress3'
									},
									{
										label: 'Bank Address 4',
										field: 'bankaddress4'
									},
									{
										label: 'Bank Address 5',
										field: 'bankaddress5'
									},
									{
										label: 'Bank Postcode',
										field: 'bankaddresspostcode'
									},
									{
										label: 'Sortcode',
										field: 'sortcode'
									},
									{
										label: 'Account Number',
										field: 'accountnumber'
									},
									{
										label: 'Default Import Scheme',
										field: 'defaultimportscheme'
									},
									{
										label: 'Default Import Mapping',
										field: 'defaultimportmapping'
									},
									{
										label: 'Default Filename',
										field: 'defaultfilename'
									},
									{
										label: 'Notes',
										field: 'notes'
									}
								]
								break
							case 'BACSDEST':
								this.data_columns = [
									{
										label: '3rd Party Acct Name',
										field: 'thirdpartyaccountname'
									},
									{
										label: '3rd Party Sortcode',
										field: 'thirdpartysortcode'
									},
									{
										label: '3rd Party Acct no.',
										field: 'thirdpartyaccountnumber'
									},
									{
										label: '3rd Party Acct Type',
										field: 'thirdpartyaccounttype'
									},
									{
										label: 'Orig Acct Name',
										field: 'originatoraccountname'
									},
									{
										label: 'Orig Sortcode',
										field: 'originatorsortcode'
									},
									{
										label: 'Orig Acct No.',
										field: 'originatoraccountnumber'
									},
									{
										label: 'User Reference',
										field: 'userreference'
									},
									{
										label: 'SUN',
										field: 'serviceusernumber'
									},
									{
										label: 'Numeric Ref',
										field: 'freeformat'
									},
									{
										label: 'Tx Code',
										field: 'transactioncode'
									},
									{
										label: 'Amount',
										field: 'amount'
									},
									{
										label: 'Date',
										field: 'processingdate'
									},
									{
										label: 'Record ID',
										field: 'recordid'
									},
									{
										label: 'Reserved',
										field: 'reserved'
									},
									{
										label: 'Message',
										field: 'message'
									},
									{
										label: 'File No.',
										field: 'filenumber'
									},
									{
										label: 'Field 1',
										field: 'field1'
									},
									{
										label: 'Field 2',
										field: 'field2'
									},
									{
										label: 'Field 3',
										field: 'field3'
									},
									{
										label: 'Field 4',
										field: 'field4'
									},
									{
										label: 'Field 5',
										field: 'field5'
									},
									{
										label: 'Field 6',
										field: 'field6'
									},
									{
										label: 'Field 7',
										field: 'field7'
									},
									{
										label: 'Field 8',
										field: 'field8'
									},
									{
										label: 'Field 9',
										field: 'field9'
									},
									{
										label: 'Field 10',
										field: 'field10'
									},
									{
										label: 'Field 11',
										field: 'field11'
									},
									{
										label: 'Field 12',
										field: 'field12'
									},
									{
										label: 'Field 13',
										field: 'field13'
									},
									{
										label: 'Field 14',
										field: 'field14'
									},
									{
										label: 'Field 15',
										field: 'field15'
									},
									{
										label: 'Field 16',
										field: 'field16'
									}
								]
								break
							case 'BANKLINEDEST':
								this.data_columns = [
									{
										label: '3rd Party Acct Name',
										field: 'thirdpartyaccountname'
									},
									{
										label: '3rd Party Sortcode',
										field: 'thirdpartysortcode'
									},
									{
										label: '3rd Party Acct no.',
										field: 'thirdpartyaccountnumber'
									},
									{
										label: 'Orig Acct Name',
										field: 'originatoraccountname'
									},
									{
										label: 'Orig Sortcode',
										field: 'originatorsortcode'
									},
									{
										label: 'Orig Acct No.',
										field: 'originatoraccountnumber'
									},
									{
										label: 'Amount',
										field: 'amount'
									},
									{
										label: 'User Reference',
										field: 'userreference'
									},
									{
										label: 'Date',
										field: 'paymentdate'
									},
									{
										label: 'Category Purpose Code',
										field: 'categorypurposecode'
									},
									{
										label: 'Service Level',
										field: 'servicelevel'
									}
								]
								break

						}
					} else {
						this.$snapbar.e('Error running mapping.  The mapping was not successful')
						// TODO: Show error messages
					}
					this.data_rows = mappingResponse.data.result.mappedSandboxData

					// console.log('this.data_rows')
					// console.log(this.data_rows)

					// Logs Tab
					// console.log('mappingResponse.data')
					// console.log(mappingResponse.data)
					await this.getLogData(mappingResponse.data.result.executionId)

					// Data Tab
					this.sandboxData = {
						timeTaken: mappingResponse.data.result.metadata.timeTaken,
						executionId: mappingResponse.data.result.executionId
					}
					this.sandboxData.numMessages = this.log_row_data.length
					if (mappingResponse.data.result.metadata.destinationType === 'BACSDEST') {
						this.sandboxData.destinationType = 'BACS Destination'
					}
				} else {
					this.$snapbar.e('Error running mapping.  No Response #1')
				}
			} else {
				this.$snapbar.e('Error running mapping.  No Response #2')
			}
		},
		async getLogData (executionId) {
			console.log('getLogData')
			try {
				const res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowAllMessages/${executionId}`)
				console.log(res.data)
				if (res && res.data && res.data.workflowMessage) {
					this.log_row_data = res.data.workflowMessage
				} else { }
			} catch (e) {
				this.$snapbar.e(`Error retrieving workflow log data from Paygate - ${e.message}`)
			}
		},
		hideExecutionModal () {
			this.$refs['my-modal'].hide()
			this.showModal = false
			this.showSpinner = true
		},
		btnBrowseFile () {
			this.$store.commit('setModalPopupString', this.selectedFilePath)
			this.showFileBrowser = true
		},
		closeFilePopup () {
			console.log('Closed closeFilePopup - updating parent')
			console.log('state: ' + this.$store.state.mapping.modalPopupString)
			this.selectedFilePath = this.$store.state.mapping.modalPopupString
		},
		handleResize: function () {
			this.height = window.innerHeight - this.margin
			const w = this.$refs.blockwidth.offsetWidth
			const cw = parseInt(w)
			this.canvasWidth = cw - 40
		},
		doCut: function () {
			diagram.cutClipboard()
		},
		doCopy: function () {
			diagram.copyClipboard()
		},
		doPaste: function () {
			diagram.pasteClipBoard()
		},
		menuMetaData () {
			this.savedMappingMetadata = JSON.parse(JSON.stringify(this.mappingMetaData))
			this.$store.commit('setMappingMetaData', this.mappingMetaData)
			this.showMetaDataPopup = true
		},
		menuDesignerSettings () {
			this.$router.push('/automation/config/designersettings')
		},
		closeMetaDataPopup (saveMetaData = false) {
			console.log('saveMetaData: ' + saveMetaData)
			this.mappingMetaData = this.$store.state.mapping.mappingMetaData
			if (saveMetaData) {
				if (this.id) {
					this.menuSaveMapping(false)
				}
			}
		},
		setId () {
			this.id = this.$route.params.id
		},
		addNode (name, extraValue) {
			console.log('name: ' + name)
			diagram.createNode(65, 65, name, undefined, undefined, undefined, extraValue)
		},
		addConnector (name) {
			diagram.createPaymentConnector(65, 65, name)
		},
		async menuStringOperator () {
			let extraValue
			const inputOptions = {
				capitalise: 'Capitalise',
				constant: 'Constant',
				deburr: 'Deburr', // e.g. 'déjà vu' -> 'deja vu'
				getLength: 'Get Length',
				guid: 'GUID',
				joiner: 'Joiner',
				moneyFormatter: 'Money Formatter',
				padLeft: 'Pad Left',
				padRight: 'Pad Right',
				replace: 'Replace',
				substitute: 'Substitute', // Strips nulls and other unsupported chars
				subString: 'Sub String',
				subStringLeft: 'Sub String Left',
				subStringRight: 'Sub String Right',
				toUpperCase: 'To Upper Case',
				toLowerCase: 'To Lower Case',
				trim: 'Trim',
				trimLeft: 'Trim Left',
				trimRight: 'Trim Right'
			}
			const result = await swal.fire({
				title: 'String Operators',
				html: 'Mapping nodes that affect or manipulate string / text data',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				console.log('selected: ' + result.value)
				if (result.value === 'joiner') {
					const res = await swal.fire({
						text: 'How many inputs?',
						input: 'number',
						inputValue: 2,
						inputAttributes: {
							min: 1,
							max: 9
						}
					})
					if (res.isConfirmed) {
						extraValue = res.value
						if (extraValue < 1 || extraValue >> 9) return
						this.addNode(result.value, extraValue)
					}
				} else {
					// console.log(result.value)
					// console.log(extraValue)
					this.addNode(result.value, extraValue)
				}
			}
		},
		async menuNumberOperator () {
			const inputOptions = {
				ceiling: 'Ceiling',
				divide: 'Divide',
				floor: 'Floor',
				maximum: 'Maximum',
				minimum: 'Minimum',
				modulus: 'Modulus',
				multiply: 'Multiply',
				sum: 'Sum',
				subtract: 'Subtract'
			}
			const result = await swal.fire({
				title: 'Number Operators',
				html: 'Mapping nodes that manipulate numeric data.',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				this.addNode(result.value)
			}
		},
		async menuDateOperator () {
			const inputOptions = {
				dateTime: 'Date / Time',
				reformatDate: 'Format Date'
			}
			const result = await swal.fire({
				title: 'Date/Time Operators',
				html: 'Mapping nodes that add date and time data to a mapping.',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				this.addNode(result.value)
			}
		},
		async menuDataOperator () {
			const inputOptions = {
				bankAccount: 'Bank Account',
				groupDetails: 'Group Details',
				bureauCustomer: 'Bureau Customer'
			}
			const result = await swal.fire({
				title: 'Data Operators',
				html: 'Mapping nodes that add dynamic data to a mapping.',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				this.addNode(result.value)
			}
		},
		async menuBacsReports () {
			const inputOptions = {
				BRADDACSDEST: 'ADDACS Report - Destination',
				BRARUDDSDEST: 'ARUDDS Report - Destination',
				BRAUDDISDEST: 'AUDDIS Report - Destination',
				BRAWACSDEST: 'AWACS Report - Destination',
				BRARUCSDEST: 'ARUCS Report - Destination',
				BRARRIVALDEST: 'Arrival Report - Destination',
				BRINPUTDEST: 'ADDACS Report - Destination',
				BRDDICDEST: 'DDIC Report - Destination',
				BRWITHDRAWALSDEST: 'Withdrawal Report - Destination'
			}
			const result = await swal.fire({
				title: 'BACS / FPS Reports',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				this.addConnector(result.value)
			}
		},
		async menuBacsConnector () {
			const inputOptions = {
				BACSDEST: 'BACS Connector - Destination',
				BACSBURCUSTDEST: 'BACS Bureau Customer - Destination',
				BANKLINEDEST: 'Bankline connector - Destination'
			}
			const result = await swal.fire({
				title: 'BACS / FPS Connectors',
				input: 'select',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				this.addConnector(result.value)
			}
		},
		async menuCommonConstant () {
			this.addNode('constant')
		},
		// loadExporterIntoCanvas (id) {
		//   axios
		//     .get(`${process.env.VUE_APP_WORKFLOW_API_URL}exporter/${id}`)
		//     .then(res => {
		//       diagram.addExporter(res.data.exporter)
		//     })
		//     .catch(e => {
		//       this.$snapbar.e(`Error contacting Paygate (MP0007ex) - ${e.message}`)
		//     })
		// },
		async menuAddExporter () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}exporters`)
				if (res && res.data && res.data.exporters) {
					// SWAL needs an object but the API returns an array, so need to transform
					let inputOptions = {}
					res.data.exporters.forEach((element) => {
						inputOptions[element._id] = element.title
					})
					const result = await swal.fire({
						title: 'Select an Exporter',
						input: 'select',
						inputOptions: inputOptions,
						showCancelButton: true,
						confirmButtonText: 'Select',
						cancelButtonText: 'Cancel'
					})
					if (result.isConfirmed) {
						this.loadExporterIntoCanvas(result.value)
					}
				}
			} catch (e) {
				this.$snapbar.e(`Error contacting Paygate (MP0008) - ${e.message}`)
			}
		},


		async menuAddImporter () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importers`)
				if (res && res.data && res.data.importers) {
					// SWAL needs an object but the API returns an array, so need to transform
					let inputOptions = {}
					res.data.importers.forEach((element) => {
						inputOptions[element._id] = element.title
					})
					const result = await swal.fire({
						title: 'Select an Importer',
						input: 'select',
						inputOptions: inputOptions,
						showCancelButton: true,
						confirmButtonText: 'Select',
						cancelButtonText: 'Cancel'
					})
					if (result.isConfirmed) {
						this.loadImporterIntoCanvas(result.value)
					}
				}
			} catch (e) {
				this.$snapbar.e(`Error contacting Paygate (MP0009) - ${e.message}`)
			}
		},
		loadExporterIntoCanvas (id) {
			axios
				.get(`${process.env.VUE_APP_WORKFLOW_API_URL}exporter/${id}`)
				.then(res => {
					diagram.addExporter(res.data.exporter)
				})
				.catch(e => {
					this.$snapbar.e(`Error contacting Paygate (MP0010) - ${e.message}`)
				})
		},
		loadImporterIntoCanvas (id) {
			axios
				.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${id}`)
				.then(res => {
					diagram.addImporter(res.data.importer)
				})
				.catch(e => {
					this.$snapbar.e(`Error contacting Paygate (MP0007) - ${e.message}`)
				})
		},
		async menuLoadMapping () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingtitles`)
				if (res && res.data && res.data.mappings) {
					// SWAL needs an object but the API returns an array, so need to transform
					var inputOptions = {}
					res.data.mappings.forEach((element) => {
						inputOptions[element._id] = element.title
					})
					const result = await swal.fire({
						title: 'Select a Mapping',
						input: 'select',
						inputOptions: inputOptions,
						showCancelButton: true,
						allowOutsideClick: false,
						confirmButtonText: 'Select',
						cancelButtonText: 'Cancel'
					})
					if (result.isConfirmed) {
						this.$router.push({ path: `/automation/mapping/designer/${result.value}` })
					}
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to load mappings.')
				} else {
					this.$snapbar.e(`Error contacting Paygate (MP0001) - ${e.message}`)
				}
			}
		},
		async loadMappingIntoCanvas (id) {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${id}`)
				if (res && res.data && res.data.mapping && res.data.mapping.data) {
					diagram.loadMapping(res.data.mapping.data)
					this.id = res.data.mapping._id
					this.title = res.data.mapping.title
					this.selectedFilePath = res.data.mapping.sandboxPath
					this.mappingMetaData = res.data.mapping.metaData
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view this mapping.')
					this.hideUI = true
				} else {
					this.$snapbar.e(`Could not display mapping - ${e.message}`)
				}
			}
		},
		async menuNewMapping () {
			console.log('Menu New Mapping')
			if (this.$store.state.mapping.isMappingDirty === true) {
				const result = await swal.fire({
					title: 'Unsaved changes!',
					text: 'Creating a new mapping will loose any changes to the current mapping.  Are you sure you want to proceed?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes!',
					cancelButtonText: 'No, keep current'
				})
				if (result.isConfirmed) {
					this.newMapping(true)
				}
			} else if (!!this.id) {
				this.newMapping(true)
			} else {
				this.newMapping()
			}
		},
		newMapping (navigate) {
			diagram.clearCanvas()
			this.id = ''
			this.isNewDiagram = true
			this.$store.commit('setIsMappingDirty', false)

			if (navigate) {
				this.$router.push('/automation/mapping/designer')
			}


		},
		async menuSaveMapping (showToast = true) {
			let postData = {}

			if (this.id) {
				// Existing Mapping
				postData = {
					id: this.id,
					paygateId: this.paygateId,
					title: this.title,
					sandboxPath: this.selectedFilePath,
					description: 'My Mapping',
					data: diagram.buildMappingModel(),
					metaData: this.mappingMetaData
				}
				let res
				try {
					res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.id}`, postData, { showload: true })
					if (res) {
						this.title = this.mappingMetaData.title
						if (showToast) {
							this.$toastr.s(`Mapping '${this.title}' successfully saved.`)
						}
					}
					this.$store.commit('setIsMappingDirty', false)
				} catch (e) {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to save Mappings.')
					} else if (e.response && e.response.status === 500) {
						this.$snapbar.e(e.response.data.errorMessage)
						console.log(e.response)
					} else if (e.response && e.response.status === 409) {
						this.$snapbar.e(e.response.data.errorMessage, { html: true })
					} else {
						this.$snapbar.e(`Error saving the Mapping - ${e.message}`)
					}

					if (this.savedMappingMetadata != null) {
						this.mappingMetaData = JSON.parse(JSON.stringify(this.savedMappingMetadata))
					}
				}
			} else {
				// New Mapping
				await this.menuSaveAsMapping()
			}
		},
		async getDesignerSettings () {
			// this.$snapbar.hide()
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}designersettings`, { showload: true })
				console.log(res)
				if (res && res.data) {
					this.config = res.data
				} else {
					this.$snapbar.e('Error retrieving Designer Settings')
				}
			} catch (e) {
				this.$snapbar.e(`Error retrieving Designer Settings - ${e.message}`)
				console.log(e)
			}
		},
		async menuSaveAsMapping (showToast = true) {
			const result = await swal.fire({
				title: 'Mapping Title',
				input: 'text',
				inputValue: this.mappingMetaData.title,
				allowOutsideClick: false,
				showCloseButton: true
			})
			if (result.isConfirmed) {
				if (result.value) {
					if (result.value.length > 64) {
						result.value = result.value.substring(0, 64)
						// this.mappingMetaData.title = title
					}
					// this.mappingMetaData.author = this.username

					this.mappingMetaData.title = result.value
					const postData = {
						paygateId: this.paygateId,
						title: result.value,
						sandboxPath: this.selectedFilePath,
						data: diagram.buildMappingModel(),
						metaData: this.mappingMetaData
					}
					let res
					try {
						res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping`, postData, { showload: true })
						if (res) {

							if (showToast) {
								this.$toastr.s(
									`Mapping '${result.value}' successfully saved.`
								)
							}
							this.$store.commit('setIsMappingDirty', false)
							this.title = result.value
							if (this.$route.params.id) {
								// The current url already ncludes an id - replace it with the new one
								const newRoute = this.$route.path.replace(this.$route.params.id, res.data._id)
								this.$router.replace(newRoute)
							} else {
								this.$router.replace(`${this.$route.path}/${res.data._id}`)
							}
						}
					} catch (e) {
						this.mappingMetaData.title = ''
						if (e.response && e.response.data && e.response.data.errorMessage) {
							this.$snapbar.e(e.response.data.errorMessage)
						} else {
							this.$snapbar.e('Error saving mapping.  The mapping was not saved.')
						}
					}
				}
			}
		},
		menuClearCurrentDiagram () {
			diagram.clearCanvas()
		},
		menuMoveOrigin () {
			diagram.moveToOrigin()
		},
		menuMappingDetail () {
			console.log(this.id)
			this.$router.push(`/automation/mapping/mappingdetail/${this.id}`)
		},
		menuMappingAutomap () {
			diagram.automap()
		},
		async menuMappingValidate (showPopup = false) {
			this.$snapbar.hide()
			const validateResponse = await this.validateMapping()
			if (validateResponse.valid === false) {
				// Only show the validation results popup if it failed validation.
				this.$refs['modal-validation'].show()
			}
			// Always show the validation result snapbar
			if (validateResponse.result === 'Valid' && showPopup) {
				this.$snapbar.s('The current mapping appears to be valid')
			} else if (validateResponse.result === 'Info') {
				this.$snapbar.i('Validation of the current mapping contains info messages.')
			} else if (validateResponse.result === 'Warning') {
				this.$snapbar.w('Validation of the current mapping contains warnings.')
			} else if (validateResponse.result === 'Invalid') {
				this.$snapbar.e('The current mapping is not valid.')
			}
			return validateResponse
		},
		menuUndo () {
			diagram.undo()
		},
		menuRedo () {
			diagram.redo()
		},
		menuRemoveItem () {
			diagram.removeSelected()
		},
		async validateMapping () {
			this.$snapbar.hide()
			const postData = diagram.buildMappingModel()
			postData.sandboxPath = this.selectedFilePath
			postData.paygateId = this.paygateId

			let res
			try {
				res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/validate`, postData)
				if (res) {
					this.validationResponse = res.data.validationResponse.validationMessages
					this.validationlevel = res.data.validationResponse.result
					return res.data.validationResponse
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to validate Mapping.')
				} else {
					this.$snapbar.e(`Error validating the Mapping - ${e.message}`)
				}
			}
		},
		hideValidationModal () {
			this.$refs['modal-validation'].hide()
		},
		reformatDate (d) {
			const myDate = new Date(d)
			let dd = myDate.getDate()
			let mm = myDate.getMonth() + 1
			const yyyy = myDate.getFullYear()
			if (dd < 10) {
				dd = '0' + dd
			}
			if (mm < 10) {
				mm = '0' + mm
			}
			let h = myDate.getHours()
			let m = myDate.getMinutes()
			let s = myDate.getSeconds()
			const ms = this.pad(myDate.getMilliseconds(), 3, '0')
			h = this.checkTime(h)
			m = this.checkTime(m)
			s = this.checkTime(s)
			const reformatted = dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s + '.' + ms
			return reformatted
		},
		checkTime (i) {
			if (i < 10) {
				i = '0' + i
			}
			return i
		},
		pad (n, width, z) {
			z = z || '0'
			n += ''
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
		},
		cancelValidationModal (evt) {
			console.log('cancelValidationModal')
		},
		closeCfgPopup (type) {
			if (type) {
				if (this.id) {
					this.menuSaveMapping(false)
				}
			}
		},
		async loadLicence () {
			let response
			try {
				response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
				if (response && response.data && response.data.automation && response.data.automation.mappingDesigner) {
					this.isLicenced = response.data.automation.mappingDesigner
				} else {
					this.isLicenced = false
				}
			} catch (e) {
				console.log(e.message)
				this.isLicenced = false
			}

			// Override if it's a corvid user
			if (this.isCorvidAdmin() === true) {
				this.isLicenced = true
			}

			if (!this.isLicenced) {
				this.$snapbar.e('Sorry, you are not licensed to use the Mapping Designer.')
			}
		}
	},
	watch: {
		'$route' (to, from) {
			this.id = to.params.id
			if (this.id) {
				this.loadMappingIntoCanvas(this.id)
			}
		},
		showPopup: function () {
			if (!this.showModal) {
				// This is used to detect when a user clicks the cog icon on a node to open the config popup.
				// Click the cog changes the state of the 'showNodeCfgModelPopup' in vuex.
				// This watcher detects the change, opens the popup and sets showNodeCfgModelPopup back to false
				this.showModal = true
				this.$store.commit('setMappingPopupState', false)
			}
		},
		saveMapping: function () {
			if (this.$store.state.mapping.saveMapping === true) {
				if (this.config.autosave) {
					this.menuSaveMapping(false)
				}
				this.$store.commit('setSaveMapping', false)
			}
		},
		corvidSelectedPaygateId: function (val) {
			// console.log('Corvid change')
		}
	}
}
</script>
<style scoped>
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.sandboxtable {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
