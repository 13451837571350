<template>
  <div>
    <div class="row mt-2" v-if="dataLoaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Billing Report Stats</h2>
            <span class></span>
          </div>
          <div class="idb-block-content">
            <div class="container-fluid">
              <div class="row mt-2">
                <div class="col text-center">
                  <h5>SMS Sent</h5>
                  <h2>
                    <i-count-up class="ml-2" :delay="1" :endVal="smsSent" :options="counterOptions"></i-count-up>
                  </h2>
                  <small>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="smsThreshold"
                      :options="limitCounterOptions"
                    ></i-count-up>
                  </small>
                </div>
                <div class="col text-center">
                  <h5>Emails Sent</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="emailSent"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                  <small>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="emailThreshold"
                      :options="limitCounterOptions"
                    ></i-count-up>
                  </small>
                </div>
                <div class="col text-center">
                  <h5>Address Lookups</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="addressLookupSent"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bacs Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bacsSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bacs Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bacsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>FPS Submissions</h5>
                  <h2>
                    <i-count-up class="ml-2" :delay="1" :endVal="fpsSubs" :options="counterOptions"></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>FPS Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="fpsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Collections Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="collectionsSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Collections Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="collectionsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bureau Unique SUNs</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauUniqueSuns"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                  <small>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauThreshold"
                      :options="limitCounterOptions"
                    ></i-count-up>
                  </small>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>Bureau Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bureau Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Workflows Executed</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="workflowsExecuted"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Agent Files Downloaded</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="agentFilesDownloaded"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Agent Files Uploaded</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="agentFilesUploaded"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>Credit Card Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="creditCardValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bank Account Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bankAccountValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                  <small>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="validateThreshold"
                      :options="limitCounterOptions"
                    ></i-count-up>
                  </small>
                </div>
                <div class="col text-center">
                  <h5>IBAN Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="ibanValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>CoP Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="copValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" v-if="dataLoaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Billing Report Table</h2>
          </div>
          <div class="idb-block-content">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              styleClass="vgt-table striped bordered"
            ></vue-good-table>
          </div>
          <div class="idb-block-footer">
            <button type="submit" class="btn btn-primary" @click="back()">
              <i class="glyphicon ti-back-left rpad"></i>Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import ICountUp from 'vue-countup-v2'
import axios from 'axios'
import _ from 'lodash'

// Constants
import UsageTypes from '@/Components/Platform/Reports/Billing/UsageType.js'
import ValidateApiCall from '@/Components/Platform/Reports/Billing/ValidateApiCall.js'

// Functions
function reduceReport (report, type) {
  return report.reduce((a, c) => {
    a += c.data.reduce((aa, cc) => {
      if (cc.type === type) {
        aa += cc.amount
      }
      return aa
    }, 0); return a
  }, 0)
}

export default {
  name: 'BillingReportSingleCustomerStats',
  props: ['report', 'dataLoaded', 'month', 'paygateId'],
  components: {
    ICountUp
  },
  computed: {
    smsSent () {
      return reduceReport(this.report, UsageTypes.SMSSent)
    },
    emailSent () {
      return reduceReport(this.report, UsageTypes.EmailSent)
    },
    addressLookupSent () {
      return reduceReport(this.report, UsageTypes.AddressLookup)
    },
    bacsSubs () {
      return reduceReport(this.report, UsageTypes.BacsSubmission)
    },
    bacsTrans () {
      return reduceReport(this.report, UsageTypes.BacsTransaction)
    },
    fpsSubs () {
      return reduceReport(this.report, UsageTypes.FPSSubmission)
    },
    fpsTrans () {
      return reduceReport(this.report, UsageTypes.FPSTransaction)
    },
    collectionsSubs () {
      return reduceReport(this.report, UsageTypes.CollectionsSubmission)
    },
    collectionsTrans () {
      return reduceReport(this.report, UsageTypes.CollectionsTransaction)
    },
    bureauUniqueSuns () {
      return reduceReport(this.report, UsageTypes.BureauUniqueSUN)
    },
    bureauSubs () {
      return reduceReport(this.report, UsageTypes.BureauSubmission)
    },
    bureauTrans () {
      return reduceReport(this.report, UsageTypes.BureauTransaction)
    },
    workflowsExecuted () {
      return reduceReport(this.report, UsageTypes.AutomationExecutedWorkflow)
    },
    agentFilesUploaded () {
      return reduceReport(this.report, UsageTypes.AgentFileUploaded)
    },
    agentFilesDownloaded () {
      return reduceReport(this.report, UsageTypes.AgentFileDownloaded)
    },
    creditCardValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && (cc.apiCall === ValidateApiCall.ValidateCreditCard || cc.apiCall === ValidateApiCall.ValidateCreditCardExtended)) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    bankAccountValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && cc.apiCall === ValidateApiCall.ValidateAccount) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    ibanValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && cc.apiCall === ValidateApiCall.ValidateIBAN) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    copValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && cc.apiCall === ValidateApiCall.ValidateCoP) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    bureauThreshold () {
      return this.report[0].licenceLimits.bureauThreshold
    },
    emailThreshold () {
      return this.report[0].licenceLimits.emailThreshold
    },
    smsThreshold () {
      return this.report[0].licenceLimits.smsThreshold
    },
    validateThreshold () {
      return this.report[0].licenceLimits.validateThreshold
    }
  },
  data () {
    return {
      counterOptions: {
        startVal: 0,
        duration: 4,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      limitCounterOptions: {
        startVal: 0,
        duration: 4,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: 'With a limit of ',
        suffix: ''
      },
      rows: [],
      columns: [
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          sortable: false
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by type',
            filterDropdownItems: Object.values(UsageTypes)
          },
          sortable: false
        },
        {
          label: 'Extra Data',
          field: 'data',
          sortable: false
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
          sortable: false
        }
      ],
      isTableLoading: true,
      serverParams: {
        columnFilters: {},
        page: 1,
        perPage: 10
      },
      totalRecords: 0,
      sortOptions: { enabled: false },
      paginationOptions: { enabled: true, perPage: 10, setCurrentPage: 1 }
    }
  },
  watch: {
    report () {
      this.populateTable()
    }
  },
  mounted () {
    this.populateTable()
  },
  methods: {
    back () {
      this.$router.push({ name: 'BillingReportAllCustomers' })
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    },
    onColumnFilter (params) {
      this.updateParams({ ...params, page: 1 })
    },
    onPerPageChange (params) {
      this.$refs.table.currentPage = 1
      this.updateParams({ perPage: params.currentPerPage, page: 1 })
    },
    updateParams: _.debounce(function (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      this.populateTable()
    }, 100),
    buildGoodTableQuery () {
      return { skip: this.serverParams.perPage * this.serverParams.page - 1, take: this.serverParams.perPage, UsageType: this.serverParams.columnFilters.type }
    },
    async populateTable () {
      if (!this.report[0]) {
        return
      }
      try {
        this.isTableLoading = true
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Billing/Single/Table`, { month: this.month, paygateId: this.paygateId, ...this.buildGoodTableQuery() }, { showload: true, showerror: true, errormessage: 'Failed to load billing report table' })
        this.totalRecords = response.data.total

        var validateValues = Object.values(ValidateApiCall)
        var validateKeys = Object.keys(ValidateApiCall)

        this.rows = response.data.data.map(d => {
          var extraData = ''

          if (d.data) {
            d.data = JSON.parse(d.data)
            if (d.type === UsageTypes.Validate) {
              var validateIndexOfType = validateValues.indexOf(d.data.apiCall)
              var error = ''
              if (d.data.errorCode !== '') {
                error = `, Error Code: ${d.data.errorCode}, Error Text ${d.data.errorText}`
              }
              extraData = `Call Type: ${validateKeys[validateIndexOfType]}${error}`
            }
          }

          return {
            amount: d.amount,
            type: d.type,
            date: d.date,
            data: extraData
          }
        })
      } catch { } finally {
        this.isTableLoading = false
      }
    }
  }
}
</script>

<style>
</style>
