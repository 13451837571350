<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <h3>
      Email File
      <span class="pull-right">
        <a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/filetransfer/emailfile/`" target="_blank">
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <br />
    <div class="alert alert-info" role="alert">
      Please note, all filenames and paths are case sensitive.
    </div>
    <!-- <hr /> -->
    <label class="required">Filename</label>
    <div class="input-group mb-3">
      <input
        type="text"
        :disabled="!hasManageRole"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" :disabled="!hasManageRole" type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty "
    >The filename field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.invalidChars">The filename contains invalid characters.</p>

    <br />
    <label class="required">Folder</label>
    <div class="input-group mb-3">
      <input
        type="text"
        :disabled="!hasManageRole"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          :disabled="!hasManageRole"
          @click.prevent="btnFolderBrowser"
          type="button"
        >...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
    >The Path field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.invalidChars">The filename contains invalid characters.</p>

    <br />
    <div class="form-group">
      <label class="required">Subject</label>
      <input
        type="text"
        :disabled="!hasManageRole"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s6.value.$error}"
        @input="$v.selectedNode.props.s6.value.$touch()"
        v-model="selectedNode.props.s6.value"
      />
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.s6.value.required && $v.selectedNode.props.s6.value.$dirty "
      >The Subject field is required!</p>
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.s6.value.maxLength"
      >Maximum length is {{ $v.selectedNode.props.s6.value.$params.maxLength.max }} characters!</p>
    </div>
    <br />
    <div class="textarea">
      <div class="form-group">
        <label class="required">Message</label>
        <textarea
          class="form-control"
          :disabled="!hasManageRole"
          rows="8"
          cols="40"
          :class="{invalid: $v.selectedNode.props.s7.value.$error}"
          @input="$v.selectedNode.props.s7.value.$touch()"
          v-model="selectedNode.props.s7.value"
        ></textarea>
        <p
          class="validationText"
          v-if="!$v.selectedNode.props.s7.value.required && $v.selectedNode.props.s7.value.$dirty "
        >The message field is required!</p>
        <p
          class="validationText"
          v-if="!$v.selectedNode.props.s7.value.maxLength"
        >Maximum length is {{ $v.selectedNode.props.s7.value.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <br />
    <div class="textarea">
      <div class="form-group">
        <label class="required">To</label><span class="text text-muted ml-1"><small>Comma separated list</small></span>
        <input type="text"
          class="form-control"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s3.value.$error}"
          @input="$v.selectedNode.props.s3.value.$touch()"
          v-model="selectedNode.props.s3.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">At least one 'To' email address is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.s3.$params.maxLength.max }} characters!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.customEmail && $v.selectedNode.props.s3.value.$dirty ">One or more invalid email addresses!</p>
      </div>
    </div>
    <br />
    <div class="textarea">
      <div class="form-group">
        <label>Cc</label><span class="text text-muted ml-1"><small>Comma separated list</small></span>
        <input type="text"
          class="form-control"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s4.value.$error}"
          @input="$v.selectedNode.props.s4.value.$touch()"
          v-model="selectedNode.props.s4.value">
          <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.s4.$params.maxLength.max }} characters!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.s4.value.customEmail">One or more invalid email addresses!</p>
        <!-- <input-tag
          v-model="ccEmailAddress"
          :disabled="!hasManageRole"
          :limit="32"
          validate="email"
          :allowDuplicates="false"
        ></input-tag> -->
      </div>
    </div>
    <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value" :disabled="!hasManageRole">Follow error path if no matching files found</p-check>
    </div>
    <br />
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" @click.prevent="btnSave" :disabled="!hasManageRole">OK</b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import roles from '@/Assets/Constants/roles'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations () {
    return {
      // toEmailAddress: {
      //   required,
      //   maxLength: maxLength(1024)
      // },
      selectedNode: {
        props: {
          s1: {
            value: {
              required,
              maxLength: maxLength(128),
              invalidChars: () => {
                if (this.selectedNode.props.s1.value === '') {
                  return true
                }
                if (this.selectedNode.props.s1.value.match(/[:]/g)) {
                  return false
                } else {
                  return true
                }
              }
            }
          },
          s2: {
            value: {
              required,
              maxLength: maxLength(128),
              invalidChars: () => {
                if (this.selectedNode.props.s2.value === '') {
                  return true
                }
                if (this.selectedNode.props.s2.value.match(/[:/]/g)) {
                  return false
                } else {
                  return true
                }
              }
            }
          },
          s3: {
            value: {
              required,
              maxLength: maxLength(1024),
              customEmail: () => {
                let valid = true
                const emails = this.selectedNode.props.s3.value.split(',')
                if (emails.length > 0) {
                  for (const ea of emails) {
                    const isValid = this.validateEmail(ea)
                    if (!isValid) valid = false
                  }
                }
                return valid
              }
            }
          },
          s4: {
            value: {
              maxLength: maxLength(1024),
              customEmail: () => {
                let valid = true
                if (this.selectedNode.props.s4.value === '') {
                  return valid
                }
                const emails = this.selectedNode.props.s4.value.split(',')
                if (emails.length > 0) {
                  for (const ea of emails) {
                    const isValid = this.validateEmail(ea)
                    if (!isValid) valid = false
                  }
                }
                return valid
              }
            }
          },
          s5: {
            value: {
              maxLength: maxLength(1024)
            }
          },
          s6: {
            value: {
              required,
              maxLength: maxLength(64)
            }
          },
          s7: {
            value: {
              required,
              maxLength: maxLength(4096)
            }
          }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      showFolderBrowser: false,
      showFileBrowser: false,
      // toEmailAddress: [],
      // ccEmailAddress: [],
      // bccEmailAddress: [],
      selectedNode: {},
      rollbackState: ''
    }
  },
  methods: {
    btnSave: function () {
      // this.selectedNode.props.s3.value = this.toEmailAddress.toString()
      // this.selectedNode.props.s4.value = this.ccEmailAddress.toString()
      // this.selectedNode.props.s5.value = this.bccEmailAddress.toString()
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      // this.selectedNode.props.s2.value = utils.sanitiseFilename(this.selectedNode.props.s2.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s3.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFileBrowser = true
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s2.value = filename
      let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      if (folder.endsWith('/') || folder.endsWith('\\')) {
        folder = folder.slice(0, -1)
      }
      this.selectedNode.props.s1.value = folder
    },
    closeFolderPopup () {
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    },
    validateEmail: function (email) {
      // Validate a single email address using some regex what I found on SO
      email = email.toLowerCase().trim()
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode

    if (!this.selectedNode.props.b1) {
      this.selectedNode.props.b1 = {
        name: 'followError',
        title: 'Follow Error',
        value: false,
        element: 'b1',
        isRequired: false
      }
    }

    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
