var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" Search "),_c('help-icon',{attrs:{"docPath":'/common/search/'}})],1)]),_c('div',{staticClass:"idb-block-content"},[_c('div',{staticClass:"accordion",attrs:{"id":"searchFilters"}},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"collapse show",attrs:{"id":"searchFilter","data-parent":"#searchFilters"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label col-md-3 required"},[_vm._v("Search Term")]),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchTerm),expression:"searchTerm",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","focus":""},domProps:{"value":(_vm.searchTerm)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value.trim()},function($event){return _vm.delayedSearch()}],"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label col-md-3 required"},[_vm._v("Search Type")]),_c('div',{staticClass:"col-md-6"},[_c('b-form-select',{attrs:{"options":_vm.typeFilters},on:{"change":function($event){return _vm.search()}},model:{value:(_vm.searchFilter.typeFilter),callback:function ($$v) {_vm.$set(_vm.searchFilter, "typeFilter", $$v)},expression:"searchFilter.typeFilter"}},[_c('template',{slot:"first"},[_c('option',{attrs:{"value":""}},[_vm._v("All")])])],2)],1)])])])])]),_c('vue-good-table',{attrs:{"paginationOptions":{
        enabled: true,
        perPage: 10,
      },"rows":_vm.rows,"columns":_vm.columns,"lineNumbers":true,"styleClass":"vgt-table striped bordered"},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'relevance')?_c('span',[_c('span',[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",class:{'bg-success':props.row.relevance >= 70,
                 'bg-info': props.row.relevance < 70 && props.row.relevance >= 50,
                 'bg-warning': props.row.relevance < 50 && props.row.relevance >= 20,
                'bg-danger': props.row.relevance < 20   },style:({width:props.row.relevance + '%'})},[_vm._v(_vm._s(Math.round(props.row.relevance)))])])])]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Nothing was found, please change the search criteria")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header",attrs:{"id":"headingOne"}},[_c('h5',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button","data-toggle":"collapse","data-target":"#searchFilter"}},[_vm._v("Search Filters")])])])
}]
render._withStripped = true
export { render, staticRenderFns }