<template>
  <div ref="Designer">
    <workflowMetaDataPopup v-if="showMetaDataPopup"
      @close="showMetaDataPopup = false; closeMetaDataPopup(true)"
      @cancel="showMetaDataPopup = false; closeMetaDataPopup(false)">
    <h3 slot="header">Workflow Metadata</h3>
  </workflowMetaDataPopup>
  <modal v-if="showModal" @close="showModal = false">
  </modal>
  <b-modal
    ref="modal-validation"
    scrollable
    centered
    size="huge"
    hide-footer
    @hidden="cancelValidationModal"
    title="Workflow Validation">
    <div>
      <vue-good-table
        :columns="validation_columns"
        :rows="validationResponse"
        :lineNumbers="true"
        :search-options="{
          enabled: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: true,
          rowsPerPageLabel: 'Messages per page',
        }"
        styleClass="vgt-table striped bordered table-hover"
        >
        <template slot="table-row" slot-scope="props">
          <span v-if = "props.column.field === 'level'">
            <b-badge v-if = "props.row.level == 'Invalid'" variant="danger">{{props.row.level}}</b-badge>
            <b-badge v-else-if = "props.row.level === 'Warning'" variant="warning">{{props.row.level}}</b-badge>
            <b-badge v-else-if = "props.row.level === 'Info'" variant="info">{{props.row.level}}</b-badge>
            <span v-else>{{props.row.level}}</span>
          </span>
          <span v-else-if = "props.column.field === 'createdAt'">
            {{ reformatDate(props.formattedRow[props.column.field])}}
          </span>
          <span v-else-if = "props.column.field === 'updatedAt'">
            {{ reformatDate(props.formattedRow[props.column.field])}}
          </span>
          <span v-else-if = "props.column.field === 'buttons'">
            <b-button @click.prevent="viewWorkflowEvent(props)" variant="link" class="pointer" title="View Workflow Event" v-b-popover.hover.top.d500="'Drill down into this workflow event.'"><span class="text-secondary"><i class="glyphicon ti-list"></i></span></b-button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        <!-- <div slot="table-actions">
          <b-button @click.prevent="getEventData" class="" variant="link" title="Refresh Table" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i></b-button>
        </div> -->
      </vue-good-table>
    </div>
    <b-button class="mt-3" variant="primary" @click="hideValidationModal">Close</b-button>
  </b-modal>
  <b-modal
    ref="my-modal"
    scrollable
    centered
    size="huge"
    hide-footer
    @hidden="cancelTelemetryModal"
    :title=executionPopupTitle>
    <div>
      <b-alert variant="success" dismissible :show="workflowCompleted" v-if="workflowCompleted">The workflow has completed.<br/><small>The workflow has signalled that it has completed executing and so realtime logging has stopped.</small></b-alert>
      <div v-if="showSpinner && !workflowCompleted" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <b-tabs content-class="mt-3">
        <b-tab title="Events" active>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{ enabled: true }"
            :pagination-options="{
              enabled: true,
              perPage: 10,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: true,
              rowsPerPageLabel: 'Events per page',
            }"
            styleClass="vgt-table striped bordered table-hover">
            <template slot="table-row" slot-scope="props">
              <span v-if = "props.column.field === 'currentStatus'">
                <b-badge pill v-if = "props.row.currentStatus == 'Failed'" variant="danger">{{props.row.currentStatus}}</b-badge>
                <b-badge pill v-else-if = "props.row.currentStatus === 'Complete'" variant="success">{{props.row.currentStatus}}</b-badge>
                <b-badge pill v-else-if = "props.row.currentStatus === 'Completed with warnings'" variant="warning">{{props.row.currentStatus}}</b-badge>
                <b-badge pill v-else-if = "props.row.currentStatus === 'Processing'" variant="primary">{{props.row.currentStatus}}</b-badge>
                <b-badge pill v-else-if = "props.row.currentStatus === 'Warning'" variant="warning">{{props.row.currentStatus}}</b-badge>
                <b-badge pill v-else-if = "props.row.currentStatus === 'Paused'" variant="warning">{{props.row.currentStatus}}</b-badge>
                <span v-else>{{props.row.currentStatus}}</span>
              </span>
              <span v-else-if = "props.column.field === 'createdAt'">
                {{ reformatDate(props.formattedRow[props.column.field])}}
              </span>
              <span v-else-if = "props.column.field === 'updatedAt'">
                {{ reformatDate(props.formattedRow[props.column.field])}}
              </span>
              <span v-else-if = "props.column.field === 'buttons'">
                <b-button @click.prevent="viewWorkflowEvent(props)" variant="link" class="pointer" title="View Workflow Event" v-b-popover.hover.top.d500="'Drill down into this workflow event.'"><span class="text-secondary"><i class="glyphicon ti-list"></i></span></b-button>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            <div slot="table-actions">
              <b-button @click.prevent="getEventData" class="" variant="link" title="Refresh Table" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i><span class="sr-only">Refresh Table</span></b-button>
            </div>
          </vue-good-table>
        </b-tab>
        <b-tab>
          <template slot="title">
            Logs <b-badge pill v-if="log_rows.length > 0" class="ml-2" variant="primary">{{log_rows.length}}</b-badge>
          </template>
          <vue-good-table
            :columns="log_columns"
            :rows="log_rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 10,
              perPageDropdown: [10, 50, 100],
              dropdownAllowAll: true,
              rowsPerPageLabel: 'Logs per page',
            }"
            styleClass="vgt-table striped bordered table-hover"
            >
            <template slot="table-row" slot-scope="props">
              <span v-if = "props.column.field == 'datetime'">
                {{ reformatDate(props.formattedRow[props.column.field])}}
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            <div slot="table-actions">
              <b-button @click.prevent="getLogData" class="" variant="link" title="Refresh Table" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon pointer"></i><span class="sr-only">Refresh Table</span></b-button>
            </div>
          </vue-good-table>
        </b-tab>
      </b-tabs>
    </div>
    <b-button class="mt-3" variant="primary" @click="hideExecutionModal">Close</b-button>
    <b-button class="mt-3 ml-2" variant="outline-primary" @click="btnWorkflowTelemetry">Workflow Telemetry</b-button>
  </b-modal>
  <div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="idb-block" ref="blockwidth">
      <div class="idb-block-title">
        <h2>Workflow Designer<span v-if="workflowMetaData.title !== ''">: </span>{{workflowMetaData.title}}<span class="pull-right"><a :href="`${docUrl}/automation/paygate-designer/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-warning alert-dismissible" role="alert" v-if="ie11">
          <h4 class="alert-heading"><i class="fas fa-exclamation-triangle mr-2"></i>Internet Explorer 11 Detected</h4>
          <p>Some features of the Workflow Designer may not work correctly on very old, unmaintained browsers such as Internet Explorer 11.  Please consider using a modern browser such as Microsoft Edge, Google Chrome or Mozilla Firefox.
          </p>
          <hr>
          <p class="mb-0"> Follow this <a :href="`${docUrl}/troubleshooting/ie11/`" target="_blank">link</a> for more details.</p>
        </div>
        <b-navbar ref="navbar" toggleable="md" type="light" class="" v-if="!hideUI && isLicenced">
          <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
          <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav>
              <b-nav-item-dropdown :disabled="isLoading" text="File" >
                <b-dropdown-item :disabled="isLoading" @click="menuNewWorkflow"><i class="far fa-file font-lite mr-2"></i>New</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="#" :disabled="isLoading" @click.prevent="menuLoadWorkflow"><i class="fas fa-file-import font-lite mr-2"></i>Load</b-dropdown-item>
                <b-dropdown-item href="#" :disabled="isLoading" @click.prevent="menuSaveWorkflow"><i class="fas fa-save font-lite mr-2"></i>Save</b-dropdown-item>
                <b-dropdown-item href="#" :disabled="isLoading" @click.prevent="menuSaveAsWorkflow"><i class="far fa-save font-lite mr-2"></i>Save As</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown :disabled="isLoading" text="Edit" >
                <b-dropdown-item href="#" :disabled="isLoading" @click.prevent="menuUndo"><span><i class="fas fa-undo font-lite mr-2"></i>Undo</span> <span class="float-right text-sml text-muted">Ctrl+Z</span></b-dropdown-item>
                <b-dropdown-item href="#" :disabled="isLoading" @click.prevent="menuRedo"><span><i class="fas fa-redo font-lite mr-2"></i>Redo</span> <span class="float-right text-sml text-muted">Ctrl+Y</span></b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :disabled="isLoading" @click="doCut"><span><i class="fas fa-cut font-lite mr-2"></i>Cut</span> <span class="float-right text-sml text-muted">Ctrl+X</span></b-dropdown-item>
                <b-dropdown-item :disabled="isLoading" @click="doCopy"><span><i class="fas fa-copy font-lite mr-2"></i>Copy</span> <span class="float-right text-sml text-muted">Ctrl+C</span></b-dropdown-item>
                <b-dropdown-item :disabled="isLoading" @click="doPaste"><span><i class="fas fa-paste font-lite mr-2"></i>Paste</span> <span class="float-right text-sml text-muted">Ctrl+V</span></b-dropdown-item>
                <!-- <b-dropdown-divider></b-dropdown-divider> -->
                <!-- <b-dropdown-item href="#" @click="magneticLinksToggle=!magneticLinksToggle"><i class="fas fa-magnet font-lite mr-2"></i>Magnetic Links: {{magneticLinksToggle}}</b-dropdown-item> -->
              </b-nav-item-dropdown>
              <b-nav-item-dropdown :disabled="isLoading" text="Workflow" >
                <b-dropdown-item :disabled="isLoading" href="#" @click.prevent="menuMoveOrigin"><i class="fas fa-map-marker font-lite mr-2"></i>Move to Origin</b-dropdown-item>
                <b-dropdown-item :disabled="isLoading" v-if="id" href="#" @click.prevent="menuWorkflowDetail"><i class="fas fa-list font-lite mr-2"></i>Workflow Detail</b-dropdown-item>
                <b-dropdown-item :disabled="isLoading" href="#" @click.prevent="menuMetaData"><i class="fas fa-database font-lite mr-2"></i>Workflow Metadata</b-dropdown-item>
                <b-dropdown-item href="#" @click.prevent="menuDesignerSettings"><i class="fas fa-cog font-lite mr-2 text-secondary"></i>Designer Settings</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :disabled="isLoading" href="#" @click.prevent="menuClearCurrentDiagram"><i class="fas fa-eraser font-lite mr-2"></i>Clear</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :disabled="isLoading" href="#" @click.prevent="menuValidate"><i class="fas fa-check font-lite mr-2"></i>Validate Workflow</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown :disabled="isLoading" text="Nodes" >
                <b-dropdown-item href="#" @click="addNode('workflowStart', config)">Workflow Start</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-header id="header1">Automation</b-dropdown-header>
                <b-dropdown-item href="#" @click="mapping()">Mapping</b-dropdown-item>
                <b-dropdown-item href="#" @click="workflow()">Workflow</b-dropdown-item>
                <!-- <b-dropdown-item href="#" @click="exporter()">Exporter</b-dropdown-item> -->
                <b-dropdown-item href="#" @click="bacsAutomation()">Bacs Automation</b-dropdown-item>
                <!-- <b-dropdown-item href="#" @click="bacsBureauAutomation()">Bacs Bureau Automation</b-dropdown-item> -->
                <!-- <b-dropdown-item href="#" @click="collectionsAutomation()">Collections Automation</b-dropdown-item> -->
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-header id="header1">Workflow Nodes</b-dropdown-header>
                <b-dropdown-item href="#" @click="fileOperationsPopup()">File Operations</b-dropdown-item>
                <b-dropdown-item href="#" @click="fileTransferPopup()">File Transfer</b-dropdown-item>
                <b-dropdown-item href="#" @click="messagingPopup()">Messaging</b-dropdown-item>
                <b-dropdown-item href="#" @click="encryptionPopup()">Encryption</b-dropdown-item>
                <b-dropdown-item href="#" @click="compressionPopup()">Compression</b-dropdown-item>
                <b-dropdown-item href="#" @click="loggingPopup()">Logging &amp; Audits</b-dropdown-item>
                <b-dropdown-item href="#" @click="flowPopup()">Flow</b-dropdown-item>
              </b-nav-item-dropdown>
            <b-nav-item href="#" :disabled="isLoading" v-b-popover.hover.top.d500="'Undo'" @click.prevent="menuUndo"><i class="fa fa-undo text-secondary"></i> </b-nav-item>
            <b-nav-item href="#" :disabled="isLoading" v-b-popover.hover.top.d500="'Redo'" @click.prevent="menuRedo"><i class="fa fa-redo text-secondary"></i> </b-nav-item>
            <b-nav-item href="#" :disabled="isLoading" v-b-popover.hover.top.d500="'Validate Workflow'"  id="validate" @click.prevent="menuValidate" >
              <i class="fa fa-vial text-secondary"
                :class="{
                  // 'text-danger': validationlevel === 'Invalid',
                  // 'text-warning': validationlevel === 'Warning',
                  // 'text-info': validationlevel === 'Info',
                  // 'text-success': validationlevel === 'Valid'
                }"
                ></i>
            </b-nav-item>
            <b-nav-item href="#" :disabled="isLoading" v-b-popover.hover.top.d500="'Execute the Workflow'" @click.prevent="menuExecuteWorkflow"><i class="fa fa-play text-secondary"></i> </b-nav-item>
            <!-- <b-nav-item href="#" title="Configure Selected" id="show-modal" @click="showModal = true"><i class="fa fa-cog dimmed"></i> </b-nav-item> -->
            <b-nav-item href="#" :disabled="isLoading" v-b-popover.hover.top.d500="'Remove Selected'" id="remove" @click="menuRemoveItem"><i class="fa fa-times text-secondary"></i> </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
        <canvas v-if="!hideUI && isLicenced" id="image-canvas" ref="imagecanvas" :width="canvasWidth" :height="canvasHeight" style="background-color:#fff; display:block;">
        </canvas>
      </div>
      <div class="idb-block-footer">
        <div class="">
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import * as diagram from './Engine/workflowDesigner.js'
import axios from 'axios'
import { mapGetters } from 'vuex'
import modal from './modal-template.vue'
import workflowMetaDataPopup from './WorkflowMetaDataPopup.vue'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'

export default {
  components: {
    modal,
    workflowMetaDataPopup,
    swal
  },
  mixins: [loading],
  data () {
    return {
      executionPopupTitle: 'Executing Workflow - Queued',
      isLicenced: true,
      ie11: false,
      config: {},
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      columns: [
        {
          label: 'executionId',
          field: 'executionId',
          hidden: true
        },
        {
          label: 'workflowId',
          field: 'workflowId',
          hidden: true
        },
        {
          label: 'Event',
          field: 'title',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Current Status',
          field: 'currentStatus',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Created At',
          field: 'createdAt',
          hidden: false,
          inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
          outputFormat: 'DD-MM-YYYY HH:mm:ss'
        },
        {
          label: 'Last Updated At',
          field: 'updatedAt',
          hidden: false
        },
        {
          label: 'Log',
          field: 'buttons',
          html: true,
          hidden: true
        }
      ],
      row_data: [],
      workflowEvent: {
        data: {}
      },
      log_columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Date',
          field: 'datetime',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Message',
          field: 'message',
          filterOptions: {
            enabled: false
          }
        }
      ],
      validation_columns: [
        {
          label: 'Rule No.',
          field: 'ruleId',
          hidden: false
        },
        {
          label: 'Validation Issue',
          field: 'ruleTitle'
        },
        {
          label: 'Message',
          field: 'message'
        },
        {
          label: 'Level',
          field: 'level'
        }
      ],
      showSpinner: true,
      workflowCompleted: false,
      hideUI: false,
      validationlevel: '',
      validationResponse: {},
      log_row_data: [],
      canvasWidth: 100,
      canvasHeight: 1000,
      showMetaDataPopup: false,
      showTelemetryWarning: false,
      showModal: false,
      paygateId: '',
      id: '',
      executionId: '',
      eventInterval: {},
      title: '',
      currentDiagramType: 'workflow',
      isNewDiagram: true,
      magneticLinksToggle: false,
      formData: {
      },
      schema: [],
      workflowMetaData: {
        title: '',
        description: '',
        author: '',
        dateCreated: '',
        type: 'bacs',
        numExecution: 0,
        lastExecuted: '',
        tags: []
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    log_rows: function () {
      return this.log_row_data
    },
    validation_rows: function () {
      return this.validationResponse
    },
    saveWorkflow () {
      return this.$store.state.workflow.saveWorkflow
    },
    selectedNode () {
      return this.$store.state.workflow.selectedNode
    },
    nodeUI () {
      return this.$store.state.workflow.selectedNode.ui.schema
    },
    showPopup () {
      return this.$store.state.workflow.showNodeCfgModelPopup
    },
    canvas: function () {
      return this.$refs.imagecanvas
    },
    ctx: function () {
      return this.canvas.getContext('2d')
    },
    ...mapGetters({
      getClaim: 'getClaim',
      getClaims: 'getClaims'
    }),
    username () {
      return this.getClaim('username').value
    }
  },
  created: async function () {
    this.$snapbar.hide()
    await this.loadLicence()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.$store.commit('setIsWorkflowDirty', false)

    await this.getDesignerSettings()
    this.isIe11()

    // Initialise the HTML5 canvas
    diagram.initCanvas()
    this.id = this.$route.params.id
    if (!this.id) {
      // Brand new Workflow Diagram - auto insert a start node so the user doesn;t forget it.
      this.isNewDiagram = true
      this.workflowMetaData.author = this.username
      this.addNode('workflowStart', this.config)
    } else {
      // Existing workflow being loaded in
      this.isNewDiagram = false
      this.loadWorkflowIntoCanvas(this.id)
    }
  },
  mounted: async function () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    diagram.destroyClipboard()
  },
  async beforeRouteLeave (to, from, next) {
    if (this.$store.state.workflow.isWorkflowDirty === true) {
      const result = await swal.fire({
        title: 'You have unsaved changes!',
        text: 'You will lose any changes to the current workflow.  Are you sure you want to navigate away?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-danger'
      })
      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    isIe11 () {
      this.ie11 = !!window.MSInputMethodContext && !!document.documentMode
    },
    async getDesignerSettings () {
      let res
      try {
        const url = `${process.env.VUE_APP_WORKFLOWAPI_API_URL}designersettings`
        console.log('url:', url)
        res = await axios.get(url, { showload: true })
        if (res && res.data) {
          this.config = res.data
        } else {
          this.$snapbar.e('Error retrieving Designer Settings')
        }
      } catch (e) {
        console.log(e)
        this.$snapbar.e(`Error retrieving Designer Settings - ${e.message}`)
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      const ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s + '.' + ms
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    async getEventData () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtelemetry/${this.executionId}`)
        this.row_data = res.data.workflowEvent
        if (res && res.data) {
          try {
            if (res.data.workflowEvent[res.data.workflowEvent.length - 1].completed) {
              this.workflowCompleted = true
            }
          } catch {}
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view telemetry data.')
        } else {
          this.$snapbar.e(`Could not get event data - ${e.message}`)
        }
      }
    },
    async getLogData () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowAllMessages/${this.executionId}`)
        this.log_row_data = res.data.workflowMessage
        if (this.log_row_data.length > 0) {
          this.executionPopupTitle = 'Workflow Execution - Realtime Logging'
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view telemetry data.')
        } else {
          this.$snapbar.e(`Error retrieving workflow log - ${e.message}`)
        }
      }
    },
    onCellClick (params) {
      if (params.column.field !== 'buttons') {
        this.$router.push(`/workflow/workflowEvent/${params.row._id}`)
      }
    },
    handleResize: function () {
      this.height = window.innerHeight
      let w = this.$refs.blockwidth.offsetWidth
      let cw = parseInt(w)
      this.canvasWidth = cw - 40
    },
    doCut: function () {
      diagram.cutClipboard()
    },
    doCopy: function () {
      diagram.copyClipboard()
    },
    doPaste: function () {
      diagram.pasteClipBoard()
    },
    closeMetaDataPopup (saveMetaData = false) {
      this.workflowMetaData = this.$store.state.workflow.workflowMetaData
      if (saveMetaData) {
        if (this.id) {
          this.menuSaveWorkflow(false)
        }
      }
    },
    async workflow () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtitles`)
        if (res) {
          const inputOptions = {}
          res.data.workflows.forEach((element) => {
            inputOptions[element._id] = element.title
          })
          const result = await swal.fire({
            title: 'Select a Workflow',
            html: '',
            input: 'select',
            inputOptions: inputOptions,
            showCancelButton: true,
            confirmButtonText: 'Select',
            cancelButtonText: 'Cancel'
          })
          if (result.isConfirmed) {
            if (result.value) {
              this.addNode('workflow', result.value)
            }
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Workflows.')
        } else {
          this.$snapbar.e(`Could not get a list of workflows - ${e.message}`)
        }
      }
    },
    async mapping () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingtitles`)
        if (res) {
          const inputOptions = {}
          res.data.mappings.forEach((element) => {
            inputOptions[element._id] = element.title
          })
          const result = await swal.fire({
            title: 'Select a Mapping',
            html: '',
            input: 'select',
            inputOptions: inputOptions,
            showCancelButton: true,
            confirmButtonText: 'Select',
            cancelButtonText: 'Cancel'
          })
          if (result.isConfirmed) {
            if (result.value) {
              this.addNode('mapping', result.value)
            }
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Mappings.')
        } else {
          this.$snapbar.e(`Could not get a list of Mappings - ${e.message}`)
        }
      }
    },
    async bacsBureauAutomation () {
      let extraValue
      const inputOptions = {
        bacsBureauJobProcessor: 'Bureau Job Processor',
        bacsSignSubmission: 'Sign Submission',
        bacsApproveSubmission: 'Approve Submission',
        bacsSubmission: 'Send Submission',
        bacsReportDownload: 'Report Download',
        bacsReportMapping: 'Report Mapping'
      }
      const result = await swal.fire({
        title: 'BACS Bureau Automation',
        html: 'Workflow nodes that automate the BACS bureau submission and reporting process.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async bacsAutomation () {
      let extraValue
      const inputOptions = {
        bacsPreSubValidation: 'Submission Validation',
        bacsSignSubmission: 'Sign Submission',
        bacsApproveSubmission: 'Approve Submission',
        bacsSubmission: 'Send Submission',
        bacsReportDownload: 'Report Download',
        bacsReportMapping: 'Report Mapping'
      }
      const result = await swal.fire({
        title: 'BACS Automation',
        html: 'Workflow nodes that automate the BACS submission and reporting process.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async collectionsAutomation () {
      let extraValue
      const inputOptions = {
        collectionsProcessor: 'Collections Processor',
        // collectionsFinaliseSubmission: 'Finalise Collection Submission',
        collectionsReportReconciliation: 'Reports / Payer Reconciliation'
      }
      const result = await swal.fire({
        title: 'Collections Automation',
        html: 'For help on these nodes go <a href="/mapping/mappings">Here</a>.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async fileOperationsPopup () {
      let extraValue
      const inputOptions = {
        copyFile: 'Copy File',
        moveFile: 'Move File',
        moveSomeFiles: 'Move Some',
        renameFile: 'Rename File',
        deleteFile: 'Delete File',
        fileJoiner: 'File Joiner',
        fileSplitter: 'File Splitter'
      }
      const result = await swal.fire({
        title: 'File Operators',
        html: 'Workflow nodes that perform file operations.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async fileTransferPopup () {
      let extraValue
      const inputOptions = {
        emailFile: 'Email File',
        sftpFile: 'SFTP (SSH) File'
      }
      const result = await swal.fire({
        title: 'File Transfer',
        html: 'Workflow nodes that transfer files.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async messagingPopup () {
      let extraValue
      const inputOptions = {
        emailMessage: 'Email Message',
        webhook: 'Webhook',
        smsMessage: 'SMS Message'
      }
      const result = await swal.fire({
        title: 'Messaging',
        html: 'Workflow nodes that send messages.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async encryptionPopup () {
      let extraValue
      const inputOptions = {
        encryptFile: 'Encrypt File',
        decryptFile: 'Decrypt File'
      }
      const result = await swal.fire({
        title: 'Encryption',
        html: 'Workflow nodes that encrypt and decrypt files.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async compressionPopup () {
      let extraValue
      const inputOptions = {
        zipCompression: 'Zip File',
        zipDecompression: 'Unzip File'
      }
      const result = await swal.fire({
        title: 'Compression',
        html: 'Workflow nodes that compress (zip) and uncompress (unzip) files.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async loggingPopup () {
      let extraValue
      const inputOptions = {
        addToWorkflowLog: 'Add To Workflow Log',
        addToAuditLog: 'Add To Audit Log'
      }
      const result = await swal.fire({
        title: 'Logging & Audits',
        html: 'Workflow nodes that create log and audit messages.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    async flowPopup () {
      let extraValue
      const inputOptions = {
        filenameVariable: 'Set Filename Variable',
        delay: 'Delay',
        fileExists: 'File Exists',
        waitForFile: 'Wait for File'
      }
      const result = await swal.fire({
        title: 'Flow Operators',
        html: 'Workflow nodes that affect how workflows operate.',
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        confirmButtonText: 'Select',
        cancelButtonText: 'Cancel'
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.addNode(result.value, extraValue)
        }
      }
    },
    menuMetaData () {
      this.$store.commit('setWorkflowMetaData', this.workflowMetaData)
      this.showMetaDataPopup = true
    },
    menuDesignerSettings () {
      this.$router.push('/automation/config/designersettings')
    },
    test: function () {
    },

    async menuValidate (showPopup = false) {
      this.$snapbar.hide()
      const validateResponse = await this.validateWorkflow()

      if (validateResponse.valid === false) {
        // Only show the validation results popup if it failed validation.
        this.$refs['modal-validation'].show()
      }
      if (validateResponse.result === 'Valid' && showPopup) {
        this.$snapbar.s('The current workflow appears to be valid')
      } else if (validateResponse.result === 'Info') {
        this.$snapbar.i('Validation of the current workflow contains info messages.')
      } else if (validateResponse.result === 'Warning') {
        this.$snapbar.w('Validation of the current workflow contains warnings.')
      } else if (validateResponse.result === 'Invalid') {
        this.$snapbar.e('The current workflow is not valid.')
      }
    },
    async validateWorkflow (showIfNoErrors = true) {
      this.$snapbar.hide()
      const postData = diagram.buildWorkflowModel()
      postData.paygateId = this.paygateId

      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/validate`, postData)
        if (res) {
          this.validationResponse = res.data.validationResponse.validationMessages
          this.validationlevel = res.data.validationResponse.result
          return res.data.validationResponse
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to validate Workflows.')
        } else {
          this.$snapbar.e(`Error validating the Workflow - ${e.message}`)
        }
      }
    },
    hideValidationModal () {
      this.$refs['modal-validation'].hide()
    },
    closeMetaData () {
      this.workflowMetaData = this.$store.state.workflow.workflowMetaData
    },
    setId () {
      this.id = this.$route.params.id
    },
    addNode (name, extra) {
      diagram.createNode(65, 65, name, undefined, undefined, undefined, extra)
    },
    async menuLoadWorkflow () {
      let res
      try {
        this.$snapbar.hide()
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtitles`, { showload: true })
        if (res) {
          var inputOptions = {}
          res.data.workflows.forEach((element) => {
            inputOptions[element._id] = element.title
          })
          const result = await swal.fire({
            title: 'Select Workflow',
            html: 'Or use the <a href="/automation/workflow/workflows">Table View</a> to search for a workflow to load.',
            input: 'select',
            inputOptions: inputOptions,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Load',
            cancelButtonText: 'Cancel'
          })
          if (result.isConfirmed) {
            if (result.value) {
              this.$router.push({ path: `/automation/workflow/designer/${result.value}` })
            }
          }
        }
      } catch (e) {
        this.$snapbar.e(`Error loading workflow - ${e.message}`)
      }
    },
    async loadWorkflowIntoCanvas (id) {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/${id}`, { showload: true })
        if (res) {
          diagram.loadWorkflow(res.data.workflow.data)
          // this.$store.commit('setIsWorkflowDirty', false)
          this.id = res.data.workflow._id
          this.title = res.data.workflow.title
          this.workflowMetaData = res.data.workflow.metaData
          this.$store.commit('setSelectedWorkflowId', this.id)
          // this.$toastr.s(
          //   `Workflow '${res.data.workflow.title}' successfully loaded.`
          // )
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this Workflow.')
          this.hideUI = true
        } else {
          this.$snapbar.e(`Could notdisplay the Workflow- ${e.message}`)
        }
      }
    },
    async menuNewWorkflow () {
      if (this.$store.state.workflow.isWorkflowDirty === true) {
        const result = await swal.fire({
          title: 'You have unsaved changes',
          text: 'Creating a new workflow will loose any changes to the current workflow.  Are you sure you want to proceed?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No, keep current',
          cancelButtonClass: 'btn btn-light',
          confirmButtonClass: 'btn btn-danger'
        })
        if (result.isConfirmed) {
          this.newWorkflow()
          this.addNode('workflowStart')
        }
      } else {
        this.newWorkflow()
        this.addNode('workflowStart')
      }
    },
    newWorkflow () {
      diagram.clearCanvas()
      this.id = ''
      this.isNewDiagram = true
      this.$store.commit('setIsWorkflowDirty', false)
      this.$router.replace('/automation/workflow/designer')
    },
    async autoSaveWorkflow (showToast = true) {
      // Only autosave if the workflow has already been manually saved and autosave is enabled in 'Designer Config'
      if (this.id && this.config.autosave) {
        this.menuSaveWorkflow(false)
      }
    },
    async menuSaveWorkflow (showToast = true) {
      let postData = {}

      // Existing Workflow
      if (this.id) {
        postData = {
          id: this.id,
          paygateId: this.paygateId,
          title: this.title,
          description: '',
          data: diagram.buildWorkflowModel(),
          metaData: this.workflowMetaData
        }
        postData.data.paygateId = this.paygateId

        let res
        try {
          this.$snapbar.hide()
          res = await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/${this.id}`, postData, { showload: true })
          if (res) {
            if (showToast) {
              this.$toastr.s(
                `Workflow '${this.title}' successfully saved.`
              )
            }
            this.$store.commit('setIsWorkflowDirty', false)
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to save Workflows.')
          } else {
            this.$snapbar.e(`Error saving the Workflows - ${e.message}`)
          }
        }
      } else {
        this.menuSaveAsWorkflow()
      }
    },
    async menuSaveAsWorkflow () {
      const result = await swal.fire({
        title: 'Workflow Title',
        input: 'text',
        allowOutsideClick: false,
        confirmButtonText: 'Save',
        showCloseButton: true
      })
      if (result.isConfirmed) {
        if (result.value) {
          if (result.value.length > 64) {
            result.value = result.value.substring(0, 64)
          }
          this.workflowMetaData.title = result.value
          const postData = {
            paygateId: this.paygateId,
            title: result.value,
            data: diagram.buildWorkflowModel(),
            metaData: this.workflowMetaData
          }
          postData.data.paygateId = this.paygateId
          let res
          try {
            this.$snapbar.hide()
            res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow`, postData, { showload: true })
            if (res) {
              this.title = result.value
              this.$toastr.s(
                `Workflow '${result.value}' successfully saved.`
              )
              this.$store.commit('setIsWorkflowDirty', false)
              if (this.$route.params.id) {
                // The current url already ncludes an id - replace it with the new one
                const newRoute = this.$route.path.replace(this.$route.params.id, res.data.doc._id)
                this.$router.replace(newRoute)
              } else {
                this.$router.replace(`${this.$route.path}/${res.data.doc._id}`)
              }
            }
          } catch (e) {
            if (e.response && e.response.status === 403) {
              this.$snapbar.e('You are not authorised to save Workflows.')
            } else {
              this.$snapbar.e(`Error saving the Workflow - ${e.message}`)
            }
          }
        }
      }
    },
    menuClearCurrentDiagram () {
      diagram.clearCanvas()
    },
    menuMoveOrigin () {
      diagram.moveToOrigin()
    },
    menuWorkflowDetail () {
      this.$router.push(`/automation/workflow/workflowdetail/${this.id}`)
    },
    menuUndo () {
      diagram.undo()
    },
    menuRedo () {
      diagram.redo()
    },
    menuRemoveItem () {
      diagram.removeSelected()
    },
    async menuExecuteWorkflow () {
      this.workflowCompleted = false
      this.$snapbar.hide()
      this.executionPopupTitle = 'Executing Workflow - Queued'
      if (!this.id) {
        await this.$snapbar.w('Workflows must be saved before they can be executed.  Save the workflow and try again.')
        return
      }

      // Workflow Validation
      const validateResponse = await this.validateWorkflow(false)
      if (validateResponse.result === 'Valid') {
        // this.$snapbar.s('The current workflow appears to be valid')
      } else if (validateResponse.result === 'Info') {
        this.$snapbar.i('Validation of the current workflow contains info messages.')
        this.$refs['modal-validation'].show()
      } else if (validateResponse.result === 'Warning') {
        this.$snapbar.w('Validation of the current workflow contains warnings.')
        this.$refs['modal-validation'].show()
      } else if (validateResponse.result === 'Invalid') {
        this.$snapbar.e('The current workflow is not valid.')
        this.$refs['modal-validation'].show()
        return
      }

      this.row_data = []
      this.log_row_data = []
      this.showTelemetryWarning = false
      let response
      try {
        // Call to /execute won't wait for the entire WF to complete.  Just starts it async running in background and returns
        response = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/execute/${this.id}`, { showload: true })
        // this.showSpinner = false
        if (response.data?.executionId) {
          this.executionId = response.data.executionId
          console.log('executionId:', response.data.executionId)
          this.$refs['my-modal'].show()
          const timeout = 2000
          let maxIterations = 20000 // 240 TODO: Set back to 240
          this.eventInterval = setInterval(async () => {
            maxIterations--
            if (maxIterations < 0 || this.workflowCompleted === true) {
              clearInterval(this.eventInterval)
              this.showTelemetryWarning = true
              return
            }
            await this.getEventData()
            await this.getLogData()
          }, timeout)
        }
      } catch (e) {
        this.showSpinner = false
        if (e.response && e.response.status === 406) {
          this.$snapbar.e('Error executing workflow - The Workflow is already queued for execution.')
        } else {
          this.$snapbar.e(`Error executing workflow - ${e.message}`)
        }
      }
    },
    hideExecutionModal () {
      this.$refs['my-modal'].hide()
      clearInterval(this.eventInterval)
      this.showModal = false
      this.showSpinner = true
    },
    btnWorkflowTelemetry () {
      clearInterval(this.eventInterval)
      this.$router.push({ path: `/automation/workflow/workflowtelemetry/${this.executionId}` })
    },
    cancelTelemetryModal (evt) {
      if (this.eventInterval) {
        clearInterval(this.eventInterval)
      }
    },
    cancelValidationModal (evt) {
    },
    async loadLicence () {
      let response
      try {
        response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
        if (response && response.data && response.data.automation && response.data.automation.workflowDesigner) {
          this.isLicenced = response.data.automation.mappingDesigner
        } else {
          this.isLicenced = false
        }
      } catch (e) {
        this.isLicenced = false
      }
      if (!this.isLicenced) {
        this.$snapbar.e('Sorry, you are not licensed to use the Workflow Designer.')
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      this.$router.push('/automation/workflow/workflows')
    },
    '$route' (to, from) {
      this.id = to.params.id
      if (this.id) {
        this.loadWorkflowIntoCanvas(this.id)
      }
    },
    saveWorkflow: function () {
      if (this.$store.state.workflow.saveWorkflow === true) {
        if (this.config.autosave) {
          this.menuSaveWorkflow(false)
        }
        this.$store.commit('setSaveWorkflow', false)
      }
    },
    showPopup: function () {
      if (!this.showModal) {
        // This is used to detect when a user clicks the cog icon on a node to open the config popup.
        // Clicking the cog changes the state of the 'showNodeCfgModelPopup' in vuex.
        // This watcher detects the change, opens the popup and sets showNodeCfgModelPopup back to false
        this.showModal = true
        // this.$store.commit('setPopupState', false)
      } else {
        this.showModal = false
        this.menuSaveWorkflow(false)
      }
    }
  }
}
</script>
<style scoped>
  .dimmed {
    color: #888888;
  }

  .modal-big {
    width: 750px;
    margin: auto;
  }
  .pgnav {
    background-color: #dddddd;
    border-width: 0px;
    border-style: solid;
    border-color: #dddddd;
  }

  @media (min-width: 992px) {
    .modal .modal-huge {
      max-width: 90% !important;
      width: 90% !important;;
    }
  }

  .font-lite {
    color: #888888;
  }

  .swal2-modal .swal2-select {
    color: #ff0000;
  }


</style>
