import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'
export default [
	{
		menu_title: 'Customer',
		menu_icon: 'ti-agenda',
		showMethod: async () => {
			return Store.getters.isInRoles([
				roles.SystemUser,
				roles.CustomerAdministrator,
				roles.ViewAuditLog,
				roles.ImportCustomer,
				roles.CreateCustomer,
				roles.DeleteCustomer,
				roles.AgentConfigure
			])
		},
		child_routes: [
			{
				path: '/admin/customers/create',
				menu_title: 'Create',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CreateCustomer)
				}
			},
			{
				path: '/admin/customers/details',
				menu_title: 'Details',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				path: '/admin/customers/emails',
				menu_title: 'Emails',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator) && false
				}
			},
			{
				path: '/admin/customer/licence',
				menu_title: 'Licence',
				showMethod: async () => {
					return !Store.getters.isInRole(roles.SystemUser) && Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				path: '/admin/customers/licence',
				menu_title: 'Licence',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/customer/certificates',
				menu_title: 'Certificates',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.CustomerAdministrator, roles.SystemUser])
				}
			},
			{
				path: '/admin/customers/dashboard',
				menu_title: 'Default Dashboard',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				path: '/admin/customers/auditlogs',
				menu_title: 'Audit Log',
				showMethod: async () => {
					return Store.getters.isInRole(roles.ViewAuditLog)
				}
			},
			{
				path: '/admin/customers/agent',
				menu_title: 'Agent Details',
				showMethod: async () => {
					return Store.getters.isInRole(roles.AgentConfigure) && !Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/customers/import',
				menu_title: 'Import Customer',
				showMethod: async () => {
					return Store.getters.isInRole(roles.ImportCustomer)
				}
			}
		]
	},
	{
		menu_title: 'Users',
		menu_icon: 'ti-user',
		path: '/admin/users/list',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.ViewUsers, roles.ManageUsers])
		}
	},
	{
		menu_title: 'Service Accounts',
		menu_icon: 'ti-desktop',
		path: '/admin/serviceaccounts/list',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.CustomerAdministrator, roles.SystemUser])
		}
	},
	{
		menu_title: 'Groups',
		path: '/admin/groups/list',
		menu_icon: 'ti-layers-alt',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.AddGroups, roles.GroupAdministrator, roles.SystemUser])
		}
	},
	{
		menu_title: 'Bank Accounts',
		menu_icon: 'ti-package',
		path: '/admin/bankaccounts/list',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.ViewBanks, roles.ManageBanks])
		}
	},
	{
		menu_title: 'Reports',
		menu_icon: 'ti-receipt',
		name: 'AdminReports',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.CustomerReports, roles.ActionsReports, roles.Finance])
		},
		child_routes: [
			{
				path: '/admin/reports/systemreport',
				menu_title: 'System Report',
				name: 'AdminReportsSystemReport',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.CustomerReports])
				}
			},
			{
				path: '/admin/reports/usagereport',
				menu_title: 'Usage Report',
				name: 'AdminReportsUsageReport',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.CustomerReports])
				}
			},
			{
				path: '/admin/reports/actionsreport',
				menu_title: 'Actions Report',
				name: 'AdminReportsActionReport',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.ActionsReports])
				}
			},
			{
				path: '/admin/reports/billingreport',
				menu_title: 'Billing Report',
				name: 'AdminReportsBillingReport',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.Finance])
				}
			},
			{
				path: '/admin/reports/validatereport',
				menu_title: 'Validate Report',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.Finance])
				}
			},
			{
				path: '/admin/reports/ssoreport',
				menu_title: 'SSO Report',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.SystemUser])
				}
			},
			{
				path: '/admin/reports/bureauusagereport',
				menu_title: 'Bureau Usage Report',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			}
		]
	},
	{
		menu_title: 'Security Policy',
		menu_icon: 'ti-lock',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.CustomerAdministrator, roles.SystemUser])
		},
		child_routes: [
			{
				path: '/admin/securitypolicy/useraccounts',
				menu_title: 'User Accounts'
			},
			{
				path: '/admin/securitypolicy/bankaccounts',
				menu_title: 'Bank Accounts'
			},
			{
				path: '/admin/securitypolicy/bureaucustomers',
				menu_title: 'Bureau Customers'
			},
			{
				path: '/admin/securitypolicy/login',
				menu_title: 'Login Policy'
			},
			{
				path: '/admin/securitypolicy/passwordcomplexity',
				menu_title: 'Password Complexity'
			},
			{
				path: '/admin/securitypolicy/passwordexpiry',
				menu_title: 'Password Expiry'
			},
			{
				path: '/admin/securitypolicy/incorrectpassword',
				menu_title: 'Incorrect Password'
			},
			{
				path: '/admin/securitypolicy/inactivitytimeout',
				menu_title: 'Inactivity Timeout'
			},
			{
				path: '/admin/securitypolicy/timerestrictions',
				menu_title: 'Time Restrictions'
			},
			{
				path: '/admin/securitypolicy/ipaddressrestrictions',
				menu_title: 'IP Address Restrictions'
			},
			{
				path: '/admin/securitypolicy/paymentgroups',
				menu_title: 'Payment Groups'
			},
			{
				path: '/admin/securitypolicy/paymentapproval',
				menu_title: 'Payment Approval'
			},
			{
				path: '/admin/securitypolicy/antiFraudLists',
				menu_title: 'Anti-Fraud Lists'
			}
		]
	},
	{
		menu_title: 'USB Tokens',
		menu_icon: 'fab fa-fw fa-usb',
		showMethod: async () => {
			return Store.getters.isInRole(roles.CustomerAdministrator)
		},
		child_routes: [
			{
				path: '/admin/usbtokens',
				menu_title: 'View Assigned USB Tokens'
			},
			{
				path: '/admin/usbtokens/assign',
				menu_title: 'Assign USB tokens'
			},
			{
				path: '/admin/usbtokens/expiring',
				menu_title: 'View Expiring USB Tokens'
			}
		]
	},
	{
		menu_title: 'GDPR',
		menu_icon: 'ti-server',
		showMethod: async () => {
			return Store.getters.isInRole(roles.GDPRAdministrator)
		},
		child_routes: [
			{
				path: '/admin/GDPR/search',
				menu_title: 'Search Personal Data'
			},
			{
				path: '/admin/GDPR/deleteGroupData',
				menu_title: 'Delete Group Data'
			}
		]
	},
	{
		menu_title: 'Anti-Fraud Lists',
		menu_icon: 'ti-id-badge',
		path: '/admin/antifraud/list',
		showMethod: async () => {
			return Store.getters.isInRole(roles.ManageAntiFraud)
		}
	},
	{
		menu_title: 'Collections',
		menu_icon: 'ti-cloud-down',
		showMethod: async () => {
			return false // return Store.getters.isInRoles([roles.CustomerAdministrator, roles.SystemUser]) && Store.getters.hasLicence(licence.collections)
		},
		child_routes: [
			{
				path: '/admin/customers/onboarding',
				menu_title: 'Onboarding Allowed Urls',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				path: '/collections/onboarding/styles/',
				menu_title: 'Manage Onboarding Stylesheet',
				menu_icon: 'ti-link',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				menu_title: 'Email Bounces',
				menu_icon: 'ti-email',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				},
				path: '/admin/email/bounces',
				counterData: 'bouncedEmailsCount'
			},
			{
				path: '/collections/portal/configuration/',
				menu_title: 'Portal Configuration',
				menu_icon: 'ti-comments',
				showMethod: async () => {
					return Store.getters.isInRole(roles.CustomerAdministrator)
				}
			},
			{
				path: '/admin/system/emailmonitoring',
				menu_title: 'Email Monitoring',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/system/onboardingapiusage',
				menu_title: 'Onboarding API Usage',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			}
		]
	},
	{
		menu_title: 'System',
		menu_icon: 'ti-panel',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.SystemUser, roles.Marketing])
		},
		child_routes: [
			{
				path: '/admin/system/maintenancewindows',
				menu_title: 'Maintenance Windows',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/system/stories',
				menu_title: 'News Stories',
				showMethod: async () => {
					return Store.getters.isInRole(roles.Marketing)
				}
			},
			{
				path: '/admin/system/listhsm',
				menu_title: 'Manage HSM',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/system/sftprequests',
				menu_title: 'SFTP Requests',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/payerthreshold/emails',
				menu_title: 'Payer Threshold Emails',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/payments/bacsAdmin/bacsConfig',
				menu_title: 'BACS Config',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},

			{
				path: '/admin/system/ImportEISCD',
				menu_title: 'EISCD Import',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/system/ImportWeightTable',
				menu_title: 'Weight Table Import',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			},
			{
				path: '/admin/system/devops',
				menu_title: 'DevOps',
				showMethod: async () => {
					return Store.getters.isInRole(roles.SystemUser)
				}
			}
		]
	}
]
