<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <h3>Filename Variable<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/flow/filenamevariable/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <br/>
    <div class="alert alert-info" role="alert">
      Please note, all filenames and paths are case sensitive.
    </div>
    <label class="required" >Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" :disabled="!hasManageRole" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The filename field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required">Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFolderBrowser" :disabled="!hasManageRole" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br/>
    <div class="form-group">
      <label>Order Criteria</label>
      <br>
      <p-radio value="Name" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s3.value">Name</p-radio>
      <p-radio value="Creation" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s3.value">Creation</p-radio>
      <p-radio value="Last Access" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s3.value">Last Access</p-radio>
      <p-radio value="File Size" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s3.value">File Size</p-radio>
    </div>
    <br/>
    <div class="form-group">
      <label>Order Direction</label>
      <br>
      <p-radio value="First" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s4.value">First</p-radio>
      <p-radio value="Last" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.s4.value">Last</p-radio>
    </div>

    <!-- <br/>
    <div class="form-group">
      <label>Set Which Filename Variable</label>
      <br>
        <div class="form-group">
          <p-radio value="A" color="primary" v-model="selectedNode.props.s5.value">A</p-radio>
          <p-radio value="B" color="primary" v-model="selectedNode.props.s5.value">B</p-radio>
          <p-radio value="C" color="primary" v-model="selectedNode.props.s5.value">C</p-radio>
          <p-radio value="D" color="primary" v-model="selectedNode.props.s5.value">D</p-radio>
        </div>
    </div> -->

    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import roles from '@/Assets/Constants/roles'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser,
    fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s3: {
          value: {
            required
          }
        },
        s4: {
          value: {
            required
          }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      showFolderBrowser: false,
      showFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s3.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFileBrowser = true
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      console.log('Closed closeFilePopup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s2.value = filename
      console.log('falsey')
      let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      if (folder.endsWith('/') || folder.endsWith('\\')) {
        folder = folder.slice(0, -1)
      }
      this.selectedNode.props.s1.value = folder
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
