<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Mapping Log<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span><favourite-icon></favourite-icon></h2>
          <h6 v-if="trigger" class="pt-1">{{trigger.title}} <span class="text-muted"> - {{trigger.triggerType}} {{trigger.enabled}} </span></h6>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="telemtable"
              :lineNumbers="true"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick">
            <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'currentStatus'">
                  <b-badge pill v-if = "props.row.currentStatus == 'Failed'" variant="danger">{{props.row.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "props.row.currentStatus == 'Execution'" variant="success">{{props.row.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "props.row.currentStatus == 'Complete'" variant="success">{{props.row.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "props.row.currentStatus == 'Processing'" variant="info">{{props.row.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "props.row.currentStatus == 'Completed with warnings'" variant="warning">{{props.row.currentStatus}}</b-badge>
                  <b-badge pill v-else-if = "props.row.currentStatus == 'Manual Action'" variant="primary">{{props.row.currentStatus}}</b-badge>
                  <span v-else>{{props.row.currentStatus}}</span>
                </span>
                <span v-else-if = "props.column.field == 'createdAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'updatedAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <!-- <span v-else-if = "props.column.field == 'title'">
                  <b-button v-if="props.formattedRow.type =='Workflow'" @click.prevent="viewWorkflow(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
                  <b-button v-else-if="props.formattedRow.type =='Mapping'" @click.prevent="viewMapping(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
                </span> -->
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <div slot="table-actions">
              <button
                @click.prevent="clearTableFilters"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'">
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span><span class="sr-only">Clear filters</span>
              </button>
              <b-button @click.prevent="load" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the Workflow and Mapping Logs'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span></b-button>
            </div>
          </vue-good-table>
        </div>
        </div>
        <div class="idb-block-footer">
          <div class="row" >
            <div class="col-md-12 col-sm-3">
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
export default {
  name: 'WorkflowLogs',
  mixins: [tableFilterMixin],
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'executionId',
          field: 'executionId',
          hidden: true
        },
        {
          label: 'Instance',
          field: 'title',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type',
          hidden: true,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Status',
          field: 'currentStatus',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Created At',
          field: 'createdAt',
          hidden: false,
          inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
          outputFormat: 'DD-MM-YYYY HH:mm:ss'
        },
        {
          label: 'Last Updated At',
          field: 'updatedAt',
          hidden: false
        },
        {
          label: '',
          field: 'workflowId',
          hidden: true
        }
      ],
      row_data: [],
      trigger: undefined,
      totalRecords: 0,
      paginationOptions: { enabled: true, perPage: 10, dropdownAllowAll: false },
      serverParams: {
        sort: [{ field: 'createdAt', type: 'desc' }]
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    async load () {
      const postObj = {
        serverParams: this.serverParams,
        id: this.id
      }
      // console.log('id:', this.id)
      // if (this.id) {
      //   // get the trigger config
      //   this.getTrigger()
      // }

      this.isTableLoading = true
      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}triggertelemetry`, postObj)
        if (res) {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view log data.')
        } else {
          this.$snapbar.e(`Could not get logs - ${e.message}`)
        }
        this.row_data = []
        this.totalRecords = 0
      } finally {
        this.isTableLoading = false
      }
    },
    // async getTrigger () {
    //   // let res
    //   // try {
    //   //   res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.id}`, { showload: true })
    //   //   if (res && res.data && res.data.trigger) {
    //   //     this.trigger = res.data.trigger

    //   //     switch (this.trigger.triggerType) {
    //   //       case 'cronTrigger' :
    //   //         this.trigger.triggerType = 'Cron Trigger'
    //   //         break
    //   //       case 'scheduleTrigger' :
    //   //         this.trigger.triggerType = 'Schedule Trigger'
    //   //         break
    //   //       case 'fileTrigger' :
    //   //         this.trigger.triggerType = 'File Trigger'
    //   //         break
    //   //       default :
    //   //         this.trigger.triggerType = 'Unknown'
    //   //     }
    //   //     switch (this.trigger.enabled) {
    //   //       case true :
    //   //         this.trigger.enabled = '- Enabled'
    //   //         break
    //   //       case false :
    //   //         this.trigger.enabled = '- Disabled'
    //   //         break
    //   //       default :
    //   //         this.trigger.enabled = ''
    //   //     }

    //   //     if (!this.trigger.boundWorkflows) {
    //   //       this.trigger.boundWorkflows = []
    //   //     }
    //   //   } else {
    //   //     this.$snapbar.w('Unknown or invalid trigger.')
    //   //   }
    //   //   console.log(this.trigger)
    //   // } catch (e) {
    //   //   if (e.response && e.response.status === 403) {
    //   //     this.$snapbar.e('You are not authorised to view this Trigger.')
    //   //   } else {
    //   //     this.$snapbar.e(`Could not display this Trigger - ${e.message}`)
    //   //   }
    //   // }
    // },
    // viewTimeline (p) {
    //   // this.$router.push(`/automation/workflow/timeline/${p.formattedRow.executionId}`)
    // },
    viewTelemetry (p) {
      this.$router.push(`/automation/workflow/workflowtelemetry/${p.formattedRow.executionId}`)
    },
    viewWorkflow (p) {
      // this.$router.push(`/automation/workflow/workflowdetail/${p.formattedRow.workflowId}`)
    },
    viewMapping (p) {
      this.$router.push(`/automation/mapping/mappingdetail/${p.formattedRow.workflowId}`)
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    onRowClick (params) {

    },
    onCellClick (params) {
      if (params.column.field !== 'workflowId') {
        this.$router.push(`/automation/mapping/logging/mappinglog/${params.row.executionId}`)
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.load()
    }
  }
}
</script>
<style scoped>
  td {
    cursor: pointer;
  }
</style>
