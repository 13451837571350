<template>
  <div>
    <form @submit.prevent="!isLoading && validate()">
      <div class="idb-block">
        <div class="idb-block-title">Email Validation</div>
        <div class="idb-block-content">
          <div class="col-md-6">
        <div class="form-group">
          <label>Email Address</label>
          <div class="input-group mb-3">
            <input
              placeholder="Email Address"
              type="text"
              v-model="emailAddress"
              class="form-control"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <div v-if="!emailValidated && !emailValidating">
                  <i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
                </div>
                <div v-if="emailValidating" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-if="!emailValidating && emailValidated">
                  <i v-if="emailValid" class="fa fa-check text-success"></i>
                  <span v-if="emailValid" class="d-none">Valid</span>
                  <i v-if="!emailValid" class="fa fa-times text-danger"></i>
                  <span v-if="!emailValid" class="d-none">Invalid</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading || emailAddress === ''">Validate</button>
          <button
          type="button"
          class="btn btn-outline-primary ml-3"
          @click="clear"
          :disabled="isLoading"
          >Clear</button>
        </div>
      </div>
    </form>
    <bulkControl v-if="false" :upload-type="0" />
  </div>
</template>
<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import auth from '@/Assets/Components/Authentication/auth.js'
import bulkControl from '@/Components/Validate/Bulk/BulkUploadControl'
export default {
 name: 'copValidation',
 mixins: [ loading ],
  components: {
   bulkControl
  },
 data () {
  return {
   emailAddress: '',
   emailValid: false,
   emailValidated: false,
   emailValidating: false,
   loading: false,
   selectedFiles: [],
   showModalFileBrowser: false,
   rights: null,
   bearerToken: null,
   dropzoneOptions: {
    maxFilesize: 250, // MB
    maxFiles: 4,
    acceptedFiles: '.csv',
    dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a delimited file such as a CSV file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
    url: `${process.env.VUE_APP_VALIDATE_API_URL}email/upload`,
    title: 'Drag and drop to upload files',
    subtitle: '...or click to select a file from your computer'
   },
  }
 },
 async mounted () {
  this.dropzoneOptions.url = `${process.env.VUE_APP_VALIDATE_API_URL}email/upload`
  this.removeAllFiles()
  auth.getAccessToken().then(token => { this.bearerToken = 'Bearer ' + token })
 },
 methods: {
  async validate () {
   this.emailValidated = false
   this.emailValidating = true
   let request = { emailAddress: this.emailAddress }
   let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}email`, request)
   console.log(response.data)
   this.emailValid = response.data.validity === 'Valid'
   this.emailValidating = false
   this.emailValidated = true
  },
  clear () {
   this.emailAddress = ''
   this.emailValid = false
   this.emailValidating = false
   this.emailValidated = false
  },
  clear() {

  },
  removeAllFiles () {
    this.selectedFiles = []
  },
  fileAdded (file) {
    this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
  },
  fileRemoved (file, error, xhr) {

  },
  sendingEvent (file, xhr, formData) {
    formData.append('paygateId', this.$store.state.common.paygateId)
  },
  afterSuccess (file, response) {
  },
  addFile (file) {
    const fileObj = { type: this.rights.uploadType, path: '', fileName: file.filename, id: file.submissionId }
    this.selectedFiles.push(fileObj)
  },
 }
}
</script>
