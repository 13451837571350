<template>
  <div id="performanceTuning">
    <div v-show="performanceTuningLoaded">
      <div class="row form-group">
        <div class="col-md-2">Payments Per Block</div>
        <div class="col-md-3" :class="{invalid: $v.performanceTuning.paymentsPerBlock.$error}">
          <input
            type="number"
            class="form-control"
            v-model.trim="$v.performanceTuning.paymentsPerBlock.$model"
            style="width: 150px;"
          />
          <validation-messages
            v-model="$v.performanceTuning.paymentsPerBlock"
            name="number of Payments per block"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2">Bulk Copy Batch Size</div>
        <div class="col-md-3" :class="{invalid: $v.performanceTuning.bulkCopyBatchSize.$error}">
          <input
            type="number"
            class="form-control"
            v-model.trim="$v.performanceTuning.bulkCopyBatchSize.$model"
            style="width: 150px;"
          />
          <validation-messages
            v-model="$v.performanceTuning.bulkCopyBatchSize"
            name="Bulk copy batch size"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2">Deletion Block Size</div>
        <div class="col-md-3" :class="{invalid: $v.performanceTuning.deletionBlockSize.$error}">
          <input
            type="number"
            class="form-control"
            v-model.trim="$v.performanceTuning.deletionBlockSize.$model"
            style="width: 150px;"
          />
          <validation-messages
            v-model="$v.performanceTuning.deletionBlockSize"
            name="Deletion block size"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2">WebSigner Limit</div>
        <div class="col-md-3" :class="{invalid: $v.performanceTuning.webSignerLimit.$error}">
          <input
            type="number"
            class="form-control"
            v-model.trim="$v.performanceTuning.webSignerLimit.$model"
            style="width: 150px;"
          />
          <validation-messages
            v-model="$v.performanceTuning.webSignerLimit"
            name="WebSigner limit"
          />
        </div>
      </div>
      <br />
      <div class="row form-group">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="savePerformanceTuning"
          >Save Performance Tuning</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, numeric } from 'vuelidate/lib/validators'

export default {

  data () {
    return {
      performanceTuningLoaded: false,
      performanceTuning: {}
    }
  },

  methods: {
    async getPerformanceTuning () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getBacsEngineConfig?networkType=BacstelIp`)
      this.performanceTuning = response.data
      this.$store.dispatch('setPerformanceTuningData', this.performanceTuning)
      this.performanceTuningLoaded = true
    },

    async savePerformanceTuning () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/admin/savePerformanceTuning',
        data: this.performanceTuning
      })

      if (response.data.status === 'Updated') {
        this.$toastr.s(response.data.toastMessage)
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    }
  },

  validations () {
    return {
      performanceTuning: {
        paymentsPerBlock: { required, numeric },
        bulkCopyBatchSize: { required, numeric },
        deletionBlockSize: { required, numeric },
        webSignerLimit: { required, numeric }
      }
    }
  },

  async mounted () {
    this.performanceTuningLoaded = false
    this.performanceTuning = this.$store.getters.performanceTuningData
    if (this.performanceTuning === null || (Object.keys(this.performanceTuning).length === 0 && this.performanceTuning.constructor === Object)) {
      await this.getPerformanceTuning()
      this.$store.dispatch('setEiscdData', this.eiscd)
    }
    this.performanceTuningLoaded = true
  }
}
</script>
