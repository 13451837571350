<template>
  <div class="idb-right-panel" :class="sidebarOpen ? 'collapse-show' : 'collapse-hide'">
    <div class="custom-sidebar-pan">
      <div class="system-setting">
        <div class="setting-header">
          <i class="fa fa-cog"></i> Widget Settings
        </div>
        <form v-if="widget !== null" class="pt-10 m-10">
          <div class="form-group" v-if="widget.sizes.length > 1">
            <label class="col-form-label">Size</label>
            <select class="form-control" v-model="selectedSize" @change="updateSize">
              <option
                v-for="size in widget.sizes"
                :key="`${size.w}${size.h}`"
                :value="size"
              >{{ size.w }} x {{ size.h }}</option>
            </select>
          </div>
          <div
            class="form-group"
            v-for="option in widget.options"
            :key="`${widget.widgetid}${option.label}`"
          >
            <label class="col-form-label">{{option.label}}</label>
            <div v-if="option.type == 0">
              <input type="text" class="form-control" v-model="option.value" />
            </div>
            <div v-if="option.type == 1">
              <input type="number" :min="0" class="form-control" v-model="option.value" />
            </div>
            <div v-if="option.type == 2">
              <p-check
                name="check"
                class="p-switch"
                style="padding: 0.5rem 0;"
                color="primary"
                v-model="option.value"
              ></p-check>
            </div>
            <div v-if="option.type == 101">
              <b-form-select v-model="option.value" :options="groupTypes" multiple title="Group Type"></b-form-select>
            </div>
            <div v-if="option.type == 102">
              <b-form-select v-model="option.value" :options="bacsReportPeriods" title="Report Periods"></b-form-select>
            </div>
            <div v-if="option.type == 103">
              <b-form-select
                v-model="option.value"
                :options="bacsSelectDataBy"
                title="BACS Select Data By"
              ></b-form-select>
            </div>
            <div v-if="option.type == 104">
              <b-form-select
                v-model="option.value"
                :options="bacsGroups"
                title="Group"
                value-field="id"
                text-field="label"
              ></b-form-select>
            </div>
            <div v-if="option.type == 201">
              <b-form-select
                v-model="option.value"
                :options="ddmsGroups"
                title="DDMS Groups"
                value-field="id"
                text-field="label"
              ></b-form-select>
            </div>
            <div v-if="option.type == 202">
              <b-form-select
                v-model="option.value"
                :options="ddmsGroupsWithNull"
                title="DDMS Groups with all"
                value-field="id"
                text-field="label"
              ></b-form-select>
            </div>
            <div v-if="option.type == 203">
              <b-form-select v-model="option.value" :options="ddmsNumberRange" title="DDMS Number Range"></b-form-select>
            </div>
            <div v-if="option.type == 204">
              <b-form-select v-model="option.value" :options="ddmsReportPeriods" title="Report Periods"></b-form-select>
            </div>
            <div v-if="option.type == 301">
              <!-- Display available workflows for aa paygateId -->
              <b-form-select v-model="option.value" :options="availableWorkflows" title="Workflow"></b-form-select>
            </div>
          </div>
        </form>
        <div class="mt-auto buttons">
          <button class="btn btn-primary mr-10" @click="save">Save</button>
          <button class="btn btn-outline-secondary" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'WidgetSettings',
  computed: {
    ...mapGetters(['selectedCustomer', 'availableGroupTypes'])
  },
  watch: {
    widget: function (newWidget) {
      if (newWidget == null) {
        return
      }
      if (this.originalState !== null && newWidget.i === this.originalState.i) {
        return
      }

      this.originalState = JSON.parse(JSON.stringify(newWidget))
      this.selectedSize = { h: newWidget.h, w: newWidget.w }
    }
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      required: true
    },
    widget: {
      type: Object
    }
  },
  data () {
    return {
      originalState: null,
      selectedSize: null,
      groupTypes: ['All'],

      bacsReportPeriods: [{ value: 1, text: 'Current Day' }, { value: 2, text: 'Last 2 Days' }, { value: 7, text: 'Last Week' }, { value: 14, text: 'Last 2 Weeks' }, { value: 31, text: 'Last Month' }],
      bacsSelectDataBy: [{ value: 'Network', text: 'Network' }, { value: 'TxCount', text: 'TX Count' }, { value: 'TxAmount', text: 'TX Amount' }],
      bacsGroups: [],

      ddmsNumberRange: [6, 12, 18, 24, 36, 48, 60],
      ddmsReportPeriods: [{ value: 1, text: 'Current Day' }, { value: 2, text: 'Last 2 Days' }, { value: 7, text: 'Last Week' }, { value: 30, text: 'Last Month' }, { value: 30, text: 'All' }],
      ddmsGroups: [],
      ddmsGroupsWithNull: [],
      availableWorkflows: []
    }
  },
  async mounted () {
    await this.$store.dispatch('getGroupTypes')
    this.groupTypes.push(...this.availableGroupTypes)
    // Load ddms groups
    const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.selectedCustomer}/2`)
    this.ddmsGroups = response.data
    this.ddmsGroupsWithNull = [{ id: null, label: 'All' }, ...response.data]

    await this.loadBacsGroups()

    // Unfortunately this gets called whether you are using a workflow widget or not,
    // TODO: Re-add when workflow is added
    await this.getAvailableWorkflows()
  },
  methods: {
    save () {
      this.originalState = null
      this.$emit('close')
    },
    cancel () {
      this.widget.options = this.originalState.options
      this.widget.w = this.originalState.w
      this.widget.h = this.originalState.h
      this.$emit('resize', this.widget)
      this.originalState = null
      this.$emit('close')
    },
    updateSize () {
      this.widget.w = this.selectedSize.w
      this.widget.h = this.selectedSize.h
      if (this.widget.x === 3) {
        this.widget.x -= this.widget.w - 1
      } else {
        this.widget.x = this.originalState.x
      }
      this.$emit('resize', this.widget)
    },
    async getAvailableWorkflows () {
      try {
        const wfRes = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtitles`)
        const result = []
        wfRes.data.workflows.forEach((element) => {
          result.push({
            text: element.title,
            value: element._id
          })
        })
        this.availableWorkflows = result
      } catch (e) {
        console.log(e)
      }
    },
    async loadBacsGroups () {
      // BACSPGEGROUPTYPE: '0',
      // BACSPGOGROUPTYPE: '3',
      // FPSPGEGROUPTYPE: '4',
      // FPSPGOGROUPTYPE: '5',

      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.selectedCustomer}/0`)
      var bacsPgeGroups = response.data
      response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.selectedCustomer}/3`)
      var bacsPgoGroups = response.data
      response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.selectedCustomer}/4`)
      var fpsPgeGroups = response.data
      response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.selectedCustomer}/5`)
      var fpsPgoGroups = response.data

      var i = 0
      for (i = 0; i < bacsPgeGroups.length; i++) {
        this.bacsGroups.push(bacsPgeGroups[i])
      }

      for (i = 0; i < bacsPgoGroups.length; i++) {
        this.bacsGroups.push(bacsPgoGroups[i])
      }

      for (i = 0; i < fpsPgeGroups.length; i++) {
        this.bacsGroups.push(fpsPgeGroups[i])
      }

      for (i = 0; i < fpsPgoGroups.length; i++) {
        this.bacsGroups.push(fpsPgoGroups[i])
      }

      this.bacsGroups.sort((a, b) => a.label.localeCompare(b.label))
      this.bacsGroups = [{ id: '00000000-0000-0000-0000-000000000000', label: 'All' }, ...this.bacsGroups]
    }
  }
}
</script>
<style lang="scss" scoped>
.system-setting {
  flex-direction: column;
  display: flex;
}
.buttons {
  padding: 1rem;
}
</style>
