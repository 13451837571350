<template>
  <div>
    <h3>FTP / FTPS File Transfer<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <br/>
    <div class="alert alert-info" role="alert">
      Please note, all filenames and paths are case sensitive.
    </div>
    <div class="form-group">
      <label>Transfer Type</label>
      <br>
      <p-radio value="Upload" color="primary" v-model="selectedNode.props.s7.value">Upload</p-radio>
      <p-radio value="Download" color="primary" v-model="selectedNode.props.s7.value">Download</p-radio>
    </div>
    <br/>
    <div class="form-group">
      <label class="required" >Host FTP Server Address</label>
      <input type="url"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The Server Address field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Port</label>
      <input
        type="number"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.int1.value.$error}"
        @input="$v.selectedNode.props.int1.value.$touch()"
        v-model="selectedNode.props.int1.value">
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.required && $v.selectedNode.props.int1.value.$dirty ">The Port field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.minValue">Minimum Port value is {{ $v.selectedNode.props.int1.value.$params.minValue.min }}!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.maxValue">Maximum Port value is {{ $v.selectedNode.props.int1.value.$params.maxValue.max }}!</p>
    </div>
    <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b2.value">Use FTPS (TLS)</p-check>
    </div>
    <br/>
    <div class="form-group">
      <label class="">Local folder</label>
      <input type="text" class="form-control" v-model="selectedNode.props.s3.value">
    </div>
    <br/>
    <div class="form-group">
      <label class="required">File</label>
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value">
      <p class="validationText" v-if="!$v.selectedNode.props.s4.value.required && $v.selectedNode.props.s4.value.$dirty ">The File field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="">Remote folder</label>
      <input type="text" class="form-control" v-model="selectedNode.props.s2.value">
    </div>
    <br/>
    <div class="font-weight-bold">Authentication</div>
      <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value">Anonymous</p-check>
    </div>
    <br/>
    <transition name="fadein">
      <div v-if="!selectedNode.props.b1.value">
        <!-- <div class="form-group">
          <p-radio value="Password" color="primary" v-model="selectedNode.props.s8.value">Password</p-radio>
          <p-radio value="Key" color="primary" v-model="selectedNode.props.s8.value">Key</p-radio>
        </div> -->
        <!-- <br/> -->
        <div class="form-group">
          <label class="required">Username</label>
          <input
            type="text"
            class="form-control"
            v-model="selectedNode.props.s5.value">
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Password'">
          <label class="required">Password</label>
          <input type="password" class="form-control" v-model="selectedNode.props.pw1.value">
          <br/>
        </div>
        <!-- <div class="form-group" v-if="selectedNode.props.s8.value === 'Key'">
          <label class="required">Pvt Key Path</label>
          <input type="text" class="form-control" v-model="selectedNode.props.s6.value">
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Key'">
          <label class="required">Pvt Key PIN</label>
          <input type="password" class="form-control" v-model="selectedNode.props.pw2.value">
        </div> -->
      </div>
    </transition>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button variant="success" @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength, integer, minValue, maxValue } from 'vuelidate/lib/validators'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s4: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        int1: {
          value: {
            required,
            integer,
            maxValue: maxValue(65535),
            minValue: minValue(1)
          }
        }
      }
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s2.value = utils.sanitisePath(this.selectedNode.props.s2.value)
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      // this.selectedNode.props.s4.value = utils.sanitiseFilename(this.selectedNode.props.s4.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

.fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
