<template>
  <div class="idb-block mb-5">
    <div class="idb-block-title">
      <h2>
        Select Additional Files for Submission: {{submissionReference}}
        <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
      </h2>
    </div>

    <div v-show="useDropzone" class="idb-block-content">
      <b-card
        v-if="customerNoBankSelected"
        bg-variant="warning"
        text-variant="white"
        style="margin-bottom: 0.8em; margin-right: 0.8em"
      >This bureau job has associated customers with no bank details set. This bureau job will fail to import with the current configuration.</b-card>
      <b-card
        v-if="submissionLocked"
        bg-variant="warning"
        text-variant="white"
        style="margin-bottom: 0.8em;"
      >Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
      <b-row v-if="!submissionLocked">
        <b-col sm="12">
          <dropzone
            id="my-dropzone"
            ref="fileUploader"
            :options="dropzoneOptions"
            @vdropzone-success="afterSuccess"
            @vdropzone-error="afterError"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-sending="sendingEvent"
            :use-custom-slot="true"
            :max-file-size-in-m-b="200"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Drag and drop to add files to the Submission</h3>
              <button
                type="button"
                class="btn btn-link"
              >...or click to select a file from your computer</button>
            </div>
          </dropzone>
        </b-col>
      </b-row>
    </div>
    <div v-show="initRestrictedFileBrowser" class="idb-block-content">
      <b-card
        v-if="submissionLocked"
        bg-variant="warning"
        text-variant="white"
        style="margin-bottom: 0.8em;"
      >Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
      <div class="col-md-3">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="submissionLocked"
          v-on:click="showRestrictedFileBrowser"
        >Select Submission File</button>
      </div>
    </div>
    <div v-show="useFtpFileBrowser" class="idb-block-content">
      <b-card
        v-if="submissionLocked"
        bg-variant="warning"
        text-variant="white"
        style="margin-bottom: 0.8em;"
      >Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
      <div class="col-md-3">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="submissionLocked"
          v-on:click="btnFtpFileBrowser"
        >Select Submission File</button>
      </div>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        ref="table"
        class="table-unset"
        :paginationOptions="{
                        enabled: true,
                        perPage: 10,
                      }"
        :rows="submissionDetails"
        :columns="columns"
        :lineNumbers="true"
        :sort-options="{
                        enabled: true,
                        initialSortBy: {field: 'status', type: 'asc'}
                      }"
        styleClass="vgt-table striped bordered"
        @on-row-click="onRowClick"
        :rowStyleClass="rowStyler"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'bureauCustomerName'">
            <b-input-group>
              <model-list-select
                :id="props.row.bureauCustomerId"
                :list="BureauCustomersDisplay"
                v-model="props.row.bureauCustomerId"
                label="name"
                option-value="bureauCustomerId"
                option-text="name"
                placeholder="Select Customer"
                :isDisabled="props.row.status === 'Imported' || props.row.status === 'Error' || submissionLocked || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
                @input="updateBureauCustomer(props.row)"
                class="addFiles-tall-dropdown"
              ></model-list-select>
              <b-input-group-append>
                <a
                  class="btn btn-sm primary mt-1 reset-customer"
                  :hidden="props.row.status != 'Uploaded' || props.row.schema == 'Standard18'"
                  :disabled="isLoading"
                  v-if="!submissionLocked && (selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)"
                  @click="onResetSelectedCustomer(props.row)"
                >
                  <i class="fa fa-times"></i>
                </a>
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="(props.row.importSchema === 'Mapping' || props.row.importSchema === 'Legacy') && props.row.bureauCustomerId === '00000000-0000-0000-0000-000000000000'"
              class="text-danger"
            >Please select a customer</small>
          </span>
          <span v-else-if="props.column.field == 'schema'">
            <b-row>
              <b-col sm="12">
                <b-form-select
                  :id="'schemaSelector-'+props.row.bureauSubmissionDetailId"
                  :options="schemas"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.importSchema"
                  :disabled="props.row.status != 'Uploaded' || submissionLocked || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
                  placeholder="Schema"

                  class="addFiles-tall-dropdown schemaControl"
                ></b-form-select>
                <small
                  v-if="props.row.importSchema === 'None'"
                  class="text-danger"
                >Please select an import schema</small>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'mapping'">
            <b-row>
              <b-col sm="12">
                <model-list-select
                  :id="'mappingSelector-'+props.row.bureauSubmissionDetailId"
                  :list="mappings"
                  option-value="value"
                  option-text="text"
                  v-model="props.row.mapping"
                  class="addFiles-tall-dropdown"
                  placeholder="Mapping"
                  :isDisabled="props.row.status != 'Uploaded' || props.row.importSchema != 'Mapping' || submissionLocked || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"

                ></model-list-select>
                <small
                  v-if="props.row.importSchema === 'Mapping' && (props.row.mapping === null || props.row.mapping === '') && !isLoading"
                  class="text-danger"
                >Please select a mapping</small>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field == 'paymentDate'">
            <b-row>
              <b-col sm="12">
                <vuejsDatepicker
                  ref="props.row.paymentDate"
                  :name="'paymentDate-'+props.row.bureauSubmissionDetailId"
                  :id="'paymentDate-'+props.row.bureauSubmissionDetailId"
                  v-model="props.row.paymentDate"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  :disabledDates="disabledDates"
                  v-on:selected="paymentDateChanged(props.row)"
                  @focusin.native="dateOnfocusSE(this)"
                  :monday-first="true"
                  :disabled="props.row.status != 'Uploaded' || submissionLocked || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
                ></vuejsDatepicker>
              </b-col>
            </b-row>
          </span>

          <span v-else-if="props.column.field == 'status'">
            <span
              v-if="props.row.status === 'Error'"
              v-b-popover.hover.top.d500="'This file could not be imported'"
              class="w-100 badge badge-pill badge-danger"
            >Error</span>

            <span
              v-else-if="props.row.status === 'Imported'"
              v-b-popover.hover.top.d500="'This file was successfully imported'"
              class="w-100 badge badge-pill badge-success"
            >Imported</span>
            <span
              v-else-if="props.row.status === 'Uploaded'"
              v-b-popover.hover.top.d500="'This file was uploaded and is awaiting import'"
              class="w-100 badge badge-pill badge-info"
            >Uploaded</span>
          </span>

          <span v-else-if="props.column.field == 'delete'">
            <button :disabled="isLoading || submissionLocked || isLoading"
                    v-if="(selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)" @click.stop="onRowClick(props)"
                    class="btn w-100 mb-1 btn-primary btn-sm"><i class="fa fa-edit mr-2"></i>Edit</button>
            <button :disabled="isLoading || submissionLocked || isLoading"
                    v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)" @click.stop="updateSubmissionDetail(props.row)"
                    class="btn w-100 mb-1 btn-success btn-sm"><i class="fa fa-save mr-2"></i>Save</button>
            <button :disabled="isLoading || submissionLocked || isLoading"
                    v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)" @click.stop="cancelRowUpdate(props.row)"
                    class="btn w-100 mb-1 btn-warning btn-sm"><i class="fa fa-times mr-2"></i>Cancel</button>
            <button v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)"
              :disabled="isLoading || submissionLocked"
              class="btn btn-danger btn-sm"
              @click.stop="onDeleteFileClick(props.row)"
            >
              <i class="fa fa-trash mr-2"></i>Delete
            </button>
          </span>
          <span v-else>
            <b-row>
              <b-col sm="12" class="mt-2">{{ props.formattedRow[props.column.field] }}</b-col>
            </b-row>
          </span>
        </template>

        <div slot="table-actions">
          <button
            @click.prevent="clearTableFilters"
            class="btn btn-link"
            v-b-popover.hover.top.d500="'Clear filters'"
          >
            <span class="fa-stack" style="font-size: 10px;">
              <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
              <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              <span class="sr-only">Clear filters</span>
            </span>
            <span class="sr-only">Clear filters</span>
          </button>
          <b-button
            @click.prevent="load"
            class
            variant="link"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-sync pointer dimmedIcon"></i>
            <span class="sr-only">Refresh Table</span>
          </b-button>
        </div>
      </vue-good-table>
      <div class="d-block py-3">&nbsp;</div>
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-primary mr-2"
        @click="ImportAdditionalFiles"
        :disabled="noSchemaSelected || noMappingSelected || noImportableFiles || noCustomerSelected || customerNoBankSelected"
      >Import Additional Files</button>
      <button class="btn btn-primary ml-2" @click="onViewPaymentsClick()">View Submission Details</button>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
    <fileBrowser v-if="showFtpFileBrowser" @close="closeFileBrowserPopup()" :groupId="groupId">
      <h3 slot="header">Select Submission File</h3>
    </fileBrowser>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import swal from 'sweetalert2'
import _ from 'lodash'
// import SubmissionDetails from '@/Components/Bureau/BureauJob/JobSubmissionDetailsComponent.vue'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import colours from '@/Assets/Constants/colours'
import bacsCommon from '@/Lib/BacsCommon'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import plugin from '@/Assets/Components/UsbTokens/Plugin.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
var GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export default {
  components: {
    fileBrowser,
    plugin,
    Dropzone,
    ModelListSelect
  },
  mixins: [Utility, loading, tableFilterMixin, bacsMixin],
  props: {
    id: String
  },
  data () {
    return {
      selectedRow: { bureauSubmissionDetailId: null },
      submissionDetails: [], // Current files in submission
      SelectedBureauCustomerId: '',
      BureauCustomers: [],
      BureauCustomersDisplay: [],
      BUREAUGROUPTYPE: '1',

      submissionReference: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      groupId: '',
      mappings: [],
      // dropzone
      dropzoneOptions: {
        url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile',
        autoProcessQueue: true,
        addRemoveLinks: true,
        parallelUploads: 1,
        maxFiles: 50,
        maxFilesize: 500
      },
      BadFilesArray: [],
      // date picker
      disabledDates: {},
      processingDates: {},
      disableDatePicker: false,
      // table
      columns: [
        {
          label: 'Filename',
          field: 'paymentFilename',
          width: '250px',
          sortable: true
        },
        {
          label: 'Customer',
          field: 'bureauCustomerName',
          sortable: true
        },
        {
          label: 'Schema',
          field: 'schema',
          sortable: false,
          width: '150px'
        },
        {
          label: 'Mapping',
          field: 'mapping',
          width: '200px',
          sortable: false
        },
        {
          label: 'Payment Date', // this is the datepicker one
          field: 'paymentDate',
          sortable: false,
          width: '150px'
        },

        {
          label: 'Processing Date',
          field: 'processingDateDisplay',
          width: '170px',
          sortable: false
        },
        // {
        //   label: 'Blocks',
        //   field: 'blocks',
        //   sortable: false
        // },
        {
          label: 'Status',
          field: 'status',
          width: '100px'
        },
        {
          field: 'delete',
          sortable: false,
          width: '50px'
        }
      ],

      objectItem: {},
      submissionLocked: false,
      lockingUsername: '',
      lockedTime: '',
      lockClicked: false,
      fileCount: 0,
      fileQueue: 0,
      deviceType: 'Restricted File Browser',
      useRestrictedFileBrowser: false,
      useFtpFileBrowser: false,
      useDropzone: false,
      showFtpFileBrowser: false,
      pluginUpdateNotInstalled: false,
      createUser: {},
      RFB_Url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile_rfb/' + this.id,
      SecFTP_Url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile_secftp/' + this.id,
      browser: '',
      plugin: undefined,
      pluginLoaded: false,
      pluginResponse: {},
      selectedGroupId: '',
      schemas: [],
      isFps: false
    }
  },
  computed: {
    initRestrictedFileBrowser: function () {
      return this.useRestrictedFileBrowser === true && this.pluginUpdateNotInstalled === false
    },
    noSchemaSelected () {
      const c = this.fileCount

      const found = this.submissionDetails.find(x => x.importSchema === 'None')

      return found !== undefined && found !== null
    },
    noMappingSelected () {
      const found = this.submissionDetails.find(x => x.importSchema === 'Mapping' && (x.mapping === null || x.mapping === ''))
      return found !== undefined && found !== null
    },
    noImportableFiles () {
      const found = this.submissionDetails.find(x => x.status === 'Uploaded')
      return found === undefined || found === null
    },
    noCustomerSelected () {
      const found = this.submissionDetails.find(x => (x.importSchema === 'Mapping' || x.importSchema === 'Legacy') && x.bureauCustomerId === '00000000-0000-0000-0000-000000000000')
      return found !== undefined && found !== null
    },
    customerNoBankSelected () {
      const found = this.submissionDetails.filter(x => (x.importSchema === 'Legacy') && x.bureauCustomerId !== '00000000-0000-0000-0000-000000000000')
      let noBank = false
      if (found !== null && found !== undefined) {
        for (let i = 0; i < this.BureauCustomers.length; i++) {
          let foundCust = found.find(x => x.bureauCustomerId === this.BureauCustomers[i].bureauCustomerId)
          if (foundCust !== undefined && foundCust !== null) {
            if (foundCust.accountNumber === '' || foundCust.sortcode === '') {
              noBank = true
            }
          }
        }
      }
      return noBank
    }
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
    this.userId = this.$store.getters.getClaim('sub').value
    this.browser = bacsCommon.getBrowser()
    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
    await this.loadMappingsOnLoad()
  },
  async created () {
    await this.GetBureauLicenseCheck()
    // this.disabledDates = await this.setUpBACSDatePicker()
    await this.checkLock()
    await this.load()
    this.fileCount++
  },

  methods: {
    onRowClick (params) {
      console.log(params.row)
      if(this.selectedRow.bureauSubmissionDetailId !== params.row.bureauSubmissionDetailId) {
        if(this.selectedRow.bureauSubmissionDetailId === null) {
          this.selectedRow = params.row
        } else {
          this.$toastr.e('Please save or cancel your current changes before editing a new row')
        }
      }
    },
    rowStyler (row) {
      var cssClass = ''
      if (this.selectedRow.bureauSubmissionDetailId === row.bureauSubmissionDetailId) {
        cssClass = 'table-primary'
      }
      return cssClass
    },
    async cancelRowUpdate (row) {
      await this.loadManualSubmissionDetails()
      this.selectedRow = { bureauSubmissionDetailId : null }
    },
    load: _.debounce(async function () {
      await this.loadBureauCustomers()
      await this.loadManualSubmission()
      await this.loadManualSubmissionDetails()
      await this.getSelectedGroupDetails()
    }, 800),
    async getSelectedGroupDetails () {
      await this.$store.dispatch('getSubBacsGroup', this.groupId)
      this.bacsGroup = this.$store.getters.bacsGroup

      this.selectedGroupId = this.groupId
      // this.disableDatePicker = this.bacsGroup.importSchema === 'Standard18'

      this.createUser = this.bacsGroup.groupUserRights.find(i => i.userId === this.userId)
      if (this.createUser !== undefined && this.createUser !== null && this.createUser.uploadType === this.deviceType) { // 'Restricted File Browser'
        // Check for web extension and plugin...
        try {
          await this.$store.commit('setDeviceType', this.deviceType)
          await this.$store.dispatch('checkPlugin')
        } catch (error) {
          this.$toastr.w(error.message, 'Warning')
        }

        this.useRestrictedFileBrowser = true
        this.useFtpFileBrowser = false
        this.useDropzone = false
      } else if (this.createUser.uploadType === 'Secure Cloud Folder') {
        this.$store.commit('setModalPopupString', this.createUser.uploadPath)
        this.useFtpFileBrowser = true
        this.useRestrictedFileBrowser = false
        this.useDropzone = false
      } else {
        // Dropzone-browser dialog.
        this.useDropzone = true
        this.useRestrictedFileBrowser = false
        this.useFtpFileBrowser = false
      }
    },
    async checkLock () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/checklock/${this.id}`)
        if (response.data !== null && response.data.userId !== '00000000-0000-0000-0000-000000000000') {
          this.submissionLocked = true
          this.lockingUsername = response.data.userName
          this.lockedTime = response.data.sessionStarted
        } else {
          this.submissionLocked = false
        }
      } catch (e) {
        this.$toastr.e('Could not retrieve lock information on submission')
      }
    },

    // Loader Methods
    async loadManualSubmission () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmission/' + this.id)

        this.groupId = response.data.groupId
        this.submissionReference = response.data.submissionReference
        this.contraNarrative = response.data.contraNarrative
        this.paymentDate = response.data.paymentDate
        this.processingDate = response.data.processingDate
        this.isFps = response.data.paymentNetworkType === 1
        this.$set(this.columns[4], 'hidden', this.isFps)
        this.$set(this.columns[5], 'hidden', this.isFps)
      } catch (e) {

      }
    },

    async loadManualSubmissionDetails () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmissiondetails/' + this.id, { showload: true })

        this.submissionDetails = _.sortBy(response.data.data, 'status')
      } catch (error) {
        console.error('Error in UploadFiles.load')
        console.error(error)
      }
    },
    loadMappingsOnLoad: async function () {
      this.mappings = await this.loadMappings('bacs')
      this.schemas = this.loadSchemas()

      return true
    },
    loadBureauCustomers: async function () {
      try {
        // Load customers in job
        this.BureauCustomersDisplay = []
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureaucustomersforsubmission/', { showload: true })
        this.BureauCustomers = response.data

        var index = 0
        for (index = 0; index < this.BureauCustomers.length; ++index) {
          var current = {
            bureauCustomerId: this.BureauCustomers[index].bureauCustomerId,
            name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
            disabled: false
          }
          this.BureauCustomersDisplay.push(current)
        }
      } catch (error) {

      }
      return true
    },

    dateOnfocusSE (datePicker) {
      setTimeout((ev) => {
        datePicker.paymentDate.isOpen || datePicker.paymentDate.showCalendar(ev)
      }, 250)
    },
    // Form Edit Methods

    async onDeleteFileClick (row) {
      const swalResult = await swal.fire({
        title: 'Delete Submission File',
        text: 'Are you sure you want to delete the file: ' + row.paymentFilename + '.',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: row.bureauSubmissionId,
            fileNumber: row.fromFileNumber,
            itemId: row.bureauSubmissionDetailId
          }

          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteBlock', data, { showload: true })

          await this.loadManualSubmissionDetails()

          // Remove from grid
          // this.submissionDetails = this.submissionDetails.filter(function (submissionRow) {
          //   return submissionRow.bureauSubmissionDetailId !== row.bureauSubmissionDetailId
          // })
          // try {
          //   await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/updatelastmodified/${this.submissionId}`)
          // } catch (e) {
          //   console.log('Could not update last modified date')
          // }
        } catch (error) {
          console.error('Error in UploadFiles.onDeleteFileClick.DeleteSubmissionDetail')
          console.error(error)
        }
        this.$toastr.s('Removed file ' + row.paymentFilename, 'Bureau Submission')
      }
    },
    async paymentDateChanged (row) {
      // Assign the processing date to the row
      await this.$nextTick()
      var newDate = new Date(row.paymentDate)
      var valasjson = row.paymentDate.toJSON()
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + valasjson)
      row.processingDateDisplay = response.data
      //await this.updateSubmissionDetail(row)
    },

    async onResetSelectedCustomer (submissionDetail) {
      submissionDetail.bureauCustomerId = GUID_EMPTY
      submissionDetail.importSchema = 'None'
      submissionDetail.mapping = null
      //this.updateSubmissionDetail(submissionDetail)
    },

    async updateBureauCustomer (row) {
      // Go and get this bureau customer's default import
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getbureaucustomerdefaultimportschema/' + row.bureauCustomerId)
        if ((row.importSchema === null || row.importSchema === '' || row.importSchema === 'None') && response.data.importSchema !== 'None') {
          row.importSchema = response.data.importSchema
          if (row.importSchema === 'Mapping') {
            row.mapping = response.data.mapping
          }
        }
      } catch (e) {
      }
      //await this.updateSubmissionDetail(row)
    },

    // Submssion Management Methods
    async updateSubmissionDetail (row) {
      row.touched = true
      try {
        if (row.importSchema !== 'Mapping') {
          row.mapping = ''
        }

        var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/updatemanualbacssubmissiondetail', row, { showload: true })

        if (response.data.toastType === 2) {
          this.$toastr.Add({
            name: 'positiveChange',
            preventDuplicates: true,
            msg: response.data.toastMessage,
            type: 'success'
          })
          // this.$toastr.s(response.data.toastMessage)
        } else {
          // this.$toastr.e(response.data.toastMessage)
          this.$toastr.Add({
            name: 'negativeChange',
            preventDuplicates: true,
            msg: response.data.toastMessage,
            type: 'danger'
          })
        }
        await this.loadManualSubmissionDetails()
      } catch (error) {
        this.$toastr.e(`Failed to update ${row.paymentFilename}`, 'Update Manual Bacs Bureau Submission')
      }
      this.fileCount++

      this.selectedRow = { bureauSubmissionDetailId : null }
    },

    async ImportAdditionalFiles () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/importadditionalfiles/' + this.id, { showload: true })

        if (response.data.toastType === 2) {
          try {
            await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/updatelastmodified/${this.submissionId}`)
          } catch (e) {
            console.log('Could not update last modified date')
          }
          this.$toastr.s(response.data.toastMessage)

          this.$router.push({ name: 'UploadAdditionalFilesBacsImport', params: { id: this.id, startProcess: true } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e(`Manual BACS Bureau Submission ${this.id} failed ${error}`, 'Manual BACS Bureau Submission')
      }
    },

    async onViewPaymentsClick () {
      if (_.some(this.submissionDetails, o => o.status === 'Uploaded')) {
        // Fire off a swal
        const swalResult = await swal.fire({
          title: 'Uploaded Submission Files',
          html: 'There are one or more files that have been uploaded but not imported. Continuing will mean that these payments are not yet included in this submission. <br /> Are you sure you want to continue?',
          showCancelButton: true,
          icon: 'info',
          focusCancel: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })

        if (swalResult.isConfirmed) {
          this.$router.push({ name: 'PaymentDetails', params: { id: this.id } })
        } else { }
      } else { this.$router.push({ name: 'PaymentDetails', params: { id: this.id } }) }
    },

    // Dropzone Methods
    async RefreshAfterDropzoneQueue () {
      await this.$nextTick
      if (this.fileQueue === 0) {
        await this.loadManualSubmissionDetails()
        this.$refs.fileUploader.removeAllFiles(true)
        this.fileQueue = 0

        if (this.BadFilesArray.length > 0) {
          // prepare a swal

          var failedFiles = ''
          for (var i = 0; i < this.BadFilesArray.length; i++) {
            failedFiles += this.BadFilesArray[i]
          }

          var htmlText = '<p>The following files could not be uploaded.</p><ul>' + failedFiles + '</ul>'

          const swalResult = await swal.fire({
            title: 'Failed To Upload All Files',
            html: htmlText,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'OK'
          })
          if (swalResult.isConfirmed) {
            this.BadFilesArray = []
          }
        }
      }
    },

    async afterError (file) {
      await this.$nextTick()
      this.fileQueue--
      var errormessage = file.xhr.responseText
      var filename = file.upload.filename
      this.BadFilesArray.push(`<li class="text-left"> ${filename}: ${errormessage} </li>`)

      await this.RefreshAfterDropzoneQueue()
    },

    async afterSuccess (file, response) {
      await this.$nextTick()
      this.fileQueue--
      await this.RefreshAfterDropzoneQueue()
    },

    async fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
      this.fileCount++
      this.fileQueue++
    },
    fileRemoved (file, error, xhr) {
      this.fileQueue--
    },
    sendingEvent (file, xhr, formData) {
      formData.append('bureauSubmissionId', this.id)
    },
    dropZoneError (file, error, request) {
      this.$toastr.e('File upload ' + file.name + ' failed', 'Bureau Submission')
    },
    btnFtpFileBrowser () {
      this.showFtpFileBrowser = true
    },
    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    },

    async closeFileBrowserPopup () {
      this.showFtpFileBrowser = false
      var selectedFileName = this.$store.state.mapping.modalPopupString
      // Check user has selected a file.
      var i = selectedFileName.lastIndexOf('.')
      if (i >= 0) {
        var data = {
          bureausubmissionid: this.id,
          filename: selectedFileName
        }

        var response = await axios.post(this.SecFTP_Url, data, { showload: true })

        // var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getNewSubmissionId`)
        // var fileBrowserResponse = { submissionId: response.data, filename: selectedFileName }
        this.afterSuccessRFB(null, selectedFileName)
      }
    },
    // Restricted File Browser Methods

    showRestrictedFileBrowser () {
      if (this.browser === 'Firefox') {
        this.showRestrictedFileBrowserFirefox()
      } else if (this.browser === 'Chrome') {
        this.showRestrictedFileBrowserChome()
      } else if (this.browser === 'IE') {
        this.showRestrictedFileBrowserIE()
      }
    },

    showRestrictedFileBrowserChome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(
          process.env.VUE_APP_CHROME_EXT_ID,
          {
            PluginTask: 'UploadFromRestrictedFolder',
            StartDirectory: this.createUser.uploadPath,
            DirectoryLocked: this.createUser.directoryLocked,
            BearerToken: this.bearerToken,
            Url: this.RFB_Url
          }, (response) => {
            var pluginResponse = common.parsePluginResponse(response.response)
            if (pluginResponse.SelectedFileName !== null) {
              var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
              this.afterSuccessRFB(null, fileBrowserResponse)
            } else if (pluginResponse.ErrorMessage !== null) {
              this.$snapbar.e(pluginResponse.ErrorMessage)
            }
          })
        resolve()
      })
    },

    showRestrictedFileBrowserFirefox () {
      this.$store.dispatch('setWebExtPluginResponse', null)

      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          if (pluginResponse.SelectedFileName !== null) {
            var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
            this.afterSuccessRFB(null, fileBrowserResponse)
            resolve()
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
          }
        }, { once: true })

        // eslint-disable-next-line
        var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.createUser.uploadPath, DirectoryLocked: this.createUser.directoryLocked, BearerToken: this.bearerToken, Url: this.RFB_Url } })
        document.dispatchEvent(pluginEvent)
      })
    },

    showRestrictedFileBrowserIE () {
      this.plugin = document.PayGatePlugin
      var result = this.plugin.UploadFromRestrictedFolder(this.createUser.uploadPath, this.createUser.directoryLocked, this.bearerToken, this.RFB_Url, 1000)
      if (result.indexOf('Error') === -1) {
        var i = result.indexOf('_')
        var submissionId = result.substring(0, i)
        var filename = result.substring(i + 1)
        var response = { submissionId: submissionId, filename: filename }
        this.afterSuccessRFB(null, response)
      } else {
        this.$snapbar.e(result)
      }
    },
    async afterSuccessRFB (file, response) {
      this.$store.commit('setModalPopupString', this.createUser.uploadPath)
      await this.loadManualSubmissionDetails()
    }
  }
}

</script>

<style scoped>
.vdp-datepicker input {
  background-color: unset;
}
.addFiles-tall-dropdown {
  min-width: 150px !important;
  width: 90% !important;
}
.addFiles-tall-dropdown .visible {
  min-width: 150px;
  width: 100%;
  max-height: 200px !important;
}

.dark-mode * .ui.selection.dropdown,
.dark-mode * .ui.search.dropdown > input.search,
.dark-mode * .addFiles-tall-dropdown * {
  background-color: transparent;
}

.schemaControl {
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  font-size: 0.9em;
}

.schemaControl option {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  font-size: 0.9em;
}

.input-group-append { margin-left: -2px; }
</style>
