<template>
  <div>
    <folderBrowser v-if="showInputFolderBrowser" @close="showInputFolderBrowser = false; closeInputFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showInputFileBrowser" @close="showInputFileBrowser = false; closeInputFilePopup()">
    </fileBrowser>
    <folderBrowser v-if="showOutputFolderBrowser" @close="showOutputFolderBrowser = false; closeOutputFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showOutputFileBrowser" @close="showOutputFileBrowser = false; closeOutputFilePopup()">
    </fileBrowser>
    <h3>File Generator<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/fileoperations/filegenerator/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <br/>
    <div class="alert alert-info" role="alert">
      Please note, all filenames and paths are case sensitive.
    </div>
    <label class="required" >Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        :disabled="!hasManageRole"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" :disabled="!hasManageRole" @click.prevent="btnInputFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The filename field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.invalidChars">The filename contains invalid characters.</p>
    <br/>
    <label class="required" >Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnInputFolderBrowser" :disabled="!hasManageRole" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.invalidChars">The folder name contains invalid characters.</p>
    <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" :disabled="!hasManageRole" v-model="selectedNode.props.b1.value">Randomise the content each time the file is generated</p-check>
    </div>
    <br/>
    <div class="textarea" v-if="!selectedNode.props.b1.value">
      <div class="form-group">
        <label>Content</label>
        <textarea class="form-control"
          rows="4"
          cols="40"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s3.value.$error}"
          @input="$v.selectedNode.props.s3.value.$touch()"
          v-model="selectedNode.props.s3.value">
        </textarea>
      </div>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import roles from '@/Assets/Constants/roles'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations () {
    return {
      selectedNode: {
        props: {
          s1: {
            value: {
              required,
              maxLength: maxLength(128),
              invalidChars: () => {
                if (this.selectedNode.props.s1.value === '') {
                  return true
                }
                if (this.selectedNode.props.s1.value.match(/[:]/g)) {
                  return false
                } else {
                  return true
                }
              }
            }
          },
          s2: {
            value: {
              required,
              maxLength: maxLength(128),
              invalidChars: () => {
                if (this.selectedNode.props.s2.value === '') {
                  return true
                }
                if (this.selectedNode.props.s2.value.match(/[:/]/g)) {
                  return false
                } else {
                  return true
                }
              }
            }
          },
          s3: {
            value: {
              maxLength: maxLength(1024)
            }
          }
          // s4: {
          //   value: {
          //     required,
          //     maxLength: maxLength(128)
          //   }
          // },
          // s5: {
          //   value: {
          //     maxLength: maxLength(2048)
          //   }
          // }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      showInputFolderBrowser: false,
      showInputFileBrowser: false,
      showOutputFolderBrowser: false,
      showOutputFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnInputFileBrowser () {
      this.property = this.selectedNode.props.s2.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showInputFileBrowser = true
    },
    btnInputFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showInputFolderBrowser = true
    },
    // btnOutputFileBrowser () {
    //   this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
    //   this.showOutputFileBrowser = true
    // },
    btnOutputFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showOutputFolderBrowser = true
    },
    closeInputFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s2.value = filename
      let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      if (folder.endsWith('/') || folder.endsWith('\\')) {
        folder = folder.slice(0, -1)
      }
      this.selectedNode.props.s1.value = folder
    },
    closeInputFolderPopup () {
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    },
    closeOutputFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      // this.selectedNode.props.s4.value = filename
      // if (!this.selectedNode.props.s3.value) {
      //   let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
      //   if (folder.endsWith('/') || folder.endsWith('\\')) {
      //     folder = folder.slice(0, -1)
      //   }
      //   this.selectedNode.props.s3.value = folder
      // }
    },
    closeOutputFolderPopup () {
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
