<template>
  <div id="approveBanklineSubmission">
    <div v-show="actionLoaded === true && !canDeleteAction">
      <div>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="startApproveSubmission"
          :disabled="this.enableApprove === false"
        >Approve Submission</button>
        <button
          type="button"
          class="btn btn-danger pull-right"
          v-on:click="cancelSubmission"
          :disabled="this.enableApprove === false && this.canCancel === false"
        >Cancel Submission</button>
      </div>
    </div>
    <div v-show="canDeleteAction">
      <button
        type="button"
        class="btn btn-danger pull-right"
        v-on:click="deleteAction"
      >Delete Orphaned Action</button>
    </div>
    <div v-show="showSmsRequest" class="row form-group"></div>
    <div v-show="showSmsRequest">
      <div class="row form-group">
        <div class="col-md-2">
          <strong><label for="securityCode">Security Code</label></strong>
        </div>
        <div class="col-md-2">
          <input
            id="securityCode"
            ref="securityCodeFocus"
            name="securityCode"
            v-model="securityCode"
            class="form-control"
            @keyup.enter="verifySecurityCode"
          />
        </div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-light pull-right"
            v-on:click="verifySecurityCode"
            :disabled="this.verifyingCode === true"
          >Verify Code</button>
        </div>
      </div>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import groupTypes from '@/Assets/Constants/groupTypes'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  components: {
    plugin: Plugin
  },
  computed: {
    enableApprove: function () {
      return this.isLoaded === true && this.canProceed === true
    },
    actionLoaded () { return this.$store.getters.parentBanklineDataLoaded }
  },
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      isLoaded: false,
      banklineGroup: {},
      password: '',
      canProceed: true,
      canCancel: true,
      canDeleteAction: false,
      browser: '',
      deviceType: 'USB Token',
      pluginUpdateNotInstalled: false,
      securityCode: '',
      showSmsRequest: false,
      verifyingCode: false
    }
  },
  methods: {
    async startApproveSubmission () {
      this.canProceed = false

      // First check that the action can proceed i.e. someone else hasn't got in there first and cancelled the submission or something!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Approved'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        if (this.banklineGroup.groupTypeDetails.paymentApprovalMethod === 'Default') {
          await this.approveSubmission()
        } else {
          if (this.banklineGroup.groupTypeDetails.approvalMethod === 'USB Token') {
            // Check for web extension and plugin...
            try {
              await this.$store.commit('setDeviceType', this.deviceType)
              await this.$store.dispatch('checkPlugin')
            } catch (error) {
              this.$toastr.w(error.message, 'Warning')
            }

            this.getUsbTokenThumbprint()
          } else if (this.banklineGroup.groupTypeDetails.approvalMethod === 'SMS') {
            this.requestSecurityCode()
          } else if (this.banklineGroup.groupTypeDetails.approvalMethod === 'Email') {
            this.emailSecurityCode()
          } else if (this.banklineGroup.groupTypeDetails.approvalMethod === 'Authenticator') {
            this.showSmsRequest = true
          } else if (this.banklineGroup.groupTypeDetails.approvalMethod === 'Password') {
            var pwdValid = await this.checkPassword()
            if (pwdValid) {
              await this.approveSubmission()
            } else {
              // this.$toastr.e('Invalid approval password')
              this.canProceed = true
              this.canCancel = true
            }
          } else {
            await this.approveSubmission()
          }
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    async checkPassword () {
      this.password = await this.enterApprovalPassword()

      var json = JSON.stringify({
        userPin: this.password,
        groupId: this.banklineGroup.groupId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/checkApprovalPassword',
        data: json,
        showload: true
      })

      if (!response.data.success) {
        this.$snapbar.e(response.data.errorMessage)
        this.unlockSubmission()
      }
      return response.data.success
    },

    async enterApprovalPassword () {
      var enteredPassword = ''

      await this.$swal({
        title: 'Approval Password',
        type: 'info',
        animation: false,
        input: 'password',
        inputPlaceholder: 'Approval Password'
      }).then((result) => {
        enteredPassword = JSON.stringify(result)
      })

      return enteredPassword
    },

    getUsbTokenThumbprint () {
      if (this.browser === 'IE') {
        this.plugin = document.PayGatePlugin
        this.usbTokenCertificateThumbprint = this.plugin.GetCertificateThumbprint('eTPKCS11.dll')
        if (this.usbTokenCertificateThumbprint.indexOf('Error') === -1) {
          this.approveWithUsbToken()
        } else {
          this.$snapbar.e(this.usbTokenCertificateThumbprint)
          this.unlockSubmission()
          this.canCancel = true
          this.canProceed = true
        }
      } else if (this.browser === 'Firefox') {
        return new Promise((resolve, reject) => {
          document.addEventListener('getPluginResponseWebPage', (response) => {
            var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.approveWithUsbToken()
              resolve()
            } else {
              this.$snapbar.e(pluginResponse.ErrorMessage)
              this.unlockSubmission()
              this.canProceed = true
              this.canCancel = true
              reject(new Error(pluginResponse.ErrorMessage))
            }
          }, { once: true })
          // eslint-disable-next-line
          var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' } })
          document.dispatchEvent(pluginEvent)
        })
      } else if (this.browser === 'Chrome') {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line
          chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' }, (response) => {
            var pluginResponse = common.parsePluginResponse(response.response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.approveWithUsbToken()
            } else if (pluginResponse.ErrorMessage !== null) {
              this.$snapbar.e(pluginResponse.ErrorMessage)
              this.unlockSubmission()
              this.canProceed = true
              this.canCancel = true
            }
          })
          resolve()
        })
      }
    },

    async approveSubmission () {
      this.canProceed = false
      this.canCancel = false

      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        createAction: true,
        deletePaymentData: this.banklineGroup.groupTypeDetails.deletePaymentData
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BANKLINE_API_URL + 'bankline/submission/approve',
        data: json,
        showload: true
      })

      if (response.data.errorMessage !== null) {
        this.$snapbar.e(response.data.errorMessage)
        this.canProceed = !response.data.success
        this.canCancel = !response.data.success
        this.showSmsRequest = false
        this.securityCode = ''
        return
      }

      // Check whether user can process the send action. If so, then redirect to that action.
      let nextActionResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getAction?submissionId=${this.submissionId}`)
      if (nextActionResponse.data.tag) {
        this.navigateToNextPage(nextActionResponse)
      } else {
        const actionLoadedFromPath = this.$store.getters.actionLoadedFromPath
        if (actionLoadedFromPath === '/actions') {
          this.$router.push('/actions')
        } else {
          this.$router.push('/')
        }
      }
    },

    async approveWithUsbToken () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        thumbprint: this.usbTokenCertificateThumbprint,
        signAction: false
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifyUsbTokenCertificate',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        await this.approveSubmission()
      } else {
        this.$snapbar.e(response.data.errorMessage)
        await this.unlockSubmission()
        this.canCancel = true
        this.canProceed = true
      }
    },

    async requestSecurityCode () {
      this.canProceed = false
      this.canCancel = false
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/requestSecurityCode`, { showload: true })
      if (response.data.success) {
        this.showSmsRequest = true
        this.$nextTick(() => {
          this.$refs.securityCodeFocus.focus()
        })
      } else {
        this.$snapbar.e(response.data.errorMessage)
        await this.unlockSubmission()
        this.canProceed = true
        this.canCancel = true
      }
    },

    async verifySecurityCode () {
      this.verifyingCode = true
      this.canProceed = false
      this.canCancel = false

      if (this.banklineGroup.groupTypeDetails.approvalMethod === 'SMS' || this.banklineGroup.groupTypeDetails.approvalMethod === 'Email') {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          securityCode: this.securityCode,
          actionSubClass: 'Approve'
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifySecurityCode',
          data: json,
          showload: true
        })

        if (response.data.success === true) {
          await this.approveSubmission()
        } else {
          this.verifyingCode = false
          this.$snapbar.e(response.data.errorMessage)
          if (response.data.tag !== 'Invalid') {
            await this.unlockSubmission()
            this.showSmsRequest = false
            this.securityCode = ''
            this.canCancel = true
            this.canProceed = true
          }
        }
      } else {
        this.authenticatorSecurityCode()
      }
    },

    async emailSecurityCode () {
      this.canProceed = false
      this.canCancel = false

      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/requestSecurityCodeViaEmail`, { showload: true })
      if (response.data.success) {
        this.showSmsRequest = true
      } else {
        this.$snapbar.e(response.data.errorMessage)
        this.canProceed = true
        this.canCancel = true
      }
    },

    async authenticatorSecurityCode () {
      this.canProceed = false
      this.canCancel = false
      var json = JSON.stringify({
        Code: this.securityCode,
        Provider: 'Authenticator'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_PLATFORM_API_URL + 'user/confirmConfirmCode',
        data: json,
        showload: true
      })

      if (response.data === true) {
        await this.approveSubmission()
      } else {
        this.verifyingCode = false
        this.$snapbar.e('Incorrect security code, please try again')
        await this.unlockSubmission()
        this.showSmsRequest = false
        this.securityCode = ''
        this.canCancel = true
        this.canProceed = true
      }
    },

    async cancelSubmission () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Approved'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        this.isLoaded = false
        var cancelReponse = await bacsCommon.cancelSubmissionDialog()
        if (cancelReponse.cancel === true) {
          json = JSON.stringify({
            submissionId: this.submissionId,
            actionId: this.actionId,
            deletePaymentData: this.banklineGroup.groupTypeDetails.deletePaymentData,
            additionalInfo: cancelReponse.cancellationReason
          })

          var response = await axios.post(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/submission/cancelSubmission/`, json, { showload: true })
          if (response.data.length === 0) {
            this.$toastr.s('Bankline submission cancelled')
          } else {
            this.$toastr.e(response.data)
          }
          this.$router.push('/actions')
        } else {
          var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
          await axios.get(endTaskUrl)
          this.isLoaded = true
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    downloadMsi () {
      bacsCommon.downloadPayGatePluginMsi(axios)
    },

    async waitForParent () {
      var looping = true
      while (looping) {
        if (this.actionLoaded === false) {
          await new Promise(resolve => setTimeout(resolve, 500))
        } else {
          looping = false
        }
      }
    },

    navigateToNextPage (response) {
      if (response.data.tag) {
        var routeJson = {}
        routeJson.id = response.data.messageId
        routeJson.actionedObjectId = this.submissionId
        routeJson.actionClass = 'Bankline'
        routeJson.actionSubClass = 'Send'
        this.$router.replace(returnRouteObject(routeJson))
      } else {
        this.$router.push('/')
      }
    },

    async doesUserHaveToken () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/doesUserHaveToken')
      if (!response.data.success) {
        this.$snapbar.w('You are not assigned to a USB token, please contact your system administrator')
        this.canProceed = false
      }
    },

    async doesUserHaveMobileNumber () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/doesUserHaveMobileNumber')
      if (!response.data.success) {
        this.$snapbar.w('You do not have a mobile phone number configured, please contact your system administrator')
        this.canProceed = false
      }
    },

    pluginNotInstalled () {
      var pluginUpdateRequired = this.$store.getters.setPluginUpdateRequired
      this.pluginUpdateNotInstalled = pluginUpdateRequired === undefined ? false : pluginUpdateRequired
      this.canCancel = true
      this.canProceed = true
    },

    async checkBanklineSubmission () {
      var today = new Date()
      var todayDateFormatted = moment(today).format('YYYY-MM-DD')
      var outOfDateSubmission = false
      var banklineSubmission = this.$store.getters.banklineSubmission
      var paymentDate = moment(banklineSubmission.createdDate).format('YYYY-MM-DD')

      if (todayDateFormatted > paymentDate) {
        outOfDateSubmission = true
      }

      if (outOfDateSubmission) {
        this.canProceed = false
        this.$snapbar.e('This submission cannot be sent to Bankline because the date for this Faster Payment submission has passed.')
      }
    },

    async unlockSubmission () {
      var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
      await axios.get(endTaskUrl)
    },

    async deleteAction () {
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.actionId
      await axios.delete(url)
      this.$router.push('/actions')
    }
  },

  async mounted () {
    await this.waitForParent()
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.banklineGroup = this.$store.getters.bacsGroup

    var submitUser = this.banklineGroup.groupUserRights.find(i => i.submit === true)
    if (submitUser === undefined) {
      this.$snapbar.w('There are no users who can send this submission to Bankline.')
    }

    if (this.banklineGroup.groupTypeDetails.paymentApprovalMethod !== 'Default') {
      if (this.banklineGroup.groupTypeDetails.approvalMethod === 'USB Token') {
        await this.doesUserHaveToken()
      } else if (this.banklineGroup.groupTypeDetails.approvalMethod === 'SMS') {
        await this.doesUserHaveMobileNumber()
      }
    }

    await this.checkBanklineSubmission()

    window.setTimeout(() => {
      this.isLoaded = true
    }, 1000)

    this.browser = bacsCommon.getBrowser()

    var extLoaded = this.$route.query.pluginAccessible
    if (extLoaded === 'true') {
      if (this.browser === 'Firefox') {
        document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
        document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
      }

      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true

      this.newPluginRequired = bacsCommon.isLatestPlugin(this.componentId, this.expectedVersion, this.deviceType)
      if (this.newPluginRequired === false) {
        this.startApproveSubmission()
      } else {
        this.pluginCheckComplete = false
        this.$toastr.w('Updated PayGate Plugin has not been installed so cannot use the ' + this.deviceType)
      }
    }

    this.isLoaded = true
  },

  destroyed () {
    if (this.browser === 'Firefox') {
      document.removeEventListener('getPluginResponseWebPage', this.initPluginEvent)
      document.removeEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
    }
  }
}
</script>
