<template>
  <div id="downloadBureauReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              <h2>
                Download {{ this.networkName }} Reports
                <help-icon :docPath="'/bacsbureau/reports/'"></help-icon>
                <favourite-icon></favourite-icon>
              </h2>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Select a Group</strong>
              </div>
              <div class="col-sm-3">
                <group-select
                  v-model="groupId"
                  :groups="bureaugroups"
                  @input="changeGroup()"
                  :clearable="false"
                  :disabled="this.isLoggedIn"
                ></group-select>
              </div>

              <div v-if="isLoggedIn === false" class="row form-group">
                <div class="col-sm-3">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="loginStart"
                    :disabled="this.enableLogin === false"
                  >Login</button>
                </div>
                <div class="col-md-1">
                  <span style="font-size: 200%" class="fa fa-unlock"></span>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-light"
                    v-on:click="viewVocalinkCertificate"
                    :disabled="this.enableLogin === false"
                  >Vocalink Certificate</button>
                </div>
              </div>
              <div v-else>
                <div class="row form-group">
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-primary"
                      v-on:click="logoff"
                      :disabled="this.loggingIn === true"
                    >Log Off</button>
                  </div>
                  <div class="col-md-3">
                    <span style="font-size: 200%" class="fa fa-lock"></span>&nbsp;&nbsp;Securely logged into Vocalink
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="btn btn-light"
                      v-on:click="viewVocalinkCertificate"
                      :disabled="this.loggingIn === true"
                    >Vocalink Certificate</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="BureauCustomersLoaded === true && isLoggedIn === true"
            >
              <div class="row form-group">
                <div class="col-md-2">Bureau Customers</div>
                <div class="col-md-3">
                  <vue-select
                    multiple
                    v-model="SelectedBureauCustomers"
                    label="name"
                    :options="BureauCustomersDisplay"
                    :closeOnSelect="false"
                    @input="CheckBureauCustomerDropdownEntries"
                    :clearSearchOnBlur="()=>{ return true }"
                  ></vue-select>
                </div>
              </div>
            </div>

            <br />
            <div v-if="isLoggedIn === true">
              <div class="row form-group">
                <div class="col-md-2">Report Types</div>
                <div class="col-md-3">
                  <b-select v-model.trim="selectedReportType" :options="reportTypes" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">Report Period</div>
                <div class="col-md-3">
                  <b-select
                    v-model.trim="selectedReportPeriodValue"
                    :options="reportPeriods"
                    @change="reportPeriodChanged"
                  />
                </div>

                <div class="col-md-1" v-if="!disableDatePicker">Select Date</div>
                <div class="col-md-3" v-if="!disableDatePicker">
                  <vuejsDatepicker
                    ref="calendarDate"
                    name="calendarDate"
                    id="calendarDate"
                    v-model="calendarDate"
                    format="dd/MM/yyyy"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    :disabledDates="disabledDates"
                    v-on:selected="calendarDateChanged"
                    :monday-first="true"
                    :disabled="disableDatePicker"
                  ></vuejsDatepicker>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">Previously Accessed</div>
                <div class="col-md-3">
                  <b-select v-model.trim="selectedReportAccessed" :options="reportAccessed" />
                </div>
              </div>
              <br />
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="getReportList"
                    :disabled="this.enableGetReportList === false"
                  >Get Report List</button>
                </div>
              </div>
              <div v-if="haveReportList === true" class="row form-group">
                <div class="col-md-12">
                  <vue-good-table
                    ref="reportList"
                    @on-per-page-change="onPerPageChange"
                    :columns="columns"
                    :rows="rows"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'report-row-selected',
                      clearSelectionText: ''
                    }"
                    :paginationOptions="paginationOptions"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'buttons'">
                        <b-dropdown right variant="outline-primary" split text="Options" class="m-2">
                          <b-dropdown-item v-if="showCsvOption(props)" @click.prevent="viewReportModal(props, 'CSV')">CSV</b-dropdown-item>
                          <b-dropdown-item v-if="showHtmlOption(props)" @click.prevent="viewReportModal(props, 'HTML')">HTML</b-dropdown-item>
                          <b-dropdown-item v-if="showPdfOption(props)" @click.prevent="viewReportModal(props, 'PDF')">PDF</b-dropdown-item>
                          <b-dropdown-item v-if="showXmlOption(props)" @click.prevent="viewReportModal(props, 'XML')">XML</b-dropdown-item>
                        </b-dropdown>
                      </span>
                      <span v-if="props.column.field === 'viewReportXml'">
                        <span class="grid-link">XML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportHtml'">
                        <span class="grid-link">HTML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportCsv'">
                        <span class="grid-link">CSV</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportPdf'">
                        <span class="grid-link">PDF</span>
                      </span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <div slot="selected-row-actions" style="width: 1200px;">
                      <div class="row">
                        <div v-if="!showDownloadWarning" class="col-md-6"></div>
                        <div v-else class="col-md-6" style="color: #DD6B55;">
                          {{ downloadWarningText }}
                        </div>
                        <div class="col-md-2">Download Format</div>
                        <div class="col-md-2">
                          <b-select
                            v-model.trim="selectedDownloadFormat"
                            :options="downloadFormats"
                          />
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="btn btn-primary pull-right"
                            v-on:click="downloadReports"
                            :disabled="this.selectedDownloadFormat === ''"
                          >Download</button>
                        </div>
                      </div>
                    </div>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="haveReportList === true" class="idb-block-footer">
            <button
              type="button"
              class="btn btn-primary mr-2"
              v-on:click="downloadAllReports('XML')"
              :disabled="downloadClicked"
            >Download All XML</button>
            <button v-show="anyNonDdicReports"
              type="button"
              class="btn btn-primary mr-2"
              v-on:click="downloadAllReports('HTML')"
              :disabled="downloadClicked"
            >Download All HTML</button>
            <button v-show="anyDdicReports"
              type="button"
              class="btn btn-primary mr-2"
              v-on:click="downloadAllReports('PDF')"
              :disabled="downloadClicked"
            >Download All PDF</button>
            <button v-show="anyDdicReports"
              type="button"
              class="btn btn-primary mr-2"
              v-on:click="downloadAllReports('CSV')"
              :disabled="downloadClicked"
            >Download All CSV</button>
            <span
              class="ml-2"
              v-if="downloadClicked"
            >Your file is being prepared, and will automatically download when ready.</span>
            <span
              class="ml-2"
              v-if="downloadClicked && showDownloadWarning"
            >{{ downloadWarningText }}</span>
          </div>
        </div>
      </div>
    </div>

    <div id="eSigner" v-show="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <div id="websignerContent" v-if="showWebSigner">
      <span v-html="webSignerHtml"></span>
    </div>
    <div>
      <viewReportModal
        v-model="isViewReportModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import ViewReportModal from '@/Views/Bacs/BacsReports/ViewReportModal.vue'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import VueSelect from 'vue-select'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import _ from 'lodash'
import auth from '@/Assets/Components/Authentication/auth'
import Ca from 'vuejs-datepicker/dist/locale/translations/ca'
import supportLinkMixin from '@/Assets/Mixins/SupportLinkMixin'
import swal from 'sweetalert2'

export default {
  mixins: [
    supportLinkMixin
  ],
  components: {
    plugin: Plugin,
    viewReportModal: ViewReportModal,
    VueSelect
  },
  data () {
    return {
      // loading: true,
      groups: [],
      group: {},
      groupId: null,
      bureaugroups: [], // My new one
      networkType: '',
      networkName: '',
      paygateId: '',
      browser: '',
      BUREAUGROUPTYPE: '1',
      vocalinkCertificate: {},
      // Bureau customer selection
      BureauCustomersLoaded: false,
      SelectedBureauCustomers: [],
      BureauCustomers: [],
      BureauCustomersDisplay: [],

      // Calendar Variables
      calendarDate: '',
      disableDatePicker: true,
      selectedReportPeriodValue: '',

      // Report Specific Variables
      reportTypes: {},
      reportPeriods: {},
      reportAccessed: {},
      downloadFormats: {},
      allDownloadFormats: {},
      selectedReportType: '',
      selectedReportPeriod: '',
      selectedReportAccessed: '',
      selectedDownloadFormat: '',
      selectedRetrievalUrl: '',
      eSignerHtml: '',
      selectedReportFormat: 0,
      haveReportList: false,
      isViewReportModalVisible: false,
      showeSigner: false,
      reportContent: '',
      reportDownload: {},
      // Table variables - may need to change as just nicked em
      columns: [
        {
          label: 'Report Name',
          field: 'reportName'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Generated',
          field: 'generationTimestamp',
          formatFn: this.formatDate
        },
        {
          label: 'Processing Date',
          field: 'processingDay',
          formatFn: this.formatDate
        },
        {
          label: 'Previously Accessed',
          field: 'previouslyAccessed',
          formatFn: this.formatPreviouslyAccessed
        },
        {
          label: 'Retrieval Url',
          field: 'retrievalUrl',
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportXml',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportHtml',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportCsv',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportPdf',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          hidden: false
        }
      ],
      rows: [],
      // Login to Vocalink Variables
      isLoggedIn: false,
      loggingIn: false,
      isLoaded: false,
      base64Signature: '',
      licenceSetting: '',
      canProceed: true,
      pluginUpdateNotInstalled: false,
      authResponse: '',
      userPin: '',
      reportDownloadConnection: null,
      downloadClicked: false,
      ddicReportTypes: ['1225', '1226', '1227'],
      downloadWarningText: 'Not all available reports can be downloaded in the same format. Incompatible selected reports cannot be downloaded.',
      processingDates: {},
      nonProcessingDates: [],
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false
      },
      currentPerPage: 10,
      webSignerHtml: '',
      showWebSigner: false,
      useWebSigner: false
    }
  },
  watch: {
    isLoggedIn: function () {
      if (this.isLoggedIn === true) {
        this.getBureauCustomers()
      }
    },
    SelectedBureauCustomers: function () {

    }
  },

  async created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    const token = await auth.getAccessToken()
    this.hubToken = 'Bearer ' + token
    this.reportDownloadConnection = new HubConnectionBuilder().withUrl(`${process.env.VUE_APP_BUREAU_API_URL}hubs/bacsdownloadhub`, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
    this.reportDownloadConnection.serverTimeoutInMilliseconds = 360000
    this.reportDownloadConnection.start()
    this.reportDownloadConnection.on('FileAvailable', async (data) => {
      // Download file
      this.$toastr.s('The file is ready for download.')
      await this.downloadFileById(data.fileId.fileid)
      this.downloadClicked = false
    })
    this.reportDownloadConnection.on('FileFailed', async (data) => {
      // Download file
      this.$toastr.e('Failed to download files.')
      this.downloadClicked = false
    })
  },
  computed: {
    disabledDates: function () {
      const maxDate = new Date()
      const minDate = new Date()

      maxDate.setDate(maxDate.getDate())
      maxDate.setUTCHours(23, 59, 59, 999)
      minDate.setMonth(minDate.getMonth() - 1)

      return {
        days: [0, 6],
        dates: this.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    },

    enableLogin: function () {
      return this.isLoaded === true &&
        this.groupId !== '' &&
        this.loggingIn === false &&
        this.canProceed === true &&
        this.pluginUpdateNotInstalled === false
    },

    enableGetReportList: function () {
      return this.selectedReportType !== '' &&
        this.selectedReportPeriod !== '' &&
        this.selectedReportAccessed !== ''
      //        && this.isLoading === false
      // this.loading === false
    },

    showDownloadWarning: function () {
      const anyDdicReports = this.rows.some(a1 => this.ddicReportTypes.includes(a1.reportType))
      const anyNonDdicReports = this.rows.some(a1 => !this.ddicReportTypes.includes(a1.reportType))
      return anyDdicReports && anyNonDdicReports
    },

    anyDdicReports: function () {
      const anyDdicReports = this.rows.some(a1 => this.ddicReportTypes.includes(a1.reportType))
      return anyDdicReports
    },
    anyNonDdicReports: function () {
      const anyNonDdicReports = this.rows.some(a1 => !this.ddicReportTypes.includes(a1.reportType))
      return anyNonDdicReports
    }
  },
  methods: {
    calendarDateChanged () {
    },

    reportPeriodChanged () {
      if (this.selectedReportPeriodValue === 'specificday' || this.selectedReportPeriodValue === 'specificdaysince') {
        this.selectedReportPeriod = 0
        // show the calendar
        this.disableDatePicker = false
      } else {
        this.selectedReportPeriod = this.selectedReportPeriodValue
        this.disableDatePicker = true
        this.calendarDate = ''
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    formatPreviouslyAccessed (field) {
      if (field) { return 'Yes' } else { return 'No' }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    stopSocketListener () {
      this.reportDownloadConnection.stop()
    },
    CheckBureauCustomerDropdownEntries (value) {
      var p = this.SelectedBureauCustomers.length - 1
      var bureauCustomerIdAdded = this.SelectedBureauCustomers[p].id
      var NonCustomerItem = this.SelectedBureauCustomers[p].nonCustomerItem

      if (NonCustomerItem) {
        this.SelectedBureauCustomers = this.SelectedBureauCustomers.slice(this.SelectedBureauCustomers.length - 1)
        // disable all other entries
        var i = 0
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === bureauCustomerIdAdded) {
            this.BureauCustomersDisplay[i].disabled = false
          } else {
            this.BureauCustomersDisplay[i].disabled = true
          }
        }
      } else {
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (NonCustomerItem) {
            this.BureauCustomersDisplay[i].disabled = true
          } else {
            this.BureauCustomersDisplay[i].disabled = false
          }
        }
        _.remove(this.SelectedBureauCustomers, item => item.nonCustomerItem)

        if (p > 0 && (this.SelectedBureauCustomers[0].NonCustomerItem)) {
          this.SelectedBureauCustomers.shift()
        }
      }
    },

    async getServiceNumbersToDownload () {
      if (this.SelectedBureauCustomers.length === 0) {
        return ''
        //  return this.group.groupTypeDetails.serviceUserNumber
      } else {
        var sunList = []

        var NonCustomerItem = _.some(this.SelectedBureauCustomers, function (o) {
          return o.nonCustomerItem
        })
        if (NonCustomerItem) {
          if (this.SelectedBureauCustomers[0].id === 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa') {
            // user selected all customers
            var aIndex = 0
            for (aIndex = 0; aIndex < this.BureauCustomers.length; ++aIndex) {
              if (!this.BureauCustomers[aIndex].nonCustomerItem) {
                if (sunList.indexOf(this.BureauCustomers[aIndex].serviceUserNumber) === -1) {
                  sunList.push(this.BureauCustomers[aIndex].serviceUserNumber)
                }
              }
            }
          } else if (this.SelectedBureauCustomers[0].id === 'bbbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb') {
            // bureau mode certificate request
            sunList.push(this.group.groupTypeDetails.serviceUserNumber)
          } else if (this.SelectedBureauCustomers[0].id === 'cccccccc-cccc-cccc-cccc-cccccccccccc') {
            // user selected certificate level, sunlist should be empty
            sunList = []
          }
        } else {
          var index = 0
          for (index = 0; index < this.SelectedBureauCustomers.length; ++index) {
            if (sunList.indexOf(this.SelectedBureauCustomers[index].serviceUserNumber) === -1) {
              sunList.push(this.SelectedBureauCustomers[index].serviceUserNumber)
            }
          }
        }
        var sunListCSV = sunList.toString()
        return sunListCSV
      }
    },

    async getBureauCustomers () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaubacsreports/GetReportsBureauCustomerList/' + this.groupId, { showload: true })
        this.BureauCustomers = response.data
        this.BureauCustomersDisplay = []
        var index = 0
        for (index = 0; index < this.BureauCustomers.length; ++index) {
          var name = ''
          if (this.BureauCustomers[index].nonCustomerItem) {
            name = this.BureauCustomers[index].bureauCustomerName
          } else {
            name = this.BureauCustomers[index].bureauCustomerName + ' (' + this.BureauCustomers[index].serviceUserNumber + ')'
          }
          var currentBC = {
            id: this.BureauCustomers[index].bureauCustomerId,
            serviceUserNumber: this.BureauCustomers[index].serviceUserNumber,
            name: name,
            nonCustomerItem: this.BureauCustomers[index].nonCustomerItem,
            disabled: false
          }
          this.BureauCustomersDisplay.push(currentBC)
        }
        this.SelectedBureauCustomers = []
        this.SelectedBureauCustomers.push(this.BureauCustomersDisplay[0])
        this.BureauCustomersLoaded = true
      } catch (e) {
      } finally {
        this.isTableLoading = false
      }
    },

    async getBureauCustomersOLD () {
      var query = {
        params: {
          fields: 'name,serviceusernumber,bureaucustomerid'
        }
      }
      query.params.paygateId = this.$store.state.common.paygateId
      query.params.sort = 'name asc'
      query.params.bureauCustomerList = 'bureauCustomerList'

      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', query, { showload: true })
        .then(response => {
          this.BureauCustomers = response.data.data
          this.BureauCustomersDisplay = [] // reset the display array
          var BureauCustomerIndirectOption = {
            id: '00000000-0000-0000-0000-000000000000',
            serviceUserNumber: '',
            name: 'Certificate Level Reports',
            disabled: false
          }
          var BureauLevelReportOption = {
            id: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
            serviceUserNumber: this.group.groupTypeDetails.serviceUserNumber,
            name: 'BureauLevelReports',
            disabled: false
          }
          this.BureauCustomersDisplay.push(BureauCustomerIndirectOption)
          this.BureauCustomersDisplay.push(BureauLevelReportOption)

          var index = 0
          for (index = 0; index < this.BureauCustomers.length; ++index) {
            var currentBC = {
              id: this.BureauCustomers[index].bureauCustomerId,
              serviceUserNumber: this.BureauCustomers[index].serviceUserNumber,
              name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
              disabled: false
            }
            this.BureauCustomersDisplay.push(currentBC)
          }
          this.BureauCustomersLoaded = true
        })
        .catch(error => console.error(error))
    },
    closeViewReport () {
      this.isViewReportModalVisible = false
    },

    async getGroupsForUser () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/1/`, { params: { rights: ['CollectReports', 'CollectCertificateReports'] } })
        if (response.data.length > 0) {
          this.bureaugroups = response.data
        } else {
          this.bureaugroups = []
        }
      } catch (e) {
      }
    },

    async changeGroup (groupValue) {
      this.groupSelected = true
      this.group = this.groups.find(i => i.groupId === this.groupId)
      this.$store.commit('setSelectedGroup', this.groupId)
      await this.getSelectedGroupDetails()

      // // To show group bank details if user clicks "View" button.
      // var payload = { 'paygateId': this.paygateId, 'bankAccountId': this.bacsGroup.groupTypeDetails.bankAccountId, 'platformUrl': this.$platformApiBaseUrl }
      // this.$store.dispatch('getGroupBankAccount', payload)
      this.loggingIn = false
    },
    async viewVocalinkCertificate () {
      if (this.vocalinkCertificate.issuer === undefined) {
        var success = await this.getVocalinkCertificate()
        if (success === true) {
          this.$swal({
            title: 'Vocalink Certificate',
            html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
            type: 'info',
            animation: false
          })
        }
      } else {
        this.$swal({
          title: 'Vocalink Certificate',
          html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
          type: 'info',
          animation: false
        })
      }
    },
    async getVocalinkCertificate () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkCertificate?networkType=` + this.networkType, { showload: true })
      if (response.data.success === true) {
        this.vocalinkCertificate = response.data.vocalinkCertificate
      } else {
        this.$snapbar.e(response.data.errorMessage)
      }

      return response.data.success
    },
    async logoff () {
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json,
        showload: true
      })
      this.authResponse = response.data
      this.isLoggedIn = false
      this.loggingIn = false
      this.isLoaded = true
      this.canProceed = true
      this.haveReportList = false
    },

    async getSelectedGroupDetails () {
      if (this.groupId === undefined || this.groupId === null) {
        return
      }
      await this.$store.dispatch('getSubBacsGroup', this.groupId)
      this.group = this.$store.getters.bacsGroup
    },
    async viewReportModalBAK (params) {
      var cellClicked = params.column.field

      if (cellClicked === 'viewReportXml' || cellClicked === 'viewReportHtml' || cellClicked === 'viewReportCsv') {
        if (this.networkType === 'BacstelIp') {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 0
          } else {
            this.selectedReportFormat = 1
          }
        } else {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })

        // this.loading = false
        this.reportContent = response.data
        this.isViewReportModalVisible = true
      }
    },

    async viewReportModal (params, reportFormat) {
      var cellClicked = params.column.field
      if (cellClicked === 'buttons') {
        if (this.networkType === 'BacstelIp') {
          if (reportFormat === 'XML') {
            this.selectedReportFormat = 0
          } else if (reportFormat === 'HTML') {
            this.selectedReportFormat = 1
          } else if (reportFormat === 'PDF') {
            this.selectedReportFormat = 3
          } else if (reportFormat === 'CSV') {
            this.selectedReportFormat = 7
          }
        } else {
          if (reportFormat === 'XML') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })

        this.reportContent = response.data

        if (this.selectedReportFormat !== 3) {
          // Not PDF format.
          this.isViewReportModalVisible = true
        } else {
          // PDF format.
          if (!this.reportContent.startsWith('data:application/pdf;base64,')) {
            // This is built approach and avoids having new browser tab open with a very long URL.
            // WARNING: Pop up blocker could cause this new tab to close.
            var binaryString = window.atob(this.reportContent)
            var binaryLen = binaryString.length
            var bytes = new Uint8Array(binaryLen)
            for (var i = 0; i < binaryLen; i++) {
              var ascii = binaryString.charCodeAt(i)
              bytes[i] = ascii
            }

            var blob = new Blob([bytes], { type: 'application/pdf' })
            var link = window.URL.createObjectURL(blob)
            window.open(link, '_blank')
          } else {
            // This prefix allows the PDF to be encoded as a very long URL.
            // New browser tab opens with link but doesn't auto load PDF. User has to select the address bar and press 'Return' key.
            window.open(this.reportContent, '_blank')
          }
        }
      }
    },

    async downloadAllReports (reportFormat) {
      var downloadFormat = 0
      if (reportFormat === 'XML') {
        downloadFormat = 0
      } else if (reportFormat === 'HTML') {
        downloadFormat = 1
      } else if (reportFormat === 'PDF') {
        downloadFormat = 3
      } else if (reportFormat === 'CSV') {
        downloadFormat = 7
      }

      // all reports appear to be in this.rows
      var selectedReportUrls = []
      for (var i = 0; i < this.rows.length; i++) {
        var tmpUrl = this.rows[i].retrievalUrl
        selectedReportUrls.push(tmpUrl)
      }
      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: downloadFormat,
        authResponse: this.authResponse
      })
      try {
        await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureaubacsreports/triggerreportdownloads`, json, { showload: true })
        this.$toastr.s('Reports are being downloaded, please wait')
        this.downloadClicked = true
      } catch (e) {
        this.$toastr.e('Could not initiate report download')
      }
      // await this.ReportDownloader(selectedReportUrls, DownloadFormatValue)
    },

    async downloadReports () {
      var selectedReports = this.$refs.reportList.selectedRows
      var selectedReportUrls = []
      for (var i = 0; i < selectedReports.length; i++) {
        var tmpUrl = selectedReports[i].retrievalUrl
        selectedReportUrls.push(tmpUrl)
      }
      await this.ReportDownloader(selectedReportUrls, this.selectedDownloadFormat)
    },
    async ReportDownloader (selectedReportUrls, DownloadFormat) {
      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: DownloadFormat,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob',
        showload: true
      })

      // this.loading = false
      const blob = new Blob([response.data])
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },
    async saveBacsReportUserPreferences () {
      var json = JSON.stringify({
        networkType: this.networkType,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        groupId: this.groupId
      })

      await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/saveBacsReportUserPreferences',
        data: json,
        showload: true
      })
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getDropdownValues?networkType=` + this.networkType, { showload: true })
      this.reportTypes = response.data.reportTypeLookups
      this.reportPeriods = response.data.periodLookups
      this.reportPeriods.push({ text: 'Specific Day', value: 'specificday' })
      this.reportPeriods.push({ text: 'Since Specific Day', value: 'specificdaysince' })
      this.reportAccessed = response.data.accessedLookups
      this.downloadFormats = response.data.downloadFormatLookups
      this.selectedReportPeriodValue = this.reportPeriods[0].value
      this.allDownloadFormats = Array.from(this.downloadFormats)
    },

    async getBacsReportUserPreferences (ignoreGroup) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getBacsReportUserPreferences?networkType=` + this.networkType, { showload: true })
      if (response.data !== null) {
        this.selectedReportType = response.data.reportType
        this.selectedReportPeriod = response.data.reportPeriod
        this.selectedReportPeriodValue = response.data.reportPeriod
        this.selectedReportAccessed = response.data.reportAccessed

        if (ignoreGroup === false) {
          this.groupId = response.data.groupId
          this.changeGroup()
        }
      } else {
        this.selectedReportType = this.reportTypes[0].value
      }
    },

    async loginStart () {
      if (this.group.groupTypeDetails.tokenType === 'SmartCard') {
        await this.customerUsesWebSigner()
        if (!this.useWebSigner) {
          // Check for web extension and plugin...
          try {
            await this.$store.commit('setDeviceType', 'SmartCard')
            await this.$store.dispatch('checkPlugin')
            if (this.pluginUpdateNotInstalled === false) {
              await this.signChallenge()
            }
          } catch (error) {
            this.$toastr.w(error.message, 'Warning')
          }
        } else {
          await this.signChallenge()
        }
      } else {
        await this.signChallenge()
      }
    },

    async signChallenge () {
      this.isLoggedIn = false
      this.loggingIn = true
      this.canProceed = false
      this.canCancel = false

      await this.getVocalinkLoginChallenge()

      if (this.authResponse.success === true) {
        // Sign either with HSM or smartcard depending on the submission group.
        this.group = this.$store.getters.bacsGroup
        if (this.group.groupTypeDetails.tokenType === 'Hsm') {
          this.signChallengeWithHsm()
        } else {
          if (this.useWebSigner) {
            this.signWithWebSigner()
          } else {
            if (this.browser !== 'IE') {
              this.startSignNonActiveXPlugin()
            } else {
              this.signWithActiveXPlugin()
            }
          }
        }
      } else {
        this.loggingIn = false
        this.canProceed = true
      }
    },

    async customerUsesWebSigner () {
      let useWebSignerResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/useWebSigner`, { showload: true })
      this.useWebSigner = useWebSignerResponse.data
    },

    async signWithWebSigner () {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithWebSigner?submissionId=00000000-0000-0000-0000-000000000000&actionId=00000000-0000-0000-0000-000000000000'
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&id=' + this.authResponse.messageId
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.groupId

      var response = await axios.get(url)
      this.webSignerHtml = response.data

      let challengeText = await this.extractLoginChallenge(this.webSignerHtml)

      let webSignerDivCount = document.querySelectorAll('div[id=websigner]').length
      var parseEvent = new CustomEvent('webSignerParse');
      document.dispatchEvent(parseEvent);

      const element = document.getElementById('websigner')
      if (element !== null) {
        element.remove()
      }

      const challengeDiv = `<div style="text-align: left;"><p>${challengeText}</p></div>`
      const websignerWarning = '<br/><div class="webSignerWarning">If you don\'t see a \'Sign\' button please install WebSigner. Then press F5 to refresh the page.</div><br/><br/>'

      let result = await swal.fire({
        title: 'Sign the following Login Challenge',
        html: websignerWarning + challengeDiv + this.webSignerHtml,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: true,
        width: '650px'
      })

      if (!result.isConfirmed) {
        this.loggingIn = false
        this.canProceed = true
      }
    },

    // Extract the Vocalink login challenge from the "GetLoginChallenge" response in order to display it in the sigining modal.
    // This is because Vocalink want the user to see the data that they are signing.
    async extractLoginChallenge(response) {
      let challengeText = '';
      let srchStr = 'data-in_data=\"';
      let idxStart = response.indexOf(srchStr);
      if (idxStart > 0) {
          challengeText = response.substring(idxStart + srchStr.length);
          let idxEnd = challengeText.indexOf('\"');
          if (idxEnd > 0) {
              challengeText = challengeText.substring(0, idxEnd);
          }
      }
      return challengeText;
    },

    async getVocalinkLoginChallenge () {
      // this.loading = true
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/comms/getVocalinkLoginChallenge?networkType=' + this.networkType + '&groupId=' + this.groupId, { showload: true })
      this.authResponse = response.data
      if (this.authResponse.success === true) {
        this.vocalinkCertificate = this.authResponse.vocalinkCertificate
      } else {
        if (!this.authResponse.licenceError) {
          this.$snapbar.e(this.authResponse.errorMessage)
        } else {
          this.$swal({
            title: 'Licence Warning',
            text: this.authResponse.errorMessage,
            type: 'warning'
          })
        }
      }

      // this.loading = false
    },

    async callHsm () {
      var thumbprint = ''
      var paygateType = bacsCommon.getPaygateType(this.group.groupType)

      if (paygateType !== 'Online') {
        thumbprint = this.group.groupTypeDetails.certificate.thumbprint
      }

      var json = JSON.stringify({
        certificateThumbprint: thumbprint,
        loginChallenge: this.authResponse.loginChallenge,
        isPaygateOnline: paygateType === 'Online',
        userPin: this.userPin,
        hsmPinRequired: this.group.groupTypeDetails.hsmPinRequired,
        groupId: this.groupId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithHsm',
        data: json,
        showload: true
      })

      return response.data
    },

    async signChallengeWithHsm () {
      if (this.group.groupTypeDetails.hsmPinRequired) {
        this.userPin = await bacsCommon.showVocalinkChallengePin(this.authResponse.loginChallenge)
      } else {
        await bacsCommon.showVocalinkChallengeNoPin(this.authResponse.loginChallenge)
      }

      var signResponse = await this.callHsm()

      if (signResponse.success === true) {
        this.base64Signature = signResponse.tag
        await this.vocalinkLogin()

        if (this.authResponse.success === true) {
          this.isLoggedIn = true
        } else {
          this.$snapbar.e(this.authResponse.errorMessage)
        }
      } else {
        this.$snapbar.e(signResponse.errorMessage)
        this.loggingIn = false
        this.canProceed = true
      }

      // this.loading = false
    },

    async signWithActiveXPlugin () {
      this.plugin = document.PayGatePlugin
      this.plugin.InitSmartCardSigning()
      this.plugin.SubmissionLength = this.authResponse.loginChallenge.length
      this.plugin.SubmissionData = this.authResponse.loginChallenge
      this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
      if (this.base64Signature.indexOf('Error') === -1) {
        // this.loading = true
        await this.vocalinkLogin()
        // this.loading = false
        if (this.authResponse.success === true) {
          this.isLoggedIn = true
        } else {
          this.loggingIn = false
          this.$toastr.e(this.authResponse.errorMessage)
          this.canCancel = true
          this.canProceed = true
        }
      } else {
        this.loggingIn = false
        this.$toastr.e(this.base64Signature)
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async startSignNonActiveXPlugin () {
      if (this.browser === 'Chrome') {
        this.processSignatureChrome()
      } else if (this.browser === 'Firefox') {
        this.processSignatureFirefox()
      }
    },

    processSignatureFirefox () {
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
              resolve()
            } else {
              this.showSupportLinkSnapbar(this.base64Signature)
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
              reject(new Error(this.base64Signature))
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })

        var pluginEvent = new CustomEvent('clientCallPlugin', { detail: { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge } })
        document.dispatchEvent(pluginEvent)
      })
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })

      if (this.networkType === 'BacstelIp') {
        this.licenceSetting = response.data.bacs.value
      } else {
        this.licenceSetting = response.data.fps.value
      }
    },

    async checkLicence () {
      var licenceOk = true
      await this.loadGroups(this.BUREAUGROUPTYPE)

      await this.getGroupsForUser()
      return licenceOk
    },

    async loadGroups (groupType) {
      var payload = { paygateId: this.paygateId, groupType: this.BUREAUGROUPTYPE, groupRole: ['collectReports'] }

      await this.$store.dispatch('getGroups', payload)
      this.groups = this.$store.getters.groups
    },

    processSignatureChrome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
            } else {
              this.showSupportLinkSnapbar(this.base64Signature)
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
            }
          } else if (pluginResponse.ErrorMessage !== null) {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
          }
        })
        resolve()
      })
    },

    async getWebSignerSignature () {
      let response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getWebSignerSignature?submissionId=` + this.submissionId, { showload: true })
      this.base64Signature = response.data
      await this.vocalinkLogin()
    },

    async vocalinkLogin () {
      var json = JSON.stringify({
        networkType: this.networkType,
        base64Signature: this.base64Signature,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogin',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.loggingIn = false
      if (this.authResponse.success === true) {
        this.isLoggedIn = true
      } else {
        this.showSupportLinkSnapbar(this.authResponse.errorMessage)
        this.isLoggedIn = false
        this.canProceed = true
      }
    },

    async getReportListMAYBENOT () {
      this.rows = []
      try {
        var servicenumber = await this.getServiceNumbersToDownload()
        var data = {

          networkType: this.networkType,
          serviceUserNumber: servicenumber,
          reportType: this.selectedReportType,
          reportPeriod: this.selectedReportPeriod,
          reportAccessed: this.selectedReportAccessed,
          authResponse: this.authResponse

        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaubacsreports/GetReportList', data, { showload: true })
      } catch (e) {
      } finally {
        this.isTableLoading = false
      }
    },

    onPerPageChange (params) {
      this.currentPerPage = params.currentPerPage
    },

    async getReportList () {
      this.haveReportList = false
      if (!this.disableDatePicker && this.calendarDate === '') {
        this.$toastr.e('Please select a date in the calendar')
      }

      this.rows = []
      var servicenumber = await this.getServiceNumbersToDownload()

      var params = {
        networkType: this.networkType,
        serviceUserNumber: servicenumber,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        authResponse: this.authResponse,
        bacsReports: false,
        downloadGenericTestReport: false
      }
      if (!this.disableDatePicker) {
        params.datedReportDownload = true
        params.processingDate = this.calendarDate
        if (this.selectedReportPeriodValue === 'specificday') {
          params.processingDateSince = false
        } else { params.processingDateSince = true }
      } else {
        params.datedReportDownload = false
      }

      var json = JSON.stringify(params)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/getReportList',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        this.rows = response.data.availableReports.report
        this.haveReportList = true
        this.filterDownloadFormatOptions()
        await this.saveBacsReportUserPreferences()
        this.paginationOptions.perPage = this.currentPerPage
        if (this.$refs.reportList) {
          this.$refs.reportList.$refs.paginationBottom.perPage = this.currentPerPage
          this.$refs.reportList.$refs.paginationBottom.handlePerPage()
          this.$refs.reportList.currentPerPage = this.currentPerPage
        }
      } else {
        this.$toastr.e(response.data.errorMessage)
      }
    },

    // Remove redundant download report formats depending on what reports are returned in the list.
    filterDownloadFormatOptions () {
      if (this.networkType === 'BacstelIp') {
        this.downloadFormats = Array.from(this.allDownloadFormats)
        const anyDdicReports = this.rows.some(a1 => this.ddicReportTypes.includes(a1.reportType))
        const anyNonDdicReports = this.rows.some(a1 => !this.ddicReportTypes.includes(a1.reportType))
        if (!anyDdicReports) {
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'PDF')
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'CSV')
        } else if (!anyNonDdicReports) {
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'HTML')
        }
      }
    },

    // Always a CSV option for FPS. Only a CSV for new DDIC BACS reports.
    showCsvOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      } else {
        showOption = true
      }
      return showOption
    },

    // Only the original BACS reports have a HTML option.
    showHtmlOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (!this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      }
      return showOption
    },

    // All reports have a XML option - for now!
    showXmlOption (params) {
      return true
    },

    // Only new BACS DDIC reports have a PDF option.
    showPdfOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      }
      return showOption
    },

    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    },

    async downloadFileById (fileId) {
      var response = await axios({
        method: 'GET',
        url: process.env.VUE_APP_BUREAU_API_URL + 'bureaubacsreports/downloadzip/' + fileId,
        responseType: 'blob',
        showload: true
      })

      // this.loading = false
      const blob = new Blob([response.data])
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = 'bacsreports.zip'
      const disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    }
  },

  // First load
  async mounted () {
    // Leaving this here for when FPS is instated
    // this.networkType = this.$route.query.networkType
    // this.networkName = this.networkType === 'BacstelIp' ? 'Bureau BACS' : 'Bureau FPS'
    // Add a check so if any  network type other than BacstelIp or SecureIp is used, it errors out.

    // Init websigner div so it exists when needed.
    this.webSignerHtml = '<div id="websigner" data-mode="button" data-in_data="DUMMY" data-reply_url="DUMMY" data-certfilter_ku=""192:8""></div>'
    this.showWebSigner = true;

    this.networkType = 'BacstelIp'
    this.networkName = 'Bureau BACS'

    this.browser = bacsCommon.getBrowser()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value

    await this.loadLicence()

    if (!await this.checkLicence()) {
      return
    }
    await this.getEnums()

    // Only need this for the "specific date" option.
    await this.$store.dispatch('getProcessingDates')
    this.processingDates = this.$store.getters.processingDates
    if (this.processingDates.nonProcessingDates.length > 0) {
      for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
        this.nonProcessingDates.push(new Date(this.processingDates.nonProcessingDates[i]))
      }
    }

    // Check if redirected here after signing with eSigner.
    var getSignature = this.$route.query.getSignature

    var extLoaded = this.$route.query.pluginAccessible

    if (getSignature === 'true') {
      this.authResponse = {}
      this.authResponse.loginChallenge = this.$route.query.challenge
      this.authResponse.messageId = this.$route.query.id

      // TEMP ID just to get signature from database.
      this.submissionId = this.$route.query.submissionId
      this.networkType = this.$route.query.networkType
      this.groupId = this.$route.query.groupId

      await this.getWebSignerSignature()

      await this.getBacsReportUserPreferences(true)
      this.changeGroup()
      // this.loading = false
    } else if (extLoaded === 'true') {
      if (this.browser === 'Firefox') {
        document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
        document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
      }

      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true

      this.groupId = this.$store.getters.selectedGroup
      if (this.selectedGroupId !== null && this.groupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.changeGroup()
        this.loginStart()
      }
    } else if (extLoaded === 'false') {
      this.isLoggedIn = false
      this.isLoaded = true
      this.groupId = this.$store.getters.selectedGroup

      if (this.groupId !== null && this.groupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.changeGroup()
      }
    } else {
      await this.getBacsReportUserPreferences(false)
      this.isLoggedIn = false
      this.isLoaded = true
    }

    this.numberOfSubmissionBytes = 1 // Not signing a submission but want to use eSigner if no plugin loaded when using IE.
    // this.loading = false
  }
}
</script>
<style>
.webSignerWarning {
  color: blue;
  text-align: left;
}

.dark-mode {
  .webSignerWarning {
    color: whitesmoke;
    text-align: left;
  }
}
</style>
