<template>
  <form @submit.prevent="!isLoading && validate()">
    <div class="idb-block">
      <div class="idb-block-title">Person Validation</div>
      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="postcodeInput">Postcode</label>
              <div class="input-group">
                <input id="postcodeInput" placeholder="Postcode" class="form-control" type="text" v-model="addressSearchText" />
                <button
                class="btn btn-info input-group-btn-reset"
                type="button"
                @click="fetchOptions"
                >Find Addresses</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="addresses.some(t => t)">
          <div class="col-md-6">
            <div class="form-group">
              <select class="form-control" v-model="selectedAddress" @change="addressChanged">
                <option selected value="null" disabled>Please select an Address</option>
                <option
                  v-for="address in addresses"
                  :key="address.addressId"
                  :value="address.addressId"
                  >{{ address.display }}</option>
              </select>
            </div>
          </div>
        </div>
        <section v-if="addressLoaded">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <button class="btn btn-danger btn-sm" type="button" @click="clearAddresses">Clear Address</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Address Line 1</label>
                <input placeholder="Address" type="text" v-model="address1" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Address Line 2</label>
                <input type="text" v-model="address2" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Address Line 3</label>
                <input type="text" v-model="address3" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Address Line 4</label>
                <input type="text" v-model="address4" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Locality</label>
                <input placeholder="Locality" type="text" v-model="locality" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Town</label>
                <input placeholder="Town" type="text" v-model="town" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>County</label>
                <input placeholder="County" type="text" v-model="county" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Postcode</label>
                <input placeholder="Postcode" type="text" v-model="postcode" class="form-control" />
              </div>
            </div>
          </div>          
        </section>   
        <div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="addresseeNameInput">Name</label>
							<div class="input-group mb-3">
								<input id="addresseeNameInput" placeholder="Full Name" type="text" v-model="name" class="form-control" />
								<div class="input-group-append">
									<span class="input-group-text">
										<div v-if="!nameValidated && !nameValidating">
											<i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
										</div>
										<div v-if="nameValidating" class="spinner-border spinner-border-sm" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<div v-if="!nameValidating && nameValidated">
											<i v-if="nameValid" class="fa fa-check text-success"></i>
											<span v-if="nameValid" class="d-none">Valid</span>
											<i v-if="!nameValid" class="fa fa-times text-danger"></i>
											<span v-if="!nameValid" class="d-none">Invalid</span>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
        <div class="row" v-if="nameValidated">
          <div class="col-md-6">
            <div class="form-group">
              <label for="matchQualityDisplay">Match Quality</label>
              <input id="matchQualityDisplay" placeholder="" type="text" v-model="matchQuality" :disabled="true" class="form-control" />
              <small v-if="!nameValid" class="text-secondary">It is possible that the user may not be on the edited electoral register</small>
            </div>
          </div>
        </div>
      </div>  
      <div class="idb-block-footer">
        <button type="submit" class="btn btn-primary" :disabled="isLoading || !canSubmit || !addressLoaded">Validate</button>
        <button
        type="button"
        class="btn btn-outline-primary ml-3"
        @click="clear"
        :disabled="isLoading"
        >Clear</button>
      </div> 
    </div>
  </form>
</template>
<script>
import { TheMask } from 'vue-the-mask'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  name: 'personValidation',
  mixins: [ loading ],
    components: {
    TheMask
  },
  data () {
    return {
      loading: false,
      addressSearchText: '',
      addresses: [],
      selectedAddress: '',
      addressLoaded: false,
      addressLoading: false,
      addressesFailed: false,
      nameValidated: false,
      nameValidating: false,
      nameValid: false,
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      locality: '',
      town: '',
      county: '',
      name: '',
      emailAddress: '',
      phoneNumber: '',
      postcode: '',
      sortCode: '',
      accountNumber: '',
      udprn: '',
      isoCode: 'gb',
      matchQuality: '',
    }
  },
  computed: {           
    canSubmit () {
      return this.udprn !== '' && this.name !== ''
    } 
  },
  methods: {
    async fetchOptions () {
      this.addressLoaded = false
      let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}address/lookup/?searchTerm=${this.addressSearchText}&isocode=${this.isoCode}`)
      this.addresses = response.data.addresses
    },
    async addressChanged () {
      try {
				let resp = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}address/getsingle/?address=${this.selectedAddress}&isocode=${this.isoCode}`)
				this.address1 = resp.data.line_1
				this.address2 = resp.data.line_2
				this.address3 = resp.data.line_3
				this.address4 = resp.data.line_4
				this.locality = resp.data.locality
				this.town = resp.data.town
				this.county = resp.data.county
				this.postcode = resp.data.postCode
				this.udprn = resp.data.udprn
				this.addressLoaded = true        
				// await this.checkAddressLinked()
				// if (!this.newAddressSearch) {
				// 	await this.validatePayer()
				// }
			} catch (e) {
				this.addressesFailed = true
			}
    },
    clearAddresses () {
			this.selectedAddress = null
			this.addresses = []
      this.udprn = ''
			this.address1 = ''
			this.address2 = ''
			this.address3 = ''
			this.address4 = ''
			this.locality = ''
			this.town = ''
			this.county = ''
			this.postcode = ''
			this.addressLoaded = false
		},
    async validate () {     
      this.nameValidating = true
      this.nameValid = false
      this.nameValidated = false      
      if (this.udprn !== '' && this.name !== '') {
        let resp = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}address/checknameaddress/`, { udprn: this.udprn, name: this.name })
        this.nameValid = resp.data.matchResult !== 'No Match'
		    this.matchQuality = resp.data.matchResult
      }     
      this.nameValidating = false      
      this.nameValidated = true
    },
    clear () {              
      this.clearAddresses()  
    }
  }
}  
</script>