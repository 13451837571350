<template>
  <div id="findPayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Search Payments
              <help-icon :docPath="helpUrl"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-4">
                <strong><label for="groupSelector">Submission Group</label></strong>
              </div>
              <div class="col-md-2">
                <strong><label for="createdFrom">From Processing Date</label></strong>
              </div>
              <div class="col-md-2">
                <strong><label for="createdTo">To Processing Date</label></strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <group-select
                  id="groupSelector"
                  v-model="selectedGroupId"
                  :groups="groups"
                  @input="selectGroup()"
                  :clearable="true"
                ></group-select>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="processingDateFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="fromDateOnfocus"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="processingDateTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="toDateOnfocus"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span
                  class="mandatory"
                >The "From Processing Date" cannot be after the "To Processing Date"</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1">
                <strong><label for="sortCode">Sort Code</label></strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong><label for="accountNumber">Account Number</label></strong>
              </div>
              <div class="col-md-2">
                <strong><label for="accountName">Account Name</label></strong>
              </div>
              <div class="col-md-1">
                <strong>Exact Match</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong><label for="amountFrom">Amount From</label></strong>
              </div>
              <div class="col-md-1">
                <strong><label for="amountTo">Amount To</label></strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <the-mask
                  id="sortCode"
                  type="text"
                  class="form-control"
                  :mask="'##-##-##'"
                  style="width: 100px;"
                  v-model.trim.lazy="$v.sortCode.$model"
                  @blur.native="onSortCodeBlur($v.sortCode)"
                  @focus.native="sortCodeBlurFired = false"
                ></the-mask>
                <span v-if="$v.sortCode.$dirty && sortCodeBlurFired" name="Sort Code">
                  <small
                    class="form-text small mandatory"
                    v-if="!$v.sortCode.minLen || !$v.sortCode.maxLen"
                  >Sort Code must contain 6 numbers</small>
                </span>
              </div>
             <div class="col-md-2">
                <the-mask
                  id="accountNumber"
                  type="text"
                  class="form-control"
                  :mask="'########'"
                  style="width: 100px;"
                  v-model.trim.lazy="$v.accountNumber.$model"
                  @blur.native="onAccountNumberBlur($v.accountNumber)"
                  @focus.native="accountNumberBlurFired = false"
                ></the-mask>
                <span v-if="$v.accountNumber.$dirty && accountNumberBlurFired" name="Sort Code">
                  <small
                    class="form-text small mandatory"
                    v-if="!$v.accountNumber.minLen || !$v.accountNumber.maxLen"
                  >Account Number must contain 8 numbers</small>
                </span>
              </div>
              <div class="col-md-2">
                <input
                  id="accountName"
                  name="accountName"
                  v-model="accountName"
                  class="form-control"
                  style="width: 200px;"
                />
              </div>
              <div class="col-md-1">
                <label for="exactAccountName" class="sr-only">Exact Account Name</label>
                <p-check class="p-switch p-fill" color="primary" :labelId="'exactAccountName'" v-model="exactAccountNameSearch"></p-check>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <input
                  id="amountFrom"
                  name="amountFrom"
                  v-model="amountFrom"
                  class="form-control"
                  style="width: 100px;"
                />
                <span v-if="!$v.amountFrom.decimal" name="Amount From">
                  <small
                    class="form-text small mandatory"
                    v-if="!$v.amountFrom.decimal"
                  >Amount From must be numeric</small>
                </span>
              </div>
              <div class="col-md-2">
                <input
                  id="amountTo"
                  name="amountTo"
                  v-model="amountTo"
                  class="form-control"
                  style="width: 100px;"
                />
                <span v-if="!$v.amountTo.decimal" name="Amount To">
                  <small
                    class="form-text small mandatory"
                    v-if="!$v.amountTo.decimal"
                  >Amount To must be numeric</small>
                </span>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="this.canSearch === false"
                  v-on:click="searchPayments"
                >Search</button>
              </div>
              <div class="col-md-10">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12" style="overflow-x: auto; white-space: nowrap;">
                <vue-good-table
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  @on-row-click="navToSubmission"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-sort-change="onSortChange"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    setCurrentPage: currentPage
                  }"
                  :sort-options="sortOptions"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'submissionStatus'">
                      <span
                        v-b-popover.hover.top="statusToolTip(props.row.submissionStatus)"
                        v-bind:class="getStatusClass(props.row.submissionStatus)"
                        v-bind:style="getStatusStyle(props.row.submissionStatus)"
                      ></span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="createPaymentCsvFile"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Export search results to CSV'"
                    >
                      <i class="fa fa-file-excel pointer dimmedIcon"></i><span class="sr-only">Export search results to CSV</span>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { numeric, minLength, maxLength, decimal } from 'vuelidate/lib/validators'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'
import { mapGetters } from 'vuex'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'searchPayments',

  mixins: [
    bacsMixin
  ],
  components: { TheMask },
  computed: {
    ...mapGetters(['selectedCustomer']),
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) }
  },

  data () {
    return {
      canSearch: false,
      maxRows: 1000,
      paygateId: '',
      userId: '',
      selectedGroupId: '',
      groups: null,
      bacsPgeGroups: null,
      bacsPgoGroups: null,
      fpsPgeGroups: null,
      fpsPgoGroups: null,
      ddmsGroups: null,
      bureauGroups: null,
      BACSPGEGROUPTYPE: '0',
      BACSPGOGROUPTYPE: '3',
      FPSPGEGROUPTYPE: '4',
      FPSPGOGROUPTYPE: '5',
      DDMSGROUPTYPE: '2',
      BUREAUGROUPTYPE: '1',
      selectedGroup: {},
      disabledDates: {},
      invalidDateRange: false,
      processingDateFrom: '',
      processingDateTo: '',
      sortCode: '',
      accountNumber: '',
      accountName: '',
      exactAccountNameSearch: false,
      amountFrom: '',
      amountTo: '',
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'SubmissionId',
          field: 'submissionId',
          hidden: true
        },
        {
          label: 'PaygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'GroupId',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Type',
          field: 'networkName'
        },
        {
          label: 'Dest SC',
          field: 'thirdPartySortCode'
        },
        {
          label: 'Dest Acc',
          field: 'thirdPartyAccountNumber'
        },
        {
          label: 'Dest Acc Name',
          field: 'thirdPartyAccountName'
        },
        {
          label: 'User Ref',
          field: 'userReference'
        },
        {
          label: 'Orig SC',
          field: 'originatorSortCode'
        },
        {
          label: 'Orig Acc',
          field: 'originatorAccountNumber'
        },
        {
          label: 'TX Code',
          field: 'transactionCode'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          type: 'decimal'
        },
        {
          label: 'Proc Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Pmt Date',
          field: 'paymentDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Status',
          field: 'submissionStatus'
        }
      ],
      exportColumns: [
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Type',
          field: 'networkName'
        },
        {
          label: 'Dest SC',
          field: 'thirdPartySortCode'
        },
        {
          label: 'Dest Acc',
          field: 'thirdPartyAccountNumber'
        },
        {
          label: 'Dest Acc Name',
          field: 'thirdPartyAccountName'
        },
        {
          label: 'User Ref',
          field: 'userReference'
        },
        {
          label: 'Orig SC',
          field: 'originatorSortCode'
        },
        {
          label: 'Orig Acc',
          field: 'originatorAccountNumber'
        },
        {
          label: 'TX Code',
          field: 'transactionCode'
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          type: 'decimal'
        },
        {
          label: 'Proc Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'YYYY-MM-DDTHH:mm:ss',
          dateOutputFormat: 'DD/MM/YYYY'
        },
        {
          label: 'Pmt Date',
          field: 'paymentDate',
          type: 'date',
          dateInputFormat: 'YYYY-MM-DDTHH:mm:ss',
          dateOutputFormat: 'DD/MM/YYYY'
        }
      ],
      rows: [],
      totalRecords: 0,
      searchComplete: false,
      currentPage: 1,
      rowsPerPage: 10,
      helpUrl: '',
      sortCodeBlurFired: false,
      accountNumberBlurFired: false,
      sortOptions: {
        enabled: true,
        initialSortBy: { field: '', type: '' }
      },
      resetTable: true
    }
  },

  methods: {
    async searchPayments () {
      if (this.checkDates() === false || this.$v.$invalid === true) {
        return
      }

      if (this.checkSearchCriteria() === false) {
        return
      }

      this.sortOptions.initialSortBy = { field: 'processingDate', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      // This calls the onSortChange event.
      this.$refs.table.reset()

      this.searchComplete = false
      this.rows = []

      var isDdiSearch = false
      if (this.amountFrom === '0' && this.amountTo === '0') {
        isDdiSearch = true
      }

      var amountFromNum = 0
      var amountToNum = 0
      if (this.amountFrom !== '') {
        amountFromNum = Number(this.amountFrom)
      }
      if (this.amountTo !== '') {
        amountToNum = Number(this.amountTo)
      }

      var groupId = '00000000-0000-0000-0000-000000000000'
      if (this.selectedGroupId !== undefined && this.selectedGroupId !== null && this.selectedGroupId !== '') {
        groupId = this.selectedGroupId
      }

      var paymentSearchParams = JSON.stringify({
        processingDateFrom: this.processingDateFrom !== '' ? this.processingDateFrom : '01/01/0001',
        processingDateTo: this.processingDateTo !== '' ? this.processingDateTo : '01/01/0001',
        maxRows: this.maxRows,
        paygateId: this.paygateId,
        selectedGroupId: groupId,
        thirdPartySortCode: this.sortCode,
        thirdPartyAccountNumber: this.accountNumber,
        thirdPartyAccountName: this.accountName,
        exactAccountNameSearch: this.exactAccountNameSearch,
        amountFrom: amountFromNum,
        amountTo: amountToNum,
        isDdiSearch: isDdiSearch
      })

      this.$store.dispatch('setSearchParams', paymentSearchParams)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/search/payments/',
        data: paymentSearchParams,
        showload: true
      })

      if (response.data !== null) {
        this.totalRecords = response.data.length
        this.rows = response.data
        this.currentPage = 1
      }

      this.searchComplete = true
    },

    clearSearchParams () {
      this.invalidDateRange = false
      this.selectedGroupId = ''
      this.selectedGroupText = 'Please Select'
      this.processingDateFrom = ''
      this.processingDateTo = ''
      this.sortCode = ''
      this.accountNumber = ''
      this.accountName = ''
      this.exactAccountNameSearch = false
      this.amountFrom = ''
      this.amountTo = ''
      this.maxRows = 1000
      this.rows = []
      this.searchComplete = false
      if (this.resetTable) {
        // Don't do this after returning to page after viewing submission details.
        this.sortOptions.initialSortBy = { field: 'processingDate', type: 'desc' }
        this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
        this.$refs.table.reset()
      }
      this.resetTable = true
    },

    async selectGroup () {
    },

    async initComboData () {
      this.clearSearchParams()

      this.userId = this.$store.getters.getClaim('sub').value
      if (this.$store.getters.isInRole(roles.SystemUser)) {
        this.paygateId = this.selectedCustomer
      } else {
        this.paygateId = this.$store.getters.getClaim('paygate_id').value
      }

      this.bacsPgeGroups = []
      this.bacsPgoGroups = []
      this.fpsPgeGroups = []
      this.fpsPgoGroups = []
      this.ddmsGroups = []
      this.bureauGroups = []
      await this.loadGroups([this.BACSPGEGROUPTYPE, this.BACSPGOGROUPTYPE, this.FPSPGEGROUPTYPE, this.FPSPGOGROUPTYPE, this.DDMSGROUPTYPE, this.BUREAUGROUPTYPE])
      await this.populateGroupCombo()
    },

    async loadGroups (groupTypes) {
      if (this.$store.getters.isInRole(roles.SystemUser)) {
        var promises = []
        this.$Progress.start()
        groupTypes.forEach(groupType => {
          promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups`, { params: { paygateid: this.selectedCustomer, groupType: groupType, perPage: 100 } }))
        })

        var responses = await Promise.allSettled(promises)
        this.$Progress.finish()
        responses.forEach(({ value }) => {
          switch (value.config.params.groupType) {
            case this.BACSPGEGROUPTYPE:
              this.bacsPgeGroups = value.data.data
              break
            case this.BACSPGOGROUPTYPE:
              this.bacsPgoGroups = value.data.data
              break
            case this.FPSPGEGROUPTYPE:
              this.fpsPgeGroups = value.data.data
              break
            case this.FPSPGOGROUPTYPE:
              this.fpsPgoGroups = value.data.data
              break
            case this.DDMSGROUPTYPE:
              this.ddmsGroups = value.data.data
              break
            case this.BUREAUGROUPTYPE:
              this.bureauGroups = value.data.data
              break
          }
        })
      } else {
        var groupRoles = ['create', 'add', 'edit', 'delete', 'sign', 'approval', 'approveOwnSubmission', 'submit', 'collectReports', 'groupAdministrator', 'collectCertificateLevelReports']
        await Promise.all(groupTypes.map(async groupType => {
          // eslint-disable-next-line
          var payload = { 'paygateId': this.paygateId, 'groupType': groupType, 'groupRole': groupRoles, or: true }
          await this.$store.dispatch('getGroups', payload)

          switch (groupType) {
            case this.BACSPGEGROUPTYPE:
              this.bacsPgeGroups = this.$store.getters.groups
              break
            case this.BACSPGOGROUPTYPE:
              this.bacsPgoGroups = this.$store.getters.groups
              break
            case this.FPSPGEGROUPTYPE:
              this.fpsPgeGroups = this.$store.getters.groups
              break
            case this.FPSPGOGROUPTYPE:
              this.fpsPgoGroups = this.$store.getters.groups
              break
            case this.DDMSGROUPTYPE:
              this.ddmsGroups = this.$store.getters.groups
              break
            case this.BUREAUGROUPTYPE:
              this.bureauGroups = this.$store.getters.groups
              break
          }
        }))
      }
    },

    async populateGroupCombo () {
      this.groups = []
      var i = 0
      for (i = 0; i < this.bacsPgeGroups.length; i++) {
        this.groups.push(this.bacsPgeGroups[i])
      }

      for (i = 0; i < this.bacsPgoGroups.length; i++) {
        this.groups.push(this.bacsPgoGroups[i])
      }

      for (i = 0; i < this.fpsPgeGroups.length; i++) {
        this.groups.push(this.fpsPgeGroups[i])
      }

      for (i = 0; i < this.fpsPgoGroups.length; i++) {
        this.groups.push(this.fpsPgoGroups[i])
      }

      for (i = 0; i < this.ddmsGroups.length; i++) {
        this.groups.push(this.ddmsGroups[i])
      }

      for (i =0; i < this.bureauGroups.length; i++) {
        this.groups.push(this.bureauGroups[i])
      }
      this.groups.sort((a, b) => a.name.localeCompare(b.name))
    },

    checkDates () {
      var datesOk = true
      if (this.processingDateFrom !== '' && this.processingDateTo !== '') {
        var fromDate = new Date(this.processingDateFrom)
        var toDate = new Date(this.processingDateTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }
      }

      return datesOk
    },

    checkSearchCriteria () {
      var criteriaEntered = true

      if (this.processingDateFrom === '' &&
        this.processingDateTo === '' &&
        this.selectedGroupId === '00000000-0000-0000-0000-000000000000' &&
        this.sortCode === '' &&
        this.accountNumber === '' &&
        this.accountName === '' &&
        this.amountFrom === '' &&
        this.amountTo === '') {
        criteriaEntered = false
        // this.$toastr.w('Please enter search criteria')
        this.$swal({
          title: 'Payment Search',
          text: 'Please enter some search criteria. E.g. A From Date and a Group.',
          type: 'warning',
          animation: false
        })
      }

      return criteriaEntered
    },

    navToSubmission (params) {
      this.$store.dispatch('setSubmissionId', params.row.submissionId)
      this.$store.dispatch('setActionId', undefined)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)
      this.$router.push('/payments/search/submissionWrapper?returnPage=searchPayments')
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
      this.$store.dispatch('setCurrentSearchResultsPage', this.currentPage)
    },

    onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
    },

    onSortChange (params) {
      this.sortOptions.initialSortBy = params
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
    },

    getStatusClass (submissionStatus) {
      var iconClass = ''
      switch (submissionStatus) {
        case 'Created':
          iconClass = 'fa fa-plus'
          break
        case 'Signed':
          iconClass = 'fa fa-file-signature'
          break
        case 'Committed':
          iconClass = 'fa fa-handshake'
          break
        case 'Approved':
          iconClass = 'fa fa-thumbs-up'
          break
        case 'complete':
          iconClass = 'fa fa-check-circle'
          break
        case 'rejected':
          iconClass = 'fa fa-times-circle'
          break
        case 'Cancelled':
          iconClass = 'fa fa-trash-alt'
          break
      }
      return iconClass
    },

    getStatusStyle (submissionStatus) {
      var colour = ''
      switch (submissionStatus) {
        case 'complete':
          colour = 'color: green;'
          break
        case 'rejected':
          colour = 'color: red;'
          break
      }
      return colour
    },

    statusToolTip (submissionStatus) {
      var toolTip = ''
      switch (submissionStatus) {
        case 'complete':
          toolTip = 'Complete'
          break
        case 'rejected':
          toolTip = 'Rejected'
          break
        case 'warning':
          toolTip = 'Warning'
          break
        default:
          toolTip = submissionStatus
          break
      }
      return toolTip
    },

    onSortCodeBlur (element) {
      element.$touch()
      this.sortCodeBlurFired = true
    },

    onAccountNumberBlur (element) {
      element.$touch()
      this.accountNumberBlurFired = true
    },

    async createPaymentCsvFile () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentSearchResultsCsvFile'
        var response = await axios({
          url: url,
          method: 'POST',
          data: this.rows,
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  watch: {
    selectedCustomer () { this.initComboData() }
  },

  created () {
    var today = new Date()
    today.setDate(today.getDate() + 39)
    this.disabledDates.from = today
    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams !== undefined) {
      this.sortOptions.initialSortBy = this.$store.getters.initialSortBy
      this.resetTable = false
    } else {
      this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'
    this.helpUrl = '/payments/search/searchpayments/'

    await this.initComboData()

    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams === undefined) {
      this.$store.dispatch('setSearchParams', null)
      this.$store.dispatch('setCurrentSearchResultsPage', 1)
      this.sortOptions.initialSortBy = { field: 'processingDate', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
      if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
        this.selectedGroup = this.groups.find(i => i.groupId === defaultGroupId)
        // Default group might be a FPS group which means it can't be the BACS default group.
        if (this.selectedGroup !== undefined) {
          this.selectedGroupId = defaultGroupId
          await this.selectGroup()
        }
      }
    } else {
      var searchParams = JSON.parse(this.$store.getters.searchParams)
      if (searchParams.selectedGroupId !== '00000000-0000-0000-0000-000000000000') {
        this.selectedGroupId = searchParams.selectedGroupId
      }
      this.processingDateFrom = searchParams.processingDateFrom !== '01/01/0001' ? searchParams.processingDateFrom : ''
      this.processingDateTo = searchParams.processingDateTo !== '01/01/0001' ? searchParams.processingDateTo : ''
      this.accountName = searchParams.thirdPartyAccountName
      this.exactAccountNameSearch = searchParams.exactAccountNameSearch
      this.sortCode = searchParams.thirdPartySortCode
      this.accountNumber = searchParams.thirdPartyAccountNumber
      this.amountFrom = searchParams.amountFrom > 0 ? searchParams.amountFrom : ''
      this.amountTo = searchParams.amountTo > 0 ? searchParams.amountTo : ''
      this.maxRows = searchParams.maxRows
      this.rows = this.$store.getters.searchResults
      this.totalRecords = this.rows.length
      this.searchComplete = true
      this.currentPage = this.$store.getters.currentSearchResultsPage
      this.rowsPerPage = this.$store.getters.searchRowsPerPage
    }
    this.canSearch = true
  },
  validations () {
    return {
      amountFrom: { decimal },
      amountTo: { decimal },
      sortCode: { numeric, minLen: minLength(6), maxLen: maxLength(6) },
      accountNumber: { numeric, minLen: minLength(8), maxLen: maxLength(8) }
    }
  }
}
</script>
