<template>
  <div>
    <h3>Approve Submission<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/bacsautomation/approvesubmission/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Approval Method</label>
      <br>
      <p-radio value="Auto Approval" color="primary" v-model="selectedNode.props.s2.value" :disabled="!hasManageRole">Auto Approval</p-radio>
      <p-radio value="Manual (User) Approval" color="primary" v-model="selectedNode.props.s2.value" :disabled="!hasManageRole">Manual (User) Approval</p-radio>
    </div>
    <br/>
   <div class="spacer50"></div>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
export default {
  validations: {
    selectedNode: {
      props: {
        s2: {
          value: {
            required
          }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    // this.paygateId = this.$store.getters.getClaim('paygate_id').value
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

</style>
