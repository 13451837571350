<template>
  <div>
    <h3>Start<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/other/start/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Group</label>
      <br>
      <group-select v-model="selectedNode.props.s1.value" :groups="options" :clearable="false" :disabled="!hasManageRole"></group-select>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">User</label>
      <br>
      <select class="form-control" v-model=selectedNode.props.s2.value :disabled="!hasManageRole">
        <option v-for="option in userOptions" :key="option.id" v-bind:value="option.value">
          {{option.label}}
        </option>
      </select>
      <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">A valid paygate user must be selected!</p>
    </div>
    <div class="spacer150"></div>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success" :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../Engine/workflowDesigner.js'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
export default {
  validations: {
    selectedNode: {
      props: {
        s2: {
          value: {
            required
          }
        }
      }
    }
  },
  data () {
    return {
      options: [],
      userOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    if (!this.paygateId) {
      this.$snapbar.e('Error retrieving list of available groups. (002)')
      return
    }
    let res
    const url = `${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.paygateId}`
    try {
      res = await axios.get(url)
      this.options = res.data
      for (let i = 0; i < res.data.length; i++) {
        this.options[i].name = this.options[i].label
        this.options[i].groupId = this.options[i].id
      }
    } catch (e) {
      this.$snapbar.e('Error getting list of available groups. (002)')
    }

    // Fetch Workflow service users
    try {
      const userRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ServiceAccount/UserAccounts`, { params: { serviceAccountType: 'Workflow' } })
      if (userRes && userRes.data) {
        // sort by label, asc
        userRes.data.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        for (const user of userRes.data) {
          this.userOptions.push({
            label: user.label,
            value: user.id
          })
        }
      } else {
        this.$snapbar.e('Error getting list of available users.')
      }
    } catch (e) {
      this.$snapbar.e('Error retrieving list of available users.')
    }

    // Set the default group if one is set and the group has not been set yet
    try {
      if (this.selectedNode.props.s1.value === undefined || this.selectedNode.props.s1.value === '') {
        const defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
        this.selectedNode.props.s1.value = defaultGroupId
      }
    } catch (e) {
      console.warn('Error setting default group')
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>

</style>
