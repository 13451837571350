<template>
	<div>
		<div class="idb-block" v-if="loaded">
			<div class="idb-block-title">
				<h2>
					Certificate Report Overview
					<favourite-icon />
				</h2>
			</div>
			<div class="idb-block-content">
				<div class="row">
					<div class="col-md-6">
						<doughnut-chart :chart-data="certificateChartData.data" :options="chartOptions" :height="300"></doughnut-chart>
					</div>
					<div class="col-md-6">
						<doughnut-chart
							:chart-data="assignedCertificateChartData.data"
							:options="chartOptions"
							:height="300"
						></doughnut-chart>
					</div>
				</div>
			</div>
		</div>
		<div class="idb-block" v-if="expiredCertificateRows.some(() => true)">
			<div class="idb-block-title">
				<h2>Expired Certificates</h2>
			</div>
			<div class="idb-block-content">
				<vue-good-table
					:paginationOptions="{
            enabled: true,
            perPage: 10,
          }"
					:rows="expiredCertificateRows"
					:columns="columns"
					:searchOptions="{
            enabled: true
          }"
					:lineNumbers="true"
					styleClass="vgt-table striped bordered"
				></vue-good-table>
			</div>
		</div>
		<div class="idb-block" v-if="expiringCertificateRows.some(() => true)">
			<div class="idb-block-title">
				<h2>Expiring Certificates</h2>
			</div>
			<div class="idb-block-content">
				<vue-good-table
					:paginationOptions="{
            enabled: true,
            perPage: 10,
          }"
					:rows="expiringCertificateRows"
					:columns="columns"
					:searchOptions="{
            enabled: true
          }"
					:lineNumbers="true"
					styleClass="vgt-table striped bordered"
				></vue-good-table>
			</div>
		</div>
		<div class="idb-block" v-if="certificateRows.some(() => true)">
			<div class="idb-block-title">
				<h2>Certificates</h2>
			</div>
			<div class="idb-block-content">
				<vue-good-table
					:paginationOptions="{
            enabled: true,
            perPage: 10,
          }"
					:rows="certificateRows"
					:columns="columns"
					:searchOptions="{
            enabled: true
          }"
					:lineNumbers="true"
					styleClass="vgt-table striped bordered"
				></vue-good-table>
			</div>
		</div>
		<div class="idb-block" v-if="loaded">
			<div class="idb-block-title">
				<h2>Certificate Report Alert Settings</h2>
			</div>
			<div class="idb-block-content">
				<div class="row">
					<div class="form-group col">
						<label for="alertMonth" class="required">Alert Period</label>
						<select class="custom-select" v-model="settings.alertPeriod">
							<option value="1">1 Month</option>
							<option value="2">2 Months</option>
							<option value="3">3 Months</option>
						</select>
						<small>This will set the time before a certificate is set to expire, where it will start sending alerts, you will receive one per week</small>
					</div>
					<div class="form-group col">
						<label>Users to Alert</label>

						<vue-select
							multiple
							v-model="settings.usersToAlert"
							:options="systemUsers"
							:closeOnSelect="false"
							append-to-body
							:reduce="user => user.id"
							:calculate-position="withPopper"
						></vue-select>
					</div>
				</div>
			</div>
			<div class="idb-block-footer">
				<button
					type="submit"
					class="btn btn-primary"
					@click="save()"
					:disabled="isLoading"
				>Save Settings</button>
			</div>
		</div>
	</div>
</template>

<script>
// Constants
import roles from '@/Assets/Constants/roles'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

// Charts
import DoughnutChart from '@/Assets/Components/Charts/DoughnutChart'

// Third Party
import axios from 'axios'
import VueSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
	name: 'CertificateReport',
	components: {
		DoughnutChart,
		VueSelect
	},
	mixins: [loading],
	data () {
		return {
			certificateRows: [],
			expiredCertificateRows: [],
			expiringCertificateRows: [],
			columns: [
				{
					label: 'Label',
					field: 'label'
				},
				{
					label: 'Valid From',
					field: 'validFrom',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSS',
					dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
				},
				{
					label: 'Valid To',
					field: 'validTo',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSS',
					dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
				},
				{
					label: 'Customer Name',
					field: 'customerName'
				}
			],
			chartOptions: {
				legend: {
					position: 'bottom',
					display: true
				},
				responsive: true,
				maintainAspectRatio: false
			},
			certificateChartData: {
				data: {
					labels: [],
					datasets: [{
						data: [],
						backgroundColor: []
					}]
				}
			},
			assignedCertificateChartData: {
				data: {
					labels: [],
					datasets: [{
						data: [],
						backgroundColor: []
					}]
				}
			},
			loaded: true,
			settings: {
				alertPeriod: 3,
				usersToAlert: []
			},
			systemUsers: []
		}
	},
	methods: {
		async load () {
			try {
				this.loaded = false
				this.$Progress.start()

				var responses = await Promise.allSettled([
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users/UserSelect`, {
						params: { role: roles.SystemUser },
						paygateId: process.env.VUE_APP_CORVID_ID
					}),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}report/certificate`,
						{
							showerror: true,
							errormessage: 'Failed loading certificate report'
						})

				])

				var systemUserResponse = responses[0].value
				this.systemUsers = systemUserResponse.data

				var response = responses[1].value
				this.certificateRows = response.data.certificates
				this.expiringCertificateRows = response.data.expiringCertificates
				this.expiredCertificateRows = response.data.expiredCertificates

				this.clearDoughnutChart(this.certificateChartData)
				this.clearDoughnutChart(this.assignedCertificateChartData)

				this.addToDoughnutChart(this.certificateChartData.data, this.certificateRows.length, 'Certificates', '#24c731')
				this.addToDoughnutChart(this.certificateChartData.data, this.expiringCertificateRows.length, 'Expiring Certificates', '#c76824')
				this.addToDoughnutChart(this.certificateChartData.data, this.expiredCertificateRows.length, 'Expired Certificates', '#c72424')

				var allCertificates = this.certificateRows.concat(this.expiredCertificateRows).concat(this.expiringCertificateRows)
				var withCustomerAssigned = allCertificates.filter(c => c.paygateId != null)

				this.addToDoughnutChart(this.assignedCertificateChartData.data, withCustomerAssigned.length, 'Assigned to A PG3 Customer', '#c7027f')
				this.addToDoughnutChart(this.assignedCertificateChartData.data, allCertificates.length - withCustomerAssigned.length, 'Not Assigned to A PG3 Customer', '#0272c7')

				this.settings.alertPeriod = response.data.alertPeriod
				this.settings.usersToAlert = response.data.usersToAlert

			} catch {
				this.$Progress.fail()
			} finally {
				this.$Progress.finish()
				this.loaded = true
			}

		},
		addToDoughnutChart (chart, data, label, colour) {
			if (data > 0) {
				chart.labels.push(label)
				chart.datasets[0].data.push(data)
				chart.datasets[0].backgroundColor.push(colour)
			}
		},
		clearDoughnutChart (chart) {
			chart.data = {
				labels: [],
				datasets: [{
					data: [],
					backgroundColor: []
				}]
			}
		},
		async save () {
			try {
				await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}report/certificate`, this.settings,
					{
						showload: true,
						showerror: true,
						errormessage: 'Failed to save certificate report'
					})
				this.$toastr.s('Certificate report saved', 'Saved')
				this.load()
			} catch {

			}
		},
		withPopper (dropdownList, component, { width }) {
			dropdownList.style.width = width
			var boundingElement = document.querySelector('#app-header')

			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: 'bottom',
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, -1],
						}
					},
					{
						name: 'hide'
					},

				],
			})

			return () => popper.destroy()
		}
	},
	async mounted () {
		await this.load()
	}
}
</script>

<style>
</style>