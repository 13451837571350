<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">Bulk {{this.readableType(session.uploadType)}} - {{this.readableStatus(session.status)}}</div>
      <div class="idb-block-content">
        <div class="row">
          <div class="col-md-4">
            <table class="table table-borderless border-top-0">
              <tbody class="border-top-0">
              <tr class="border-top-0"><td class="border-top-0">File Name</td><td class="border-top-0">{{ session.fileName }}</td></tr>
              <tr><td>Uploading User</td><td>{{ session.userName }}</td></tr>
              <tr  v-if="session.status < 2 && session.uploadType === 4"><td>Include additional data?</td><td><p-check @change="updateData" class="p-switch p-fill" color="primary" v-model="session.extraDepth" /></td></tr>
              <tr v-if="session.status === 4"><td>Error Message</td><td>{{session.errorMessage}}</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <div class="clearfix" v-if="session.status === 2 || session.status === 5">
          <div class="spinner-border float-start" role="status">
            <span class="sr-only">Loading...</span>
          </div><span v-if="session.status === 2" class="ml-2">Validating, please wait.</span><span v-if="session.status === 5" class="ml-2">Processing, please wait.</span>
        </div>
        <b-dropdown
          id="detailsOptions"
          v-if="session.status === 3"
          text="Download validated file"
          class="m-md-2"
          variant="success"
        >
          <b-dropdown-item
            @click.stop="downloadFilteredOriginal(0)"
          >Original</b-dropdown-item>
          <b-dropdown-item
            @click.stop="downloadFilteredOriginal(1)"
            :disabled="validCount === 0">Valid results only</b-dropdown-item>
          <b-dropdown-item
            @click.stop="downloadFilteredOriginal(2)"
            :disabled="invalidCount === 0"
          >Invalid results only</b-dropdown-item>
        </b-dropdown>
        <button  v-if="session.status !== 2 && session.status !== 5" class="btn btn-danger float-right" @click="deleteSession"><i class="fa fa-trash mr-2"></i>Delete</button>
      </div>
    </div>
    <div class="row" v-if="session.status === 3">
      <div class="col-md">
        <valid-invalid-chart :session-type="session.uploadType" :session-id="sessionId" />
      </div>
      <div class="col-md">
        <email-address-type-chart  v-if="session.uploadType === 0" :session-id="sessionId" />
        <phone-network-chart v-if="session.uploadType === 1" :session-id="sessionId" />
        <bank-institution-chart v-if="session.uploadType === 4" :session-id="sessionId" />
        <cop-accuracy-chart v-if="session.uploadType === 2" :session-id="sessionId" />
      </div>
    </div>

    <div class="idb-block"  v-if="session.status < 2">
      <div class="idb-block-title">Field Assignment</div>
      <div class="idb-block-content">
        <textarea v-if="false" class="form-control" v-model="formattedRows" :rows="10" disabled></textarea>
        <br/>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <td>Field</td>
            <td>Suggested Field</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(field, i) in session.fields" :key="i">
            <th scope="row">{{i+1}}</th>
            <td>{{ field }}</td>
            <td>
              <select :ref="'fieldMap-'+i" class="form-control" @change="update(i)">
                <option value="0" :selected="possibleIndices.includes(i)">None</option>
                <option value="1" v-if="session.uploadType === 0" :selected="i === session.emailIndex">Email</option>
                <option value="2" v-if="session.uploadType === 1" :selected="i === session.phoneIndex">Phone</option>
                <option value="3"  v-if="session.uploadType === 2 || session.uploadType === 4" :selected="i === session.sortCodeIndex">Sort Code</option>
                <option value="4"  v-if="session.uploadType === 2 || session.uploadType === 4" :selected="i === session.accountNumberIndex">Account Number</option>
                <option value="5"  v-if="session.uploadType === 2 || session.uploadType === 4" :selected="i === session.forenameIndex">Payer Name</option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="idb-block-footer">
        <button v-if="session.status < 2" class="btn btn-success" :disabled="!canUpload" @click="doTheValidate">Validate</button>
        <small class="ml-2 text-danger" v-if="!enoughFields">There aren't enough fields to perform {{this.readableType(session.uploadType)}} validation</small>
      </div>
    </div>

    <div class="idb-block"  v-if="session.status === 3">
      <div class="idb-block-title">Results</div>
      <div class="idb-block-content">
        <emailResults :sessionId="session.sessionId" v-if="session.uploadType === 0"></emailResults>
        <phoneResults :sessionId="session.sessionId" v-if="session.uploadType === 1"></phoneResults>
        <copResults :sessionId="session.sessionId" v-if="session.uploadType === 2"></copResults>
        <bankAccountResults :sessionId="session.sessionId" v-if="session.uploadType === 4" :extra-depth="session.extraDepth"></bankAccountResults>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import emailResults from '@/Components/Validate/Bulk/ResultTables/EmailResults'
  import phoneResults from '@/Components/Validate/Bulk/ResultTables/PhoneResults'
  import copResults from '@/Components/Validate/Bulk/ResultTables/CoPResults'
  import bankAccountResults from '@/Components/Validate/Bulk/ResultTables/BankAccountResults'
  import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
  import auth from "@/Assets/Components/Authentication/auth"
  import papa from 'papaparse'
  import validInvalidChart from "@/Components/Validate/Bulk/ResultCharts/ValidInvalidChart"
  import emailAddressTypeChart from "@/Components/Validate/Bulk/ResultCharts/EmailAddressTypeChart"
  import phoneNetworkChart from "@/Components/Validate/Bulk/ResultCharts/PhoneNetworkChart"
  import bankInstitutionChart from "@/Components/Validate/Bulk/ResultCharts/BankInstitutionChart"
  import copAccuracyChart from "@/Components/Validate/Bulk/ResultCharts/CoPAccuracyChart";
  export default {
    components: {
      emailResults,
      phoneResults,
      copResults,
      bankAccountResults,
      validInvalidChart,
      emailAddressTypeChart,
      phoneNetworkChart,
      bankInstitutionChart,
      copAccuracyChart
    },
    data () {
      return {
        updateHub: null,
        sessionId: '',
        session: {
          fields: [],
          rows: [],
          userName: '',
          uploadDate: new Date(),
          fileName: '',
          fieldCount: 0,
          rowCount: 0,
          phoneIndex: -1,
          emailIndex: -1,
          sortCodeIndex: -1,
          accountNumberIndex: -1,
          forenameIndex: -1,
          surnameIndex: -1,
          postCodeIndex: -1,
          addressIndex: -1,
          uprnIndex: -1,
          uploadType: 0,
          status: 0,
          errorMessage: '',
          extraDepth: false
        },
        validCount: 0,
        invalidCount: 0
      }
    },
    computed: {
      enoughFields () {
        let enoughFields = false
        switch(this.session.uploadType) {
          case 0:
            //output = 'Email Addresses'
            enoughFields = this.session.fields.length > 0
            break
          case 1:
            //output = 'Phone Number'
            enoughFields = this.session.fields.length > 2
            break
          case 2:
            //output = 'Confirmation Of Payer'
            enoughFields = this.session.fields.length > 2
            break
          case 3:
            //output = 'Person At Address'
            enoughFields = this.session.fields.length > 2
            break
          case 4:
            //output = 'Bank Account'
            enoughFields = this.session.fields.length > 1
            break
        }
        return enoughFields
      },
      canUpload () {
        let canUpload = false
        switch(this.session.uploadType) {
          case 0:
            //output = 'Email Addresses'
            canUpload = this.session.emailIndex !== -1
            break
          case 1:
            //output = 'Phone Number'
            canUpload = this.session.phoneIndex !== -1
            break
          case 2:
            //output = 'Confirmation Of Payer'
            canUpload = this.session.fields.length > 2 && this.session.sortCodeIndex !== -1 && this.session.accountNumberIndex !== -1 && this.session.forenameIndex !== -1
            break
          case 3:
            //output = 'Person At Address'
            canUpload = this.session.fields.length > 2 && this.session.forenameIndex !== -1 && this.session.postCodeIndex !== -1 && this.session.addressIndex !== -1
            break
          case 4:
            //output = 'Bank Account'
            canUpload = this.session.fields.length > 1 && this.session.sortCodeIndex !== -1 && this.session.accountNumberIndex !== -1
            break
        }
        return canUpload
      },
      possibleIndices () {
        return [
          this.session.phoneIndex,
          this.session.emailIndex,
          this.session.sortCodeIndex,
          this.session.accountNumberIndex,
          this.session.forenameIndex,
          this.session.surnameIndex,
          this.session.postCodeIndex,
          this.session.addressIndex,
          this.session.uprnIndex
         ]
      },
      formattedRows () {
        return this.session.rows.length > 0 ? this.session.rows.join('\r\n') : ''
      },
      updateHubUrl () {
        return `${process.env.VUE_APP_VALIDATE_API_URL}hubs/bulkuploadhub?sessionId=${this.sessionId}`
      }
    },
    async mounted () {
      this.sessionId = this.$route.params.sessionId
      window.addEventListener('beforeunload', this.stopSocketListener)
      auth.getAccessToken()
        .then(token => {
          this.bearerToken = 'Bearer ' + token
          this.updateHub = new HubConnectionBuilder().withUrl(this.updateHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
          this.updateHub.serverTimeoutInMilliseconds = 3600000
          this.updateHub.start()
          this.updateHub.on('Refresh', data => {
            this.session = data
          })
        })
      await this.load()
    },
    beforeRouteLeave (to, from, next) {
      this.stopSocketListener()
      next()
    },
    methods: {
      stopSocketListener () {
        if (this.updateHub !== undefined && this.updateHub !== null) {
          this.updateHub.stop()
        }
      },
      async deleteSession () {
        let response = await axios.delete(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/${this.sessionId}`)
        if (response.status === 200) {
          this.$toastr.s('Session deleted')
          this.$router.push({name: 'bulkValidation'})
        } else {
          this.$toastr.e('Could not delete session')
        }
      },
      async load () {
        try {
          let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/${this.sessionId}`)
          this.session = response.data
          var countResponse = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/charts/validity/${this.session.uploadType}/${this.sessionId}`)
          this.validCount = countResponse.data.valid
          this.invalidCount = countResponse.data.invalid
        } catch (e) {
          this.$toastr.e(`Could not load session`)
          this.$router.push({name: 'bulkValidation'})
        }
      },
      async updateData () {
        let updateRequest = {
          phoneIndex: this.session.phoneIndex,
          emailIndex: this.session.emailIndex,
          sortCodeIndex: this.session.sortCodeIndex,
          accountNumberIndex: this.session.accountNumberIndex,
          forenameIndex: this.session.forenameIndex,
          surnameIndex: this.session.surnameIndex,
          postCodeIndex: this.session.postCodeIndex,
          addressIndex:  this.session.addressIndex,
          uprnIndex: this.session.uprnIndex,
          extraDepth: this.session.extraDepth
        }

        let response = await axios.put(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/${this.sessionId}`, updateRequest).catch((error) => {
            if(error.response.status === 400) {
              this.session.phoneIndex= error.response.data.phoneIndex
              this.session.emailIndex= error.response.data.emailIndex
              this.session.sortCodeIndex= error.response.data.sortCodeIndex
              this.session.accountNumberIndex= error.response.data.accountNumberIndex
              this.session.forenameIndex= error.response.data.forenameIndex
              this.session.surnameIndex= error.response.data.surnameIndex
              this.session.postCodeIndex= error.response.data.postCodeIndex
              this.session.addressIndex= error.response.data.addressIndex
              this.session.uprnIndex= error.response.data.uprnIndex
              this.$toastr.e('Selected field is invalid, please select another field.')
            } else {
              this.$toastr.e('Failed to save your settings.')
            }
          })
          if (response !== undefined && response !== null && response.status === 200) {
            this.$toastr.s(`Settings saved.`)
          }
      },
      async doTheValidate () {
        let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/trigger-validation`, { sessionId: this.sessionId })
        console.log(response.data)
      },
      readableType (input) {
        let output = ''
        switch (input) {
          case 0:
            output = 'Email Addresses'
            break
          case 1:
            output = 'Phone Number'
            break
          case 2:
            output = 'Confirmation Of Payer'
            break
          case 3:
            output = 'Person At Address'
            break
          case 4:
            output = 'Bank Account'
            break
        }
        return output
      },
      async download (downloadType) {
        let typeText = 'all'
        if (downloadType === 1) {
          typeText = 'valid-only'
        }
        if (downloadType === 2) {
          typeText = 'invalid-only'
        }
        let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/downloadresults/${this.sessionId}`, { params: { downloadType: downloadType, uploadType: this.session.uploadType } })
        let fileName = `${this.readableType(this.session.uploadType)}-${typeText}-${this.sessionId}.csv`
        if (response.status === 200) {
          var csvString = papa.unparse(response.data)
          var blob = new Blob([csvString])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName)
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        } else {
          this.$toastr.e('Could not create file to download')
        }
      },
      async downloadFilteredOriginal (downloadType) {
        let typeText = 'all'
        if (downloadType === 1) {
          typeText = 'valid-only'
        }
        if (downloadType === 2) {
          typeText = 'invalid-only'
        }
        let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/filteredoriginal/${this.sessionId}`, { params: { downloadType: downloadType, uploadType: this.session.uploadType } })
        if (response.status === 200) {
          var bytes = this.base64ToArrayBuffer(response.data.fileData)
          var blob = new Blob([bytes], { type: response.data.mimeType })
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, response.data.fileName)
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: response.data.mimeType })
            a.download = response.data.fileName
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        } else {
          this.$toastr.e('Could not create file to download')
        }
      },
      base64ToArrayBuffer (base64) {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      },
      readableStatus(input) {
        let output = ''
        switch(input) {
          case 0:
            output = 'Uploaded'
            break
          case 1:
            output = 'Mapping'
            break
          case 2:
            output = 'Validating'
            break
          case 3:
            output = 'Complete'
            break
          case 4:
            output = 'Error'
            break
          case 5:
            output = 'Processing'
            break
          default:
            break
        }
        return output
      },
      async update (e) {
        console.log(e)
        var valueField = this.$refs['fieldMap-'+e][0].value
        switch(valueField){
          case '0' :
            if (this.session.emailIndex === e) {
              this.session.emailIndex = -1
            }
            if (this.session.phoneIndex === e) {
              this.session.phoneIndex = -1
            }
            if (this.session.sortCodeIndex === e) {
              this.session.sortCodeIndex = -1
            }
            if (this.session.accountNumberIndex === e) {
              this.session.accountNumberIndex = -1
            }
            if (this.session.forenameIndex === e) {
              this.session.forenameIndex = -1
            }
            break
          case '1' :
            this.session.emailIndex = e
            break
          case '2' :
            this.session.phoneIndex = e
            break
          case '3' :
            this.session.sortCodeIndex = e
            break
          case '4' :
            this.session.accountNumberIndex = e
            break
          case '5':
            this.session.forenameIndex = e
            console.log('forename updated to '+this.session.forenameIndex)
            break
        }
        await this.updateData()
      }
    }
  }
</script>
