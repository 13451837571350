import { render, staticRenderFns } from "./ImportPaymentFiles.vue?vue&type=template&id=795e7157"
import script from "./ImportPaymentFiles.vue?vue&type=script&lang=js"
export * from "./ImportPaymentFiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('795e7157')) {
      api.createRecord('795e7157', component.options)
    } else {
      api.reload('795e7157', component.options)
    }
    module.hot.accept("./ImportPaymentFiles.vue?vue&type=template&id=795e7157", function () {
      api.rerender('795e7157', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Components/Bureau/BureauJob/ImportPaymentFiles.vue"
export default component.exports