<template>
  <div id="viewValidationMessages">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Bankline Validation {{ messageSeverity }} Messages <span class="pull-right"><a :href="helpUrl" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div v-if="this.totalRecords > 0" class="col-md-12">
                <vue-good-table
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                  @on-column-filter="onColumnFilter"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
                    jumpFirstOrLast: true
                  }"
                  :sort-options="{
                    enabled: false
                  }"
                  styleClass="vgt-table striped bordered">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'messageSeverity'">
                      <span v-bind:class="getSeverityClass(props.row.messageSeverity)" class="badge badge-pill pull-left">{{props.row.messageSeverity}}</span>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button id="returnToSummary" type="button" class="btn btn-primary" v-on:click="returnToSummary">Back to Result Summary</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert2'
  import colours from '@/Assets/Constants/colours'
  
  export default {
  
    data () {
      return {
        submissionData: {},
        columns: [
          {
            label: 'Severity',
            field: 'messageSeverity'
          },
          {
            label: 'Title',
            field: 'title'
          },
          {
            label: 'Description',
            field: 'description'
          }
        ],
        rows: [],
        totalRecords: 0,
        serverParams: {
          submissionId: '',
          // a map of column filters example: {name: 'john', age: '20'}
          columnFilters: {
          },
          sort: {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          },
  
          page: 1, // what page I want to show
          perPage: 10 // how many items I'm showing per page
        },
        saveStarted: false,
        max: 100,
        progress: 0,
        validationResponse: {},
        getTaskProgressUrl: '',
        endTaskUrl: '',
        groupBankAccount: {},
        importFileResponse: {},
        bacsGroup: {},
        messageSeverity: '',
        fileNumber: 1,
        helpUrl: ''
      }
    },
  
    methods: {
      getSeverityClass (severity) {
        if (severity === 'Fix') {
          return 'badge-danger'
        } else if (severity === 'Duplicate') {
          return 'badge-warning'
        } else {
          return 'badge-' + severity.toLowerCase()
        }
      },
  
      getButtonClass () {
        if (this.validationResponse.validationResult === 'Warnings') {
          return 'btn btn-light'
        } else {
          return 'btn btn-primary'
        }
      },
  
      updateParams (newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
  
      onPageChange (params) {
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
  
      onPerPageChange (params) {
        this.rows.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
      },
  
      onSortChange (params) {
        this.updateParams({
          sort: {
            field: params[0].field,
            type: params[0].type
          }
        })
        this.loadItems()
      },
  
      onColumnFilter (params) {
        this.updateParams(params)
        this.loadItems()
      },
  
      returnToSummary () {
        this.$router.push('/bankline/banklinepayments/validationResult?retrieveResult=false')
      },
  
      async loadItems () {
        this.serverParams.submissionId = this.submissionData.submissionId
  
        var json = JSON.stringify({
          submissionId: this.submissionData.submissionId,
          messageSeverity: this.messageSeverity,
          page: this.serverParams.page,
          perPage: this.serverParams.perPage,
          showValidationMessages: false,
          sortColumn: this.serverParams.sort.field,
          sortDirection: this.serverParams.sort.type,
          resetStart: false,
          paymentsWithMessages: this.validationResponse.paymentsWithMessages
        })
  
        var response = await axios.post(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/validation/get`, json, { showload: true })
        this.rows = response.data.data
        this.totalRecords = response.data.count
      }
    },
    async beforeRouteLeave (to, from, next) {
      if (to.path.endsWith('validationResult')) {
        next()
      } else {
        var result = await swal.fire({
          title: 'Navigation',
          text: 'Your submission details will be lost. Do you want to navigate away from this page?',
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
  
        if (result.isConfirmed) {
          next()
        } else {
          next(false)
        }
      }
    },
    async mounted () {
      this.messageSeverity = this.$route.query.messageSeverity
      this.submissionData = this.$store.getters.submissionData
      this.groupBankAccount = this.$store.getters.groupBankAccount
      this.importFileResponse = this.$store.getters.importFileResponse
      this.bacsGroup = this.$store.getters.bacsGroup
      this.validationResponse = this.$store.getters.validationResponse
      this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
      this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndSaveTask?submissionId=' + this.submissionData.submissionId
  
      if (this.submissionData.filename !== undefined && this.submissionData.filename !== null && this.submissionData.filename !== 'N/A') {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionfromfile/'
      } else {
        this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionusingeditor/'
      }
      await this.loadItems()
    }
  }
  </script>
  