<template>
	<div>
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						Mappings
						<span class="pull-right">
							<a :href="`${docUrl}/automation/paygate-mapping/`" target="_blank">
								<i class="far fa-question-circle"></i>
							</a>
						</span>
						<favourite-icon></favourite-icon>
					</h2>
				</div>
				<div class="idb-block-content">
					<div>
						<vue-good-table
							:paginationOptions="paginationOptions"
							:sort-options="sortOptions"
							:isLoading.sync="isTableLoading"
							ref="table"
							:columns="columns"
							:rows="rows"
							:lineNumbers="true"
							:search-options="{ enabled: true }"
							@on-page-change="onPageChange"
							@on-sort-change="onSortChange"
							@on-column-filter="onColumnFilter"
							@on-per-page-change="onPerPageChange"
							@on-search="onSearch"
							@on-row-click="onRowClick"
							styleClass="vgt-table striped bordered table-hover"
						>
							<template slot="table-row" slot-scope="props">
								<span
									v-if="props.column.field == 'metaData.type'"
								>{{ reformatType(props.formattedRow[props.column.field]) }}</span>
								<span v-else-if="props.column.field == 'lastExecutionDate'">
									{{ props.formattedRow[props.column.field] }}
									<div v-if="props.formattedRow[props.column.field]"></div>
								</span>
								<span v-else-if="props.column.field == 'buttons'">
									<b-button
										@click.prevent="btnEditMapping(props)"
										variant="outline-info"
										class="pointer"
										title="Edit Mapping"
									>
										<i class="glyphicon ti-pencil"></i>
									</b-button>
								</span>
								<span v-else>{{props.formattedRow[props.column.field]}}</span>
							</template>
							<div slot="table-actions">
								<button
									@click.prevent="clearTableFilters"
									class="btn btn-link"
									v-b-popover.hover.top.d500="'Clear filters'"
								>
									<span class="fa-stack" style="font-size: 10px;">
										<i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
										<i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
									</span>
									<span class="sr-only">Clear filters</span>
								</button>
								<b-button
									@click.prevent="load"
									class
									variant="link"
									v-b-popover.hover.top.d500="'Refresh the data in the table'"
								>
									<i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
									<span class="sr-only">Refresh Table</span>
								</b-button>
								<b-button
									@click.prevent="btnNewMapping"
									class
									variant="link"
									v-b-popover.hover.top.d500="'Create a new mapping in the designer'"
								>
									<i class="fa fa-plus pointer dimmedIcon pointer"></i>
									<span class="sr-only">Add New Mapping</span>
								</b-button>
							</div>
						</vue-good-table>
					</div>
					<br />
					<div class="row col-12">
						<div class="btn-group" role="group">
							<button id="btnGroupDrop1" @click="btnNewMapping" type="button" class="btn btn-primary">
								<i class="fa fa-plus mr-2"></i>New Mapping
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
	name: 'my-component',
	mixins: [tableFilterMixin, loading],
	components: {
	},
	data () {
		return {
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			columns: [
				{
					label: 'Id',
					field: '_id',
					hidden: true
				},
				{
					label: 'Title',
					field: 'metaData.title',
					filterOptions: { enabled: false }
				},
				{
					label: 'Description',
					field: 'metaData.description',
					filterOptions: { enabled: false }
				},
				{
					label: 'Type',
					field: 'metaData.type',
					filterOptions: { enabled: false }
				},
				{
					label: 'Creation Date',
					field: 'creationDate',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
					filterOptions: { enabled: false }
				},
				{
					label: 'Last Execution Date',
					field: 'lastExecutionDate',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
					filterOptions: { enabled: false }
				},
				{
					label: '',
					field: 'buttons',
					sortable: false,
					filterOptions: { enabled: false },
					tdClass: 'text-center',
					thClass: 'text-center',
					hidden: false
				}
			],
			row_data: [],
			btnLatch: false,
			serverParams: {
				sort: [{ field: 'metaData.title', type: 'asc' }]
			}
		}
	},
	computed: {
		rows: function () {
			return this.row_data
		},
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		}
	},
	methods: {
		async load () {
			try {
				this.isTableLoading = true
				console.log('process.env.VUE_APP_WORKFLOW_API_URL:', process.env.VUE_APP_WORKFLOW_API_URL)
				const res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappings`, { showload: true, params: { ...this.buildGoodTableQuery() } })
				if (res) {
					this.row_data = res.data.mappings
					this.$snapbar.hide()
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view Mappings.')
				} else {
					this.$snapbar.e(`Could not get a list of Mappings - ${e.message}`)
				}
				this.row_data = []
			} finally {
				this.isTableLoading = false
			}
		},
		reformatDate (d) {
			let i = []
			if (d.indexOf('/') > 0) {
				i = d.substring(0, 10).split('/')
			} else {
				i = d.substring(0, 10).split('\\')
			}
			const nd = [i[1], i[0], i[2]].join('/')
			return `${nd} ${d.substring(11, 19)}`
		},
		reformatType (type) {
			switch (type) {
				case 'bureauCustomerImporter':
					return 'Bureau Customer Importer'
				case 'bacs':
				case 'fps':
					return 'BACS & FPS'
				case 'ukBureau':
					return 'UK BACS Bureau'
				case 'bankline':
					return 'Bankline'
				default:
					return 'Other'
			}
		},
		onRowClick (params) {
			if (!this.btnLatch) this.$router.push(`mappingdetail/${params.row._id}`)
		},
		btnNewMapping () {
			this.$router.push('/automation/mapping/designer')
		},
		btnCancel () {
			this.$router.go(-1)
		},
		btnEditMapping (params) {
			this.btnLatch = true
			this.$router.push(`/automation/mapping/designer/${params.row._id}`)
		}
	},
	created: async function () {
		// await this.loadLicence()
	},
	watch: {
		corvidSelectedPaygateId: async function (val) {
			await this.load()
		}
	}
}
</script>

<style scoped>
td {
	cursor: pointer;
}
.v-time-ago__text {
	font-size: xx-small;
	color: #ff0000;
}
</style>
