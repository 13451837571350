<template>
    <form @submit.prevent="!isLoading && validate()">
        <div class="idb-block">
            <div class="idb-block-title">Phone Number Validation</div>
            <div class="idb-block-content">
                <div class="form-group col-md-6">
                    <label for="validatePhoneInput" class="label-control required">Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3"><i :class="'flag-icon flag-icon-' + isoCode"></i></span>
                        </div>
                        <div class="input-group-prepend">

                            <select  class="input-group-text" id="basic-addon1" v-model="countryCode">
                                <option value="44">+44</option>
                                <option value="353">+353</option>
                            </select>
                        </div>
                        <input
                            id="validatePhoneInput"
                            placeholder="Phone Number"
                            type="text"
                            v-model="phoneNumber"
                            class="form-control"
                            @blur="removeLeadingZero"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">
                                <div v-if="!phoneValidated && !phoneValidating">
                                    <i title="awaiting validation" class="fa fa-question-circle" aria-hidden="true"></i>
                                </div>
                                <div v-if="phoneValidating" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-if="!phoneValidating && phoneValidated">
                                    <i v-if="phoneValid" class="fa fa-check text-success"></i>
                                    <span v-if="phoneValid" class="d-none">Valid</span>
                                    <i v-if="!phoneValid" class="fa fa-times text-danger"></i>
                                    <span v-if="!phoneValid" class="d-none">Invalid</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="idb-block-footer">
                <button type="submit" class="btn btn-primary" :disabled="isLoading || phoneNumber === ''">Validate</button>
                <button
                type="button"
                class="btn btn-outline-primary ml-3"
                @click="clear"
                :disabled="isLoading"
                >Clear</button>
            </div>
        </div>
      <bulkControl v-if="false" :upload-type="1" />
    </form>
</template>
<script>
    import axios from 'axios'
    import loading from '@/Assets/Mixins/LoadingMixin'
import { phoneNumber } from '../../../Assets/Validators';
    import bulkControl from "@/Components/Validate/Bulk/BulkUploadControl.vue";
    export default {
        name: 'phoneValidation',
      components: {bulkControl},
        mixins: [ loading ],
        data () {
            return {
                phoneValidated: false,
                phoneValidating: false,
                phoneValid: false,
                phoneNumber: '',
                loading: false,
                countryCode: '44'
            }
        },
        computed: {
            isoCode () {
                let isoCode
                switch (this.countryCode) {
                    case '353':
                        isoCode = 'ie'
                        break
                    case '44':
                    default:
                        isoCode = 'gb'
                        break
                }
                return isoCode
            }
        },
        methods: {
            async validate () {
                this.phoneValidated = false
                this.phoneValidating = true
                let request = { phoneNumber: this.phoneNumber, countryCode: this.countryCode }
                let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}phone`, request)
                this.phoneValid = response.data.validity === 'Valid'
                this.phoneValidating = false
                this.phoneValidated = true
            },
            clear () {
                this.phoneNumber = ''
                this.phoneValid = false
                this.phoneValidating = false
                this.phoneValidated = false
            },
            removeLeadingZero () {
                this.phoneNumber = this.phoneNumber.trim()
                this.phoneNumber = this.phoneNumber.replace(/^[0\+]*/g, '')
            }
        }
    }
</script>
