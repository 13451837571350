<template>
  <div id="findSubmissions">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Search Submissions
              <help-icon :docPath="helpUrl"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-4">
                <strong><label for="paymentType">Payment Type</label></strong>
              </div>
              <div class="col-md-2">
                <strong><label for="createdFrom">From Date</label></strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong><label for="createdTo">To Date</label></strong>
              </div>
              <div class="col-md-1"></div>
              <div v-if="isSystemUser" class="col-md-2">
                <strong><label for="paygateType">Paygate Type</label></strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <b-select id="paymentType" v-model.trim="selectedPaymentEngineType" :options="paymentEngineTypes" @change="selectPaymentType()" />
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="fromDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="toDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div v-if="isSystemUser" class="col-md-2">
                <b-select id="paygateType" v-model.trim="selectedPaygateType" :options="paygateTypes" />
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <strong><label for="groupSelector">Select a Group</label></strong>
              </div>
              <div class="col-md-3">
                <strong><label for="userSelector">Select a User</label></strong>
              </div>
              <div class="col-md-2">
                <strong><label for="reference">Reference</label></strong>
              </div>
              <div class="col-md-1"></div>
              <div v-if="isSystemUser" class="col-md-2">
                <strong><label for="customer">Customers</label></strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <group-select
                  id="groupSelector"
                  v-model="selectedGroupId"
                  :groups="groups"
                  @input="selectGroup()"
                  :clearable="true"
                ></group-select>
              </div>
              <div class="col-md-3">
                <b-form-select id="userSelector" v-model="selectedUserId" :options="allUsers" @change="selectUser()"></b-form-select>
              </div>
              <div class="col-md-2">
                <input
                  id="reference"
                  name="reference"
                  v-model="reference"
                  class="form-control"
                  style="width: 300px;"
                />
              </div>
              <div class="col-md-1"></div>
              <div v-if="isSystemUser" class="col-md-2">
                <b-select id="customer" v-model.trim="selectedCustomerType" :options="customerTypes" />
              </div>
            </div>
            <div class="row form-group">
              <div v-if="this.showMaxRowsWarning" class="col-md-12">
                <b-card bg-variant="warning" text-variant="white" class="text-center">
                  <b-card-text>Only the first {{ maxRows }} rows have been returned.</b-card-text>
                </b-card>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="this.canSearch === false"
                  v-on:click="searchSubmissions"
                >Search</button>
              </div>
              <div v-bind:class="getBtnColClass">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams"
                >Clear Search Criteria</button>
              </div>
              <div v-if="antiFraudListId" class="col-md-2">
                <button
                  class="btn btn-outline-danger pull-right ml-3"
                  type="button"
                  @click="returnToAntiFraudList('cancel')"
                >Cancel</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12" style="overflow-x: auto; white-space: nowrap;">
                <vue-good-table
                  ref="table"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-cell-click="onCellClick"
                  @on-sort-change="onSortChange"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    setCurrentPage: currentPage
                  }"
                  :sort-options="sortOptions"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'submissionStatus'">
                      <span
                        v-b-popover.hover.top="statusToolTip(props.row.submissionStatus)"
                        v-bind:class="getStatusClass(props.row.submissionStatus)"
                        v-bind:style="getStatusStyle(props.row.submissionStatus)"
                      ></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryReport'">
                      <span v-bind:class="getReportClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryPdf'">
                      <span v-bind:class="getPdfClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryXml'">
                      <span v-bind:class="getXmlClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'antiFraudList'">
                      <span v-bind:class="getAntiFraudClass()"></span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { numeric } from 'vuelidate/lib/validators'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'
import { mapGetters } from 'vuex'

export default {
  name: 'searchSubmissions',
  mixins: [
    bacsMixin
  ],
  computed: {
    ...mapGetters(['selectedCustomer']),
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) },
    getBtnColClass () { return this.antiFraudListId !== undefined && this.antiFraudListId ? 'col-md-8' : 'col-md-10' },
    showMaxRowsWarning () { return false }
    // showMaxRowsWarning () { return this.totalRecords === this.maxRows }
  },

  data () {
    return {
      canSearch: false,
      // maxRows: 1000,
      paygateId: '',
      userId: '',
      paymentEngineTypes: {},
      selectedPaymentEngineType: 0,
      paygateTypes: {},
      selectedPaygateType: -1,
      createdFrom: '',
      createdTo: '',
      selectedGroupId: '',
      groups: null,
      bacsPgeGroups: null,
      bacsPgoGroups: null,
      fpsPgeGroups: null,
      fpsPgoGroups: null,
      ddmsGroups: null,
      bureauGroups: null,
      BACSPGEGROUPTYPE: '0',
      BACSPGOGROUPTYPE: '3',
      FPSPGEGROUPTYPE: '4',
      FPSPGOGROUPTYPE: '5',
      DDMSGROUPTYPE: '2',
      BUREAUGROUPTYPE: '1',
      selectedGroup: {},
      selectedUserId: '00000000-0000-0000-0000-000000000000',
      users: [],
      allUsers: [],
      selectedUser: {},
      reference: '',
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'PaygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'customerName',
          hidden: true
        },
        {
          label: 'UserId',
          field: 'userId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Created By',
          field: 'userName'
        },
        {
          label: 'GroupId',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'AccountId',
          field: 'accountId',
          hidden: true
        },
        {
          label: 'Bank',
          field: 'bankReference'
        },
        {
          label: 'Type',
          field: 'paymentEngineType',
          formatFn: this.setUpperCase
        },
        {
          label: 'Amount',
          field: 'totalAmount',
          type: 'number',
          hidden: false,
          formatFn: this.formatAmount
        },
        {
          label: 'Cur',
          field: 'currency',
          hidden: true
        },
        {
          label: 'Created On',
          field: 'createdOn',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Status',
          field: 'submissionStatus'
        },
        {
          label: 'Report',
          field: 'submissionSummaryReport',
          sortable: false
        },
        {
          label: 'PDF',
          field: 'submissionSummaryPdf',
          sortable: false
        },
        {
          label: 'XML',
          field: 'submissionSummaryXml',
          sortable: false
        },
        {
          label: 'Anti-Fraud',
          field: 'antiFraudList',
          hidden: true,
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      searchComplete: false,
      disabledDates: {},
      invalidDateRange: false,
      selectedSubmissionId: '',
      subReference: '',
      currentPage: 1,
      rowsPerPage: 10,
      customerTypes: {},
      selectedCustomerType: 0,
      antiFraudListId: false,
      btnColClass: 'col-md-10',
      helpUrl: '',
      submissionStatus: '',
      defaultFromDate: '',
      defaultToDate: '',
      sortOptions: {
        enabled: true,
        initialSortBy: { field: '', type: '' }
      },
      resetTable: true
    }
  },

  methods: {
    async searchSubmissions () {
      if (this.checkDates() === false || this.$v.$invalid === true) {
        return
      }
      this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      // This calls the onSortChange event.
      this.$refs.table.reset()

      this.searchComplete = false
      this.rows = []

      var fromDate = this.createdFrom
      var toDate = this.createdTo

      if (fromDate === '') {
        fromDate = '01/01/0001'
      }

      if (toDate === '') {
        toDate = '01/01/0001'
      }

      var groupId = '00000000-0000-0000-0000-000000000000'
      if (this.selectedGroupId !== undefined && this.selectedGroupId !== null && this.selectedGroupId !== '') {
        groupId = this.selectedGroupId
      }

      var submissionSearchParams = JSON.stringify({
        createdFrom: fromDate,
        createdTo: toDate,
        paymentEngineType: this.selectedPaymentEngineType,
        // maxRows: this.maxRows,
        paygateId: this.paygateId,
        reference: this.reference,
        selectedGroupId: groupId,
        selectedUserId: this.selectedUserId,
        paygateType: this.selectedPaygateType,
        customerType: this.selectedCustomerType,
        submissionStatus: this.submissionStatus
      })

      this.$store.dispatch('setSearchParams', submissionSearchParams)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/search/submissions/',
        data: submissionSearchParams,
        showload: true
      })

      if (response.data !== null) {
        this.totalRecords = response.data.length
        this.rows = response.data
        this.currentPage = 1
      }

      if (this.isSystemUser) {
        this.columns[2].hidden = false
      }

      this.searchComplete = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    clearSearchParams () {
      this.invalidDateRange = false
      this.selectedGroupId = ''
      this.selectedGroupText = 'Please Select'
      this.selectedUserId = '00000000-0000-0000-0000-000000000000'
      this.selectedUserText = 'Please Select'
      this.createdFrom = ''
      this.createdTo = ''
      this.reference = ''
      // this.maxRows = 1000
      this.selectedPaymentEngineType = 0
      this.rows = []
      this.searchComplete = false
      this.selectedPaygateType = -1
      this.selectedCustomerType = 0
      this.totalRecords = 0
      this.submissionStatus = ''
      if (this.resetTable) {
        // Don't do this after returning to page after viewing submission details.
        this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
        this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
        this.$refs.table.reset()
      }
      this.initSearchDates()
      this.resetTable = true
    },

    async selectGroup () {
    },

    async initComboData () {
      this.clearSearchParams()

      this.userId = this.$store.getters.getClaim('sub').value
      if (this.$store.getters.isInRole(roles.SystemUser)) {
        this.paygateId = this.selectedCustomer
      } else {
        this.paygateId = this.$store.getters.getClaim('paygate_id').value
      }

      this.bacsPgeGroups = []
      this.bacsPgoGroups = []
      this.fpsPgeGroups = []
      this.fpsPgoGroups = []
      this.ddmsGroups = []
      this.users = []
      await this.loadGroups([this.BACSPGEGROUPTYPE, this.BACSPGOGROUPTYPE, this.FPSPGEGROUPTYPE, this.FPSPGOGROUPTYPE, this.DDMSGROUPTYPE, this.BUREAUGROUPTYPE])
      await this.populateGroupCombo()
      await this.populateUserCombo()
      await this.getEnums()
    },

    initSearchDates () {
      // var today = new Date()
      // var dd = String(today.getDate()).padStart(2, '0')
      // var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // var yyyy = today.getFullYear()
      // this.createdFrom = mm + '/' + dd + '/' + yyyy
      // this.createdTo = mm + '/' + dd + '/' + yyyy

      // Dates in Javascript and/or combined with the date picker are nuts.
      // Dateformat dd/MM/yyyy returned by server has the day and month swapped over by JS/datepicker.
      // Hence the fudge of using the MM returned as the day and the dd returned as the month!
      var mm = this.defaultFromDate.substring(0, 2)
      var dd = this.defaultFromDate.substring(3, 5)
      var yyyy = this.defaultFromDate.substring(6, 10)
      this.createdFrom = dd + '/' + mm + '/' + yyyy

      mm = this.defaultToDate.substring(0, 2)
      dd = this.defaultToDate.substring(3, 5)
      yyyy = this.defaultToDate.substring(6, 10)
      this.createdTo = dd + '/' + mm + '/' + yyyy
    },

    async loadGroups (groupTypes) {
      if (this.$store.getters.isInRole(roles.SystemUser)) {
        var promises = []
        this.$Progress.start()
        groupTypes.forEach(groupType => {
          promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups`, { params: { paygateid: this.selectedCustomer, groupType: groupType, perPage: 100 } }))
        })

        var responses = await Promise.allSettled(promises)
        this.$Progress.finish()
        responses.forEach(({ value }) => {
          switch (value.config.params.groupType) {
            case this.BACSPGEGROUPTYPE:
              this.bacsPgeGroups = value.data.data
              break
            case this.BACSPGOGROUPTYPE:
              this.bacsPgoGroups = value.data.data
              break
            case this.FPSPGEGROUPTYPE:
              this.fpsPgeGroups = value.data.data
              break
            case this.FPSPGOGROUPTYPE:
              this.fpsPgoGroups = value.data.data
              break
            case this.DDMSGROUPTYPE:
              this.ddmsGroups = value.data.data
              break
            case this.BUREAUGROUPTYPE:
              this.bureauGroups = value.data.data
              break
          }
        })
      } else {
        var groupRoles = ['create', 'add', 'edit', 'delete', 'sign', 'approval', 'approveOwnSubmission', 'submit', 'collectReports', 'groupAdministrator', 'collectCertificateLevelReports']
        await Promise.all(groupTypes.map(async groupType => {
          // eslint-disable-next-line
          var payload = { 'paygateId': this.paygateId, 'groupType': groupType, 'groupRole': groupRoles, or: true }
          await this.$store.dispatch('getGroups', payload)

          switch (groupType) {
            case this.BACSPGEGROUPTYPE:
              this.bacsPgeGroups = this.$store.getters.groups
              break
            case this.BACSPGOGROUPTYPE:
              this.bacsPgoGroups = this.$store.getters.groups
              break
            case this.FPSPGEGROUPTYPE:
              this.fpsPgeGroups = this.$store.getters.groups
              break
            case this.FPSPGOGROUPTYPE:
              this.fpsPgoGroups = this.$store.getters.groups
              break
            case this.DDMSGROUPTYPE:
              this.ddmsGroups = this.$store.getters.groups
              break
            case this.BUREAUGROUPTYPE:
              this.bureauGroups = this.$store.getters.groups
              break
          }
        }))
      }
    },

    async populateGroupCombo () {
      this.groups = []
      var i = 0
      if (this.selectedPaymentEngineType === 0 || this.selectedPaymentEngineType === 1) {
        for (i = 0; i < this.bacsPgeGroups.length; i++) {
          this.groups.push(this.bacsPgeGroups[i])
        }

        for (i = 0; i < this.bacsPgoGroups.length; i++) {
          this.groups.push(this.bacsPgoGroups[i])
        }
        for (i =0; i < this.bureauGroups.length; i++) {
          this.groups.push(this.bureauGroups[i])
        }
      }

      if (this.selectedPaymentEngineType === 0 || this.selectedPaymentEngineType === 2) {
        for (i = 0; i < this.fpsPgeGroups.length; i++) {
          this.groups.push(this.fpsPgeGroups[i])
        }

        for (i = 0; i < this.fpsPgoGroups.length; i++) {
          this.groups.push(this.fpsPgoGroups[i])
        }
      }

      if (this.selectedPaymentEngineType === 0) {
        for (i = 0; i < this.ddmsGroups.length; i++) {
          this.groups.push(this.ddmsGroups[i])
        }
      }

      this.groups.sort((a, b) => a.name.localeCompare(b.name))
    },

    async selectPaymentType () {
      await this.populateGroupCombo()
      this.selectedGroupId = ''
      this.selectedGroupText = 'Please Select'
    },

    async selectUser () {
    },

    async populateUserCombo () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'users/userSelect', { showload: true })
      var tmpUser = {}
      for (var i = 0; i < response.data.length; i++) {
        tmpUser = {}
        tmpUser.id = response.data[i].id
        tmpUser.name = response.data[i].label
        this.users.push(tmpUser)
      }

      this.users.sort((a, b) => a.name.localeCompare(b.name))

      this.allUsers = this.users.map(user => {
        return { value: user.id, text: user.name }
      })
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.paymentEngineTypes = response.data.paymentEngineTypes
      this.paygateTypes = response.data.paygateTypes
      this.customerTypes = response.data.customerTypes
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate
    },

    onRowClick (params) {
      var subId = params.row.id
      this.$store.dispatch('setActionId', undefined)
      this.$store.dispatch('setSubmissionId', subId)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)
      this.$store.dispatch('setSelectedRowGroupName', params.row.groupName)
    },

    async onCellClick (params) {
      this.$store.dispatch('setSubmissionId', params.row.id)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)

      var canShowReport = false
      if (params.row.submissionStatus === 'complete' || params.row.submissionStatus === 'rejected' || params.row.submissionStatus === 'warning') {
        canShowReport = true
      }
      if (params.column.field === 'submissionSummaryReport') {
        if (canShowReport) {
          const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: params.row.id } })
          window.open(routeData.href, '_blank')
        }
      } else if (params.column.field === 'submissionSummaryXml') {
        if (canShowReport) {
          this.downloadXml(params.row.id)
        }
      } else if (params.column.field === 'submissionSummaryPdf') {
        if (canShowReport) {
          this.downloadPdf(params.row.id)
        }
      } else if (params.column.field === 'antiFraudList') {
        this.returnToAntiFraudList(params.row.id)
      } else {
        this.$store.dispatch('setPayments', [])
        let subUrl = '/payments/search/submissionWrapper?returnPage=searchSubmissions&submissionStatus=' + params.row.submissionStatus
        if (this.antiFraudListId !== undefined) {
          subUrl += '&antiFraudListId=' + this.antiFraudListId
        }
        this.$router.push(subUrl)
      }
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }
      }

      return datesOk
    },

    checkSearchCriteria () {
      var criteriaEntered = true

      if ((this.createdFrom === '' || this.createdFrom === '01/01/0001') &&
        (this.createdTo === '' || this.createdTo === '01/01/0001') &&
        (this.selectedGroupId === '' || this.selectedGroupId === '00000000-0000-0000-0000-000000000000') &&
        (this.selectedUserId === '' || this.selectedUserId === '00000000-0000-0000-0000-000000000000') &&
        this.reference === '' &&
        this.selectedPaymentEngineType === 0) {
        criteriaEntered = false
        // this.$toastr.w('Please enter search criteria')
        this.$swal({
          title: 'Submission Search',
          text: 'Please enter some search criteria. E.g. A From Date and a Group or a PaymentType which isn\'t "All".',
          type: 'warning',
          animation: false
        })
      }

      return criteriaEntered
    },

    getStatusClass (submissionStatus) {
      var iconClass = ''
      switch (submissionStatus) {
        case 'Created':
          iconClass = 'fa fa-plus'
          break
        case 'Signed':
          iconClass = 'fa fa-file-signature'
          break
        case 'Committed':
          iconClass = 'fa fa-handshake'
          break
        case 'Approved':
          iconClass = 'fa fa-thumbs-up'
          break
        case 'complete':
          iconClass = 'fa fa-check-circle'
          break
        case 'rejected':
          iconClass = 'fa fa-times-circle'
          break
        case 'Cancelled':
          iconClass = 'fa fa-trash-alt'
          break
        case 'warning':
          iconClass = 'fa fa-exclamation-circle'
          break
      }
      return iconClass
    },

    getReportClass (submissionStatus) {
      var iconClass = ''
      if (submissionStatus === 'complete' || submissionStatus === 'rejected' || submissionStatus === 'warning') {
        iconClass = 'fa fa-file-alt'
      }
      return iconClass
    },

    getXmlClass (submissionStatus) {
      var iconClass = ''
      if (submissionStatus === 'complete' || submissionStatus === 'rejected' || submissionStatus === 'warning') {
        iconClass = 'fa fa-file-code'
      }
      return iconClass
    },

    getPdfClass (submissionStatus) {
      var iconClass = ''
      if (submissionStatus === 'complete' || submissionStatus === 'rejected' || submissionStatus === 'warning') {
        iconClass = 'fa fa-file-pdf'
      }
      return iconClass
    },

    getAntiFraudClass () {
      return 'fa fa-database'
    },

    getStatusStyle (submissionStatus) {
      var colour = ''
      switch (submissionStatus) {
        case 'complete':
          colour = 'color: green;'
          break
        case 'rejected':
          colour = 'color: red;'
          break
        case 'warning':
          colour = 'color: #DD6B55;'
          break
      }
      return colour
    },

    statusToolTip (submissionStatus) {
      var toolTip = ''
      switch (submissionStatus) {
        case 'complete':
          toolTip = 'Complete'
          break
        case 'rejected':
          toolTip = 'Rejected'
          break
        case 'warning':
          toolTip = 'Warning'
          break
        default:
          toolTip = submissionStatus
          break
      }
      return toolTip
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
      this.$store.dispatch('setCurrentSearchResultsPage', this.currentPage)
    },

    onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
    },

    onSortChange (params) {
      this.sortOptions.initialSortBy = params
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
    },

    async downloadXml (submissionId) {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getSubmissionSummaryXml?submissionId=' + submissionId
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      })

      this.saveFile(response)
    },

    async downloadPdf (submissionId) {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadSubmissionSummaryPdf?submissionId=' + submissionId
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      })

      this.saveFile(response)
    },

    returnToAntiFraudList (subId) {
      const queryParam = this.antiFraudListId === 'create' ? 'create' : 'edit/' + this.antiFraudListId
      this.$router.push(`/admin/antifraud/${queryParam}?submissionId=${subId}`)
    },

    setUpperCase (value) {
      return value.toUpperCase()
    }
  },

  watch: {
    selectedCustomer () {
      this.initComboData()
      this.initSearchDates()
    }
  },

  created () {
    this.disabledDates.from = new Date()
    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams !== undefined) {
      this.sortOptions.initialSortBy = this.$store.getters.initialSortBy
      this.resetTable = false
    } else {
      this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
    }
  },

  async mounted () {
    this.disabledDates.from = new Date()

    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'

    this.helpUrl = '/payments/search/searchsubmissions/'
    await this.initComboData()

    this.antiFraudListId = this.$route.query.antiFraudListId
    if (this.antiFraudListId !== undefined) {
      this.btnColClass = 'col-md-5'
      this.columns[18].hidden = false
    }

    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams === undefined) {
      this.$store.dispatch('setSearchParams', null)
      this.$store.dispatch('setCurrentSearchResultsPage', 1)
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
      this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      this.initSearchDates()
      var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
      if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
        this.selectedGroup = this.groups.find(i => i.groupId === defaultGroupId)
        // Default group might be a FPS group which means it can't be the BACS default group.
        if (this.selectedGroup !== undefined) {
          this.selectedGroupId = defaultGroupId
          await this.selectGroup()
        }
      }
    } else {
      // Returning from submission details...
      var searchParams = JSON.parse(this.$store.getters.searchParams)
      this.selectedPaymentEngineType = searchParams.paymentEngineType
      if (this.selectedPaymentEngineType !== 0) {
        this.populateGroupCombo()
      }
      this.createdFrom = searchParams.createdFrom !== '01/01/0001' ? searchParams.createdFrom : ''
      this.createdTo = searchParams.createdTo !== '01/01/0001' ? searchParams.createdTo : ''
      this.reference = searchParams.reference
      this.maxRows = searchParams.maxRows
      if (searchParams.selectedGroupId !== '00000000-0000-0000-0000-000000000000') {
        this.selectedGroupId = searchParams.selectedGroupId
      }
      if (searchParams.selectedUserId !== '00000000-0000-0000-0000-000000000000') {
        this.selectedUserId = searchParams.selectedUserId
      }
      this.selectedPaygateType = searchParams.paygateType
      this.selectedCustomerType = searchParams.customerType

      if (useSearchParams === 'true') {
        this.rows = this.$store.getters.searchResults
        this.totalRecords = this.rows.length
        this.searchComplete = true
        this.currentPage = this.$store.getters.currentSearchResultsPage
        this.rowsPerPage = this.$store.getters.searchRowsPerPage
      } else if (useSearchParams === 'auto') {
        this.submissionStatus = 'complete'
        this.searchSubmissions()
      }
      if (this.isSystemUser) {
        this.columns[2].hidden = false
      }
    }
    this.canSearch = true
  },

  destroyed () {
    // this.$store.dispatch('setSubmissionSearchResults', null)
  },

  validations: {
    maxRows: { numeric }
  }
}
</script>
