export default {
	CustomerAdministrator: 'CustomerAdministrator',
	ManageBanks: 'ManageBanks',
	AddGroups: 'AddGroups',
	GroupAdministrator: 'GroupAdministrator',
	ManageUsers: 'ManageUsers',
	ViewAuditLog: 'ViewAuditLog',
	ViewBanks: 'ViewBanks',
	ViewUsers: 'ViewUsers',
	CreateCustomer: 'CreateCustomer',
	DeleteCustomer: 'DeleteCustomer',
	GDPRAdministrator: 'GDPRAdministrator',
	ImportCustomer: 'ImportCustomer',
	AgentConfigure: 'AgentConfigure',
	SystemUser: 'PaygateSystemAdmin',
	ManageAntiFraud: 'ManageAntiFraud',
	CustomerReports: 'CustomerReports',
	ProcessSubmissions: 'ProcessSubmissions',
	ActionsReports: 'ActionsReports',
	Finance: 'Finance',
	Marketing: 'Marketing',
	ManageBureauCustomers: 'ManageBureauCustomers',
	ValidateBankAccount: 'ValidateUKBankAccount',
	ValidateIban: 'ValidateIBAN',
	ValidateCoP: 'ValidateCoP',
	ValidateCoPBulk: 'ValidateCoPBulk',
	ViewWorkflow: 'ViewWorkflow',
	ManageWorkflow: 'ManageWorkflow',
	ExecuteWorkflow: 'ExecuteWorkflow',
	ViewMapping: 'ViewMapping',
	ManageMapping: 'ManageMapping',
	FolderManager: 'FolderManager',
	FolderManagerFileViewer: 'FolderManagerFileViewer',
	ViewTelemetry: 'ViewTelemetry',

}
