<template>
  <b-modal v-model="value" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Bankline Payment Details</div>

    <!-- Third party destination account name -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party account name.'"><label for="accountName">Account Name</label></div>
      <div class="col-md-4" :class="{invalid: $v.accountName.$error}">
        <input
          id="accountName"
          type="text"
          class="form-control"
          ref="defaultElement"
          v-model.trim.lazy="$v.accountName.$model"
          @change="onChange($v.accountName)"
          @blur="onBlur($v.accountName)"
          :disabled="!canSavePayment"
          maxlength="18"
        />
      </div>
      <span v-if="$v.accountName.$dirty">
        <small
          v-if="!$v.accountName.required"
          class="form-text text-danger small text-nowrap"
        >Account Name is required</small>
        <small
          v-if="!$v.accountName.maxLen"
          class="form-text text-danger small text-nowrap"
        >Maximum account name length is {{ $v.accountName.$params.maxLen.max }} characters</small>
      </span>
    </div>

    <!-- Third party sort code -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s sort code.'"><label for="sortCode">Sort Code</label></div>
      <div class="col-md-3" :class="{invalid: $v.sortCode.$error && sortCodeBlurFired}">
        <the-mask
          id="sortCode"
          type="text"
          class="form-control"
          :mask="'##-##-##'"
          v-model.trim.lazy="$v.sortCode.$model"
          @blur.native="onSortCodeBlur($v.sortCode)"
          @focus.native="sortCodeBlurFired = false"
          :disabled="!canSavePayment"
        ></the-mask>
      </div>
      <div v-if="bankDetailsValidated && validSortCode" class="col-md-1">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.sortCode.$dirty && sortCodeBlurFired">
        <small
          v-if="!$v.sortCode.required"
          class="form-text text-danger small text-nowrap"
        >Sort Code is required</small>
        <small
          v-if="!$v.sortCode.minLen || !$v.sortCode.maxLen"
          class="form-text text-danger small text-nowrap"
        >Sort code must contain {{ $v.sortCode.$params.minLen.min }} numbers</small>
        <small
          v-if="!validSortCode"
          class="form-text text-warning small text-nowrap"
        >{{ sortCodeError }}</small>
      </span>
    </div>

    <!-- Third party account number -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s account number.'"><label for="accountNumber">Account Number</label></div>
      <div class="col-md-3" :class="{invalid: $v.accountNumber.$error && accountNumberBlurFired}">
        <the-mask
          id="accountNumber"
          type="text"
          class="form-control"
          :mask="'########'"
          v-model.trim="$v.accountNumber.$model"
          @blur.native="onAccountNumberBlur($v.accountNumber)"
          @focus.native="accountNumberBlurFired = false"
          :disabled="!canSavePayment"
        ></the-mask>
      </div>
      <div v-if="bankDetailsValidated && validAccountNumber" class="col-md-1">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.accountNumber.$dirty && accountNumberBlurFired">
        <small
          v-if="!$v.accountNumber.required"
          class="form-text text-danger small text-nowrap"
        >Account Number is required</small>
        <small
          v-if="!$v.accountNumber.minLen || !$v.accountNumber.maxLen"
          class="form-text text-danger small text-nowrap"
        >Account number must contain {{ $v.accountNumber.$params.minLen.min }} numbers</small>
        <small
          v-if="!validAccountNumber"
          class="form-text text-warning small text-nowrap"
        >{{ accountNumberError }}</small>
      </span>
      <span v-if="!newPayment && !accountNumberBlurFired">
        <small
          v-if="!validAccountNumber"
          class="form-text text-warning small text-nowrap"
        >{{ accountNumberError }}</small>
      </span>
    </div>

    <!-- User reference -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the user\'s payment reference.'"><label for="userReference">User Reference</label></div>
      <div class="col-md-4" :class="{invalid: $v.userReference.$error}">
        <input
          id="userReference"
          type="text"
          class="form-control"
          v-model.trim.lazy="$v.userReference.$model"
          @change="onChange($v.userReference)"
          :disabled="!canSavePayment"
          maxlength="18"
        />
      </div>
      <span v-if="$v.userReference.$dirty">
        <small
          v-if="!$v.userReference.maxLen"
          class="form-text text-danger small text-nowrap"
        >Maximum user reference length is {{ $v.accountName.$params.maxLen.max }} characters</small>
      </span>
    </div>

    <!-- Amount -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the payment amount.'"><label for="amount">Amount (£)</label></div>
      <div class="col-md-3" :class="{invalid: $v.amount.$error}">
        <input
          id="amount"
          type="text"
          class="form-control"
          v-model.trim.lazy="$v.amount.$model"
          @change="onChangeAmount($v.amount)"
          @keypress="onKeypressAmount($event)"
          @keyup="onKeyupAmount($event)"
          @blur="onBlur($v.amount)"
          :disabled="disableAmount || !canSavePayment"
        />
      </div>
      <span v-if="$v.amount.$dirty">
        <small
          v-if="!$v.amount.required"
          class="form-text text-danger small text-nowrap"
        >Amount is required</small>
        <small
          v-if="!$v.amount.decimal"
          class="form-text text-danger small text-nowrap"
        >Amount must be a monetory value</small>
        <small
          v-if="!$v.amount.between"
          class="form-text text-danger small text-nowrap"
        >Amount must be between £{{ this.minAmount }} and £{{ this.maxAmount }}</small>
      </span>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="savePayment"
            :disabled="disableButtons || !canSavePayment"
          >Save Payment</b-btn>
        </b-col>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-left"
            variant="danger"
            @click="cancel"
            :disabled="disableButtons"
          >Cancel</b-btn>
        </b-col>
        <b-col cols="8">
          <b-btn
            size="sm"
            class="float-right"
            variant="danger"
            @click="deletePayment"
            :disabled="disableButtons || !canDeletePayment"
            v-show="!newPayment"
          >Delete Payment</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { required, requiredIf, minLength, maxLength, numeric, decimal, between } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import { CurrencyDirective } from 'vue-currency-input'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import moment from "moment";

export default {
  name: 'editBanklinePayment',

  mixins: [
    bacsMixin
  ],
  components: { TheMask },
  directives: {
    currency: CurrencyDirective
  },
  props: {
    value: Boolean,
    payment: Object,
    newPayment: Boolean,
    canSavePayment: Boolean,
    canDeletePayment: Boolean,
    submissionReference: {},
    importResponse: {},
    maxAmount: Number
  },

  data: () => ({
    accountName: '',
    sortCode: '',
    accountNumber: '',
    userReference: '',
    amount: 0,
    disableButtons: false,
    disableAmount: false,
    origAmount: 0,
    minAmount: 0.01,
    validSortCode: false,
    validAccountNumber: false,
    sortCodeError: '',
    accountNumberError: '',
    bankDetailsValidated: false,
    bankId: 0,
    sortCodeBlurFired: false,
    accountNumberBlurFired: false,
    decimalPointEntered: false,
    paymentType: ''
  }),

  methods: {
    hide () {
      this.$emit('input', false)
    },

    setFocus () {
      this.disableButtons = false
      this.$v.$reset()
      this.$refs.defaultElement.focus()
      this.accountName = this.payment.thirdPartyAccountName
      this.sortCode = this.payment.thirdPartySortCode
      this.accountNumber = this.payment.thirdPartyAccountNumber
      this.userReference = this.payment.userReference
      this.amount = this.payment.amount
      this.validSortCode = false
      this.validAccountNumber = false
      this.bankDetailsValidated = false
      this.accountNumberError = ''
      this.bankId = 0
      if (!this.newPayment) {
        this.validateBankAccount()
      } else {
        this.transactionCodeDescription = 'Standard credit'
      }

      this.sortCodeBlurFired = false
      this.accountNumberBlurFired = false
      this.decimalPointEntered = false
      this.onChangeAmount(null)
    },

    onChange (element) {
      element.$touch()
      this.forceUpper()
    },

    onChangeAmount (element) {
      if (this.amount === '') {
        this.amount = 0
      }
      this.amount = parseFloat(this.amount).toFixed(2)
    },

    onBlur (element) {
      element.$touch()
    },

    onKeypressAmount (e) {
      const tmpAmount = e.target.value + ''
      // Allow another DP to be entered if user has deleted the one that was there.
      if (!tmpAmount.includes('.')) {
        this.decimalPointEntered = false
      }
      if (e.charCode >= 48 && e.charCode <= 57) {
        // Only allow up to 2 digits after the DP.
        return true
      } else if (e.charCode === 46) {
        if (!this.decimalPointEntered) {
          this.decimalPointEntered = true
        } else {
          e.preventDefault()
        }
        return true
      } else {
        e.preventDefault()
      }
    },

    onKeyupAmount (e) {
      // Only allow up to 2 digits after the DP.
      const i = e.target.value.indexOf('.')
      if (i > -1) {
        const mantissa = e.target.value.substring(i)
        const num = e.target.value.substring(0, e.target.value.length - mantissa.length)
        if (mantissa.length > 3) {
          e.target.value = num + mantissa.substring(0, mantissa.length - 1)
        }
      }
    },

    onSortCodeBlur (element) {
      element.$touch()
      this.sortCodeBlurFired = true
      this.validateBankAccount()
    },

    onAccountNumberBlur (element) {
      element.$touch()
      this.accountNumberBlurFired = true
      this.validateBankAccount()
    },

    forceUpper () {
      this.accountName = this.accountName.toUpperCase()
      this.userReference = this.userReference.toUpperCase()
    },

    cancel () {
      this.$emit('close')
    },

    async savePayment () {
      this.$v.$touch()

      this.sortCodeBlurFired = true
      this.accountNumberBlurFired = true

      if (this.$v.$invalid) {
        return false
      }

      this.disableButtons = true

      this.origAmount = this.payment.amount
      this.origTxCode = this.payment.transactionCode

      this.payment.thirdPartyAccountName = this.accountName
      this.payment.thirdPartyAccountNumber = this.accountNumber
      this.payment.thirdPartySortCode = this.sortCode
      this.payment.userReference = this.userReference
      this.payment.amount = this.amount
      // Temp setting just to populate audit log record.
      this.payment.message = this.submissionReference

      let method = 'PUT'

      this.payment.amountText = this.payment.amount + ''
      let json = JSON.stringify(this.payment)
      let url = process.env.VUE_APP_BANKLINE_API_URL + 'bankline/payment/'
      if (this.newPayment) {
        method = 'POST'
      }

      let response = await axios({
        method: method,
        url: url,
        data: json
      })

      if (response.data.status !== 'Failed') {
        if (this.newPayment) {
          this.$toastr.s('Created new Bankline payment')
        } else {
          this.$toastr.s('Updated Bankline payment')
        }

        this.updateFileSummary()
        this.$emit('saveBanklinePayment')
      } else {
        this.$toastr.e('Error saving Bankline payment')
        this.saveStarted = false
        this.disableButtons = false
      }
    },

    async deletePayment () {
      var result = await swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure want to delete this Bankline payment?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: colours.danger
      })

      if (result.isConfirmed) {
        this.disableButtons = true

        let json = JSON.stringify(this.payment)
        let response = await axios({
          method: 'DELETE',
          url: process.env.VUE_APP_BANKLINE_API_URL + 'bankline/payment/',
          data: json,
          showload: true
        })

        if (response.data.status !== 'Failed') {
          this.$toastr.s('Deleted Bankline payment')

          let numberOfCredits = parseInt(this.importResponse.totalNumberOfCredits)
          let currentAmount = parseFloat(this.payment.amount)
          let creditsValue = parseFloat(this.importResponse.totalCreditsValue)

          this.importResponse.totalNumberOfCredits = (--numberOfCredits).toString()
          this.importResponse.totalCreditsValue = (creditsValue - currentAmount).toString()
          this.$emit('deleteBanklinePayment')
        } else {
          this.$toastr.e('Error deleting Bankline payment')
          this.disableButtons = false
        }
      }
    },

    updateFileSummary () {
      let numberOfCredits = parseInt(this.importResponse.totalNumberOfCredits)
      let creditsValue = parseFloat(this.importResponse.totalCreditsValue)
      let currentAmount = parseFloat(this.origAmount)
      let newAmount = parseFloat(this.amount)

      if (this.newPayment === true) {
        this.importResponse.totalNumberOfCredits = (++numberOfCredits).toString()
        this.importResponse.totalCreditsValue = (creditsValue + newAmount).toString()
      } else {
        this.importResponse.totalCreditsValue = (creditsValue - currentAmount + newAmount).toString()
      }
    },

    async validateBankAccount () {
      if (this.sortCode.length === 6 && this.accountNumber.length === 8) {
        this.bankDetailsValidated = false
        this.validAccountNumber = false
        this.validSortCode = false
        this.accountNumberError = ''
        this.sortCodeError = ''
        const validateUrl = process.env.VUE_APP_PLATFORM_API_URL + 'BankAccounts/ValidateBankAccount'
        const data = { sortCode: this.sortCode, accountNumber: this.accountNumber }

        try {
          const response = await axios.post(validateUrl, data)
          var validationResult = response.data
          if (validationResult.valid) {
            this.validAccountNumber = true
            this.validSortCode = true
          } else {
            if (validationResult.errorCode === '1502') {
              this.validSortCode = true
              this.accountNumberError = validationResult.errorText
            } else {
              this.sortCodeError = validationResult.errorText
            }
          }

          this.bankId = validationResult.fid
          if (this.validSortCode) {
            await this.validateBacsDetails()
          }

          this.bankDetailsValidated = true
          this.$v.sortCode.$touch()
          this.$v.accountNumber.$touch()
        } catch (e) {
          console.log(e)
        }
      }
    },

    async validateBacsDetails () {
      if (this.networkType === 'BacstelIp' && this.sortCode.length === 6 && this.accountNumber.length === 8) {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount/GetBacsDetails'
        const data = { fid: this.bankId }
        try {
          const response = await axios.get(validateUrl, { params: data })
          var validationResult = response.data
          if (validationResult.transactionsDisallowedCr === true) {
            this.validSortCode = false
            this.sortCodeError = 'This sort code cannot be used for credits.'
          } else {
            this.validSortCode = true
            this.sortCodeError = ''
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },

  validations: {
    accountName: { required, maxLen: maxLength(18) },
    sortCode: { required, numeric, minLen: minLength(6), maxLen: maxLength(6) },
    accountNumber: { required, numeric, minLen: minLength(8), maxLen: maxLength(8) },
    userReference: {
      maxLen: maxLength(18)
    },
    amount: {
      required,
      decimal,
      between (value) {
        return between(this.minAmount, this.maxAmount)(value)
      }
    }
  }
}
</script>
