var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded)?_c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" Certificate Report Overview "),_c('favourite-icon')],1)]),_c('div',{staticClass:"idb-block-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('doughnut-chart',{attrs:{"chart-data":_vm.certificateChartData.data,"options":_vm.chartOptions,"height":300}})],1),_c('div',{staticClass:"col-md-6"},[_c('doughnut-chart',{attrs:{"chart-data":_vm.assignedCertificateChartData.data,"options":_vm.chartOptions,"height":300}})],1)])])]):_vm._e(),(_vm.expiredCertificateRows.some(() => true))?_c('div',{staticClass:"idb-block"},[_vm._m(0),_c('div',{staticClass:"idb-block-content"},[_c('vue-good-table',{attrs:{"paginationOptions":{
            enabled: true,
            perPage: 10,
          },"rows":_vm.expiredCertificateRows,"columns":_vm.columns,"searchOptions":{
            enabled: true
          },"lineNumbers":true,"styleClass":"vgt-table striped bordered"}})],1)]):_vm._e(),(_vm.expiringCertificateRows.some(() => true))?_c('div',{staticClass:"idb-block"},[_vm._m(1),_c('div',{staticClass:"idb-block-content"},[_c('vue-good-table',{attrs:{"paginationOptions":{
            enabled: true,
            perPage: 10,
          },"rows":_vm.expiringCertificateRows,"columns":_vm.columns,"searchOptions":{
            enabled: true
          },"lineNumbers":true,"styleClass":"vgt-table striped bordered"}})],1)]):_vm._e(),(_vm.certificateRows.some(() => true))?_c('div',{staticClass:"idb-block"},[_vm._m(2),_c('div',{staticClass:"idb-block-content"},[_c('vue-good-table',{attrs:{"paginationOptions":{
            enabled: true,
            perPage: 10,
          },"rows":_vm.certificateRows,"columns":_vm.columns,"searchOptions":{
            enabled: true
          },"lineNumbers":true,"styleClass":"vgt-table striped bordered"}})],1)]):_vm._e(),(_vm.loaded)?_c('div',{staticClass:"idb-block"},[_vm._m(3),_c('div',{staticClass:"idb-block-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col"},[_c('label',{staticClass:"required",attrs:{"for":"alertMonth"}},[_vm._v("Alert Period")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings.alertPeriod),expression:"settings.alertPeriod"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.settings, "alertPeriod", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("1 Month")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2 Months")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3 Months")])]),_c('small',[_vm._v("This will set the time before a certificate is set to expire, where it will start sending alerts, you will receive one per week")])]),_c('div',{staticClass:"form-group col"},[_c('label',[_vm._v("Users to Alert")]),_c('vue-select',{attrs:{"multiple":"","options":_vm.systemUsers,"closeOnSelect":false,"append-to-body":"","reduce":user => user.id,"calculate-position":_vm.withPopper},model:{value:(_vm.settings.usersToAlert),callback:function ($$v) {_vm.$set(_vm.settings, "usersToAlert", $$v)},expression:"settings.usersToAlert"}})],1)])]),_c('div',{staticClass:"idb-block-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.isLoading},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save Settings")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v("Expired Certificates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v("Expiring Certificates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v("Certificates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v("Certificate Report Alert Settings")])])
}]
render._withStripped = true
export { render, staticRenderFns }