<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Email Address Types</h2>
    </div>
    <div class="idb-block-content">
      <doughnut-chart :chart-data="emailChart.data" :options="emailChart.options" :height="300"></doughnut-chart>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import randomColor from 'randomcolor'
import DoughnutChart from '@/Assets/Components/Charts/DoughnutChart'
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    DoughnutChart
  },
  props: {
    sessionId: {
      type: String,
      required: false
    },
  },
  methods: {
    async load() {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/charts/emailtype/${this.sessionId}`)

      this.okCount = response.data.ok
      this.disabledCount = response.data.emailDisabled
      this.deadServerCount = response.data.deadServer
      this.invalidMxCount = response.data.invalidMx
      this.disposableCount = response.data.disposableCount
      this.spamTrapCount = response.data.spamTrap
      this.okForAllCount = response.data.okForAll
      this.smtpProtocolCount = response.data.smtpProtocol
      this.antispamSystemCount = response.data.antispam
      this.unknownCount = response.data.unknown
      this.invlaidSyntaxCount = response.data.invalidSyntax

      this.emailChart.data = {
        labels: ['Ok', 'Email Disabled', 'Dead Server', "Invalid MX", "Disposable", "Spam Trap", "Ok For All", "SMTP Protocol", "Anti-Spam System", "Unknown", "Invalid Syntax"],
        datasets: [{
          data: [this.okCount,
            this.disabledCount,
            this.deadServerCount,
            this.invalidMxCount,
            this.disposableCount,
            this.spamTrapCount,
            this.okForAllCount,
            this.smtpProtocolCount,
            this.antispamSystemCount,
            this.unknownCount,
            this.invlaidSyntaxCount],
          backgroundColor: [
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' }),
            randomColor({ luminosity: 'dark' })
          ]
        }]
      }
    }
  },
  data() {
    return {
      okCount:0,
      disabledCount:0,
      deadServerCount:0,
      invalidMxCount: 0,
      disposableCount:0,
      spamTrapCount:0,
      okForAllCount:0,
      smtpProtocolCount:0,
      antispamSystemCount:0,
      unknownCount: 0,
      invlaidSyntaxCount: 0,
      emailChart: {
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'bottom',
            // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
            onClick: function () { }
          }
        }
      },
    }
  },
  async mounted() {
    await this.load()
  }
}
</script>
