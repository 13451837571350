<template>
  <div class="idb-block">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block-title">
      <h2>
        Submission Details for '{{ bureauJob.bureauJobName }}'
        <help-icon :docPath="'/bacsbureau/jobs/'"></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="idb-block-content">
      <b-card
        v-if="badCustomersAvailable"
        bg-variant="warning"
        text-variant="white"
        style="margin-bottom: 0.8em; margin-right: 0.8em"
      >This bureau job has associated customers with no bank details set. This bureau job will fail to import with the current configuration.</b-card>
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="submissionReference">Submission Reference</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="submissionReference" type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.submissionReference.$dirty">
          <label id="submissionReferenceMissing"
            class="text-danger small"
            v-if="!$v.submissionReference.required"
          >A 'Submission Reference' is required</label>
          <label id="submissionReferenceTooLong"
            class="text-danger small"
            v-if="!$v.submissionReference.maxLength"
          >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="contraNarrative">Contra Narrative</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="contraNarrative" type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.contraNarrative.$dirty">
          <label
            class="text-danger small" id="contraNarrativeTooLong"
            v-if="!$v.contraNarrative.maxLength"
          >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <b-row class="mt-2" v-if="!isFps">
        <b-col sm="3" class="mt-2">
          <label for="paymentDate">Payment Date</label>
        </b-col>
        <b-col sm="3">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="paymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
            :disabled="disableDatePicker"
          ></vuejsDatepicker>
          <small>This is a default date for the submission that can be overridden for individual blocks.</small>
        </b-col>
      </b-row>

      <b-row class="mt-2" v-if="!isFps">
        <b-col sm="3" class="mt-2">
          <label for="processingDate">Processing Date</label>
        </b-col>
        <b-col id="processingDate" sm="3">{{ processingDate }}</b-col>
      </b-row>

      <b-row class="mt-2" v-if="finishedLoading && bureauJob.bureauJobDetailLines.length ===0">
        <b-col sm="3" class="mt-2">
          <small id="noDetailLines" class="form-text text-danger small">This job has no detail lines and will not run</small>
        </b-col>
      </b-row>
    </div>

    <div class="idb-block-footer">
      <button id="nextButton"
        class="btn btn-primary mr-2"
        :disabled="$v.$invalid || isTableLoading || bureauJob.bureauJobDetailLines.length ===0 || subInProcess || badCustomersAvailable"
        @click="onNextClick"
      >Next</button>
    </div>
  </div>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import colours from '@/Assets/Constants/colours'
import moment from 'moment/moment'

export default {
  mixins: [tableFilterMixin,
    bacsMixin,
    Utility,
    loading
  ],
  props: {
    id: String
  },
  data () {
    return {
      subInProcess: false,
      submissionReference: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      processingDates: {},
      disableDatePicker: false,
      readonly: false, // get rid of this
      bureauJob: {
        bureauJobDetailLines: []
      },
      // bureauJobName: '',
      // bureauServiceUserNumber: '',
      // groupId: '',

      rows: [],
      columns: [
        {
          label: 'Bureau Customer',
          field: 'bureauCustomerName'
        },
        {
          label: 'Filename',
          field: 'filename'
        }
      ],
      badCustomersAvailable: false,
      finishedLoading: false,
      isFps: false
    }
  },

  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    await this.GetBureauLicenseCheck()
    await this.load()
    await this.checkForBadCustomers()
    this.finishedLoading = true
  },

  async mounted () {
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/GetBureauJobDetails/' + this.id, {})
        this.bureauJob = response.data
        await this.$store.dispatch('getSubBacsGroup', this.bureauJob.groupId)
        this.bacsGroup = this.$store.getters.bacsGroup
        this.isFps = this.bacsGroup.groupType === 'FPS Bureau'
        this.rows = response.data.bureauJobDetailLines
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    async onNextClick () {
      const checkResponse = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getcountforjob/${this.id}`, {
        showload: true,
        params: { paygateid: this.paygateId }
      })
      if (checkResponse.data > 0) {
        this.subInProcess = true
        this.$toastr.e('This job is already being used in a submission.')
      } else {
        this.isTableLoading = true
        try {
          var data = {
            submissionReference: this.submissionReference,
            bureauJobId: this.bureauJob.bureauJobId,
            contraNarrative: this.contraNarrative,
            processingDate: this.processingDate,
            groupId: this.bureauJob.groupId
          }

          var sendupdata = JSON.stringify(data)
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/startbacsbureausubmission', sendupdata, { showload: true })

          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
            var bureauSubmissionId = response.data.id
            this.$store.dispatch('updateBureauTopSeverityLevel')
            this.$store.dispatch('updateBureauSubmissionPill')
            this.$router.push({ name: 'ImportPaymentFiles', params: { id: bureauSubmissionId, startProcess: true } })
          } else if (response.data.toastType === 1 && response.data.status === 'Action') {
            var SubmissionReference = response.data.errorText

            const swalResult = await swal.fire({
              title: 'Submission In Progress',
              html: 'This bureau job is already in progress with the submission reference <strong>' + SubmissionReference + '</strong>. Would you like to cancel or go to the submission in progress?',
              icon: 'warning',
              showCancelButton: true,
              focusCancel: true,
              confirmButtonColor: colours.danger,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel'
            })
            if (swalResult.isConfirmed) {
              await this.GoToSubmissionLastScreen(response.data.id)
            }
          } else {
            this.$toastr.e(response.data.toastMessage)
            this.isTableLoading = false
          }
          // this.$store.dispatch('setSubmission', data)
        } catch (error) {
          this.isTableLoading = false
          this.$toastr.e(`Bureau Submission ${data.submissionReference} failed ${error}`, 'Bureau Submission')
        }
      }
    },

    async GoToSubmissionLastScreen (BureauSubmissionId) {
      this.isTableLoading = true
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureausubmission/' + BureauSubmissionId, {})
      this.$store.dispatch('updateBureauTopSeverityLevel')
      this.$store.dispatch('updateBureauSubmissionPill')
      console.log('GetLastSubmission Response', response)

      if (response.data.bureauSubmissionId === '00000000-0000-0000-0000-000000000000') {
        this.$toastr.e('Bureau Submission has changed state or no longer exists. Please retry.', 'Bureau Submission')
      } else {
        switch (response.data.lastScreenState) {
          case 'BureauManualBacsAddInitialFiles':
            this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: BureauSubmissionId } })
            break
          case 'BureauManualFPSAddInitialFiles':
            this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: BureauSubmissionId } })
            break
          case 'BureauManualBacsImporting':
            this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauManualFPSImporting':
            this.$router.push({ name: 'ManualFPSImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauAutoBacsImporting':
            this.$router.push({ name: 'ImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauAutoFPSImporting':
            this.$router.push({ name: 'ImportPaymentFiles', params: { id: BureauSubmissionId, startProcess: false } })
            break
          case 'BureauBacsViewPayments':
            this.$router.push({ name: 'PaymentDetails', params: { id: BureauSubmissionId } })
            break
          case 'BureauFPSViewPayments':
            this.$router.push({ name: 'PaymentDetailsFPS', params: { id: BureauSubmissionId } })
            break
          case 'BureauActionsPage':
            var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${BureauSubmissionId}`, { showload: true })

            if (actionUrl.data) {
              this.$router.replace(returnRouteObject(actionUrl.data))
            } else {
              this.autoReturnToHomePage = true
              this.$router.push('/')
            }
            break
        }
      }
    },

    paymentDateChanged (value) {
      value = moment(value).format('DD/MM/YYYY')
      this.$store.dispatch('getNextProcessingDate', value)
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
        })
    },
    async checkForBadCustomers () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureaujob/nobankcustomerscheck/${this.bureauJob.bureauJobId}`)
        this.badCustomersAvailable = response.data
      } catch (e) {
        this.$toastr.e('Failed to check customer validity')
      }
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    }
  }

}

</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
