var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("help-icon", {
            attrs: { docPath: "/administration/customer/licence/" }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("pie-chart", {
                attrs: {
                  "chart-data": _vm.charts.user.data,
                  options: _vm.charts.user.options,
                  height: 300
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "col-md" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("pie-chart", {
                attrs: {
                  "chart-data": _vm.charts.group.data,
                  options: _vm.charts.group.options,
                  height: 300
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "col-md" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("pie-chart", {
                attrs: {
                  "chart-data": _vm.charts.serviceAccount.data,
                  options: _vm.charts.serviceAccount.options,
                  height: 300
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(3),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("BACS")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.bacs.value === "Both" ||
                          _vm.licence.bacs.value === "Direct"
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.licence.bacs.value === "Both" ||
                              _vm.licence.bacs.value === "Direct"
                          )
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("BACS Online")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.bacs.value === "Both" ||
                          _vm.licence.bacs.value === "Indirect"
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.licence.bacs.value === "Both" ||
                              _vm.licence.bacs.value === "Indirect"
                          )
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _vm.licence.bacs.value !== "Off"
                ? _c("div", { staticClass: "form-row" }, [
                    _vm.licence.bacs.value === "Both" ||
                    _vm.licence.bacs.value === "Direct"
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("Allow Bank Grade BACS")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.bacs.bankGrade)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.bacs.bankGrade))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.licence.bacs.value === "Both" ||
                    _vm.licence.bacs.value === "Indirect"
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("Allow Multi-Block BACS")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.bacs.proxyGroup)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.bacs.proxyGroup))
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.licence.bacs.value !== "Off"
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("BACS API")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.bacs.api)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.bacs.api))
                          ])
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("hr"),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("FPS")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.fps.value === "Both" ||
                          _vm.licence.fps.value === "Direct"
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.licence.fps.value === "Both" ||
                              _vm.licence.fps.value === "Direct"
                          )
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("FPS Online")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.fps.value === "Both" ||
                          _vm.licence.fps.value === "Indirect"
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.licence.fps.value === "Both" ||
                              _vm.licence.fps.value === "Indirect"
                          )
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _vm.licence.fps.value !== "Off"
                ? _c("div", { staticClass: "form-row" }, [
                    _vm.licence.fps.value === "Both" ||
                    _vm.licence.fps.value === "Indirect"
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("Allow Multi-Block FPS")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.bacs.proxyGroup)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.bacs.proxyGroup))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("FPS API")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.fps.api)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.fps.api))
                          ])
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(4),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Mapping")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.automation.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.automation.value))
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Mapping Designer")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.automation.mappingDesigner
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.automation.mappingDesigner))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Folder Manager")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.automation.folderManager)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.automation.folderManager))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Workflow")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.automation.workflow)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.automation.workflow))
                      ])
                    ]
                  )
                ]),
                _vm.licence.automation.workflow
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Workflow Designer")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(
                            _vm.licence.automation.workflowDesigner
                          )
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(
                              _vm._s(_vm.licence.automation.workflowDesigner)
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.licence.automation.workflow
                ? _c("div", { staticClass: "form-row" }, [
                    _vm.licence.automation.workflow
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("Frequency")]),
                          _c(
                            "span",
                            { staticClass: "form-control-plaintext" },
                            [_vm._v(_vm._s(_vm.licence.automation.frequency))]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Live Workflows")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.automation.liveWorkflows))
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(5),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.bureau.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.bureau.value))
                      ])
                    ]
                  )
                ]),
                _vm.licence.bureau.value
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Faster Payments Service (FPS)")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.bureau.fps)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.bureau.fps))
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.licence.bureau.value
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.bureau.threshold))
                      ])
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Bureau API")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.bureau.api)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.bureau.api))
                          ])
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(6),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("SMS Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.other.sms)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.other.sms))
                      ])
                    ]
                  )
                ]),
                _vm.licence.other.sms
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("SMS Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.other.smsThreshold))
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Email Limit")]),
                  _c("span", { staticClass: "form-control-plaintext" }, [
                    _vm._v(_vm._s(_vm.licence.other.emailThreshold))
                  ])
                ]),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Secure Cloud Storage")]),
                  _c("span", { staticClass: "form-control-plaintext" }, [
                    _vm._v(_vm._s(_vm.licence.other.storage + "GB"))
                  ])
                ])
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("SSO Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.other.sso)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.other.sso))
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(7),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.validate.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.validate.value))
                      ])
                    ]
                  )
                ]),
                _vm.licence.validate.value
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("UK Bank Account")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.validate.modCheck)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.validate.modCheck))
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.licence.validate.value
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("IBAN")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.validate.iban)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.validate.iban))
                          ])
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.licence.validate.value
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.validate.threshold))
                      ])
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("API")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.validate.api)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.validate.api))
                          ])
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(8),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.agent.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.agent.value))
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Use Own Certificate")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.agent.useOwnCertificate)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.agent.useOwnCertificate))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Allow FTP Address Change")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(
                        _vm.licence.agent.allowFtpAddressChange
                      )
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.agent.allowFtpAddressChange))
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(9),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.hsm.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.hsm.value))
                      ])
                    ]
                  )
                ]),
                _vm.licence.hsm.value
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("HSM Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.hsm.hsmThreshold))
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(10),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.antiFraud.enabled)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.antiFraud.enabled))
                      ])
                    ]
                  )
                ])
              ]),
              _vm.licence.antiFraud.enabled
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Whitelist Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.antiFraud.whitelistThreshold))
                      ])
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Blacklist Limit")]),
                      _c("span", { staticClass: "form-control-plaintext" }, [
                        _vm._v(_vm._s(_vm.licence.antiFraud.blacklistThreshold))
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(11),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("form", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("span", [_vm._v("Enabled")]),
                  _c(
                    "span",
                    {
                      staticClass: "fa form-control-plaintext",
                      class: _vm.getClass(_vm.licence.mfa.value)
                    },
                    [
                      _c("span", { staticClass: "sr-only ml-2" }, [
                        _vm._v(_vm._s(_vm.licence.mfa.value))
                      ])
                    ]
                  )
                ]),
                _vm.licence.mfa.value
                  ? _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("span", [_vm._v("Authenticator")]),
                      _c(
                        "span",
                        {
                          staticClass: "fa form-control-plaintext",
                          class: _vm.getClass(_vm.licence.mfa.authenticator)
                        },
                        [
                          _c("span", { staticClass: "sr-only ml-2" }, [
                            _vm._v(_vm._s(_vm.licence.mfa.authenticator))
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.licence.mfa.value
                ? _c("div", { staticClass: "form-row" }, [
                    _vm.licence.mfa.value
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("USB Tokens")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.mfa.usbTokens)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.mfa.usbTokens))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.licence.mfa.value
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("Email")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.mfa.emails)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.mfa.emails))
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.licence.mfa.value && _vm.licence.other.sms
                ? _c("div", { staticClass: "form-row" }, [
                    _vm.licence.mfa.value
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("span", [_vm._v("SMS")]),
                          _c(
                            "span",
                            {
                              staticClass: "fa form-control-plaintext",
                              class: _vm.getClass(_vm.licence.mfa.sms)
                            },
                            [
                              _c("span", { staticClass: "sr-only ml-2" }, [
                                _vm._v(_vm._s(_vm.licence.mfa.sms))
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("User Usage")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Group Usage")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Service Account Usage")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Payments")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Automation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Bureau")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Other")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Validation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Agent")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Hardware Security Module (HSM)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Anti-Fraud Module")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Multi-Factor Authentication (MFA)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }