<template>
  <div>
    <h3>Email Message<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/messaging/emailmessage/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>    <hr>
    <br/>
    <div class="form-group">
      <label class="required">Subject</label>
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The Subject field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label class="required">Message</label>
        <textarea class="form-control"
          rows="8"
          cols="40"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s2.value.$error}"
          @input="$v.selectedNode.props.s2.value.$touch()"
          v-model="selectedNode.props.s2.value">
        </textarea>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The message field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label class="required">To</label> <span class="text text-muted ml-1"><small>Comma separated list</small></span>
        <input type="text"
          class="form-control"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s3.value.$error}"
          @input="$v.selectedNode.props.s3.value.$touch()"
          v-model="selectedNode.props.s3.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">At least one 'To' email address is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.s3.$params.maxLength.max }} characters!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.customEmail && $v.selectedNode.props.s3.value.$dirty">One or more invalid email addresses!</p>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Cc</label><span class="text text-muted ml-1"><small>Comma separated list</small></span>
        <input type="text"
          class="form-control"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s4.value.$error}"
          @input="$v.selectedNode.props.s4.value.$touch()"
          v-model="selectedNode.props.s4.value">
          <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.s4.$params.maxLength.max }} characters!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.s4.value.customEmail">One or more invalid email addresses!</p>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Bcc</label><span class="text text-muted ml-1"><small>Comma separated list</small></span>
        <input type="text"
          class="form-control"
          :disabled="!hasManageRole"
          :class="{invalid: $v.selectedNode.props.s5.value.$error}"
          @input="$v.selectedNode.props.s5.value.$touch()"
          v-model="selectedNode.props.s5.value">
          <p class="validationText" v-if="!$v.selectedNode.props.s5.value.maxLength">Maximum length is {{ $v.s5.$params.maxLength.max }} characters!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.s5.value.customEmail">One or more invalid email addresses!</p>
      </div>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
export default {
  validations () {
    return {
      selectedNode: {
        props: {
          s1: {
            value: {
              required,
              maxLength: maxLength(256)
            }
          },
          s2: {
            value: {
              required,
              maxLength: maxLength(4096)
            }
          },
          s3: {
            value: {
              required,
              maxLength: maxLength(1024),
              customEmail: () => {
                let valid = true
                const emails = this.selectedNode.props.s3.value.split(',')
                if (emails.length > 0) {
                  for (const ea of emails) {
                    const isValid = this.validateEmail(ea)
                    if (!isValid) valid = false
                  }
                }
                return valid
              }
            }
          },
          s4: {
            value: {
              maxLength: maxLength(1024),
              customEmail: () => {
                let valid = true
                if (this.selectedNode.props.s4.value === '') {
                  return valid
                }
                const emails = this.selectedNode.props.s4.value.split(',')
                if (emails.length > 0) {
                  for (const ea of emails) {
                    const isValid = this.validateEmail(ea)
                    if (!isValid) valid = false
                  }
                }
                return valid
              }
            }
          },
          s5: {
            value: {
              maxLength: maxLength(1024),
              customEmail: () => {
                let valid = true
                if (this.selectedNode.props.s5.value === '') {
                  return valid
                }
                const emails = this.selectedNode.props.s5.value.split(',')
                if (emails.length > 0) {
                  for (const ea of emails) {
                    const isValid = this.validateEmail(ea)
                    if (!isValid) valid = false
                  }
                }
                return valid
              }
            }
          }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    validateEmail: function (email) {
      // Validate a single email address using some regex what I found on SO
      email = email.toLowerCase().trim()
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
