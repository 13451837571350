<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <h3>Mapping<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/other/mapping/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <!-- <div class="form-group">
      <label>Group</label>
      <br>
      <group-select v-model="selectedNode.props.s2.value" :groups="groupOptions" :class="{invalid: $v.selectedNode.props.s2.value.$error}"></group-select>
    </div>
    <br/> -->
    <div class="form-group">
      <label class="required">Mapping</label>
      <br>
      <select class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        :disabled="!hasManageRole"
        v-model="selectedNode.props.s1.value">
        <option v-for="option in mappingOptions" :key="option.value" :value="option.value">
        {{option.text}}
      </option>
    </select>
    </div>
    <br/>
    <label class="" >Override Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button" :disabled="!hasManageRole">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="">Override Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :disabled="!hasManageRole"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnPathBrowser" :disabled="!hasManageRole" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        :disabled="!hasManageRole"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import roles from '@/Assets/Constants/roles'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
import axios from 'axios'
import { maxLength } from 'vuelidate/lib/validators'
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            maxLength: maxLength(128)
          }
        },
        s3: {
          value: {
            maxLength: maxLength(255)
          }
        },
        s4: {
          value: {
            maxLength: maxLength(255)
          }
        }
      }
    }
  },
  computed: {
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
  },
  data () {
    return {
      showFolderBrowser: false,
      showModalFileBrowser: false,
      // groupOptions: [],
      mappingOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s3.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showModalFileBrowser = true
    },
    btnPathBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s3.value = filename
      if (!this.selectedNode.props.s4.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s4.value = folder
      }
    },
    closeFolderPopup () {
      this.selectedNode.props.s4.value = this.$store.state.mapping.modalPopupString
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    // let res
    // try {
    //   res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`)
    //   console.log('res.data.data')
    //   console.log(res.data.data)
    //   this.groupOptions = res.data.data
    // } catch (e) {
    //   this.$snapbar.e('Error getting list of available groups. (002)')
    // }
    let mappingResponse
    try {
      mappingResponse = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingtitles`)
      if (mappingResponse) {
        console.log('mappingResponse.data')
        console.log(mappingResponse.data)
        for (const mapping of mappingResponse.data.mappings) {
          this.mappingOptions.push({
            text: mapping.title,
            value: mapping._id
          })
        }
      }
    } catch (e) {
      this.$snapbar.e('Error getting list of available mappings')
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    console.log('selectedNode')
    console.log(this.selectedNode)
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
