import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/Assets/Components/Authentication/auth'

// Main routes
import CommonRoutes from '@/Assets/Routes/routes'
import AuthenticationRoutes from '@/Assets/Routes/authenticationRoutes'

// Module routes
import BacsRoutes from '@/Routes/bacs'
import CollectionRoutes from '@/Routes/collections'
import BureauRoutes from '@/Routes/bureau'
import WorkflowRoutes from '@/Routes/workflow'
import ValidateRoutes from '@/Routes/validate'
import PlatformRoutes from '@/Routes/platform'
import AboutRoutes from '@/Routes/about'
import BanklineRoutes from '@/Routes/bankline'

const Full = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Full')

auth.addGuardsAndInterceptors()

const router = new Router({
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		}
	},
	routes: [
		...AuthenticationRoutes.callbacks,
		{
			path: '/',
			component: Full,
			children: [
				...CommonRoutes,
				...BacsRoutes,
				...CollectionRoutes,
				...BureauRoutes,
				...WorkflowRoutes,
				...ValidateRoutes,
				...PlatformRoutes,
				...AboutRoutes,
				...BanklineRoutes,
				{
					path: '*',
					redirect: '/'
				}
			],
			meta: {
				anon: true
			}
		}
	]
})

router.beforeEach(async (to, from, next) => {
	if (to.matched.every((record) => record.meta.anon)) {
		next()
	} else {
		await auth.isLoggedIn(to, from, next, router.app)
	}
})

router.afterEach((to) => {
	// I hate the fact that we have to use next tick here but that just be what it is!
	Vue.nextTick(() => {
		if (to.meta.title) {
			document.title = `${process.env.VUE_APP_NAME} - ${to.meta.title}`
		} else if (to.path !== '/silentrenew') {
			document.title = process.env.VUE_APP_NAME
		}

		router.app.$store.dispatch('updateMenu')
	})
})

Vue.use(Router)

export default router
