<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>
							Usage Report
							<help-icon docPath="/administration/reports/usagereport/" />
							<favourite-icon />
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="form-row">
							<div class="form-group col-md-4">
								<label for="from" class="required">From</label>
								<vuejsDatepicker
									name="from"
									id="from"
									format="MMM/yyyy"
									input-class="form-control bg-white"
									:bootstrap-styling="true"
									minimumView="month"
									maximumView="year"
									initial-view="month"
									:use-utc="true"
									:disabledDates="fromDisabledDates"
									v-model="dates.from"
								></vuejsDatepicker>
							</div>
							<div class="form-group col-md-4">
								<label for="to" class="required">To</label>
								<vuejsDatepicker
									name="to"
									id="to"
									format="MMM/yyyy"
									input-class="form-control bg-white"
									:bootstrap-styling="true"
									minimumView="month"
									maximumView="year"
									initial-view="month"
									:use-utc="true"
									:disabledDates="dates.disabledDates"
									v-model="dates.to"
								></vuejsDatepicker>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-4">
								<label for="group" class="required">Select Group</label>
								<group-select
									id="group"
									v-model="selectedGroupId"
									:groups="groups"
									@input="selectGroup()"
									:clearable="true"
								></group-select>
							</div>
						</div>
					</div>
					<div class="idb-block-footer">
						<button type="submit" class="btn btn-primary" @click="load()" :disabled="isLoading">
							<i class="glyphicon ti-pie-chart rpad"></i>Generate Usage Report
						</button>

						<div class="ml-3 btn-group">
							<button
								type="button"
								class="btn btn-success dropdown-toggle"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								:disabled="isLoading || !dataLoaded"
							>Export as</button>
							<div class="dropdown-menu">
								<a class="dropdown-item" href="#" @click="saveAsJson">JSON</a>
								<a class="dropdown-item" href="#" @click="saveAsCsv">CSV</a>
							</div>
						</div>
						<div class="ml-3 btn-group">
							<button
								type="button"
								class="btn btn-success dropdown-toggle"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								:disabled="isLoading"
							>Export BACS CSV</button>
							<div class="dropdown-menu">
								<a class="dropdown-item" href="#" @click="downloadBacsStats">{{downloadStatsText}}</a>
								<a
									class="dropdown-item"
									href="#"
									@click="downloadAllCustomerBacsStats"
									v-if="isSystemUser"
								>Create BACS and FPS Stats CSV File For All Customers</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-md-4">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">SMS Sent</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="smsSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart :chart-data="charts.sms.data" :options="charts.sms.options"></bar-chart>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Emails Sent</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="emailSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart :chart-data="charts.email.data" :options="charts.email.options"></bar-chart>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Address Lookups</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="addressLookupSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart :chart-data="charts.addressLookup.data" :options="charts.addressLookup.options"></bar-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-4 mb-4">
			<div class="col-md-12">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Storage</h5>
						<div class="progress" v-if="charts.space.any">
							<div
								class="progress-bar bg-success"
								role="progressbar"
								:style="{width: charts.space.private.percent + '%'}"
								v-b-popover.top.hover="`Privata Data - ${charts.space.private.value} - ${roundToTwo(charts.space.private.percent)}%`"
							>Private Data {{charts.space.private.value}}</div>
							<div
								class="progress-bar"
								role="progressbar"
								:style="{width: charts.space.public.percent + '%'}"
								v-b-popover.top.hover="`Public Data - ${charts.space.public.value} - ${roundToTwo(charts.space.public.percent)}%`"
							>Public Data {{charts.space.public.value}}</div>
							<div
								class="progress-bar bg-info"
								role="progressbar"
								:style="{width: charts.space.free.percent + '%'}"
								v-b-popover.top.hover="`Free Space - ${charts.space.free.value} - ${roundToTwo(charts.space.free.percent)}%`"
							>Free Space {{charts.space.free.value}}</div>
						</div>
						<div v-else>You do not have any storage space allocated</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-md-12">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Validations</h5>
						<div>
							Total -
							<i-count-up :delay="1" :endVal="report.validate.total" :options="counterOptions"></i-count-up>
						</div>
						<div>
							<bar-chart :chart-data="charts.validate.data" :options="charts.validate.options"></bar-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- BACS stats block -->
		<div class="row mt-4">
			<div class="col">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">BACS Submission Count {{ selectedGroupName }}</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="bacsSubmissionsSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart
								:chart-data="charts.bacsSubmissionCount.data"
								:options="charts.bacsSubmissionCount.options"
							></bar-chart>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">BACS Transaction Count {{ selectedGroupName }}</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="bacsTransactionsSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart
								:chart-data="charts.bacsTransactionCount.data"
								:options="charts.bacsTransactionCount.options"
							></bar-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- FPS stats block -->
		<div class="row mt-4">
			<div class="col">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Faster Payment Submission Count {{ selectedGroupName }}</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="fpsSubmissionsSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart
								:chart-data="charts.fpsSubmissionCount.data"
								:options="charts.fpsSubmissionCount.options"
							></bar-chart>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="idb-block text-center">
					<div class="idb-block-content">
						<h5 class="card-title">Faster Payment Transaction Count {{ selectedGroupName }}</h5>
						<div>
							<h2 class="card-count">
								<i-count-up :delay="1" :endVal="fpsTransactionsSent" :options="counterOptions"></i-count-up>
							</h2>
							<bar-chart
								:chart-data="charts.fpsTransactionCount.data"
								:options="charts.fpsTransactionCount.options"
							></bar-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-md-12">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Usage Report Table</h2>
					</div>
					<div class="idb-block-content">
						<vue-good-table
              ref="table"
							:paginationOptions="paginationOptions"
							:isLoading.sync="isTableLoading"
							:rows="rows"
							:columns="columns"
							mode="remote"
							:totalRows="totalRecords"
							@on-page-change="onPageChange"
							@on-column-filter="onColumnFilter"
							@on-per-page-change="onPerPageChange"
							styleClass="vgt-table striped bordered"
						></vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Third Party
import axios from 'axios'
import ICountUp from 'vue-countup-v2'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'
import _ from 'lodash'

// Helpers
import interpolateColors from '@/Assets/Helpers/ColourHelper'

// Charts
import BarChart from '@/Assets/Components/Charts/BarChart'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'
import bacsMixin from '@/Lib/BacsMixin.js'

// Constants
import roles from '@/Assets/Constants/roles'
import UsageTypes from '@/Components/Platform/Reports/Billing/UsageType.js'
import ValidateApiCall from '@/Components/Platform/Reports/Billing/ValidateApiCall.js'

export default {
	mixins: [loading, bacsMixin],
	name: 'UsageReport',
	components: {
		ICountUp,
		BarChart
	},
	computed: {
		smsSent () {
			return this.report.sms.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		emailSent () {
			return this.report.email.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		addressLookupSent () {
			return this.report.addressLookup.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		bacsSubmissionsSent () {
			return this.report.bacsSubmissionCount.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		bacsTransactionsSent () {
			return this.report.bacsTransactionCount.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		fpsSubmissionsSent () {
			return this.report.fpsSubmissionCount.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		fpsTransactionsSent () {
			return this.report.fpsTransactionCount.reduce((a, c) => { a += c.amount; return a }, 0)
		},
		fromDisabledDates () {
			return { from: moment(this.dates.to).toDate() }
		},
		downloadStatsText () {
			return this.isSystemUser ? 'Create BACS and FPS Stats CSV File For Selected Customer' : 'Create BACS and FPS Stats CSV File'
		},
		...mapGetters(['selectedCustomer'])
	},
	watch: {
		selectedCustomer () {
			this.load()
			this.loadGroups()
		}
	},
	data () {
		return {
			dates: {
				from: null,
				to: null,
				disabledDates: {
					from: null
				}
			},
			report: {
				addressLookup: [],
				email: [],
				validate: {
					stats: [],
					total: 0
				},
				sms: [],
				space: {
					privateData: 0,
					publicData: 0,
					totalSpace: 0
				},
				bacsSubmissionCount: [],
				bacsTransactionCount: [],
				fpsSubmissionCount: [],
				fpsTransactionCount: []
			},
			charts: {
				sms: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: true
					}
				},
				email: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: true
					}

				},
				addressLookup: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: true
					}

				},
				validate: {
					data: {
						datasets: []
					},
					options: {
						legend: {
							display: true
						},
						scales: {
							xAxes: [{

								offset: 'true',
								stacked: 'true'
							}],
							yAxes: [{
								stacked: 'true'
							}]
						},
						responsive: true,
						maintainAspectRatio: false
					}

				},
				space: {
					private: {
						value: 0,
						percent: 0
					},
					public: {
						value: 0,
						percent: 0
					},
					free: {
						value: 0,
						percent: 0
					},
					any: true
				},
				bacsSubmissionCount: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: false
					}
				},
				bacsTransactionCount: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: false
					}
				},
				fpsSubmissionCount: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: false
					}
				},
				fpsTransactionCount: {
					data: {},
					options: {
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								time: {
									unit: 'month',
									tooltipFormat: 'MMM YYYY'
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						},
						responsive: true,
						maintainAspectRatio: false
					}
				}
			},
			counterOptions: {
				startVal: 0,
				duration: 4,
				useEasing: true,
				useGrouping: true,
				separator: ',',
				decimal: '.',
				prefix: '',
				suffix: ''
			},
			selectedGroupId: '00000000-0000-0000-0000-000000000000',
			selectedGroupName: '(all groups)',
			groups: [],
			isSystemUser: false,
			dataLoaded: false,
			rows: [],
			columns: [{
				label: 'Amount',
				field: 'amount',
				type: 'number',
				sortable: false
			},
			{
				label: 'Type',
				field: 'type',
				filterOptions: {
					enabled: true,
					placeholder: 'All Types',
					filterDropdownItems: Object.values(UsageTypes)
				},
				sortable: false
			},
			{
				label: 'Extra Data',
				field: 'data',
				sortable: false
			},
			{
				label: 'Date',
				field: 'date',
				type: 'date',
				dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
				dateOutputFormat: 'dd/MM/yyyy HH:mm',
				sortable: false
			}],
			isTableLoading: true,
			serverParams: {
				columnFilters: {},
				page: 1,
				perPage: 10
			},
			totalRecords: 0,
			sortOptions: { enabled: false },
			paginationOptions: { enabled: true, perPage: 10, setCurrentPage: 1 }
		}
	},
	methods: {
		async populateValidateChart () {
			this.charts.validate.data = { datasets: [] }
			var labels = this.generateMonthLabels()
			this.charts.validate.data.labels = labels
			this.charts.validate.data.datasets.push(
				{
					label: 'Credit Card Validations',
					data: labels.map(m => {
						var result = this.report.validate.creditCardValidations.find(v => v.month === m)

						if (!result) {
							return 0
						}

						return result.amount
					}),
					backgroundColor: 'rgba(0, 255, 255)',
					categoryPercentage: 0.3,
					barPercentage: 1
				}
			)

			this.charts.validate.data.datasets.push(
				{
					label: 'Bank Account Validations',
					data:
						labels.map(m => {
							var result = this.report.validate.bankAccountValidations.find(v => v.month === m)

							if (!result) {
								return 0
							}

							return result.amount
						}),
					backgroundColor: 'rgba(85, 170, 255)',
					categoryPercentage: 0.3,
					barPercentage: 1
				}
			)

			this.charts.validate.data.datasets.push(
				{
					label: 'IBAN Validations',
					data:
						labels.map(m => {
							var result = this.report.validate.ibanValidations.find(v => v.month === m)

							if (!result) {
								return 0
							}

							return result.amount
						}),
					backgroundColor: 'rgba(170, 85, 255)',
					categoryPercentage: 0.3,
					barPercentage: 1
				}
			)

			this.charts.validate.data.datasets.push(
				{
					label: 'Confirmation Of Payer Validations',
					data:
						labels.map(m => {
							var result = this.report.validate.coPValidations.find(v => v.month === m)

							if (!result) {
								return 0
							}

							return result.amount
						}),
					backgroundColor: 'rgba(73,129,83)',
					categoryPercentage: 0.3,
					barPercentage: 1
				}
			)
		},
		async populateChart (type, colourStart, colourEnd) {
			var colours = interpolateColors(colourStart, colourEnd, this.report[type].length + 1)
			this.charts[type].data = {
				datasets: [{
					data: this.report[type].map((v) => { return { x: moment(v.month, 'MMM YYYY').toDate(), y: v.amount } }),
					backgroundColor: colours
				}]
			}
		},
		async populateSpace () {
			// Prv Data

			if (this.report.space.totalSpace > 0) {
				this.charts.space.any = true
				this.charts.space.private.percent = this.report.space.privateData / this.report.space.totalSpace * 100
				this.charts.space.public.percent = this.report.space.publicData / this.report.space.totalSpace * 100
				this.charts.space.free.percent = 100 - (this.charts.space.public.percent + this.charts.space.private.percent)

				var freeValue = this.report.space.totalSpace - (this.report.space.publicData + this.report.space.privateData)

				this.charts.space.private.value = this.highestByteValueString(this.report.space.privateData)
				this.charts.space.public.value = this.highestByteValueString(this.report.space.publicData)
				this.charts.space.free.value = this.highestByteValueString(freeValue)
			} else {
				this.charts.space.any = false
			}
		},
		async load () {
			try {
				if (this.selectedGroupId === null || this.selectedGroupId === undefined || this.selectedGroupId === '') {
					this.selectedGroupId = '00000000-0000-0000-0000-000000000000'
					this.selectedGroupName = '(all groups)'
				}
				this.$Progress.start()
				var reportResponse = await Promise.allSettled([
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Sms`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Email`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/AddressLookup`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Bacs`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Fps`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Validate`, { from: this.dates.from, to: this.dates.to, groupId: this.selectedGroupId }, { showerror: true, errormessage: 'Failed to load usage report' }),
					axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Space`, null, { showerror: true, errormessage: 'Failed to load usage report' })
				]
				)
				this.report = {
					sms: reportResponse[0].value.data,
					email: reportResponse[1].value.data,
					addressLookup: reportResponse[2].value.data,
					bacsSubmissionCount: reportResponse[3].value.data.bacsSubmissionCount,
					bacsTransactionCount: reportResponse[3].value.data.bacsTransactionCount,
					fpsSubmissionCount: reportResponse[4].value.data.fpsSubmissionCount,
					fpsTransactionCount: reportResponse[4].value.data.fpsTransactionCount,
					validate: reportResponse[5].value.data,
					space: reportResponse[6].value.data
				}
			} catch {
				this.$Progress.fail()
			} finally {
				this.dataLoaded = true
				this.$Progress.finish()
			}

			this.populateChart('sms', 'rgb(0, 255, 255)', 'rgb(85, 170, 255)')
			this.populateChart('email', 'rgb(85, 170, 255)', 'rgb(170, 85, 255)')
			this.populateChart('addressLookup', 'rgb(170, 85, 255)', 'rgb(255, 0, 255)')
			this.populateChart('bacsSubmissionCount', 'rgb(214, 28, 28)', 'rgb(130, 73, 204)')
			this.populateChart('bacsTransactionCount', 'rgb(227,245,66)', 'rgb(153,166,43)')
			this.populateChart('fpsSubmissionCount', 'rgb(214, 28, 28)', 'rgb(130, 73, 204)')
			this.populateChart('fpsTransactionCount', 'rgb(227,245,66)', 'rgb(153,166,43)')
			this.populateSpace()
			this.populateValidateChart()
			this.populateTable()
		},
		roundToTwo (num) {
			return +(Math.round(num + 'e+2') + 'e-2')
		},
		highestByteValueString (bytes) {
			var result = bytes / 1024

			if (result < 1024) {
				return `${this.roundToTwo(result)}KB`
			}

			result = result / 1024

			if (result < 1024) {
				return `${this.roundToTwo(result)}MB`
			}

			result = result / 1024

			if (result < 1024) {
				return `${this.roundToTwo(result)}GB`
			}

			if (result < 1024) {
				return `${this.roundToTwo(result)}TB`
			}

			return result
		},
		async loadGroups () {
			let paygateId = ''
			if (this.$store.getters.isInRole(roles.SystemUser)) {
				paygateId = this.selectedCustomer
			} else {
				paygateId = this.$store.getters.getClaim('paygate_id').value
			}

			var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/dropdown/${paygateId}`)
			response.data.map(group => {
				var grp = { groupId: group.id, name: group.label }
				this.groups.push(grp)
			})
			this.groups.sort((a, b) => a.name.localeCompare(b.name))
		},
		async selectGroup () {
			const selectedGroup = this.groups.find(i => i.groupId === this.selectedGroupId)
			this.selectedGroupName = 'for \'' + selectedGroup.name + '\''
		},
		async downloadBacsStats () {
			let paygateId = ''
			if (this.$store.getters.isInRole(roles.SystemUser)) {
				paygateId = this.selectedCustomer
			} else {
				paygateId = this.$store.getters.getClaim('paygate_id').value
			}
			try {
				var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/report/createBacsStatsCsvFile`, { createdFrom: this.dates.from, createdTo: this.dates.to, paygateId: paygateId }, { responseType: 'blob', showload: true, showerror: true, errormessage: 'Failed to download BACS stats' })
				this.saveFile(response)
			} catch { }
		},
		async downloadAllCustomerBacsStats () {
			try {
				if (this.isSystemUser) {
					var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/report/createBacsStatsCsvFile`, { createdFrom: this.dates.from, createdTo: this.dates.to }, { responseType: 'blob', showload: true, showerror: true, errormessage: 'Failed to download BACS stats' })
					this.saveFile(response)
				}
			} catch { }
		},
		async saveAsJson () {
			try {
				this.$Progress.start()
				const now = new Date()

				var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/JSON`, { from: this.dates.from, to: this.dates.to, paygateId: this.id }, { showload: true, showerror: true, errormessage: 'Failed to download usage report JSON' })
				var file = new Blob([JSON.stringify(response.data)], { type: 'text/plain' })
				saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-billing-report.json`)
			} catch {
				this.$Progress.fail()
			} finally {
				this.$Progress.finish()
			}
		},
		async saveAsCsv () {
			try {
				const now = new Date()
				var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/CSV`, { from: this.dates.from, to: this.dates.to, paygateId: this.id }, { showload: true, showerror: true, errormessage: 'Failed to download usage report CSV' })
				var file = new Blob([response.data], { type: 'text/csv' })
				saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-usage-report.csv`)
			} catch { }
		},
		generateMonthLabels () {
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
			var from = moment(this.dates.from)
			var to = moment(this.dates.to)
			var result = []
			to.add(1, 'month')

			do {
				result.push(`${months[from.month()]} ${from.year()}`)
				from = from.add(1, 'month')
			} while (from.isBefore(to))

			return result
		},
		async populateTable () {
			try {
				this.isTableLoading = true
				var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Usage/Table`, { from: this.dates.from, to: this.dates.to, ...this.buildGoodTableQuery() }, { showload: true, showerror: true, errormessage: 'Failed to load usage report table' })
				this.totalRecords = response.data.total

				var validateValues = Object.values(ValidateApiCall)
				var validateKeys = Object.keys(ValidateApiCall)

				this.rows = response.data.data.map(d => {
					var extraData = ''

					if (d.data) {
						d.data = JSON.parse(d.data)
						if (d.type === UsageTypes.Validate) {
							var validateIndexOfType = validateValues.indexOf(d.data.apiCall)
							var error = ''
							if (d.data.errorCode !== '') {
								error = `, Error Code: ${d.data.errorCode}, Error Text ${d.data.errorText}`
							}
							extraData = `Call Type: ${validateKeys[validateIndexOfType]}${error}`
						}
					}

					return {
						amount: d.amount,
						type: d.type,
						date: d.date,
						data: extraData
					}
				})
			} catch { } finally {
				this.isTableLoading = false
			}
		},
		onPageChange (params) {
			this.updateParams({ page: params.currentPage })
		},
		onColumnFilter (params) {
			this.updateParams({ ...params, page: 1 })
		},
		onPerPageChange (params) {
			this.$refs.table.currentPage = 1
			this.updateParams({ perPage: params.currentPerPage, page: 1 })
		},
		updateParams: _.debounce(function (newProps) {
			this.serverParams = Object.assign({}, this.serverParams, newProps)
			this.populateTable()
		}, 100),
		buildGoodTableQuery () {
			return { skip: this.serverParams.perPage * (this.serverParams.page - 1), take: this.serverParams.perPage, UsageType: this.serverParams.columnFilters.type }
		}
	},
	async created () {
		var thisMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 })
		this.dates.to = moment(thisMonth).toDate()
		this.dates.from = moment(thisMonth).subtract(3, 'month').toDate()
		this.dates.disabledDates.from = thisMonth.toDate()
		this.isSystemUser = this.$store.getters.isInRole(roles.SystemUser)
		await this.load()
		await this.loadGroups()
	}
}
</script>

<style lang="scss" scoped>
.progress-bar {
	overflow: hidden;
}
</style>
