<template>
	<div>
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						Mapping Detail
						<span class="pull-right">
							<a :href="`${docUrl}/automation/paygate-mapping/mappingdetail/`" target="_blank">
								<i class="far fa-question-circle"></i>
							</a>
						</span>
						<span class="pull-right">
							<b-button class="mr-3 mb-1" size="sm" variant="primary" @click="btnEditMapping">
								<i class="glyphicon ti-pencil mr-2"></i>Edit Mapping
							</b-button>
						</span>
					</h2>
				</div>
				<div class="idb-block-content">
					<div class="row">
						<div class="col-md-2 col-sm-12">
							<strong>Title</strong>
						</div>
						<div class="col-md-3 col-sm-12">{{mapping.metaData.title}}</div>
						<div class="col-md-2 col-sm-12">
							<strong>Description</strong>
						</div>
						<div class="col-md-3 col-sm-12">{{mapping.metaData.description}}</div>
						<div class="col-md-2 col-sm-12">
							<!-- <b-button variant="primary" @click="btnEditMapping"><i class="glyphicon ti-pencil mr-2"></i>Edit Mapping</b-button> -->
						</div>
					</div>
					<br />
					<div class="row">
						<div class="col-md-2 col-sm-12">
							<strong>Mapping Type</strong>
						</div>
						<div class="col-md-3 col-sm-12">{{reformatType(mapping.metaData.type)}}</div>
						<div class="col-md-2 col-sm-12">
							<strong>Author</strong>
						</div>
						<div class="col-md-3 col-sm-12">{{mapping.metaData.author}}</div>
					</div>
					<br />
					<div class="row">
						<div class="col-md-2 col-sm-12">
							<strong>Creation Date</strong>
						</div>
						<div class="col-md-3 col-sm-12">{{reformatDate(mapping.creationDate)}}</div>
						<div class="col-md-2 col-sm-12">
							<strong>Last Executed Date</strong>
						</div>
						<div class="col-md-4 col-sm-12">{{reformatDate(mapping.lastExecutionDate)}}</div>
					</div>
				</div>
			</div>
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						Heatmap
						<span>
							<b-button
								@click.prevent="getHeatmapData"
								class
								variant="link"
								v-b-popover.hover.top.d500="'Refresh the data in the heatmap'"
							>
								<i class="fas fa-sync-alt pointer"></i>
							</b-button>
						</span>
					</h2>
				</div>
				<div class="idb-block-content">
					<calendar-heatmap
						tooltip-unit="executions"
						@day-click="heatmapClick"
						:endDate="getHeatmapEnddate()"
						:values="activityData"
						:range-color="rangeColours"
					/>
				</div>
			</div>
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						Mapping Log
						<span v-if="!showAll">{{justDate(targetDay)}}</span>
					</h2>
				</div>
				<div class="idb-block-content">
					<vue-good-table
						:paginationOptions="paginationOptions"
						:sort-options="sortOptions"
						:isLoading.sync="isTableLoading"
						:rows="rows"
						:columns="columns"
						mode="remote"
						:totalRows="totalRecords"
						@on-page-change="onPageChange"
						@on-sort-change="onSortChange"
						@on-column-filter="onColumnFilter"
						@on-per-page-change="onPerPageChange"
						@on-search="onSearch"
						ref="triggerTelemetry"
						:lineNumbers="true"
						styleClass="vgt-table striped bordered table-hover"
						@on-row-click="onRowClick"
						@on-cell-click="onCellClick"
					>
						styleClass="vgt-table striped bordered table-hover">
						<template
							slot="table-row"
							slot-scope="props"
						>
							<span v-if="props.column.field == 'currentStatus'">
								<b-badge
									pill
									v-if="props.row.currentStatus == 'Failed'"
									variant="danger"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Complete'"
									variant="success"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Processing'"
									variant="info"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Completed with warnings'"
									variant="warning"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Manual Action'"
									variant="primary"
								>{{props.row.currentStatus}}</b-badge>
								<span v-else>{{props.row.currentStatus}}</span>
							</span>
							<span
								v-else-if="props.column.field == 'createdAt'"
							>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
							<span
								v-else-if="props.column.field == 'updatedAt'"
							>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
							<!-- <span v-else-if = "props.column.field == 'title'">
                  <b-button @click.prevent="viewMapping(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
							</span>-->
							<span v-else>{{props.formattedRow[props.column.field]}}</span>
						</template>
						<div slot="table-actions">
							<button
								@click.prevent="clearTableFilters"
								class="btn btn-link"
								v-b-popover.hover.top.d500="'Clear filters'"
							>
								<span class="fa-stack" style="font-size: 10px;">
									<i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
									<i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
								</span>
								<span class="sr-only">Clear filters</span>
							</button>
							<b-button
								@click.prevent="setShowToday"
								class
								variant="link"
								v-b-popover.hover.top.d500="'Show Today'"
							>
								<i class="far fa-calendar pointer dimmedIcon"></i>
								<span class="sr-only">Show Table</span>
							</b-button>
							<!-- <b-button @click.prevent="setShowAll" class="" variant="link" v-b-popover.hover.top.d500="'Show all days'" ><i class="far fa-calendar-alt pointer dimmedIcon"></i></b-button> -->
							<b-button
								@click.prevent="load"
								class
								variant="link"
								v-b-popover.hover.top.d500="'Refresh the data in the table'"
							>
								<i class="fas fa-sync-alt pointer dimmedIcon"></i>
								<span class="sr-only">Refresh Table</span>
							</b-button>
						</div>
					</vue-good-table>
				</div>
			</div>
			<div class="idb-block mb-5">
				<div class="idb-block-title">
					<span>
						Mapping Usage
						<b-button
							@click.prevent="getBindings"
							class
							variant="link"
							v-b-popover.hover.top.d500="'Refresh the list of bound items.'"
						>
							<i class="fas fa-sync-alt pointer"></i>
						</b-button>
					</span>
				</div>
				<div class="idb-block-content">
					<div v-if="boundGroups && boundGroups.length > 0">
						<div class="row">
							<span>
								<span v-if="boundGroups" class="col-md-12 col-sm-12 ml-3">
									<strong>Groups using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">{{boundGroups.length}}</span>
								</span>
								<span v-else class="col-md-12 col-sm-12 ml-3">
									<strong>Groups using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">0</span>
								</span>
							</span>
						</div>
						<div v-if="boundGroups">
							<hr />
							<div class="row" v-for="w in boundGroups" :key="w.index" :value="w.value">
								<div class="col-md-12 ml-5">
									{{w.name}} (
									<router-link tag="a" :to="'/admin/groups/edit/' + w.groupid" class="small">Edit</router-link>)
								</div>
							</div>
						</div>
						<br />
						<br />
					</div>
					<div v-if="boundWorkflows && boundWorkflows.length > 0">
						<div class="row">
							<span>
								<span v-if="boundWorkflows" class="col-md-12 col-sm-12 ml-3">
									<strong>Workflows using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">{{boundWorkflows.length}}</span>
								</span>
								<span v-else class="col-md-12 col-sm-12 ml-3">
									<strong>Workflows using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">0</span>
								</span>
							</span>
						</div>
						<div v-if="boundWorkflows">
							<hr />
							<div class="row" v-for="w in boundWorkflows" :key="w.index" :value="w.value">
								<div class="col-md-12 ml-5">
									{{w.metaData.title}} (
									<router-link
										tag="a"
										:to="'/automation/workflow/workflowdetail/' + w._id"
										class="small"
									>Edit</router-link>)
								</div>
							</div>
						</div>
						<br />
						<br />
					</div>
					<div v-if="boundBureauCustomers && boundBureauCustomers.length > 0">
						<div class="row" v-if="isBureauLicenced">
							<span>
								<span v-if="boundBureauCustomers && boundBureauCustomers" class="col-md-12 col-sm-12 ml-3">
									<strong>Bureau Customers using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">{{boundBureauCustomers.length}}</span>
								</span>
								<span v-else class="col-md-12 col-sm-12 ml-3">
									<strong>Bureau Customers using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">0</span>
								</span>
							</span>
						</div>
						<div v-if="isBureauLicenced && boundBureauCustomers && boundBureauCustomers">
							<hr />
							<div class="row" v-for="w in boundBureauCustomers" :key="w.index" :value="w.name">
								<div class="col-md-12 ml-5">
									{{w.name}} (
									<router-link
										tag="a"
										:to="'/bureau/bureauCustomers/details/' + w.bureaucustomerid"
										class="small"
									>Edit</router-link>)
								</div>
							</div>
						</div>
						<br />
						<br />
					</div>
					<div v-if="boundBureauJobs && boundBureauJobs.length > 0">
						<div class="row" v-if="isBureauLicenced">
							<span>
								<span v-if="boundBureauJobs && boundBureauJobs" class="col-md-12 col-sm-12 ml-3">
									<strong>Bureau Jobs using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">{{boundBureauJobs.length}}</span>
								</span>
								<span v-else class="col-md-12 col-sm-12 ml-3">
									<strong>Bureau Jobs using this mapping</strong>
									<span class="badge badge-pill badge-primary ml-2">0</span>
								</span>
							</span>
						</div>
						<div v-if="isBureauLicenced && boundBureauJobs">
							<hr />
							<div class="row" v-for="w in boundBureauJobs" :key="w.index" :value="w.name">
								<div class="col-md-12 ml-5">
									{{w.name}} (
									<router-link tag="a" :to="'/bureau/bureaujobs/manage/' + w.bureaujobid" class="small">Edit</router-link>)
								</div>
							</div>
						</div>
					</div>
					<br />
				</div>
				<div class="idb-block-footer">
					<b-button variant="primary" @click="btnEditMapping">
						<i class="glyphicon ti-pencil mr-2"></i>Edit Mapping
					</b-button>
					<div class="btn-group" role="group">
						<button
							id="btnGroupDrop1"
							type="button"
							class="btn btn-outline-primary dropdown-toggle ml-3"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<i class="glyphicon ti-more mr-2"></i>
						</button>
						<div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
							<a @click="btnCloneMapping" class="dropdown-item" href="#">
								<i class="glyphicon ti-files mr-2"></i>Clone Mapping
							</a>
							<a v-if="isCorvidAdmin" @click="btnTransferMapping" class="dropdown-item" href="#">
								<i class="glyphicon ti-new-window mr-2"></i>Copy Mapping to Another Customer
							</a>
							<div class="dropdown-divider"></div>
							<a @click.prevent="btnDeleteMapping" class="dropdown-item text-danger" href="#">
								<i class="glyphicon ti-trash mr-2"></i>Delete Mapping
							</a>
						</div>
					</div>
					<!-- <b-button class="ml-3" variant="outline-primary" @click="btnCloneMapping"><i class="glyphicon ti-layers mr-2"></i>Clone Mapping</b-button>
          <b-button v-if="isCorvidAdmin" class="ml-3" variant="outline-primary" @click="btnTransferMapping"><i class="glyphicon ti-new-window mr-2"></i>Copy Mapping to Another Customer</b-button>
					<b-button @click.prevent="btnDeleteMapping" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this mapping.'"><i class="glyphicon ti-trash mr-2"></i>Delete Mapping</b-button>-->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { CalendarHeatmap } from 'vue-calendar-heatmap'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import { mapGetters } from 'vuex'
import roles from '@/Assets/Constants/roles'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'
export default {
	name: 'MappingDetail',
	mixins: [tableFilterMixin, loading],
	components: {
		CalendarHeatmap,
		swal
	},
	computed: {
		isBureauLicenced () {
			return Store.getters.hasLicence(licence.bureau)
		},
		isSystemAdmin () {
			return this.$store.getters.isInRole(roles.SystemUser)
		},
		isCorvidAdmin () {
			return this.$store.getters.isInRole(roles.SystemUser)
		},
		rows: function () {
			return this.row_data
		},
		rangeColours () {
			if (this.$store.getters.getClaim('theme').value === 'dark') {
				return ['#777777', '#136fc9', '#0e59a4', '#12427a', '#0b224f']
			}

			return ['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']
		},
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		},
		...mapGetters(['selectedCustomer'])
	},
	data () {
		return {
			boundGroups: [],
			boundWorkflows: [],
			boundJobs: [],
			boundBureauCustomers: [],
			boundBureauJobs: [],
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			canViewTelemetry: true,
			mappingTitle: 'My Mapping',
			id: this.$route.params.id,
			mapping: {
				metaData: {}
			},
			activityData: [],
			columns: [
				{
					label: 'Id',
					field: '_id',
					hidden: true
				},
				{
					label: 'executionId',
					field: 'executionId',
					hidden: true
				},
				{
					label: 'Instance',
					field: 'title',
					filterOptions: { enabled: false },
					sortable: false
				},
				{
					label: 'Type',
					field: 'type',
					filterOptions: { enabled: false },
					sortable: false,
					hidden: true
				},
				{
					label: 'Status',
					field: 'currentStatus',
					filterOptions: {
						enabled: false
					}
				},
				{
					label: 'Created At',
					field: 'createdAt',
					hidden: false,
					inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
					outputFormat: 'DD-MM-YYYY HH:mm:ss'
				},
				{
					label: 'Last Updated At',
					field: 'updatedAt',
					hidden: false
				},
				{
					label: '',
					field: 'mappingId',
					hidden: true
				}
			],
			targetDay: new Date(),
			showAll: true,
			row_data: [],
			totalRecords: 0,
			defaultParams: {},
			sortOptions: { enabled: true },
			paginationOptions: { enabled: true, perPage: 10 },
			doneParams: false,
			serverParams: {
				columnFilters: {},
				sort: [{
					field: 'createdAt',
					type: 'desc'
				}],
				page: 1,
				perPage: 10
			}
		}
	},
	methods: {
		reformatDateUSToUk (d) {
			// dd/mm/yyyy hh:mm:ss -> mm/dd/yyyy hh:mm:ss
			const i = d.substring(0, 10).split(/\//)
			const nd = [i[1], i[0], i[2]].join('/')
			return `${nd} ${d.substring(11, 19)}`
		},
		async setShowAll () {
			this.showAll = true
			await this.load()
		},
		async setShowToday () {
			this.showAll = false
			this.targetDay = new Date()
			await this.load()
		},
		async heatmapClick (evt) {
			this.targetDay = evt.date
			this.showAll = false
			await this.load()
		},
		onPageChange (params) {
			this.updateParams({ page: params.currentPage })
		},
		onSortChange (params) {
			this.updateParams({
				sort: params
			})
		},
		onColumnFilter (params) {
			this.updateParams(params)
		},
		onPerPageChange (params) {
			this.updateParams({ perPage: params.currentPerPage })
		},
		onSearch (params) {
			this.updateParams({ searchKey: params.searchTerm })
		},
		updateParams (newProps) {
			this.serverParams = Object.assign({}, this.serverParams, newProps)
			this.load()
		},
		savedParamsToObjects (savedParams) {
			// Update the sort options, might not need to do this as the changes _should_ call the event handlers
			this.sortOptions = { ...this.sortOptions, initialSortBy: savedParams.sort }
			// Get the field names from the column filters
			const columnnFilterKeys = Object.keys(savedParams.columnFilters)
			for (var i = 0; i < columnnFilterKeys.length; i++) {
				const filterKey = columnnFilterKeys[i]
				// Find the column in the list
				const column = this.columns.find(c => c.field === filterKey)
				// Apply the filter
				column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
			}
			// Set per page
			this.paginationOptions.perPage = savedParams.perPage
			if (this.$refs.table) {
				this.$refs.triggerTelemetry.$refs.paginationBottom.perPage = savedParams.perPage
				this.$refs.triggerTelemetry.$refs.paginationBottom.handlePerPage()
			}
			// Set page
			this.paginationOptions.setCurrentPage = savedParams.page
			// Has to be done in next tick
			this.$nextTick(() => {
				// They have no initial global search so this be the only way!
				this.$refs.triggerTelemetry.globalSearchTerm = savedParams.searchKey
			})
		},
		clearTableFilters () {
			// Clear column filters, doubt there will be any initial ones but just in case apply them after
			this.$refs.triggerTelemetry.resetTable()
			// Update params on good table
			this.savedParamsToObjects(this.defaultParams)
			// Update params that are actually sent to the server
			this.updateParams(this.defaultParams)
			// Reset show all days
			this.setShowAll()
		},
		async load () {
			this.isTableLoading = true
			const postObj = {
				serverParams: this.serverParams,
				id: this.id,
				showAll: this.showAll,
				targetDay: this.targetDay
			}
			// console.log(this.serverParams)
			let res
			try {
				res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}triggertelemetry`, postObj)
				if (res) {
					this.row_data = res.data.resObj.data
					this.totalRecords = res.data.resObj.count
				}
			} catch (e) {
				// NO!
				// this.canViewTelemetry = false
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view the log data.')
				} else {
					this.$snapbar.e(`Could not display the Mapping Logs- ${e.message}`)
					console.log(e)
				}
			}
			this.isTableLoading = false
		},
		async getMapping () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.id}`)
				this.mapping = res.data.mapping
				// console.log(this.mapping)
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view this Mapping data.')
				} else {
					this.$snapbar.e(`Could not display the Mapping data - ${e.message}`)
				}
			}
		},
		async getBindings () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/bindings/${this.id}`)
				console.log('bindings')
				console.log(res.data.response)
				if (res && res.data && res.data.response) {
					if (res.data.response.boundWorkflows) {
						this.boundWorkflows = res.data.response.boundWorkflows
					}
					if (res.data.response.boundGroups) {
						this.boundGroups = res.data.response.boundGroups
					}
					if (res.data.response.boundBureauCustomers) {
						this.boundBureauCustomers = res.data.response.boundBureauCustomers
					}
					if (res.data.response.bureaujobs) {
						this.boundBureauJobs = res.data.response.bureaujobs
					}
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view this Mapping data.')
				} else {
					this.$snapbar.e(`Could not display the data - ${e.message}`)
				}
			}
		},
		getHeatmapEnddate () {
			const enddate = new Date().toISOString().split('T')[0]
			return enddate
		},
		async getHeatmapData () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowheatmapdata/${this.id}`)
				if (res) {
					this.activityData = res.data
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view the telemetry data.')
				} else {
					this.$snapbar.e(`Could not display the Heatmap data- ${e.message}`)
					console.log(e)
				}
			}
		},
		onRowClick (params) {
		},
		btnNewMapping () {
			this.$router.push('/automation/mapping/designer')
		},
		btnCancel () {
			this.$router.go(-1)
		},
		async btnCloneMapping () {
			const result = await swal.fire({
				title: 'Clone Mapping',
				text: 'Create an exact copy of the current mapping.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				input: 'text',
				inputValue: `${this.mapping.metaData.title} (clone)`
			})
			if (result.isConfirmed) {
				if (result.value === this.mapping.metaData.title) {
					result.value = `${this.mapping.metaData.title} (clone)`
				}
				const cloneMapping = {
					title: result.value,
					sandboxPath: this.mapping.sandboxPath,
					paygateId: this.mapping.paygateId,
					data: this.mapping.data,
					metaData: this.mapping.metaData
				}
				cloneMapping.metaData.title = cloneMapping.title
				cloneMapping.metaData.author = this.$store.getters.username
				let res
				try {
					res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping`, cloneMapping)
					if (res) {
						this.$toastr.s(`The mapping '${cloneMapping.title}' was successfully created.`)
						this.$router.push('/automation/mapping/mappings')
					}
				} catch (e) {
					if (e.response && e.response.data && e.response.data.errorMessage) {
						this.$snapbar.e(`Error cloning mapping.  The mapping was not cloned by Paygate. ${e.response.data.errorMessage}`)
					} else {
						this.$snapbar.e('Error cloning mapping.  The mapping was not cloned by Paygate.')
					}
				}
			}
		},
		async btnTransferMapping () {
			// Get list of customers
			const customers = this.$store.getters.customersOrdered
			let inputOptions = {}
			// Munge the customer data into something that the SweetAlert will use.
			// Probably a nicer way to do this but it works for now
			let str = '{ '
			customers.forEach(el => {
				const name = el.name.replace(/[^0-9a-z-A-Z ]/g, '').replace(/ +/, ' ')
				const token = `"${el.paygateId}": "${name}", `
				str = str + token
			})
			str = str.substring(0, str.length - 2)
			str = str + '}'
			inputOptions = JSON.parse(str)

			const result = await swal.fire({
				title: 'Copy Mapping To Another Customer',
				text: 'Copy the current mapping to another customer.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes!',
				cancelButtonText: 'No',
				input: 'select',
				inputOptions: inputOptions
			})
			if (result.isConfirmed) {
				// paygateId of the recipient customer is in result.value
				// Need to transfer the mapping and any associated importers

				// 1 - Transfer Mapping
				const transferMapping = {
					title: this.mapping.metaData.title,
					sandboxPath: '',
					paygateId: result.value,
					data: this.mapping.data,
					metaData: this.mapping.metaData,
					transfer: true
				}
				let res
				try {
					res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}transfermapping`, transferMapping)
					if (res) {
						this.$toastr.s(`The mapping '${transferMapping.title}' was successfully transferred.`)
					}
				} catch (e) {
					if (e.response && e.response.data && e.response.data.errorMessage) {
						this.$snapbar.e(`Error transferring mapping.  The mapping was not transferred. ${e.response.data.errorMessage}`)
					} else {
						this.$snapbar.e('Error transferring mapping.  The mapping was not transferred.')
					}
				}

				// 2 - Transfer Importer

			}
		},
		async btnDeleteMapping () {
			const result = await swal.fire({
				title: 'Are you sure you want to permanently delete this mapping?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: colours.danger,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			})
			if (result.isConfirmed) {
				let res
				try {
					res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.id}`)
					if (res) {
						this.$toastr.s(`The mapping '${this.mapping.title}' was successfully deleted.`)
						this.$router.push('/automation/mapping/mappings')
					}
				} catch (e) {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to delete this Mapping.')
					} else if (e.response && e.response.status === 409) {
						this.$snapbar.e(e.response.data.errorMessage, { html: true })
					} else {
						this.$snapbar.e(`Could not delete the Mapping - ${e.message}`)
					}
				}
			}
		},
		btnEditMapping () {
			this.$router.push(`/automation/mapping/designer/${this.id}`)
		},
		reformatType (type) {
			switch (type) {
				case 'bureauCustomerImporter':
					return 'Bureau Customer Importer'
				case 'bacs':
				case 'fps':
					return 'BACS & FPS'
				case 'ukBureau':
					return 'UK BACS Bureau'
				case 'bankline':
					return 'Bankline'
				default:
					return 'Other'
			}
		},
		reformatTags (t) {
			// Simple visual reformat from ["one","two"] to one, two
			const nt = JSON.stringify(t)
			let ntr
			if (nt) ntr = nt.replace(/"/g, '')
			// eslint-disable-next-line
			if (ntr) ntr = ntr.replace(/\,/g, ', ')
			if (ntr) ntr = ntr.replace(/\[/g, '')
			if (ntr) ntr = ntr.replace(/\]/g, '')
			return ntr
		},
		reformatDate (d) {
			if (!d) return ''
			const myDate = new Date(d)
			let dd = myDate.getDate()
			let mm = myDate.getMonth() + 1 // January is 0!

			const yyyy = myDate.getFullYear()
			if (dd < 10) {
				dd = '0' + dd
			}
			if (mm < 10) {
				mm = '0' + mm
			}

			let h = myDate.getHours()
			let m = myDate.getMinutes()
			let s = myDate.getSeconds()
			h = this.checkTime(h)
			m = this.checkTime(m)
			s = this.checkTime(s)
			return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
		},
		justDate (d) {
			const myDate = new Date(d)
			let dd = myDate.getDate()
			let mm = myDate.getMonth() + 1 // January is 0!
			const yyyy = myDate.getFullYear()
			if (dd < 10) {
				dd = '0' + dd
			}
			if (mm < 10) {
				mm = '0' + mm
			}
			return dd + '/' + mm + '/' + yyyy
		},
		checkTime (i) {
			if (i < 10) {
				i = '0' + i
			}
			return i
		},
		onCellClick (params) {
			if (params.column.field !== 'workflowId') {
				this.$router.push(`/automation/mapping/logging/mappinglog/${params.row.executionId}`)
			}
		}
	},
	created: async function () {

		// Get the mapping
		await this.getMapping()
		// Get the logs
		await this.load()
		// Get the heatmap data
		await this.getHeatmapData()

		await this.getBindings()

		this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))
		// Check if there are any in there?
		const savedParams = this.$store.getters.tableFilters('triggerTelemetry')
		if (savedParams) {
			// console.log('savedParams')
			// console.log(savedParams)
			// If there are, apply them!
			this.savedParamsToObjects(savedParams)
			this.updateParams(savedParams)
		}
		this.doneParams = true
	},
	watch: {
		corvidSelectedPaygateId: async function (val) {
			this.$router.push('/automation/mapping/mappings')
		}
	}
}
</script>

<style scoped>
td {
	cursor: pointer;
}
</style>
