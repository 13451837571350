<template>
  <div>
    <div class="row mb-4" v-if="authorised">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 v-if="isNewTrigger"><span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/triggers/scheduletrigger/`" target="_blank"><i class="far fa-question-circle"></i></a></span> Create a schedule trigger</h2>
            <h2 v-else><span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/triggers/scheduletrigger/`" target="_blank"><i class="far fa-question-circle"></i></a></span> Edit a schedule trigger</h2>
          </div>
          <form>
          <div class="idb-block-content">
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <label class="required" v-b-popover.hover.top.d500="'The title of the trigger.'" >Title</label>
                </div>
                <div class="form-group col-md-9 col-sm-12">
                  <input
                    type="text"
                    :disabled="!hasManageRole"
                    class="form-control"
                    :class="{invalid: $v.trigger.title.$error}"
                    @input="$v.trigger.title.$touch()"
                    v-model="trigger.title">
                  <p class="validationText" v-if="!$v.trigger.title.required && $v.trigger.title.$dirty ">The title field is required!</p>
                  <p class="validationText" v-if="!$v.trigger.title.maxLength">Maximum length is {{ $v.trigger.title.$params.maxLength.max }} characters!</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <label v-b-popover.hover.top.d500="'An optional text field for you to write a discription of the trigger.' ">Description</label>
                </div>
                <div class="form-group col-md-9 col-sm-12">
                  <textarea
                    class="form-control"
                    rows="4"
                    cols="60"
                    :disabled="!hasManageRole"
                    @input="$emit('textarea',$event.target.value)"
                    @blur="$v.trigger.description.$touch()"
                    v-model="trigger.description">
                  </textarea>
                  <p class="validationText" v-if="!$v.trigger.description.maxLength">Maximum length is {{ $v.trigger.description.$params.maxLength.max }} characters!</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <label class="" v-b-popover.hover.top.d500="'The schedule that is used to determine when the trigger will fire.'">Schedule</label>
                </div>
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-3">
                      At
                    </div>
                    <div class="col-md-7">
                      <v-select
                        multiple
                        :disabled="!hasManageRole"
                        v-model="trigger.configuration.selectedMinute"
                        :close-on-select="false"
                        :options=scheduledMinutes>
                      </v-select>
                    </div>
                    <div class="col-md-2">
                      Minutes
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-3">
                      During these
                    </div>
                    <div class="col-md-7">
                      <v-select multiple v-model="trigger.configuration.selectedHours" :disabled="!hasManageRole" :close-on-select="false" :options=scheduledHours></v-select>
                    </div>
                    <div class="col-md-2">
                      Hours
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-3">
                      On these week days
                    </div>
                      <div class="col-md-7">
                        <v-select multiple v-model="trigger.configuration.selectedDayOfWeek" :disabled="!hasManageRole" :close-on-select="false" :options=scheduledDayOfWeek></v-select>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-3">
                      On these month days
                    </div>
                      <div class="col-md-7">
                        <v-select multiple v-model="trigger.configuration.selectedDayOfMonth" :disabled="!hasManageRole" :close-on-select="false" :options=scheduledDayOfMonth></v-select>
                    </div>
                    </div>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-9 offset-md-3 col-sm-12">
                  <!-- <b-button class="" :disabled="isLoading" @click.prevent="viewSchedule" variant="outline-primary" v-b-popover.hover.top.d500="'View a sample schedule based on the current configuration.'">View Schedule</b-button> -->
                  <b-button class="float-right" :disabled="!hasManageRole" @click.prevent="clearSchedule" variant="outline-danger" v-b-popover.hover.top.d500="'Clear the current schedule configuration.'">Clear</b-button>
                  <button class="btn btn-outline-secondary" @click.prevent="view" :disabled="!hasManageRole" type="button" v-b-popover.hover.top.d500="'View a schedule of the next 10 dates/times that this expression would fire the trigger.  Note, BACS non-working days and quiet times are not taken into account.'">View</button>
                </div>
              </div>
              <br><hr> <br>
              <div class="row top-buffer">
                <div class="col-md-3 col-sm-12">
                  <span v-b-popover.hover.top="'Determines if the trigger is enabled or disabled.  Disabled triggers will not execute bound workflows when they fire.'"><label for="triggerEnabledCheckbox">Enabled</label></span>
                </div>
                <div class="col-md-3 col-sm-12">
                  <p-check name="check" :labelId="'triggerEnabledCheckbox'" :disabled="!hasManageRole" class="p-switch" color="primary " v-model="trigger.enabled"></p-check>
                </div>
              </div>
              <br />
              <div class="row top-buffer">
                <div class="col-md-3 col-sm-12">
                  <span v-b-popover.hover.top.d500="'If enabled, the trigger will not run any bound workflows if the current day is a BACS non-working day, i.e. a weekend or public bank holiday.'"><label for="suppressNonWorkingCheckbox">Suppress on BACS non-working days</label></span>
                </div>
                <div class="col-md-6 col-sm-12">
                    <p-check name="check" :labelId="'suppressNonWorkingCheckbox'" :disabled="!hasManageRole" class="p-switch" color="primary " v-model="trigger.configuration.suppressOnNonWorkingDays"></p-check>
                </div>
              </div>
              <br>
              <div class="row top-buffer">
                <div class="col-md-3 col-sm-12">
                  <span v-b-popover.hover.top.d500="'If enabled, schedule triggers will not execute their bound workflows during the start and end times.'"><label for="quietTimeCheckBox">Quiet time</label></span>
                </div>
                <div class="col-md-2 col-sm-12">
                    <p-check :labelId="'quietTimeCheckbox'" name="check" :disabled="!hasManageRole" class="p-switch" color="primary " v-model="trigger.configuration.quietTime"></p-check>
                </div>
                 <transition name="fadein">
                  <div class="col-md-2 col-sm-12" v-if="trigger.configuration.quietTime">
                    Start Time
                    <vue-timepicker
                      v-model="trigger.configuration.quietTimeStart"
                      :disabled="!hasManageRole"
                      format="HH:mm"
                    ></vue-timepicker>
                  </div>
                  </transition>
                  <transition name="fadein">
                  <div class="col-md-2 col-sm-12" v-if="trigger.configuration.quietTime">
                    End Time
                    <vue-timepicker
                      v-model="trigger.configuration.quietTimeEnd"
                      :disabled="!hasManageRole"
                      format="HH:mm"
                    ></vue-timepicker>
                  </div>
                  </transition>
                </div>
              <br><hr><br>
              <div class="row">
              <div class="col-md-3 col-sm-12">
                <label v-b-popover.hover.top.d500="'A list of workflows that will execute when the trigger fires.'">Bound Workflows</label> <span class="badge badge-pill badge-primary">{{this.enabledBoundWorkflows}}</span>
                <b-button @click.prevent="btnAddWorkflow" :disabled="!hasManageRole" class="ml-3 btn-sm" variant="outline-primary" v-b-popover.hover.top.d500="'Add a new workflow to this trigger so that the workflow runs when the trigger fires.'">+</b-button>
              </div>
              <div class="form-group col-md-9 col-sm-12">
                <ul class="list-group">
                  <li v-for="(workflow,index) in boundWorkflows" :key="index" class="list-group-item align-items-center">
                    <div class="row">
                      <div class="col-1">
                        <!-- <p-check v-model="workflow.enabled" name="onoffswitch" :id="index" color="primary" title="Enable / Disable the workflow" v-b-popover.hover.top="'When unchecked the workflow will not execute when the trigger fires.'"></p-check> -->
                        <div class="pretty p-icon p-smooth">
                          <input type="checkbox" v-model="workflow.enabled" :disabled='!trigger.enabled'/>
                          <div class="state p-primary">
                              <i class="icon fa fa-check"></i>
                              <label></label>
                          </div>
                        </div>
                      </div>
                      <div class="col-1">
                        <span v-if="workflow.enabled && trigger.enabled" class="badge badge-pill badge-success">Enabled</span>
                        <span v-else class="badge badge-pill badge-danger">Disabled</span>
                      </div>
                      <div class="col-8">
                        <i class="fas fa-code-branch"></i>
                        {{workflow.Title}} (
                        <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + workflow.id" class="small">Edit</router-link>
                        )
                      </div>
                      <div class="col-2">
                        <!-- <b-button variant="link" @click.prevent="btnEditWorkflow(workflow.id)" v-b-popover.hover.top.d500="'Click to edit this workflow using the designer'"><span class="text-secondary glyphicon ti-settings" aria-hidden="true"></span></b-button> -->
                        <b-button variant="link" @click.prevent="btnRemoveWorkflow(workflow.id)" :disabled="!hasManageRole" v-b-popover.hover.top.d500="'Removes the workflow from the trigger.'"><span class="text-secondary glyphicon ti-trash text-danger" aria-hidden="true"></span></b-button>
                      </div>
                    </div>
                  </li>
                </ul>
                <br>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <div class="row">
              <div class="col-md-12 col-sm-3">
                <b-button v-if="isNewTrigger" :disabled="!hasManageRole" @click.prevent="createTrigger" variant="primary" v-b-popover.hover.top.d500="'Creates a new trigger and saves it in Paygate.  Enabled triggers will become active immediately.'">Create</b-button>
                <b-button v-else :disabled="!hasManageRole" @click.prevent="updateTrigger" variant="primary" v-b-popover.hover.top.d500="'Update the current trigger saving any changes. Enabled triggers will become active immediately.'">Save</b-button>
                <b-button :disabled="!hasViewLogsRole" class="ml-2" v-if="!isNewTrigger" @click.prevent="viewTelemetry" variant="outline-primary" v-b-popover.hover.top.d500="'View this Trigger\'s execution history.'">View Logs</b-button>
                <b-button :disabled="!hasManageRole" class="ml-2" v-if="!isNewTrigger" @click.prevent="cloneTrigger" variant="outline-primary" v-b-popover.hover.top.d500="'Create an exact copy of this trigger.'">Clone Trigger</b-button>
                <b-button :disabled="!hasManageRole" v-if="!isNewTrigger" @click.prevent="deleteTrigger" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this trigger.'"><i class="glyphicon ti-trash mr-2"></i>Delete Trigger</b-button>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import Licence from '@/Assets/Constants/licence'
import roles from '@/Assets/Constants/roles'
import loading from '@/Assets/Mixins/LoadingMixin'
import vSelect from 'vue-select'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    'v-select': vSelect,
    swal,
    VueTimepicker
  },
  mixins: [loading],
  validations: {
    trigger: {
      title: {
        required,
        maxLength: maxLength(48)
      },
      description: {
        maxLength: maxLength(1024)
      }
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      selectedScheduleType: 'Hourly',
      // fill the array up with numbers 0,59, representing minutes
      scheduledMinutes: Array.from(Array(60).keys()),
      // fill the array up with numbers 1, 31, representing minutes
      // eslint-disable-next-line
      scheduledDayOfMonth: Array.from(Array(31).keys()).map(d => d += 1),
      scheduledHours: [
        { label: '0:00', value: 0 },
        { label: '1:00', value: 1 },
        { label: '2:00', value: 2 },
        { label: '3:00', value: 3 },
        { label: '4:00', value: 4 },
        { label: '5:00', value: 5 },
        { label: '6:00', value: 6 },
        { label: '7:00', value: 7 },
        { label: '8:00', value: 8 },
        { label: '9:00', value: 9 },
        { label: '10:00', value: 10 },
        { label: '11:00', value: 11 },
        { label: '12:00', value: 12 },
        { label: '13:00', value: 13 },
        { label: '14:00', value: 14 },
        { label: '15:00', value: 15 },
        { label: '16:00', value: 16 },
        { label: '17:00', value: 17 },
        { label: '18:00', value: 18 },
        { label: '19:00', value: 19 },
        { label: '20:00', value: 20 },
        { label: '21:00', value: 21 },
        { label: '22:00', value: 22 },
        { label: '23:00', value: 23 }
      ],
      scheduledDayOfWeek: [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 }
      ],
      maxBoundWorkflows: 3,
      isNewTrigger: true,
      id: this.$route.params.id,
      boundWorkflows: [],
      paygateId: '',
      trigger: {
        title: '',
        configuration: {
          selectedMinute: [30],
          selectedDayOfMonth: [],
          selectedHours: [
            { label: '9:00', value: 9 },
            { label: '10:00', value: 10 },
            { label: '11:00', value: 11 },
            { label: '12:00', value: 12 },
            { label: '13:00', value: 13 },
            { label: '14:00', value: 14 },
            { label: '15:00', value: 15 },
            { label: '16:00', value: 16 },
            { label: '17:00', value: 17 }
          ],
          selectedDayOfWeek: [
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 }
          ]
        }
      },
      triggerTypes: [
        { text: 'Cron Trigger', value: 'CronTrigger' },
        { text: 'Schedule Trigger', value: 'ScheduleTrigger' },
        { text: 'File Trigger', value: 'FileTrigger' }
      ],
      selectedTriggerType: 'ScheduleTrigger',
      authorised: true,
      isLicenced: true
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    enabledBoundWorkflows () {
      if (!this.trigger.enabled) {
        return 0
      }
      let enabledAndBound = 0
      try {
        for (const wf of this.trigger.boundWorkflows) {
          if (wf.enabled) {
            enabledAndBound++
          }
        }
      } catch (e) {
        return 'Error'
      }
      return enabledAndBound
    },
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    },
    hasViewLogsRole () {
      let hasRole = this.$store.getters.isInRole(roles.ViewTelemetry)
      if (!hasRole) {
        hasRole = this.$store.getters.isInRole(roles.ExecuteWorkflow)
      }
      console.log(hasRole)
      return hasRole
    }
  },
  methods: {
    isCorvidAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    async loadLicence () {
      let response
      try {
        response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
        if (response && response.data && response.data.automation && response.data.automation.workflow) {
          this.isLicenced = response.data.automation.workflow
        } else {
          this.isLicenced = false
          this.authorised = false
        }
      } catch (e) {
        console.log(e.message)
        this.isLicenced = false
        this.authorised = false
      }

      // Override if it's a corvid user
      if (this.isCorvidAdmin() === true) {
        this.isLicenced = true
      }

      if (!this.isLicenced) {
        this.$snapbar.e('Sorry, you do not appear to be licensed to use Workflows.')
      }
    },
    btnRemoveWorkflow (workflowId) {
      this.boundWorkflows = this.boundWorkflows.filter(boundWorkflows => boundWorkflows.id !== workflowId)
    },
    btnEditWorkflow (workflowId) {
      this.$router.push('/automation/workflow/workflowdetail/' + workflowId)
    },
    async cloneTrigger () {
      const result = await swal.fire({
        title: 'Clone Trigger',
        text: 'Create an exact copy of the current trigger. Cloned trigger will be initially disabled.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        input: 'text',
        inputValue: `${this.trigger.title} (clone)`
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        if (result.value) {
          const cloneTrigger = {
            title: result.value,
            enableTelemetry: this.trigger.enableTelemetry,
            paygateId: this.trigger.paygateId,
            triggerType: this.trigger.triggerType,
            enabled: false,
            description: this.trigger.description,
            configuration: this.trigger.configuration,
            boundWorkflows: []
          }
          let res
          try {
            res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger`, cloneTrigger, { showload: true })
            if (res) {
              await this.updatePill()
              this.$toastr.s(`The trigger '${cloneTrigger.title}' was successfully cloned.`)
              this.$router.push('/automation/workflow/triggers')
            }
          } catch (e) {
            if (e.response && e.response.data && e.response.data.errorMessage) {
              this.$snapbar.e(`Error cloning trigger.  The trigger was not cloned. ${e.response.data.errorMessage}`)
            } else {
              if (e.response && e.response.status === 403) {
                this.$snapbar.e('You are not authorised to clone this trigger.')
              } else {
                this.$snapbar.e(`Error cloning trigger.  The trigger was not cloned. ${e.message}`)
              }
            }
          }
        }
      }
    },
    async btnAddWorkflow () {
      this.$snapbar.hide()
      if (this.boundWorkflows.length >= this.maxBoundWorkflows) {
        await swal.fire({
          title: 'Warning',
          text: 'You have reached the maximum number of bound workflows for this trigger.',
          icon: 'warning',
          showOkButton: true,
          confirmButtonColor: colours.warning,
          confirmButtonText: 'Ok'
        })
        return
      }

      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtitles`)
        var inputOptions = {}
        res.data.workflows.forEach((element) => {
          inputOptions[element._id] = element.title
        })
        const result = await swal.fire({
          title: 'Select Workflow',
          input: 'select',
          inputOptions: inputOptions,
          showCancelButton: true,
          confirmButtonText: 'Select',
          cancelButtonText: 'Cancel'
        })
        if (result.isConfirmed) {
          if (result.value) {
            this.boundWorkflows.push({
              Title: inputOptions[result.value],
              enabled: true,
              id: result.value
            })
          }
        }
      } catch (e) {
        this.$snapbar.e(`Error adding workflow - ${e.message}`)
      }
    },
    async createTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.trigger.boundWorkflows = this.boundWorkflows

      try {
        await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger`, this.trigger, { showload: true })
        await this.updatePill()
        this.$toastr.s(`The trigger '${this.trigger.title}' was successfully created.`)
        this.$router.push('/automation/workflow/triggers')
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Error saving trigger.  The trigger was not saved by Paygate. (${e.response.data.errorMessage})`)
        } else {
          this.$snapbar.e('Error saving trigger.  The trigger was not saved by Paygate.')
        }
      }
    },
    updateTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$snapbar.hide()
      this.trigger.boundWorkflows = this.boundWorkflows
      axios
        .patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.trigger._id}`, this.trigger, { showload: true })
        .then(async res => {
          await this.updatePill()
          this.$toastr.s(`The trigger '${this.trigger.title}' was successfully updated.`)
          this.$router.push('/automation/workflow/triggers')
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.errorMessage) {
            this.$snapbar.e(`Could not update the trigger. ${e.response.data.errorMessage}`)
          } else {
            this.$snapbar.e(`Error updating trigger - ${e.message}`)
          }
        })
    },
    viewTelemetry () {
      this.$router.push(`/automation/workflow/triggerTelemetry/${this.$route.params.id}`)
    },
    async deleteTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const result = await swal.fire({
        title: 'Are you sure you want to delete this trigger?',
        text: 'Any workflow currently using this trigger will stop working!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        axios
          .delete(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.id}`, { showload: true })
          .then(async res => {
            this.$toastr.s(`The trigger '${this.trigger.title}' was successfully deleted.`)
            await this.updatePill()
            this.$router.push('/automation/workflow/triggers')
          })
          .catch(e => {
            if (e.response && e.response.status === 403) {
              this.$snapbar.e('You are not authorised to delete this Trigger.')
            } else {
              this.$snapbar.e(`Could not delete the trigger - ${e.message}`)
            }
          })
      }
    },
    async updatePill () {
      if (this.$store.getters.hasLicence(Licence.automation_workflow) && this.$store.getters.isInRole(roles.ManageWorkflow)) {
        this.$store.dispatch('updateCurrentTriggerCount')
      }
    },
    async clearSchedule () {
      const result = await swal.fire({
        title: 'Are you sure you want to clear the schedule?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.trigger.configuration.selectedMinute = []
        this.trigger.configuration.selectedDayOfMonth = []
        this.trigger.configuration.selectedHours = []
        this.trigger.configuration.selectedDayOfWeek = []
      }
    },
    async view () {
      const that = this
      let res
      try {
        this.$snapbar.hide()
        res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}getschedulerschedule`, this.trigger, { showload: true })
      } catch (e) {
        this.$snapbar.e('Error loading schedule')
        return
      }
      if (res) {
        let html = '<hr><ul class="list-unstyled">'
        res.data.forEach(element => {
          const nd = that.reformatDate(element)
          html += `<li>${nd}</li>`
        })
        html += '</ul>'
        await swal.fire({
          title: 'Trigger Schedule',
          html: html,
          showCancelButton: false
        })
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      // let ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    defaultTrigger () {
      return {
        title: '',
        configuration: {
          selectedMinute: [30],
          selectedDayOfMonth: [],
          suppressOnNonWorkingDays: true,
          boundWorkflows: [],
          enabled: false,
          triggerType: 'scheduleTrigger',
          selectedHours: [
            { label: '9:00', value: 9 },
            { label: '10:00', value: 10 },
            { label: '11:00', value: 11 },
            { label: '12:00', value: 12 },
            { label: '13:00', value: 13 },
            { label: '14:00', value: 14 },
            { label: '15:00', value: 15 },
            { label: '16:00', value: 16 },
            { label: '17:00', value: 17 }
          ],
          selectedDayOfWeek: [
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 }
          ]
        }
      }
    },
    async getTrigger () {
      this.$snapbar.hide()
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.id}`, { showload: true })
        if (res && res.data && res.data.trigger) {
          this.trigger = res.data.trigger
          if (!this.trigger.boundWorkflows) {
            this.trigger.boundWorkflows = []
          }
          this.boundWorkflows = this.trigger.boundWorkflows
        } else {
          this.$snapbar.w('Unknown or invalid trigger.')
          this.trigger = this.defaultTrigger()
          // this.clearSchedule()
        }
      } catch (e) {
        this.trigger = this.defaultTrigger()
        if (e.response && e.response.status === 403) {
          this.$snapbar.e(`You are not authorised to view this Trigger. (${e.response.data.errorMessage})`)
          this.authorised = false
        } else {
          this.$snapbar.e(`Could not display this Trigger - ${e.message}`)
        }
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      this.$router.push('/automation/workflow/triggers')
    }
  },
  created: async function () {
    await this.loadLicence()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.trigger.triggerType = 'scheduleTrigger'
    this.trigger.paygateId = this.paygateId
    this.trigger.enabled = false
    this.trigger.boundWorkflows = []
    this.trigger.configuration.suppressOnNonWorkingDays = true

    if (!this.id) {
      this.isNewTrigger = true
    } else {
      this.isNewTrigger = false
      this.getTrigger()
    }
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  label.required {
    /* $gray-700:  */
    color: #878787;
  }
  label.required::after {
    content: " *";
    color: red;
  }
  .top-buffer { margin-top:20px; }
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
