<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Monitor<favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="progress-bar bg-primary" role="progressbar" :style=getCountdown() aria-valuemax="100"></div>
          <br/>
          <div class="row">
            <div class="col-md-4"><h4>Status</h4></div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-3">Sensor</div>
            <div class="col-md-2">Last Time / Date</div>
            <div class="col-md-2">State</div>
            <div class="col-md-2">Transition Date</div>
            <div class="col-md-2">Notes</div>
          </div>
          <hr>
          <div class="row" v-for="(sensor, index) in sensorsState" :key="index">
            <div class="col-md-3">{{sensor.component}}</div>
            <div class="col-md-2">{{reformatDate(sensor.lastHeartbeat)}}</div>
            <div class="col-md-2" v-if="sensor.state==='pass'"><span class="badge badge-pill badge-success">Healthy</span></div>
            <div class="col-md-2" v-else-if="sensor.state==='fail'"><span class="badge badge-pill badge-danger">Fail</span></div>
            <div class="col-md-2" v-else><span class="badge badge-pill badge-info">{{sensor.state}}</span></div>
            <div class="col-md-2" v-if="sensor.state==='fail'">{{reformatDate(sensor.initialFailDate)}}</div>
            <br/><br/>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Queue <span><b-badge v-if="row_data.length > 0" pill variant="primary">{{ row_data.length }}</b-badge></span> </h2>
        </div>
        <div class="idb-block-content">
          <div class="row" v-if="isCorvidAdmin">
            <div class="col-md-12">
              <vue-good-table
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :isLoading.sync="isTableLoading"
                ref="table"
                :columns="columns"
                :rows="rows"
                :lineNumbers="true"
                :search-options="{ enabled: true }"
                styleClass="vgt-table striped bordered table-hover" >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'buttons'">
                      <b-button
                        @click.prevent="btnRemoveFromQueue(props)"
                        variant="outline-danger"
                        class="pointer btn-small"
                        v-b-popover.hover.top.d500="'Remove from queue'">
                        <i class="glyphicon ti-close"></i>
                      </b-button>
                    </span>
                </template>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'">
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                    <span class="sr-only">Clear filters</span>
                  </button>
                  <b-button
                    @click.prevent="load"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'">
                    <i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
                    <span class="sr-only">Refresh Table</span>
                  </b-button>
                </div>
              </vue-good-table>
            </div>
          </div>
          <br/>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Alerting</h2>
        </div>
        <div class="idb-block-content">
          <div class="row" v-if="isCorvidAdmin">
            <div class="col-md-4">
              <p-check name="check" class="p-switch" color="primary " v-model="emailAlertsEnabled">Email Workflow Alerts</p-check>
            </div>
          </div>
          <br/>
          <transition name="fadein">
            <div class="textarea" v-if="isCorvidAdmin">
              <div class="form-group">
                <label class="">Email Alerts Recipients</label><span class="text text-muted ml-1"><small>Comma separated list</small></span>
                <input type="text"
                  class="form-control"
                  :disabled="!isCorvidAdmin"
                  @input="$v.emailAlertRecipientsArray.$touch()"
                  v-model="emailAlertRecipientsArray">
                <p class="validationText" v-if="!$v.emailAlertRecipientsArray.requireIf && $v.emailAlertRecipientsArray.$dirty">At least one email address is required!</p>
                <p class="validationText" v-if="!$v.emailAlertRecipientsArray.maxLength">Maximum length is 1024 characters!</p>
                <p class="validationText" v-if="!$v.emailAlertRecipientsArray.customEmail && $v.emailAlertRecipientsArray.$dirty">One or more invalid email addresses!</p>
               </div>
            </div>
          </Transition>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Control</h2>
        </div>
        <div class="idb-block-content">
          <div class="alert alert-warning" v-if="enabledWorkflowProcessor==false" role="alert" aria-label="Warning:">
            <i class="fas fa-exclamation-triangle mr-2"></i><b>Warning.</b> Workflow execution is currently disabled.
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <label for="enableWorkflowProcessor">Enabled Workflow Processor</label>
            </div>
            <div class="col-md-2">
              <p-check name="check" :labelId="'enableWorkflowProcessor'" class="p-switch" color="primary " @change="saveWorkflowControlConfig" v-model="enabledWorkflowProcessor"></p-check>
            </div>
            <div class="col-md-6">
              When disabled, workflows that are currently running will be allowed to complete but new workflows will <strong>not</strong> start.
            </div>
          </div>
          <br/>
        </div>
        <div class="idb-block-footer">
          <div>
            <button class="btn btn-primary" @click="btnSave"><i class="fas fa-save "></i> Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import { required, maxLength } from 'vuelidate/lib/validators'
import swal from 'sweetalert2'
import roles from '@/Assets/Constants/roles'
import colours from '@/Assets/Constants/colours'
export default {
  name: 'my-component',
  components: {
    swal
  },
  mixins: [tableFilterMixin, loading],
  data () {
    return {

      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'Queue Id',
          field: 'queueId',
          sortable: false,
          hidden: true,
          filterOptions: { enabled: false }
        },
        {
          label: 'Workflow',
          field: 'title',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: 'Customer',
          field: 'customerId',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: 'Execution Id',
          field: 'executionId',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: 'Queued Time',
          field: 'queuedDatetime',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
          sortable: false,
          filterOptions: { enabled: false }
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false }
        }
      ],
      row_data: [],
      serverParams: {
        sort: [{ field: 'title', type: 'asc' }]
      },
      emailAlertRecipientsArray: '',
      emailAlertsEnabled: true,
      smsAlertRecipientsArray: [],
      smsAlertsEnabled: true,
      sensorsState: [],
      progressBarValue: 100,
      timer: undefined,
      timerValue: 0,
      refreshPeriod: 30000,
      timerInterval: 1000,
      enabledWorkflowProcessor: false
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    isCorvidAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    }
  },
  validations () {
    return {
      emailAlertRecipientsArray: {
        requireIf: () => {
          if (this.emailAlertsEnabled === true) {
            console.log('emailAlertsEnabled', this.emailAlertsEnabled)
            if (this.emailAlertRecipientsArray && this.emailAlertRecipientsArray !== '') {
              return true
            } else {
              return false
            }
          } else {
            console.log('emailAlertsEnabled', this.emailAlertsEnabled)
            return true
          }
        },
        maxLength: maxLength(1024),
        customEmail: () => {
          let valid = true
          if (this.emailAlertsEnabled === true) {
            if (!this.emailAlertRecipientsArray || this.emailAlertRecipientsArray === '') {
              return false
            }
            const emails = this.emailAlertRecipientsArray.split(',')
            if (emails.length > 0) {
              for (const ea of emails) {
                const isValid = this.validateEmail(ea)
                if (!isValid) valid = false
              }
            }
          }
          return valid
        }
      }
    }
  },
  methods: {
    async load () {
      let res
      try {
        this.isTableLoading = true
        const url = `${process.env.VUE_APP_WORKFLOWAPI_API_URL}queue`
        res = await axios.get(url, { showload: true })
        if (res && res.data && res.data.queueDto) {
          this.row_data = res.data.queueDto
        } else {
          this.$snapbar.e('Could not get a workflow queue.')
          this.row_data = []
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view the queue.')
        } else {
          console.log(e)
          this.$snapbar.e(`Could not get the queue - ${e.message}`)
        }
        this.row_data = []
      } finally {
        this.isTableLoading = false
      }
    },
    async saveWorkflowControlConfig () {
      const workflowControl = {
        workflowsEnabled: this.enabledWorkflowProcessor
      }
      let res
      try {
        res = await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowcontrol`, workflowControl)
        if (res) {
          this.$toastr.s('Workflow control settings have been updated', 'Workflow Control')
        }
      } catch (e) {
        this.$snapbar.e('Could not update workflow subsystem configuration.')
      }
    },
    async getWorkflowControlConfig () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowcontrol`)
        if (res && res.data) {
          this.enabledWorkflowProcessor = res.data.workflowsEnabled
        } else {
          this.$snapbar.w('Warning: Could not get workflow subsystem configuration.')
        }
      } catch (e) {
        this.$snapbar.e('Error: Could not get workflow subsystem configuration.')
      }
    },
    getCountdown () {
      return `width: ${this.progressBarValue}%`
    },
    reformatDate (d) {
      if (!d) {
        return ''
      }
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    async btnSave (trigger) {
      this.$snapbar.hide()
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const config = {
        emailAlertsEnabled: this.emailAlertsEnabled,
        smsAlertsEnabled: this.smsAlertsEnabled,
        emailAlertRecipients: this.emailAlertRecipientsArray.toString(),
        smsAlertRecipients: this.smsAlertRecipientsArray.toString()
      }
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}alertConfig`, config)
        this.$toastr.s('The config was successfully updated.')
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Could not update the config. ${e.response.data.errorMessage}`)
        } else {
          this.$snapbar.e(`Error updating config - ${e.message}`)
        }
      }
    },
    async btnRemoveFromQueue (props) {
      console.log(props.formattedRow.queueId)
      const result = await swal.fire({
        title: 'Are you sure you want to permanently remove this item from the queue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          const url = `${process.env.VUE_APP_WORKFLOWAPI_API_URL}queue/${props.formattedRow.queueId}`
          console.log(url)
          res = await axios.delete(url, { showload: false })
          if (res) {
            this.$toastr.s('The item was successfully removed.')
            this.load()
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this item.')
          } else {
            this.$snapbar.e(`Could not delete this item - ${e.message}`)
          }
        }
      }
    },
    validateEmail: function (email) {
      // Validate a single email address using some regex what I found on SO
      email = email.toLowerCase().trim()
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    },
    async loadSensors () {
      await this.getWorkflowControlConfig()
      let ssres
      try {
        ssres = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}sensorstate`)
        if (ssres) {
          this.sensorsState = ssres.data.heartbeats
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this data.')
        } else if (e.response && e.response.status === 404) {
          this.$snapbar.e('Could not retrieve the workflow monitor config.')
        } else {
          this.$snapbar.e(`Could not display the Workflow Monitor configuration - ${e.message}`)
        }
      }
    },
    async loadInitialConfig () {
      await this.getWorkflowControlConfig()
      let res
      let ssres
      try {
        // Heartbeats
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}alertconfig`)
        if (res) {
          if (res.data.alertConfig && res.data.alertConfig.emailAlertRecipients) {
            this.emailAlertRecipientsArray = res.data.alertConfig.emailAlertRecipients
            // this.smsAlertRecipientsArray = res.data.alertConfig.smsAlertRecipients.split(',')
          }
          this.emailAlertsEnabled = res.data.alertConfig.emailAlertsEnabled
        }
        // Sensors
        ssres = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}sensorstate`)
        if (ssres) {
          this.sensorsState = ssres.data.heartbeats
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this data.')
        } else if (e.response && e.response.status === 404) {
          this.$snapbar.e('Could not retrieve the workflow monitor config.')
        } else {
          this.$snapbar.e(`Could not display the Workflow Monitor configuration - ${e.message}`)
        }
      }
    }
  },
  async created () {
  },
  async mounted () {
    await this.loadInitialConfig()
    this.timerValue = this.refreshPeriod
    this.timer = setInterval(async () => {
      this.timerValue -= this.timerInterval
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      if (this.progressBarValue <= 0) {
        this.timerValue = this.refreshPeriod
        this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
        await this.loadSensors()
        await this.load()
      }
    }, this.timerInterval)
    this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
  },
  beforeUnmount () {
    // Destroy the setInterval on browse away
    clearInterval(this.timer)
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      // await this.getTriggerData()
    }
  }
}
</script>
<style scoped>
  .progress {
    height: 4px;
    line-height: 4px;
  }
  .progress-bar {
    height: 4px;
    line-height: 4px;
  }
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
  /* td {
    cursor: pointer;
  } */
</style>
