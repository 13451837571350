<template>
  <div v-show="actionLoaded" id="submissionWrapper">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Submission Details
          <span class="pull-right">
            <a :href="helpUrl" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
        </h2>
      </div>
      <viewSubmission v-if="showBacs"></viewSubmission>
      <viewBanklineSubmission v-else-if="showBankline"></viewBanklineSubmission>
      <div class="idb-block-footer">
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="backToSearchResults"
        >Back to Search Results</button>
        <button v-show="showSubSummaryButton"
          type="button"
          class="btn btn-outline-primary pull-right"
          v-on:click="showSubmissionSummary"
        >BACS Submission Summary</button>
      </div>
    </div>
  </div>
</template>

<script>
import ViewSubmission from '@/Views/Bacs/Common/ViewSubmission.vue'
import ViewBanklineSubmission from '@/Views/Bankline/Common/ViewBanklineSubmission.vue'

export default {
  components: {
    viewSubmission: ViewSubmission,
    viewBanklineSubmission: ViewBanklineSubmission
  },
  computed: {
    actionLoaded () { 
      let loaded = false;
      if (this.paymentEngineType === 'Bacs' || this.paymentEngineType === 'Fps') {
        loaded = this.$store.getters.parentDataLoaded
      } else {
        loaded = this.$store.getters.parentBanklineDataLoaded
      }
      return loaded;
    },
    showSubSummaryButton () { 
      return (this.submissionStatus === 'complete' || this.submissionStatus === 'rejected') && (this.paymentEngineType === 'Bacs' || this.paymentEngineType === 'Fps') 
    },
    showBacs () { return this.paymentEngineType === 'Bacs' || this.paymentEngineType === 'Fps' },
    showBankline () { return this.paymentEngineType === 'Bankline' }
  },
  data () {
    return {
      returnPage: '',
      submissionId: '',
      antiFraudListId: '',
      submissionStatus: '',
      helpUrl: '',
      paymentEngineType: ''
    }
  },

  methods: {
    backToSearchResults () {
      var returnUrl = '/payments/search/' + this.returnPage + '?useSearchParams=true'
      if (this.antiFraudListId !== undefined) {
        returnUrl += '&antiFraudListId=' + this.antiFraudListId
      }
      this.$router.push(returnUrl)
    },

    showSubmissionSummary () {
      const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: this.submissionId } })
      window.open(routeData.href, '_blank')
    }
  },

  mounted () {
    this.returnPage = this.$route.query.returnPage
    this.antiFraudListId = this.$route.query.antiFraudListId
    this.submissionStatus = this.$route.query.submissionStatus
    this.paymentEngineType = this.$route.query.paymentEngineType
    this.submissionId = this.$store.getters.submissionId
    this.$store.dispatch('setActionId', '00000000-0000-0000-0000-000000000000')
    this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/search/viewsubmission/'
  }
}
</script>
