<template>
  <div class="idb-block">
    <div class="idb-block-title">Bulk {{ readableType(uploadType) }} Validation</div>
    <div class="idb-block-content">
      <div class="row" v-if="false">
        <div class="column-md-2">Import Template</div>
        <div class="column-md-2"><select v-model="templateId"  /></div>
      </div>
      <vue-dropzone
        id="drop1"
        ref="fileUploader"
        :url="dropzoneOptions.url"
        :options="dropzoneOptions"
        @vdropzone-success="afterSuccess"
        @vdropzone-file-added="fileAdded"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-sending="sendingEvent"
        @vdropzone-error="afterFailed"
        :use-custom-slot="true"
        :accepted-file-types="dropzoneOptions.acceptedFiles"
        :max-file-size-in-m-b="200"
        :useCustomDropzoneOptions="true"
        :use-custom-drop-zone-options="true"
        :useCustomSlot="true"                >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
          <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
        </div>
      </vue-dropzone>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  props: {
    uploadType: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      selectedFiles: [],
      showModalFileBrowser: false,
      rights: null,
      bearerToken: null,
      expectedVersion: '1',
      dropzoneOptions: {
        maxFilesize: 250, // MB
        maxFiles: 4,
        acceptedFiles: '.csv,.xlsx,.xls',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop an Excel File or CSV file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload`,
        title: 'Drag and drop to upload files',
        subtitle: '...or click to select a file from your computer',
        addRemoveLinks: true
      },
      fileTypes: [
        { id: 0, label: 'Email Addresses' },
        { id: 1, label: 'Phone Number' },
        { id: 4, label: 'Bank Account' },
        { id: 2, label: 'Confirmation Of Payer' },
        // { id: 3, label: 'Person At Address' },
      ],
      fileType: 0,
      sessionId: '',
      templateId: '00000000-0000-0000-0000-000000000000',
      templates: []
    }
  },
  async mounted () {
    await this.loadTemplates()
    this.dropzoneOptions.url = `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload`
    this.removeAllFiles()
    auth.getAccessToken().then(token => { this.bearerToken = 'Bearer ' + token })
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  watch: {
    paygateId (newId, oldId) {
      this.dropzoneOptions.url = `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload`
    }
  },
  methods: {
    async loadTemplates () {

    },
    afterFailed (e) {
      console.log(e)
    },
    readableType (input) {
      let output = ''
      switch (input) {
        case 0:
          output = 'Email Addresses'
          break
        case 1:
          output = 'Phone Number'
          break
        case 2:
          output = 'Confirmation Of Payer'
          break
        case 3:
          output = 'Person At Address'
          break
        case 4:
          output = 'Bank Account'
          break
      }
      return output
    },
    clear() {

    },
    removeAllFiles () {
      this.selectedFiles = []
    },
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
      formData.append('fileType', this.uploadType)
      formData.append('templateId', this.templateId)
    },
    async afterSuccess (file, response) {
      this.sessionId = response.sessionId
      let initResponse = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/interpret/${this.sessionId}`)
      if (initResponse.status === 200) {
        this.$router.push({ name: 'validationSession', params: { sessionId: this.sessionId } })
      } else {
        this.$toastr.e('Could not continue with validation')
      }
    },
    addFile (file) {
      const fileObj = { type: this.rights.uploadType, path: '', fileName: file.filename, id: file.submissionId }
      this.selectedFiles.push(fileObj)
    },
    async load () {
    },
    onRowClick (e) {

    },
    async commenceValidation (e) {
      let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/interpret/${this.sessionId}`)
      if (response.status === 200) {
        this.$router.push({ name: 'validationSession', params: { sessionId: this.sessionId } })
      } else {
        this.$toastr.e('Could not continue with validation')
      }
    }
  }
}
</script>
<style scoped>
a.dz-remove:hover, a.dz-remove:active, a.dz-remove:visited {
  color: #ffffff
}
</style>
