// HTML5 Canvas based Mapping Designer - Project Blueroom
// Paygate 3.2.0 (Project Chorrol)
// September 2020 - Gary Vry

import { vueInstance } from '@/main.js'
import createjs from 'createjs-cmd'
import _ from 'lodash'
import { getTheme } from './themes.js'
import { createWorkflowNodeProperties, createPaymentConnectorProperties } from './nodeDefs'
import axios from 'axios'
import { controllers } from 'chart.js'

let dragMode = false
let dragDeltaX = 0
let dragDeltaY = 0
let itemOffsets = []
const megneticRadius = 7500
let magneticInputRect
let magneticShape
let selectedRect
let selectedShape
let canvas
let stage
let selectedObjects = []
let selectedId
let rubberBandRect
let rubberBandShape
let rubberBandOriginX
let rubberBandOriginY
let rubberBandMode = false
let lineDrawingMode = false
let lineOriginX
let lineOriginY

const storedMouseCoords = { x: 0, y: 0 }

// When drawing  a line, this is the destination connector object
let inputConnectorTarget

// When drawing  a line, this is the origin connector object
let outputConnectorOrigin

let dragNodeMode = false
let drawingLine
let drawingLineShape

// The undo / redo stack
let stack = []
let stackPointer = -1
const maxStackLength = 64 // Number of undo steps

// Flag to detect if we need to detect if a workflow is in progress
// eslint-disable-next-line no-unused-vars
let canvasIsDirty = false

let originalUnpluggedNode

let theme
const gridSize = 16
const snapToGrid = false
const showGrid = false

// Node Config
const nodeConfig = []
const nodeRadius = 8

const nodeOpacity = 1.0
const connectorSize = 10
const framerate = 30

// Shape Cache - may imprved performance (?)
const enableShapeCaching = true

// Lines
const lineThickness = 2

// Shadow
const showShadows = true
const showLineShadows = false
const shadowDepth = 3
const shadowBlur = 16

const connectorsToBack = true

let popupTimer
let popupGroup
let popupRect
let popupShape
let popupOriginX
let popupOriginY

const ie11 = !!window.MSInputMethodContext && !!document.documentMode

const detectIE = () => {
	// Detect IE and Edge
	const ua = window.navigator.userAgent

	const msie = ua.indexOf('MSIE ')
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	const trident = ua.indexOf('Trident/')
	if (trident > 0) {
		// IE 11 => return version number
		const rv = ua.indexOf('rv:')
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	const edge = ua.indexOf('Edge/')
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
	}
	// other browser
	return false
}

const detectIe10OrBelow = () => {
	if (navigator.userAgent.indexOf('MSIE') >= 0) {
		return true
	} else {
		return false
	}
}

if (detectIE() === true) {
	console.log('IE Detected')
	// .. IE Shims here
}

if (detectIe10OrBelow()) {
	console.error('IE10 or below detected.')
	window.location.replace('./incompatible.html')
}

export const initCanvas = () => {
	const setTheme = vueInstance.$store.getters.getClaim('theme').value
	if (setTheme) {
		theme = getTheme('dark')
	} else {
		theme = getTheme('light')
	}

	canvas = document.getElementById('image-canvas')
	canvas.objectType = 'Canvas'

	// Prevent Right-Click context menu
	canvas.oncontextmenu = e => e.preventDefault()

	stage = new createjs.Stage(canvas)
	stage.enableMouseOver(20)
	stage.mouseMoveOutside = true
	stage.width = 200

	stage.on('stagemouseup', function (evt) {
		if (evt.nativeEvent.button === 0) {
			if (rubberBandMode) {
				unSelectAll()
				const adjustedX = evt.stageX - dragDeltaX
				const adjustedY = evt.stageY - dragDeltaY
				// Select everything inside the band
				rubberBandMode = false
				stage.removeChild(rubberBandShape)
				selectEnclosedShapes(rubberBandOriginX, rubberBandOriginY, adjustedX, adjustedY)
			}
		}
		dragMode = false
		rubberBandMode = false
		document.body.style.cursor = 'default'
	})

	stage.on('stagemousemove', function (evt) {
		if (rubberBandMode) {
			dragMode = false
			stage.removeChild(rubberBandShape)
			rubberBandRect = new createjs.Graphics()
			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			rubberBandRect.beginFill('#44a7ff').drawRect(rubberBandOriginX, rubberBandOriginY, adjustedX - rubberBandOriginX, adjustedY - rubberBandOriginY)
			rubberBandShape = new createjs.Shape(rubberBandRect)
			rubberBandShape.objectType = 'rubberBand'
			rubberBandShape.alpha = 0.35
			stage.addChild(rubberBandShape)
		}
		if (dragMode) {
			document.body.style.cursor = 'move'
			rubberBandMode = false
			dragDeltaX = stage.mouseX - storedMouseCoords.x
			dragDeltaY = stage.mouseY - storedMouseCoords.y
			stage.x = dragDeltaX
			stage.y = dragDeltaY
		}
	})

	stage.on('stagemousedown', evt => {
		// Mouse Click for context menu
		// Value of evt.nativeEvent.button
		// 0 LH button
		// 1 middle button
		// 2 RH button

		if (evt.nativeEvent.button === 0) {
			const shape = getObjectUnderMouse(false)
			if (shape === null) {
				const adjustedX = evt.stageX - dragDeltaX
				const adjustedY = evt.stageY - dragDeltaY
				rubberBandMode = true
				rubberBandOriginX = adjustedX
				rubberBandOriginY = adjustedY

				stage.removeChild(rubberBandShape)
				rubberBandRect = new createjs.Graphics()
				rubberBandRect.beginFill('#44a7ff').drawRect(rubberBandOriginX, rubberBandOriginY, 1, 1)
				rubberBandShape = new createjs.Shape(rubberBandRect)
				rubberBandShape.objectType = 'rubberBand'
				rubberBandShape.alpha = 0.3
				stage.addChild(rubberBandShape)
			}
			dragMode = false
		}

		if (evt.nativeEvent.button === 2) {
			// Store the co-ordinates of the mouse pointer
			// will be used to paste any new nodes
			storedMouseCoords.x = stage.mouseX - dragDeltaX
			storedMouseCoords.y = stage.mouseY - dragDeltaY
			dragMode = true
			rubberBandMode = false
		}
	})

	// Keypress Event
	if (ie11) {
		// FIXME:  not working - IE11 keyboard events not firing
		console.log('IE11 Specific key handling')
		window.onkeydown = function (e) {
		}
	} else {
		console.log('Non-IE11 key handling')
		window.onkeydown = keyPressed
	}

	canvas.style.backgroundColor = theme.canvasColour
	renderGrid(1)

	drawingLine = new createjs.Graphics().beginStroke(theme.connectorLineColour).moveTo(0, 0).bezierCurveTo(0, 0, 0, 0, 0, 0)
	drawingLineShape = new createjs.Shape(drawingLine)
	stage.addChild(drawingLineShape)
	stage.update()

	// Framerate ticker
	createjs.Ticker.addEventListener('tick', tick)
	createjs.Ticker.framerate = framerate
	initWorkflow()
}

export function initWorkflow () {
	showStackStats()
	addToStack()
}

export function keyPressed (evt) {
	// General canvas keydown event
	if (evt) {
		if (evt.code === 'Delete') {
			removeSelected()
		}
		if (evt.code === 'Escape') {
			unSelectAll()
		}
		if (evt.code === 'KeyA' && evt.ctrlKey) {
			selectAll()
			// Prevent browser from selecting non-canvas stuff
			return false
		}
		if (evt.code === 'KeyX' && evt.ctrlKey) {
			cutClipboard()
			// Prevent browser from going non-canvas stuff
			return false
		}
		if (evt.code === 'KeyC' && evt.ctrlKey) {
			copyClipboard()
			// Prevent browser from going non-canvas stuff
			return false
		}
		if (evt.code === 'KeyV' && evt.ctrlKey) {
			pasteClipBoard()
			// Prevent browser from going non-canvas stuff
			return false
		}
		if (evt.code === 'KeyZ' && evt.ctrlKey) {
			undo()
			return false
		}
		if (evt.code === 'KeyY' && evt.ctrlKey) {
			redo()
			return false
		}
	}
}

export const destroyClipboard = () => {
	// Remove the keyboard events that designer uses for clipboard - otherwise ctrl-c, etc
	// won't work on any other page during the session.
	window.onkeydown = null
}

export const moveToOrigin = () => {
	// MOves the diagram viewport back to 0,0
	stage.x = 0
	stage.y = 0
	dragDeltaX = 0
	dragDeltaY = 0
	stage.update()
}

export const automap = () => {
	// Attempts to auto map the input and output connectors if the types match
	// Find the input connector
	addToStack()
	const model = buildMappingModel()
	console.log(model)

	model.importers.forEach((item, index) => {
		const importerId = item.Id
		item.importer.configuration.forEach((rowItem, idx) => {
			model.paymentConnectors.forEach((item, index) => {
				item.data.nodeConfig.lLabels.forEach((label, i) => {
					if (rowItem.strongType === label) {
						const originId = getOutputConnectorId(importerId, idx, model)
						const origin = getObjectWithId(originId)
						const targetId = getInputConnectorId(item.Id, i, model)
						const target = getObjectWithId(targetId)
						const exists = doesLineConnectorExist(origin, target)
						if (!exists) {
							// Check if target already has a line going into it.  If so don;t automat this line
							const hasInput = doesInputHaveConnector(target.id)
							if (!hasInput) {
								drawLineConnector(origin, target, false)
							}
						}
					}
				})
			})
		})
	})
	addToStack()
}

const getOutputConnectorId = (parentId, index, model) => {
	let id
	model.outputs.forEach((item, i) => {
		if (item.ParentId === parentId && item.Row === index) {
			// console.log('found it: ' + item.Id)
			id = item.Id
		}
	})
	return id
}

const getInputConnectorId = (parentId, index, model) => {
	let id
	model.inputs.forEach((item, i) => {
		if (item.ParentId === parentId && item.Row === index) {
			// console.log('found it: ' + item.Id)
			id = item.Id
		}
	})
	return id
}

export const clearCanvas = () => {
	// Clears the current canvas of all children
	nodeConfig.length = 0
	stage.removeChild(magneticShape)
	// let fadeTime = 250
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		stage.removeChild(child)
	}
	vueInstance.$store.commit('setIsMappingDirty', true)
	renderGrid(1)
}

export const createNode = (x, y, nodeType, nodeId, workflow, nodeConf, extraValue) => {
	// This is not a brand new node, it is being recreated from say a saved workflow.
	// This is important because if it is being recreated then a lot of id's need to be reused and not recreated,
	let newNode = false
	if (nodeId === undefined) {
		nodeId = createGuid()
		newNode = true
	}

	const nodeDef = createWorkflowNodeProperties(nodeType)
	let props = {}

	vueInstance.$store.commit('setIsMappingDirty', true)

	// console.log('nodeType: ' + nodeType)
	// console.log('nodeDef')
	// console.log(nodeDef)

	// console.log('nodeConf')
	// console.log(nodeConf)

	// Some nodes have a dynamic number of inputs based on user input
	// An example is the joiner.  The user chooses the number of input when they create the node.
	// The amount comes into the function in extraValue - we then override n1 and a few other props
	if (nodeType === 'joiner') {
		// console.log('Joiner')
		// console.log(nodeDef)
		// console.log('n1')
		// console.log(nodeConf.NodeCfg.nodeConfig.n1)
		// console.log('extraValue')
		// console.log(extraValue)

		if (extraValue) {
			// Newly added to the diagram and not yet saved
			nodeDef.nodeConfig.n1 = parseInt(extraValue)
			for (let i = 1; i <= extraValue; i++) {
				nodeDef.nodeConfig.lLabels.push(`Input ${i}`)
			}
		} else {
			// already part of the saved diagram
			nodeDef.nodeConfig.n1 = parseInt(nodeConf.NodeCfg.nodeConfig.n1)
			nodeDef.nodeConfig.lLabels = nodeConf.NodeCfg.nodeConfig.lLabels
		}
		// console.log(nodeDef)
	}

	if (nodeConf !== undefined) {
		// Existing node - existing props
		props = nodeConf.NodeCfg.props
	} else {
		// New node - blank props
		props = nodeDef.props
	}

	canvasIsDirty = true
	// vueInstance.$store.commit('setIsMappingDirty', true)

	if (newNode === true) {
	}

	if (snapToGrid) {
		x = allignToGrid(x)
		y = allignToGrid(y)
	}

	nodeDef.id = nodeId
	console.log('nodeDef.id:', nodeDef.id)
	if (props) {
		// Attach any props the node already has - say when it is loaded in
		nodeDef.props = props
	}

	nodeConfig.push(nodeDef)

	// Dimensions of the node
	let width = 115
	const headerHeight = 24
	const footerHeight = 20

	if (nodeType === 'bureauCustomer') {
		width = 180
	}

	// Calculate the height of the node
	const maxSections = Math.max(nodeDef.nodeConfig.n1, nodeDef.nodeConfig.n2)
	const height = (30 * (1 + maxSections)) + 8 + footerHeight

	// Group
	const group = new createjs.Container()

	stage.addChild(group)

	group.objectType = 'nodeGroup'
	group.nodeId = nodeId
	group.nodecfg = nodeDef

	vueInstance.$store.commit('setSelectedMappingNode', nodeDef)

	// Main Rect
	const mainRect = new createjs.Graphics()

	// if (nodeId === selectedId) {
	//   console.log('selected')
	// }

	mainRect.beginFill(theme.nodeColour)
	mainRect.drawRoundRect(x, y, width, height, nodeRadius)
	const mainRectShape = new createjs.Shape(mainRect)
	mainRectShape.objectType = 'nodeBody'
	mainRectShape.id = nodeId
	mainRectShape.alpha = nodeOpacity
	group.addChild(mainRectShape)

	mainRectShape.on('pressup', function (evt) {
		const n = getObjectWithId(evt.target.id)
		vueInstance.$store.commit('setSelectedMappingNode', n.parent.nodecfg)
		console.log('pressup A')
		vueInstance.$store.commit('setSaveMapping', true)
	})

	// Shadow
	if (showShadows) {
		mainRectShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
	}

	// Header
	const headerRect = new createjs.Graphics()
	headerRect.beginFill(nodeDef.nodeConfig.headerBackColour)
	headerRect.drawRoundRectComplex(x, y, width, headerHeight, nodeRadius, nodeRadius, 0, 0)
	const headerRectShape = new createjs.Shape(headerRect)
	group.addChild(headerRectShape)
	headerRectShape.cursor = 'move'
	headerRectShape.objectType = 'nodeHeader'
	headerRectShape.id = nodeId

	group.headerBackColour = nodeDef.nodeConfig.headerBackColour

	// Click is like mouseup - it triggers when you let go of LMB
	headerRectShape.on('click', evt => {
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		if (evt.nativeEvent.ctrlKey) {
			// Is not already selected.  If so we want to remove it.
			let isSelected = false
			for (const g of selectedObjects) {
				if (g.nodeId === group.nodeId) isSelected = true
			}
			if (!isSelected) {
				addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)
			} else {
				const i = selectedObjects.map(e => { return e.nodeId }).indexOf(group.nodeId)
				selectedObjects.splice(i, 1)
			}
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
			addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)
		}
	})

	// Drag Bar
	headerRectShape.on('mousedown', evt => {
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		if (evt.nativeEvent.ctrlKey) {
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
		}

		dragMode = false
		rubberBandMode = false
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.offset = { x: group.x - adjustedX, y: group.y - adjustedY }
		bringToFront(group)

		// Add offsets
		itemOffsets = []
		for (const g of selectedObjects) {
			const o = getObjectWithId(g.nodeId)
			if (o && o.parent) {
				const p = o.parent
				itemOffsets.push(
					{
						id: g.nodeId,
						item: g,
						itemParent: p,
						offset: new createjs.Point(p.x - group.x, p.y - group.y)
					}
				)
			}
		}
	})

	headerRectShape.on('pressmove', evt => {
		// Is the node already selected?
		// A some nodes are selected and a user press moves
		// an unselected node then everything should be de-selected
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		let isSelected = false
		for (const g of selectedObjects) {
			if (g.nodeId === group.nodeId) isSelected = true
		}
		if (!isSelected) selectedObjects = []

		// Dragging
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.x = adjustedX + group.offset.x
		group.y = adjustedY + group.offset.y
		dragNodeMode = true
		addNodeToSelected(group, nodeDef.nodeConfig.headerBackColour)

		// Now drag any other select nodes by the same amount
		// ***************************************************
		// NOTE:  Careful here - this took ages to figure out!
		// ***************************************************
		for (const g of selectedObjects) {
			if (g.type === 'line') continue
			if (g.nodeId !== group.nodeId) {
				const o = getObjectWithId(g.nodeId)
				if (o.parent) {
					const p = o.parent
					for (const offset of itemOffsets) {
						if (g.nodeId === offset.id) {
							p.x = group.x + offset.offset.x
							p.y = group.y + offset.offset.y
						}
					}
				}
			}
		}
	})

	headerRectShape.on('pressup', evt => {
		// Finished Dragging
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		vueInstance.$store.commit('setSelectedMappingNode', nodeDef)
		vueInstance.$store.commit('setIsMappingDirty', true)
		vueInstance.$store.commit('setSaveMapping', true)
		dragNodeMode = false
		if (connectorsToBack) sendAllConnectorsToBack()
		if (snapToGrid) {
			const actualX = evt.stageX + group.offset.x
			const xDif = actualX % gridSize
			let xDelta
			if (xDif === 0) {
				xDelta = 0
			} else {
				if (xDif > (gridSize / 2)) {
					xDelta = Math.abs(xDif - gridSize)
				} else {
					xDelta = xDif * -1
				}
			}

			// snap y - Find the y distance from the nearest snap point: yDelta
			const actualY = evt.stageY + group.offset.y
			const yDif = actualY % gridSize
			let yDelta = 0
			if (yDif !== 0) {
				if (yDif > (gridSize / 2)) {
					yDelta = Math.abs(yDif - gridSize)
				} else {
					yDelta = yDif * -1
				}
			}

			// Calculate the transform needed between the node's current position and the nearest snap point.
			const dx = actualX + xDelta
			const dy = actualY + yDelta
			createjs.Tween.get(group).to({ x: dx, y: dy }, 300, createjs.Ease.exponentialOut)
				.call(() => {
					repositionAllLineConnectors()
					addToStack()
				})
		} else {
			addToStack()
		}
	})

	headerRectShape.on('mouseover', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 0.80 }, 150, createjs.Ease.exponentialOut)
		popupTimer = setTimeout(() => {
			popupGroup = new createjs.Container()
			stage.addChild(popupGroup)
			popupGroup.objectType = 'popupGroup'

			const popupWidth = 350
			let popupHeight = 32 + (Object.keys(nodeDef.props).length * 32) + 16
			if (nodeDef.title === 'Group') popupHeight += 32

			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			popupOriginX = adjustedX
			popupOriginY = adjustedY
			if (popupOriginX + popupWidth > canvas.width) popupOriginX -= popupWidth

			stage.removeChild(popupShape)
			popupRect = new createjs.Graphics()
			popupRect.beginFill(theme.popupBackColour).drawRect(popupOriginX, popupOriginY, popupWidth, popupHeight)
			popupShape = new createjs.Shape(popupRect)
			popupShape.objectType = 'popup'
			popupShape.alpha = 0.95
			popupGroup.addChild(popupShape)
			if (showShadows) popupShape.shadow = new createjs.Shadow(theme.popupShadowColour, 0, 0, 14)

			// Header
			const popupHdrRect = new createjs.Graphics()
			popupHdrRect.beginFill(nodeDef.nodeConfig.headerBackColour).drawRect(popupOriginX, popupOriginY, popupWidth, 24)
			const popupHdrRectShape = new createjs.Shape(popupHdrRect)
			popupHdrRectShape.objectType = 'popupHdr'
			popupHdrRectShape.alpha = 0.7
			popupGroup.addChild(popupHdrRectShape)

			const popupHdrText = new createjs.Text(nodeDef.title, '12px Arial', theme.popupHeaderForeColour)
			popupHdrText.textAlign = 'center'
			popupHdrText.x = popupOriginX + (popupWidth / 2)
			popupHdrText.y = popupOriginY + 6
			popupHdrText.objectType = 'popupHdrText'
			popupGroup.addChild(popupHdrText)
			console.log(nodeDef)
			// Do we need to go off and get the data
			if (nodeDef.title === 'Account') {
				axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}/BankAccounts/${nodeDef.props.s1.value}`)
					.then(res => {
						if (res && res.data) {
							const propTitleText = new createjs.Text('Bank', '12px Arial', theme.popupForeColour)
							propTitleText.textAlign = 'left'
							propTitleText.x = popupOriginX + 16
							propTitleText.y = popupOriginY + 40
							popupGroup.addChild(propTitleText)

							const propValueText = new createjs.Text(truncateText(res.data.reference), '12px Arial', theme.popupForeValueColour)
							propValueText.textAlign = 'left'
							propValueText.x = popupOriginX + popupWidth / 3
							propValueText.y = popupOriginY + 40
							popupGroup.addChild(propValueText)
						}
					})
			} else if (nodeDef.title === 'Group') {
				axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}/Groups/${nodeDef.props.s1.value}`)
					.then(res => {
						if (res && res.data && res.data.name) {
							const propTitleText = new createjs.Text('Group', '12px Arial', theme.popupForeColour)
							propTitleText.textAlign = 'left'
							propTitleText.x = popupOriginX + 16
							propTitleText.y = popupOriginY + 40
							popupGroup.addChild(propTitleText)

							const propValueText = new createjs.Text(truncateText(res.data.name), '12px Arial', theme.popupForeValueColour)
							propValueText.textAlign = 'left'
							propValueText.x = popupOriginX + popupWidth / 3
							propValueText.y = popupOriginY + 40
							popupGroup.addChild(propValueText)

							const propTitleText2 = new createjs.Text('Group Type', '12px Arial', theme.popupForeColour)
							propTitleText2.textAlign = 'left'
							propTitleText2.x = popupOriginX + 16
							propTitleText2.y = popupOriginY + 72
							popupGroup.addChild(propTitleText2)

							const propValueText2 = new createjs.Text(res.data.groupType, '12px Arial', theme.popupForeValueColour)
							propValueText2.textAlign = 'left'
							propValueText2.x = popupOriginX + popupWidth / 3
							propValueText2.y = popupOriginY + 72
							popupGroup.addChild(propValueText2)
						}
					})
			} else {
				// Values
				const props = nodeDef.props
				// console.log(props)
				let propPosY = 40
				Object.keys(props).forEach(p => {
					const propTitleText = new createjs.Text(truncateText(props[p].title, 16), '12px Arial', theme.popupForeColour)
					propTitleText.textAlign = 'left'
					propTitleText.x = popupOriginX + 16
					propTitleText.y = popupOriginY + propPosY
					popupGroup.addChild(propTitleText)

					const propValueText = new createjs.Text(truncateText(props[p].value), '12px Arial', theme.popupForeValueColour)
					propValueText.textAlign = 'left'
					propValueText.x = popupOriginX + popupWidth / 3
					propValueText.y = popupOriginY + propPosY
					popupGroup.addChild(propValueText)
					propPosY += 32
				})
			}
		}, 500)
	})
	headerRectShape.on('mouseout', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
	})

	// Header Text
	const text = new createjs.Text(nodeDef.title, '12px Arial', theme.headerLabelColour)
	text.textAlign = 'center'
	text.x = x + (width / 2)
	text.y = y + 6
	text.objectType = 'headerText'
	group.addChild(text)

	// Footer
	const footerRect = new createjs.Graphics()
	footerRect.beginFill(theme.nodeFooterBackColour)
	footerRect.drawRoundRectComplex(x, y + height - footerHeight, width, headerHeight, 0, 0, nodeRadius, nodeRadius)
	const footerRectShape = new createjs.Shape(footerRect)
	footerRectShape.objectType = 'NodeFooter'
	group.addChild(footerRectShape)

	// ID Icon
	const idIcon = new createjs.Text(nodeDef.nodeConfig.icon, '900 13px \'Font Awesome 5 Free\'', theme.headerLabelColour)
	idIcon.textAlign = 'left'
	idIcon.x = x + 6
	idIcon.y = y + 5
	idIcon.objectType = 'idIcon'
	group.addChild(idIcon)

	const cfgIcon = new createjs.Text('\uf013', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	cfgIcon.textAlign = 'right'
	cfgIcon.x = x + width - 6
	cfgIcon.y = y + height - 15
	cfgIcon.objectType = 'cfgIcon'
	group.addChild(cfgIcon)

	const cfgIconRect = new createjs.Graphics()
	cfgIconRect.beginFill('#ffffff')
	cfgIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
	const cfgIconRectShape = new createjs.Shape(cfgIconRect)
	cfgIconRectShape.alpha = 0.01
	group.addChild(cfgIconRectShape)
	cfgIconRectShape.cursor = 'pointer'
	cfgIconRectShape.objectType = 'cfgIconRectShape'
	cfgIconRectShape.on('mousedown', function (evt) {
		// Send the selected node to the vuex state: selectedNode
		dragMode = false
		rubberBandMode = false
		vueInstance.$store.commit('setSelectedMappingNode', nodeDef)
	})
	cfgIconRectShape.on('pressup', function (evt) {
		console.log('cfg icon was clicked')
		destroyClipboard()
		vueInstance.$store.commit('setMappingPopupState', true)
	})

	const helpIcon = new createjs.Text('\uf059', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	helpIcon.textAlign = 'right'
	helpIcon.x = x + width - 24
	helpIcon.y = y + height - 15
	helpIcon.objectType = 'helpIcon'
	group.addChild(helpIcon)

	const helpIconRect = new createjs.Graphics()
	helpIconRect.beginFill('#ffffff')
	helpIconRect.drawRect(x + width - 37, y + height - 15, 13, 13)
	const helpIconRectShape = new createjs.Shape(helpIconRect)
	helpIconRectShape.alpha = 0.01
	group.addChild(helpIconRectShape)
	helpIconRectShape.cursor = 'help'
	helpIconRectShape.objectType = 'helpIconRectShape'
	helpIconRectShape.on('mousedown', function (evt) {
		dragMode = false
		rubberBandMode = false
		const url = process.env.VUE_APP_DOCUMENTATION_ROOT_URL.concat(nodeDef.nodeConfig.htmlLink)
		window.open(url, '_blank')
	})

	// Draw the input connectors
	let ypos = y + 45
	let p = 0
	for (let yp = 0; yp < nodeDef.nodeConfig.n1; yp++) {
		// Connectors
		const ipcnt = new createjs.Graphics()
		ipcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		ipcnt.setStrokeStyle(1)
		ipcnt.beginFill('#eeeeee')

		ipcnt.drawRect(x, ypos, connectorSize, connectorSize)
		const ipcntShape = new createjs.Shape(ipcnt)

		ipcntShape.cursor = 'crosshair'
		group.addChild(ipcntShape)

		ipcntShape.on('mouseover', function (evt) {
			inputConnectorTarget = ipcntShape
		})

		ipcntShape.on('mouseout', function (evt) {
			inputConnectorTarget = null
		})

		ipcntShape.on('pressmove', function (evt) {
			if (lineDrawingMode) {
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				const adjustedX = evt.stageX - dragDeltaX
				const adjustedY = evt.stageY - dragDeltaY
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2

				// Create a new line.  A new line is created every mouse move
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				getClosestInput(evt)
				stage.addChild(drawingLineShape)
			}
		})

		ipcntShape.on('mousedown', evt => {
			// Get a list of lines connected to this connector
			dragMode = false
			rubberBandMode = false
			const links = []
			let op, ip
			let pickedLine
			for (let i = stage.numChildren - 1; i >= 0; i--) {
				const child = stage.getChildAt(i)
				if (child.objectType === 'lineConnector') {
					if (child.data.TargetParent) {
						if (child.data.TargetParent.id === evt.target.id) {
							pickedLine = child
							op = child.data.OriginParent
							ip = child.data.TargetParent
							links.push(child.data.Id)
						}
					}
				}
			}

			// Handle differently depending on number of
			// links connected to a connection point

			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY

			if (links.length === 0) {
			} else if (links.length === 1) {
				// Set up the drawing line
				outputConnectorOrigin = op
				originalUnpluggedNode = ip
				lineOriginX = getObjectX(op)
				lineOriginY = getObjectY(op)
				lineDrawingMode = true

				// Draw a line to stop a visual glitch
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				stage.addChild(drawingLineShape)

				// Remove old line(s)
				removeShadowLine(pickedLine.data.Id)
				stage.removeChild(pickedLine)
			} else {
				// more than one link.  Need to determine which link
				// to remove

				// Selected link has priority.
				// Is one of the links the selected link
				if (_.includes(links, selectedId)) {
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === selectedId) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				} else {
					// Just pick the first connector in the array
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === _.head(links)) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				}
			}
		})

		ipcntShape.on('pressup', evt => {
			vueInstance.$store.commit('setSaveMapping', true)
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				// Find Closest input connector
				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					// Is there already a connector though?
					const hasInput = doesInputHaveConnector(closestInput.id)
					if (!hasInput) {
						inputConnectorTarget = getObjectWithId(closestInput.id)
						drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
					} else {
						stage.removeChild(magneticShape)
						lineDrawingMode = false
					}
				} else {
					drawLineConnector(outputConnectorOrigin, originalUnpluggedNode, false)
				}

				inputConnectorTarget = undefined
				addToStack()
			} else {
				lineDrawingMode = false
			}
		})

		ipcntShape.objectType = 'nodeInput'

		if (newNode) {
			ipcntShape.id = createGuid()
		} else {
			for (let ii = 0; ii < workflow.inputs.length; ii++) {
				if (workflow.inputs[ii].Row === p && workflow.inputs[ii].ParentId === nodeId) {
					ipcntShape.id = workflow.inputs[ii].Id
				}
			}
		}

		ipcntShape.parentId = nodeId
		ipcntShape.row = yp

		// Label
		const ipcntText = new createjs.Text(nodeDef.nodeConfig.lLabels[p++], '12px Arial', theme.bodyLabelColour)
		ipcntText.textAlign = 'left'
		ipcntText.x = x + connectorSize + 4
		ipcntText.y = ypos - 2
		ipcntText.objectType = 'label'
		group.addChild(ipcntText)
		ypos += 30
	}

	// Draw the output connectors
	p = 0
	ypos = y + 45
	for (let yp = 0; yp < nodeDef.nodeConfig.n2; yp++) {
		// Connectors
		const opcnt = new createjs.Graphics()
		opcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		opcnt.setStrokeStyle(1)
		opcnt.beginFill('#eeeeee')
		opcnt.drawRect(x + width - connectorSize, ypos, connectorSize, connectorSize)
		const opcntShape = new createjs.Shape(opcnt)
		opcntShape.cursor = 'crosshair'
		group.addChild(opcntShape)

		opcntShape.on('mousedown', function (evt) {
			console.log('opcntShape.id:', opcntShape.id)
			console.log('opcntShape.row:', opcntShape.row)
			console.log('opcntShape.parentId:', opcntShape.parentId)
			console.log('newNode:', newNode)
			dragMode = false
			rubberBandMode = false
			// Store the co-ordinates of where the mouse down happened. This is the origin of the new line.
			outputConnectorOrigin = evt.target
			lineOriginX = getObjectX(outputConnectorOrigin)
			lineOriginY = getObjectY(outputConnectorOrigin)
			lineDrawingMode = true
		})

		// Metadata
		opcntShape.objectType = 'nodeOutput'

		if (newNode) {
			opcntShape.id = createGuid()
		} else {
			console.log('nodeId:', nodeId)
			console.log('p:', p)
			for (let ii = 0; ii < workflow.outputs.length; ii++) {
				console.log('ii:', ii)
				if ((workflow.outputs[ii].Row === p) && (workflow.outputs[ii].ParentId === nodeId)) {
					opcntShape.id = workflow.outputs[ii].Id
					console.log('Match')
					console.log(workflow.outputs[ii])
					console.log('workflow.outputs[ii].ParentId:', workflow.outputs[ii].ParentId)
					console.log('opcntShape.id :', opcntShape.id)
					// On number nodes there seems to be two matches.  The first (correct) gets overwritten.
					// Not sure why but if there are future issues with migration then it's going to be
					// related to this issue.
					break
				}
			}
		}

		opcntShape.parentId = nodeId
		opcntShape.row = yp
		opcntShape.on('pressmove', function (evt) {
			// Take dragging offset into account
			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			// Remove the old line
			stage.removeChild(drawingLineShape)
			stage.removeChild(magneticShape)
			// Bezier Control Point
			const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
			// Create a new line.  A new line is created wvery mouse move
			drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
			drawingLineShape = new createjs.Shape(drawingLine)
			// bringToFront(drawingLineShape)
			if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
			getClosestInput(evt)
			stage.addChild(drawingLineShape)
		})

		opcntShape.on('pressup', evt => {
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					// Is there already a connector though?
					const hasInput = doesInputHaveConnector(closestInput.id)
					if (!hasInput) {
						inputConnectorTarget = getObjectWithId(closestInput.id)
						drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
					} else {
						stage.removeChild(magneticShape)
						lineDrawingMode = false
					}
				} else {
					lineDrawingMode = false
				}
				inputConnectorTarget = undefined
				addToStack()
				vueInstance.$store.commit('setSaveMapping', true)
			} else {
				lineDrawingMode = false
			}
		})

		// Label
		const opcntText = new createjs.Text(nodeDef.nodeConfig.rLabels[p++], '12px Arial', theme.bodyLabelColour)
		opcntText.textAlign = 'right'
		opcntText.x = x + width - connectorSize - 4
		opcntText.y = ypos - 2
		opcnt.objectType = 'label'
		group.addChild(opcntText)
		ypos += 30
	}

	if (enableShapeCaching) {
		group.cache(x - 8, y - 8, width + 32, height + 32)
	}
	vueInstance.$store.commit('setSaveMapping', true)
}

export const createImporter = (x, y, importer, id, mapping) => {
	// This is not a brand new node, it is being recreated from say a saved workflow.
	// This is important because if it is being recreated then a lot of id's need to be reused and not recreated,
	let newNode = false
	let nodeId = ''
	if (id === undefined) {
		nodeId = createGuid()
		newNode = true
	} else {
		nodeId = id
		newNode = false
	}

	canvasIsDirty = true

	if (snapToGrid) {
		x = allignToGrid(x)
		y = allignToGrid(y)
	}

	// Dimensions of the node
	const width = 215
	const headerHeight = 24
	const footerHeight = 20

	// Calculate the height of the node
	let numEnabledOutputs = 0
	for (let i = 0; i < importer.configuration.length; i++) {
		if (importer.configuration[i].enabled === true) {
			numEnabledOutputs++
		}
	}
	const height = (30 * (1 + numEnabledOutputs)) + 8 + footerHeight

	// Group
	const group = new createjs.Container()
	stage.addChild(group)
	group.objectType = 'importerGroup'
	group.nodeId = nodeId
	group.importer = importer

	// Main Rect
	const mainRect = new createjs.Graphics()
	mainRect.beginFill(theme.nodeColour)
	mainRect.drawRoundRect(x, y, width, height, nodeRadius)
	const mainRectShape = new createjs.Shape(mainRect)
	mainRectShape.objectType = 'nodeBody'
	mainRectShape.id = nodeId
	mainRectShape.alpha = nodeOpacity
	group.addChild(mainRectShape)

	mainRectShape.on('mouseover', evt => {
		createjs.Tween.get(evt.target).to({ alpha: 0.9 }, 150, createjs.Ease.exponentialOut)
	})
	mainRectShape.on('mouseout', evt => {
		createjs.Tween.get(evt.target).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
	})
	mainRectShape.on('pressup', evt => {
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		const n = getObjectWithId(evt.target.id)
		if (n.parent.nodecfg) {
			vueInstance.$store('setSelectedMappingNode', n.parent.nodecfg)
		}
		vueInstance.$store.commit('setSaveMapping', true)
	})

	// Shadow
	if (showShadows) mainRectShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
	// Header
	const headerBackColour = '#077F3F'
	const headerRect = new createjs.Graphics()
	headerRect.beginFill(headerBackColour)
	headerRect.drawRoundRectComplex(x, y, width, headerHeight, nodeRadius, nodeRadius, 0, 0)
	const headerRectShape = new createjs.Shape(headerRect)
	group.addChild(headerRectShape)
	headerRectShape.cursor = 'move'
	headerRectShape.objectType = 'nodeHeader'
	headerRectShape.id = nodeId

	// headerRectShape.on('doubleclick', evt => {
	//   console.log('double click')
	//   vueInstance.$store.commit('setMappingPopupState', true)
	// })

	group.headerBackColour = headerBackColour

	// Drag Bar
	headerRectShape.on('click', evt => {
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		if (evt.nativeEvent.ctrlKey) {
			// Is not already selected.  If so we want to remove it.
			let isSelected = false
			for (const g of selectedObjects) {
				if (g.nodeId === group.nodeId) isSelected = true
			}
			if (!isSelected) {
				addNodeToSelected(group, headerBackColour)
			} else {
				const i = selectedObjects.map(function (e) { return e.nodeId }).indexOf(group.nodeId)
				selectedObjects.splice(i, 1)
			}
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
			addNodeToSelected(group, headerBackColour)
		}
	})

	headerRectShape.on('mousedown', evt => {
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		if (evt.nativeEvent.ctrlKey) {
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
		}

		dragMode = false
		rubberBandMode = false
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.offset = { x: group.x - adjustedX, y: group.y - adjustedY }
		bringToFront(group)

		// Add offsets
		itemOffsets = []
		for (const g of selectedObjects) {
			const o = getObjectWithId(g.nodeId)
			const p = o.parent
			itemOffsets.push(
				{
					id: g.nodeId,
					item: g,
					itemParent: p,
					offset: new createjs.Point(p.x - group.x, p.y - group.y)
				}
			)
		}
	})

	headerRectShape.on('pressmove', evt => {
		// Is the node already selected?
		// A some nodes are selected and a user press moves
		// an unselected node then everything should be de-selected
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		let isSelected = false
		for (const g of selectedObjects) {
			if (g.nodeId === group.nodeId) isSelected = true
		}
		if (!isSelected) selectedObjects = []

		// Dragging
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.x = adjustedX + group.offset.x
		group.y = adjustedY + group.offset.y
		dragNodeMode = true
		addNodeToSelected(group, headerBackColour)

		// Now drag any other select nodes by the same amount
		// ***************************************************
		// NOTE:  Careful here - this look ages to figure out!
		// ***************************************************
		for (const g of selectedObjects) {
			if (g.nodeId !== group.nodeId) {
				const o = getObjectWithId(g.nodeId)
				if (o.parent) {
					const p = o.parent
					for (const offset of itemOffsets) {
						if (g.nodeId === offset.id) {
							p.x = group.x + offset.offset.x
							p.y = group.y + offset.offset.y
						}
					}
				}
			}
		}
	})

	headerRectShape.on('pressup', evt => {
		// Finished Dragging
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
		vueInstance.$store.commit('setIsMappingDirty', true)
		vueInstance.$store.commit('setSaveMapping', true)
		dragNodeMode = false
		if (connectorsToBack) sendAllConnectorsToBack()
		if (snapToGrid) {
			const actualX = evt.stageX + group.offset.x
			const xDif = actualX % gridSize

			let xDelta = 0
			if (xDif !== 0) {
				if (xDif > (gridSize / 2)) {
					xDelta = Math.abs(xDif - gridSize)
				} else {
					xDelta = xDif * -1
				}
			}

			const adjustedY = evt.stageY - dragDeltaY

			// snap y - Find the y distance from the nearest snap point: yDelta
			const actualY = adjustedY + group.offset.y
			const yDif = actualY % gridSize
			let yDelta = 0
			if (yDif !== 0) {
				if (yDif > (gridSize / 2)) {
					yDelta = Math.abs(yDif - gridSize)
				} else {
					yDelta = yDif * -1
				}
			}

			// Calculate the transform needed between the node's current position and the nearest snap point.
			const dx = actualX + xDelta
			const dy = actualY + yDelta
			createjs.Tween.get(group).to({ x: dx, y: dy }, 300, createjs.Ease.exponentialOut)
				.call(() => {
					repositionAllLineConnectors()
					addToStack()
				})
		} else {
			addToStack()
		}
	})

	headerRectShape.on('mouseover', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 0.85 }, 150, createjs.Ease.exponentialOut)
		popupTimer = setTimeout(() => {
			popupGroup = new createjs.Container()
			stage.addChild(popupGroup)
			popupGroup.objectType = 'popupGroup'

			const popupWidth = 350
			const popupHeight = 32 + (2 * 32) + 16

			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			popupOriginX = adjustedX
			popupOriginY = adjustedY
			if (popupOriginX + popupWidth > canvas.width) popupOriginX -= popupWidth

			stage.removeChild(popupShape)
			popupRect = new createjs.Graphics()
			popupRect.beginFill(theme.popupBackColour).drawRect(popupOriginX, popupOriginY, popupWidth, popupHeight)
			popupShape = new createjs.Shape(popupRect)
			popupShape.objectType = 'popup'
			popupShape.alpha = 0.95
			popupGroup.addChild(popupShape)
			if (showShadows) popupShape.shadow = new createjs.Shadow(theme.popupShadowColour, 0, 0, 14)

			// Header
			const popupHdrRect = new createjs.Graphics()
			popupHdrRect.beginFill('#077F3F').drawRect(popupOriginX, popupOriginY, popupWidth, 24)
			const popupHdrRectShape = new createjs.Shape(popupHdrRect)
			popupHdrRectShape.objectType = 'popupHdr'
			popupHdrRectShape.alpha = 0.7
			popupGroup.addChild(popupHdrRectShape)

			const popupHdrText = new createjs.Text(importer.title, '12px Arial', theme.popupHeaderForeColour)
			popupHdrText.textAlign = 'center'
			popupHdrText.x = popupOriginX + (popupWidth / 2)
			popupHdrText.y = popupOriginY + 6
			popupHdrText.objectType = 'popupHdrText'
			popupGroup.addChild(popupHdrText)
			console.log(importer)

			const propTitleText1 = new createjs.Text('Data Type', '12px Arial', theme.popupForeColour)
			propTitleText1.textAlign = 'left'
			propTitleText1.x = popupOriginX + 16
			propTitleText1.y = popupOriginY + 40
			popupGroup.addChild(propTitleText1)

			let propValue2Text = 'Bacs Payments'
			if (importer.dataType === 'fixedLength') propValue2Text = 'Fixed Length'

			const propValueText1 = new createjs.Text(truncateText(propValue2Text), '12px Arial', theme.popupForeValueColour)
			propValueText1.textAlign = 'left'
			propValueText1.x = popupOriginX + popupWidth / 3
			propValueText1.y = popupOriginY + 40
			popupGroup.addChild(propValueText1)

			const propTitleText2 = new createjs.Text('Importer Type', '12px Arial', theme.popupForeColour)
			propTitleText2.textAlign = 'left'
			propTitleText2.x = popupOriginX + 16
			propTitleText2.y = popupOriginY + 72
			popupGroup.addChild(propTitleText2)

			let propValue3Text = 'Delimited'
			if (importer.importerType === 'fixedlength') propValue3Text = 'Fixed Length'
			const propValueText2 = new createjs.Text(truncateText(propValue3Text), '12px Arial', theme.popupForeValueColour)
			propValueText2.textAlign = 'left'
			propValueText2.x = popupOriginX + popupWidth / 3
			propValueText2.y = popupOriginY + 72
			popupGroup.addChild(propValueText2)

			// const propTitleText3 = new createjs.Text('Don\'t write data', '12px Arial', theme.popupForeColour)
			// propTitleText3.textAlign = 'left'
			// propTitleText3.x = popupOriginX + 16
			// propTitleText3.y = popupOriginY + 104
			// popupGroup.addChild(propTitleText3)

			// const propValueText3 = new createjs.Text(truncateText(importer.importerType), '12px Arial', theme.popupForeValueColour)
			// propValueText3.textAlign = 'left'
			// propValueText3.x = popupOriginX + popupWidth / 3
			// propValueText3.y = popupOriginY + 104
			// popupGroup.addChild(propValueText3)
		}, 500)
	})
	headerRectShape.on('mouseout', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
		clearTimeout(popupTimer)
		stage.removeChild(popupGroup)
	})

	// Header Text
	const text = new createjs.Text(importer.title, '12px Arial', theme.headerLabelColour)
	text.textAlign = 'center'
	text.x = x + (width / 2)
	text.y = y + 6
	text.objectType = 'headerText'
	group.addChild(text)

	// Footer
	const footerRect = new createjs.Graphics()
	footerRect.beginFill(theme.nodeFooterBackColour)
	footerRect.drawRoundRectComplex(x, y + height - footerHeight, width, headerHeight, 0, 0, nodeRadius, nodeRadius)
	const footerRectShape = new createjs.Shape(footerRect)
	footerRectShape.objectType = 'NodeFooter'
	group.addChild(footerRectShape)

	// ID Icon
	const idIcon = new createjs.Text('\uf0ce', '900 13px \'Font Awesome 5 Free\'', theme.headerLabelColour)
	idIcon.textAlign = 'left'
	idIcon.x = x + 6
	idIcon.y = y + 5
	idIcon.objectType = 'idIcon'
	group.addChild(idIcon)

	const editIcon = new createjs.Text('\uf044', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	editIcon.textAlign = 'right'
	editIcon.x = x + 21
	editIcon.y = y + height - 15
	editIcon.objectType = 'editIcon'
	group.addChild(editIcon)

	const editIconRect = new createjs.Graphics()
	editIconRect.beginFill('#ffffff')
	editIconRect.drawRect(x + 6, y + height - 15, 13, 13)
	const editIconRectShape = new createjs.Shape(editIconRect)
	editIconRectShape.alpha = 0.01
	group.addChild(editIconRectShape)
	editIconRectShape.cursor = 'pointer'
	editIconRectShape.objectType = 'editIconRectShape'

	editIconRectShape.on('pressup', function (evt) {
		// vueInstance.$store.commit('setMappingPopupState', true)
		// Jumnp to the import config page.  But there are different pages
		// depending on importerType
		// TODO: We'll want to save the mapping at this point otherwise unsaved
		// changes will be lost
		// vueInstance.$router.push(`/automation/mapping/import/${importer.importerType}Importer/${importer._id}`)

		const routeData = vueInstance.$router.resolve(`/automation/mapping/import/${importer.importerType}Importer/${importer._id}`)
		window.open(routeData.href, '_blank')
	})

	// const cfgIcon = new createjs.Text('\uf013', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	// cfgIcon.textAlign = 'right'
	// cfgIcon.x = x + width - 6
	// cfgIcon.y = y + height - 15
	// cfgIcon.objectType = 'cfgIcon'
	// group.addChild(cfgIcon)

	// const cfgIconRect = new createjs.Graphics()
	// cfgIconRect.beginFill('#ffffff')
	// cfgIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
	// const cfgIconRectShape = new createjs.Shape(cfgIconRect)
	// cfgIconRectShape.alpha = 0.01
	// group.addChild(cfgIconRectShape)
	// cfgIconRectShape.cursor = 'pointer'
	// cfgIconRectShape.objectType = 'cfgIconRectShape'
	// cfgIconRectShape.on('mousedown', function (evt) {
	//   dragMode = false
	//   rubberBandMode = false
	// })
	// cfgIconRectShape.on('pressup', function (evt) {
	//   vueInstance.$store.commit('setMappingPopupState', true)
	// })

	console.log('importer')
	console.log(importer._id)

	const helpIcon = new createjs.Text('\uf059', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	helpIcon.textAlign = 'right'
	helpIcon.x = x + width - 6
	helpIcon.y = y + height - 15
	helpIcon.objectType = 'helpIcon'
	group.addChild(helpIcon)

	const helpIconRect = new createjs.Graphics()
	helpIconRect.beginFill('#ffffff')
	helpIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
	const helpIconRectShape = new createjs.Shape(helpIconRect)
	helpIconRectShape.alpha = 0.01
	group.addChild(helpIconRectShape)
	helpIconRectShape.cursor = 'help'
	helpIconRectShape.objectType = 'helpIconRectShape'
	helpIconRectShape.on('mousedown', function (evt) {
		dragMode = false
		rubberBandMode = false
		const url = process.env.VUE_APP_DOCUMENTATION_ROOT_URL.concat('/automation/paygate-mapping/importers/')
		window.open(url, '_blank')
	})

	// Draw the output connectors
	let p = 0
	let ypos = y + 45
	for (let yp = 0; yp < importer.configuration.length; yp++) {
		if (importer.configuration[p].enabled === false) {
			p++
			continue
		}
		// Connectors
		const opcnt = new createjs.Graphics()
		opcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		opcnt.setStrokeStyle(1)
		opcnt.beginFill('#eeeeee')
		opcnt.drawRect(x + width - connectorSize, ypos, connectorSize, connectorSize)
		const opcntShape = new createjs.Shape(opcnt)
		opcntShape.cursor = 'crosshair'
		group.addChild(opcntShape)

		opcntShape.on('mousedown', function (evt) {
			dragMode = false
			rubberBandMode = false
			// Store the co-ordinates of where the mouse down happened. This is the origin of the new line.
			outputConnectorOrigin = evt.target
			lineOriginX = getObjectX(outputConnectorOrigin)
			lineOriginY = getObjectY(outputConnectorOrigin)
			lineDrawingMode = true
		})

		// Metadata
		opcntShape.objectType = 'nodeOutput'

		if (newNode) {
			opcntShape.id = createGuid()
		} else {
			for (let ii = 0; ii < mapping.outputs.length; ii++) {
				if (mapping.outputs[ii].Row === p && mapping.outputs[ii].ParentId === nodeId) {
					opcntShape.id = mapping.outputs[ii].Id
				}
			}
		}

		opcntShape.parentId = nodeId
		opcntShape.row = yp
		opcntShape.on('pressmove', function (evt) {
			// Take dragging offset into account
			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			// Remove the old line
			stage.removeChild(drawingLineShape)
			stage.removeChild(magneticShape)
			// Bezier Control Point
			const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
			// Create a new line.  A new line is created wvery mouse move
			drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
			drawingLineShape = new createjs.Shape(drawingLine)
			if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
			getClosestInput(evt)
			stage.addChild(drawingLineShape)
		})

		opcntShape.on('pressup', function (evt) {
			vueInstance.$store.commit('setSaveMapping', true)
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)
				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					// Is there already a connector though?
					const hasInput = doesInputHaveConnector(closestInput.id)
					if (!hasInput) {
						inputConnectorTarget = getObjectWithId(closestInput.id)
						drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
					} else {
						stage.removeChild(magneticShape)
						lineDrawingMode = false
					}
				} else {
					lineDrawingMode = false
				}
				inputConnectorTarget = undefined
				addToStack()
				vueInstance.$store.commit('setSaveMapping', true)
			} else {
				lineDrawingMode = false
			}
		})

		// Label
		const opcntText = new createjs.Text(importer.configuration[p].strongType, '12px Arial', theme.bodyLabelColour)
		opcntText.textAlign = 'right'
		opcntText.x = x + width - connectorSize - 4
		opcntText.y = ypos - 2
		opcnt.objectType = 'label'
		// If there's no strong type assigned - use basic text label
		if (!importer.configuration[p].strongType) {
			opcntText.text = `Output: ${p + 1}`
		}

		group.addChild(opcntText)
		ypos += 30
		p++
	}

	if (enableShapeCaching) {
		group.cache(x - 8, y - 8, width + 32, height + 32)
	}
	vueInstance.$store.commit('setSaveMapping', true)
}

export const createExporter = (x, y, exporter, id, mapping) => {
	// This is not a brand new node, it is being recreated from say a saved workflow.
	// This is important because if it is being recreated then a lot of id's need to be reused and not recreated,
	let newNode = false
	let nodeId = ''
	if (id === undefined) {
		nodeId = createGuid()
		newNode = true
	} else {
		nodeId = id
		newNode = false
	}

	canvasIsDirty = true

	if (snapToGrid) {
		x = allignToGrid(x)
		y = allignToGrid(y)
	}

	// Dimensions of the node
	const width = 215
	const headerHeight = 24
	const footerHeight = 20

	// Calculate the height of the node
	let numEnabledOutputs = 0
	for (let i = 0; i < exporter.configuration.length; i++) {
		if (exporter.configuration[i].enabled === true) {
			numEnabledOutputs++
		}
	}
	const height = (30 * (1 + numEnabledOutputs)) + 8 + footerHeight

	// Group
	const group = new createjs.Container()
	stage.addChild(group)
	group.objectType = 'exporterGroup'
	group.nodeId = nodeId
	group.exporter = exporter

	// Main Rect
	const mainRect = new createjs.Graphics()
	mainRect.beginFill(theme.nodeColour)
	mainRect.drawRoundRect(x, y, width, height, nodeRadius)
	const mainRectShape = new createjs.Shape(mainRect)
	mainRectShape.objectType = 'nodeBody'
	mainRectShape.id = nodeId
	mainRectShape.alpha = nodeOpacity
	group.addChild(mainRectShape)

	mainRectShape.on('mouseover', function (evt) {
		createjs.Tween.get(evt.target).to({ alpha: 0.9 }, 150, createjs.Ease.exponentialOut)
	})
	mainRectShape.on('mouseout', function (evt) {
		createjs.Tween.get(evt.target).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
	})
	mainRectShape.on('pressup', function (evt) {
		const n = getObjectWithId(evt.target.id)
		if (n.parent.nodecfg) {
			vueInstance.$store('setSelectedMappingNode', n.parent.nodecfg)
		}
		vueInstance.$store.commit('setSaveMapping', true)
	})

	// Shadow
	if (showShadows) mainRectShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
	// Header
	const headerBackColour = '#177F3F'
	const headerRect = new createjs.Graphics()
	headerRect.beginFill(headerBackColour)
	headerRect.drawRoundRectComplex(x, y, width, headerHeight, nodeRadius, nodeRadius, 0, 0)
	const headerRectShape = new createjs.Shape(headerRect)
	group.addChild(headerRectShape)
	headerRectShape.cursor = 'move'
	headerRectShape.objectType = 'nodeHeader'
	headerRectShape.id = nodeId

	headerRectShape.on('doubleclick', function (evt) {
		console.log('double click')
		vueInstance.$store.commit('setMappingPopupState', true)
	})

	group.headerBackColour = headerBackColour

	// Drag Bar
	headerRectShape.on('click', evt => {
		if (evt.nativeEvent.ctrlKey) {
			// Is not already selected.  If so we want to remove it.
			let isSelected = false
			for (const g of selectedObjects) {
				if (g.nodeId === group.nodeId) isSelected = true
			}
			if (!isSelected) {
				addNodeToSelected(group, headerBackColour)
			} else {
				const i = selectedObjects.map(function (e) { return e.nodeId }).indexOf(group.nodeId)
				selectedObjects.splice(i, 1)
			}
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
			addNodeToSelected(group, headerBackColour)
		}
	})

	headerRectShape.on('mousedown', evt => {
		if (evt.nativeEvent.ctrlKey) {
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
		}

		dragMode = false
		rubberBandMode = false
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.offset = { x: group.x - adjustedX, y: group.y - adjustedY }
		bringToFront(group)

		// Add offsets
		itemOffsets = []
		for (const g of selectedObjects) {
			const o = getObjectWithId(g.nodeId)
			if (o && o.parent) {
				const p = o.parent
				itemOffsets.push(
					{
						id: g.nodeId,
						item: g,
						itemParent: p,
						offset: new createjs.Point(p.x - group.x, p.y - group.y)
					}
				)
			}
		}
	})

	headerRectShape.on('pressmove', evt => {
		// Is the node already selected?
		// A some nodes are selected and a user press moves
		// an unselected node then everything should be de-selected
		let isSelected = false
		for (const g of selectedObjects) {
			if (g.nodeId === group.nodeId) isSelected = true
		}
		if (!isSelected) selectedObjects = []

		// Dragging
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.x = adjustedX + group.offset.x
		group.y = adjustedY + group.offset.y
		dragNodeMode = true
		addNodeToSelected(group, headerBackColour)

		// Now drag any other select nodes by the same amount
		// ***************************************************
		// NOTE:  Careful here - this took ages to figure out!
		// ***************************************************
		for (const g of selectedObjects) {
			if (g.nodeId !== group.nodeId) {
				const o = getObjectWithId(g.nodeId)
				if (o.parent) {
					const p = o.parent
					for (const offset of itemOffsets) {
						if (g.nodeId === offset.id) {
							p.x = group.x + offset.offset.x
							p.y = group.y + offset.offset.y
						}
					}
				}
			}
		}
	})

	headerRectShape.on('pressup', evt => {
		// Finished Dragging
		vueInstance.$store.commit('setIsMappingDirty', true)
		vueInstance.$store.commit('setSaveMapping', true)
		if (connectorsToBack) sendAllConnectorsToBack()
		dragNodeMode = false
		if (snapToGrid) {
			const actualX = evt.stageX + group.offset.x
			const xDif = actualX % gridSize
			let xDelta = 0
			if (xDif !== 0) {
				if (xDif > (gridSize / 2)) {
					xDelta = Math.abs(xDif - gridSize)
				} else {
					xDelta = xDif * -1
				}
			}

			// let adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY

			// snap y - Find the y distance from the nearest snap point: yDelta
			const actualY = adjustedY + group.offset.y
			const yDif = actualY % gridSize
			let yDelta = 0
			if (yDif !== 0) {
				if (yDif > (gridSize / 2)) {
					yDelta = Math.abs(yDif - gridSize)
				} else {
					yDelta = yDif * -1
				}
			}

			// Calculate the transform needed between the node's current position and the nearest snap point.
			const dx = actualX + xDelta
			const dy = actualY + yDelta

			createjs.Tween.get(group).to({ x: dx, y: dy }, 300, createjs.Ease.exponentialOut)
				.call(function () {
					repositionAllLineConnectors()
					addToStack()
				}
				)
		} else {
			addToStack()
		}
	})

	headerRectShape.on('mouseover', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 0.85 }, 150, createjs.Ease.exponentialOut)
	})
	headerRectShape.on('mouseout', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
	})

	// Header Text
	const text = new createjs.Text(exporter.title, '12px Arial', theme.headerLabelColour)
	text.textAlign = 'center'
	text.x = x + (width / 2)
	text.y = y + 6
	text.objectType = 'headerText'
	group.addChild(text)

	// Footer
	const footerRect = new createjs.Graphics()
	footerRect.beginFill(theme.nodeFooterBackColour)
	footerRect.drawRoundRectComplex(x, y + height - footerHeight, width, headerHeight, 0, 0, nodeRadius, nodeRadius)
	const footerRectShape = new createjs.Shape(footerRect)
	footerRectShape.objectType = 'NodeFooter'
	group.addChild(footerRectShape)

	// ID Icon
	const idIcon = new createjs.Text('\uf0ce', '900 13px \'Font Awesome 5 Free\'', theme.headerLabelColour)
	idIcon.textAlign = 'left'
	idIcon.x = x + 6
	idIcon.y = y + 5
	idIcon.objectType = 'idIcon'
	group.addChild(idIcon)

	const editIcon = new createjs.Text('\uf044', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	editIcon.textAlign = 'right'
	editIcon.x = x + 21
	editIcon.y = y + height - 15
	editIcon.objectType = 'editIcon'
	group.addChild(editIcon)

	const editIconRect = new createjs.Graphics()
	editIconRect.beginFill('#ffffff')
	editIconRect.drawRect(x + 6, y + height - 15, 13, 13)
	const editIconRectShape = new createjs.Shape(editIconRect)
	editIconRectShape.alpha = 0.01
	group.addChild(editIconRectShape)
	editIconRectShape.cursor = 'pointer'
	editIconRectShape.objectType = 'editIconRectShape'

	editIconRectShape.on('pressup', function (evt) {
		// vueInstance.$store.commit('setMappingPopupState', true)
		// Jumnp to the import config page.  But there are different pages
		// depending on importerType
		// TODO: We'll want to save the mapping at this point otherwise unsaved
		// changes will be lost

		vueInstance.$router.push(`/automation/mapping/import/${exporter.importerType}Importer/${exporter._id}`)

		// const routeData = vueInstance.$router.resolve(`/automation/mapping/import/${exporter.importerType}Importer/${exporter._id}`)
		// window.open(routeData.href, '_blank')
	})

	const cfgIcon = new createjs.Text('\uf013', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	cfgIcon.textAlign = 'right'
	cfgIcon.x = x + width - 6
	cfgIcon.y = y + height - 15
	cfgIcon.objectType = 'cfgIcon'
	group.addChild(cfgIcon)

	const cfgIconRect = new createjs.Graphics()
	cfgIconRect.beginFill('#ffffff')
	cfgIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
	const cfgIconRectShape = new createjs.Shape(cfgIconRect)
	cfgIconRectShape.alpha = 0.01
	group.addChild(cfgIconRectShape)
	cfgIconRectShape.cursor = 'pointer'
	cfgIconRectShape.objectType = 'cfgIconRectShape'
	cfgIconRectShape.on('mousedown', function (evt) {
		dragMode = false
		rubberBandMode = false
	})
	cfgIconRectShape.on('pressup', function (evt) {
		vueInstance.$store.commit('setMappingPopupState', true)
	})

	console.log('exporter')
	console.log(exporter._id)

	const helpIcon = new createjs.Text('\uf059', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	helpIcon.textAlign = 'right'
	helpIcon.x = x + width - 24
	helpIcon.y = y + height - 15
	helpIcon.objectType = 'helpIcon'
	group.addChild(helpIcon)

	const helpIconRect = new createjs.Graphics()
	helpIconRect.beginFill('#ffffff')
	helpIconRect.drawRect(x + width - 37, y + height - 15, 13, 13)
	const helpIconRectShape = new createjs.Shape(helpIconRect)
	helpIconRectShape.alpha = 0.01
	group.addChild(helpIconRectShape)
	helpIconRectShape.cursor = 'help'
	helpIconRectShape.objectType = 'helpIconRectShape'
	helpIconRectShape.on('mousedown', function (evt) {
		dragMode = false
		rubberBandMode = false
		const url = '' // nodeDef.nodeConfig.htmlLink
		window.alert(url)
	})

	// Draw the input connectors
	let ypos = y + 45
	let p = 0
	for (let yp = 0; yp < exporter.configuration.length; yp++) {
		// Connectors
		const ipcnt = new createjs.Graphics()
		ipcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		ipcnt.setStrokeStyle(1)
		ipcnt.beginFill('#eeeeee')

		ipcnt.drawRect(x, ypos, connectorSize, connectorSize)
		const ipcntShape = new createjs.Shape(ipcnt)
		ipcntShape.cursor = 'crosshair'
		group.addChild(ipcntShape)

		ipcntShape.on('mouseover', function (evt) {
			inputConnectorTarget = ipcntShape
		})

		ipcntShape.on('mouseout', function (evt) {
			inputConnectorTarget = null
		})

		ipcntShape.on('pressmove', function (evt) {
			if (lineDrawingMode) {
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)
				const adjustedX = evt.stageX - dragDeltaX
				const adjustedY = evt.stageY - dragDeltaY
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
				// Create a new line.  A new line is created every mouse move
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				getClosestInput(evt)
				stage.addChild(drawingLineShape)
			}
		})

		ipcntShape.on('mousedown', function (evt) {
			dragMode = false
			rubberBandMode = false
			// Get a list of lines connected to this connector
			const links = []
			let op, ip
			let pickedLine
			for (let i = stage.numChildren - 1; i >= 0; i--) {
				const child = stage.getChildAt(i)
				if (child.objectType === 'lineConnector') {
					if (child.data.TargetParent) {
						if (child.data.TargetParent.id === evt.target.id) {
							pickedLine = child
							op = child.data.OriginParent
							ip = child.data.TargetParent
							links.push(child.data.Id)
						}
					}
				}
			}

			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY

			// Handle differently depending on number of
			// links connected to a connection point
			if (links.length === 0) {
				// console.log('0 Links')
			} else if (links.length === 1) {
				// Set up the drawing line
				selectedId = undefined
				unselectAllLineConnectors()
				drawSelectedRect()

				outputConnectorOrigin = op
				originalUnpluggedNode = ip
				lineOriginX = getObjectX(op)
				lineOriginY = getObjectY(op)
				lineDrawingMode = true

				// Draw a line to stop a visual glitch
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				// bringToFront(drawingLineShape)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				stage.addChild(drawingLineShape)

				// Remove old line(s)
				removeShadowLine(pickedLine.data.Id)
				stage.removeChild(pickedLine)
			} else {
				// more than one link.  Need to determine which link
				// to remove

				// Selected link has priority.
				// Is one of the links the selected link
				// console.log('More than 1 Links')
				if (_.includes(links, selectedId)) {
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === selectedId) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								// bringToFront(drawingLineShape)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				} else {
					// Just pick the first connector in the array
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === _.head(links)) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								// bringToFront(drawingLineShape)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				}
			}
		})

		ipcntShape.on('pressup', function (evt) {
			vueInstance.$store.commit('setSaveMapping', true)
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				// Find Closest input connector
				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					// Is there already a connector though?
					const hasInput = doesInputHaveConnector(closestInput.id)
					if (!hasInput) {
						inputConnectorTarget = getObjectWithId(closestInput.id)
						drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
					} else {
						stage.removeChild(magneticShape)
						lineDrawingMode = false
					}
				} else {
					drawLineConnector(outputConnectorOrigin, originalUnpluggedNode, false)
				}

				inputConnectorTarget = undefined
				addToStack()
			} else {
				lineDrawingMode = false
			}
		})

		ipcntShape.objectType = 'nodeInput'

		if (newNode) {
			ipcntShape.id = createGuid()
		} else {
			// TODO:
			for (let ii = 0; ii < mapping.inputs.length; ii++) {
				if (mapping.inputs[ii].Row === p && mapping.inputs[ii].ParentId === nodeId) {
					ipcntShape.id = mapping.inputs[ii].Id
				}
			}
		}

		ipcntShape.parentId = nodeId
		ipcntShape.row = yp

		const ipcntText = new createjs.Text(exporter.configuration[p++].strongType, '12px Arial', theme.bodyLabelColour)
		ipcntText.textAlign = 'left'
		ipcntText.x = x + connectorSize + 4
		ipcntText.y = ypos - 2
		ipcntText.objectType = 'label'
		group.addChild(ipcntText)
		ypos += 30
	}

	if (enableShapeCaching) {
		group.cache(x - 8, y - 8, width + 32, height + 32)
	}
	vueInstance.$store.commit('setSaveMapping', true)
}

export const createPaymentConnector = (x, y, name, connector, id, mapping) => {
	// This is not a brand new node, it is being recreated from say a saved workflow.
	// This is important because if it is being recreated then a lot of id's need to be reused and not recreated,
	let newNode = false
	let nodeId = ''
	if (id === undefined) {
		nodeId = createGuid()
		newNode = true
	} else {
		nodeId = id
		newNode = false
	}

	canvasIsDirty = true

	if (snapToGrid) {
		x = allignToGrid(x)
		y = allignToGrid(y)
	}
	// Dimensions of the node
	const width = 215
	const headerHeight = 24
	const footerHeight = 20
	if (connector === undefined) {
		connector = createPaymentConnectorProperties(name)
	}
	const largestHeight = Math.max(connector.nodeConfig.n1, connector.nodeConfig.n2)
	// Calculate the height of the node
	const height = (30 * (1 + largestHeight)) + 8 + footerHeight

	// Group
	const group = new createjs.Container()
	stage.addChild(group)
	group.objectType = 'connectorGroup'
	group.nodeId = nodeId
	group.data = connector

	vueInstance.$store.commit('setSelectedMappingNode', connector)

	// Main Rect
	const mainRect = new createjs.Graphics()
	mainRect.beginFill(theme.nodeColour)
	mainRect.drawRoundRect(x, y, width, height, nodeRadius)
	const mainRectShape = new createjs.Shape(mainRect)
	mainRectShape.objectType = 'nodeBody'
	mainRectShape.id = nodeId
	mainRectShape.alpha = nodeOpacity
	group.addChild(mainRectShape)

	mainRectShape.on('mouseover', function (evt) {
		createjs.Tween.get(evt.target).to({ alpha: 0.9 }, 150, createjs.Ease.exponentialOut)
	})
	mainRectShape.on('mouseout', function (evt) {
		createjs.Tween.get(evt.target).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
	})
	mainRectShape.on('pressup', function (evt) {
		vueInstance.$store.commit('setSaveMapping', true)
	})

	// Shadow
	if (showShadows) mainRectShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
	// Header
	const headerRect = new createjs.Graphics()
	headerRect.beginFill(connector.nodeConfig.headerBackColour)
	headerRect.drawRoundRectComplex(x, y, width, headerHeight, nodeRadius, nodeRadius, 0, 0)
	const headerRectShape = new createjs.Shape(headerRect)
	group.addChild(headerRectShape)
	headerRectShape.cursor = 'move'
	headerRectShape.objectType = 'nodeHeader'
	headerRectShape.id = nodeId

	headerRectShape.on('doubleclick', function (evt) {
		console.log('double click')
		vueInstance.$store.commit('setMappingPopupState', true)
	})

	group.headerBackColour = connector.nodeConfig.headerBackColour

	headerRectShape.on('click', evt => {
		if (evt.nativeEvent.ctrlKey) {
			// Is not already selected.  If so we want to remove it.
			let isSelected = false
			for (const g of selectedObjects) {
				if (g.nodeId === group.nodeId) isSelected = true
			}
			if (!isSelected) {
				addNodeToSelected(group, connector.nodeConfig.headerBackColour)
			} else {
				const i = selectedObjects.map(function (e) { return e.nodeId }).indexOf(group.nodeId)
				selectedObjects.splice(i, 1)
			}
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
			addNodeToSelected(group, connector.nodeConfig.headerBackColour)
		}
	})

	// Drag Bar
	headerRectShape.on('mousedown', evt => {
		if (evt.nativeEvent.ctrlKey) {
		} else {
			if (selectedObjects.length < 2) {
				selectedObjects = []
			}
		}

		dragMode = false
		rubberBandMode = false
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.offset = { x: group.x - adjustedX, y: group.y - adjustedY }
		bringToFront(group)

		// Add offsets
		itemOffsets = []
		for (const g of selectedObjects) {
			const o = getObjectWithId(g.nodeId)
			if (o && o.parent) {
				const p = o.parent
				itemOffsets.push(
					{
						id: g.nodeId,
						item: g,
						itemParent: p,
						offset: new createjs.Point(p.x - group.x, p.y - group.y)
					}
				)
			}
		}
	})

	headerRectShape.on('pressmove', evt => {
		// Is the node already selected?
		// A some nodes are selected and a user press moves
		// an unselected node then everything should be de-selected
		let isSelected = false
		for (const g of selectedObjects) {
			if (g.nodeId === group.nodeId) isSelected = true
		}
		if (!isSelected) selectedObjects = []

		// Dragging
		const adjustedX = evt.stageX - dragDeltaX
		const adjustedY = evt.stageY - dragDeltaY
		group.x = adjustedX + group.offset.x
		group.y = adjustedY + group.offset.y
		dragNodeMode = true
		addNodeToSelected(group, connector.nodeConfig.headerBackColour)

		// Now drag any other select nodes by the same amount
		// ***************************************************
		// NOTE:  Careful here - this took ages to figure out!
		// ***************************************************
		for (const g of selectedObjects) {
			if (g.nodeId !== group.nodeId) {
				const o = getObjectWithId(g.nodeId)
				if (o.parent) {
					const p = o.parent
					for (const offset of itemOffsets) {
						if (g.nodeId === offset.id) {
							p.x = group.x + offset.offset.x
							p.y = group.y + offset.offset.y
						}
					}
				}
			}
		}
	})

	headerRectShape.on('pressup', evt => {
		// Finished Dragging
		vueInstance.$store.commit('setSelectedMappingNode', connector)
		vueInstance.$store.commit('setIsMappingDirty', true)
		vueInstance.$store.commit('setSaveMapping', true)
		if (connectorsToBack) sendAllConnectorsToBack()
		dragNodeMode = false
		if (snapToGrid) {
			const actualX = evt.stageX + group.offset.x
			const xDif = actualX % gridSize
			let xDelta = 0
			if (xDif !== 0) {
				if (xDif > (gridSize / 2)) {
					xDelta = Math.abs(xDif - gridSize)
				} else {
					xDelta = xDif * -1
				}
			}

			// snap y - Find the y distance from the nearest snap point: yDelta
			const adjustedY = evt.stageY - dragDeltaY
			const actualY = adjustedY + group.offset.y
			const yDif = actualY % gridSize
			let yDelta = 0
			if (yDif !== 0) {
				if (yDif > (gridSize / 2)) {
					yDelta = Math.abs(yDif - gridSize)
				} else {
					yDelta = yDif * -1
				}
			}

			// Calculate the transform needed between the node's current position and the nearest snap point.
			const dx = actualX + xDelta
			const dy = actualY + yDelta

			createjs.Tween.get(group).to({ x: dx, y: dy }, 300, createjs.Ease.exponentialOut)
				.call(() => {
					repositionAllLineConnectors()
					addToStack()
				})
		} else {
			addToStack()
		}
	})

	headerRectShape.on('mouseover', evt => {
		console.log('mouseover')
		createjs.Tween.get(evt.target.parent).to({ alpha: 0.85 }, 150, createjs.Ease.exponentialOut)
	})
	headerRectShape.on('mouseout', evt => {
		createjs.Tween.get(evt.target.parent).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
	})

	// Header Text
	const text = new createjs.Text(connector.title, '12px Arial', theme.headerLabelColour)
	text.textAlign = 'center'
	text.x = x + (width / 2)
	text.y = y + 6
	text.objectType = 'headerText'
	group.addChild(text)

	// Footer
	const footerRect = new createjs.Graphics()
	footerRect.beginFill(theme.nodeFooterBackColour)
	footerRect.drawRoundRectComplex(x, y + height - footerHeight, width, headerHeight, 0, 0, nodeRadius, nodeRadius)
	const footerRectShape = new createjs.Shape(footerRect)
	footerRectShape.objectType = 'NodeFooter'
	group.addChild(footerRectShape)

	// ID Icon
	const idIcon = new createjs.Text(connector.nodeConfig.icon, '900 13px \'Font Awesome 5 Free\'', theme.headerLabelColour)
	idIcon.textAlign = 'left'
	idIcon.x = x + 6
	idIcon.y = y + 5
	idIcon.objectType = 'idIcon'
	group.addChild(idIcon)

	const cfgIcon = new createjs.Text('\uf013', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	cfgIcon.textAlign = 'right'
	cfgIcon.x = x + width - 6
	cfgIcon.y = y + height - 15
	cfgIcon.objectType = 'cfgIcon'
	group.addChild(cfgIcon)

	const cfgIconRect = new createjs.Graphics()
	cfgIconRect.beginFill('#ffffff')
	cfgIconRect.drawRect(x + width - 17, y + height - 15, 13, 13)
	const cfgIconRectShape = new createjs.Shape(cfgIconRect)
	cfgIconRectShape.alpha = 0.01
	group.addChild(cfgIconRectShape)
	cfgIconRectShape.cursor = 'pointer'
	cfgIconRectShape.objectType = 'cfgIconRectShape'
	cfgIconRectShape.on('mousedown', function (evt) {
		dragMode = false
		rubberBandMode = false
	})
	cfgIconRectShape.on('pressup', function (evt) {
		vueInstance.$store.commit('setSelectedMappingNode', connector)
		vueInstance.$store.commit('setMappingPopupState', true)
	})

	const helpIcon = new createjs.Text('\uf059', '900 13px \'Font Awesome 5 Free\'', theme.nodeFooterForeColour)
	helpIcon.textAlign = 'right'
	helpIcon.x = x + width - 24
	helpIcon.y = y + height - 15
	helpIcon.objectType = 'helpIcon'
	group.addChild(helpIcon)

	const helpIconRect = new createjs.Graphics()
	helpIconRect.beginFill('#ffffff')
	helpIconRect.drawRect(x + width - 37, y + height - 15, 13, 13)
	const helpIconRectShape = new createjs.Shape(helpIconRect)
	helpIconRectShape.alpha = 0.01
	group.addChild(helpIconRectShape)
	helpIconRectShape.cursor = 'help'
	helpIconRectShape.objectType = 'helpIconRectShape'
	helpIconRectShape.on('mousedown', evt => {
		console.log(connector)
		dragMode = false
		rubberBandMode = false
		const url = process.env.VUE_APP_DOCUMENTATION_ROOT_URL.concat(connector.nodeConfig.htmlLink)
		window.open(url, '_blank')
	})

	// Draw the input connectors
	// -------------------------
	let ypos = y + 45
	let p = 0
	for (let yp = 0; yp < connector.nodeConfig.n1; yp++) {
		// Connectors
		const ipcnt = new createjs.Graphics()
		ipcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		ipcnt.setStrokeStyle(1)
		ipcnt.beginFill('#eeeeee')

		ipcnt.drawRect(x, ypos, connectorSize, connectorSize)
		const ipcntShape = new createjs.Shape(ipcnt)
		ipcntShape.cursor = 'crosshair'
		group.addChild(ipcntShape)

		ipcntShape.on('mouseover', () => {
			inputConnectorTarget = ipcntShape
		})

		ipcntShape.on('mouseout', () => {
			inputConnectorTarget = null
		})

		ipcntShape.on('pressmove', evt => {
			if (lineDrawingMode) {
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)
				const adjustedX = evt.stageX - dragDeltaX
				const adjustedY = evt.stageY - dragDeltaY
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
				// Create a new line.  A new line is created every mouse move
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				getClosestInput(evt)
				stage.addChild(drawingLineShape)
			}
		})

		ipcntShape.on('mousedown', function (evt) {
			dragMode = false
			rubberBandMode = false
			// Get a list of lines connected to this connector
			const links = []
			let op, ip
			let pickedLine

			for (let i = stage.numChildren - 1; i >= 0; i--) {
				const child = stage.getChildAt(i)
				if (child.objectType === 'lineConnector') {
					if (child.data.TargetParent) {
						if (child.data.TargetParent.id === evt.target.id) {
							pickedLine = child
							op = child.data.OriginParent
							ip = child.data.TargetParent
							links.push(child.data.Id)
						}
					}
				}
			}

			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY

			// Handle differently depending on number of
			// links connected to a connection point
			if (links.length === 0) {
			} else if (links.length === 1) {
				// Set up the drawing line
				selectedId = undefined
				unselectAllLineConnectors()
				drawSelectedRect()

				outputConnectorOrigin = op
				originalUnpluggedNode = ip
				lineOriginX = getObjectX(op)
				lineOriginY = getObjectY(op)
				lineDrawingMode = true

				// Draw a line to stop a visual glitch
				const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
				drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
				drawingLineShape = new createjs.Shape(drawingLine)
				// bringToFront(drawingLineShape)
				if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
				stage.addChild(drawingLineShape)

				// Remove old line(s)
				removeShadowLine(pickedLine.data.Id)
				stage.removeChild(pickedLine)
			} else {
				// more than one link.  Need to determine which link
				// to remove

				// Selected link has priority.
				// Is one of the links the selected link
				// console.log('More than 1 Links')

				if (_.includes(links, selectedId)) {
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === selectedId) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				} else {
					// Just pick the first connector in the array
					for (let i = stage.numChildren - 1; i >= 0; i--) {
						const child = stage.getChildAt(i)
						if (child.objectType === 'lineConnector') {
							if (child.data.Id === _.head(links)) {
								pickedLine = child
								outputConnectorOrigin = child.data.OriginParent
								originalUnpluggedNode = child.data.TargetParent
								lineOriginX = getObjectX(outputConnectorOrigin)
								lineOriginY = getObjectY(outputConnectorOrigin)
								lineDrawingMode = true

								// Draw a line to stop a visual glitch
								const cpx = lineOriginX + (adjustedX - lineOriginX) / 2
								drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)
								drawingLineShape = new createjs.Shape(drawingLine)
								// bringToFront(drawingLineShape)
								if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
								stage.addChild(drawingLineShape)

								// Remove old line(s)
								removeShadowLine(pickedLine.data.Id)
								stage.removeChild(pickedLine)
							}
						}
					}
				}
			}
		})

		ipcntShape.on('pressup', evt => {
			console.log('pressup A here')
			vueInstance.$store.commit('setSaveMapping', true)
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				// Find Closest input connector
				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					inputConnectorTarget = getObjectWithId(closestInput.id)
					drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
				} else {
					drawLineConnector(outputConnectorOrigin, originalUnpluggedNode, false)
				}

				inputConnectorTarget = undefined
				addToStack()
			} else {
				lineDrawingMode = false
			}
		})

		ipcntShape.objectType = 'nodeInput'

		if (newNode) {
			ipcntShape.id = createGuid()
		} else {
			for (let ii = 0; ii < mapping.inputs.length; ii++) {
				if (mapping.inputs[ii].Row === p && mapping.inputs[ii].ParentId === nodeId) {
					ipcntShape.id = mapping.inputs[ii].Id
				}
			}
		}

		ipcntShape.parentId = nodeId
		ipcntShape.row = yp

		const ipcntText = new createjs.Text(connector.nodeConfig.lLabels[p++], '12px Arial', theme.bodyLabelColour)
		ipcntText.textAlign = 'left'
		ipcntText.x = x + connectorSize + 4
		ipcntText.y = ypos - 2
		ipcntText.objectType = 'label'
		group.addChild(ipcntText)
		ypos += 30
	}

	// Draw the output connectors
	p = 0
	ypos = y + 45
	for (let yp = 0; yp < connector.nodeConfig.n2; yp++) {
		console.log(yp)
		// Connectors
		const opcnt = new createjs.Graphics()
		opcnt.beginStroke(createjs.Graphics.getRGB(0, 0, 0))
		opcnt.setStrokeStyle(1)
		opcnt.beginFill('#eeeeee')
		opcnt.drawRect(x + width - connectorSize, ypos, connectorSize, connectorSize)
		const opcntShape = new createjs.Shape(opcnt)
		opcntShape.cursor = 'crosshair'
		group.addChild(opcntShape)

		opcntShape.on('mousedown', evt => {
			dragMode = false
			rubberBandMode = false
			// Store the co-ordinates of where the mouse down happened. This is the origin of the new line.
			outputConnectorOrigin = evt.target
			lineOriginX = getObjectX(outputConnectorOrigin)
			lineOriginY = getObjectY(outputConnectorOrigin)
			lineDrawingMode = true
		})

		// Metadata
		opcntShape.objectType = 'nodeOutput'

		if (newNode) {
			opcntShape.id = createGuid()
		} else {
			for (let ii = 0; ii < mapping.outputs.length; ii++) {
				if (mapping.outputs[ii].Row === p && mapping.outputs[ii].ParentId === nodeId) {
					opcntShape.id = mapping.outputs[ii].Id
				}
			}
		}

		opcntShape.parentId = nodeId
		opcntShape.row = yp
		opcntShape.on('pressmove', evt => {
			// Take dragging offset into account
			const adjustedX = evt.stageX - dragDeltaX
			const adjustedY = evt.stageY - dragDeltaY
			// Remove the old line
			stage.removeChild(drawingLineShape)
			stage.removeChild(magneticShape)
			// Bezier Control Point
			const cpx = lineOriginX + (adjustedX - lineOriginX) / 2

			// Create a new line.  A new line is created wvery mouse move
			drawingLine = new createjs.Graphics().setStrokeStyle(2).beginStroke(theme.connectorLineColour).moveTo(lineOriginX, lineOriginY).bezierCurveTo(cpx, lineOriginY, cpx, adjustedY, adjustedX - 1, adjustedY - 1)

			drawingLineShape = new createjs.Shape(drawingLine)
			if (showLineShadows) drawingLineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur)
			getClosestInput(evt)
			stage.addChild(drawingLineShape)
		})

		opcntShape.on('pressup', function (evt) {
			if (lineDrawingMode) {
				lineDrawingMode = false
				stage.removeChild(drawingLineShape)
				stage.removeChild(magneticShape)

				// Find Closest input connector
				const closestInput = getClosestInput(evt)
				if (closestInput !== undefined) {
					inputConnectorTarget = getObjectWithId(closestInput.id)
					drawLineConnector(outputConnectorOrigin, inputConnectorTarget, false)
				} else {
					lineDrawingMode = false
				}
				inputConnectorTarget = undefined
				addToStack()
				vueInstance.$store.commit('setSaveMapping', true)
			} else {
				lineDrawingMode = false
			}
		})
		// Label
		const opcntText = new createjs.Text(connector.nodeConfig.rLabels[p++], '12px Arial', theme.bodyLabelColour)
		opcntText.textAlign = 'right'
		opcntText.x = x + width - connectorSize - 4
		opcntText.y = ypos - 2
		opcnt.objectType = 'label'
		group.addChild(opcntText)
		ypos += 30
	}
	if (enableShapeCaching) {
		group.cache(x - 8, y - 8, width + 32, height + 32)
	}
	vueInstance.$store.commit('setSaveMapping', true)
}

const getClosestInput = (evt) => {
	const points = []
	const adjustedX = evt.stageX - dragDeltaX
	const adjustedY = evt.stageY - dragDeltaY
	const origin = { x: adjustedX, y: adjustedY }
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'nodeGroup' || child.objectType === 'connectorGroup' || child.objectType === 'exporterGroup') {
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeInput') {
					points.push({
						id: subChild.id,
						x: getObjectX(subChild),
						y: getObjectY(subChild)
					})
				}
			}
		}
	}

	points.forEach((item, index) => {
		const dist = pointDistance(item, origin)
		item.d = dist
	})

	let lowest = Number.POSITIVE_INFINITY
	let closestInput
	points.forEach((item, index) => {
		if (item.d < lowest && item.d < megneticRadius) {
			lowest = item.d
			closestInput = item
		}
	})

	if (closestInput) {
		highlightInput(closestInput.id)
		return closestInput
	}
}

const highlightInput = id => {
	stage.removeChild(magneticShape)
	const input = getObjectWithId(id)

	magneticInputRect = new createjs.Graphics()

	const hasInput = doesInputHaveConnector(id)
	if (hasInput) {
		magneticInputRect.beginFill('#ff0000')
	} else {
		magneticInputRect.beginFill('#44ff44')
	}

	magneticInputRect.drawRect(getObjectX(input) - connectorSize / 2, getObjectY(input) - connectorSize / 2, connectorSize, connectorSize)
	magneticShape = new createjs.Shape(magneticInputRect)
	magneticShape.objectType = 'magneticInput'
	magneticShape.alpha = 0.3
	stage.addChild(magneticShape)
}

// does the input already have a line connected to it?
const doesInputHaveConnector = id => {
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			if (id === child.data.TargetParent.id) return true
		}
	}
	return false
}

// Measures the distance between two points.
// Sort of pythag but without the root which is not nessessary
const pointDistance = (point, origin) => {
	return Math.pow(point.x - origin.x, 2) + Math.pow(point.y - origin.y, 2)
}

function tick (event) {
	// this set makes it so the stage only re-renders when an event handler indicates a change has happened.
	if (dragNodeMode) {
		// A node is being dragged - we need to update the line connectors
		repositionAllLineConnectors()
	}
	stage.update(event)
}

function renderGrid (gridType) {
	if (!showGrid) {
		return
	}

	// Render the background grid
	if (gridType === 1) {
		const majorGridSize = gridSize * 4
		for (let i = 0; i < canvas.width; i += gridSize) {
			const line = new createjs.Shape()
			stage.addChild(line)

			if (i % majorGridSize === 0) {
				line.graphics.setStrokeStyle(2).beginStroke(theme.majorGridColour)
			} else {
				line.graphics.setStrokeStyle(2).beginStroke(theme.minorGridColour)
			}

			line.graphics.moveTo(i, 0)
			line.graphics.lineTo(i, canvas.height)
			line.graphics.endStroke()
		}
		for (let i = 0; i < canvas.height; i += gridSize) {
			const line = new createjs.Shape()
			stage.addChild(line)

			if (i % majorGridSize === 0) {
				line.graphics.setStrokeStyle(2).beginStroke(theme.majorGridColour)
			} else {
				line.graphics.setStrokeStyle(2).beginStroke(theme.minorGridColour)
			}

			line.graphics.moveTo(0, i)
			line.graphics.lineTo(canvas.width, i)
			line.graphics.endStroke()
		}
	}
}

const doesLineConnectorExist = (origin, target) => {
	// Returns true if the mapping already contains a line between two points (origin and target)
	// Mainly used to prevent duplicate lines when automapping
	let exists = false
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			if ((origin.id === child.data.OriginParent.id) && (target.id === child.data.TargetParent.id)) {
				console.log('found: ' + child.data.OriginParent.id)
				exists = true
				break
			}
		}
	}
	return exists
}

function drawLineConnector (origin, target, selected) {
	canvasIsDirty = true
	vueInstance.$store.commit('setIsMappingDirty', true)

	// Origin co-ords
	const ox = getObjectX(origin) + connectorSize / 2
	const oy = getObjectY(origin)

	// Target co-ords
	const tx = getObjectX(target) - connectorSize / 2
	const ty = getObjectY(target)

	// BZ Control Point
	const cpx = ox + (tx - ox) / 2

	const id = createGuid()

	let colour = theme.connectorLineColour
	if (selected) {
		colour = theme.selectedConnectorLineColour
		selectedId = id
	}

	// Draw Line
	const lineShape = new createjs.Shape()
	const cmd = lineShape.graphics.beginStroke(colour).command
	lineShape.graphics.setStrokeStyle(lineThickness).moveTo(ox, oy).bezierCurveTo(cpx, oy, cpx, ty, tx, ty)
	lineShape.objectType = 'lineConnector'
	let shadowLineShape
	if (!lineDrawingMode) {
		stage.removeChild(magneticShape)
		shadowLineShape = new createjs.Shape()
		shadowLineShape.graphics.setStrokeStyle(connectorSize)
			.beginStroke('rgba(255, 0, 0, .01)')
			.moveTo(ox, oy)
			.bezierCurveTo(cpx, oy, cpx, ty, tx, ty)
		shadowLineShape.objectType = 'shadowlineConnector'

		shadowLineShape.data = {
			Id: id,
			OriginParent: origin,
			TargetParent: target
		}

		shadowLineShape.on('mousedown', evt => {
			dragMode = false
			rubberBandMode = false
			if (evt.nativeEvent.ctrlKey) {
				cmd.style = theme.selectedConnectorLineColour
				addLineToSelected(evt.target.data.Id, origin, target)
			} else {
				unSelectAll()
				unselectAllLineConnectors()
				cmd.style = theme.selectedConnectorLineColour
				addLineToSelected(evt.target.data.Id, origin, target)
			}
		})
		shadowLineShape.on('mouseover', evt => {
			createjs.Tween.get(lineShape).to({ alpha: 0.65 }, 150, createjs.Ease.exponentialOut)
		})
		shadowLineShape.on('mouseout', evt => {
			createjs.Tween.get(lineShape).to({ alpha: 1 }, 300, createjs.Ease.exponentialOut)
		})
	}

	if (showLineShadows) lineShape.shadow = new createjs.Shadow(theme.shadowColour, shadowDepth, shadowDepth, shadowBlur / 2)
	lineShape.data = {
		Id: id,
		OriginParent: origin,
		TargetParent: target
	}
	if (!lineDrawingMode) { }

	stage.addChild(shadowLineShape)
	stage.addChild(lineShape)
}

const sendAllConnectorsToBack = () => {
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			stage.setChildIndex(child, 0)
		}
		if (child.objectType === 'shadowlineConnector') {
			stage.setChildIndex(child, 0)
		}
	}
}

const getMapping = () => {
	const mappingObj = buildMappingModel()
	const json = JSON.stringify(mappingObj)

	// TODO: LZ String
	// const b64Compressed = LZString.compressToBase64(json)
	// return b64Compressed
	return json
}

// eslint-disable-next-line no-unused-vars
const getWorkflowJson = () => {
	const workflowObj = buildMappingModel()
	const json = JSON.stringify(workflowObj)
	return json
}

export function determineMappingType () {
	// Tries to determine the type of mapping - for the metadata.
	// i.e. Is it a DDMS payer import mapping or a bacs mappings, etc

}

export function buildMappingModel () {
	// Create the workflow model
	// TODO: Hard coded PaygateId
	const mappingObj = {
		diagramType: 'Mapping',
		paygateId: '',
		version: '3.3.0',
		generator: 'blueroom'
	}

	const nodes = []
	const connectors = []
	const inputs = []
	const outputs = []
	const importers = []
	const exporters = []
	const paymentConnectors = []

	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)

		if (child.objectType === 'nodeGroup') {
			const node = {
				Id: child.nodeId,
				X: getNodeX(child),
				Y: getNodeY(child),
				NodeCfg: child.nodecfg
			}
			nodes.push(node)

			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)

				if (subChild.objectType === 'nodeInput') {
					const input = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					inputs.push(input)
				}

				if (subChild.objectType === 'nodeOutput') {
					const output = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					outputs.push(output)
				}
			}
		}

		if (child.objectType === 'importerGroup') {
			const importer = {
				Id: child.nodeId,
				X: getNodeX(child),
				Y: getNodeY(child),
				importer: child.importer
			}
			importers.push(importer)

			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeOutput') {
					const output = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					outputs.push(output)
				}
			}
		}
		if (child.objectType === 'exporterGroup') {
			const exporter = {
				Id: child.nodeId,
				X: getNodeX(child),
				Y: getNodeY(child),
				exporter: child.exporter
			}
			exporters.push(exporter)
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeInput') {
					const input = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					inputs.push(input)
				}
			}
		}
		if (child.objectType === 'connectorGroup') {
			const paymentConnector = {
				Id: child.nodeId,
				X: getNodeX(child),
				Y: getNodeY(child),
				data: child.data
			}
			paymentConnectors.push(paymentConnector)

			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)

				if (subChild.objectType === 'nodeInput') {
					const input = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					inputs.push(input)
				}

				if (subChild.objectType === 'nodeOutput') {
					const output = {
						Id: subChild.id,
						ParentId: subChild.parentId,
						Row: subChild.row
					}
					outputs.push(output)
				}
			}
		}

		if (child.objectType === 'lineConnector') {
			try {
				const connector = {
					Id: child.data.Id,
					OriginId: child.data.OriginParent.id,
					TargetId: child.data.TargetParent.id
				}
				connectors.push(connector)
			} catch (ex) { }
		}
	}

	mappingObj.nodes = nodes
	mappingObj.connectors = connectors
	mappingObj.inputs = inputs
	mappingObj.outputs = outputs
	mappingObj.importers = importers
	mappingObj.exporters = exporters
	mappingObj.paymentConnectors = paymentConnectors
	return mappingObj
}

export const addExporter = exporter => {
	if (!exporter) {
		return
	}
	addToStack()

	// Clear undo stack
	stack.length = 0
	stackPointer = -1
	createExporter(40, 40, exporter)
	addToStack()
	canvasIsDirty = false
}

export const addImporter = importer => {
	if (!importer) {
		return
	}
	addToStack()
	stack.length = 0
	stackPointer = -1
	createImporter(40, 40, importer)
	addToStack()
	canvasIsDirty = false
}

export const loadMapping = mapping => {
	if (!mapping) {
		return
	}

	// console.log('mapping')
	// console.dir(mapping)

	// Clear Canvas
	addToStack()
	stack.length = 0
	stackPointer = -1

	retrieveMapping(mapping)
	if (connectorsToBack) sendAllConnectorsToBack()
	addToStack()
	canvasIsDirty = false
	vueInstance.$store.commit('setIsMappingDirty', false)
}

const retrieveMapping = json => {
	clearCanvas()

	// If json is not an object, turn it into one
	let wfObj
	if (typeof (json) !== 'object') {
		wfObj = JSON.parse(json)
	} else {
		wfObj = json
	}

	// Rebuild Nodes
	for (let i = wfObj.nodes.length - 1; i >= 0; i--) {
		console.log('createNode Id:', wfObj.nodes[i].Id)
		createNode(wfObj.nodes[i].X, wfObj.nodes[i].Y, wfObj.nodes[i].NodeCfg.name, wfObj.nodes[i].Id, wfObj, wfObj.nodes[i])
	}

	// Rebuild Importers
	for (let i = wfObj.importers.length - 1; i >= 0; i--) {
		createImporter(wfObj.importers[i].X, wfObj.importers[i].Y, wfObj.importers[i].importer, wfObj.importers[i].Id, wfObj)
	}

	// Rebuild Exporters
	if (wfObj.exporters) {
		for (let i = wfObj.exporters.length - 1; i >= 0; i--) {
			createExporter(wfObj.exporters[i].X, wfObj.exporters[i].Y, wfObj.exporters[i].exporter, wfObj.exporters[i].Id, wfObj)
		}
	}

	// Rebuild paymentConnectors
	try {
		for (let i = wfObj.paymentConnectors.length - 1; i >= 0; i--) {
			createPaymentConnector(wfObj.paymentConnectors[i].X, wfObj.paymentConnectors[i].Y, wfObj.paymentConnectors[i].data.name, wfObj.paymentConnectors[i].data, wfObj.paymentConnectors[i].Id, wfObj)
		}
	} catch (e) {
		console.log(e)
	}

	// Rebuild Line Connectors
	console.log('Length:', wfObj.connectors.length)
	for (let i = wfObj.connectors.length - 1; i >= 0; i--) {
		console.log('origin:', wfObj.connectors[i].OriginId)
		console.log('target:', wfObj.connectors[i].TargetId)
		const origin = getObjectWithId(wfObj.connectors[i].OriginId)
		const target = getObjectWithId(wfObj.connectors[i].TargetId)
		console.log('origin:', origin)
		console.log('target:', target)
		drawLineConnector(origin, target, false)
	}
}

export const removeSelected = () => {
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child) {
			if (child.objectType === 'nodeGroup') {
				if (selectedObjects.some(e => e.nodeId === child.nodeId)) {
					stage.removeChild(child)
					removeConnectedLine(child.nodeId)
					drawSelectionBoxes()
				}
			}
			if (child.objectType === 'lineConnector') {
				if (selectedObjects.some(e => e.nodeId === child.data.Id)) {
					removeShadowLine(child.data.Id)
					stage.removeChild(child)
				}
			}
			if (child.objectType === 'importerGroup' || child.objectType === 'exporterGroup' || child.objectType === 'connectorGroup') {
				console.log('child')
				console.log(child)
				if (selectedObjects.some(e => e.nodeId === child.nodeId)) {
					stage.removeChild(child)
					removeConnectedLine(child.nodeId)
					drawSelectionBoxes()
				}
			}
		}
	}
	selectedObjects = []
	addToStack()
	vueInstance.$store.commit('setIsMappingDirty', true)
}

const removeConnectedLine = parentId => {
	// Removes all line connectors that are connected to the node with the parentId
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		console.log('removeConnectedLine')
		if (child.objectType === 'lineConnector') {
			console.log(child.data.OriginParent.parentId)
			if (child.data.OriginParent.parentId === parentId) {
				console.log('Found Origin')
				removeShadowLine(child.data.Id)
				stage.removeChild(child)
			}
			console.log(child.data.TargetParent.parentId)
			if (child.data.TargetParent.parentId === parentId) {
				console.log('Found Target')
				removeShadowLine(child.data.Id)
				stage.removeChild(child)
			}
		}
	}
}

export const undo = () => {
	stackPointer--
	if (stackPointer < 0) {
		stackPointer++
		return
	}
	const newCanvas = stack[stackPointer]
	retrieveMapping(newCanvas)
	vueInstance.$store.commit('setIsMappingDirty', true)
}

export const redo = () => {
	stackPointer++
	if (stackPointer >= stack.length) {
		stackPointer--
		return
	}
	const newCanvas = stack[stackPointer]
	retrieveMapping(newCanvas)
	vueInstance.$store.commit('setIsMappingDirty', true)
}

const addToStack = () => {
	// Keep the undo stack from growing too large
	if (stack.length > maxStackLength) {
		console.warn('Max reached')
		stack.shift()
		stackPointer--
	}

	if (stack.length > 0) {
		if (stack.length !== stackPointer + 1) {
			const cutAmount = stack.length - stackPointer - 1
			stack = _.dropRight(stack, cutAmount)
		}
	}

	stackPointer++
	const b64Compressed = getMapping()
	stack[stackPointer] = b64Compressed
}

const showStackStats = text => {
	if (text === undefined) {
		text = ''
	} else {
		text += ': '
	}
}

const createGuid = () => {
	//  Guid Helper.  Creates a GUID in same format as c#
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		// TODO: Investigate
		/* eslint-disable one-var */
		const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
		return v.toString(16)
	})
}

const bringToFront = obj => {
	// move an object in the cavs to the front of the z-order
	stage.setChildIndex(obj, stage.numChildren - 1)
}

// const toDdMmYyyy = () => {
//   let now = new Date()
//   let dd = now.getDate()
//   let mm = now.getMonth() + 1
//   const yyyy = now.getFullYear()

//   if (dd < 10) {
//     dd = '0' + dd
//   }

//   if (mm < 10) {
//     mm = '0' + mm
//   }

//   const hours = now.getHours()
//   const mins = now.getMinutes()
//   const secs = now.getSeconds()

//   now = dd + '/' + mm + '/' + yyyy + ' ' + hours + ':' + mins + ':' + secs
//   return now
// }

const getNodeX = node => {
	// Helper - returns the x co-ordinate of a node.
	let x
	try {
		for (let i = node.numChildren - 1; i >= 0; i--) {
			const child = node.getChildAt(i)
			if (child.objectType === 'nodeBody') {
				x = getObjectX(child)
			}
		}
	} catch (ex) { }
	return x
}

const getNodeY = node => {
	// Helper - returns the y co-ordinate of a node.
	let y
	try {
		for (let i = node.numChildren - 1; i >= 0; i--) {
			const child = node.getChildAt(i)
			if (child.objectType === 'nodeBody') {
				y = getObjectY(child)
			}
		}
	} catch (ex) { }
	return y
}

const getObjectX = obj => {
	// Helper - returns the x co-ordinate of the centre point of a shape.
	let x
	try {
		x = (obj.graphics.command.x + obj.parent.x) + connectorSize / 2
	} catch (ex) { }
	return x
}

const getObjectW = obj => {
	// Helper - returns the width of the shape.
	let w
	try {
		w = (obj.graphics.command.w)
	} catch (ex) { }
	return w
}

const getObjectH = obj => {
	// Helper - returns the height of the shape.
	let h
	try {
		h = (obj.graphics.command.h)
	} catch (ex) { }
	return h
}

const getObjectY = obj => {
	// Helper - returns the y co-ordinate of the centre point of a shape.
	let y
	try {
		y = (obj.graphics.command.y + obj.parent.y) + connectorSize / 2
	} catch (ex) { }
	return y
}

const getObjectWithId = id => {
	// Helper - returns the object matching an ID
	// Use it for find the connection point matching an id
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'nodeGroup' || child.objectType === 'importerGroup' || child.objectType === 'exporterGroup' || child.objectType === 'connectorGroup') {
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeInput') {
					if (subChild.id === id) {
						return subChild
					}
				}
				if (subChild.objectType === 'nodeOutput') {
					if (subChild.id === id) {
						return subChild
					}
				}
				if (subChild.objectType === 'nodeBody') {
					if (subChild.id === id) {
						return subChild
					}
				}
			}
		}
	}
}

const unselectAllLineConnectors = () => {
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			child.graphics._stroke.style = theme.connectorLineColour
		}
	}
}

const removeShadowLine = id => {
	// Removes the shadow line with a particular id from the stage.
	// Used when dragging nodes.
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'shadowlineConnector') {
			if (child.data.Id === id) {
				stage.removeChild(child)
			}
		}
	}
}

const getObjectUnderMouse = useStored => {
	let shape
	if (useStored) {
		shape = stage.getObjectUnderPoint(storedMouseCoords.x, storedMouseCoords.y)
	} else {
		shape = stage.getObjectUnderPoint(stage.mouseX, stage.mouseY)
	}

	if (shape == null) return null

	if (shape.objectType) {
		if (shape.objectType === 'nodeGroup') {
			return { id: shape.nodeId, type: shape.objectType }
		} else if (shape.objectType === 'connectorGroup') {
			return { id: shape.data.Id, type: shape.objectType }
		} else if (shape.objectType === 'lineConnector') {
			return { id: shape.data.Id, type: shape.objectType }
		} else if (shape.objectType === 'shadowlineConnector') {
			return { id: shape.data.Id, type: shape.objectType }
		} else {
			// It might be part of a node - we need to find its parent
			const parent = shape.parent
			if (parent.objectType === 'nodeGroup') {
				return { id: parent.nodeId, type: parent.objectType }
			}
		}
	} else {
		return { id: null, type: null }
	}
}

const repositionAllLineConnectors = () => {
	// While dragging a node the line connectors also need to be repositioned
	let isSeleted = false
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			if (child.data.Id === selectedId) {
				isSeleted = true
			} else {
				isSeleted = false
			}
			// create a new line and remove the old one
			drawLineConnector(child.data.OriginParent, child.data.TargetParent, isSeleted)
			removeShadowLine(child.data.Id)
			stage.removeChild(child)
		}
	}
}

const allignToGrid = num => {
	const dif = num % gridSize
	let delta = 0
	if (dif !== 0) {
		if (dif > (gridSize / 2)) {
			delta = Math.abs(dif - gridSize)
		} else {
			delta = dif * -1
		}
	}
	return num + delta
}

const drawSelectedRect = (backColour) => {
	if (!backColour) {
		backColour = '#ffffff'
	}

	stage.removeChild(selectedShape)
	const ss = getObjectWithId(selectedId)
	selectedRect = new createjs.Graphics()
	const offset = connectorSize / 2
	selectedRect.setStrokeStyle(3)
	selectedRect.beginStroke(backColour)
	selectedRect.drawRoundRect(getObjectX(ss) - offset, getObjectY(ss) - offset, getObjectW(ss), getObjectH(ss) + offset, nodeRadius)
	selectedShape = new createjs.Shape(selectedRect)
	selectedShape.objectType = 'selectedNode'
	selectedShape.alpha = 0.9
	stage.addChild(selectedShape)
}

const unSelectAll = () => {
	unselectAllLineConnectors()
	selectedObjects = []
	drawSelectionBoxes()
	vueInstance.$store.commit('setSelectedMappingObjects', [])
}

const selectAll = () => {
	for (let i = 0; i < stage.numChildren; i++) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			addLineToSelected(child.data.Id, child.data.OriginParent, child.data.TargetParent)
			child.graphics._stroke.style = theme.selectedConnectorLineColour
		}

		if (child.objectType === 'nodeGroup') {
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeBody') {
					addNodeToSelected(child)
				}
			}
		}
		if (child.objectType === 'importerGroup' || child.objectType === 'exporterGroup') {
			console.log('importerGroup')
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild2 = child.getChildAt(j)
				if (subChild2.objectType === 'nodeBody') {
					addNodeToSelected(child)
				}
			}
		}
		if (child.objectType === 'connectorGroup') {
			console.log('connectorGroup')
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild2 = child.getChildAt(j)
				if (subChild2.objectType === 'nodeBody') {
					addNodeToSelected(child)
				}
			}
		}
	}
}

const selectEnclosedShapes = (x, y, dx, dy) => {
	// Returns an array of shapes that are completely enclosed within the args
	selectedObjects = []

	// Not very readable but these next two lines swap start and end points
	// if the user starts drawing the rubberband from say the bottom right corner.
	if (dx < x) dx = [x, x = dx][0]
	if (dy < y) dy = [y, y = dy][0]

	for (let i = 0; i < stage.numChildren; i++) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'lineConnector') {
			const lox = getObjectX(child.data.OriginParent)
			const loy = getObjectY(child.data.OriginParent)
			const ltx = getObjectX(child.data.TargetParent)
			const lty = getObjectY(child.data.TargetParent)

			// Is the line totally enclosed within the rubberband?
			// It's actually just basic geometry
			if (((lox > x) && (lox < dx)) && ((ltx > x) && (ltx < dx)) && ((loy > y) && (loy < dy)) && ((lty > y) && (lty < dy))) {
				child.graphics._stroke.style = theme.selectedConnectorLineColour
				addLineToSelected(child.data.Id, child.data.OriginParent, child.data.TargetParent)
			}
		}
		if (child.objectType === 'nodeGroup' || child.objectType === 'importerGroup' || child.objectType === 'connectorGroup') {
			for (let j = child.numChildren - 1; j >= 0; j--) {
				const subChild = child.getChildAt(j)
				if (subChild.objectType === 'nodeBody') {
					const scx = getObjectX(subChild)
					const scy = getObjectY(subChild)
					const scw = getObjectW(subChild)
					const sch = getObjectH(subChild)
					if ((scx >= x) && (scy >= y) && ((scx + scw) <= dx) && ((scy + sch) <= dy)) {
						addNodeToSelected(child)
					}
				}
			}
		}
	}
}

const addLineToSelected = (id, origin, target) => {
	console.log('origin')
	console.log(origin)
	console.log('id')
	console.log(id)
	const item = {
		type: 'line',
		nodeId: id,
		originId: origin.id,
		targetId: target.id
	}
	// Add the selected line to the array - only if it's not already in there
	if ((selectedObjects.filter(e => e.originId === origin.id).length === 0) && (selectedObjects.filter(e => e.targetId === target.id).length === 0)) {
		selectedObjects.push(item)
		vueInstance.$store.commit('setSelectedMappingObjects', selectedObjects)
	}
}

const addNodeToSelected = node => {
	const item = {
		type: node.objectType,
		nodeId: node.nodeId,
		headerBackColour: node.headerBackColour
	}
	// Add the selected item to the array - only if it's not already in there
	if (selectedObjects.filter(e => e.nodeId === node.nodeId).length === 0) {
		selectedObjects.push(item)
		vueInstance.$store.commit('setSelectedMappingObjects', selectedObjects)
	}
	drawSelectionBoxes()
}

const drawSelectionBoxes = () => {
	// Daws the coloured boxes around selected nodes
	const offset = connectorSize / 2
	for (let i = stage.numChildren - 1; i >= 0; i--) {
		const child = stage.getChildAt(i)
		if (child.objectType === 'selectedNode') {
			stage.removeChild(child)
		}
	}
	for (const box of selectedObjects) {
		const item = getObjectWithId(box.nodeId)
		const nx = getObjectX(item) - offset
		const ny = getObjectY(item) - offset
		const nh = getObjectH(item) + offset
		const nw = getObjectW(item)
		const selectedRect = new createjs.Graphics()
		selectedRect.setStrokeStyle(3)
		selectedRect.beginStroke(box.headerBackColour)
		selectedRect.drawRoundRect(nx, ny, nw, nh, nodeRadius)
		const selectedShape = new createjs.Shape(selectedRect)
		selectedShape.objectType = 'selectedNode'
		stage.addChild(selectedShape)
	}
}

export function copyClipboard () {
	console.log('------------------')
	console.log('copyClipboard')

	const so = vueInstance.$store.state.mapping.selectedMappingObjects
	console.log(so)
	console.log('------------------')
	const bundle = []
	for (const o of so) {
		console.log(o)

		for (let i = stage.numChildren - 1; i >= 0; i--) {
			const newId = createGuid()
			const child = stage.getChildAt(i)
			if (o.type === 'nodeGroup') {
				if (child.nodeId === o.nodeId) {
					console.log(child)
					const node = {
						Id: newId,
						X: getNodeX(child) + 32,
						Y: getNodeY(child) + 32,
						NodeCfg: child.nodecfg
					}
					node.NodeCfg.id = newId
					bundle.push(node)
				}
			}
			if (o.type === 'importerGroup' || o.type === 'exporterGroup') {
				if (child.nodeId === o.nodeId) {
					console.log(child)
					const node = {
						Id: newId,
						X: getNodeX(child) + 32,
						Y: getNodeY(child) + 32,
						Importer: child.importer
					}
					bundle.push(node)
				}
			}
			if (o.type === 'connectorGroup') {
				if (child.nodeId === o.nodeId) {
					console.log(child)
					const node = {
						Id: newId,
						X: getNodeX(child) + 32,
						Y: getNodeY(child) + 32,
						Connector: child.data
					}
					bundle.push(node)
				}
			}
		}
	}

	const clipboardContent = {
		generator: 'blueroom',
		version: '1.0.0',
		bundle
	}

	window.localStorage.setItem('pgmpcb', JSON.stringify(clipboardContent))
	return clipboardContent
}

export function cutClipboard () {
	copyClipboard()
	removeSelected()
}

export function pasteClipBoard () {
	addToStack()
	// Get the clipboard contents from localstorage
	try {
		const encodedClipboard = window.localStorage.getItem('pgmpcb')
		const cbData = JSON.parse(encodedClipboard)

		if (cbData) {
			if (cbData.bundle) {
				for (let i = cbData.bundle.length - 1; i >= 0; i--) {
					if (cbData.bundle[i].NodeCfg) {
						createNode(cbData.bundle[i].X, cbData.bundle[i].Y, cbData.bundle[i].NodeCfg.name, undefined, cbData, cbData.bundle[i])
					}
					if (cbData.bundle[i].Importer) {
						createImporter(cbData.bundle[i].X, cbData.bundle[i].Y, cbData.bundle[i].Importer, undefined)
					}
					if (cbData.bundle[i].Connector) {
						createPaymentConnector(cbData.bundle[i].X, cbData.bundle[i].Y, cbData.bundle[i].Connector.name, cbData.bundle[i].Connector, undefined, cbData.bundle[i])
					}
				}
			}
		}
	} catch (e) {
		console.log(`Paste Error: ${e.message}`)
	}
	addToStack()
	vueInstance.$store.commit('setIsMappingDirty', true)
}

const truncateText = (t, l = 32) => {
	if (t) {
		if (t.length > l) {
			t = t.substring(0, l)
			t = t.concat('...')
		}
		return t
	}
}
