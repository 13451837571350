<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<slot name="header">
						<div>
							<b-button variant="link" size="lg" class="pull-right close-btn" @click="cancelPopup">
								<span class="fa fa-times" aria-hidden="true"></span>
							</b-button>
							<h3>Folder Browser</h3>
						</div>
					</slot>
					<div class="row" v-if="errorMessage !==''">
						<div class="col-md-12 text-danger">{{errorMessage}}</div>
					</div>
					<div class="modal-body">
						<slot name="body">
							<div class="row" v-if="errorMessage===''">
								<div class="col-md-1">Path</div>
								<div class="col-md-11">{{selectedFolder}}</div>
							</div>
							<hr />
							<br />
							<div class="row">
								<div class="col-md-12 folderPanel">
									<VuePerfectScrollbar class="scroll-area" :settings="settings">
										<v-jstree
											:data="treeData"
											multiple
											allow-batch
											whole-row
											@item-click="itemClick"
											@item-dblclick="itemDblClick"
										></v-jstree>
									</VuePerfectScrollbar>
								</div>
							</div>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<b-button variant="danger" @click="closePopup">
								<span class="fa fa-times" aria-hidden="true"></span>
								Cancel
							</b-button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios'
import VJstree from 'vue-jstree'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
	name: 'Popup',
	components: {
		VJstree,
		VuePerfectScrollbar
	},
	props: ['groupId'],
	data () {
		return {
			initialSelected: '',
			treeData: [],
			selectedId: null,
			selectedFolder: '',
			selectedFile: '',
			myArray: [
			],
			settings: {},
			errorMessage: ''
		}
	},
	methods: {
		selectAndClose () {
			if (this.selectedItem) {
				if (this.selectedItem.isFolder) {
					this.$store.commit('setModalPopupString', this.initialSelected)
					this.$emit('close')
				} else {
					this.$store.commit('setModalPopupString', this.selectedFolder)
					this.$emit('close')
				}
			} else {
				this.$store.commit('setModalPopupString', this.initialSelected)
				this.$emit('close')
			}
		},
		cancelPopup () {
			this.$store.commit('setModalPopupString', this.initialSelected)
			this.$emit('close')
		},
		closePopup () {
      // https://paygate.atlassian.net/browse/PGE-9882
      if (this.selectedFolder === '//') {
        this.selectedFolder = '/'
      }
			this.$store.commit('setModalPopupString', this.selectedFolder)
			this.$emit('close')
		},
		selectItem (e) {
			this.selectedFolder = e.path
		},
		selectItemAndClose (e) {
			// Double click
			this.selectedFolder = e.path
			this.closePopup()
		},
		async loadFolderTree () {
			console.log('{this.groupId: ', this.groupId)
			try {
				let res
				if (this.groupId) {
					res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders/${this.groupId}`)
				} else {
					res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders`)
				}

				if (res.data.response) {
					if (Array.isArray(res.data.response)) {
						this.treeData = res.data.response
					} else {
						console.log('not an array')
					}
				}
			} catch (e) {
				console.log(e)
				if (e.response && e.response.status === 403) {
					this.errorMessage = 'Error: You are not authorised to view the customer folder area.'
				} else {
					this.errorMessage = 'Error: Cannot show the customer folder area.'
				}
			}
		},
		itemClick (node, item, e) {
			const rootPath = this.treeData[0].path
			let fullpath = `${rootPath}${node.model.path}`
			fullpath = fullpath.replace(/\\/g, '/')
			this.selectedFolder = fullpath // node.model.path
			this.closePopup()
		},
		itemDblClick (node) {
		}
	},
	created: function () {
		this.loadFolderTree()
		this.selectedFolder = this.$store.state.mapping.modalPopupString
		this.initialSelected = this.$store.state.mapping.modalPopupString
	}
}
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 50vw;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	overflow-y: auto;
	max-height: 90vh;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	margin: 20px 0;
}

.modal-default-button {
	float: right;
}

.modal-enter,
.modal-leave {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.treeFolder {
	color: #f1ce6b;
}

.list-group-item {
	border-style: none;
}
.close-btn {
	color: #999999;
}
.scroll-area {
	height: calc(100% - 0px);
	min-height: unset;
}
.folderPanel {
	height: 550px;
	min-height: 400px;
}
</style>
