import { render, staticRenderFns } from "./FolderBrowser.vue?vue&type=template&id=207f2b16&scoped=true"
import script from "./FolderBrowser.vue?vue&type=script&lang=js"
export * from "./FolderBrowser.vue?vue&type=script&lang=js"
import style0 from "./FolderBrowser.vue?vue&type=style&index=0&id=207f2b16&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207f2b16",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('207f2b16')) {
      api.createRecord('207f2b16', component.options)
    } else {
      api.reload('207f2b16', component.options)
    }
    module.hot.accept("./FolderBrowser.vue?vue&type=template&id=207f2b16&scoped=true", function () {
      api.rerender('207f2b16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Workflow/Shared/FolderBrowser.vue"
export default component.exports