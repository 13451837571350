<template>
	<form @submit.prevent="checkValidation() && saveServiceAccount()">
		<div class="idb-block">
			<div class="idb-block-title">
				<h2>
					{{status}} Service Account
					<help-icon :docPath="docPath" />
				</h2>
			</div>

			<div class="idb-block-content">
				<div class="form-group row" :class="{invalid: $v.serviceAccount.type.$error}">
					<label for="description" class="col-sm-2 text-right col-form-label required">Type</label>
					<div class="col-sm-3">
						<div class="form-control-plaintext" v-if="status == 'Edit'">
							<span style="margin-right:5px;">{{serviceAccount.type}}</span>
						</div>
						<div v-else>
							<b-form-select
								class="form-input"
								v-model="$v.serviceAccount.type.$model"
								:options="serviceAccountTypes"
							></b-form-select>
							<!-- Validation -->
							<validation-messages v-model="$v.serviceAccount.type" name="type"></validation-messages>
						</div>
					</div>
				</div>
				<div
					class="form-group row"
					:class="{invalid: $v.serviceAccount.username.$error}"
					v-if="serviceAccount.type === 'API' || serviceAccount.type === 'Workflow'"
				>
					<label
						for="description"
						class="col-sm-2 text-right col-form-label"
						:class="{required: status=='Create' }"
					>Username</label>
					<div class="col-sm-3">
						<input
							v-if="status == 'Create'"
							class="form-control"
							type="text"
							v-model="$v.serviceAccount.username.$model"
						/>
						<span v-else style="margin-right:5px;">{{serviceAccount.username}}</span>
						<!-- Validation -->
						<validation-messages v-model="$v.serviceAccount.username" name="username">
							<small
								class="form-text small"
								v-if="
                    $v.serviceAccount.username.whitespace != undefined &&
                      !$v.serviceAccount.username.whitespace"
							>The username cannot contain whitespaces, only alphanumeric characters</small>
						</validation-messages>
					</div>
				</div>
				<div class="form-group row">
					<label for="description" class="col-sm-2 text-right col-form-label">Description</label>
					<div class="col-sm-5">
						<textarea
							class="form-control"
							rows="3"
							v-model.trim="$v.serviceAccount.description.$model"
							v-if="serviceAccount.type !== 'Internal'"
						/>
						<div class="form-control-plaintext" v-else>
							<span style="margin-right:5px;">{{serviceAccount.description}}</span>
						</div>
					</div>
				</div>

				<div class="form-group row" v-if="status == 'Edit' && serviceAccount.type != 'Workflow'">
					<label for="description" class="col-sm-2 text-right col-form-label">API Key</label>
					<div class="col-sm-7">
						<div class="input-group">
							<input type="text" readonly class="form-control" v-model="serviceAccount.passwordHidden" />
							<div class="input-group-append">
								<button
									class="btn btn-outline-info ml-3"
									type="button"
									@click="revealPassword"
								>{{passwordRevealed?'Hide':'Reveal'}}</button>
								<button class="btn btn-outline-secondary ml-3" type="button" @click="copyPassword">Copy Key</button>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
			</div>

			<div class="idb-block-footer button-block">
				<button
					v-if="status == 'Create' || serviceAccount.type !== 'Internal'"
					:disabled="isLoading"
					class="btn btn-primary mr-3"
					type="submit"
				>Save</button>

				<button
					v-if=" (serviceAccount.type === 'API' || serviceAccount.type == 'Workflow') && status == 'Edit'"
					:disabled="isLoading"
					class="btn btn-outline-primary mr-3"
					type="button"
					@click="gotoApiEdit"
				>Edit Roles</button>

				<button
					v-if="status == 'Edit'"
					:disabled="isLoading"
					class="btn btn-danger pull-right"
					type="button"
					@click="deleteApiKey"
				>
					<i class="glyphicon ti-trash rpad"></i>Delete Service Account
				</button>
				<button
					v-if="status == 'Edit'  && serviceAccount.type != 'Workflow'"
					:disabled="isLoading"
					class="btn btn-outline-danger pull-right mr-3"
					type="button"
					@click="resetApiKey"
				>Reset API Key</button>
			</div>
		</div>
	</form>
</template>

<script>
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

import { required, requiredIf } from 'vuelidate/lib/validators'
import { regex } from '@/Assets/Validators'

import axios from 'axios'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import Licence from '@/Assets/Constants/licence'

export default {
	mixins: [DataLeaveMixin, loading],
	props: ['serviceAccountId', 'status'],
	computed: {
		docPath () {
			switch (this.status) {
				case 'Create':
					return '/administration/serviceaccounts/createserviceaccount/'
				case 'Edit':
					return '/administration/serviceaccounts/editserviceaccount/'
				default:
					return null
			}
		},
		...mapGetters(['selectedCustomer']),
		serviceAccountTypes () {

			var result = ['API']

			if (this.$store.getters.hasLicence(Licence.agent)) {
				result.unshift('Agent')
			}

			if (this.$store.getters.hasLicence(Licence.automation_workflow)) {
				result.push('Workflow')
			}

			return result
		}
	},
	watch: {
		selectedCustomer () { this.$router.push({ name: 'ServiceAccounts' }) }
	},
	data () {
		return {
			serviceAccount: {
				serviceAccountId: null,
				userId: null,
				type: null,
				description: null,
				password: null,
				passwordHidden: null,
				username: null
			},
			passwordRevealed: false
		}
	},
	async mounted () {
		if (this.status === 'Edit') {
			await this.loadServiceAccount()
		}
	},
	methods: {
		async saveServiceAccount () {
			if (this.serviceAccount.type === 'Agent' && !this.$store.getters.hasLicence(Licence.agent)) {
				this.$toastr.e('Not licenced to use Agent', 'Failed')
			} else {
				try {
					if (this.status === 'Edit') {
						await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount`, {
							description: this.serviceAccount.description,
							serviceAccountId: this.serviceAccount.serviceAccountId
						},
							{ showload: true, showerror: true, errormessage: 'Service Account failed to save' })
						this.$toastr.s('Service account has been updated!', 'Updated')
						await this.loadServiceAccount()
						this.$v.$reset()
					} else {
						await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount`, {
							type: this.serviceAccount.type,
							description: this.serviceAccount.description,
							username: this.serviceAccount.username
						},
							{ showload: true, showerror: true, errormessage: 'Service Account failed to create' })
						this.$v.$reset()
						this.$toastr.s(`New service account of type ${this.serviceAccount.type} created!`, 'Created')
						this.$router.push({ name: 'ServiceAccounts' })
					}
				} catch { }
			}
		},
		async loadServiceAccount () {
			try {
				const serviceAccountResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount/${this.serviceAccountId}`,
					{
						params: { includePassword: true },
						showload: true,
						showerror: true,
						errormessage: 'Service Account failed to load'
					})
				this.serviceAccount = serviceAccountResponse.data
				this.serviceAccount.passwordHidden = this.serviceAccount.password.replace(/[\w\d]/g, '*')
			} catch { }
		},
		revealPassword () {
			if (this.passwordRevealed) {
				this.serviceAccount.passwordHidden = this.serviceAccount.password.replace(/[\w\d]/g, '*')
			} else {
				this.serviceAccount.passwordHidden = this.serviceAccount.password
			}
			this.passwordRevealed = !this.passwordRevealed
		},
		copyPassword () {
			this.copyTextToClipboard(this.serviceAccount.password)
		},
		fallbackCopyTextToClipboard (text) {
			var textArea = document.createElement("textarea")
			textArea.value = text

			// Avoid scrolling to bottom
			textArea.style.top = "0"
			textArea.style.left = "0"
			textArea.style.position = "fixed"

			document.body.appendChild(textArea)
			textArea.focus()
			textArea.select()

			try {
				var successful = document.execCommand('copy')
				this.$toastr.s('The API Key has been copied', 'Key Copied')
			} catch (err) {
				this.$toastr.e('Unable to Copy API Key', 'API Key Failed to Copy')
			}

			document.body.removeChild(textArea)
		},
		copyTextToClipboard (text) {
			if (!navigator.clipboard) {
				this.fallbackCopyTextToClipboard(text)
				return
			}
			let toastr = this.$toastr
			navigator.clipboard.writeText(text).then(function () {
				toastr.s('The API Key has been copied', 'Key Copied')
			}, function (err) {
				toastr.e('Unable to Copy API Key', 'API Key Failed to Copy')
			})
		},
		async resetApiKey () {
			try {
				var swalResult = await swal.fire({
					title: 'Reset API Key?',
					html: `<div>This will permanently change this service account's API key!</div> 
          <div>This key may be being used by <strong>LIVE</strong> customers and this will affect them</div>
          <div class="mb-1"><strong>Are you REALLY sure you want to do this?</strong></div>
          <div>Please type <code>Reset API Key</code> to confirm </div>`,
					icon: 'warning',
					input: 'text',
					inputValidator: result => new Promise((resolve, reject) => {
						result === 'Reset API Key' ? resolve() : resolve('You need to write "Reset API Key" to confirm')
					}),
					showCancelButton: true,
					confirmButtonColor: colours.danger,
					confirmButtonText: 'Reset API Key',
					cancelButtonText: 'Cancel'
				})
				if (!swalResult.isConfirmed) {
					return
				}
				try {
					const resetApiKeyResponse = await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount/ResetApiKey/${this.serviceAccountId}`, null,
						{ showload: true, showerror: true, errormessage: 'Service Account failed to reset api key' })
					this.serviceAccount.password = resetApiKeyResponse.data.password
					this.serviceAccount.passwordHidden = this.serviceAccount.password.replace(/[\w\d]/g, '*')
					this.passwordRevealed = false
				} catch { }
			} catch {
				this.$toastr.w('Resetting API key has been canceled', 'Canceled')
			}
		},
		async deleteApiKey () {
			try {
				var swalResult = await swal.fire({
					title: 'Delete Service Account?',
					html: `<div>This will permanently delete this service account!</div> 
          <div>This service account may be being used by <strong>LIVE</strong> customers and this will affect them</div>
          <div class="mb-1"><strong>Are you REALLY sure you want to do this?</strong></div>
          <div>Please type <code>Delete Service Account</code> to confirm </div>`,
					icon: 'warning',
					input: 'text',
					inputValidator: result => new Promise((resolve, reject) => {
						result === 'Delete Service Account' ? resolve() : resolve('You need to write "Delete Service Account" to confirm')
					}),
					showCancelButton: true,
					confirmButtonColor: colours.danger,
					confirmButtonText: 'Delete Service Account',
					cancelButtonText: 'Cancel'
				})

				if (!swalResult.isConfirmed) {
					return
				}
				try {
					await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}serviceaccount/`,
						{
							params: { serviceAccountId: this.serviceAccountId },
							showload: true,
							showerror: true,
							errormessage: 'Service Account failed to delete'
						}
					)
					this.$v.$reset()
					this.$toastr.s('Serivce Account Deleted!', 'Deleted')
					this.$router.push({ name: 'ServiceAccounts' })
				} catch { }
			} catch {
				this.$toastr.w('Deleting service account has been canceled', 'Canceled')
			}
		},
		gotoApiEdit () {
			this.$router.push({ name: 'ServiceAccountUserEdit', params: { userId: this.serviceAccount.userId } })
		}
	},
	validations: {
		serviceAccount: {
			type: { required },
			username: {
				required: requiredIf((v) => v.type === 'API' && v.user === null),
				whitespace: regex(/^\S+$/)
			},
			description: {}
		}
	}
}
</script>
<style lang="scss" scoped>
</style>
