<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <viewBanklineSubmission></viewBanklineSubmission>
          <div v-show="actionLoaded" class="idb-block-footer">
            <approveBanklineSubmission v-if="actionSubClass === 'Approve'">
            </approveBanklineSubmission>
            <sendBanklineSubmission v-else-if="actionSubClass === 'Send'">
            </sendBanklineSubmission>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import ViewBanklineSubmission from '@/Views/Bankline/Common/ViewBanklineSubmission.vue'
  import ApproveBanklineSubmission from '@/Views/Bankline/BanklineActions/ApproveBanklineSubmission.vue'
  import SendBanklineSubmission from '@/Views/Bankline/BanklineActions/SendBanklineSubmission.vue'
  export default {
    components: {
      viewBanklineSubmission: ViewBanklineSubmission,
      approveBanklineSubmission: ApproveBanklineSubmission,
      sendBanklineSubmission: SendBanklineSubmission
    },
  
    props: {
      actionId: {},
      actionedObjectId: {}
    },
  
    data () {
      return {
        canContinue: false,
        userId: '',
        action: {},
        unlockSubmissionOnLeave: true
      }
    },
  
    methods: {
      async getAction () {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + this.actionId
        var response = await axios.get(url, { action: true })
        this.action = response.data
      }
    },
  
    async created () {
      this.userId = this.$store.getters.getClaim('sub').value
      this.$store.dispatch('setParentBanklineDataLoaded', false)
      this.$store.dispatch('setActionId', this.actionId)
      this.$store.dispatch('setSubmissionId', this.actionedObjectId)
      await this.getAction()
    },
  
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.$store.dispatch('setActionLoadedFromPath', from.path)
      })
      next()
    },
  
    async beforeRouteLeave (to, from, next) {
      // if (this.unlockSubmissionOnLeave) {
      //   await this.unlockSubmission()
      // }
  
      if (!to.path.includes('viewBanklineImportedPayments') && !to.path.includes('validationResult')) {
        next()
      } else {
        this.$router.push('/')
      }
    },
  
    computed: {
      actionLoaded () { return this.$store.getters.parentBanklineDataLoaded },
      actionSubClass () {
        return this.action.actionSubClass
      }
    }
  }
  </script>
  