var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v(" Anti-Fraud Lists "),_c('help-icon',{attrs:{"docPath":"/administration/antifraudlists/"}}),_c('favourite-icon')],1)]),_c('div',{staticClass:"idb-block-content"},[_c('vue-good-table',{attrs:{"paginationOptions":{
      enabled: true,
      perPage: 10,
      jumpFirstOrLast: true
    },"rows":_vm.rows,"columns":_vm.columns,"lineNumbers":true,"searchOptions":{
      enabled: true
    },"styleClass":"vgt-table striped bordered"},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'description')?_c('span',[_c('span',{staticStyle:{"overflow-wrap":"break-word","word-wrap":"break-word","word-break":"break-all"}},[_vm._v(_vm._s(props.row.description))])]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:('Create a new anti-fraud list'),expression:"'Create a new anti-fraud list'",modifiers:{"hover":true,"top":true,"d500":true}}],staticClass:"btn btn-link",on:{"click":function($event){$event.preventDefault();return _vm.createNewAntiFraudList.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-plus dimmedIcon pointer"}),_c('span',{staticClass:"sr-only"},[_vm._v("Create a new anti-fraud list")])])])])],1),_c('div',{staticClass:"idb-block-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.antiFraudLicenceSettings.enabled,"type":"button"},on:{"click":_vm.createNewAntiFraudList}},[_c('i',{staticClass:"fa fa-plus rpad"}),_vm._v("Create Anti-Fraud List ")])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }