const ImportingBanklinePayments = () => import(/* webpackChunkName: "bankline" */ '@/Views/Bankline/BanklinePayments/ImportingBanklinePayments.vue')
const ViewImportedBanklinePayments = () => import(/* webpackChunkName: "bankline" */ '@/Views/Bankline/BanklinePayments/ViewImportedBanklinePayments.vue')

const ValidationResult = () => import(/* webpackChunkName: "bankline" */ '@/Views/Bankline/BanklinePayments/ValidationResult.vue')
const ViewValidationMessages = () => import(/* webpackChunkName: "bankline" */ '@/Views/Bankline/BanklinePayments/ViewValidationMessages')

const BanklineActionWrapper = () => import(/* webpackChunkName: "bankline" */ '@/Views/Bankline/BanklineActions/BanklineActionWrapper.vue')

export default [
  {
		path: '/bankline/banklinepayments/importingBanklinePayments',
		component: ImportingBanklinePayments,
		meta: {
			title: 'Importing Bankline Payments'
		}
	},
	{
		path: '/bankline/banklinepayments/viewImportedBanklinePayments',
		component: ViewImportedBanklinePayments,
		meta: {
			title: 'Imported Bankline Payments'
		}
	},
	{
		path: '/bankline/banklinepayments/validationResult',
		component: ValidationResult,
		meta: {
			title: 'Validation Result'
		}
	},
	{
		path: '/bankline/banklinepayments/viewValidationMessages',
		component: ViewValidationMessages,
		meta: {
			title: 'View Validation Messages'
		}
	},
	{
		path: '/bankline/banklineActions/banklineactionWrapper',
		component: BanklineActionWrapper,
		meta: {
			title: 'Bankline Action'
		},
		props: route => ({ ...route.params, ...route.query })
	}	
]