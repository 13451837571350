// Validation
import licence from '@/Assets/Constants/licence'
import roles from "@/Assets/Constants/roles";

const BankAccountValidation = () => import(/* webpackChunkName: "validate-bankaccount" */ '@/Components/Validate/BankAccount/BankAccountValidation')
const IbanValidation = () => import(/* webpackChunkName: "validate-iban" */ '@/Components/Validate/IBAN/IbanValidation')
const ConfirmationOfPayer = () => import(/* webpackChunkName: "validate-cop" */ '@/Components/Validate/CoP/CoPValidation')
const Email = () => import(/* webpackChunkName: "validate-cop" */ '@/Components/Validate/Email/EmailValidation')
const Phone = () => import(/* webpackChunkName: "validate-cop" */ '@/Components/Validate/Phone/PhoneValidation')
const Address = () => import(/* webpackChunkName: "validate-cop" */ '@/Components/Validate/Address/AddressValidation')
const Person = () => import(/* webpackChunkName: "validate-cop" */ '@/Components/Validate/Address/PersonValidation')
const BulkValidation = () => import(/* webpackChunkName: "validate-bulk */ '@/Components/Validate/Bulk/BulkValidation')
const ValidationSession = () => import(/* webpackChunkName: "validate-bulk" */ '@/Components/Validate/Bulk/ValidationSession')

export default [
  {
    path: '/validate/validation/BankAccount',
    component: BankAccountValidation,
    meta: {
      licence: licence.validate_modcheck,
      title: 'UK Bank Account Validation'
    }
  },
  {
    path: '/validate/validation/IBAN',
    component: IbanValidation,
    meta: {
      licence: licence.validate_iban,
      title: 'International Bank Account Number Validation'
    }
  },
  {
    path: '/validate/valdation/cop',
    component: ConfirmationOfPayer,
    meta: {
      licence: [licence.validate_cop],
      auth: ['ValidateCoPBulk','ValidateCoP', roles.SystemUser],
      title: 'Confirmation of Payer Validation'
    }
  }
  /*,
  {
    path: '/validate/valdation/email',
    component: Email,
    meta: {
      tite: 'Email Validation'
    }
  }
  ,
  {
    path: '/validate/valdation/phone',
    component: Phone,
    meta: {
      tite: 'Phone Validation'
    }
  }
  ,
  {
    path: '/validate/valdation/address',
    component: Address,
    meta: {
      tite: 'Address Validation'
    }
  }
  ,
  {
    path: '/validate/valdation/person',
    component: Person,
    meta: {
      tite: 'Person Validation'
    }
  }*/
  ,
  {
    name: 'bulkValidation',
    path: '/validate/valdation/bulk',
    component: BulkValidation,
    meta: {
      licence: [licence.validate_cop],
      auth: ['ValidateCoPBulk', roles.SystemUser],
      tite: 'Bulk Validation'
    }
  },
  {
    name: 'validationSession',
    path: '/validate/validation/bulk/:sessionId',
    component: ValidationSession,
    props: true,
    meta: {
      title: 'Bulk Validation - Verification',
      auth: ['ValidateCoPBulk', roles.SystemUser],
    }
  }
]
