<template>
  <div id="banklinePayments">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          ref="payments"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-column-filter="onColumnFilter"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRecords"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false,
            jumpFirstOrLast: true,
            setCurrentPage: serverParams.page
          }"
          styleClass="vgt-table striped bordered"
        >
          <div slot="table-actions">
            <b-button
              @click.prevent="createPaymentReport"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Shows a summary of the payments'"
            >
              <i class="fa fa-file-pdf pointer dimmedIcon"></i><span class="sr-only">Create Payment Report</span>
            </b-button>
            <b-button
              @click.prevent="createPaymentCsvFile"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export payment details to CSV'"
            >
              <i class="fa fa-file-excel pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
            </b-button>
            <b-button
              @click.prevent="refreshGrid"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Reset payment table options and filters'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import bacsMixin from '@/Lib/BacsMixin.js'
  import moment from 'moment'
  
  export default {
    mixins: [
      bacsMixin
    ],
    data () {
      return {
        submissionId: '',
        fileNumber: 1,
        columns: [
          {
            label: 'Id',
            field: 'id',
            hidden: true
          },
          {
            label: 'Third Party Name',
            field: 'thirdPartyAccountName',
            filterOptions: {
              enabled: true,
              trigger: 'enter'
            }
          },
          {
            label: 'Sort Code',
            field: 'thirdPartySortCode',
            filterOptions: {
              enabled: true,
              trigger: 'enter'
            }
          },
          {
            label: 'Account Number',
            field: 'thirdPartyAccountNumber',
            filterOptions: {
              enabled: true,
              trigger: 'enter'
            }
          },
          {
            label: 'User Reference',
            field: 'userReference',
            filterOptions: {
              enabled: true,
              trigger: 'enter'
            }
          },
          {
            label: 'Amount (£)',
            field: 'amount',
            type: 'number',
            formatFn: this.formatAmount,
            thClass: 'text-right',
            tdClass: 'text-right',
            filterOptions: {
              enabled: true,
              trigger: 'enter'
            }
          }
        ],
        rows: [],
        totalRecords: 0,
        totalSubmissionPayments: 0,
        banklineSubmission: {},
        serverParams: {
          submissionId: '',
          showPresubValMessages: this.showPresubValMessages,
          // a map of column filters example: {name: 'john', age: '20'}
          columnFilters: {
          },
          sort: {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          },

          page: 1, // what page I want to show
          perPage: 10 // how many items I'm showing per page
        }
      }
    },
  
    methods: {
      formatDate: function (dateVal) {
        return moment(dateVal).format('DD/MM/YYYY')
      },
  
      // Payments Table Stuff
  
      updateParams (newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
  
      onPageChange (params) {
        this.updateParams({ page: params.currentPage })
        this.loadItems(false)
      },
  
      onPerPageChange (params) {
        this.rows.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems(true)
      },
  
      onSortChange (params) {
        this.updateParams({
          sort: {
            field: params[0].field,
            type: params[0].type
          }
        })
        this.loadItems(true)
      },
  
      onColumnFilter (params) {
        this.appliedFilter = true
        this.updateParams(params)
        this.loadItems(true)
      },
  
      async refreshGrid () {
        this.serverParams.columnFilters = {}
        this.serverParams.sort.field = ''
        this.serverParams.sort.type = ''
        this.serverParams.page = 1
        this.serverParams.perPage = 10
        this.updateParams(this.serverParams)
        this.$refs.payments.reset()
        this.$refs.payments.$refs.paginationBottom.perPage = this.serverParams.perPage
        this.$refs.payments.$refs.paginationBottom.handlePerPage()
        await this.loadItems(true)
      },
  
      async loadItems (resetStart) {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          page: this.serverParams.page,
          perPage: this.serverParams.perPage,
          showValidationMessages: false,
          sortColumn: this.serverParams.sort.field,
          sortDirection: this.serverParams.sort.type,
          resetStart: resetStart,
          columnFilters: this.serverParams.columnFilters,
          editAction: false,
          paymentsWithValidationIssues: false
        })

        var response = await axios.post(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/payment/get/`, json, { showload: true })
        this.rows = response.data.payments
        if (this.rows.length > 0) {
          this.$store.dispatch('setBanklinePayments', response.data)
          this.$store.dispatch('setServerParams', this.serverParams)

          if (response.data.filtersSet === 0) {
            this.totalRecords = this.banklineSubmission.totalNumberOfCredits
          } else {
            this.totalRecords = response.data.filteredRecordCount
          }
        }

        this.totalSubmissionPayments = this.totalRecords
      },

      formatAmount (value) {
        return this.formatValue(value)
      },

      // Other
      async createPaymentReport () {
        try {
          var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentReport?submissionId=' + this.submissionId
          var response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
            showload: true
          })
          this.saveFile(response)
        } catch (ex) {
          console.log(JSON.stringify(ex))
        }
      },
  
      async createPaymentCsvFile () {
        try {
          var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPaymentCsvFile?submissionId=' + this.submissionId
          var response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
            showload: true
          })
          this.saveFile(response)
        } catch (ex) {
          console.log(JSON.stringify(ex))
        }
      }
    },
  
    async mounted () {
      this.submissionId = this.$store.getters.submissionId
      this.banklineSubmission = this.$store.getters.banklineSubmission
  
      var viewBanklineSubmissionData = this.$store.getters.viewBanklineSubmissionData
      if (viewBanklineSubmissionData.paymentsLoaded === false) {
        await this.loadItems(true)
        viewBanklineSubmissionData.paymentsLoaded = true
        this.$store.dispatch('setViewBanklineSubmissionData', viewBanklineSubmissionData)
      } else {
        this.serverParams = this.$store.getters.serverParams
        var response = this.$store.getters.banklinePayments
        this.rows = response.payments
      }
    }
  }
  </script>
  