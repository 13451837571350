// The single source of truth for workflow node configuration
export function createWorkflowNodeProperties (name) {
  switch (name) {
    case 'csvConnector':
      return {
        id: '',
        name: name,
        title: 'CSV Mapping',
        type: 'csvMapping',
        area: 'mapping',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 12,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/csvmapping',
          icon: '\uf017'
        }
      }
    case 'mapping':
      return {
        id: '',
        name: name,
        title: 'Mapping',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'mappingId', title: 'Mapping', value: '', element: 's1', isRequired: true },
          // s2: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's2', isRequired: true },
          s3: { name: 'overrideFilename', title: 'Override Filename', value: '', element: 's3', isRequired: false },
          s4: { name: 'overridePath', title: 'Override Folder', value: '', element: 's4', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0f380f',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/other/mapping/',
          icon: '\uf0b2'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Mapping'
            },
            {
              fieldType: 'TextInput',
              label: 'Mapping',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'Group',
            //   name: 's2',
            //   isRequired: true
            // },
            {
              fieldType: 'TextInput',
              label: 'Override Filename',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              label: 'Override Folder',
              name: 's4',
              maxLength: 255,
              isRequired: false
            }
          ]
        }
      }
    case 'workflow':
      return {
        id: '',
        name: name,
        title: 'Workflow',
        type: 'action',
        area: 'Automation',
        props: {
          s1: { name: 'workflowId', title: 'Workflow', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#820043',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/other/workflow/',
          icon: '\uf78c'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Workflow'
            },
            {
              fieldType: 'TextInput',
              label: 'Workflow',
              name: 's1',
              maxLength: 255,
              isRequired: true
            }
          ]
        }
      }
    case 'exporter':
      return {
        id: '',
        name: name,
        title: 'Exporter',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'exporterId', title: 'Exporter', value: '', element: 's1', isRequired: true },
          s2: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#62204C',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/other/exporter/',
          icon: '\uf019'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Exporter'
            },
            {
              fieldType: 'TextInput',
              label: 'Exporter',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'GroupList',
              label: 'Group',
              name: 's2',
              isRequired: true
            }
          ]
        }
      }
    case 'bacsBureauJobProcessor':
      return {
        id: '',
        name: name,
        title: 'Bureau Job',
        type: 'action',
        area: 'BACS Bureau',
        props: {
          // s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true },
          s1: { name: 'jobId', title: 'Job ID', value: '', element: 's1', isRequired: true },
          // s2: { name: 'submissionref', title: 'Submission Reference', value: '', element: 's2', isRequired: true },
          // s3: { name: 'contraNarrative', title: 'Contra Narrative', value: '', element: 's3', isRequired: false },
          s4: { name: 'engineType', title: 'Engine Type', value: 'BACS', element: 's4', isRequired: true },
          s5: { name: 'exceptionHandlingBehaviour', title: 'Mapping', value: 'All payment instructions must validate before the entire submission can continue', element: 's5', isRequired: true },
          s6: { name: 'exporterSelect', title: 'Selected Exporter', value: '', element: 's6', isRequired: false },
          s7: { name: 'exporterOveridePath', title: 'Exporter Override Path', value: '', element: 's7', isRequired: false },
          s8: { name: 'groupId', title: 'Group Id', value: '', element: 's8', isRequired: true },
          s9: { name: 'jobId', title: 'Job Id', value: '', element: 's9', isRequired: true },
          s10: { name: 'exporterSelect', title: 'Selected Exporter', value: '', element: 's10', isRequired: false },
          s11: { name: 'exporterOveridePath', title: 'Exporter Override Path', value: '', element: 's11', isRequired: false },
          s12: { name: 'exporterType', title: 'Exporter Type', value: 'Simple CSV', element: 's12', isRequired: false },
          b1: { name: 'removeWarnings', title: 'Remove Warnings', value: true, element: 'b1', isRequired: false },
          b2: { name: 'removeErrors', title: 'Remove Errors', value: true, element: 'b2', isRequired: false },
          b3: { name: 'exportErrors', title: 'Export Errors', value: false, element: 'b3', isRequired: false },
          b4: { name: 'removeDuplicates', title: 'Remove Duplicates', value: true, element: 'b4', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 3,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'PSV Error', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/bacsBureauJobProcessor/',
          icon: '\uf0ca'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Bureau Job'
            },
            {
              fieldType: 'JobId',
              label: 'Job',
              name: 's1',
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Submission Reference',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Contra Narrative',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'Label',
              label: 'Engine Type'
            },
            {
              fieldType: 'RadioButton',
              labels: ['BACS', 'Faster Payments'],
              name: 's4',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'Label',
              label: 'Exception Handling Behaviour'
            },
            {
              fieldType: 'RadioButton',
              labels: ['All payment instructions must validate before the entire submission can continue', 'Remove from the submision, those payment instructions that do not pass validation'],
              name: 's5',
              selectedValue: 'All payment instructions must validate before the entire submission can continue'
            },
            // {
            //   fieldType: 'ExporterList',
            //   label: 'Exportors',
            //   name: 's6',
            //   isRequired: false
            // },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Warnings - Payment instructions with a validation result of Warning will be removed from the submission',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Error - Payment instructions with a validation result of Fix will be removed from the submission',
              name: 'b2'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Export errors',
              name: 'b3'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Duplicates - Payment instructions with a validation result of Duplicate will be removed from the submission',
              name: 'b4'
            }
          ]
        }
      }
    case 'bacsPreSubValidation':
      return {
        id: '',
        name: name,
        title: 'Presub Val',
        type: 'action',
        area: 'BACS Automation',
        props: {
          s2: { name: 'submissionref', title: 'Submission Reference', value: '', element: 's2', isRequired: true },
          s3: { name: 'contraNarrative', title: 'Contra Narrative', value: '', element: 's3', isRequired: false },
          s4: { name: 'engineType', title: 'Engine Type', value: 'BACS', element: 's4', isRequired: true },
          s5: { name: 'exceptionHandlingBehaviour', title: 'Exception Behaviour', value: 'All payment instructions must validate before the entire submission can continue', element: 's5', isRequired: true },
          s7: { name: 'exporterFilename', title: 'Exporter Filename', value: '', element: 's7', isRequired: false },
          s8: { name: 'exporterType', title: 'Exporter Type', value: 'Simple CSV', element: 's8', isRequired: false },
          s9: { name: 'exporterFolder', title: 'Exporter Folder', value: '', element: 's9', isRequired: false },
          b1: { name: 'removeWarnings', title: 'Remove Warnings', value: true, element: 'b1', isRequired: false },
          b2: { name: 'removeErrors', title: 'Remove Errors', value: true, element: 'b2', isRequired: false },
          b3: { name: 'exportErrors', title: 'Export Errors', value: false, element: 'b3', isRequired: false },
          b4: { name: 'removeDuplicates', title: 'Remove Duplicates', value: true, element: 'b4', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/presubmissionvalidation/',
          icon: '\uf0ca'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Presub Val'
            },
            {
              fieldType: 'TextInput',
              label: 'Submission Reference',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Contra Narrative',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'Label',
              label: 'Engine Type'
            },
            {
              fieldType: 'RadioButton',
              labels: ['BACS', 'Faster Payments'],
              name: 's4',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'Label',
              label: 'Exception Handling Behaviour'
            },
            {
              fieldType: 'RadioButton',
              labels: ['All payment instructions must validate before the entire submission can continue', 'Remove from the submision, those payment instructions that do not pass validation'],
              name: 's5',
              selectedValue: 'All payment instructions must validate before the entire submission can continue'
            },
            {
              fieldType: 'TextInput',
              labels: 'Exporter Filename',
              name: 's7',
              selectedValue: ''
            },
            {
              fieldType: 'RadioButton',
              labels: ['Simple JSON','Simple CSV'],
              name: 's8',
              selectedValue: 'Simple CSV'
            },
            {
              fieldType: 'TextInput',
              labels: 'Exporter Folder',
              name: 's9',
              selectedValue: ''
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Warnings - Payment instructions with a validation result of Warning will be removed from the submission',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Error - Payment instructions with a validation result of Fix will be removed from the submission',
              name: 'b2'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Export errors',
              name: 'b3'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Duplicates - Payment instructions with a validation result of Duplicate will be removed from the submission',
              name: 'b4'
            }
          ]
        }
      }
    case 'bacsSignSubmission':
      return {
        id: '',
        name: name,
        title: 'Sign',
        type: 'action',
        area: 'BACS Automation',
        props: {
          // s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true }
          // s2: { name: 'signingMethod', title: 'Signing Method', value: 'Auto', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/signsubmission/',
          icon: '\uf084'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Sign Submission'
            }
            // {
            //   fieldType: 'GroupList',
            //   label: 'GroupList',
            //   name: 's1',
            //   isRequired: true
            // }
            // {
            //   fieldType: 'RadioButton',
            //   labels: ['Auto', 'Manual'],
            //   name: 's2',
            //   selectedValue: 'Auto'
            // }
          ]
        }
      }
    case 'bacsApproveSubmission':
      return {
        id: '',
        name: name,
        title: 'Approval',
        type: 'action',
        area: 'BACS Automation',
        props: {
          // s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'approvalMethod', title: 'Approval Method', value: 'Auto Approval', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/approvesubmission/',
          icon: '\uf00c'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Approve Submission'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'GroupList',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'RadioButton',
              labels: ['Auto Approval', 'Manual (User) Approval'],
              name: 's2',
              selectedValue: 'Auto (HSM)'
            }

          ]
        }
      }
    case 'bacsSubmission':
      return {
        id: '',
        name: name,
        title: 'Submit',
        type: 'action',
        area: 'BACS Automation',
        props: {
          // s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'submissionService', title: 'Submission Service', value: 'BACS', element: 's2', isRequired: true },
          s3: { name: 'submissionType', title: 'Submission Type', value: 'Live', element: 's3', isRequired: true },
          // s4: { name: 'automation', title: 'Automation', value: 'Auto', element: 's4', isRequired: true },
          s5: { name: 'subSummaryFormat', title: 'Submission Summary Format', value: 'HTML', element: 's5', isRequired: true },
          s6: { name: 'submissionSummaryPath', title: 'Submission Summary Path', value: '\\', element: 's6', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/submission/',
          icon: '\uf093'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Submit'
            },
            {
              fieldType: 'Label',
              label: 'Submission Service'
            },
            {
              fieldType: 'RadioButton',
              labels: ['BACS', 'Faster Payments'],
              name: 's2',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'Label',
              label: 'Submission Type'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Live', 'Structural Test', 'Full Test'],
              name: 's3',
              selectedValue: 'Live'
            },
            {
              fieldType: 'Label',
              label: 'Submission Summary Format'
            },
            {
              fieldType: 'RadioButton',
              labels: ['HTML', 'XML', 'PDF'],
              name: 's5',
              selectedValue: 'HTML'
            },
            {
              fieldType: 'TextInput',
              label: 'Save Path',
              name: 's6',
              maxLength: 255,
              isRequired: true
            }
          ]
        }
      }
    case 'bacsReportDownload':
      return {
        id: '',
        name: name,
        title: 'Reports D/L',
        type: 'action',
        area: 'BACS Automation',
        props: {
          // s1: { name: 'group', title: 'Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'reportFormat', title: 'Report Format', value: 'HTML', element: 's2', isRequired: true },
          s3: { name: 'reportPath', title: 'Report Path', value: '\\', element: 's3', isRequired: true },
          s4: { name: 'reportAge', title: 'Report Age', value: 'Last Month', element: 's4', isRequired: true },
          s5: { name: 'serviceType', title: 'Service Type', value: 'BACS', element: 's5', isRequired: true },
          b1: { name: 'downloadPrevious', title: 'Downlaod Previous', value: false, element: 'b1', isRequired: false },
          b2: { name: 'addacsReport', title: 'ADDACS Report', value: false, element: 'b2', isRequired: false },
          b3: { name: 'arucsReport', title: 'ARUCS Report', value: false, element: 'b3', isRequired: false },
          b4: { name: 'aruddReport', title: 'ARUDD Report', value: false, element: 'b4', isRequired: false },
          b5: { name: 'auddisReport', title: 'AUDDIS Report', value: false, element: 'b5', isRequired: false },
          b6: { name: 'awacsReport', title: 'AWACS Report', value: false, element: 'b6', isRequired: false },
          b7: { name: 'ddicAdviceReports', title: 'DDIC Advice Reports', value: false, element: 'b7', isRequired: false },
          b8: { name: 'arrivalReport', title: 'Arrival Report', value: false, element: 'b8', isRequired: false },
          b9: { name: 'withdrawalReport', title: 'Withdrawal Report', value: false, element: 'b9', isRequired: false },
          b10: { name: 'inputReport', title: 'Input Report', value: false, element: 'b10', isRequired: false },
          b11: { name: 'componentHistory', title: 'Component Report', value: false, element: 'b11', isRequired: false },
          b12: { name: 'testReport', title: 'Test Report', value: false, element: 'b12', isRequired: false },
          b13: { name: 'downloadAllGroups', title: 'Downlaod All Groups', value: false, element: 'b13', isRequired: false },
          b14: { name: 'ddicReports', title: 'DDIC Reports', value: false, element: 'b14', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/reports/',
          icon: '\uf019'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Reports Download'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'GroupList',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'Label',
              label: 'Report Format'
            },
            {
              fieldType: 'RadioButton',
              labels: ['XML', 'HTML', 'PDF', 'CSV'],
              name: 's2',
              selectedValue: 'HTML'
            },
            {
              fieldType: 'TextInput',
              label: 'Save Path',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Label',
              label: 'Report Age'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Current Day', 'Last Two Days', 'Last Week', 'Last Month'],
              name: 's4',
              selectedValue: 'Last Month'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Download previously accessed reports',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Download reports across all groups',
              name: 'b13'
            },
            {
              fieldType: 'Label',
              label: 'Service Type'
            },
            {
              fieldType: 'RadioButton',
              labels: ['BACS', 'Faster Payments'],
              name: 's5',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'Label',
              label: 'Reports'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'ADDACS Report',
              name: 'b2'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'ARUCS Report',
              name: 'b3'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'ARUDD Report',
              name: 'b4'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'AUDDIS Report',
              name: 'b5'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'AWACS Report',
              name: 'b6'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'DDIC Report',
              name: 'b7'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Arrival Report',
              name: 'b8'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Withdrawal Report',
              name: 'b9'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Input Report',
              name: 'b10'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Component History',
              name: 'b11'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Test Report',
              name: 'b12'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Test Report',
              name: 'b13'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Test Report',
              name: 'b14'
            }
          ]
        }
      }
    case 'bacsReportMapping':
      return {
        id: '',
        name: name,
        title: 'Report Map',
        type: 'action',
        area: 'BACS Automation',
        props: {
          s1: { name: 'reportFilename', title: 'Report Filename', value: '', element: 's1', isRequired: false },
          s2: { name: 'reportPath', title: 'Report Folder', value: '', element: 's2', isRequired: false },
          s3: { name: 'outputFilename', title: 'Output Filename', value: '', element: 's3', isRequired: false },
          s4: { name: 'outputPath', title: 'Output Folder', value: '', element: 's4', isRequired: false },
          s5: { name: 'mappingType', title: 'mappingType', value: 'Default', element: 's5', isRequired: true },
          s6: { name: 'mappingId', title: 'mappingId', value: '', element: 's6', isRequired: false },
          b1: { name: 'defaultMapping', title: 'Default Mapping', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#750000',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/bacsautomation/reportmapping/',
          icon: '\uf019'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'BACS Report Mapping'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'GroupList',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'Label',
              label: 'Report Format'
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's1',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Report Path',
              name: 's2',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Output Filename',
              name: 's3',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'Label',
              label: 'Report Age'
            },
            {
              fieldType: 'TextInput',
              label: 'Output Folder',
              name: 's4',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'RadioButton',
              labels: ['Default', 'Custom'],
              name: 's5',
              selectedValue: 'BACS'
            },
            {
              fieldType: 'TextInput',
              label: 'Mapping',
              name: 's6',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Download previously accessed reports',
              name: 'b1'
            },
            {
              fieldType: 'Label',
              label: 'Service Type'
            }
          ]
        }
      }
    case 'collectionsProcessor':
      return {
        id: '',
        name: name,
        title: 'Collection',
        type: 'action',
        area: 'Collections Automation',
        props: {
          // s1: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'submissionref', title: 'Submission Reference', value: '', element: 's2', isRequired: true },
          s3: { name: 'contraNarrative', title: 'Contra Narrative', value: '', element: 's3', isRequired: false },
          s4: { name: 'collectionType', title: 'Collection Type', value: 'Standard', element: 's4', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0F876F',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/collectionsProcessor',
          icon: '\uf133'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Presub Val'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'Group',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'TextInput',
              label: 'Submission Reference',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Contra Narrative',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'Label',
              label: 'Collection Type'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Standard', 'AUDDIS'],
              name: 's4',
              selectedValue: 'Standard'
            }
          ]
        }
      }
    case 'collectionsFinaliseSubmission':
      return {
        id: '',
        name: name,
        title: 'Finalise',
        type: 'action',
        area: 'Collections',
        props: {
          s1: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0F876F',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/collectionsProcessor',
          icon: '\uf274'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Presub Val'
            },
            {
              fieldType: 'GroupList',
              label: 'Group',
              name: 's1',
              isRequired: true
            }
          ]
        }
      }
    case 'collectionsReportReconciliation':
      return {
        id: '',
        name: name,
        title: 'Report Rec.',
        type: 'action',
        area: 'Collections',
        props: {
          // s1: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'reportPath', title: 'Report Path', value: '', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0F876F',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/collectionsReportReconciliation',
          icon: '\uf4fe'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Presub Val'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'Group',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'TextInput',
              label: 'Report Path',
              name: 's2',
              maxLength: 255,
              isRequired: true
            }
          ]
        }
      }
    case 'workflowStart':
      return {
        id: '',
        name: name,
        title: 'Start',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's1', isRequired: false },
          s2: { name: 'userSelect', title: 'Selected User', value: '', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 0,
          n2: 1,
          lLabels: [],
          rLabels: ['Out'],
          headerBackColour: '#298025',
          fontColour: '#ffffff',
          htmlLink: '/automation/paygate-workflow/workflow-nodes/other/start/',
          icon: '\uf04b'
        },
        ui: {
          formData: {},
          schema: [
            {
              fieldType: 'Label',
              label: 'Workflow Start'
            },
            {
              fieldType: 'GroupList',
              label: 'Group',
              name: 's1',
              isRequired: false
            },
            {
              fieldType: 'UserList',
              label: 'User',
              name: 's2',
              isRequired: false
            }
          ]
        }
      }
    case 'cronTrigger':
      return {
        id: '',
        name: name,
        title: 'Cron',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/crontrigger',
          icon: '\uf017'
        },
        ui: {
          formData: {
            firstName: 'Evan'
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Cron Trigger'
            },
            {
              fieldType: 'TextInput',
              placeholder: '',
              label: 'Cron Expression',
              name: 'cronExpression',
              maxLength: 128,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Suppress on non-BACS processing days',
              name: 'cbBacsSuppress'
            }
          ]
        }
      }
    case 'schedulerTrigger':
      return {
        id: '',
        name: name,
        title: 'Scheduler',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'iCal Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/schedulerTrigger',
          icon: '\uf073'
        }
      }
    case 'fileWatcherTrigger':
      return {
        id: '',
        name: name,
        title: 'File Watcher',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/fileWatcherTrigger',
          icon: '\uf1c2'
        }
      }
    case 'dbTrigger':
      return {
        id: '',
        name: name,
        title: 'Data Watcher',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/dataWatcherTrigger',
          icon: '\uf1c0'
        }
      }
    case 'manualTrigger':
      return {
        id: '',
        name: name,
        title: 'Manual Trigger',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/manualTrigger',
          icon: '\uf0e7'
        }
      }
    case 'ruleTrigger':
      return {
        id: '',
        name: name,
        title: 'Rule Trigger',
        type: 'trigger',
        area: 'trigger',
        props: {
          s1: { name: 'cronExpression', title: 'Cron Expression', value: '', element: 's1', isRequired: true },
          b1: { name: 'dontFireOnNonBacsDays', title: 'Don’t Fire On Non-Bacs Days', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          lLabels: ['Input'],
          rLabels: ['Out', 'Error'],
          headerBackColour: '#C99257',
          fontColour: '#ffffff',
          htmlLink: '/nodes/ruleTrigger',
          icon: '\uf126'
        }
      }
    case 'copyFile':
      return {
        id: '',
        name: name,
        title: 'Copy File',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'fromPath', title: 'From Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'toPath', title: 'To Path', value: '', element: 's2', isRequired: true },
          s3: { name: 'filename', title: 'Filename', value: '', element: 's3', isRequired: true },
          b1: { name: 'overwrite', title: 'Overwrite', value: false, element: 'b1', isRequired: false },
          b2: { name: 'followErrorOnNoFile', title: 'Follow Error path on no matchign file', value: false, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/copyfile/',
          icon: '\uf0c5'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Copy File'
            },
            {
              fieldType: 'TextInput',
              label: 'From Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'To Path',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Overwrite existing?',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              label: 'Follow error path on no file',
              name: 'b2'
            }
          ]
        }
      }
    case 'moveFile':
      return {
        id: '',
        name: name,
        title: 'Move File',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'fromPath', title: 'From Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'toPath', title: 'To Path', value: '', element: 's2', isRequired: true },
          s3: { name: 'filename', title: 'Filename', value: '', element: 's3', isRequired: true },
          b1: { name: 'overwrite', title: 'Overwrite', value: false, element: 'b1', isRequired: false },
          b2: { name: 'followErrorOnNoFile', title: 'Follow Error path on no matchign file', value: false, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/movefile/',
          icon: '\uf15b'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Move File'
            },
            {
              fieldType: 'TextInput',
              label: 'From Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'To Path',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Overwrite existing?',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              label: 'Follow error path on no file',
              name: 'b2'
            }
          ]
        }
      }
    case 'moveSomeFiles':
      return {
        id: '',
        name: name,
        title: 'Move Some',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'fromPath', title: 'From Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'toPath', title: 'To Path', value: '', element: 's2', isRequired: true },
          s3: { name: 'filename', title: 'Filename', value: '', element: 's3', isRequired: true },
          int1: { name: 'Quantity', title: 'Quantity', value: '1', element: 'int1', isRequired: false },
          b1: { name: 'overwrite', title: 'Overwrite', value: false, element: 'b1', isRequired: false },
          b2: { name: 'followErrorOnNoFile', title: 'Follow Error path on no matchign file', value: false, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/movesomefiles/',
          icon: '\uf15b'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Move File'
            },
            {
              fieldType: 'TextInput',
              label: 'From Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'To Path',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Overwrite existing?',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              label: 'Follow error path on no file',
              name: 'b2'
            }
          ]
        }
      }
    case 'renameFile':
      return {
        id: '',
        name: name,
        title: 'Rename File',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'fromPath', title: 'From Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'oldFilename', title: 'Old Filename', value: '', element: 's2', isRequired: true },
          s3: { name: 'newFilename', title: 'New Filename', value: '', element: 's3', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/renamefile/',
          icon: '\uf044'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Rename File'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Old Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'New Filename',
              name: 's3',
              maxLength: 255,
              isRequired: true
            }
          ]
        }
      }
    case 'deleteFile':
      return {
        id: '',
        name: name,
        title: 'Delete File',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'path', title: 'Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          b2: { name: 'followErrorOnNoFile', title: 'Follow Error path on no matchign file', value: false, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/deletefile/',
          icon: '\uf1f8'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Delete File'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Follow error path on no file',
              name: 'b2'
            }
          ]
        }
      }
    // case 'fileAppender':
    //   return {
    //     id: '',
    //     name: name,
    //     title: 'File Appender',
    //     type: 'action',
    //     area: 'File Processing',
    //     props: {
    //       s1: { name: 'inputPath', title: 'Input Folder', value: '', element: 's1', isRequired: true },
    //       s2: { name: 'inputFilename', title: 'Input Filename', value: '', element: 's2', isRequired: true },
    //       s3: { name: 'outputPath', title: 'Output Folder', value: '', element: 's3', isRequired: true },
    //       s4: { name: 'outputFilename', title: 'Output Filename', value: '', element: 's4', isRequired: true },
    //       b1: { name: 'appendCrLf', title: 'Append CRLF', value: false, element: 'b1', isRequired: false }
    //     },
    //     nodeConfig: {
    //       n1: 1,
    //       n2: 3,
    //       headerBackColour: '#0E4C8A',
    //       fontColour: '#eeeeee',
    //       lLabels: ['Input 1'],
    //       rLabels: ['Continue', 'Success', 'Error'],
    //       htmlLink: '/nodes/fileAppender',
    //       icon: '\uf055'
    //     },
    //     ui: {
    //       formData: {
    //       },
    //       schema: [
    //         {
    //           fieldType: 'Label',
    //           label: 'File Appender'
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Input Folder',
    //           name: 's1',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Input Filname',
    //           name: 's2',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Output Folder',
    //           name: 's3',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Output Filname',
    //           name: 's4',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'Checkbox',
    //           label: 'Append newline characters (CRLF) between each appended file.',
    //           name: 'b1'
    //         }
    //       ]
    //     }
    //   }
    case 'fileJoiner':
      return {
        id: '',
        name: name,
        title: 'File Joiner',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'inputPath', title: 'Input Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'inputFilename', title: 'Input Filename', value: '', element: 's2', isRequired: true },
          s3: { name: 'outputPath', title: 'Output Folder', value: '', element: 's3', isRequired: true },
          s4: { name: 'outputFilename', title: 'Output Filename', value: '', element: 's4', isRequired: true },
          s5: { name: 'delimiter', title: 'Delimiter', value: '{CRLF}', element: 's5', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/filejoiner/',
          icon: '\uf0c5'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'File Joiner'
            },
            {
              fieldType: 'TextInput',
              label: 'Input Folder',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Input Filname',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Output Path',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Output Filename',
              name: 's4',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Delimiter',
              name: 's5',
              rows: 4,
              cols: 23,
              maxLength: 1024
            }
          ]
        }
      }
    case 'fileSplitter':
      return {
        id: '',
        name: name,
        title: 'File Splitter',
        type: 'action',
        area: 'File Processing',
        props: {
          // s1: { name: 'groupSelect', title: 'Selected Group', value: '', element: 's1', isRequired: true },
          s2: { name: 'importerSelect', title: 'Selected Importer', value: '', element: 's2', isRequired: true },
          s3: { name: 'inputPath', title: 'Input Folder', value: '', element: 's3', isRequired: true },
          s4: { name: 'inputFilename', title: 'Input Filename', value: '', element: 's4', isRequired: true },
          s5: { name: 'data', title: 'Data', value: '' }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#0E4C8A',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/fileoperations/filesplitter/',
          icon: '\uf478'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'File Splitter'
            },
            // {
            //   fieldType: 'GroupList',
            //   label: 'Group',
            //   name: 's1',
            //   isRequired: true
            // },
            {
              fieldType: 'ImporterList',
              label: 'Importor',
              name: 's2',
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Input Filname',
              name: 's4',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Output Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Output Filename',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Delimiter',
              name: 's5',
              rows: 4,
              cols: 23,
              maxLength: 1024
            }
          ]
        }
      }
    case 'emailFile':
      return {
        id: '',
        name: name,
        title: 'Email File',
        type: 'action',
        area: 'File Processing',
        props: {
          s1: { name: 'path', title: 'Path', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          s3: { name: 'emailAddress', title: 'Email Address', value: '', element: 's3', isRequired: true },
          s4: { name: 'ccAddress', title: 'cc Email Address', value: '', element: 's4', isRequired: true },
          s5: { name: 'bccAddress', title: 'bcc Email Address', value: '', element: 's5', isRequired: true },
          s6: { name: 'title', title: 'Title', value: '', element: 's6', isRequired: true },
          s7: { name: 'messageTemplate', title: 'Message Template', value: '', element: 's7', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#9E519F',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/filetransfer/emailfile/',
          icon: '\uf0c6'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Email File'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Email Address List',
              name: 's3',
              rows: 4,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'TextArea',
              label: 'Cc Address List',
              name: 's4',
              rows: 8,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'TextArea',
              label: 'Bcc Address List',
              name: 's5',
              rows: 8,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'TextInput',
              label: 'Title',
              name: 's6',
              maxLength: 256,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Message',
              name: 's7',
              rows: 14,
              cols: 23,
              maxLength: 20
            }
          ]
        }
      }
    case 'sftpFile':
      return {
        id: '',
        name: name,
        title: 'SFTP File',
        type: 'action',
        area: 'File Transfer',
        props: {
          s1: { name: 'hostAddress', title: 'Host Address', value: '', element: 's1', isRequired: true },
          s2: { name: 'remoteDir', title: 'Remote Directory', value: '', element: 's2', isRequired: false },
          s3: { name: 'localDir', title: 'Local Directory', value: '', element: 's3', isRequired: false },
          s4: { name: 'filename', title: 'Filename', value: '', element: 's4', isRequired: true },
          s5: { name: 'username', title: 'username', value: '', element: 's5', isRequired: false },
          s6: { name: 'pvtKeyPath', title: 'Pvt Key Path', value: '', element: 's6', isRequired: false },
          s7: { name: 'upDown', title: 'Up Down', value: 'Download', element: 's7', isRequired: true },
          s8: { name: 'authType', title: 'Auth Type', value: 'Password', element: 's8', isRequired: true },
          b1: { name: 'anon', title: 'Anonymous', value: false, element: 'b1', isRequired: false },
          b2: { name: 'pvtKey', title: 'Pvt Key', value: false, element: 'b2', isRequired: false },
          b3: { name: 'ignoreDays', title: 'Ignore Days', value: false, element: 'b3', isRequired: false },
          b4: { name: 'overwriteRemote', title: 'Overwrite Remote Files', value: false, element: 'b4', isRequired: false },
          pw1: { name: 'userPassword', title: 'User Password', value: '', element: 'pw1', isRequired: false },
          pw2: { name: 'pinPassword', title: 'Pin Password', value: '', element: 'pw2', isRequired: false },
          int1: { name: 'port', title: 'Port', value: '22', element: 'int1', isRequired: false },
          int2: { name: 'days', title: 'Days', value: '31', element: 'int2', isRequired: false }

        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#9E519F',
          fontColour: '#eeeeee',
          lLabels: ['Input', 'Input 2'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/filetransfer/sftpfiletransfer/',
          icon: '\uf093'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'SFTP (SSH) File Transfer'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Upload', 'Download'],
              name: 's7',
              selectedValue: 'Download'
            },
            {
              fieldType: 'TextInput',
              label: 'Host Address',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Port',
              minValue: 1
            },
            // {
            //   fieldType: 'RadioButton',
            //   labels: ['FTP', 'sFTP', 'FTPS'],
            //   name: 's9',
            //   selectedValue: 'sFTP'
            // },
            {
              fieldType: 'TextInput',
              label: 'Remote Directory',
              name: 's2',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              label: 'Local Directory',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's4',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Label',
              label: 'Authentication'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Anonymous',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Use FTPS (TLS)',
              name: 'b2'
            },
            {
              fieldType: 'TextInput',
              label: 'Username',
              name: 's5',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'PasswordInput',
              label: 'Password',
              name: 'pw1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Public Key Authentication',
              name: 'b2'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Password', 'Pvt Key', 'Pvt Key & Password'],
              name: 's8',
              selectedValue: 'Password'
            },
            {
              fieldType: 'TextInput',
              label: 'Pvt Key Path',
              name: 's6',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'PasswordInput',
              label: 'Pvt Key Password',
              name: 'pw2',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'Checkbox',
              label: 'Overwrite Remote Files',
              name: 'b4'
            },
            {
              fieldType: 'Label',
              label: 'Duplicates'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Ignore files already downloaded in the last',
              name: 'b3'
            },
            {
              fieldType: 'NumberInput',
              name: 'int2',
              label: 'Days',
              minValue: 1
            }
          ]
        }
      }
    case 'ftpFile':
      return {
        id: '',
        name: name,
        title: 'FTP File',
        type: 'action',
        area: 'File Transfer',
        props: {
          s1: { name: 'hostAddress', title: 'Host Address', value: '', element: 's1', isRequired: true },
          s2: { name: 'remoteDir', title: 'Remote Directory', value: '', element: 's2', isRequired: false },
          s3: { name: 'localDir', title: 'Local Directory', value: '', element: 's3', isRequired: false },
          s4: { name: 'filename', title: 'Filename', value: '', element: 's4', isRequired: true },
          s5: { name: 'username', title: 'username', value: '', element: 's5', isRequired: false },
          s6: { name: 'pvtKeyPath', title: 'Pvt Key Path', value: '', element: 's6', isRequired: false },
          s7: { name: 'upDown', title: 'Up Down', value: 'Download', element: 's7', isRequired: true },
          s8: { name: 'authType', title: 'Auth Type', value: 'Password', element: 's8', isRequired: true },
          s9: { name: 'ftpType', title: 'FTP Type', value: 'FTP', element: 's9', isRequired: false },
          b1: { name: 'anon', title: 'Anonymous', value: false, element: 'b1', isRequired: false },
          b2: { name: 'useFtps', title: 'Use FTPS', value: true, element: 'b2', isRequired: false },
          b3: { name: 'ignoreDays', title: 'Ignore Days', value: false, element: 'b3', isRequired: false },
          b4: { name: 'useProxy', title: 'Use Proxy', value: false, element: 'b4', isRequired: false },
          pw1: { name: 'userPassword', title: 'User Password', value: '', element: 'pw1', isRequired: false },
          int1: { name: 'port', title: 'Port', value: '21', element: 'int1', isRequired: true },
          int2: { name: 'days', title: 'Days', value: '31', element: 'int2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#9E519F',
          fontColour: '#eeeeee',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/ftpFile',
          icon: '\uf093'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'FTP / FTPS File Transfer'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Upload', 'Download'],
              name: 's7',
              selectedValue: 'Download'
            },
            {
              fieldType: 'TextInput',
              label: 'Host Address',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Port',
              minValue: 1
            },
            // {
            //   fieldType: 'RadioButton',
            //   labels: ['FTP', 'sFTP', 'FTPS'],
            //   name: 's9',
            //   selectedValue: 'sFTP'
            // },
            {
              fieldType: 'TextInput',
              label: 'Remote Directory',
              name: 's2',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              label: 'Local Directory',
              name: 's3',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's4',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Label',
              label: 'Authentication'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Anonymous',
              name: 'b1'
            },
            {
              fieldType: 'TextInput',
              label: 'Username',
              name: 's5',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'PasswordInput',
              label: 'Password',
              name: 'pw1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Public Key Authentication',
              name: 'b2'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Password', 'Pvt Key', 'Pvt Key & Password'],
              name: 's8',
              selectedValue: 'Password'
            },
            {
              fieldType: 'TextInput',
              label: 'Pvt Key Path',
              name: 's6',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'PasswordInput',
              label: 'Pvt Key Password',
              name: 'pw2',
              maxLength: 255,
              isRequired: false
            },
            {
              fieldType: 'Checkbox',
              label: 'Use FTP Proxy',
              name: 'b4'
            },
            {
              fieldType: 'Label',
              label: 'Duplicates'
            },
            {
              fieldType: 'Checkbox',
              placeholder: '',
              label: 'Ignore files already downloaded in the last',
              name: 'b3'
            },
            {
              fieldType: 'NumberInput',
              name: 'int2',
              label: 'Days',
              minValue: 1
            }
          ]
        }
      }
    case 'emailMessage':
      return {
        id: '',
        name: name,
        title: 'Email',
        type: 'action',
        area: 'Messaging',
        props: {
          s1: { name: 'title', title: 'Title', value: '', element: 's1', isRequired: true },
          s2: { name: 'messageTemplate', title: 'Message Template', value: '', element: 's2', isRequired: true },
          s3: { name: 'emailAddress', title: 'Email Address', value: '', element: 's3', isRequired: true },
          s4: { name: 'ccEmailAddress', title: 'Cc Email Address', value: '', element: 's4', isRequired: false },
          s5: { name: 'bccEmailAddress', title: 'Bcc Email Address', value: '', element: 's5', isRequired: false },
          b1: { name: 'appendErrorMessage', title: 'Append Error Message', value: false, element: 'b1', isRequired: false },
          b2: { name: 'onlyEmailOnError', title: 'Only email on error', value: true, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#158BEF',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/messaging/emailmessage/',
          icon: '\uf0e0'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Send Email'
            },
            {
              fieldType: 'TextInput',
              label: 'Title',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Message Template',
              name: 's3',
              rows: 8,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'TextArea',
              label: 'Email Address List',
              name: 's1',
              rows: 4,
              cols: 23,
              isRequired: true,
              maxLength: 1024
            },
            {
              fieldType: 'TextArea',
              label: 'Cc Address List',
              name: 's4',
              rows: 4,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'TextArea',
              label: 'Bcc Address List',
              name: 's5',
              rows: 4,
              cols: 23,
              maxLength: 1024
            },
            {
              fieldType: 'Checkbox',
              label: 'Append Error message',
              name: 'b1'
            },
            {
              fieldType: 'Checkbox',
              label: 'Only email on error',
              name: 'b2'
            }
          ]
        }
      }
    // case 'sendTweet':
    //   return {
    //     id: '',
    //     name: name,
    //     title: 'Tweet',
    //     type: 'action',
    //     area: 'Messaging',
    //     props: {
    //       s1: { name: 'message', title: 'Message', value: '', element: 's1', isRequired: true },
    //       s2: { name: 'consumerKey', title: 'Consumer Key', value: '', element: 's2', isRequired: true },
    //       pw1: { name: 'consumerSecret', title: 'Consumer Secret', value: '', element: 'pw1', isRequired: true },
    //       s3: { name: 'accessToken', title: 'Access Token', value: '', element: 's3', isRequired: true },
    //       pw2: { name: 'accessTokenSecret', title: 'Access Token Secret', value: '', element: 'pw2', isRequired: true }
    //     },
    //     nodeConfig: {
    //       n1: 1,
    //       n2: 2,
    //       headerBackColour: '#158BEF',
    //       fontColour: '#ffffff',
    //       lLabels: ['Input'],
    //       rLabels: ['Continue', 'Error'],
    //       htmlLink: '/automation/paygate-workflow/workflow-nodes/messaging/sendtweet/',
    //       icon: '\uf249'
    //     },
    //     ui: {
    //       formData: {
    //       },
    //       schema: [
    //         {
    //           fieldType: 'Label',
    //           label: 'Send Tweet'
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Message',
    //           name: 's1',
    //           maxLength: 280,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Consumer Key',
    //           name: 's2',
    //           maxLength: 128,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'PasswordInput',
    //           label: 'Consumer Secret',
    //           name: 'pw1',
    //           maxLength: 128,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Access Token',
    //           name: 's3',
    //           maxLength: 128,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'PasswordInput',
    //           label: 'Access Token Secret',
    //           name: 'pw2',
    //           maxLength: 128,
    //           isRequired: true
    //         }
    //       ]
    //     }
    //   }
    case 'webhook':
      return {
        id: '',
        name: name,
        title: 'WebHook',
        type: 'action',
        area: 'Messaging',
        props: {
          s1: { name: 'title', title: 'Title', value: '', element: 's1', isRequired: true },
          s2: { name: 'url', title: 'URL', value: '', element: 's2', isRequired: true },
          s3: { name: 'message', title: 'Message', value: '', element: 's3', isRequired: true, maxLength: 1024 },
          pw1: { name: 'secret', title: 'Secret', value: '', element: 'pw1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#158BEF',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/messaging/webhookmessage/',
          icon: '\uf0ac'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Webhook'
            },
            {
              fieldType: 'TextInput',
              label: 'Title',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'URL',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Message',
              name: 's3',
              rows: 4,
              cols: 23,
              maxLength: 1024,
              isRequired: false
            },
            {
              fieldType: 'PasswordInput',
              label: 'Password',
              name: 'pw1',
              maxLength: 255
            },
          ]
        }
      }
    case 'smsMessage':
      return {
        id: '',
        name: name,
        title: 'SMS',
        type: 'action',
        area: 'Messaging',
        props: {
          s1: { name: 'number', title: 'Phone Number', value: '', element: 's1', isRequired: true, maxLength: 256 },
          s2: { name: 'message', title: 'Message', value: '', element: 's2', isRequired: true, maxLength: 1024 }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#158BEF',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/messaging/smsmessage/',
          icon: '\uf7cd'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'SMS Message'
            },
            {
              fieldType: 'TextInput',
              label: 'Number(s)',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextArea',
              label: 'Message',
              name: 's2',
              rows: 4,
              cols: 23,
              maxLength: 4096,
              isRequired: true
            }
          ]
        }
      }
    // Encryption
    case 'encryptFile':
      return {
        id: '',
        name: name,
        title: 'Encrypt File',
        type: 'action',
        area: 'Encryption',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          b1: { name: 'v2Compat', title: 'v2 Compatible', value: true, element: 'b1', isRequired: false },
          b2: { name: 'followError', title: 'Follow Error', value: false, element: 'b2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#4C888C',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/encryption/encryptfile/',
          icon: '\uf023'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Encrypt File'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Paygate V2 Compatible',
              name: 'b1'
            }
          ]
        }
      }
    case 'decryptFile':
      return {
        id: '',
        name: name,
        title: 'Decrypt File',
        type: 'action',
        area: 'Encryption',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          b1: { name: 'v2Compat', title: 'v2 Compatible', value: true, element: 'b1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#4C888C',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/encryption/decryptfile/',
          icon: '\uf09c'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Encrypt File'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Paygate V2 Compatible',
              name: 'b1'
            }
          ]
        }
      }
    // Compression
    // case 'gzipCompression':
    //   return {
    //     id: '',
    //     name: name,
    //     title: 'gzip',
    //     type: 'action',
    //     area: 'File Compression',
    //     props: {
    //       s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
    //       s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true }
    //     },
    //     nodeConfig: {
    //       n1: 1,
    //       n2: 2,
    //       headerBackColour: '#8C694C',
    //       fontColour: '#ffffff',
    //       lLabels: ['Input'],
    //       rLabels: ['Continue', 'Error'],
    //       htmlLink: '/nodes/gzipCompression',
    //       icon: '\uf1c6'
    //     },
    //     ui: {
    //       formData: {
    //       },
    //       schema: [
    //         {
    //           fieldType: 'Label',
    //           label: 'gzip Compression'
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'File Path',
    //           name: 's1',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Filename',
    //           name: 's2',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Output Folder',
    //           name: 's3',
    //           maxLength: 255,
    //           isRequired: true
    //         }
    //       ]
    //     }
    //   }
    // case 'gzipDecompression':
    //   return {
    //     id: '',
    //     name: name,
    //     title: 'gunzip',
    //     type: 'action',
    //     area: 'File Compression',
    //     props: {
    //       s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
    //       s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true }
    //     },
    //     nodeConfig: {
    //       n1: 1,
    //       n2: 2,
    //       headerBackColour: '#8C694C',
    //       fontColour: '#ffffff',
    //       lLabels: ['Input'],
    //       rLabels: ['Continue', 'Error'],
    //       htmlLink: '/nodes/gzipDecompression',
    //       icon: '\uf1c6'
    //     },
    //     ui: {
    //       formData: {
    //       },
    //       schema: [
    //         {
    //           fieldType: 'Label',
    //           label: 'gzip Decompression'
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'File Path',
    //           name: 's1',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Filename',
    //           name: 's2',
    //           maxLength: 255,
    //           isRequired: true
    //         },
    //         {
    //           fieldType: 'TextInput',
    //           label: 'Output Folder',
    //           name: 's3',
    //           maxLength: 255,
    //           isRequired: true
    //         }
    //       ]
    //     }
    //   }
    case 'zipCompression':
      return {
        id: '',
        name: name,
        title: 'zip',
        type: 'action',
        area: 'File Compression',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          s3: { name: 'zipFolder', title: 'Zip Folder', value: '', element: 's3', isRequired: true },
          s4: { name: 'zipFilename', title: 'Zip Filename', value: '', element: 's4', isRequired: true },
          b1: { name: 'encrypt', title: 'Encrypt', value: false, element: 'b1', isRequired: false },
          b2: { name: 'followError', title: 'Follow Error', value: false, element: 'b2', isRequired: false },
          pw1: { name: 'password', title: 'Password', value: '', element: 'pw1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#893C8A',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/compression/zipfile/',
          icon: '\uf1c6'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'zip Compression'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Zip File Path',
              name: 's3',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Zip Filename',
              name: 's4',
              maxLength: 255,
              isRequired: true
            },
            // {
            //   fieldType: 'TextInput',
            //   label: 'Output Folder',
            //   name: 's3',
            //   maxLength: 255,
            //   isRequired: true
            // },
            {
              fieldType: 'Checkbox',
              label: 'Encrypt Zip File',
              name: 'b1'
            },
            {
              fieldType: 'PasswordInput',
              label: 'Password',
              name: 'pw1',
              maxLength: 255
            },
            {
              fieldType: 'SelectList',
              name: 'int1',
              multi: false,
              label: 'Key Length',
              options: ['128 bit', '192 bit', '256 bit']
            }
          ]
        }
      }
    case 'zipDecompression':
      return {
        id: '',
        name: name,
        title: 'unzip',
        type: 'action',
        area: 'File Compression',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          // s3: { name: 'outputFolder', title: 'Output Folder', value: '', element: 's3', isRequired: false },
          b1: { name: 'decrypt', title: 'Decrypt', value: false, element: 'b1', isRequired: false },
          b2: { name: 'followError', title: 'Follow Error', value: false, element: 'b2', isRequired: false },
          b3: { name: 'overwrite', title: 'Overwrite Existing', value: true, element: 'b3', isRequired: false },
          pw1: { name: 'password', title: 'Password', value: '', element: 'pw1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#893C8A',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/compression/unzipfile/',
          icon: '\uf1c6'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'zip Decompression'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Checkbox',
              label: 'Decrypt File',
              name: 'b1'
            },
            {
              fieldType: 'PasswordInput',
              label: 'Password',
              name: 'pw1',
              maxLength: 32
            },
            {
              fieldType: 'SelectList',
              name: 'int1',
              multi: false,
              label: 'Key Length',
              options: ['128 bit', '192 bit', '256 bit']
            }
          ]
        }
      }
    case 'delay':
      return {
        id: '',
        name: name,
        title: 'Delay',
        type: 'action',
        area: 'Misc',
        props: {
          int1: { name: 'delayTime', title: 'Delay (s)', value: '1', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/flow/delay/',
          icon: '\uf253'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Delay'
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Delay Time (seconds)',
              minValue: 1,
              value: 10
            }
          ]
        }
      }
    case 'waitForFile':
      return {
        id: '',
        name: name,
        title: 'Wait for File',
        type: 'action',
        area: 'Misc',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          int1: { name: 'timeoutTime', title: 'Time (seconds)', value: '30', element: 'int1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/flow/waitforfile/',
          icon: '\uf15c'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'zip Decompression'
            },
            {
              fieldType: 'TextInput',
              label: 'File Path',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'NumberInput',
              name: 'int1',
              label: 'Timeout Time (Seconds)',
              minValue: 10,
              value: 900
            }
          ]
        }
      }
    case 'xsltFormatter':
      return {
        id: '',
        name: name,
        title: 'XSLT',
        type: 'action',
        area: 'Misc',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          int1: { name: 'orderCriteria', title: 'Order Criteria', value: '', element: 'int1', isRequired: false },
          int2: { name: 'orderDirection', title: 'Order Direction', value: '', element: 'int2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/xsltFormatter',
          icon: '\uf1c9'
        }
      }
    case 'filenameVariable':
      return {
        id: '',
        name: name,
        title: 'Filename Var',
        type: 'action',
        area: 'Misc',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          s3: { name: 'orderCriteria', title: 'Order Criteria', value: 'Creation', element: 's3', isRequired: false },
          s4: { name: 'orderDirection', title: 'Order Direction', value: 'First', element: 's4', isRequired: false },
          s5: { name: 'selected', title: 'Selected', value: '1', element: 's5', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Found', 'None Found'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/flow/filenamevariable/',
          icon: '\uf292'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Filename Variable'
            },
            {
              fieldType: 'TextInput',
              label: 'Folder',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'Label',
              label: 'Order Criteria'
            },
            {
              fieldType: 'RadioButton',
              labels: ['Name', 'Creation', 'Last Access', 'File Size'],
              name: 's3',
              selectedValue: 'Name'
            },
            {
              fieldType: 'Label',
              label: 'Order Direction'
            },
            {
              fieldType: 'RadioButton',
              labels: ['First', 'Last'],
              name: 's4',
              selectedValue: 'First'
            },
            {
              fieldType: 'RadioButton',
              labels: ['1', '2', '3', '4', '5', '6'],
              name: 's5',
              selectedValue: '1'
            }
          ]
        }
      }
    case 'fileExists':
      return {
        id: '',
        name: name,
        title: 'File Exists',
        type: 'action',
        area: 'Misc',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Exists', 'Does not Exist'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/flow/fileexists/',
          icon: '\uf15b'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'File Exists'
            },
            {
              fieldType: 'TextInput',
              label: 'Folder',
              name: 's1',
              maxLength: 255,
              isRequired: true
            },
            {
              fieldType: 'TextInput',
              label: 'Filename',
              name: 's2',
              maxLength: 255,
              isRequired: true
            }
          ]
        }
      }
    case 'rule':
      return {
        id: '',
        name: name,
        title: 'Rule',
        type: 'action',
        area: 'Flow',
        props: {
          s1: { name: 'groupSelect', title: 'Selected Group', value: '' },
          s2: { name: 'sourceData', title: 'Source Data', value: '' },
          s3: { name: 'data', title: 'Data', value: '' },
          b1: { name: 'overwrite', title: 'Overwrite', value: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 8,
          headerBackColour: '#8C484D',
          fontColour: '#ffffff',
          lLabels: ['In'],
          rLabels: ['Out 1', 'Out 2', 'Out 3', 'Out 4', 'Out 5', 'Out 6', 'Out 7', 'Out 8'],
          htmlLink: '/nodes/rule',
          icon: '\uf0e3'
        }
      }
    // Commands
    case 'dosCommand':
      return {
        id: '',
        name: name,
        title: 'DOS Cmd',
        type: 'action',
        area: 'Scripts And Commands',
        props: {
          s1: { name: 'command', title: 'Command', value: '', element: 's1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#222222',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/doscmd',
          icon: '\uf120'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'DOS Command'
            },
            {
              fieldType: 'TextArea',
              label: 'Command',
              name: 's1',
              rows: 4,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
    case 'powershellScript':
      return {
        id: '',
        name: name,
        title: 'PS Script',
        type: 'action',
        area: 'Scripts And Commands',
        props: {
          s1: { name: 'script', title: 'Script', value: '', element: 's1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#222222',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/psscript',
          icon: '\uf1c9'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'PowerShell Script'
            },
            {
              fieldType: 'TextArea',
              label: 'Script',
              name: 's1',
              rows: 16,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
    case 'paygateScript':
      return {
        id: '',
        name: name,
        title: 'Script',
        type: 'action',
        area: 'Scripts And Commands',
        props: {
          s1: { name: 'script', title: 'Script', value: '', element: 's1', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#222222',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/psscript',
          icon: '\uf1c9'
        }
      }
    case 'addToBlockchain':
      return {
        id: '',
        name: name,
        title: 'Blockchain',
        type: 'action',
        area: 'variables',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          int1: { name: 'orderCriteria', title: 'Order Criteria', value: '', element: 'int1', isRequired: false },
          int2: { name: 'orderDirection', title: 'Order Direction', value: '', element: 'int2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8D557E',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/nodes/addToBlockchain',
          icon: '\uf00b'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Add to Paygate Blockchain'
            },
            {
              fieldType: 'TextArea',
              label: 'Message Template',
              name: 's1',
              rows: 16,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
    case 'addToWorkflowLog':
      return {
        id: '',
        name: name,
        title: 'Log Entry',
        type: 'action',
        area: 'logging',
        props: {
          s1: { name: 'message', title: 'message', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8D557E',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/logging/logentry/',
          icon: '\uf0ca'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Add to Workflow log'
            },
            {
              fieldType: 'TextArea',
              label: 'Message',
              name: 's1',
              rows: 16,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
    case 'addToAuditLog':
      return {
        id: '',
        name: name,
        title: 'Audit',
        type: 'action',
        area: 'logging',
        props: {
          s1: { name: 'message', title: 'Message', value: '', element: 's1', isRequired: true }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8D557E',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/logging/auditentry/',
          icon: '\uf022'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Add to Paygate Audit Log'
            },
            {
              fieldType: 'TextArea',
              label: 'Message',
              name: 's1',
              rows: 16,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
    case 'addToExecutionLog':
      return {
        id: '',
        name: name,
        title: 'ExecutionLog',
        type: 'action',
        area: 'logging',
        props: {
          s1: { name: 'sourceFolder', title: 'Source Folder', value: '', element: 's1', isRequired: true },
          s2: { name: 'filename', title: 'Filename', value: '', element: 's2', isRequired: true },
          int1: { name: 'orderCriteria', title: 'Order Criteria', value: '', element: 'int1', isRequired: false },
          int2: { name: 'orderDirection', title: 'Order Direction', value: '', element: 'int2', isRequired: false }
        },
        nodeConfig: {
          n1: 1,
          n2: 2,
          headerBackColour: '#8D557E',
          fontColour: '#ffffff',
          lLabels: ['Input'],
          rLabels: ['Continue', 'Error'],
          htmlLink: '/automation/paygate-workflow/workflow-nodes/logging/logentry/',
          icon: '\uf022'
        },
        ui: {
          formData: {
          },
          schema: [
            {
              fieldType: 'Label',
              label: 'Add to Paygate Execution Log'
            },
            {
              fieldType: 'TextArea',
              label: 'Message Template',
              name: 's1',
              rows: 16,
              cols: 23,
              maxLength: 1024,
              isRequired: true
            }
          ]
        }
      }
  }
}
