<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Manage Job - {{ bureauJob.name }}
          <help-icon :docPath="'/bacsbureau/jobs/'" />
        </h2>
      </div>

      <div class="idb-block-content">
        <b-card
          v-if="currentSubmissionCount > 0"
          bg-variant="warning"
          text-variant="white"
          style="margin-bottom: 0.8em; margin-right: 0.8em"
        >This bureau job is currently being used in a submission in progress. No modifications can be made while it is in the processing stages.</b-card>

        <b-row>
          <b-col sm="2">
            <label class="mt-2" for="filter">Group</label>
          </b-col>
          <b-col sm="3">
            <group-select id="filter"
              v-model="bureauJob.groupId"
              @input="onSave()"
              :groups="groups"
              :clearable="false"
              :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
            ></group-select>
          </b-col>
          <!-- <b-col>
            <b-btn
              variant="outline-primary"
              class="float-right"
              @click="showCustomerSelector()"
              ref="btnShow"
              :disabled="isLoading"
            >
              <i class="fa fa-list pointer"></i>
              Bulk Insert
            </b-btn>
          </b-col>-->
        </b-row>
        <b-row class="mt-2">
          <b-col sm="2">
            <label for="DeleteSourceFiles">Delete Source Files</label>
          </b-col>
          <b-col sm="3">
            <p-check
              name="DeleteSourceFiles"
              class="p-switch p-fill"
              :labelId="'DeleteSourceFiles'"
              color="primary"
              @change="onSave()"
              v-model="bureauJob.deleteSourceFiles"
              :disabled="isLoading || currentSubmissionCount > 0 || !canManage" aria-describedby="deleteSourceFilesDescription"
            >
            </p-check>
            <span id="deleteSourceFilesDescription"
                  class="form-text small"
            ><label>Specifies whether the source files will be deleted upon successfully being submitted to BACS.</label></span>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              @on-row-click="() => {}"
              @on-cell-click="onCellClick"
              styleClass="vgt-table striped bordered"
              :searchOptions="{
                          enabled: true
                        }"
              :sort-options="sortOptions"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              ref="table"
              :isLoading.sync="isTableLoading"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="btn btn-sm btn-warning"
                    id="onDuplicateJobDetailClick"
                    @click.stop="onDuplicateJobDetailClick(props.row)"
                    title="Duplicate Job Detail"
                    :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
                  >
                    <span class="fa fa-copy"></span>
                  </button>
                  <button
                    class="btn btn-sm btn-danger ml-1"
                    id="onDeleteJobDetailClick"
                    @click="onDeleteJobDetailClick(props.row)"
                    title="Delete Job Detail"
                    :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
                  >
                    <span class="fa fa-trash-alt"></span>
                  </button>
                </span>

                <span v-else-if="props.column.field === 'customerName'">
                 <span v-if="props.row.customerName === ' ()'"><i>Not Set</i></span>
                 <span v-else>
                  {{props.row.customerName }}
                 </span>
                </span>

                <span v-else-if="props.column.field === 'hasWildcard'">
                  <i class="fa fa-check" v-if="props.row.hasWildcard"></i>
                  <i class="fa fa-times" v-else></i>
                </span>
                <span v-else-if="props.column.field === 'importSchema'">
                  {{
                  (props.row.mapping === null ||props.row.mapping === '')  ? props.formattedRow[props.column.field] : getMappingName(props.row.mapping)
                  }}
                </span>
                <span v-else @click="onCellClick(props.row)">
                  {{
                  props.formattedRow[props.column.field]
                  }}
                </span>
              </template>

              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                  aria-label="Clear Filters"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span><span class="sr-only">Clear filters</span>
                </button>
                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  aria-label="Refresh the data in the table"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  aria-label="Export the contents of the table"
                  v-b-popover.hover.top.d500="'Export the contents of the table'"
                >
                  <i class="fa fa-file-export pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
                </b-button>
                <b-button
                  @click.prevent="onAddNewJobDetailClick"
                  class
                  variant="link"
                  aria-label="Add Job Detail"
                  v-b-popover.hover.top.d500="'Add Job Detail'"
                  :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
                >
                  <i class="fa fa-plus pointer dimmedIcon"></i><span class="sr-only">Add New Job Detail</span>
                </b-button>
              </div>
              <div slot="table-actions-bottom">
                <div class="row" style="padding: 8px 0 8px 8px;">
                  <div class="col-md-2">
                    <button
                      class="btn btn-outline-primary"
                      @click.prevent="onAddNewJobDetailClick"
                      :disabled="currentSubmissionCount > 0 || !canManage"
                    >Add Job Detail</button>
                  </div>
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

      <div class="idb-block-footer">
        <!-- <b-button class="mr-2" variant="primary" @click="onRunClick()">Run</b-button> -->
        <!-- <b-button variant="primary" @click="onSave()" :disabled="isLoading">Save</b-button> -->

        <button
          @click="runJob"
          class="btn btn-success mr-2"
          :disabled="isLoading  || currentSubmissionCount > 0"
          v-if="canRunSub && canSubmit && currentSubmissionCount == 0"
        >
          <i class="fa fa-play mr-2"></i>Create Submission
        </button>

        <button
          @click="GoToJob"
          class="btn btn-success mr-2"
          :disabled="isLoading  || currentSubmissionCount == 0"
          v-if="canRunSub && canSubmit && currentSubmissionCount > 0"
        >
          <i class="fa fa-play mr-2"></i>Go To Submission
        </button>

        <b-button
          class="float-right"
          variant="danger"
          @click="onDeleteClick()"
          :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
        >Delete</b-button>

        <button
          class="btn btn-outline-primary"
          type="button"
          @click="onRenameBureauJob"
          :disabled="isLoading || currentSubmissionCount > 0 || !canManage"
        >
          <i class="fas fa-edit rpad"></i>Rename Job
        </button>

        <button
          class="btn btn-outline-primary ml-3"
          type="button"
          @click="onShowCloneModal"
          :disabled="isLoading || !canManage"
        >
          <i class="glyphicon ti-layers rpad"></i>Clone Bureau Job
        </button>

        <button
          v-if="enableUpdateButton"
          class="btn btn-outline-danger ml-1"
          @click.stop="onSave()"
          :disabled="isLoading || currentSubmissionCount > 0"
        >Update Bureau Group</button>
      </div>
    </div>

    <b-modal lazy size="lg" id="customerselector" title="Select Bureau Customer(s)" hide-footer>
      <bureau-customer-selector
        :customerIdsInJob="getCustomerIdsFromRows()"
        @hideCustomerSelector="hideCustomerSelector"
      ></bureau-customer-selector>
    </b-modal>

    <div>
      <addBureauJobDetailModal
        :bureauJobId="bureauJob.bureauJobId"
        :bureauCustomers="bureauCustomers"
        v-on:submit="addBureauJobDetailClosed"
      ></addBureauJobDetailModal>
    </div>
    <div>
      <editBureauJobDetailModal
        :bureauJobId="bureauJob.bureauJobId"
        :bureauJobDetailId="bureauJobDetailId"
        :bureauCustomers="bureauCustomers"
        :duplicateMode="duplicateMode"
        v-on:submit="editBureauJobDetailClosed"
      ></editBureauJobDetailModal>
    </div>
    <div>
      <cloneModal :bureauJobId="bureauJob.bureauJobId" v-on:submit="cloneModalClosed"></cloneModal>
    </div>
    <div>
      <renameModal
        :current-name="bureauJob.name"
        :bureauJobId="bureauJob.bureauJobId"
        @jobRenamed="jobRenamed"
        v-on:submit="renameModalClosed"
      ></renameModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import BureauCustomerSelector from '@/Components/Bureau/BureauCustomer/BureauCustomerSelector.vue'
// import BureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/BureauJobDetail.vue'
import AddBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/AddBureauJobDetail.vue'
import EditBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/EditBureauJobDetail.vue'
import CloneModal from '@/Components/Bureau/BureauJobsAdministration/CloneModal.vue'
import RenameModal from '@/Components/Bureau/BureauJobsAdministration/RenameModal.vue'

import uuid from 'uuid/v4'
import _ from 'lodash'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'

// Validation helpers
import { maxLength, requiredIf, not, sameAs } from 'vuelidate/lib/validators'
import { cu } from '@fullcalendar/core/internal-common'

import papa from 'papaparse'
import { mapGetters } from 'vuex'
import roles from '@/Assets/Constants/roles'

export default {
  props: {
    id: String
  },

  mixins: [Utility, loading, tableFilterMixin, bacsMixin],
  components: {
    BureauCustomerSelector,
    // BureauJobDetail,
    addBureauJobDetailModal: AddBureauJobDetail,
    editBureauJobDetailModal: EditBureauJobDetail,
    cloneModal: CloneModal,
    renameModal: RenameModal
  },
  data () {
    return {
      groups: [],
      bureauCustomers: [],
      bureauCustomerDisplay: [],
      bureauJobDetailId: '',
      duplicateMode: 0,
      selectedJobDetailRecord: {},
      selectedJobDetailRecordId: '',
      bureauJob: {},
      originalGroupId: '',
      enableUpdateButton: false,
      rows: [],
      totalRecords: 0,
      columns: [
        {
          label: 'Job Details Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          label: 'Bureau Customer Id',
          field: 'bureauCustomerId',
          hidden: true
        },
        {
          label: 'Customer Name (SUN)',
          field: 'customerName'
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          hidden: true
        },
        {
          label: 'Payment File/URL',
          field: 'filename'
        },
        {
          label: 'Wildcard',
          field: 'hasWildcard',
          tdClass: 'text-center'
        },
        {
          label: 'Import Schema',
          field: 'importSchema'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }]
      },

      loading: false,
      cloning: false,
      mappings: [],
      currentSubmissionCount: 0,
      createRights: []
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.state.common.paygateId
    },
    gridInfo () {
      return document.getElementById('jobDetailsTable')
    },
    canRunSub () {
      return this.createRights.length > 0 && this.createRights.filter(x => x.groupId === this.bureauJob.groupId).length > 0
    },
    canSubmit () {
      return this.$store.getters.isInRole('SubmitBureauJobs')
    },
    canManage () {
      return this.$store.getters.isInRole('ManageBureauJobs')
    },
    isFps () {
      return this.groups.filter(x=> { return x.groupId === this.bureauJob.groupId && x.groupType === 6 }).length > 0
    }
  },
  async created () {
    // await this.load()
  },
  async mounted () {
    // Register modal events
    this.$root.$on('bv::modal::shown', async (bvEvent, modalId) => {
      if (bvEvent.target.id === 'bureauJobDetailModal') {
        console.info('bureau job detail modal dialog shown')
        // this.$refs.bureauJobDetailComponent.$v.$reset()
        this.$refs.bureauJobDetailComponent.onFilenameChanged()
      }
    })
    this.mappings = await this.loadMappings('')
    var rightsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.selectedCustomer}/1/`, { params: { rights: ['Create'] } })
    var fpsRightsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.selectedCustomer}/6/`, { params: { rights: ['Create'] } })

    this.createRights = rightsResponse.data.concat(fpsRightsResponse.data)
  },
  methods: {
    cu,
    jobRenamed (e) {
      this.bureauJob.name = e
    },
    load:
      _.debounce(async function () {
        await this.getBureauJobData()
        await this.getBureauJobDetails()
        await this.getBureauCustomerData()
        await this.getSubmissionCount()
      }, 800),
    async getSubmissionCount () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getcountforjob/${this.id}`, {
          showload: true,
          params: { paygateid: this.paygateId }
        })
        console.log('GetCountForJob', response)
        this.currentSubmissionCount = response.data
      } catch (e) {
      }
    },
    ChangeGroup (groupId) {
      if (this.bureauJob.groupId !== this.originalGroupId) {
        this.enableUpdateButton = true
      } else {
        this.enableUpdateButton = false
      }
    },
    async getBureauJobDetails () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...this.buildGoodTableQuery(), id: this.id }, showload: true })
        this.totalRecords = response.data.meta.totalItems
        this.rows = response.data.data
      } catch (e) { } finally {
        this.isTableLoading = false
      }
    },
    async exportTable () {
      try {
        this.isTableLoading = true
        const query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        query.forExport = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...query, id: this.id }, showload: true })
        var output = response.data
        for (var i = 0; i < output.length; i++) {
          switch (output[i].importSchema) {
            case 'Legacy':
            case 'Standard18':
              break
            default:
              output[i].importSchema = this.getMappingName(output[i].importSchema)
              break
          }
        }
        var csvString = papa.unparse(output)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `${this.bureauJob.name}-job-details.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `${this.bureauJob.name}-job-details.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) { } finally {
        this.isTableLoading = false
      }
    },

    async getGroups () {
      const getGroupsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/1/`, { params: { rights: ['Create'] } })

      var arr = []
      for(var i = 0; i < getGroupsResponse.data.length; i++){
        arr.push(getGroupsResponse.data[i])
      }
      try {
        const getFpsGroupsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/6/`, {params: {rights: ['Create']}})
        for (var i = 0; i < getFpsGroupsResponse.data.length; i++) {
          getFpsGroupsResponse.data[i].name = `${getFpsGroupsResponse.data[i].name} (Faster Payments)`
          arr.push(getFpsGroupsResponse.data[i])
        }
      } catch (e) {
        console.log('Could not load FPS groups')
      }
      arr.sort(function (a, b) { return a.name.localeCompare(b.name) })
      this.groups = arr
    },

    async getBureauJobData () {

      await this.getGroups()

      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJob/' + this.id, { showload: true })
      this.bureauJob = response.data
      this.originalGroupId = this.bureauJob.groupId
    },

    async getBureauCustomerData () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaucustomerlist', { showload: true })

      this.bureauCustomers = response.data

      this.bureauCustomerDisplay = []

      var dummyCustomer = {
        id: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
        name: '',
        disabled: false
      }

      this.bureauCustomerDisplay.push(dummyCustomer)

      var index = 0
      for (index = 0; index < this.bureauCustomers.length; ++index) {
        var current = {
          id: this.bureauCustomers[index].bureauCustomerId,
          name: this.bureauCustomers[index].display,
          disabled: false
        }
        this.bureauCustomerDisplay.push(current)
      }
    },

    onAddNewJobDetailClick () {
      this.$bvModal.show('add-bureau-job-detail')
    },

    async addBureauJobDetailClosed () {
      await this.getBureauJobDetails()
    },

    onEditJobDetailClick () {
      this.$nextTick(function () {
        this.duplicateMode = 0
        this.$bvModal.show('edit-bureau-job-detail')
      })
    },

    async editBureauJobDetailClosed () {
      this.$nextTick(async function () {
        this.duplicateMode = 0
        await this.getBureauJobDetails()
      })
    },

    onRenameBureauJob () {
      this.$bvModal.show('rename-bureau-job')
    },

    onShowCloneModal () {
      this.$bvModal.show('clone-bureau-job')
    },
    FormatCustomerName (field) {
      if (field === ' ()') return 'Not Set'
      else return field
    },
    async renameModalClosed () {
      this.$router.push({ name: 'ManageBureauJobs' })
    },
    async cloneModalClosed () {
      this.$router.push({ name: 'ManageBureauJobs' })
    },
    showModal () {
      this.bureauJob.clonedName = `${this.bureauJob.name} Copy`
      this.$refs['clone-modal'].show()
      this.cloning = true
    },
    resetModal () {
      this.cloning = false
      this.bureauJob.clonedName = ''
    },
    async cloneBureauJob (e) {
      this.$v.bureauJob.clonedName.$touch()
      e.preventDefault()
      if (this.$v.bureauJob.clonedName.$invalid) {
        this.$toastr.e('There are validation errors on the form', 'Validation')
      } else {
        this.onSave()
      }
    },

    onCellClick (params) {
      if (params.column.field !== 'actions' && this.currentSubmissionCount === 0) {
        this.$nextTick(function () {
          this.duplicateMode = 0
          console.log('duplicateMode', this.duplicateMode)
          this.bureauJobDetailId = params.row.bureauJobDetailId
          this.$bvModal.show('edit-bureau-job-detail')
        })
      }
    },

    async onDeleteJobDetailClick (row) {
      var _name = row.customerName
      var _filename = row.filename
      var messageText = _name + ' filename:' + _filename + ''
      const swalResult = await swal.fire({
        title: 'Delete Bureau Job Detail',
        html: 'Are you sure you want to <strong>delete</strong> this job detail for <strong>' + messageText + '</strong>?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deletebureaujobdetail/' + row.bureauJobDetailId,
            {
              showerror: true,
              errormessage: 'Failed deleting bureau job detail'
            })

          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          await this.getBureauJobDetails()
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        } finally {

        }
      }
    },

    async onDuplicateJobDetailClick (row) {
      var _name = row.customerName
      var _filename = row.filename
      var messageText = _name + ' filename:' + _filename + ''
      const swalResult = await swal.fire({
        title: 'Duplicate Bureau Job Detail',
        html: 'Are you sure you want to <strong>duplicate</strong> this job detail for <strong>' + messageText + '</strong>?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.warn,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        this.$nextTick(function () {
          this.duplicateMode = 1
          console.log('duplicatemode', this.duplicateMode)
          this.bureauJobDetailId = row.bureauJobDetailId
          // go open the modal
          this.$bvModal.show('edit-bureau-job-detail')
        })
      }
      // this.$nextTick(function () {
      //   this.duplicateMode = 0
      // })
    },

    async onDeleteClick () {
      const swalResult = await swal.fire({
        title: 'Delete Bureau Job',
        text: `Are you sure you want to delete the job: ${this.bureauJob.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deleteJob/' + this.bureauJob.bureauJobId, { showload: true })
          .then(response => {
            this.$toastr.s(response.data.toastMessage, 'Bureau Job')
            this.$router.push({ name: 'ManageBureauJobs' })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    onRunClick () {
      this.$router.push({ name: 'JobSubmissionDetails', params: { id: this.bureauJob.bureauJobId, name: this.bureauJob.name } })
    },
    async onSave () {
      var duplicateCheckResult = this.duplicateCheck(this.rows)
      if (duplicateCheckResult.length !== 0) {
        swal.fire('Duplicate Job Records', 'Sorry, you have duplicate job records for: ' + [...duplicateCheckResult], 'warning')
        return
      }

      var data = {
        name: this.bureauJob.name,
        paygateId: this.paygateId,
        groupId: this.bureauJob.groupId,
        deleteSourceFiles: this.bureauJob.deleteSourceFiles,
        bureauJobDetails: this.rows,
        bureauJobId: this.bureauJob.bureauJobId,
        cloning: this.cloning,
        clonedName: this.bureauJob.clonedName
      }
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/SaveBureauJob', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
        if (this.cloning) {
          this.$refs['clone-modal'].close()
          this.$refs.cloneModal.close()

          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
        this.load()
      }
    },
    async onSaveAs () {
      const swalResult = await swal.fire({
        title: 'Enter a job name',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Save'
      })

      if (swalResult.isConfirmed) {
        this.bureauJob.name = swalResult.value
      } else {
        return
      }

      var data = {
        name: this.bureauJob.name,
        paygateId: this.paygateId,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows
      }

      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/saveJob', data, { showload: true })
        .then(response => {
          this.$toastr.s(`Bureau Job ${this.bureauJob.name} created`, 'Bureau Job')
          this.$store.commit('setBureauJob', response.data.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    showCustomerSelector () {
      this.$root.$emit('bv::show::modal', 'customerselector')
    },
    async hideCustomerSelector () {
      this.$root.$emit('bv::hide::modal', 'customerselector')
      // Process bureau customers selector
      // Process currently unallocated bureau customers
      var returnType = this.$store.getters.bureauCustomerSelectorReturnType
      if (returnType === 'AddUnallocated') {
        var query = { params: {} }
        query.params.fields = 'name,serviceUserNumber,customerReference,bureauCustomerId,defaultImportSchema,defaultImportMapping,defaultImportFilename'
        query.params.paygateId = this.paygateId
        query.params.groupId = this.bureauJob.groupId
        query.params.sort = 'name asc'
        query.params.perPage = 10000
        query.params.page = 1
        query.params.bureauCustomerList = 'bureauCustomerList'
        if (this.$store.getters.bureauCustomerSelectorFilter !== '') {
          query.params.searchFilter = this.$store.getters.bureauCustomerSelectorFilter
        }

        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`, query, { showload: true })
        const customers = response.data.data
        customers.forEach(customer => {
          var newRow = {
            bureauJobDetailId: uuid(),
            bureauCustomerId: customer.bureauCustomerId,
            customerName: `${customer.name} - (${customer.serviceUserNumber})`,
            customerReference: customer.customerReference,
            parsedFilename: customer.defaultImportFilename,
            importSchema: customer.defaultImportSchema,
            importMapping: customer.defaultImportMapping,
            serviceUserNumber: customer.serviceUserNumber
          }
          var found = this.rows.find(function (cust) {
            return cust.bureauCustomerId === newRow.bureauCustomerId
          })
          if (found === undefined) {
            this.rows.push(newRow)
          }
        })
      } else if (returnType === 'Selection') {
        var payload = []
        this.$store.getters.bureauCustomerSelectorSelection.forEach(customer => {
          var newRow = {
            JobId: this.id,
            bureauCustomerId: customer.bureauCustomerId,
            filename: customer.defaultImportFilename,
            importSchema: customer.defaultImportSchema,
            importMapping: customer.defaultImportMapping
          }
          payload.push(newRow)
        })
        if (payload.length > 0) {
          var data = JSON.stringify(payload)

          var result = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetailbulk', data, { showload: false })

          if (result.data.toastType === 2) {
            this.$toastr.s(result.data.toastMessage)
            await this.getBureauJobDetails()
          } else {
            this.$toastr.e('Could not bulk insert job details.')
          }
        } else {
          this.$toastr.w('No records added')
        }
      }
    },
    hideJobDetail () {
      this.$root.$emit('bv::hide::modal', 'bureauJobDetailModal')
      var returnType = this.$store.getters.bureauJobDetailsReturnType
      if (returnType === 'Ok') {
        // post updated value back into rows
        for (let index = 0; index < this.rows.length; index++) {
          const row = this.rows[index]
          if (row.bureauJobDetailId === this.selectedJobDetailRecord.bureauJobDetailId) {
            this.selectedJobDetailRecord.serviceUserNumber = this.selectedJobDetailRecord.bureauCustomer.serviceUserNumber
            this.selectedJobDetailRecord.parsedFilename = this.buildFilename(this.selectedJobDetailRecord)
            this.rows[index] = this.selectedJobDetailRecord
            break
          }
        }
      }
    },
    getCustomerIdsFromRows () {
      var bureauCustomerIds = []
      if (this.rows !== null) {
        this.rows.forEach(customer => {
          bureauCustomerIds.push(customer.bureauCustomerId)
        })
      }
      return bureauCustomerIds
    },
    buildFilename (data) {
      var parsedFilename = data.filename
      parsedFilename = parsedFilename.replace('[CUSTOMERREF]', data.customerReference)
      parsedFilename = parsedFilename.replace('[SUN]', data.serviceUserNumber)
      return parsedFilename
    },
    duplicateCheck (dataToCheck) {
      var object = {}
      var candidates = []
      var results = []

      // Check for duplicate bureau customers
      dataToCheck.forEach(function (item) {
        if (!object[item.customerName]) {
          object[item.customerName] = 0
        }
        object[item.customerName] += 1
      })

      for (var prop in object) {
        if (object[prop] >= 2) {
          candidates.push(prop)
        }
      }

      // Now check filenames for duplicate candidates
      candidates.forEach(candidate => {
        var filenames = {}
        var jobRows = this.rows.filter(o => o.customerName === candidate)
        jobRows.forEach(jobRow => {
          if (!filenames[jobRow.filename]) {
            filenames[jobRow.filename] = 0
          }
          filenames[jobRow.filename] += 1
        })
        for (var filename in filenames) {
          if (filenames[filename] >= 2) {
            results.push(candidate)
          }
        }
      })
      return results
    },
    getMappingName (mapping) {
      let mappingName = 'unknown'
      for (let i = 0; i < this.mappings.length; i++) {
        if (this.mappings[i].value === mapping) {
          mappingName = this.mappings[i].text
        }
      }
      return mappingName
    },
    runJob () {
      this.$router.push({ name: 'AutomatedBACSDetailSelection', params: { id: this.id } })
    },

    async GoToJob () {
      switch (this.bureauJob.lastScreenState) {
        case 'BureauManualBacsAddInitialFiles':
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: this.bureauJob.bureauSubmissionId } })
          break
        case 'BureauManualFPSAddInitialFiles':
          this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: this.bureauJob.bureauSubmissionId } })
          break
        case 'BureauManualBacsImporting':
          this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: this.bureauJob.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauManualFPSImporting':
          this.$router.push({ name: 'ManualFPSImportPaymentFiles', params: { id: this.bureauJob.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoBacsImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: this.bureauJob.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoFPSImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: this.bureauJob.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauBacsViewPayments':
          this.$router.push({ name: 'PaymentDetails', params: { id: this.bureauJob.bureauSubmissionId } })
          break
        case 'BureauFPSViewPayments':
          this.$router.push({ name: 'PaymentDetailsFPS', params: { id: this.bureauJob.bureauSubmissionId } })
          break
        case 'BureauActionsPage':
          var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${this.bureauJob.bureauSubmissionId}`, { showload: true })

          if (actionUrl.data) {
            this.$router.replace(returnRouteObject(actionUrl.data))
          } else {
            this.autoReturnToHomePage = true
            this.$router.push('/')
          }
          break
      }
    }

  },

  validations: {
    bureauJob: {
      clonedName: {
        required: requiredIf(() => {
          return 'bureauJob.cloning'
        }),
        maxLength: maxLength(50),
        notSameAs: not(sameAs('name'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input,
select {
  font-size: 100%;
  border: none;
  width: 100%;
}
</style>
