export default {
  ValidateCreditCard: 0,
  ValidateCreditCardExtended: 1,
  ValidateAccount: 2,
  GetBACSDetails: 3,
  GetFPSDetails: 4,
  GetCHAPSDetails: 5,
  GetCCCDetails: 6,
  ValidateIBAN: 7,
  ValidatePhoneNumber: 8,
  ValidateEmail: 9,
  ValidateCoP: 10
}
