import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=657ed3ba&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=657ed3ba&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('657ed3ba')) {
      api.createRecord('657ed3ba', component.options)
    } else {
      api.reload('657ed3ba', component.options)
    }
    module.hot.accept("./Dashboard.vue?vue&type=template&id=657ed3ba&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml", function () {
      api.rerender('657ed3ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Assets/Components/Dashboard/Dashboard.vue"
export default component.exports