<template>
  <div>
    <div class="idb-block">
        <div class="idb-block-title">Current Bulk Validation Sessions</div>
        <div class="idb-block-content">
          <vue-good-table
                ref="table"
                mode="remote"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-sort-change="onSortChange"
                :columns="columns"
                :rows="sessions"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :search-options="{
                  enabled: false
                }"
                :isLoading.sync="isTableLoading"
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                styleClass="vgt-table striped bordered"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Sessions Available</div>
                </div>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span><span class="sr-only">Clear filters</span>
                  </button>
                  <b-button
                    @click.prevent="load"
                    class
                    variant="link"
                    v-b-popover.hover.top.d500="'Refresh the data in the table'"
                  >
                    <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'controls'">
                    <b-button @click="deleteSession(props.row.sessionId)" variant="danger" class="w-100"><i class="fa fa-trash mr-2"></i>Delete</b-button>
                  </span>
                  <span v-else-if="props.column.field === 'status'">
                    <b-badge pill
                             :variant="getBadgeClass(props.row.status)" class="w-100"
                    >{{props.formattedRow[props.column.field]}}</b-badge>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
        </div>
      </div>
  </div>
</template>
<script>
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import auth from "@/Assets/Components/Authentication/auth"
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  export default {
    mixins: [loading, tableFilterMixin],
    data () {
      return {
        updateHub: null,
        sessions: [],
        serverParams: {
          sort: [{ field: 'uploadDate', type: 'desc' }]
        },
        columns: [
          {
            field: 'fileName',
            label: 'File Name'
          },
          {
            field: 'uploadType',
            label: 'Type',
            formatFn: this.readableType
          },
          {
            field: 'status',
            label: 'Status',
            formatFn: this.readableStatus
          },
          {
            field: 'uploadDate',
            label: 'Upload Date',
            formatFn: this.formatDate
          },
          {
            field: 'rowCount',
            label: 'Rows'
          },
          {
            field: 'userName',
            label: 'Uploading User'
          },
          {
            field: 'controls',
            label: '',
            sortable: false
          }
        ],
        selectedFiles: [],
        showModalFileBrowser: false,
        rights: null,
        bearerToken: null,
        expectedVersion: '1',
        dropzoneOptions: {
          maxFilesize: 250, // MB
          maxFiles: 4,
          acceptedFiles: '.csv',
          dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a delimited file such as a CSV file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
          url: `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload?paygateid=${this.paygateId}`,
          title: 'Drag and drop to upload files',
          subtitle: '...or click to select a file from your computer'
        },
        fileTypes: [
          { id: 0, label: 'Email Addresses' },
          { id: 1, label: 'Phone Number' },
          { id: 4, label: 'Bank Account' },
          { id: 2, label: 'Confirmation Of Payer' },
          // { id: 3, label: 'Person At Address' },
        ],
        fileType: 0,
        sessionId: '',
        intervalId: null
      }
    },
    async mounted () {
      this.dropzoneOptions.url = `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload?paygateid=${this.paygateId}`
      this.removeAllFiles()
      window.addEventListener('beforeunload', this.stopSocketListener)
      auth.getAccessToken()
        .then(token => {
          this.bearerToken = 'Bearer ' + token
          this.updateHub = new HubConnectionBuilder().withUrl(this.updateHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
          this.updateHub.serverTimeoutInMilliseconds = 3600000
          this.updateHub.start()
          this.updateHub.on('Refresh', data => {
            this.load()
          })
        })
    },
    beforeRouteLeave (to, from, next) {
      this.stopSocketListener()
      next()
    },
    computed: {
      paygateId () {
        return this.$store.state.common.paygateId
      },
      updateHubUrl () {
        return `${process.env.VUE_APP_VALIDATE_API_URL}hubs/bulkuploadhub?sessionId=${this.paygateId}`
      }
    },
    watch: {
      paygateId (newId, oldId) {
        this.dropzoneOptions.url = `${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/upload?paygateid=${newId}`
      }
    },
    methods: {
      stopSocketListener () {
        if (this.updateHub !== undefined && this.updateHub !== null) {
          this.updateHub.stop()
        }
      },
      formatDate (date) {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        var hour = date.getHours()
        var minutes = date.getMinutes()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${this.pad(hour, 2)}:${this.pad(minutes, 2)}`
      },
      pad (num, size) {
        var s = '00' + num
        return s.substr(s.length - size)
      },
      clear() {

      },
      readableType (input) {
        let output = ''
        switch (input) {
          case 0:
            output = 'Email Addresses'
            break
          case 1:
            output = 'Phone Number'
            break
          case 2:
            output = 'Confirmation Of Payer'
            break
          case 3:
            output = 'Person At Address'
            break
          case 4:
            output = 'Bank Account'
            break
        }
        return output
      },
      readableStatus(input) {
        let output = ''
        switch(input) {
          case 0:
            output = 'Uploaded'
            break
          case 1:
            output = 'Mapping'
            break
          case 2:
            output = 'Validating'
            break
          case 3:
            output = 'Complete'
            break
          case 4:
            output = 'Error'
            break
          default:
            break
        }
        return output
      },
      removeAllFiles () {
        this.selectedFiles = []
      },
      fileAdded (file) {
        this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
      },
      fileRemoved (file, error, xhr) {

      },
      sendingEvent (file, xhr, formData) {
        formData.append('paygateId', this.$store.state.common.paygateId)
        formData.append('fileType', this.fileType)
      },
      afterSuccess (file, response) {
        this.sessionId = response.sessionId
      },
      addFile (file) {
        const fileObj = { type: this.rights.uploadType, path: '', fileName: file.filename, id: file.submissionId }
        this.selectedFiles.push(fileObj)
      },
      async load () {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation`, { params: { ...this.buildGoodTableQuery() } })
        this.sessions = response.data.data
        this.totalRecords = response.data.totalRecords
        this.isTableLoading = false
      },
      getBadgeClass (value) {
        let output = 'success'
        switch (value) {
          case 0:
            // uploaded
            output ='secondary'
            break
          case 1:
            // mapping
            output ='primary'
            break
          case 2:
            // validating
            output ='info'
            break
          case 3:
            // complete
            output ='success'
            break
          case 4:
            // error
            output ='danger'
            break
        }
        return output
      },
      async deleteSession (sessionId) {
        let response = await axios.delete(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/${sessionId}`)
        if (response.status === 200) {
          this.$toastr.s('Session deleted')
          await this.load()
        } else {
          this.$toastr.e('Could not delete session')
        }
      },
      onRowClick (e) {

      },
      onCellClick (e) {
        if (e.column.field !== 'controls') {
          this.$router.push({name: 'validationSession', params: {sessionId: e.row.sessionId}})
        }
      },
      async commenceValidation (e) {
        let response = await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/interpret/${this.sessionId}`)
        if (response.status === 200) {
          this.$router.push({ name: 'validationSession', params: { sessionId: this.sessionId } })
        } else {
          this.$toastr.e('Could not continue with validation')
        }
      }
    }
  }
</script>
<style scoped>
  a.dz-remove:hover, a.dz-remove:active, a.dz-remove:visited {
    color: #ffffff
  }
</style>
