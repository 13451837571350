import { render, staticRenderFns } from "./FolderManager.vue?vue&type=template&id=451e0290&scoped=true"
import script from "./FolderManager.vue?vue&type=script&lang=js"
export * from "./FolderManager.vue?vue&type=script&lang=js"
import style0 from "./FolderManager.vue?vue&type=style&index=0&id=451e0290&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451e0290",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('451e0290')) {
      api.createRecord('451e0290', component.options)
    } else {
      api.reload('451e0290', component.options)
    }
    module.hot.accept("./FolderManager.vue?vue&type=template&id=451e0290&scoped=true", function () {
      api.rerender('451e0290', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Workflow/Config/FolderManager.vue"
export default component.exports