var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "standard18" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("b-form-textarea", {
            attrs: { readonly: "true", rows: 10 },
            model: {
              value: _vm.standard18,
              callback: function($$v) {
                _vm.standard18 = $$v
              },
              expression: "standard18"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _c("div", { staticClass: "col-md-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", disabled: this.moreStnd18 === false },
            on: { click: _vm.getMoreStandard18 }
          },
          [_vm._v("Get More Standard18")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }