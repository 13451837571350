<template>
  <div id="presubValResults">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Bankline Validation Summary
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div v-if="this.validationResponse.totalMessages > 0" class="col-md-12">
                <vue-good-table
                  mode="remote"
                  @on-row-click="onRowClick"
                  :columns="columns"
                  :rows="rows"
                  :sort-options="{ enabled: false }"
                  styleClass="vgt-table striped bordered"
                >
                  <div slot="table-actions" class="dropdown">
                    <a class="btn btn btn-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    v-b-popover.hover.top.d500="'Download pre-sub val messages'">
                      <i class="fa fa-file-alt pointer dimmedIcon pointer"></i><span class="sr-only">Expand Menu</span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" @click.prevent="createPresubPdfReport" href="#"><i class="fas fa-file-csv faIconGray"></i> Create Pre-sub validation messages PDF report</a>
                      <a class="dropdown-item" @click.prevent="createPresubPaymentsPdfReport" href="#"><i class="fas fa-file-alt faIconGray"></i> Create Pre-sub payments PDF report</a>
                      <a class="dropdown-item" @click.prevent="createPresubCsvReport" href="#"><i class="fas fa-file-csv faIconGray"></i> Create Pre-sub validation CSV file</a>
                      <a class="dropdown-item" @click.prevent="createPresubPaymentsCsvReport" href="#"><i class="fas fa-file-alt faIconGray"></i> Create Pre-sub payments CSV file</a>
                    </div>
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'messageSeverity'">
                      <span
                        v-bind:class="getSeverityClass(props.row.messageSeverity)"
                        class="badge badge-pill pull-left"
                      >{{props.row.messageSeverity}}</span>
                    </span>
                    <span v-else-if="props.column.field === 'hyperlink'">
                      <span class="viewLink">View Items</span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            <div class="row form-group">
              <div v-if="this.validationResponse.banklineValidationSummaries !== undefined && this.validationResponse.banklineValidationSummaries.length > 1" class="col-md-12">
                <button
                  id="viewAll"
                  type="button"
                  class="btn btn-outline-secondary pull-right"
                  v-on:click="viewAll"
                >View All Messages</button>
              </div>
            </div>
            <div class="row form-group">
              <div v-if="this.validationResponse.totalMessages === 0" class="col-md-12">
                <b-card bg-variant="success" text-variant="white" class="text-center">
                  <b-card-text>Pre-submission validation completed without issue.</b-card-text>
                </b-card>
                <br />
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button v-if="this.validationResponse.validationResult !== 'Fixes' && this.validationResponse.validationResult !== 'Aborted'"
              id="save"
              type="button"
              class="btn btn-primary"
              :disabled="this.saveStarted === true"
              v-on:click="startSave"
            >Process Submission</button>
            <button v-if="this.validationResponse.validationResult !== 'Valid'"
              id="edit"
              type="button"
              v-bind:class="getButtonClass()"
              :disabled="this.saveStarted === true"
              v-on:click="editPayments"
            >Edit Payments</button>
            <div v-show="saveStarted" class="row form-group"></div>
            <div v-show="saveStarted" class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
                  <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import groupTypes from '@/Assets/Constants/groupTypes'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  name: 'validationResult',
  mixins: [
    bacsMixin
  ],
  data () {
    return {
      submissionData: {},
      columns: [
        {
          label: 'Severity',
          field: 'messageSeverity',
          width: '100px'
        },
        {
          label: 'Message',
          field: 'severitySummaryMessage'
        },
        {
          label: '',
          field: 'hyperlink'
        }
      ],
      rows: [],
      validationResponse: {},
      saveStarted: false,
      cannotNavToNextPage: false,
      max: 100,
      progress: 0,
      getTaskProgressUrl: '',
      endTaskUrl: '',
      getActionUrl: '',
      groupBankAccount: {},
      importFileResponse: {},
      bacsGroup: {},
      retrieveResult: false,
      paygateType: '',
      vol1ServiceUserNumber: '',
      autoReturnToHomePage: false,
      helpUrl: '',
      processingDate: ''
    }
  },

  methods: {
    getSeverityClass (severity) {
      if (severity === 'Fix') {
        return 'badge-danger'
      } else if (severity === 'Duplicate') {
        return 'badge-warning'
      } else {
        return 'badge-' + severity.toLowerCase()
      }
    },

    getButtonClass () {
      if (this.validationResponse.validationResult === 'Warnings') {
        return 'btn btn-outline-primary ml-3'
      } else {
        return 'btn btn-primary ml-3'
      }
    },

    onRowClick (params) {
      // Show Bankline validation messages.
      this.$router.push('/bankline/banklinepayments/viewValidationMessages?messageSeverity=' + params.row.messageSeverity)
    },

    editPayments () {
      this.$router.push('/bankline/banklinepayments/viewImportedBanklinePayments?showValidationMessages=true')
    },

    startSave () {
      this.saveStarted = true
      this.cannotNavToNextPage = true

      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        groupId: this.submissionData.groupId,
        reference: this.submissionData.reference,
        bankAccountId: this.groupBankAccount.bankAccountId,
        createAction: true,
        usedFtpFileBrowser: this.submissionData.usedFtpFileBrowser,
        ftpFilename: this.submissionData.filename
      })

      axios({
        method: 'POST',
        url: process.env.VUE_APP_BANKLINE_API_URL + 'bankline/submission/post/',
        data: json
      })

      const id = setInterval(() => {
        return axios.get(this.getTaskProgressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (response.data >= 100) {
              clearInterval(id)
              return axios.get(this.endTaskUrl, {
              })
                .then(response => {
                  if (response.data === '') {
                    this.$toastr.s('Bankline submission created')
                    // // HACK return to home page until we get Bankline actions implemented.
                    // this.autoReturnToHomePage = true
                    // this.cannotNavToNextPage = false
                    // this.$router.push('/')
                    return axios.get(this.getActionUrl, {
                    })
                      .then(response => {
                        this.cannotNavToNextPage = false
                        if (response.data.tag) {
                          var routeJson = {}
                          routeJson.id = response.data.messageId
                          routeJson.actionedObjectId = this.submissionData.submissionId
                          routeJson.actionClass = 'Bankline'
                          routeJson.actionSubClass = 'Approve'
                          this.$router.replace(returnRouteObject(routeJson))
                        } else {
                          this.autoReturnToHomePage = true
                          this.$router.push('/')
                        }
                      })
                  } else {
                    this.saveStarted = false
                    this.cannotNavToNextPage = false
                    this.progress = 0
                    this.$swal('Save Failed.', response.data, 'error')
                  }
                })
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    },

    async loadItems () {
      let response = await axios.get(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/validation/getValidationResult?submissionId=${this.submissionData.submissionId}`)
      this.validationResponse = response.data
      if (this.validationResponse.result !== 3) { // I.e. not aborted.
        if (this.validationResponse.totalMessages > 0) {
          this.rows = this.validationResponse.banklineValidationSummaries
          this.$store.dispatch('setValidationResponse', this.validationResponse)
        }
      } else {
        this.$snapbar.e('Bankline validation failed')
      }
    },

    viewAll () {
      var messageSeverity = ''
      this.$router.push('/bankline/banklinepayments/viewValidationMessages?messageSeverity=' + messageSeverity)
    },

    async createPresubPdfReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationReport?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async createPresubPaymentsPdfReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubPaymentsReport?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async createPresubCsvReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubValidationCsvFile?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    },

    async createPresubPaymentsCsvReport () {
      try {
        var url = process.env.VUE_APP_BACS_API_URL + 'bacs/report/createPresubPaymentsCsvFile?submissionId=' + this.submissionData.submissionId + '&submissionReference=' + this.submissionData.reference
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (this.cannotNavToNextPage) {
      return
    }
    if (this.autoReturnToHomePage || to.path.endsWith('banklineActionWrapper') ||
        to.path.endsWith('viewValidationMessages') || to.path.endsWith('viewAllValidationMessages') || to.path.endsWith('viewImportedBanklinePayments')) {
      next()
    } else {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'Your submission details will be lost. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    }
  },

  async mounted () {
    this.submissionData = this.$store.getters.submissionData
    this.groupBankAccount = this.$store.getters.groupBankAccount
    this.importFileResponse = this.$store.getters.importFileResponse
    this.bacsGroup = this.$store.getters.bacsGroup

    if (this.submissionData.filename !== undefined && this.submissionData.filename !== null && this.submissionData.filename !== 'N/A') {
      this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionfromfile/'
    } else {
      this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/faster-payments/createfpssubmissionusingeditor/'
    }

    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndSaveTask?submissionId=' + this.submissionData.submissionId
    this.getActionUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getAction?submissionId=' + this.submissionData.submissionId

    this.retrieveResult = this.$route.query.retrieveResult
    if (this.retrieveResult === undefined || this.retrieveResult === 'true') {
      await this.loadItems()
      this.retrieveResult = false
    } else {
      this.validationResponse = this.$store.getters.validationResponse
      this.rows = this.validationResponse.banklineValidationSummaries
    }
  }
}
</script>
<style lang="scss">
  .viewLink {
    color: blue;
    text-decoration: underline;
  }

.dark-mode {
  .viewLink {
    color: #888888;
    text-decoration: underline;
  }
}
</style>
