import axios from 'axios'

const state = {
  parentBanklineDataLoaded: false,
  viewBanklineSubmissionData: {},
  banklineSubmission: {},
  banklinePayments: {},
  banklineSubmissionHistory: {},
  banklineValidationMessages: {},
  numberOfWarnings: 0,
  validationResponse: {}
}

const mutations = {
  setParentBanklineDataLoaded (state, parentBanklineDataLoaded) {
    state.parentBanklineDataLoaded = parentBanklineDataLoaded
  },

  setViewBanklineSubmissionData (state, viewBanklineSubmissionData) {
    state.viewBanklineSubmissionData = viewBanklineSubmissionData
  },
    
  setBanklineSubmission (state, banklineSubmission) {
    state.banklineSubmission = banklineSubmission
  },
    
  setBanklinePayments (state, banklinePayments) {
    state.banklinePayments = banklinePayments
  },
    
  setBanklineSubmissionHistory (state, banklineSubmissionHistory) {
    state.banklineSubmissionHistory = banklineSubmissionHistory
  },
    
  setBanklineValidationMessages (state, banklineValidationMessages) {
    state.banklineValidationMessages = banklineValidationMessages
  },
    
  setNumberOfWarnings (state, numberOfWarnings) {
    state.numberOfWarnings = numberOfWarnings
  },
    
  setValidationResponse (state, validationResponse) {
    state.validationResponse = validationResponse
  }  
}

const getters = {
  parentBanklineDataLoaded: state => {
    return state.parentBanklineDataLoaded
  },

  viewBanklineSubmissionData: state => {
    return state.viewBanklineSubmissionData
  },

  banklineSubmission: state => {
    return state.banklineSubmission
  },
  
  banklinePayments: state => {
    return state.banklinePayments
  },
  
  banklineSubmissionHistory: state => {
    return state.banklineSubmissionHistory
  },
  
  banklineValidationMessages: state => {
    return state.banklineValidationMessages
  },
  
  numberOfWarnings: state => {
    return state.numberOfWarnings
  },

  validationResponse: state => {
    return state.validationResponse
  }
}

const actions = {
  setParentBanklineDataLoaded ({ commit }, parentBanklineDataLoaded) {
    commit('setParentBanklineDataLoaded', parentBanklineDataLoaded)
  },

  setViewBanklineSubmissionData ({ commit }, viewBanklineSubmissionData) {
    commit('setViewBanklineSubmissionData', viewBanklineSubmissionData)
  },

  setBanklineSubmission ({ commit }, banklineSubmission) {
    commit('setBanklineSubmission', banklineSubmission)
  },

  setBanklinePayments ({ commit }, banklinePayments) {
    commit('setBanklinePayments', banklinePayments)
  },

  setBanklineSubmissionHistory ({ commit }, banklineSubmissionHistory) {
    commit('setBanklineSubmissionHistory', banklineSubmissionHistory)
  },

  setBanklineValidationMessages ({ commit }, banklineValidationMessages) {
    commit('setBanklineValidationMessages', banklineValidationMessages)
  },

  setNumberOfWarnings ({ commit }, numberOfWarnings) {
    commit('setNumberOfWarnings', numberOfWarnings)
  },

  setValidationResponse ({ commit }, validationResponse) {
    commit('setValidationResponse', validationResponse)
  }  
}

export default {
  state,
  mutations,
  getters,
  actions
}
