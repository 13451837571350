var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "payments" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "vue-good-table",
            {
              ref: "payments",
              attrs: {
                mode: "remote",
                columns: _vm.columns,
                rows: _vm.rows,
                totalRows: _vm.totalRecords,
                "pagination-options": {
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false,
                  setCurrentPage: _vm.serverParams.page
                },
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-per-page-change": _vm.onPerPageChange,
                "on-column-filter": _vm.onColumnFilter
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field === "displayProcessingDate"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(props.row.displayProcessingDate)
                              )
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Shows a summary of the payments",
                          expression: "'Shows a summary of the payments'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createPaymentReport($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-pdf pointer dimmedIcon"
                      }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Create Payment Report")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export payment details to CSV",
                          expression: "'Export payment details to CSV'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createPaymentCsvFile($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file-excel pointer dimmedIcon"
                      }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Export Table")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Reset payment table options and filters",
                          expression:
                            "'Reset payment table options and filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.refreshGrid($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Refresh Table")
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.bacsSubmission.paygateType === "Bureau"
        ? _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("hr"),
              _c("strong", { staticClass: "d-block mb-2" }, [
                _vm._v("Contras")
              ]),
              _c("vue-good-table", {
                ref: "contras",
                attrs: {
                  mode: "remote",
                  columns: _vm.contraColumns,
                  rows: _vm.contraRows,
                  totalRows: _vm.contraTotalRecords,
                  "pagination-options": {
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
                    setCurrentPage: _vm.contraServerParams.page
                  },
                  styleClass: "vgt-table striped bordered"
                },
                on: {
                  "on-page-change": _vm.onContraPageChange,
                  "on-sort-change": _vm.onContraSortChange,
                  "on-per-page-change": _vm.onContraPerPageChange,
                  "on-column-filter": _vm.onContraColumnFilter
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }