// Import the main application files
import 'es6-promise/auto'
import 'babel-polyfill'
import 'innersvg-polyfill/innersvg'
import Vue from 'vue'
import App from '@/App'
import store from '@/Store/index'
import BootstrapVue from 'bootstrap-vue'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import VueSweetAlert from 'vue-sweetalert'
import Toastr from 'vue-toastr'
import SnapBar from '@/Assets/Components/Snapbar/VueSnapBar'
import VueGoodTable from '@/Assets/Components/Tables/AccessibleTable/src'
import Vuelidate from 'vuelidate'
import PrettyCheckBox from '@/Assets/Components/Inputs/src/plugin'
import VueSelect from 'vue-select'
import InputTag from 'vue-input-tag'
import VueDropzone from 'vue2-dropzone'
import VueMask from 'v-mask'
import vueDebounce from 'vue-debounce'
import VueMomentLib from 'vue-moment-lib'
import Diagram from '@/Components/Workflow/Workflow/Designer'
import MappingDesigner from '@/Components/Workflow/Mapping/MappingDesigner'
import LightTimeline from 'vue-light-timeline'
import VModal from 'vue-js-modal'
import VueCalendarHeatmap from 'vue-calendar-heatmap'
import VuejsDatepicker from 'vuejs-datepicker'
import VueCurrencyInput from 'vue-currency-input'
import VDraggable from 'vue-sortable-list'
import VueProgressBar from 'vue-progressbar'
// import VueTimepicker from 'vue2-timepicker'
import VueGtag from 'vue-gtag'

import '@/Assets'

// Import CSS and adminify scripts
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/Lib/adminifyScripts'
import '@/Lib/adminifycss'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue-swatches/dist/vue-swatches.min.css'
import 'flag-icon-css/css/flag-icon.css'
import 'vue-select/dist/vue-select.css'
import 'vue2-timepicker/dist/VueTimepicker.css'

// Import Assets components
import '@/registerServiceWorker'
import Loading from '@/Assets/Components/Loading/LoadingFullPage'
import tableActions from '@/Assets/Components/Tables/TableActions'
import validationMessages from '@/Assets/Components/Validation/ValidationMessages'
import '@/Assets/Filters/dateFilter.js'
import '@/Assets/Filters/currencyFilter.js'
import '@/Assets/Filters/numberFilter.js'
import '@/Assets/Directives/focus.js'
import '@/Assets/Filters/startCase.js'
import '@/Assets/Filters/substring.js'
import BackMixin from '@/Assets/Mixins/BackMixin'
import ValidationMixin from '@/Assets/Mixins/ValidationCheckMixin'
import Tick from '@/Assets/Components/Helpers/Tick'
import GroupSelect from '@/Assets/Components/Helpers/GroupSelect'
import FavouriteIcon from '@/Assets/Components/Helpers/FavouriteIcon'
import HelpIcon from '@/Assets/Components/Helpers/HelpIcon'
import avatar from '@/Assets/Components/Avatar'

// Import Axios, router
import axios from 'axios'
import router from '@/router'
import VueSanitize from 'vue-sanitize'
// Make vuex store in localstorage
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  var store = { common: state.common }
  localStorage.setItem('store', JSON.stringify(store))
})

// Add Vue usings
Vue.use(BootstrapVue)
Vue.use(VueSweetAlert)
Vue.use(Toastr)
Vue.use(SnapBar)
Vue.use(PrettyCheckBox)
Vue.use(VueBreadcrumbs)
Vue.use(VueGoodTable)
Vue.use(Vuelidate)
Vue.use(VueSelect)
Vue.use(InputTag)
Vue.use(VueDropzone)
Vue.use(VueMask)
Vue.use(vueDebounce)
Vue.use(VueMomentLib)
Vue.use(LightTimeline)
Vue.use(VModal)
Vue.use(VueCalendarHeatmap)
Vue.use(VuejsDatepicker)
Vue.use(VueCurrencyInput)
Vue.use(VDraggable)
Vue.use(VueProgressBar, {
  color: '#c7027f',
  failedColor: '#f74a55',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 500
  },
  location: 'top',
  inverse: false,
  position: 'fixed',
  autoFinish: false
})
// Vue.use(VueTimepicker)

// configure sanitize so it strips out all html.
const defaults = VueSanitize.defaults
defaults.allowedTags = defaults.allowedTags.filter(t => {
  return false
})
Vue.use(VueSanitize, defaults)

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_ANALYTICS_UID,
    params: {
      send_page_view: false
    },
    enabled: false
  }
})

// Add Vue global components
Vue.component('table-actions', tableActions)
Vue.component('loading', Loading)
Vue.component('vueDropzone', VueDropzone)
Vue.component('validation-messages', validationMessages)
Vue.component('input-tag', InputTag)
Vue.component('diagram', Diagram)
Vue.component('mappingDesigner', MappingDesigner)
Vue.component('vuejsDatepicker', VuejsDatepicker)
Vue.component('tick', Tick)
Vue.component('group-select', GroupSelect)
Vue.component('favourite-icon', FavouriteIcon)
Vue.component('help-icon', HelpIcon)
Vue.component('avatar', avatar)

// Add Mixins
Vue.mixin(BackMixin)
Vue.mixin(ValidationMixin)
Vue.mixin({
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    isGuid (value) {
      var regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')
      var result = regex.test(value)
      return result
    },
    orderCustomerListByName (customerList) {
      return customerList.sort((a, b) => {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    }
  }
})

Vue.directive('integer', {
  inserted (el) {
    el.oninput = (event) => {
      if (!/\d/.test(event.data) && event.data !== null && event.data !== undefined) {
        var resetEvent = document.createEvent('Event')
        resetEvent.initEvent('input', true, true)
        event.target.value = event.target.value.replace(/\D/g, '')
        event.target.dispatchEvent(resetEvent)
      }
    }
  }
})

Vue.config.productionTip = false

const embeddedId = document.querySelector('meta[name="embedded_id"]').getAttribute('content')
const date = new Date()
// Setup Axios
axios.defaults.headers.common['X-Paygate-Embedded'] = `${embeddedId}${date.getUTCDay()}${date.getUTCMonth()}${date.getUTCFullYear()}`
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.get.Accepts = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json; charset=utf-8'

// Fix for IE 11
axios.defaults.headers.Pragma = 'no-cache'

export const vueInstance = new Vue({
  el: '#app-client',
  router,
  store,
  template: '<App/>',
  components: {
    App,
    'vue-toastr': Toastr
  },
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  beforeMount: function () {
    // toastr global default settings
    this.$toastr.defaultPosition = 'toast-bottom-right'
    this.$toastr.defaultTimeout = 5000
  }
})
