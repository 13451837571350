<template>
  <div v-if="hasManageRole">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Workflow/Mapping Designer Settings
              <help-icon docPath="/automation/paygate-mapping/mapping-designer/designersettings/" />
              <favourite-icon></favourite-icon>
            </h2>
          </div>
            <div class="idb-block-content">
              <br />
              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-3">
                  <label for="autoSaveCheck">Enable Auto Save</label>
                </div>
                <div class="col-md-3">
                  <p-check
                    :labelId="'autoSaveCheck'"
                    name="check"
                    class="p-switch"
                    color="primary "
                    v-model="config.autosave"
                  >
                    <span class="ml-2"></span>
                  </p-check>
                </div>
              </div>
            </div>
          </div>
          <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Workflow Settings
            </h2>
          </div>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-3">Default Group</div>
                <div class="col-md-5">
                  <group-select v-model="config.selectedGroupId" :groups="options" :clearable="false"></group-select>
                </div>
              </div>
            </div>
              <br />
              <br />
              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-3">Default Service User</div>
                <div class="col-md-5">
                  <select class="form-control" v-model="config.selectedUserId">
                    <option
                      v-for="option in userOptions"
                      :key="option.id"
                      v-bind:value="option.value"
                    >{{option.label}}</option>
                  </select>
                </div>
              </div>
              <div style="line-height:600%;">
                <br />
              </div>
            <div class="idb-block-footer">
              <div class="row">
                <div class="col-md-12 col-sm-3">
                  <b-button @click.prevent="btnSave" :disabled="isLoading" variant="primary">
                    <i class="fa fa-save mr-2"></i>Save Settings
                  </b-button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from '@/Assets/Constants/roles'
export default {
  mixins: [loading],
  data: () => ({
    options: [],
    userOptions: [],
    paygateId: '',
    config: {},
    docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
  }),
  computed: {
    hasViewRole () {
      let hasRole = false

      try {
        hasRole = this.$store.getters.isInRole(roles.ViewMapping)
      } catch (e) {
        console.log(e.message)
      }
      try {
        if (!hasRole) hasRole = this.$store.getters.isInRole(roles.ViewWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      console.log('hasViewRole', hasRole)
      return hasRole
    },
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageMapping)
      } catch (e) {
        console.log(e.message)
      }
      try {
        if (!hasRole) hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      return hasRole
    }
  },
  methods: {
    async btnSave () {
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}designersettings`, this.config, { showload: true })
        this.$toastr.s('The settings were successfully updated.')
      } catch (e) {
        this.$snapbar.e(`Error saving configuration - ${e.message}`)
      }
    }
  },
  created: async function () {
    this.$snapbar.hide()
    let res
    try {
      res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}designersettings`, { showload: true })
      console.log(res)
      if (res && res.data) {
        this.config = res.data
      } else {
        this.$snapbar.e('Error retrieving Designer Settings')
      }
    } catch (e) {
      if (e.response && e.response.status === 403) {
        this.$snapbar.e('You are not authorised to view designer settings.')
      } else {
        this.$snapbar.e(`Could not return designer settings (${e.message})`)
      }
    }

    // Fetch Groups
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    if (!this.paygateId) {
      this.$snapbar.e('Error retrieving list of available groups. (002)')
      return
    }
    let groupRes
    try {
      groupRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups?sort=name:asc,&perPage=999&page=`)
      this.options = groupRes.data.data
    } catch (e) {
      this.$snapbar.e('Error getting list of available groups. (002)')
    }

    // Fetch users
    try {
      // const userRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users?sort=email:asc,&perPage=999`)
      const userRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}ServiceAccount/UserAccounts`, { params: { serviceAccountType: 'Workflow' } })
      console.log(userRes)
      if (userRes && userRes.data) {
        userRes.data.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        for (const user of userRes.data) {
          this.userOptions.push({
            label: user.label,
            value: user.id
          })
        }
      } else {
        this.$snapbar.e('Error getting list of available users.')
      }
    } catch (e) {
      this.$snapbar.e('Error retrieving list of available users.')
    }



  }
}
</script>

<style scoped>
.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  transition: opacity 0.6s;
}

.fadein-leave-active {
  transition: opacity 0.6s;
  opacity: 0;
}
</style>
