<template>
	<div>
		<div class="idb-block" v-if="groups.length > 0">
			<div class="idb-block-title">
				<h2>Groups</h2>
			</div>
			<div class="idb-block-content">
				<div class="container-fluid">
					<div v-for="(group,index) in groups" :key="group.name">
						<div v-if="group.groupType === groupTypes.bacs">
							<bacs-group-config-report :group="group" :bacsLicenceSettings="licenceSettings.bacs"></bacs-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.bacsOnline">
							<bacs-online-group-config-report :group="group" :bacsLicenceSettings="licenceSettings.bacs"></bacs-online-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.fps">
							<fps-group-config-report :group="group"></fps-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.fpsOnline">
							<fps-online-group-config-report :group="group" :fpsLicenceSettings="licenceSettings.fps"></fps-online-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.bureau">
							<bureau-group-config-report :group="group"></bureau-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.collections">
							<collections-group-config-report :group="group" :bacsLicenceSettings="licenceSettings.bacs"></collections-group-config-report>
						</div>
						<div v-else-if="group.groupType === groupTypes.bankline">
							<bankline-group-config-report :group="group" :bacsLicenceSettings="licenceSettings.bacs"></bankline-group-config-report>
						</div>

						<hr v-if="index !== groups.length -1" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// Components
import BacsGroupConfigReport from './BacsGroupConfigReport'
import BureauGroupConfigReport from './BureauGroupConfigReport'
import BacsOnlineGroupConfigReport from './BacsOnlineGroupConfigReport'
import CollectionsGroupConfigReport from './CollectionsGroupConfigReport'
import FpsGroupConfigReport from './FpsGroupConfigReport'
import FpsOnlineGroupConfigReport from './FpsOnlineGroupConfigReport'
import BanklineGroupConfigReport from './BanklineGroupConfigReport.vue'

// Constants
import groupTypes from '@/Assets/Constants/groupTypes'

export default {
	name: 'GroupConfigReport',
	props: {
		groups: {
			required: true,
			type: Array
		},
		licenceSettings: {
			type: Object
		}
	},
	data () {
		return {
			groupTypes
		}
	},
	components: {
		BacsGroupConfigReport,
		BureauGroupConfigReport,
		BacsOnlineGroupConfigReport,
		CollectionsGroupConfigReport,
		FpsGroupConfigReport,
		FpsOnlineGroupConfigReport,
		BanklineGroupConfigReport
	}
}
</script>

<style>
</style>
