<template>
  <div id="banklineSubmissionSummary">
    <div class="row">
      <div class="col-6">
        <table role="table" class="table table-striped pt-0 mt-0">
          <thead>
          <tr><th role="columnheader"><span class="sr-only">Unit</span></th><th role="columnheader">Items</th><th role="columnheader">Total</th></tr>
          </thead>
          <tbody>
          <tr><td role="rowheader"><strong>Credits</strong></td><td>{{ formatCount(banklineSubmission.totalNumberOfCredits) }}</td><td>£{{ formatValue(banklineSubmission.totalCreditValue) }}</td></tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table role="table" class="table table-striped pt-0 mt-0 tableNeedsPadding">
          <tbody>
            <tr><td role="rowheader"><strong>Payment Date</strong></td><td>{{paymentDate}}</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import moment from 'moment'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      // banklineSubmissionSummary: {},
      banklineSubmission: {},
      paymentDate: ''
    }
  },

  computed: {
  },

  methods: {
  },

  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    // this.banklineSubmissionSummary = this.$store.getters.banklineSubmissionSummary
    this.banklineSubmission = this.$store.getters.banklineSubmission
    this.paymentDate = moment(this.banklineSubmission.createdDate).format('DD/MM/YYYY')
  }
}
</script>
<style scoped>
  .tableNeedsPadding {
    position: relative;
    top: 3em;
  }
</style>
