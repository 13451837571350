var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"presubValResults"}},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"idb-block"},[_c('div',{staticClass:"idb-block-title"},[_c('h2',[_vm._v("Pre-sub Validation "+_vm._s(_vm.messageSeverity)+" Messages "),_c('span',{staticClass:"pull-right"},[_c('a',{attrs:{"href":_vm.helpUrl,"target":"_blank"}},[_c('i',{staticClass:"far fa-question-circle"})])])])]),_c('div',{staticClass:"idb-block-content"},[_c('div',{staticClass:"row form-group"},[(this.totalRecords > 0)?_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"lineNumbers":false,"totalRows":_vm.totalRecords,"pagination-options":{
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false,
                  jumpFirstOrLast: true
                },"sort-options":{
                  enabled: false
                },"styleClass":"vgt-table striped bordered"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-column-filter":_vm.onColumnFilter},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'messageSeverity')?_c('span',[_c('span',{staticClass:"badge badge-pill pull-left",class:_vm.getSeverityClass(props.row.messageSeverity)},[_vm._v(_vm._s(props.row.messageSeverity))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2532307069)})],1):_vm._e()])]),_c('div',{staticClass:"idb-block-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"id":"returnToSummary","type":"button"},on:{"click":_vm.returnToSummary}},[_vm._v("Back to Result Summary")])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }