import { render, staticRenderFns } from "./VgtHeaderRow.vue?vue&type=template&id=3e5c98c6"
import script from "./VgtHeaderRow.vue?vue&type=script&lang=js"
export * from "./VgtHeaderRow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e5c98c6')) {
      api.createRecord('3e5c98c6', component.options)
    } else {
      api.reload('3e5c98c6', component.options)
    }
    module.hot.accept("./VgtHeaderRow.vue?vue&type=template&id=3e5c98c6", function () {
      api.rerender('3e5c98c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Assets/Components/Tables/AccessibleTable/src/components/VgtHeaderRow.vue"
export default component.exports