var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          !_vm.$v.$invalid && _vm.saveCertificate()
        }
      }
    },
    [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" Add Customer Certificate "),
              _c("help-icon", {
                attrs: {
                  docPath:
                    "/administration/customer/certificates/addcertificate/"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          !_vm.selected
            ? _c("div", [
                _c("div", { staticClass: "row form-group" }, [
                  _c("div", { staticClass: "col-md-2 required" }, [
                    _vm._v("HSM Slots")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.hsmSlots },
                        on: { input: _vm.selectHsmSlot },
                        model: {
                          value: _vm.selectedSlotValue,
                          callback: function($$v) {
                            _vm.selectedSlotValue = $$v
                          },
                          expression: "selectedSlotValue"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm.slotSelected === true
                  ? _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2 required" }, [
                        _vm._v("HSM Slot Certificates")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.slotCertificates },
                            on: { input: _vm.selectSlotCertificate },
                            model: {
                              value: _vm.selectedCertificateValue,
                              callback: function($$v) {
                                _vm.selectedCertificateValue = $$v
                              },
                              expression: "selectedCertificateValue"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.selected
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.certificate.friendlyName.$error }
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "col-form-label col-md-3 required" },
                      [_vm._v("Friendly Name")]
                    ),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.certificate.friendlyName.$model,
                            expression: "$v.certificate.friendlyName.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        domProps: {
                          value: _vm.$v.certificate.friendlyName.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.certificate.friendlyName,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm.$v.certificate.friendlyName.$error
                        ? _c("span", { staticClass: "validation-messages" }, [
                            !_vm.$v.certificate.friendlyName.required
                              ? _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "Please enter a friendly name for this certificate"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]
                ),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Distinguished Name")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.distinguishedName,
                          expression: "certificate.distinguishedName",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.distinguishedName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "distinguishedName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Issuer")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.issuer,
                          expression: "certificate.issuer",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.issuer },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "issuer",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Serial Number")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.serialNumber,
                          expression: "certificate.serialNumber",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.serialNumber },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "serialNumber",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Thumbprint")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.thumbprint,
                          expression: "certificate.thumbprint",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.thumbprint },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "thumbprint",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Valid From")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.validFrom,
                          expression: "certificate.validFrom",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.validFrom },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "validFrom",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Valid To")
                  ]),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.certificate.validTo,
                          expression: "certificate.validTo",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "", readonly: "" },
                      domProps: { value: _vm.certificate.validTo },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.certificate,
                            "validTo",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "idb-block-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "submit",
                disabled: _vm.$v.$invalid || _vm.isLoading
              }
            },
            [_vm._v("Save Certificate")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-danger pull-right",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            },
            [_vm._v("Cancel")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }