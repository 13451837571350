<template>
	<div>
		<form @submit.prevent="checkValidation() && saveLicence()" novalidate>
			<div class="row">
				<div class="col-md-12">
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Resources</h2>
						</div>
						<div class="idb-block-content">
							<div v-if="areErrors" class="alert alert-danger" id="errors">
								<p>The licence cannot be saved due to these errors, please fix the problems in this list by either changing the related entities or amending the licence</p>
								<ul>
									<li v-for="error in errors" :key="error.message">{{error.message}}</li>
								</ul>
							</div>
							<div class="col-md-10">
								<!-- TODO: This looks horid when shrunk to be responsive -->
								<div class="container-fluid">
									<div class="row">
										<div class="col-md-2"></div>
										<div class="col-md-3">Current Limit</div>
										<div class="col-md-3">Used</div>
										<div class="col-md-3">Available</div>
									</div>
									<div class="row top-buffer">
										<div class="col-md-2">Users</div>
										<div class="col-md-3" :class="{invalid: $v.licence.platform.users.$error}">
											<input class="form-control" type="number" v-model="$v.licence.platform.users.$model" />
											<!-- Validation -->
											<validation-messages v-model="$v.licence.platform.users" name="limit"></validation-messages>
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="usersAmount" />
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="availableUsers" />
										</div>
									</div>
									<div class="row top-buffer">
										<div class="col-md-2">Groups</div>
										<div class="col-md-3" :class="{invalid: $v.licence.platform.groups.$error}">
											<input class="form-control" type="number" v-model="$v.licence.platform.groups.$model" />
											<!-- Validation -->
											<validation-messages v-model="$v.licence.platform.groups" name="limit"></validation-messages>
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="groupsAmount" />
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="availableGroups" />
										</div>
									</div>
									<div class="row top-buffer">
										<div class="col-md-2">Service Accounts</div>
										<div class="col-md-3" :class="{invalid: $v.licence.platform.serviceAccounts.$error}">
											<input
												class="form-control"
												type="number"
												v-model="$v.licence.platform.serviceAccounts.$model"
											/>
											<!-- Validation -->
											<validation-messages v-model="$v.licence.platform.serviceAccounts" name="limit"></validation-messages>
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="serviceAccountAmount" />
										</div>
										<div class="col-md-3">
											<input class="form-control" readonly v-model="availableServiceAccounts" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12 col-lg-6">
					<!-- Payments -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Payments</h2>
						</div>
						<div class="idb-block-content">
							<!-- Bacs -->
							<div class="form-group row" :class="{invalid: $v.licence.bacs.value.$error}">
								<label class="col-form-label col-md-3">BACS</label>
								<div class="col-md-6">
									<b-form-select v-model="$v.licence.bacs.value.$model" :options="paymentValues"></b-form-select>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.bacs.value" name="bacs"></validation-messages>
								</div>
							</div>

							<!-- Allow Bank Grade -->
							<div
								class="form-group row"
								v-if="$v.licence.bacs.value.$model == 'Both' || $v.licence.bacs.value.$model == 'Direct'"
							>
								<label for="bankgrade" class="col-form-label col-md-3">Allow Bank Grade BACS</label>
								<div class="col-md-6">
									<p-check
										:label-id="'bankgrade'"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.bacs.bankGrade.$model"
									></p-check>
								</div>
							</div>

							<!-- Allow Proxy Groups -->
							<div
								class="form-group row"
								v-if="$v.licence.bacs.value.$model == 'Both' || $v.licence.bacs.value.$model == 'Indirect'"
							>
								<label for="multiblock" class="col-form-label col-md-3">Allow Multi-Block BACS</label>
								<div class="col-md-6">
									<p-check
										:label-id="'multiblock'"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.bacs.proxyGroup.$model"
									></p-check>
								</div>
							</div>

							<!-- Bacs Api -->
							<div
								class="form-group row"
								:class="{invalid: $v.licence.bacs.api.$error}"
								v-if="$v.licence.bacs.value.$model !== 'Off'"
							>
								<label for="BACSAPI" class="col-form-label col-md-3">BACS API</label>
								<div class="col-md-6">
									<p-check
										:label-id="'BACSAPI'"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.bacs.api.$model"
									></p-check>
								</div>
							</div>

							<!-- FPS -->
							<div class="form-group row" :class="{invalid: $v.licence.fps.value.$error}">
								<label class="col-form-label col-md-3">Faster Payments Service (FPS)</label>
								<div class="col-md-6">
									<b-form-select v-model="$v.licence.fps.value.$model" :options="paymentValues"></b-form-select>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.fps.value" name="fps"></validation-messages>
								</div>
							</div>

							<!-- Allow Bank Grade; FPS bank grade subs will never pass pre-sub val so this will never be shown for now. Correct check is for 'Both' or 'Direct' -->
							<div
								class="form-group row"
								v-if="$v.licence.fps.value.$model == 'Foo' || $v.licence.fps.value.$model == 'Bar'"
							>
								<label for="bankgradeFPS" class="col-form-label col-md-3">Allow Bank Grade FPS</label>
								<div class="col-md-6">
									<p-check
										label-id="bankgradeFPS"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.fps.bankGrade.$model"
									></p-check>
								</div>
							</div>

							<!-- Allow Proxy Groups -->
							<div
								class="form-group row"
								v-if="$v.licence.fps.value.$model == 'Both' || $v.licence.fps.value.$model == 'Indirect'"
							>
								<label for="multiblockfps" class="col-form-label col-md-3">Allow Multi-Block FPS</label>
								<div class="col-md-6">
									<p-check
										label-id="multiblockfps"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.fps.proxyGroup.$model"
									></p-check>
								</div>
							</div>

							<!-- FPS Api -->
							<div
								class="form-group row"
								:class="{invalid: $v.licence.fps.api.$error}"
								v-if="$v.licence.fps.value.$model !== 'Off'"
							>
								<label for="fpsapi" class="col-form-label col-md-3">FPS API</label>
								<div class="col-md-6">
									<p-check
										label-id="fpsapi"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.fps.api.$model"
									></p-check>
								</div>
							</div>
						</div>
					</div>

					<!-- Automation -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Automation</h2>
						</div>
						<div class="idb-block-content">
							<!-- Mapping -->
							<div class="row">
								<div class="col form-group" :class="{invalid: $v.licence.automation.value.$error}">
									<div class="row">
										<label for="mapping" class="col-md-6">Mapping</label>
										<div class="col-md-6">
											<p-check
												label-id="mapping"
												class="p-switch p-fill"
												color="primary"
												v-model="$v.licence.automation.value.$model"
												readonly
												disabled
											></p-check>
											<!-- Validation -->
											<validation-messages v-model="$v.licence.automation.value" name="mapping"></validation-messages>
										</div>
									</div>
								</div>
								<div
									class="col form-group"
									:class="{invalid: $v.licence.automation.mappingDesigner.$error}"
								>
									<div class="row">
										<label for="mappingdesigner" class="col-md-6">Mapping Designer</label>
										<div class="col-md-6">
											<p-check
												label-id="mappingdesigner"
												class="p-switch p-fill"
												color="primary"
												v-model="$v.licence.automation.mappingDesigner.$model"
											></p-check>
											<!-- Validation -->
											<validation-messages
												v-model="$v.licence.automation.mappingDesigner"
												name="mapping designer"
											></validation-messages>
										</div>
									</div>
								</div>
							</div>

							<!-- Folder Manager -->
							<div class="form-group row" :class="{invalid: $v.licence.automation.folderManager.$error}">
								<label for="foldermanager" class="col-form-label col-md-3">Folder Manager</label>
								<div class="col-md-6">
									<p-check
										label-id="foldermanager"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.automation.folderManager.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.automation.folderManager" name="folder manager"></validation-messages>
								</div>
							</div>

							<!-- Workflow -->
							<div class="row">
								<div class="col-md-6 form-group" :class="{invalid: $v.licence.automation.workflow.$error}">
									<div class="row">
										<label for="workflow" class="col-md-6">Workflow</label>
										<div class="col-md-6">
											<p-check
												label-id="workflow"
												class="p-switch p-fill"
												color="primary"
												v-model="$v.licence.automation.workflow.$model"
											></p-check>
											<!-- Validation -->
											<validation-messages v-model="$v.licence.automation.workflow" name="workflow"></validation-messages>
										</div>
									</div>
								</div>
								<div
									v-if="$v.licence.automation.workflow.$model"
									class="col form-group"
									:class="{invalid: $v.licence.automation.workflowDesigner.$error}"
								>
									<div class="row">
										<label for="workflowdesigner" class="col-md-6">Workflow Designer</label>
										<div class="col-md-6">
											<p-check
												label-id="workflowdesigner"
												class="p-switch p-fill"
												color="primary"
												v-model="$v.licence.automation.workflowDesigner.$model"
											></p-check>
											<!-- Validation -->
											<validation-messages
												v-model="$v.licence.automation.workflowDesigner"
												name="workflow designer"
											></validation-messages>
										</div>
									</div>
								</div>
							</div>

							<div v-if="$v.licence.automation.workflow.$model">
								<!-- Frequency -->
								<div class="form-group row" :class="{invalid: $v.licence.automation.frequency.$error}">
									<label for="frequency" class="col-form-label col-md-3">Frequency</label>
									<div class="col-md-6">
										<b-form-select
											id="frequency"
											v-model="$v.licence.automation.frequency.$model"
											:options="triggerFrequency"
										></b-form-select>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.automation.frequency" name="frequency"></validation-messages>
									</div>
								</div>

								<!-- Live Workflows -->
								<div class="form-group row" :class="{invalid: $v.licence.automation.liveWorkflows.$error}">
									<label class="col-form-label col-md-3">Live Workflows</label>
									<div class="col-md-6">
										<input
											type="number"
											class="form-control"
											v-model="$v.licence.automation.liveWorkflows.$model"
										/>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.automation.liveWorkflows" name="Live Workflows"></validation-messages>
									</div>
								</div>

								<!-- Automation Api -->
								<div class="form-group row" :class="{invalid: $v.licence.automation.api.$error}">
									<label for="automationapi" class="col-form-label col-md-3">Automation API</label>
									<div class="col-md-6">
										<p-check
											label-id="automationapi"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.automation.api.$model"
										></p-check>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Bureau -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Bureau</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.bureau.value.$error}">
								<label for="bureauenabled" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="bureauenabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.bureau.value.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.bureau.value" name="bureau"></validation-messages>
								</div>
							</div>

							<div v-if="$v.licence.bureau.value.$model">
								<!-- Bureau FPS Submissions -->
								<div class="form-group row" :class="{invalid: $v.licence.bureau.fps.$error}">
									<label
										for="bureaufpssubmissionsenabled"
										class="col-form-label col-md-3"
									>Faster Payments Service (FPS)</label>
									<div class="col-md-6">
										<p-check
											label-id="bureaufpssubmissionsenabled"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.bureau.fps.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.bureau.fps" name="bureau FPS"></validation-messages>
									</div>
								</div>

								<!-- Limit -->
								<div class="form-group row" :class="{invalid: $v.licence.bureau.threshold.$error}">
									<label class="col-form-label col-md-3">Limit</label>
									<div class="col-md-6">
										<input type="number" class="form-control" v-model="$v.licence.bureau.threshold.$model" />
										<small
											class="form-text text-muted"
										>This is the number of unique active SUNs that can be submitted per month. This will not stop the user from submitting past this limit.</small>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.bureau.threshold" name="limit"></validation-messages>
									</div>
								</div>

								<!-- Bureau API -->
								<div class="form-group row" :class="{invalid: $v.licence.bureau.api.$error}">
									<label for="bureauapienabled" class="col-form-label col-md-3">Bureau API</label>
									<div class="col-md-6">
										<p-check
											label-id="bureauapienabled"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.bureau.api.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.bureau.api" name="bureau API"></validation-messages>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Other -->
					<div class="idb-block">
						<div class="idb-block-title">Other</div>
						<div class="idb-block-content">
							<!-- SMS Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.other.sms.$error}">
								<label for="smsEnabled" class="col-form-label col-md-3">SMS Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="smsEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.other.sms.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.other.sms" name="sms"></validation-messages>
								</div>
							</div>

							<!-- SMS Limit -->
							<div
								v-if="$v.licence.other.sms.$model"
								class="form-group row"
								:class="{invalid: $v.licence.other.smsThreshold.$error}"
							>
								<label for="smsLimit" class="col-form-label col-md-3">SMS Limit</label>
								<div class="col-md-6">
									<input
										id="smsLimit"
										type="number"
										class="form-control"
										v-model="$v.licence.other.smsThreshold.$model"
									/>
									<small class="form-text text-muted">This is the amount of SMS's per month</small>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.other.smsThreshold" name="limit"></validation-messages>
								</div>
							</div>

							<!-- Email Limit -->
							<div class="form-group row" :class="{invalid: $v.licence.other.emailThreshold.$error}">
								<label class="col-form-label col-md-3">Email Limit</label>
								<div class="col-md-6">
									<input type="number" class="form-control" v-model="$v.licence.other.emailThreshold.$model" />
									<small class="form-text text-muted">This is the amount of emails per month</small>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.other.emailThreshold" name="limit"></validation-messages>
								</div>
							</div>

							<!-- Storage -->
							<div class="form-group row" :class="{invalid: $v.licence.other.storage.$error}">
								<label class="col-form-label col-md-3">Secure Cloud Storage</label>
								<div class="col-md-6">
									<div class="input-group">
										<input
											type="text"
											class="form-control"
											readonly
											v-model="$v.licence.other.storage.$model"
											aria-describedby="storage-addon"
										/>
										<div class="input-group-append">
											<span class="input-group-text" id="storage-addon">GB</span>
										</div>
									</div>
									<input
										type="range"
										class="custom-range"
										v-model="$v.licence.other.storage.$model"
										min="1"
										max="10"
										step="1"
									/>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.other.storage" name="sms"></validation-messages>
								</div>
							</div>

							<!-- SMS Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.other.sso.$error}">
								<label for="ssoEnabled" class="col-form-label col-md-3">SSO Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="ssoEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.other.sso.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.other.sso" name="sso"></validation-messages>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-12 col-lg-6">
					<!-- Validation -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Validation</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.validate.value.$error}">
								<label for="otherValidate" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="otherValidate"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.validate.value.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.validate.value" name="validate"></validation-messages>
								</div>
							</div>

							<div v-if="$v.licence.validate.value.$model">
								<!-- Modulus Check -->
								<div class="form-group row" :class="{invalid: $v.licence.validate.modCheck.$error}">
									<label for="ukBankAccount" class="col-form-label col-md-3">UK Bank Account</label>
									<div class="col-md-6">
										<p-check
											label-id="ukBankAccount"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.validate.modCheck.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.validate.modCheck" name="modulus check"></validation-messages>
									</div>
								</div>

								<!-- Iban -->
								<div class="form-group row" :class="{invalid: $v.licence.validate.iban.$error}">
									<label for="iban" class="col-form-label col-md-3">IBAN</label>
									<div class="col-md-6">
										<p-check
											label-id="iban"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.validate.iban.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.validate.iban" name="iban"></validation-messages>
									</div>
								</div>

								<!-- Threshold -->
								<div class="form-group row" :class="{invalid: $v.licence.validate.threshold.$error}">
									<label class="col-form-label col-md-3">Limit</label>
									<div class="col-md-6">
										<input type="number" class="form-control" v-model="$v.licence.validate.threshold.$model" />
										<small class="form-text text-muted">This is the amount of billable modchecks per month</small>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.validate.threshold" name="limit"></validation-messages>
									</div>
								</div>

								<!-- validate Api -->
								<div
									class="form-group row"
									:class="{invalid: $v.licence.validate.api.$error}"
									v-if="$v.licence.validate.value.$model"
								>
									<label for="validateApi" class="col-form-label col-md-3">Validate API</label>
									<div class="col-md-6">
										<p-check
											label-id="validateApi"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.validate.api.$model"
										></p-check>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Agent -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Agent</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.agent.value.$error}">
								<label for="agentEnabled" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="agentEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.agent.value.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.agent.value" name="enabled"></validation-messages>
								</div>
							</div>

							<div v-if="$v.licence.agent.value.$model">
								<!-- Use Own Certificate -->
								<div class="form-group row" :class="{invalid: $v.licence.agent.useOwnCertificate.$error}">
									<label for="ownCertificate" class="col-form-label col-md-3">Use Own Certificate</label>
									<div class="col-md-6">
										<p-check
											label-id="ownCertificate"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.agent.useOwnCertificate.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages
											v-model="$v.licence.agent.useOwnCertificate"
											name="use own certificate"
										></validation-messages>
									</div>
								</div>

								<!-- Allow FTP Address Change -->
								<div
									class="form-group row"
									:class="{invalid: $v.licence.agent.allowFtpAddressChange.$error}"
								>
									<label for="ftpAddressChange" class="col-form-label col-md-3">Allow FTP Address Change</label>
									<div class="col-md-6">
										<p-check
											label-id="ftpAddressChange"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.agent.allowFtpAddressChange.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages
											v-model="$v.licence.agent.allowFtpAddressChange"
											name="allow FTP address change"
										></validation-messages>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Hsm -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Hardware Security Module (HSM)</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.hsm.value.$error}">
								<label for="hsmEnabled" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="hsmEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.hsm.value.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.hsm.value" name="hsm"></validation-messages>
								</div>
							</div>

							<!-- HSM Limit -->
							<div
								v-if="$v.licence.hsm.value.$model"
								class="form-group row"
								:class="{invalid: $v.licence.hsm.hsmThreshold.$error}"
							>
								<label for="hsmLimit" class="col-form-label col-md-3">HSM Limit</label>
								<div class="col-md-6">
									<input
										id="hsmLimit"
										type="number"
										class="form-control"
										v-model="$v.licence.hsm.hsmThreshold.$model"
										min="1"
									/>
									<small
										class="form-text text-muted"
									>This is the amount of valid customer certificates you can have at one time</small>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.hsm.hsmThreshold" name="limit"></validation-messages>
								</div>
							</div>
						</div>
					</div>

					<!-- Anti-Fraud module -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Anti-Fraud Module</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.antiFraud.enabled.$error}">
								<label for="antiFraudEnabled" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="antiFraudEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.antiFraud.enabled.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.antiFraud.enabled" name="antiFraud"></validation-messages>
								</div>
							</div>

							<!-- Whitelist Limit -->
							<div
								v-if="$v.licence.antiFraud.enabled.$model"
								class="form-group row"
								:class="{invalid: $v.licence.antiFraud.whitelistThreshold.$error}"
							>
								<label class="col-form-label col-md-3">Whitelist Limit</label>
								<div class="col-md-6">
									<input
										type="number"
										class="form-control"
										v-model="$v.licence.antiFraud.whitelistThreshold.$model"
									/>
									<small
										class="form-text text-muted"
									>This is the amount of whitelists you can have at one time</small>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.antiFraud.whitelistThreshold" name="limit"></validation-messages>
								</div>
							</div>

							<!-- Blacklist Threshold -->
							<div
								v-if="$v.licence.antiFraud.enabled.$model"
								class="form-group row"
								:class="{invalid: $v.licence.antiFraud.blacklistThreshold.$error}"
							>
								<label class="col-form-label col-md-3">Blacklist Limit</label>
								<div class="col-md-6">
									<input
										type="number"
										class="form-control"
										v-model="$v.licence.antiFraud.blacklistThreshold.$model"
									/>
									<small
										class="form-text text-muted"
									>This is the amount of blacklists you can have at one time</small>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.antiFraud.blacklistThreshold" name="limit"></validation-messages>
								</div>
							</div>
						</div>
					</div>

					<!-- MFA -->
					<div class="idb-block">
						<div class="idb-block-title">
							<h2>Multi-Factor Authentication (MFA)</h2>
						</div>
						<div class="idb-block-content">
							<!-- Enabled -->
							<div class="form-group row" :class="{invalid: $v.licence.mfa.value.$error}">
								<label for="mfaEnabled" class="col-form-label col-md-3">Enabled</label>
								<div class="col-md-6">
									<p-check
										label-id="mfaEnabled"
										class="p-switch p-fill"
										color="primary"
										v-model="$v.licence.mfa.value.$model"
									></p-check>
									<!-- Validation -->
									<validation-messages v-model="$v.licence.mfa.value" name="mfa"></validation-messages>
								</div>
							</div>

							<div v-if="$v.licence.mfa.value.$model">
								<!-- Authenticator -->
								<div class="form-group row" :class="{invalid: $v.licence.mfa.authenticator.$error}">
									<label for="authenticator" class="col-form-label col-md-3">Authenticator</label>
									<div class="col-md-6">
										<p-check
											label-id="authenticator"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.mfa.authenticator.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.mfa.authenticator" name="authenticator"></validation-messages>
									</div>
								</div>

								<!-- USB Tokens -->
								<div class="form-group row" :class="{invalid: $v.licence.mfa.usbTokens.$error}">
									<label for="usbTokens" class="col-form-label col-md-3">USB Tokens</label>
									<div class="col-md-6">
										<p-check
											label-id="usbTokens"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.mfa.usbTokens.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.mfa.usbTokens" name="USB Tokens"></validation-messages>
									</div>
								</div>

								<!-- Email -->
								<div class="form-group row" :class="{invalid: $v.licence.mfa.emails.$error}">
									<label for="emailEnabled" class="col-form-label col-md-3">Email</label>
									<div class="col-md-6">
										<p-check
											label-id="emailEnabled"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.mfa.emails.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.mfa.emails" name="email"></validation-messages>
									</div>
								</div>

								<!-- SMS -->
								<div
									class="form-group row"
									v-if="$v.licence.other.sms.$model"
									:class="{invalid: $v.licence.mfa.sms.$error}"
								>
									<label for="otherSms" class="col-form-label col-md-3">SMS</label>
									<div class="col-md-6">
										<p-check
											label-id="otherSms"
											class="p-switch p-fill"
											color="primary"
											v-model="$v.licence.mfa.sms.$model"
										></p-check>
										<!-- Validation -->
										<validation-messages v-model="$v.licence.mfa.sms" name="sms"></validation-messages>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="idb-block">
				<div class="idb-block-footer">
					<button
						class="btn"
						:class="needsChecking? 'btn-warning': 'btn-success'"
						type="submit"
						:disabled="(areErrors && !needsChecking) || isLoading"
					>{{needsChecking? 'Check conflicts':'Save'}}</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { required, numeric, minValue, requiredIf, helpers } from 'vuelidate/lib/validators'
import { int32 } from '@/Assets/Validators'
import axios from 'axios'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
	mixins: [loading],
	computed: {
		availableUsers () {
			return this.licence.platform.users - this.usersAmount
		},
		availableGroups () {
			return this.licence.platform.groups - this.groupsAmount
		},
		availableServiceAccounts () {
			return this.licence.platform.serviceAccounts - this.serviceAccountAmount
		},
		areErrors () {
			return this.errors.some(() => true)
		},
		...mapGetters(['selectedCustomer'])
	},
	watch: {
		'$v.$anyDirty': function (val) {
			if (val) {
				this.needsChecking = true
			}
		},
		async selectedCustomer () { await this.loadLicence() }
	},
	data () {
		return {
			needsChecking: true,
			usersAmount: 0,
			groupsAmount: 0,
			serviceAccountAmount: 0,
			licence: {
				platform: {
					users: 10,
					groups: 3,
					serviceAccounts: 0
				},
				bacs: {
					value: 'Direct',
					api: false,
					bankGrade: false,
					proxyGroup: false
				},
				fps: {
					value: 'Off',
					api: false,
					bankGrade: false,
					proxyGroup: false
				},
				collections: {
					value: false,
					api: false,
					onBoarding: false,
					payerPortal: false,
					payerThreshold: 10,
					refunds: false
				},
				bureau: {
					value: false,
					api: false,
					fps: false,
					threshold: 100
				},
				validate: {
					value: true,
					modCheck: false,
					iban: false,
					cc: false,
					api: false,
					threshold: 20000
				},
				automation: {
					value: true,
					workflow: false,
					mode: 'Managed',
					frequency: 'Hourly',
					liveWorkflows: 5,
					api: false,
					folderManager: false,
					mappingDesigner: false,
					workflowDesigner: false
				},
				agent: {
					value: false,
					useOwnCertificate: false,
					allowFtpAddressChange: false
				},
				hsm: {
					value: false,
					hsmThreshold: 2
				},
				mfa: {
					value: true,
					authenticator: true,
					usbTokens: false,
					sms: false,
					emails: false
				},
				other: {
					sms: false,
					smsThreshold: 10000,
					emailThreshold: 10000,
					storage: 1,
					sso: false
				},
				antiFraud: {
					enabled: false,
					whitelistThreshold: 0,
					blacklistThreshold: 0
				}
			},
			paymentValues: ['Off', 'Direct', 'Indirect', 'Both'],
			triggerFrequency: [
				{ value: 'Hourly', text: 'Hourly' },
				{ value: 'FifteenMinutes', text: '15 Minutes' },
				{ value: 'FiveMinutes', text: '5 Minutes' },
				{ value: 'EveryMinute', text: 'Every Minute' }
				// { value: 'EverySecond', text: 'Every Second' }
			],
			errors: []
		}
	},
	async created () {
		await this.loadLicence()
	},
	methods: {
		async saveLicence () {
			if (this.needsChecking) {
				try {
					var checkResponse = await axios.post(
						`${process.env.VUE_APP_PLATFORM_API_URL}Licence/Check`,
						this.licence,
						{ showload: true, showerror: true, errormessage: 'Failed to check licence' }
					)
					if (checkResponse.data.error) {
						this.$snapbar.w('There are modules in paygate which are unlicensed')
						this.errors = checkResponse.data.errors
						var container = document.getElementById('base-container')
						container.scrollTop = 0
					} else {
						this.errors = []
						this.$snapbar.hide()
					}

					this.needsChecking = false
					this.$v.$reset()
				} catch { }
			} else {
				try {
					await axios.put(
						`${process.env.VUE_APP_PLATFORM_API_URL}Licence`,
						this.licence,
						{ showload: true, showerror: true, errormessage: 'Failed to save licence' }
					)
					this.$toastr.s('Licence has been successfully saved!', 'Saved')
					await this.loadLicence()
				} catch { }
			}
		},
		async loadLicence () {
			this.$snapbar.hide()
			try {
				this.$Progress.start()

				const results = await Promise.allSettled([
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/GroupCount`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/UserCount`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/ServiceAccountCount`)])

				const licenceResponse = results[0].value
				const groupResponse = results[1].value
				const userResponse = results[2].value
				const serviceAccountResponse = results[3].value

				this.licence = licenceResponse.data
				this.errors = []
				this.groupsAmount = groupResponse.data
				this.usersAmount = userResponse.data
				this.serviceAccountAmount = serviceAccountResponse.data
			} catch {
				this.$Progress.fail()
			} finally {
				await this.$nextTick()
				this.$v.$reset()
				this.$Progress.finish()
			}
		}
	},
	validations: {
		licence: {
			platform: {
				users: { numeric, required, minValue: minValue(1), int32 },
				groups: { numeric, required, minValue: minValue(0), int32 },
				serviceAccounts: { numeric, required, minValue: minValue(0), int32 }
			},
			bacs: {
				value: { required },
				bankGrade: {},
				proxyGroup: {},
				api: {}
			},
			fps: {
				value: { required },
				bankGrade: {},
				proxyGroup: {},
				api: {}
			},
			collections: {
				value: {},
				api: {},
				onBoarding: {},
				payerPortal: {},
				payerThreshold: {
					numeric,
					required: requiredIf((model) => {
						return model.value
					}),
					int32
				},
				refunds: {}
			},
			bureau: {
				value: {},
				api: {},
				fps: {},
				threshold: {
					numeric,
					required: requiredIf((model) => {
						return model.value
					}),
					int32
				}
			},
			validate: {
				value: {},
				modCheck: {},
				iban: {},
				cc: {},
				threshold: {
					numeric,
					required: requiredIf((model) => {
						return model.value
					}),
					int32
				},
				api: {}
			},
			automation: {
				value: {},
				workflow: {},
				mode: {
					required: requiredIf((model) => {
						return model.value
					})
				},
				frequency: {
					required: requiredIf((model) => {
						return model.workflow
					})
				},
				liveWorkflows: {
					required: requiredIf((model) => {
						return model.workflow
					}),
					numeric,
					minValue: minValue(0),
					int32
				},
				api: {},
				folderManager: {},
				mappingDesigner: {},
				workflowDesigner: {}
			},
			agent: {
				value: {},
				useOwnCertificate: {},
				allowFtpAddressChange: {}
			},
			hsm: {
				value: {},
				hsmThreshold: {
					numeric,
					minValue: helpers.withParams({ type: 'minValue', min: 2 }, (value, hsm) => {
						return hsm.value ? value >= 2 : true
					}),
					required: requiredIf((model) => {
						return model.value
					}),
					int32
				}
			},
			mfa: {
				value: {},
				usbTokens: {},
				sms: {},
				authenticator: {},
				emails: {}
			},
			other: {
				sms: {},
				smsThreshold: {
					numeric,
					required: requiredIf((model) => {
						return model.sms
					}),
					int32
				},
				emailThreshold: { numeric, required, int32 },
				storage: { required, numeric },
				sso: {}
			},
			antiFraud: {
				enabled: {},
				whitelistThreshold: {
					numeric,
					required: requiredIf((model) => {
						return model.enabled
					}),
					int32
				},
				blacklistThreshold: {
					numeric,
					required: requiredIf((model) => {
						return model.enabled
					}),
					int32
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.top-buffer {
	margin-top: 20px;
}
</style>
