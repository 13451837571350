<template>
  <div id="banklineSubmissionHistory">
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :lineNumbers="true"
          :pagination-options="{
            enabled: true
          }"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'userId'">
              <avatar
                class="rounded-circle"
                width="30"
                height="30"
                v-b-tooltip.hover="props.row.Username"
                :title="props.row.Username"
                :user-id="props.row.userId"
              />
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      submissionId: '',
      columns: [
        {
          label: 'Status',
          field: 'toStatus'
        },
        {
          label: 'Status Updated On',
          field: 'updatedOn',
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm'
        },
        {
          label: 'Message',
          field: 'message'
        },
        {
          label: 'Created By',
          field: 'userId',
          width: '130px'
        },
        {
          label: 'Username',
          field: 'username'
        }
      ],
      rows: []
    }
  },

  methods: {
    tdClassFunc (row) {
      return 'alertValueLeft'
    },

    async getSubmissionHistory () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionHistory?submissionId=` + this.submissionId)
      this.rows = response.data
      this.$store.dispatch('setBanklineSubmissionHistory', response.data)
    }
  },

  async mounted () {
    this.submissionId = this.$store.getters.submissionId

    let viewBanklineSubmissionData = this.$store.getters.viewBanklineSubmissionData
    if (viewBanklineSubmissionData.historyLoaded === false) {
      viewBanklineSubmissionData.historyLoaded = true
      this.$store.dispatch('setViewBanklineSubmissionData', viewBanklineSubmissionData)
      await this.getSubmissionHistory()
    } else {
      let submissionHistory = this.$store.getters.banklineSubmissionHistory
      this.rows = submissionHistory
    }
  }
}
</script>
