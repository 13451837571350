<template>
  <div id="viewBanklineSubmission">
    <div v-show="action.id !== undefined">
      <div v-show="action.id !== '00000000-0000-0000-0000-000000000000'" id="actionDetails">
        <div class="idb-block-title">
          <h2>
            {{ this.action.title }}
            <help-icon :docPath="helpUrl"></help-icon>
          </h2>
        </div>
      </div>
      <div class="idb-block-content">
        <b-col sm="8">
          <div v-if="action.id !== '00000000-0000-0000-0000-000000000000'">
            <div class="row form-group">
              <div class="col-md-3">
                <strong>Message</strong>
              </div>
              <div class="col-md-9">{{ this.action.message }}</div>
            </div>
          </div>
          <div v-if="validationWarnings" class="row form-group">
            <div class="col-md-3">
              <strong>Validation Messages</strong>
            </div>
            <div class="col-md-9">
              <span
                class="badge badge-warning badge-pill"
              >Submission contains {{ this.numberOfWarnings }} {{ this.validationTotalText }}</span>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <strong>Submission Reference</strong>
            </div>
            <div class="col-md-9">{{ this.banklineSubmission.reference }}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <strong>Group Name</strong>
            </div>
            <div class="col-md-9">{{ this.groupName }}</div>
          </div>
        </b-col>
        <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
          <b-tab title="Summary" active>
            <banklineSubmissionSummary></banklineSubmissionSummary>
          </b-tab>
          <b-tab title="Payments">
            <banklinePayments></banklinePayments>
          </b-tab>
          <b-tab title="Submission History">
            <banklineSubmissionHistory></banklineSubmissionHistory>
          </b-tab>
          <b-tab v-if="numberOfWarnings > 0">
            <template slot="title">
              Validation Messages
              <span
                class="badge badge-warning badge-pill"
              >{{ this.numberOfWarnings }}</span>
            </template>
            <banklineValidationWarnings></banklineValidationWarnings>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import BanklineSubmissionSummary from '@/Views/Bankline/Common/BanklineSubmissionSummary.vue'
  import BanklinePayments from '@/Views/Bankline/Common/BanklinePayments.vue'
  import BanklineValidationWarnings from '@/Views/Bankline/Common/BanklineValidationWarnings.vue'
  import BanklineSubmissionHistory from '@/Views/Bankline/Common/BanklineSubmissionHistory.vue'
  import bacsMixin from '@/Lib/BacsMixin.js'
  import Utility from '@/Assets/Mixins/Utility'
  import roles from '@/Assets/Constants/roles'
  import swal from 'sweetalert2'
  import colours from '@/Assets/Constants/colours'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  
  export default {
    components: {
      banklineSubmissionSummary: BanklineSubmissionSummary,
      banklinePayments: BanklinePayments,
      banklineValidationWarnings: BanklineValidationWarnings,
      banklineSubmissionHistory: BanklineSubmissionHistory
    },
    mixins: [
      bacsMixin, Utility
    ],
    computed: {
      validationTotalText: function () {
        if (this.numberOfWarnings === 1) {
          return 'validation message'
        } else {
          return 'validation messages'
        }
      },
      ...mapGetters(['selectedCustomer'])
    },
    data () {
      return {
        processingDate: '',
        paymentDate: '',
        submissionData: {},
        submissionId: '',
        banklineSubmissionSummary: {},
        tabIndex: 0,
        validationWarnings: false,
        numberOfWarnings: 0,
        isLoaded: false,
        viewBanklineSubmissionData: {},
        action: {},
        groupName: '',
        tokenType: '',
        messageCountPerFile: {},
        valMessageClasses: 'badge badge-warning badge-pill',
        helpUrl: '',
        importedFilename: '',
        banklineSubmission: {}
      }
    },
  
    methods: {
      tabSelected (tabIndex) {
      },
  
      changeTab () {
        this.tabIndex = 0
      },
  
      // async getBanklineSubmissionSummary () {
      //   let response = await axios.get(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/validation/getBanklineSubmissionSummary?submissionId=` + this.submissionId, { showload: true })
      //   this.banklineSubmissionSummary = response.data
      //   this.$store.dispatch('setBanklineSubmissionSummary', this.banklineSubmissionSummary)
      //   this.viewBanklineSubmissionData.summaryLoaded = true
      //   this.$store.dispatch('setViewBanklineSubmissionData', this.viewBanklineSubmissionData)
      // },
  
      async getBanklineSubmission () {
        let response = await axios.get(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/submission/get?submissionId=` + this.submissionId)
        this.banklineSubmission = response.data
        this.$store.dispatch('setBanklineSubmission', this.banklineSubmission)
        this.$store.dispatch('getSubBacsGroup', this.banklineSubmission.groupId)
      },
  
      async getValidationResult () {
        let response = await axios.get(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/validation/getValidationResult?submissionId=${this.submissionId}`)
        this.numberOfWarnings = response.data.totalMessages
        this.$store.dispatch('setNumberOfWarnings', this.numberOfWarnings)
        if (response.data.validationResult === 'Info') {
          this.valMessageClasses = 'badge badge-info badge-pill'
        }
      },
  
      async getAction () {
        var actionId = this.$store.getters.actionId
        if (actionId !== undefined) {
          if (actionId !== '00000000-0000-0000-0000-000000000000') {
            var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
            var response = await axios.get(url, { action: true })
            this.action = response.data
          } else {
            this.action.id = '00000000-0000-0000-0000-000000000000'
          }
        }
      },
  
      async getGroupDetails () {
        try {
          await this.$store.dispatch('getSubBacsGroup', this.banklineSubmission.groupId)
          let banklineGroup = this.$store.getters.bacsGroup
          this.groupName = banklineGroup.name
        } catch (err) {
          console.log(err)
          if (this.groupName === '' || this.groupName === undefined) {
            this.groupName = this.$store.getters.selectedRowGroupName
          }
        }
      },
  
      async getCustomer () {
        if (!this.$store.getters.isInRole(roles.SystemUser)) {
          var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/allowedlivesubmissions`)
          if (!response.data) {
            this.$snapbar.e('Your customer is set in \'Test Mode\' so is not allowed to send live submissions at this time.')
          }
        }
      }
    },

    async mounted () {
    },

    async created () {
      this.$store.dispatch('setParentBanklineDataLoaded', false)
      this.submissionId = this.$store.getters.submissionId
      this.$store.dispatch('setSubmissionId', this.submissionId)
  
      this.viewBanklineSubmissionData = {
        summaryLoaded: false,
        paymentsLoaded: false,
        validationMessagesLoaded: false,
        historyLoaded: false
      }
  
      this.$store.dispatch('setViewBanklineSubmissionData', this.viewBanklineSubmissionData)
  
      await this.getCustomer()
      await this.getBanklineSubmission()
      // await this.getBanklineSubmissionSummary()
      await this.getValidationResult()
      await this.getAction()
      await this.getGroupDetails()
      this.isLoaded = true
      this.$store.dispatch('setParentBanklineDataLoaded', true)
    }
  }
  </script>
  
  <style>
  .tall-dropdown .dropdown-menu {
    max-height: 350px;
    overflow-y: auto;
  }
  </style>
  