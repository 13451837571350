var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _vm._v(" Events "),
            _c("span", { staticClass: "pull-right" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.docUrl + "/automation/logging/",
                    target: "_blank"
                  }
                },
                [_c("i", { staticClass: "far fa-question-circle" })]
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("div", { staticClass: "mb-4" }, [
              _vm._v(
                "In a workflow, each node creates a separate event. A mapping creates a single event."
              )
            ]),
            _c(
              "vue-good-table",
              {
                ref: "table1",
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.rows,
                  lineNumbers: true,
                  "search-options": { enabled: true },
                  paginationOptions: _vm.paginationOptions1,
                  "sort-options": _vm.sortOptions1,
                  styleClass:
                    "vgt-table striped bordered table-hover handPointer"
                },
                on: {
                  "on-cell-click": _vm.onCellClick1,
                  "on-page-change": _vm.onPageChange1,
                  "on-sort-change": _vm.onSortChange1,
                  "on-column-filter": _vm.onColumnFilter1,
                  "on-per-page-change": _vm.onPerPageChange1,
                  "on-search": _vm.onSearch1
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "currentStatus"
                          ? _c(
                              "span",
                              [
                                props.row.currentStatus == "Failed"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "danger" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Complete"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "success" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Warning"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "warning" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus ==
                                    "Completed with warnings"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "warning" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Processing"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "primary" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Paused"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "warning" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(props.row.currentStatus))
                                    ])
                              ],
                              1
                            )
                          : props.column.field == "createdAt"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.reformatDate(
                                    props.formattedRow[props.column.field]
                                  )
                                )
                              )
                            ])
                          : props.column.field == "updatedAt"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.reformatDate(
                                    props.formattedRow[props.column.field]
                                  )
                                )
                              )
                            ])
                          : props.column.field == "buttons"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "View Workflow Event: Drill down into this workflow event.",
                                        expression:
                                          "'View Workflow Event: Drill down into this workflow event.'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass: "pointer",
                                    attrs: { variant: "link" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.viewWorkflowEvent(props)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-secondary" },
                                      [
                                        _c("i", {
                                          staticClass: "glyphicon ti-list"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ])
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Clear filters",
                            expression: "'Clear filters'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "btn btn-link",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.clearTableFilters1($event)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fa-stack",
                            staticStyle: { "font-size": "10px" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                            }),
                            _c("i", {
                              staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                            })
                          ]
                        ),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Clear filters")
                        ])
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Refresh the data in the table",
                            expression: "'Refresh the data in the table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.getEventData($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-sync-alt pointer dimmedIcon pointer"
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Refresh Table")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "mb-4" }, [
            _vm._v("All log messages that this instance created")
          ]),
          _c(
            "div",
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Tabular", active: "" } },
                    [
                      _c(
                        "vue-good-table",
                        {
                          ref: "table2",
                          attrs: {
                            columns: _vm.log_columns,
                            rows: _vm.log_rows,
                            lineNumbers: true,
                            "search-options": {
                              enabled: true
                            },
                            paginationOptions: _vm.paginationOptions2,
                            "sort-options": _vm.sortOptions2,
                            styleClass: "vgt-table striped bordered table-hover"
                          },
                          on: {
                            "on-page-change": _vm.onPageChange2,
                            "on-sort-change": _vm.onSortChange2,
                            "on-column-filter": _vm.onColumnFilter2,
                            "on-per-page-change": _vm.onPerPageChange2,
                            "on-search": _vm.onSearch2
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field == "datetime"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reformatDate(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            )
                                          )
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          )
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Clear filters",
                                      expression: "'Clear filters'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass: "btn btn-link",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.clearTableFilters2($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fa-stack",
                                      staticStyle: { "font-size": "10px" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-filter fa-stack-1x dimmedIcon"
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "fa fa-ban fa-stack-2x dimmedIcon"
                                      })
                                    ]
                                  ),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Clear filters")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: { variant: "link" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.getLogData($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-sync-alt pointer dimmedIcon pointer"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Refresh Table")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("b-tab", { attrs: { title: "Text View" } }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.logText,
                          expression: "logText",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        rows: "40",
                        autocomplete: "off",
                        autocorrect: "off",
                        autocapitalize: "off",
                        spellcheck: "false"
                      },
                      domProps: { value: _vm.logText },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.logText = $event.target.value.trim()
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Log Messages")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }