<template>
  <div>
    <vue-good-table
      :paginationOptions="paginationOptions"
      :sort-options="sortOptions"
      :isLoading.sync="isTableLoading"
      :rows="rows"
      :columns="columns"
      :lineNumbers="true"
      mode="remote"
      :totalRows="totalRecords"
      @on-row-click="onRowClick"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
      styleClass="vgt-table striped bordered"
      ref="table"
    >
      <div slot="table-actions">
        <button
          @click.prevent="clearTableFilters"
          class="btn btn-link"
          v-b-popover.hover.top.d500="'Clear filters'"
        >
          <span class="fa-stack" style="font-size: 10px;">
            <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
            <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
          </span><span class="sr-only">Clear filters</span>
        </button>
        <b-button
          @click.prevent="load"
          class
          variant="link"
          v-b-popover.hover.top.d500="'Refresh the data in the table'"
        >
          <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
        </b-button>
        <b-button
          @click.prevent="exportTable"
          class
          variant="link"
          v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
        >
          <i class="fa fa-file-export pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
        </b-button>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  props:{
    customerId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Job ID',
          field: 'bureauJobId',
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name'
          },
          sortable: true
        },
        {
          label: 'Group',
          field: 'groupName',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Group'
          }
        },
        {
          label: 'Group Id',
          field: 'groupId',
          hidden: true
        },
        {
          label: 'Number Of Job Details',
          field: 'numberOfBureauCustomers',
          sortable: false
        },
        {
          label: 'Last Run',
          field: 'lastRun',
          type: 'date',
          sortable: true,
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'desc' }]
      }
    }
  },

  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  created () {
    this.load()
  },
  methods: {
    onRowClick (params) {
      this.$store.commit('setBureauJob', params.row)
      this.$router.push({ name: 'ManageJob', params: { id: params.row.bureauJobId } })
    },
    load: _.debounce(async function () {
      //    async load () {
      try {
        this.isTableLoading = true
        var params = { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer }
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauJob/loadforcustomer/${this.customerId}`,
          {
            params,
            showerror: true,
            errormessage: 'Failed to load bureau jobs'
          })

        this.rows = response.data.data
      } catch (e) {
        console.log('error', e)
      } finally {
        this.isTableLoading = false
      }
    }, 500)
  }
}

</script>
