<template>
	<div>
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="authorised">
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						Workflow Events
						<span class="pull-right">
							<a :href="`${docUrl}/automation/logging/`" target="_blank">
								<i class="far fa-question-circle"></i>
							</a>
						</span>
					</h2>
				</div>
				<div class="idb-block-content">
					<div
						class="mb-4"
					>In a workflow, each node creates a separate event. A mapping creates a single event.</div>
					<vue-good-table
						:columns="columns"
						:rows="rows"
						:lineNumbers="true"
						:search-options="{ enabled: true }"
						:paginationOptions="paginationOptions1"
						:sort-options="sortOptions1"
						@on-cell-click="onCellClick1"
						@on-page-change="onPageChange1"
						@on-sort-change="onSortChange1"
						@on-column-filter="onColumnFilter1"
						@on-per-page-change="onPerPageChange1"
						@on-search="onSearch1"
						ref="table1"
						styleClass="vgt-table striped bordered table-hover handPointer"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'currentStatus'">
								<b-badge
									pill
									v-if="props.row.currentStatus == 'Failed'"
									variant="danger"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Complete'"
									variant="success"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Warning'"
									variant="warning"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Completed with warnings'"
									variant="warning"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Processing'"
									variant="primary"
								>{{props.row.currentStatus}}</b-badge>
								<b-badge
									pill
									v-else-if="props.row.currentStatus == 'Paused'"
									variant="warning"
								>{{props.row.currentStatus}}</b-badge>
								<span v-else>{{props.row.currentStatus}}</span>
							</span>
							<span
								v-else-if="props.column.field == 'createdAt'"
							>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
							<span
								v-else-if="props.column.field == 'updatedAt'"
							>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
							<span v-else-if="props.column.field == 'buttons'">
								<!-- <b-button
									@click.prevent="viewWorkflowEvent(props)"
									variant="link"
									class="pointer"
									v-b-popover.hover.top.d500="'View Workflow Event: Drill down into this workflow event.'"
								>
									<span class="text-secondary">
										<i class="glyphicon ti-list"></i>
									</span>
								</b-button> -->
							</span>
							<span v-else>{{props.formattedRow[props.column.field]}}</span>
						</template>
						<div slot="table-actions">
							<button
								@click.prevent="clearTableFilters1"
								class="btn btn-link"
								v-b-popover.hover.top.d500="'Clear filters'"
							>
								<span class="fa-stack" style="font-size: 10px;">
									<i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
									<i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
								</span>
								<span class="sr-only">Clear filters</span>
							</button>
							<b-button
								@click.prevent="getEventData"
								class
								variant="link"
								v-b-popover.hover.top.d500="'Refresh the data in the table'"
							>
								<i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
								<span class="sr-only">Refresh Table</span>
							</b-button>
						</div>
					</vue-good-table>
				</div>
			</div>
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>Log Messages</h2>
				</div>
				<div class="idb-block-content">
					<div class="mb-4">All log messages that this instance created</div>
					<div>
						<b-tabs content-class="mt-3">
							<b-tab title="Tabular" active>
								<vue-good-table
									:columns="log_columns"
									:rows="log_rows"
									:lineNumbers="true"
									:search-options="{
                      enabled: true,
                    }"
									:paginationOptions="paginationOptions2"
									:sort-options="sortOptions2"
									ref="table2"
									@on-page-change="onPageChange2"
									@on-sort-change="onSortChange2"
									@on-column-filter="onColumnFilter2"
									@on-per-page-change="onPerPageChange2"
									@on-search="onSearch2"
									styleClass="vgt-table striped bordered table-hover"
								>
									<template slot="table-row" slot-scope="props">
										<span
											v-if="props.column.field == 'datetime'"
										>{{ reformatDate(props.formattedRow[props.column.field])}}</span>
										<span v-else>{{props.formattedRow[props.column.field]}}</span>
									</template>
									<div slot="table-actions">
										<button
											@click.prevent="clearTableFilters2"
											class="btn btn-link"
											v-b-popover.hover.top.d500="'Clear filters'"
										>
											<span class="fa-stack" style="font-size: 10px;">
												<i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
												<i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
											</span>
											<span class="sr-only">Clear filters</span>
										</button>
										<b-button
											@click.prevent="getLogData"
											class
											variant="link"
											v-b-popover.hover.top.d500="'Refresh the data in the table'"
										>
											<i class="fas fa-sync-alt pointer dimmedIcon pointer"></i>
											<span class="sr-only">Refresh Table</span>
										</b-button>
									</div>
								</vue-good-table>
							</b-tab>
							<b-tab title="Text View">
								<textarea
									class="form-control"
									rows="40"
									v-model.trim="logText"
									autocomplete="off"
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
								></textarea>
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<br />
		<br />
	</div>
</template>

<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
export default {
	name: 'my-component',
	data () {
		return {
      authorised: true,
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			id: this.$route.params.id,
			columns: [
				{
					label: 'executionId',
					field: 'executionId',
					hidden: true
				},
				{
					label: 'workflowId',
					field: 'workflowId',
					hidden: true
				},
				{
					label: 'Event',
					field: 'title',
					filterOptions: {
						enabled: false
					}
				},
				{
					label: 'Current Status',
					field: 'currentStatus',
					filterOptions: {
						enabled: false
					}
				},
				{
					label: 'Created At',
					field: 'createdAt',
					hidden: false,
					inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
					outputFormat: 'DD-MM-YYYY HH:mm:ss'
				},
				{
					label: 'Last Updated At',
					field: 'updatedAt',
					hidden: false
				}
				// {
				// 	label: 'Log',
				// 	field: 'buttons',
				// 	html: true,
				// 	sortable: false
				// }
			],
			row_data: [],
			workflowEvent: {
				data: {}
			},
			log_columns: [
				{
					label: 'Id',
					field: '_id',
					hidden: true
				},
				{
					label: 'Date',
					field: 'datetime',
					filterOptions: {
						enabled: false
					}
				},
				{
					label: 'Message',
					field: 'message',
					filterOptions: {
						enabled: false
					}
				}
			],
			log_row_data: [],
			serverParams1: {
				columnFilters: {},
				sort: [{ field: 'createdAt', type: 'asc' }],
				page: 1,
				perPage: 10,
				searchKey: ''
			},
			paginationOptions1: {
				enabled: true,
				perPage: 20,
				perPageDropdown: [10, 20, 100],
				dropdownAllowAll: false,
				rowsPerPageLabel: 'Events per page'
			},
			defaultParams1: {},
			sortOptions1: { enabled: true },
			doneParams1: false,
			serverParams2: {
				columnFilters: {},
				sort: [{ field: 'datetime', type: 'asc' }],
				page: 1,
				perPage: 10,
				searchKey: ''
			},
			paginationOptions2: {
				enabled: true,
				perPage: 100,
				perPageDropdown: [10, 20, 100],
				dropdownAllowAll: false,
				rowsPerPageLabel: 'Logs per page'
			},
			defaultParams2: {},
			sortOptions2: { enabled: true },
			doneParams2: false,
			logText: ''
		}
	},
	computed: {
		rows: function () {
			return this.row_data
		},
		log_rows: function () {
			return this.log_row_data
		},
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		},
    hasManageRole () {
      let hasRole = false
      try {
        hasRole = this.$store.getters.isInRole(roles.ManageWorkflow)
      } catch (e) {
        console.log(e.message)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    },
    hasViewTelemetryRole () {
      let hasRole = false
      hasRole = this.$store.getters.isInRole(roles.ViewTelemetry)
      if (!hasRole) {
        hasRole = this.$store.getters.isInRole(roles.ExecuteWorkflow)
      }
      if (!hasRole) {
        try {
          hasRole = this.$store.getters.isInRole(roles.SystemUser)
        } catch (e) {
          console.log(e.message)
        }
      }
      return hasRole
    }
	},
	methods: {
		reformatDate (d) {
			const myDate = new Date(d)
			let dd = myDate.getDate()
			let mm = myDate.getMonth() + 1
			const yyyy = myDate.getFullYear()
			if (dd < 10) {
				dd = '0' + dd
			}
			if (mm < 10) {
				mm = '0' + mm
			}
			let h = myDate.getHours()
			let m = myDate.getMinutes()
			let s = myDate.getSeconds()
			const ms = this.pad(myDate.getMilliseconds(), 3, '0')
			h = this.checkTime(h)
			m = this.checkTime(m)
			s = this.checkTime(s)
			return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s + '.' + ms
		},
		checkTime (i) {
			if (i < 10) {
				i = '0' + i
			}
			return i
		},
		pad (n, width, z) {
			z = z || '0'
			n += ''
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
		},
		async getEventData () {
			console.log('getEventData')
			this.$snapbar.hide()
			let res
			try {
				let url = `${process.env.VUE_APP_WORKFLOW_API_URL}workflowtelemetry/${this.id}`
				console.log(url)
				res = await axios.get(url)
				if (res) {
					this.row_data = res.data.workflowEvent
					if (res.data && res.data.workflowEvent) {
						if (res.data.workflowEvent.length === 0) {
							this.$snapbar.w('Invalid Telemetry id')
						}
					}
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e(`You are not authorised to view log data. (${e.response.data.errorMessage})`)
          this.authorised = false
				} else {
					this.$snapbar.e(`Could not get a list of logs - ${e.message}`)
				}
				this.row_data = []
			}
		},
		async getLogData () {
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowAllMessages/${this.id}`)
				if (res) {
					this.log_row_data = res.data.workflowMessage
					await this.generateLogText()
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e(`You are not authorised to view log data. (${e.response.data.errorMessage})`)
          this.authorised = false
				} else {
					this.$snapbar.e(`Could not get a list of logs - ${e.message}`)
				}
			}
		},
		onRowClick (params) {
		},
		onCellClick1 (params) {
			if (params.column.field !== 'buttons') {
				this.$router.push(`/automation/workflow/workflowEvent/${params.row._id}/${this.id}`)
			}
		},
		viewTimeline () {
			this.$router.push(`/automation/workflow/timeline/${this.id}`)
		},
		viewWorkflowEvent (params) {
			this.$router.push(`/automation/workflow/workflowEvent/${params.row._id}`)
		},
		onPageChange1 (params) {
			this.updateParams1({ page: params.currentPage })
		},
		onSortChange1 (params) {
			this.updateParams1({
				sort: params
			})
		},
		onColumnFilter1 (params) {
			this.updateParams1(params)
		},
		onPerPageChange1 (params) {
			this.updateParams1({ perPage: params.currentPerPage })
		},
		onSearch1 (params) {
			this.updateParams1({ searchKey: params.searchTerm })
		},
		updateParams1 (newProps) {
			this.serverParams1 = Object.assign({}, this.serverParams1, newProps)
			if (this.doneParams1) {
				this.$store.commit('updateTable', { name: 'Events', filters: this.serverParams1 })
			}
		},
		savedParamsToObjects1 (savedParams) {
			this.sortOptions1 = { ...this.sortOptions1, initialSortBy: savedParams.sort }
			const columnnFilterKeys = Object.keys(savedParams.columnFilters)
			for (var i = 0; i < columnnFilterKeys.length; i++) {
				const filterKey = columnnFilterKeys[i]
				const column = this.columns.find(c => c.field === filterKey)
				column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
			}
			this.paginationOptions1.perPage = savedParams.perPage
			this.paginationOptions1.setCurrentPage = savedParams.page
			this.$nextTick(() => {
				this.$refs.table1.globalSearchTerm = savedParams.searchKey
			})
		},
		clearTableFilters1 () {
			this.$refs.table1.reset()
			this.savedParamsToObjects1(this.defaultParams1)
			this.updateParams1(this.defaultParams1)
		},
		onPageChange2 (params) {
			this.updateParams2({ page: params.currentPage })
		},
		onSortChange2 (params) {
			this.updateParams2({
				sort: params
			})
		},
		onColumnFilter2 (params) {
			this.updateParams2(params)
		},
		onPerPageChange2 (params) {
			this.updateParams2({ perPage: params.currentPerPage })
		},
		onSearch2 (params) {
			this.updateParams2({ searchKey: params.searchTerm })
		},
		updateParams2 (newProps) {
			this.serverParams2 = Object.assign({}, this.serverParams2, newProps)
			if (this.doneParams2) {
				this.$store.commit('updateTable', { name: 'Logs2', filters: this.serverParams2 })
			}
		},
		savedParamsToObjects2 (savedParams) {
			this.sortOptions2 = { ...this.sortOptions2, initialSortBy: savedParams.sort }
			const columnnFilterKeys = Object.keys(savedParams.columnFilters)
			for (let i = 0; i < columnnFilterKeys.length; i++) {
				const filterKey = columnnFilterKeys[i]
				const column = this.columns.find(c => c.field === filterKey)
				column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
			}
			this.paginationOptions2.perPage = savedParams.perPage
			this.paginationOptions2.setCurrentPage = savedParams.page
			this.$nextTick(() => {
				this.$refs.table2.globalSearchTerm = savedParams.searchKey
			})
		},
		clearTableFilters2 () {
			this.$refs.table2.reset()
			this.savedParamsToObjects2(this.defaultParams2)
			this.updateParams2(this.defaultParams2)
		},
		async generateLogText () {
			this.logText = ''
			for (const message of this.log_row_data) {
				console.log(message.message)
				this.logText += `${this.reformatDate(message.createdAt)}    ${message.message} \r\n`
			}
		}
	},
	watch: {
		corvidSelectedPaygateId: async function (val) {
			await this.getEventData()
			await this.getLogData()
		}
	},
	created: async function () {
		await this.getEventData()
		this.defaultParams1 = JSON.parse(JSON.stringify(this.serverParams1))
		// Check if there are any in there?
		const savedParams1 = this.$store.getters.tableFilters('Events')
		if (savedParams1) {
			// If there are, apply them!
			this.savedParamsToObjects1(savedParams1)
			this.updateParams1(savedParams1)
		}
		this.doneParams1 = true

		await this.getLogData()
		this.defaultParams2 = JSON.parse(JSON.stringify(this.serverParams2))
		// Check if there are any in there?
		const savedParams2 = this.$store.getters.tableFilters('Logs2')
		if (savedParams2) {
			// If there are, apply them!
			this.savedParamsToObjects2(savedParams2)
			this.updateParams2(savedParams2)
		}
		this.doneParams2 = true
	}
}
</script>

<style scoped>
textarea {
	font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
		DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
	white-space: pre;
	overflow-wrap: normal;
	overflow: auto;
}
.handPointer td {
	cursor: pointer;
}
</style>
