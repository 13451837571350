<template>
  <div id="importedBanklinePayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Bankline Payments
              <help-icon :docPath="helpUrl"></help-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div v-if="showImportMessages === true">
              <b-card :border-variant="importMessagesVariant" :header="messageHeader" :header-bg-variant="importMessagesVariant" header-text-variant="white">
                <div v-for="msg in importFileResponse.responseMessages" v-bind:key="msg.messageText">
                  <div>{{ msg.messageText }}</div>
                </div>
              </b-card>
              <br />
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1 alertValueRight">
                    <strong>Items</strong>
                  </div>
                  <div class="col-md-2 alertValueRight">
                    <strong>Total (£)</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <b>Group Name</b>
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn btn-link"
                      style="padding: 0 0 0 0; white-space: normal; text-align: left;"
                      v-on:click="this.viewGroupDetails"
                    >{{ submissionData.groupName }}</button>
                  </div>
                  <div class="col-md-2">
                    <b>Reference</b>
                  </div>
                  <div class="col-md-2" style="overflow-wrap: break-word; word-wrap: break-word;">{{ this.submissionData.reference }}</div>
                  <div class="col-md-1">
                    <strong>Credits</strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  >{{ formatCount(importFileResponse.totalNumberOfCredits) }}</div>
                  <div
                    class="col-md-2 alertValueRight"
                  >{{ formatValue(importFileResponse.totalCreditsValue) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <b>Payment Date</b>
                  </div>
                  <div class="col-md-2">{{ displayedPaymentDate }}</div>
                  <div class="col-md-2">
                    <b>{{ submissionData.submissionType }} Processing Date</b>
                  </div>
                  <div class="col-md-2">{{ this.selectedProcessingDate }}</div>
                  <div class="col-md-1">
                    <strong></strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  ></div>
                  <div
                    class="col-md-2 alertValueRight"
                  ></div>
                </div>
                <div class="row">
                  <div v-if="false" class="col-md-2">
                    <b></b>
                  </div>
                  <div v-if="false" class="col-md-2" style="overflow-wrap: break-word; word-wrap: break-word;"></div>
                  <div class="col-md-2">
                    <b>Submission File</b>
                  </div>
                  <div class="col-md-2">{{ this.submissionData.filename }}</div>
                  <div class="col-md-2" style="overflow-wrap: break-word; word-wrap: break-word;">&nbsp</div>
                  <div class="col-md-2" style="overflow-wrap: break-word; word-wrap: break-word;">&nbsp</div>
                  <div class="col-md-1">
                    <strong></strong>
                  </div>
                  <div
                    class="col-md-1 alertValueRight"
                  ></div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
            <div v-if="this.fileBlocks > 1" class="row form-group">
              <div class="col-md-2">
                <b>Select Block</b>
              </div>
              <div class="col-md-2">
                <b-dropdown :text="selectedFile" split variant="outline-secondary">
                  <b-dropdown-item
                    v-for="detail in fileDetails"
                    :key="detail.fileNumber"
                    :value="detail.fileNumber"
                    @click="selectFileDetail(detail)"
                  >{{ detail.filename }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div v-if="totalSubmissionPayments > 0" class="row form-group">
              <div class="col-md-12">
                <vue-good-table
                  ref="payments"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-row-mouseenter="onRowMouseover"
                  @on-row-mouseleave="onRowMouseleave"
                  @on-row-click="onRowClick"
                  :columns="columns"
                  :rows="rows"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: 10,
                    dropdownAllowAll: false,
                    jumpFirstOrLast: true
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'displayProcessingDate'">
                      {{ formatDate(props.row.displayProcessingDate) }}
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="emptystate">
                    Click the
                    <strong>Create Payment</strong> button or the
                    <i class="fa fa-plus-circle pointer dimmedIcon"></i> icon to create the first payment in this submission.
                  </div>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="createBanklinePayment"
                      class
                      :disabled="this.groupUserRights.add === false || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Create a new payment'"
                    >
                      <i class="fa fa-plus pointer dimmedIcon"></i><span class="sr-only">Create New Payment</span>
                    </b-button>
                    <b-button
                      @click.prevent="resetAmountsToZero"
                      class
                      :disabled="this.groupUserRights.edit === false || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Reset amounts to zero'"
                    >
                      <i class="fa fa-power-off dimmedIcon"></i><span class="sr-only">Reset amounts to zero</span>
                    </b-button>
                    <b-button
                      @click.prevent="saveSubmissionFile"
                      class
                      :disabled="this.bacsGroup.groupTypeDetails.importSchema === 'Standard18' || this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Save submission file'"
                    >
                      <i class="fa fa-file pointer dimmedIcon"></i><span class="sr-only">Save Submission File</span>
                    </b-button>
                    <b-button
                      @click.prevent="refreshGrid"
                      class
                      :disabled="this.disableEditing === true"
                      variant="link"
                      v-b-popover.hover.top.d500="'Reset payment table options and filters'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                    </b-button>
                  </div>
                  <div slot="table-actions-bottom">
                    <div class="row" style="padding: 8px 0 8px 8px;">
                      <div class="col-md-2">
                        <button
                          class="btn btn-outline-primary"
                          :disabled="this.disableCreate === true"
                          @click.prevent="createBanklinePayment"
                        >Add Payment</button>
                      </div>
                    </div>
                  </div>
                </vue-good-table>
              </div>
            </div>
            <div v-if="totalSubmissionPayments === 0">
              <br />
              <div class="row form-group">
                <div class="col-md-12">
                  Click the
                  <strong>Add Payment</strong> button to create the first payment in this submission.
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    class="btn btn-outline-primary"
                    :disabled="this.disableCreate === true"
                    @click.prevent="createBanklinePayment"
                  >Add Payment</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="this.disableValidatePayments"
              v-on:click="initPresubVal"
            >Validate Payments</button>
            <button
              type="button"
              class="btn btn-outline-secondary ml-3"
              :disabled="this.validationStarted === true"
              v-on:click="changeSubmission"
            >Change Submission</button>
            <button v-show="validationStarted"
              type="button"
              class="btn btn-danger pull-right"
              :disabled="this.validationCancelled === true"
              v-on:click="cancelValidation"
            >Cancel Validation</button>
            <div v-show="validationStarted" class="row form-group"></div>
            <div v-show="validationStarted" class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
                  <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <editBanklinePayment
        v-model="isModalVisible"
        :payment="currentPayment"
        :newPayment="newPayment"
        :canSavePayment="canSavePayment"
        :canDeletePayment="canDeletePayment"
        :submissionReference="submissionData.reference"
        :importResponse="importFileResponse"
        :maxAmount="maxAmount"
        @deleteBanklinePayment="banklinePaymentDeleted"
        @saveBanklinePayment="banklinePaymentSaved"
        @close="editCancelled"
      ></editBanklinePayment>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditBanklinePayment from '@/Views/Bankline/BanklinePayments/EditBanklinePayment.vue'
import bacsCommon from '@/Lib/BacsCommon.js'
import bacsMixin from '@/Lib/BacsMixin.js'
import moment from 'moment'
import groupTypes from '@/Assets/Constants/groupTypes'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  name: 'viewImportedBanklinePayments',
  mixins: [
    bacsMixin
  ],
  components: {
    editBanklinePayment: EditBanklinePayment
  },
  data () {
    return {
      submissionData: {},
      importFileResponse: {},
      columns: [
        {
          label: 'PaymentId',
          field: 'paymentId',
          hidden: true
        },
        {
          label: 'Third Party Name',
          field: 'thirdPartyAccountName',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'User Reference',
          field: 'userReference',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Amount (£)',
          field: 'amount',
          type: 'number',
          formatFn: this.formatAmount,
          thClass: 'text-right',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            trigger: 'enter'
          }
        },
        {
          label: 'Message',
          field: 'message',
          hidden: true
        }
      ],
      rows: [],
      MESSAGECOLUMN: 6,
      totalRecords: 0,
      serverParams: {
        submissionId: '',
        showValidationMessages: this.showValidationMessages,
        paymentsWithMessages: this.paymentsWithMessages,
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '' // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 10 // how many items I'm showing per page
      },
      validationStarted: false,
      showValidationMessages: false,
      paymentsWithMessages: 0,
      editAction: false,
      validationCancelled: false,
      canStartValidation: false,
      max: 100,
      progress: 0,
      fileNumber: 1,
      fileBlocks: 0,
      fileDetails: {},
      getTaskProgressUrl: '',
      endTaskUrl: '',
      groupBankAccount: {},
      isModalVisible: false,
      currentPayment: {},
      uneditedPayment: {},
      newPayment: false,
      selectedPaymentDate: '',
      selectedProcessingDate: '',
      displayedPaymentDate: '',
      disabledDates: {},
      disableDatePicker: true,
      selectedGroup: {},
      bacsGroup: {},
      groupUserRights: {},
      canSavePayment: false,
      canDeletePayment: false,
      row: {},
      totalSubmissionPayments: 0,
      paymentsWithValidationIssues: 0,
      disableEditing: false,
      userDetails: {},
      paygateType: '',
      vol1ServiceUserNumber: '',
      paymentsLoaded: false,
      maxAmount: 0,
      importErrors: false,
      showImportMessages: false,
      changingSubmission: false,
      importMessagesVariant: '',
      messageHeader: '',
      messageText: '',
      helpUrl: '',
      savedSubmissionInProgress: false,
      invalidRoute: false,
      selectedFile: '' // Only relevant for multi-block PGO Standard18 files.,
    }
  },
  computed: {
    disableCreate: function () {
      return this.groupUserRights.add === false || this.disableEditing === true || this.validationStarted === true || this.importErrors === true
    },
    disableValidatePayments: function () {
      return this.validationStarted === true || this.totalSubmissionPayments === 0 || this.importErrors === true
    },
    displayPaymentDate: function () {
      return this.displayedPaymentDate
    }
  },
  methods: {
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },

    async viewGroupDetails () {
      this.groupBankAccount = this.$store.getters.groupBankAccount
      await swal.fire({
        title: '"' + this.submissionData.groupName + '" group bank details',
        html: bacsCommon.viewGroupBankDetails(this.groupBankAccount, this.submissionData.groupName),
        type: 'info',
        animation: false
      })
    },

    initPresubVal () {
      return axios.get(this.endTaskUrl, {
      })
        .then(() => {
          this.startPresubVal()
        })
        .catch(error => console.log(error))
    },

    startPresubVal () {
      this.validationStarted = true
      this.validationCancelled = false
      this.canStartValidation = false
      this.progress = 0

      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        groupId: this.submissionData.groupId,
        reference: this.submissionData.reference,
        totalNumberOfPayments: this.importFileResponse.totalNumberOfPayments,
        paymentLimit: this.bacsGroup.groupTypeDetails.paymentLimit,
        submissionLimit: this.bacsGroup.groupTypeDetails.submissionLimit,
        userPaymentLimit: this.userDetails.paymentLimit,
        userSubmissionLimit: this.userDetails.submissionLimit
      })

      axios({
        method: 'POST',
        url: process.env.VUE_APP_BANKLINE_API_URL + 'bankline/validation/post/',
        data: json
      })

      var id = setInterval(() => {
        return axios.get(this.getTaskProgressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (this.progress >= 100) {
              clearInterval(id)
              this.validationStarted = false
              return axios.get(this.endTaskUrl, {
              })
                .then(() => {
                  setTimeout(() => {
                    this.$router.push('/bankline/banklinePayments/validationResult').catch(err => { console.log(err) })
                  }, 1000)
                })
            } else if (this.progress === -1) {
              clearInterval(id)
              this.validationStarted = false
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    },

    cancelValidation () {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelTask?submissionId=' + this.submissionData.submissionId
      return axios.get(url, {
      })
        .then(response => {
          this.validationCancelled = true
          this.canStartValidation = true
          this.validationStarted = false
          this.$toastr.w('Pre-submission validation cancelled')
        })
    },

    async changeSubmission () {
      var result = await swal.fire({
        title: 'Change Submission?',
        text: 'Your submission details will be lost. Do you want to change the submission?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        this.changingSubmission = true
        this.$router.push('/payments/fasterpayments/' + this.submissionData.createPage + '?changeSubmission=true')
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems(false)
    },

    onPerPageChange (params) {
      // This method is called when page mounted so only want to load items AFTER payments have been loaded and items per page changed by the user.
      if (this.paymentsLoaded === true) {
        this.rows.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems(true)
      }
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems(true)
    },

    onColumnFilter (params) {
      this.appliedFilter = true
      this.updateParams(params)
      this.loadItems(true)
    },

    onRowMouseover (params) {
    },
    onRowMouseleave (params) {
    },
    onRowMouseover (params) {
    },
    onRowMouseleave (params) {
    },

    async onRowClick (params) {
      if (this.groupUserRights.edit === false && this.groupUserRights.delete === false) {
        this.$snapbar.w('You cannot edit or delete payments in a submission linked to this group')
        return
      }

      this.newPayment = false
      this.canSavePayment = this.groupUserRights.edit
      this.canDeletePayment = this.groupUserRights.delete
      this.currentPayment = params.row
      this.uneditedPayment = Object.assign({}, this.currentPayment)
      this.isModalVisible = true
    },

    editCancelled () {
      this.currentPayment.thirdPartyAccountName = this.uneditedPayment.thirdPartyAccountName
      this.currentPayment.thirdPartyAccountNumber = this.uneditedPayment.thirdPartyAccountNumber
      this.currentPayment.thirdPartySortCode = this.uneditedPayment.thirdPartySortCode
      this.currentPayment.userReference = this.uneditedPayment.userReference
      this.currentPayment.transactionCode = this.uneditedPayment.transactionCode
      this.currentPayment.amount = this.uneditedPayment.amount
      this.isModalVisible = false
    },

    banklinePaymentSaved () {
      if (this.newPayment) {
        this.totalRecords++
        this.newPayment = false
      }
      this.loadItems(false)
      this.isModalVisible = false
    },

    banklinePaymentDeleted () {
      this.loadItems(true)
      this.totalRecords--
      this.isModalVisible = false
    },

    async createBanklinePayment () {
      if (this.groupUserRights.add === false) {
        this.$snapbar.w('You cannot add payments to a submission linked to this group')
        return
      }

      this.newPayment = true
      this.canSavePayment = true
      this.canDeletePayment = false
      this.currentPayment = {}
      this.currentPayment.submissionId = this.submissionData.submissionId
      this.currentPayment.thirdPartyAccountName = ''
      this.currentPayment.thirdPartySortCode = ''
      this.currentPayment.thirdPartyAccountNumber = ''
      this.currentPayment.userReference = ''
      this.currentPayment.amount = 0
      this.currentPayment.message = this.submissionData.reference

      if (this.groupBankAccount.reference === undefined) {
        this.groupBankAccount = this.$store.getters.groupBankAccount
      }

      this.currentPayment.originatorAccountName = this.groupBankAccount.reference
      this.currentPayment.originatorSortCode = this.groupBankAccount.sortCode
      this.currentPayment.originatorAccountNumber = this.groupBankAccount.accountNumber
      this.isModalVisible = true
    },

    async getMaxAmount () {
      let response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPaymentLimit?paymentNetworkType=SecureIp`)
      this.maxAmount = response.data
    },

    async refreshGrid () {
      this.serverParams.columnFilters = {}
      this.serverParams.sort.field = ''
      this.serverParams.sort.type = ''
      this.serverParams.page = 1
      this.serverParams.perPage = 10
      this.updateParams(this.serverParams)
      this.$refs.payments.reset()
      this.$refs.payments.$refs.paginationBottom.perPage = this.serverParams.perPage
      this.$refs.payments.$refs.paginationBottom.handlePerPage()
      await this.loadItems(true)
    },

    async loadItems (resetStart) {
      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showValidationMessages: this.showValidationMessages,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: resetStart,
        columnFilters: this.serverParams.columnFilters,
        editAction: this.editAction,
        paymentsWithMessages: this.paymentsWithMessages
      })

      var response = await axios.post(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/payment/get/`, json, { showload: true })
      this.rows = response.data.payments
      if (this.rows.length > 0) {
        if (response.data.filtersSet === 0) {
          this.totalRecords = this.importFileResponse.totalNumberOfCredits
        } else {
          this.totalRecords = response.data.filteredRecordCount
        }
      }

      this.totalSubmissionPayments = this.totalRecords

      if (this.showValidationMessages) {
        this.columns[this.MESSAGECOLUMN].hidden = false
        this.columns[this.MESSAGECOLUMN].width = '500px'
      } else {
        this.columns[this.MESSAGECOLUMN].hidden = true
      }

      this.paymentsWithValidationIssues = response.data.paymentsWithValidationIssues
      this.paymentsLoaded = true
    },

    formatAmount (value) {
      return this.formatValue(value)
    },

    async getUserDetails () {
      var userId = this.$store.getters.getClaim('sub').value
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'users/limits/' + userId
      var response = await axios.get(url)
      this.userDetails = response.data

      if (this.userDetails.paymentLimit === null) {
        this.userDetails.paymentLimit = 0
      }

      if (this.userDetails.submissionLimit === null) {
        this.userDetails.submissionLimit = 0
      }
    },

    async showFileImportMessages () {
      var licenceError = false

      for (var i = 0; i < this.importFileResponse.responseMessages.length; i++) {
        this.messageText += this.importFileResponse.responseMessages[i].messageText
        if (this.importFileResponse.responseMessages[i].messageTitle !== null && this.importFileResponse.responseMessages[i].messageTitle === 'LICENCE') {
          licenceError = true
          this.disableEditing = true
          this.importErrors = true
          this.$snapbar.w(this.importFileResponse.responseMessages[i].messageText)
        }
      }

      if (!licenceError) {
        this.showImportMessages = true
        if (this.importFileResponse.responseMessages[0].messageSeverity === 2) {
          this.messageHeader = 'File Import Errors'
          this.importMessagesVariant = 'danger'
          this.importErrors = true
        } else if (this.importFileResponse.responseMessages[0].messageSeverity === 1) {
          this.messageHeader = 'File Import Warnings'
          this.importMessagesVariant = 'warning'
        }
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (this.validationStarted) {
      return
    }
    if (to.path.endsWith('validationResult') || this.changingSubmission || this.invalidRoute) {
      next()
    } else {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'Your submission details will be lost. Do you want to navigate away from this page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        if (to.path.endsWith('importingBanklinePayments')) {
          this.$store.dispatch('setBrowserBackButtonClicked', true)
        }
        // await this.unlockSubmission()
        if (this.submissionData.submissionId !== undefined && this.submissionData.submissionId !== null) {
          await this.$store.dispatch('clearImportFileResponse', this.submissionData.submissionId)
        }
        next()
      } else {
        next(false)
      }
    }
  },

  async mounted () {
    this.submissionData = this.$store.getters.submissionData

    this.selectedGroup = this.$store.getters.selectedGroup
    this.bacsGroup = this.$store.getters.bacsGroup

    if (Object.entries(this.submissionData).length === 0 && this.submissionData.constructor === Object) {
      this.$snapbar.e('No submission or payment data can be found. This can happen if the browser is refreshed by pressing F5. Please create a new submission.')
    }

    this.paygateType = 'Bankline'

    if (this.submissionData.filename !== undefined && this.submissionData.filename !== null && this.submissionData.filename !== 'N/A') {
      this.helpUrl = '/payments/bankline-payments/createbanklinesubmissionfromfile/'
    } else {
      this.helpUrl = '/payments/bankline-payments/createbanklinesubmissionusingeditor/'
    }

    var userId = this.$store.getters.getClaim('sub').value
    this.groupUserRights = this.bacsGroup.groupUserRights.find(i => i.userId === userId)
    let routeShowValidationMessages = this.$route.query.showValidationMessages
    if (routeShowValidationMessages === undefined || routeShowValidationMessages === 'false') {
      this.showValidationMessages = false
    } else {
      this.showValidationMessages = true
      let validationResponse = this.$store.getters.validationResponse
      this.paymentsWithMessages = validationResponse.paymentsWithMessages
    }

    var routeEditAction = this.$route.query.editAction
    if (routeEditAction === undefined || routeEditAction === 'false') {
      this.editAction = false
    } else {
      this.editAction = true
    }

    // Use BACS methods to get progress.
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionData.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionData.submissionId

    // Show the user any file import issues.
    this.importFileResponse = this.$store.getters.importFileResponse
    if (Object.entries(this.importFileResponse).length > 0 && this.importFileResponse.constructor === Object) {
      if (this.importFileResponse.responseMessages !== undefined &&
        this.importFileResponse.responseMessages !== null &&
        this.importFileResponse.responseMessages.length > 0 &&
        this.showValidationMessages === false) {
        this.showFileImportMessages()
      }

      // if (this.showValidationMessages || this.editAction) {
      //   this.recalcTotals()
      // }
    }

    await this.getMaxAmount()
    await this.getUserDetails()
    await this.loadItems(true)
    this.totalSubmissionPayments = this.importFileResponse.totalNumberOfCredits
    this.displayedPaymentDate = this.formatDate(this.submissionData.paymentDate)
    this.selectedProcessingDate = this.formatDate(this.submissionData.paymentDate)
  }
}
</script>
