<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Match Quality</h2>
    </div>
    <div class="idb-block-content">
      <doughnut-chart :chart-data="accuracyChart.data" :options="accuracyChart.options" :height="300"></doughnut-chart>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import randomColor from 'randomcolor'
import DoughnutChart from '@/Assets/Components/Charts/DoughnutChart'
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    DoughnutChart
  },
  props: {
    sessionId: {
      type: String,
      required: true
    }
  },
  methods: {
    async load() {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/charts/copaccuracy/${this.sessionId}`)
      let labels = []
      let networkData = []
      let colours = []
      for(var i = 0; i < response.data.length; i++) {
        labels.push(response.data[i].accuracy)
        networkData.push(response.data[i].accuracyCount)
        colours.push(randomColor({ hue: 'blue', luminosity: 'dark', seed: (i*50).toString() }))
      }
      this.accuracyChart.data = {
        labels: labels,
        datasets: [{
          data: networkData,
          backgroundColor: colours,
        }]
      }
    }
  },
  data() {
    return {
      accuracyChart: {
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'bottom',
            // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
            onClick: function () { }
          }
        }
      },
    }
  },
  async mounted() {
    await this.load()
  }
}
</script>
