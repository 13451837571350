<template>
  <div>
    <div v-if="!showItemTable && itemsLoaded" class="row form-group">
      <div class="col-md-12">
        Create or upload items to this anti-fraud list.
      </div>
    </div>
    <div v-if="!showItemTable && itemsLoaded" class="row form-group">
      <div class="col-md-4">
        <button
          class="btn btn-primary"
          :disabled="this.isItemActioned"
          @click.prevent="uploadItemsFromFile"
        >Upload Items From File
        </button>
      </div>
      <div class="col-md-4">
        <button
          class="btn btn-outline-primary"
          :disabled="this.isItemActioned"
          @click.prevent="addNewItem"
        >Add New Item
        </button>
      </div>
      <div class="col-md-4">
        <button
          class="btn btn-outline-primary"
          :disabled="this.isItemActioned"
          @click.prevent="uploadItemsFromSubmission"
        >Upload Items From Submission
        </button>
      </div>
    </div>
    <div v-if="showDropzone" class="row form-group">
      <div id="dzUpload" class="col-md-12">
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          v-bind:use-custom-dropzone-options="true"
          :options="dropzoneOptions"
          v-on:vdropzone-success="afterSuccess"
          v-on:vdropzone-file-added="fileAdded"
          v-on:vdropzone-sending="sending"
        ></vue-dropzone>
      </div>
    </div>
    <div v-if="showItemTable" class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          ref="antiFraudListItems"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
          @on-row-click="onRowClick"
          :columns="columns"
          :rows="antiFraudListItems"
          :lineNumbers="true"
          :totalRows="itemCount"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false,
            jumpFirstOrLast: true
          }"
          styleClass="vgt-table striped bordered"
        >
          <div slot="table-actions">
            <b-button
              @click.prevent="uploadItemsFromFile"
              class
              :disabled="this.isItemActioned"
              variant="link"
              v-b-popover.hover.top.d500="'Upload items from file'"
            >
              <i class="fa fa-upload pointer dimmedIcon"></i><span class="sr-only">Upload items from file</span>
            </b-button>
            <b-button
              @click.prevent="addNewItem"
              class
              :disabled="this.isItemActioned"
              variant="link"
              v-b-popover.hover.top.d500="'Create a new anti-fraud item'"
            >
              <i class="fa fa-plus-circle pointer dimmedIcon"></i><span class="sr-only">Create a new anti-fraud module</span>
            </b-button>
            <b-button
              @click.prevent="uploadItemsFromSubmission"
              class
              :disabled="this.isItemActioned"
              variant="link"
              v-b-popover.hover.top.d500="'Upload items from a previous submission'"
            >
              <i class="fa fa-database pointer dimmedIcon"></i><span class="sr-only">Upload items from a previous submission</span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
    <div>
      <editAntiFraudItemModal
        v-model="isModalVisible"
        :item="currentItem"
        :newItem="newItem"
        @deleteItem="itemDeleted"
        @saveItem="itemSaved"
        @close="editCancelled"
      ></editAntiFraudItemModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Utility from '@/Assets/Mixins/Utility'
import EditAntiFraudItemModal from '@/Components/Platform/AntiFraud/EditAntiFraudItemModal.vue'

export default {
  mixins: [Utility],
  props: ['status', 'selectedAntiFraudListId', 'isItemActioned', 'antiFraudList'],
  components: {
    editAntiFraudItemModal: EditAntiFraudItemModal
  },
  computed: {
    showItemTable () {
      return this.itemCount > 0
    },
    detailsLoaded () { return this.$store.getters.parentDataLoaded }
  },
  data: () => ({
    itemCount: 0,
    bearerToken: '',
    dropzoneOptions: {
      url: '',
      language: {
        dictDefaultMessage: 'Drop a file here or click to select a file to upload.'
      },
      timeout: 180000,
      maxNumberOfFiles: 1,
      maxFileSizeInMB: 200 // MBs
    },
    showDropzone: false,
    antiFraudListItems: [],
    antiFraudMessages: [],
    columns: [
      {
        label: 'Account Name',
        field: 'thirdPartyAccountName',
        width: '50%'
      },
      {
        label: 'Sort Code',
        field: 'thirdPartySortCode',
        width: '25%'
      },
      {
        label: 'Account Number',
        field: 'thirdPartyAccountNumber',
        width: '25%'
      }
    ],
    serverParams: {
      antiFraudListId: '',
      // a map of column filters example: {name: 'john', age: '20'}
      columnFilters: {
      },
      sort: {
        field: '', // example: 'name'
        type: '' // 'asc' or 'desc'
      },

      page: 1, // what page I want to show
      perPage: 10 // how many items I'm showing per page
    },
    // tableMode: 'local',
    isModalVisible: false,
    currentItem: {},
    newItem: false,
    submissionId: '',
    emitArgs: {},
    originalItem: {},
    antiFraudListId: '',
    itemsLoaded: false
  }),
  methods: {
    uploadItemsFromFile () {
      this.showDropzone = true
    },

    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },

    async sending (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
    },

    async afterSuccess (file, response) {
      this.showDropzone = false
      await this.importItemsFromFile(response.filename)
    },

    async importItemsFromFile (filename) {
      var json = JSON.stringify({
        antiFraudListId: this.antiFraudListId !== undefined ? this.antiFraudListId : '00000000-0000-0000-0000-000000000000',
        filename: filename
      })

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/importAntiFraudItems`, json, { showload: true }
        )
        if (response.data.success) {
          this.itemCount += response.data.total
          await this.loadItems()
          this.itemsChanged()
        } else {
          this.$toastr.e(response.data.errorMessage)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    itemsChanged () {
      this.$emit('itemsChanged')
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange (params) {
      // This method is called when page mounted so only want to load items AFTER payments have been loaded and items per page changed by the user.
      if (this.paymentsLoaded === true) {
        this.antiFraudListItems.length = 0
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
      }
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
      this.loadItems(true)
    },

    onRowClick (params) {
      if (!this.isItemActioned) {
        this.newItem = false
        this.currentItem = params.row
        this.isModalVisible = true
        this.originalItem = { thirdPartyAccountName: this.currentItem.thirdPartyAccountName, thirdPartySortCode: this.currentItem.thirdPartySortCode, thirdPartyAccountNumber: this.currentItem.thirdPartyAccountNumber }
      }
    },

    async getItemCount () {
      const response = await axios.get(
        `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/getItemCount/${this.antiFraudListId}`
      )
      this.itemCount = response.data
    },

    async loadItems () {
      var json = JSON.stringify({
        antiFraudListId: this.antiFraudListId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        columnFilters: this.serverParams.columnFilters
      })

      const response = await axios.post(
        `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/getItems`, json
      )
      this.antiFraudListItems = response.data
    },

    addNewItem () {
      this.newItem = true
      this.currentItem = {}
      this.currentItem.antiFraudListId = this.antiFraudListId
      this.currentItem.paygateId = this.$store.state.common.paygateId
      this.currentItem.thirdPartyAccountName = ''
      this.currentItem.thirdPartySortCode = ''
      this.currentItem.thirdPartyAccountNumber = ''
      this.isModalVisible = true
    },

    async itemSaved (editedItem) {
      this.currentItem.thirdPartyAccountName = editedItem.thirdPartyAccountName
      this.currentItem.thirdPartySortCode = editedItem.thirdPartySortCode
      this.currentItem.thirdPartyAccountNumber = editedItem.thirdPartyAccountNumber
      let response = {}
      try {
        if (this.newItem) {
          response = await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/createAntiFraudListItem`, this.currentItem, { showload: true }
          )
        } else {
          response = await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/updateAntiFraudListItem`, this.currentItem, { showload: true }
          )
        }

        if (response.data.status !== 'Failed') {
          this.$toastr.s(response.data.toastMessage)
          if (this.newItem) {
            this.itemCount++
          }
          await this.loadItems()
          this.itemsChanged()
        } else {
          this.$toastr.e(response.data.errorText)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      this.isModalVisible = false
      this.newItem = false
    },

    async itemDeleted () {
      this.isModalVisible = false
      try {
        const response = await axios.delete(
            `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/deleteAntiFraudListItem?antiFraudListItemId=${this.currentItem.id}`, { showload: true }
        )
        if (response.data.status !== 'Failed') {
          this.$toastr.s(response.data.toastMessage)
          this.itemCount--
          await this.loadItems()
          this.itemsChanged()
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
      this.currentItem = {}
    },

    editCancelled () {
      this.isModalVisible = false
      this.newItem = false
    },

    uploadItemsFromSubmission () {
      this.$store.dispatch('setAntiFraudList', this.antiFraudList)
      const storedAntiFraudList = this.$store.getters.antiFraudList
      const queryParam = this.status === 'Edit' ? this.antiFraudListId : 'create'
      this.$router.push(`/payments/search/searchSubmissions?antiFraudListId=${queryParam}`)
    },

    async loadItemsFromSubmission () {
      try {
        var json = JSON.stringify({
          antiFraudListId: this.antiFraudListId !== undefined ? this.antiFraudListId : '00000000-0000-0000-0000-000000000000',
          submissionId: this.submissionId
        })

        const response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/loadItemsFromSubmission`, json, { showload: true }
        )
        if (response.data.success) {
          if (response.data.total == 0) {
            this.$snapbar.w('The selected submission does not contain any payments')
          }
          this.itemCount += response.data.total
          await this.loadItems()
          this.itemsChanged()
        } else {
          this.$toastr.e(response.data.errorMessage)
        }
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async deletePendingItems () {
      try {
        const response = await axios.delete(
           `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/deletePendingAntiFraudListItems?antiFraudListItemId=${this.antiFraudListId}`, { showload: true }
        )
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async waitForParent () {
      var looping = true
      while (looping) {
        if (this.detailsLoaded === false) {
          await new Promise(resolve => setTimeout(resolve, 100))
        } else {
          looping = false
        }
      }
    }
  },
  created () {
    this.dropzoneOptions.url = process.env.VUE_APP_PLATFORM_API_URL + 'antiFraudList/uploadFile'
  },
  async mounted () {
    try {
      await this.waitForParent()
      this.antiFraudListId = this.selectedAntiFraudListId
      if (this.status === 'Edit') {
        await this.deletePendingItems()
      }

      auth.getAccessToken()
        .then(token => {
          this.bearerToken = 'Bearer ' + token
        })

      if (this.antiFraudListId !== undefined) {
        await this.getItemCount()
        if (this.itemCount > 0) {
          await this.loadItems()
        }
        this.itemsLoaded = true
      }

      this.submissionId = this.$route.query.submissionId
      if (this.submissionId !== undefined && this.submissionId !== 'cancel') {
        await this.loadItemsFromSubmission()
      }
    } catch (e) {
      console.log(e)
      this.$toastr.e(e)
    }
  }
}
</script>
