<template>
  <div id="sendBanklineSubmission">
    <div v-show="actionLoaded === true && !canDeleteAction">
      <div>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="startSendSubmission"
          :disabled="this.enableSend === false"
        >Send Submission</button>
        <button
          type="button"
          class="btn btn-danger pull-right"
          v-on:click="cancelSubmission"
          :disabled="this.enableSend === false && this.canCancel === false"
        >Cancel Submission</button>
      </div>
    </div>
    <div v-show="canDeleteAction">
      <button
        type="button"
        class="btn btn-danger pull-right"
        v-on:click="deleteAction"
      >Delete Orphaned Action</button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import bacsCommon from '@/Lib/BacsCommon.js'
  import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
  import Plugin from '@/Assets/Components/UsbTokens/Plugin'
  import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
  import groupTypes from '@/Assets/Constants/groupTypes'
  import moment from 'moment'
  import bacsMixin from '@/Lib/BacsMixin.js'
  
  export default {
    components: {
      plugin: Plugin
    },
    computed: {
      enableSend: function () {
        return this.isLoaded === true && this.canProceed === true
      },
      actionLoaded () { return this.$store.getters.parentBanklineDataLoaded }
    },
    mixins: [
      bacsMixin
    ],
    data () {
      return {
        submissionId: '',
        actionId: '',
        isLoaded: false,
        banklineGroup: {},
        password: '',
        canProceed: true,
        canCancel: true,
        canDeleteAction: false
      }
    },
    methods: {
      async startSendSubmission () {
        this.canProceed = false
  
        // First check that the action can proceed i.e. someone else hasn't got in there first and cancelled the submission or something!
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          nextStatus: 'Submitted'
        })
  
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
          data: json
        })
  
        if (response.data.success) {
          await this.sendSubmission()
        } else {
          this.$snapbar.w(response.data.errorMessage)
          this.canProceed = false
          this.canCancel = false
          this.canDeleteAction = response.data.tag
        }
      },
  
      async sendSubmission () {
        this.canProceed = false
        this.canCancel = false
  
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          createAction: false,
          deletePaymentData: this.banklineGroup.groupTypeDetails.deletePaymentData
        })
  
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BANKLINE_API_URL + 'bankline/comms/sendBanklinePayments',
          data: json,
          showload: true
        })
  
        console.log('send response: ' + JSON.stringify(response.data))
  
        if (response.data.errorText === null) {
          this.$toastr.i('Your payments are being sent to Bankline. A confirmation action will be created once the payments have been made.')
        } else {
          this.$snapbar.e(response.data.errorText)
          this.canProceed = !response.data.success
          this.canCancel = !response.data.success
          return
        }

        const actionLoadedFromPath = this.$store.getters.actionLoadedFromPath
        console.log('actionLoadedFromPath: ' + actionLoadedFromPath)
        if (actionLoadedFromPath === '/actions') {
          this.$router.push('/actions')
        } else {
          this.$router.push('/')
        }
      },
  
      async cancelSubmission () {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          nextStatus: 'Submitted'
        })
  
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
          data: json
        })
  
        if (response.data.success) {
          this.isLoaded = false
          var cancelReponse = await bacsCommon.cancelSubmissionDialog()
          if (cancelReponse.cancel === true) {
            json = JSON.stringify({
              submissionId: this.submissionId,
              actionId: this.actionId,
              deletePaymentData: this.banklineGroup.groupTypeDetails.deletePaymentData,
              additionalInfo: cancelReponse.cancellationReason
            })
  
            var response = await axios.post(`${process.env.VUE_APP_BANKLINE_API_URL}bankline/submission/cancelSubmission/`, json, { showload: true })  
            if (response.data.length === 0) {
              this.$toastr.s('Bankline submission cancelled')
            } else {
              this.$toastr.e(response.data)
            }
            this.$router.push('/actions')
          } else {
            var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
            await axios.get(endTaskUrl)
            this.isLoaded = true
          }
        } else {
          this.$snapbar.w(response.data.errorMessage)
          this.canProceed = false
          this.canCancel = false
          this.canDeleteAction = response.data.tag
        }
      },
  
      async waitForParent () {
        var looping = true
        while (looping) {
          if (this.actionLoaded === false) {
            await new Promise(resolve => setTimeout(resolve, 500))
          } else {
            looping = false
          }
        }
      },
  
      async checkBanklineSubmission () {
        var today = new Date()
        var todayDateFormatted = moment(today).format('YYYY-MM-DD')
        var outOfDateSubmission = false
        var banklineSubmission = this.$store.getters.banklineSubmission
        var paymentDate = moment(banklineSubmission.createdDate).format('YYYY-MM-DD')
  
        if (todayDateFormatted > paymentDate) {
          outOfDateSubmission = true
        }
  
        if (outOfDateSubmission) {
          this.canProceed = false
          this.$snapbar.e('This submission cannot be sent to Bankline because the date for this Faster Payment submission has passed.')
        }
      },
  
      async unlockSubmission () {
        var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
        await axios.get(endTaskUrl)
      },
  
      async deleteAction () {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.actionId
        await axios.delete(url)
        this.$router.push('/actions')
      }
    },
  
    async mounted () {
      await this.waitForParent()
      this.submissionId = this.$store.getters.submissionId
      this.actionId = this.$store.getters.actionId
      this.banklineGroup = this.$store.getters.bacsGroup
  
      await this.checkBanklineSubmission()
  
      window.setTimeout(() => {
        this.isLoaded = true
      }, 1000)
  
      this.isLoaded = true
    },
  
    destroyed () {
      if (this.browser === 'Firefox') {
        document.removeEventListener('getPluginResponseWebPage', this.initPluginEvent)
        document.removeEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
      }
    }
  }
  </script>
  