import { render, staticRenderFns } from "./PreSubmissionValidation.vue?vue&type=template&id=5f12caf7&scoped=true"
import script from "./PreSubmissionValidation.vue?vue&type=script&lang=js"
export * from "./PreSubmissionValidation.vue?vue&type=script&lang=js"
import style0 from "./PreSubmissionValidation.vue?vue&type=style&index=0&id=5f12caf7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f12caf7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f12caf7')) {
      api.createRecord('5f12caf7', component.options)
    } else {
      api.reload('5f12caf7', component.options)
    }
    module.hot.accept("./PreSubmissionValidation.vue?vue&type=template&id=5f12caf7&scoped=true", function () {
      api.rerender('5f12caf7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Components/Bureau/BureauJob/PreSubmissionValidation.vue"
export default component.exports