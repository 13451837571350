<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>Valid / Invalid Data</h2>
    </div>
    <div class="idb-block-content">
      <doughnut-chart :chart-data="validInvalidChart.data" :options="validInvalidChart.options" :height="300"></doughnut-chart>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import randomColor from 'randomcolor'
import DoughnutChart from '@/Assets/Components/Charts/DoughnutChart'
export default {
  mixins: [tableFilterMixin, loading],
  components: {
    DoughnutChart
  },
  props: {
    sessionId: {
      type: String,
      required: true
    },
    sessionType : {
      type: Number,
      required: true
    }
  },
  methods: {
    async load() {
      var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/charts/validity/${this.sessionType}/${this.sessionId}`)
      this.validCount = response.data.valid
      this.invalidCount = response.data.invalid
      this.validInvalidChart.data = {
        labels: ['Valid', 'Invalid'],
        datasets: [{
          data: [this.validCount, this.invalidCount],
          backgroundColor: ['#0b6810','#a30e20']
        }]
      }
    }
  },
  data() {
    return {
      validCount: 0,
      invalidCount: 0,
      validInvalidChart: {
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'bottom',
            // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
            onClick: function () { }
          }
        }
      },
    }
  },
  async mounted() {
    await this.load()
  }
}
</script>
