<template>
	<div>
		<form @submit.prevent="setSaved() && checkValidation() && saveGroup()" novalidate>
			<div class="idb-block">
				<div class="idb-block-title">
					<h2>
						{{ status }} Group
						<help-icon :docPath="docPath" />
					</h2>
				</div>
				<div class="idb-block-content">
					<div
						class="alert alert-warning"
						v-if="group.isItemActioned"
					>This group has a pending action against it and cannot be edited</div>
					<div class="alert alert-warning" v-if="!approvable">
						<b>Warning</b> This group does not have enough approvers
					</div>
					<b-tabs @input="changedTab" ref="tabs">
						<b-tab
							title="Group Details"
							:title-link-class="{
                invalid: $v.group.mainGroupValidation.$error,
              }"
							active
						>
							<!-- Reference -->
							<div class="form-group row" :class="{ invalid: $v.group.name.$error }">
								<label for="group-name-input" class="col-form-label col-md-3 required">Name</label>
								<div class="col-md-6">
									<input
										id="group-name-input"
										type="text"
										class="form-control"
										v-model.trim="$v.group.name.$model"
										:disabled="group.isItemActioned"
									/>
									<!-- Validation -->
									<validation-messages v-model="$v.group.name" name="name"></validation-messages>
								</div>
							</div>

							<!-- Description -->
							<div class="form-group row" :class="{ invalid: $v.group.description.$error }">
								<label for="group-description-input" class="col-form-label col-md-3">Description</label>
								<div class="col-md-6">
									<textarea
										id="group-description-input"
										rows="3"
										class="form-control"
										v-model.trim="$v.group.description.$model"
										:disabled="group.isItemActioned"
									></textarea>
									<!-- Validation -->
									<validation-messages v-model="$v.group.description" name="description"></validation-messages>
								</div>
							</div>
							<!-- Colour -->
							<div class="form-group row" :class="{ invalid: $v.group.description.$error }">
								<label for="colour-tag-input" class="col-form-label col-md-3">Colour Tag</label>
								<div class="col-md-6">
									<swatches id="colour-tag-input" v-model="group.colour" />
									<!-- Validation -->
									<validation-messages v-model="$v.group.description" name="description"></validation-messages>
								</div>
							</div>

							<!-- Group Type -->
							<div class="form-group row" :class="{ invalid: $v.group.groupType.$error }">
								<label class="col-form-label col-md-3 required" for="group-type-input">Type</label>
								<div class="col-md-6">
									<b-form-select
										id="group-type-input"
										v-if="status === 'Create'"
										v-model="$v.group.groupType.$model"
										:options="availableGroupTypes"
										title="Type"
										@input="$v.$reset()"
									></b-form-select>
									<input
										id="group-type-input"
										v-if="status !== 'Create'"
										type="text"
										disabled
										class="form-control"
										v-model="$v.group.groupType.$model"
									/>
									<!-- Validation -->
									<validation-messages v-model="$v.group.groupType" name="type"></validation-messages>
									<small class="form-text text-muted">
										This is the group type, once created it cannot be
										altered
									</small>
								</div>
							</div>
						</b-tab>
						<b-tab title="Group Users" :title-link-class="{ invalid: $v.group.groupUserRights.$error }">
							<group-users v-if="loadUsers" :group="$v.group"></group-users>
						</b-tab>
						<b-tab
							v-if="group.groupType === groupTypes.bacs"
							title="BACS Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.groupTypeDetails.$error }"
						>
							<bacs-config
								:details="$v.groupTypeDetails"
								:disabled="group.isItemActioned"
								:status="status"
							></bacs-config>
						</b-tab>
						<b-tab
							v-if="group.groupType === groupTypes.bureau || group.groupType === groupTypes.fpsBureau"
							title="Bureau Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.$error }"
						>
							<bureau-config
								:details="$v.groupTypeDetails"
								:disabled="group.isItemActioned"
								:status="status"
							></bureau-config>
						</b-tab>
						<b-tab
							v-if="group.groupType === groupTypes.bacsOnline"
							title="BACS Online Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.groupTypeDetails.$error }"
						>
							<bacs-online-config :details="$v.groupTypeDetails" :disabled="group.isItemActioned"></bacs-online-config>
						</b-tab>
						<b-tab
							v-if="group.groupType === groupTypes.fps"
							title="FPS Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.groupTypeDetails.$error }"
						>
							<fps-config :details="$v.groupTypeDetails" :disabled="group.isItemActioned" :status="status"></fps-config>
						</b-tab>

						<b-tab
							v-if="group.groupType === groupTypes.fpsOnline"
							title="FPS Online Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.groupTypeDetails.$error }"
						>
							<fps-online-config :details="$v.groupTypeDetails" :disabled="group.isItemActioned"></fps-online-config>
						</b-tab>

						<b-tab
							v-if="group.groupType === groupTypes.bankline"
							title="Bankline Config"
							:title-link-class="{ invalid: $v.groupTypeDetails.groupTypeDetails.$error }"
						>
							<bankline-config :details="$v.groupTypeDetails" :disabled="group.isItemActioned"></bankline-config>
						</b-tab>
						<b-tab
							v-if="
                group.groupType === groupTypes.bacs ||
                group.groupType === groupTypes.fps ||
                group.groupType === groupTypes.bacsOnline ||
                group.groupType === groupTypes.fpsOnline ||
                group.groupType === groupTypes.bureau ||
                group.groupType === groupTypes.fpsBureau ||
								group.groupType === groupTypes.bankline
              "
							title="Approval Settings"
							:title-link-class="{
                invalid: $v.approvalSettings.$error,
              }"
						>
							<approval-settings :details="$v.groupTypeDetails" :group="$v.group"></approval-settings>
						</b-tab>
						<b-tab
							v-if="
                group.groupType === groupTypes.bacs ||
                group.groupType === groupTypes.bacsOnline ||
                group.groupType === groupTypes.fps ||
                group.groupType === groupTypes.fpsOnline
              "
							title="Auto File Import Config"
							:title-link-class="{
                invalid: $v.groupTypeDetails.bacsAutoFileImportConfig.$error,
              }"
						>
							<auto-file-import-config :details="$v.groupTypeDetails.bacsAutoFileImportConfig"></auto-file-import-config>
						</b-tab>
						<b-tab
							v-if="
                group.groupType === groupTypes.bacs ||
                group.groupType === groupTypes.bacsOnline ||
                group.groupType === groupTypes.fps ||
                group.groupType === groupTypes.fpsOnline
              "
							title="Anti-Fraud Lists"
						>
							<group-anti-fraud-lists
								v-if="loadAntiFraudLists"
								:group="group"
								:disabled="group.isItemActioned"
								:groupId="groupId"
							></group-anti-fraud-lists>
						</b-tab>
					</b-tabs>
				</div>
				<div class="idb-block-footer">
					<button
						id="persist-group-button"
						class="btn btn-primary"
						:disabled="group.isItemActioned || isLoading || isSaving"
						type="submit"
					>{{ status === "Edit" ? "Save" : status }}</button>
					<button
						id="delete-group-button"
						v-if="status === 'Edit'"
						class="btn btn-danger pull-right"
						@click="deleteGroup"
						:disabled="group.isItemActioned || isLoading"
						type="button"
					>
						<i class="glyphicon ti-trash rpad"></i>Delete Group
					</button>
					<button
						id="cancel-group-button"
						v-if="status === 'Create'"
						:disabled="isLoading"
						class="btn btn-outline-danger pull-right"
						type="button"
						@click="back"
					>Cancel</button>
					<button
						id="clone-group-button"
						:disabled="isLoading"
						v-if="status === 'Edit' && canAddGroups"
						class="btn btn-outline-primary ml-3"
						type="button"
						@click="showModal"
					>
						<i class="glyphicon ti-layers rpad"></i>Clone
					</button>
				</div>
			</div>
		</form>
		<b-modal
			id="cloneModal"
			ref="clone-modal"
			title="Clone Group"
			ok-title="Clone"
			cancel-variant="secondary-outline"
			@ok="cloneGroup"
			@hidden="resetModal"
			no-close-on-backdrop
			:centered="true"
		>
			<div class="form-group row" :class="{ invalid: $v.group.clonedName.$error }">
				<label class="col-form-label col-md-3 required">New Name</label>
				<div class="col-md-6">
					<input type="text" class="form-control" v-model="$v.group.clonedName.$model" />
					<!-- Validation -->
					<validation-messages v-model="$v.group.clonedName" name="new name">
						<small
							class="form-text small"
							v-if="
                $v.group.clonedName.notSameAs != undefined &&
                !$v.group.clonedName.notSameAs
              "
						>Cloned name cannot be the same as the original name</small>
					</validation-messages>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
// Third Party
import axios from 'axios'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import Swatches from 'vue-swatches'
import _ from 'lodash'
import roles from '@/Assets/Constants/roles'

// Validation helpers
import { required, maxLength, minValue, maxValue, helpers, requiredIf, not, sameAs, numeric } from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'

// Constants
import groupTypes from '@/Assets/Constants/groupTypes'
import colours from '@/Assets/Constants/colours'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Group Types
import BacsConfig from './EditGroupTypeDetails/BACSGroupConfig.vue'
import BacsOnlineConfig from './EditGroupTypeDetails/BACSOnlineGroupConfig.vue'
import FpsConfig from './EditGroupTypeDetails/FpsGroupConfig.vue'
import FpsOnlineConfig from './EditGroupTypeDetails/FpsOnlineGroupConfig.vue'
import BureauConfig from './EditGroupTypeDetails/BureauGroupConfig.vue'
import BanklineConfig from './EditGroupTypeDetails/BanklineGroupConfig.vue'

// Other Group Tabs
import GroupUsers from './Users/GroupUsers.vue'
import ApprovalSettings from './Approval/ApprovalSettings'
import AutoFileImportConfig from './AutoFileImport/AutoFileImportConfig.vue'
import GroupAntiFraudLists from './AntiFraud/GroupAntiFraudLists.vue'

const sunValidator = helpers.regex('alpha', /^B\d{5}$|^\d{6}$/)
// For BACS auto file import settings.
function uniqueWatchedFolderValidator (value) {
	if (this.autoFileImportConfigurations) {
		var match = this.autoFileImportConfigurations.find(i => i.watchedFolder === value && i.groupId !== this.groupTypeDetails.bacsAutoFileImportConfig.groupId)
	}
	// If undefined i.e. no match then watched folder not used by any other of the customer's groups.
	return match === undefined
}
export default {
	mixins: [DataLeaveMixin, loading],
	props: ['status', 'groupId'],
	computed: {
		...mapGetters(['availableGroupTypes', 'selectedCustomer']),
		docPath () {
			switch (this.currentTab) {
				case 'Group Details':
					return '/administration/groups/groupdetails/'
				case 'Group Users':
					switch (this.group.groupType) {
						case groupTypes.bacs:
						case groupTypes.fps:
							return '/administration/groups/bacs/bacsgroupusers/'
						case groupTypes.bacsOnline:
						case groupTypes.fpsOnline:
							return '/administration/groups/bacsonline/bacsonlinegroupusers/'
						default:
							return null
					}
				case 'FPS Config':
				case 'BACS Config':
					return '/administration/groups/bacs/bacsgroupconfig/'
				case 'BACS Online Config':
				case 'FPS Online Config':
					return '/administration/groups/bacsonline/bacsonlinegroupconfig/'
				case 'Approval Settings':
					return '/administration/groups/approvalsettings/'
				case 'Auto File Import Config':
					switch (this.group.groupType) {
						case groupTypes.bacs:
						case groupTypes.fps:
							return '/administration/groups/bacs/autofileimportconfig/'
						default:
							return '/administration/groups/bacs/autofileimportconfig/'
					}
				case 'Anti-Fraud Lists':
					return '/administration/groups/antifraudlists/'
				default:
					return null
			}
		},
		canAddGroups () {
			return this.$store.getters.isInRole(roles.AddGroups)
		},
		approvable () {
			if (!this.loaded) {
				return true
			}

			if (this.status === 'Create' && !this.saved) {
				return true
			}


			const amount = this.group.groupUserRights.filter(gur => gur.approval || gur.approveOwnSubmission).length
			const total = this.group.groupUserRights.length

			switch (this.groupTypeDetails.paymentApprovalMethod) {
				case 'Number of Approvers':
					if (amount >= this.groupTypeDetails.numberOfApprovers) {
						if (amount === this.groupTypeDetails.numberOfApprovers && this.groupTypeDetails.numberOfApprovers === total) {
							if (this.group.groupUserRights.some(gur => gur.approveOwnSubmission)) {
								return true
							} else {
								return false
							}
						}

						return true
					}
					return false
				case 'Approval Range':
					if (this.group.approverAmountRanges.some(r => amount >= r.numberOfApprovers)) {
						if (this.group.groupUserRights.some(gur => gur.approveOwnSubmission)) {
							return true
						} else {
							return false
						}
					}
					return false
				default:
					if (amount >= 1) {
						if (amount === 1 && total === 1) {
							if (this.group.groupUserRights.some(gur => gur.approveOwnSubmission)) {
								return true
							} else {
								return false
							}
						}
						return true
					}
					return false
			}
		}
	},
	watch: {
		selectedCustomer () { this.$router.push({ name: 'Groups' }) }
	},
	components: {
		GroupUsers,
		BacsConfig,
		FpsConfig,
		BureauConfig,
		BacsOnlineConfig,
		FpsOnlineConfig,
		ApprovalSettings,
		AutoFileImportConfig,
		GroupAntiFraudLists,
		Swatches,
		BanklineConfig
	},
	async mounted () {
		await this.$store.dispatch('getGroupTypes')

		if (this.status === 'Edit') {
			await this.loadGroup()
		} else {
			this.group.paygateId = this.$store.state.common.paygateId
			await this.loadPaymentApprovalSettings()
			this.$nextTick(() => {
				this.$v.$reset()
			})
		}
		this.loadUsers = true
		this.loadAntiFraudLists = true
		this.loaded = true
	},
	data () {
		return {
			currentTab: null,
			cloning: false,
			groupTypes,
			group: {
				name: '',
				clonedName: '',
				description: '',
				colour: null,
				groupType: groupTypes.bacs,
				groupUserRights: [],
				approverAmountRanges: [{ greaterThan: 0, numberOfApprovers: 1 }],
				antiFraudGroupLists: []
			},
			groupTypeDetails: {
				importSchema: null,
				paymentApprovalMethod: 'Default',
				approvalMethod: 'Via Action Item',
				numberOfApprovers: null,
				hsmPinRequired: null,
				// Bacs And Bureau
				serviceUserNumber: null,
				bankAccountId: null,
				tokenType: null,
				certificateId: null,
				isIndirectSubmitterGroup: null,
				allowFileLevelSigning: null,
				deletePaymentData: true,
				paymentLimit: null,
				submissionLimit: null,
				bankGradeGroup: null,
				mappingId: null,
				// DDMS
				serviceUserName: null,
				submissionWindowType: 2,
				multidayWindowSize: 10,
				advanceNoticeInDays: 10,
				defaultContraNarrative: null,
				isAuddis: true,
				serviceUserAddress1: null,
				serviceUserAddress2: null,
				serviceUserAddress3: null,
				serviceUserAddress4: null,
				serviceUserPostcode: null,
				serviceUserTelephone: null,
				serviceUserFax: null,
				serviceUserEmail: null,
				serviceUserWebsite: null,
				serviceUserContactName1: null,
				serviceUserContactName2: null,
				serviceUserContactName3: null,
				serviceUserCustomField1: null,
				serviceUserCustomField2: null,
				serviceUserCustomField3: null,
				serviceUserCustomField4: null,
				serviceUserCustomField5: null,
				serviceUserCustomField6: null,
				serviceUserCustomField7: null,
				serviceUserCustomField8: null,
				defaultPayerMessageType: null,
				docxExportType: null,
				emailBccList: null,
				letterOutputFolder: null,
				domainEmailAddress: null,
				domainEmailId: null,
				updateSchedPaymentOnError: null,
				suppressionEnabled: null,
				suppressionDayStart: null,
				suppressionMonthStart: null,
				suppressionDayEnd: null,
				suppressionMonthEnd: null,
				groupWorkflowType: null,
				apiActive: null,
				serviceUserDetails: null,
				paymentPlanNames: null,
				defaultVariableAmountMapping: null,
				variableAmountMappingId: null,
				defaultPlanTemplateId: null,
				planPurpose: null,
				suppress0c: null,
				payerReferencePattern: null,
				autoGenerateReferences: null,
				levelsOfOrphanEscalation: null,
				allowOrphanwarnings: null,
				orphanReportingEmails: [],
				payerThreshold: null,
				submissionType: null,
				groupEmail: null,
				refundWindowStart: null,
				refundWindowEnd: null,
				autoCancelFromWithdrawal: false,
				// BACS Online
				email: null,
				approvalPassword: null,
				segregateGroupRoles: null,
				proxyGroup: null,
				bacsAutoFileImportConfig: {
					// Auto file import config
					watchedFolder: null,
					defaultSubmissionReference: null,
					defaultContraNarrative: null,
					stopOnDuplicateFile: null,
					stopOnFix: null,
					stopOnWarning: null,
					removeFixPayments: null,
					removeWarningPayments: null,
					removeDuplicatePayments: null,
					removedFixesAction: null,
					removedWarningsAction: null,
					removedDuplicatesAction: null,
					fileSaveFolder: null
				}
			},
			autoFileImportConfigurations: [],
			loadUsers: false,
			loadAntiFraudLists: false,
			loaded: false,
			saved: false,
			isSaving: false
		}
	},
	methods: {
		setSaved () {
			this.saved = true
			return true
		},
		async saveGroup () {
			try {
				this.isSaving = true
				if (this.status === 'Create') {
					this.group.paygateId = this.$store.state.common.paygateId
					this.group.groupTypeDetails = this.groupTypeDetails
					const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`, this.group, { showload: true, showerror: true, errormessage: 'Group failed to create' })
					this.$v.$reset()
					if (response.data.status === 'Action') {
						this.$toastr.s('New group needs to be approved', 'Needs Approval')
						this.$router.push({ name: 'Groups' })
					} else {
						this.$toastr.s('New group created', 'Created')
						this.$router.push({ name: 'Groups' })
					}
				} else {
					const response = await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`, this.group, { showload: true, showerror: true, errormessage: 'Group failed to save' })
					if (response.data.status === 'Action') {
						this.$toastr.s('Group update needs to be approved', 'Needs Approval')
						await this.loadGroup()
					} else {
						this.$toastr.s('Group updated', 'Updated')
						await this.loadGroup()
					}
				}
				this.$v.$reset()
			} catch { } finally {
				this.isSaving = false
				this.saved = false
			}
		},
		async deleteGroup () {
			if (this.group.groupType === groupTypes.bureau || this.group.groupType === groupTypes.fpsBureau) {
				const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureaujob/checkgroup/${this.group.groupId}`, { showload: true, showerror: true, errormessage: 'Could not check linked Bureau Jobs' })
				if (response.data) {
					this.$snapbar.e('There are Bureau Jobs linked to this group, it cannot be deleted.')
					return
				}
			}
			try {
				var swalResult = await swal.fire({
					title: 'Delete Group',
					text: 'Are you sure you want to delete this group?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: colours.danger,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				})
			} catch (error) {
				// Do nothing as this will be if they clicked cancel
				return
			}

			if (!swalResult.isConfirmed) {
				return
			}

			try {
				const response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.group.groupId}`, { showload: true, showerror: true, errormessage: 'Group failed to delete' })
				if (response.data.status === 'Action') {
					this.$toastr.s('Group deletion needs to be approved', 'Needs Approval')
					await this.loadGroup()
				} else {
					this.$toastr.s('Group deleted', 'Deleted')
					this.$v.$reset()
					await this.$store.dispatch('loadUser')

					if (this.$store.getters.isInRoles([roles.GroupAdministrator, roles.SystemUser])) {
						this.$router.push({ name: 'Groups' })
					} else {
						this.$router.push({ name: 'Dashboard' })
					}
				}
			} catch { }
		},
		showModal () {
			this.group.clonedName = `${this.group.name} Copy`
			this.$refs['clone-modal'].show()
			this.cloning = true
			// this.$v.group.clonedName.$touch()
		},
		resetModal () {
			this.cloning = false
			this.group.clonedName = ''
		},
		async cloneGroup (e) {
			this.$v.group.clonedName.$touch()
			e.preventDefault()
			if (this.$v.group.clonedName.$invalid) {
				this.$toastr.e('There are validation errors on the form', 'Validation')
			} else {
				try {
					this.group.groupTypeDetails = this.groupTypeDetails
					const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/Clone`, this.group, { showload: true, showerror: true, errormessage: 'Group failed to clone' })
					this.$v.$reset()
					if (response.data.status === 'Action') {
						this.$toastr.s('New group needs to be approved', 'Needs Approval')
						this.$router.push({ name: 'Groups' })
					} else {
						this.$toastr.s('Group Cloned', 'Cloned')
						this.$router.push({ name: 'GroupEdit', params: { groupId: response.data.id } })
					}
				} catch { }
			}
		},
		async loadGroup () {
			try {
				const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/GroupEdit/${this.groupId}`, { showload: true, showerror: true, errormessage: 'Group failed to load' })
				this.group = response.data
				this.$set(this.group, 'clonedName', '')
				this.groupTypeDetails = this.group.groupTypeDetails
				if (this.group.groupType === groupTypes.bacs || this.group.groupType === groupTypes.bacsOnline || this.group.groupType === groupTypes.fps || this.group.groupType === groupTypes.fpsOnline) {
					try {
						const response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getCustAutoFileImportConfig`, { params: { paygateid: this.$store.state.common.paygateId }, showload: true, showerror: true, errormessage: 'Group failed to get auto file import config' })
						this.autoFileImportConfigurations = response.data
					} catch {
						// do nothing
					}
				}
			} finally {
				this.$nextTick(() => {
					this.$v.$reset()
				})
			}
		},
		changedTab (index) {
			this.currentTab = this.$refs.tabs.getTabs()[index].title
		},
		triggerTouch () {
			this.$v.$touch()
		},
		async loadPaymentApprovalSettings () {
			var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PaymentApproval`,
				{ showerror: true, errormessage: 'Payment approval failed to load' })
			var paymentApprovalSettings = response.data
			this.groupTypeDetails.approvalMethod = paymentApprovalSettings.approvalMethod
			this.group.approverAmountRanges = paymentApprovalSettings.approverAmountRanges
			this.groupTypeDetails.numberOfApprovers = paymentApprovalSettings.numberOfApprovers
			this.groupTypeDetails.paymentApprovalMethod = paymentApprovalSettings.paymentApprovalMethod
		}
	},
	validations () {
		var group = {
			name: { required, maxLength: maxLength(50) },
			description: { maxLength: maxLength(200) },
			groupType: { required },
			groupUserRights: {
				groupAdmin: (value) => {
					if (this.saved) {
						return value.some(gur => gur.groupAdministrator)
					}
					return true
				},
				$each: {
					userName: {},
					userId: {},
					create: {},
					add: {},
					edit: {},
					delete: {},
					sign: {},
					approval: {
						approveOwnRequiresApprove: (value, model) => {
							if (model.approveOwnSubmission) {
								return value
							}

							return true
						}
					},
					approveOwnSubmission: {},
					submit: {},
					collectReports: {},
					collectCertificateLevelReports: {},
					groupAdministrator: {},
					uploadType: {},
					uploadPath: {
						required: requiredIf((model) => {
							return model.uploadType === 'Secure Cloud Folder' || model.uploadType === 'Restricted File Browser'
						})
					},
					directoryLocked: {},
					required: {
						required: requiredIf((model) => {
							if (this.saved) {
								if (!model.create && !model.add && !model.edit && !model.delete && !model.sign && !model.delete && !model.approval && !model.approveOwnSubmission && !model.submit && !model.collectReports && !model.groupAdministrator && !model.collectCertificateLevelReports) {
									return true
								}
							}
							return false
						})

					}
				}
			},
			clonedName: {
				required: requiredIf(() => {
					return this.cloning
				}),
				maxLength: maxLength(50),
				notSameAs: not(sameAs('name'))
			},
			antiFraudGroupLists: {},
			mainGroupValidation: ['group.name', 'group.description', 'group.groupType']
		}

		switch (this.group.groupType) {
			case groupTypes.bacs:
			case groupTypes.fps:
				return {
					approvalSettings: ['groupTypeDetails.numberOfApprovers', 'group.approverAmountRanges'],
					group: {
						approverAmountRanges: {
							$each: {
								greaterThan: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 0 : true
									}),
									valid: (value) => {
										var count = _.sumBy(this.group.approverAmountRanges,
											v => +(v.greaterThan === value))
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? count === 1 : true
									}
								},
								numberOfApprovers: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 1 : true
									}),
									maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value <= 10 : true
									})
								}
							}
						},
						...group
					},
					groupTypeDetails: {
						groupTypeDetails: [
							'groupTypeDetails.serviceUserNumber',
							'groupTypeDetails.bankAccountId',
							'groupTypeDetails.tokenType',
							'groupTypeDetails.certificateId',
							'groupTypeDetails.paymentLimit',
							'groupTypeDetails.submissionLimit',
							'groupTypeDetails.importSchema',
							'groupTypeDetails.mappingId'
						],
						serviceUserNumber: {
							required,
							sunValidator
						},
						bankAccountId: { required },
						tokenType: { required },
						certificateId: {
							required: requiredIf((model) => {
								return model.tokenType === 'Hsm'
							})
						},

						isIndirectSubmitterGroup: {},
						allowFileLevelSigning: {},
						segregateGroupRoles: {},
						deletePaymentData: {},
						deleteHistoricalPayments: {},
						paymentDeletionPeriod: {},
						paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						importSchema: { required },
						mappingId: {
							required: requiredIf((model) => {
								return model.importSchema === 'Mapping'
							})
						},
						paymentApprovalMethod: {},
						numberOfApprovers: {
							required: requiredIf((model) => {
								return model.paymentApprovalMethod === 'Number of Approvers'
							}),
							minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value >= 1 : true
							}),
							maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value <= 10 : true
							})
						},
						approvalMethod: {},
						hsmPinRequired: {},
						bankGradeGroup: {},
						bacsAutoFileImportConfig: {
							watchedFolder: {
								required: requiredIf(function () {
									var isRequired = (this.groupTypeDetails.bacsAutoFileImportConfig.defaultSubmissionReference !== null && this.groupTypeDetails.bacsAutoFileImportConfig.defaultSubmissionReference.length > 0) ||
										(this.groupTypeDetails.bacsAutoFileImportConfig.defaultContraNarrative !== null && this.groupTypeDetails.bacsAutoFileImportConfig.defaultContraNarrative.length > 0) ||
										(this.groupTypeDetails.bacsAutoFileImportConfig.fileSaveFolder !== null && this.groupTypeDetails.bacsAutoFileImportConfig.fileSaveFolder.length > 0) ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnDuplicateFile === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnFix === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnWarning === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnDuplicateFile === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeFixPayments === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeWarningPayments === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeDuplicatePayments === true
									return isRequired
								}),
								uniqueWatchedFolderValidator
							},
							defaultSubmissionReference: {},
							defaultContraNarrative: {},
							stopOnDuplicateFile: {},
							stopOnFix: {},
							stopOnWarning: {},
							stopOnDuplicate: {},
							removeFixPayments: {},
							removeWarningPayments: {},
							removeDuplicatePayments: {},
							removedFixesAction: {},
							removedWarningsAction: {},
							removedDuplicatesAction: {},
							fileSaveFolder: {
								required: requiredIf(function () {
									return this.groupTypeDetails.bacsAutoFileImportConfig.removedFixesAction === 'CreateNewFile' ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removedWarningsAction === 'CreateNewFile' ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removedDuplicatesAction === 'CreateNewFile'
								})
							}
						}
					}
				}
			case groupTypes.bureau:
			case groupTypes.fpsBureau:
				return {
					approvalSettings: ['groupTypeDetails.numberOfApprovers', 'group.approverAmountRanges'],
					group: {
						approverAmountRanges: {
							$each: {
								greaterThan: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 0 : true
									}),
									valid: (value) => {
										var count = _.sumBy(this.group.approverAmountRanges,
											v => +(v.greaterThan === value))
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? count === 1 : true
									}
								},
								numberOfApprovers: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 1 : true
									}),
									maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value <= 10 : true
									})
								}
							}
						},
						...group
					},
					groupTypeDetails: {
						serviceUserNumber: {
							required,
							sunValidator
						},
						deletePaymentData: {},
						certificateId: {
							required: requiredIf((model) => {
								return model.tokenType === 'Hsm'
							})
						},
						paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						tokenType: { required },
						hsmPinRequired: {},
						paymentApprovalMethod: {},
						numberOfApprovers: {
							required: requiredIf((model) => {
								return model.paymentApprovalMethod === 'Number of Approvers'
							}),
							minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value >= 1 : true
							}),
							maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value <= 10 : true
							})
						},
						approvalMethod: {}
					}
				}
			case groupTypes.bacsOnline:
			case groupTypes.fpsOnline:
				return {
					approvalSettings: ['groupTypeDetails.numberOfApprovers', 'group.approverAmountRanges'],
					group:
					{
						approverAmountRanges: {
							$each: {
								greaterThan: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 0 : true
									}),
									valid: (value) => {
										var count = _.sumBy(this.group.approverAmountRanges,
											v => +(v.greaterThan === value))
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? count === 1 : true
									}
								},
								numberOfApprovers: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 1 : true
									}),
									maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value <= 10 : true
									})
								}
							}
						},
						...group
					},
					groupTypeDetails: {
						groupTypeDetails: [
							'groupTypeDetails.email',
							'groupTypeDetails.serviceUserNumber',
							'groupTypeDetails.bankAccountId',
							'groupTypeDetails.tokenType',
							'groupTypeDetails.paymentLimit',
							'groupTypeDetails.submissionLimit',
							'groupTypeDetails.importSchema',
							'groupTypeDetails.mappingId'
						],
						email: {
							email: (value) => {
								if (!value) {
									return true
								}
								return email(value)
							},
							maxLength: maxLength(256)
						},
						serviceUserNumber: {
							required,
							sunValidator
						},
						bankAccountId: { required },
						tokenType: { required },
						deletePaymentData: {},
						deleteHistoricalPayments: {},
						paymentDeletionPeriod: {},
						paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						paymentApprovalMethod: {},
						numberOfApprovers: {
							required: requiredIf((model) => {
								return model.paymentApprovalMethod === 'Number of Approvers'
							}),
							minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value >= 1 : true
							}),
							maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value <= 10 : true
							})
						},
						approvalMethod: {},
						segregateGroupRoles: {},
						proxyGroup: {},
						importSchema: { required },
						mappingId: {
							required: requiredIf((model) => {
								return model.importSchema === 'Mapping'
							})
						},
						hsmPinRequired: {},
						bacsAutoFileImportConfig: {
							watchedFolder: {
								required: requiredIf(function () {
									var isRequired = (this.groupTypeDetails.bacsAutoFileImportConfig.defaultSubmissionReference !== null && this.groupTypeDetails.bacsAutoFileImportConfig.defaultSubmissionReference.length > 0) ||
										(this.groupTypeDetails.bacsAutoFileImportConfig.defaultContraNarrative !== null && this.groupTypeDetails.bacsAutoFileImportConfig.defaultContraNarrative.length > 0) ||
										(this.groupTypeDetails.bacsAutoFileImportConfig.fileSaveFolder !== null && this.groupTypeDetails.bacsAutoFileImportConfig.fileSaveFolder.length > 0) ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnDuplicateFile === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnFix === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnWarning === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.stopOnDuplicate === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeFixPayments === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeWarningPayments === true ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removeDuplicatePayments === true
									return isRequired
								}),
								uniqueWatchedFolderValidator
							},
							defaultSubmissionReference: {},
							defaultContraNarrative: {},
							stopOnDuplicateFile: {},
							stopOnFix: {},
							stopOnWarning: {},
							stopOnDuplicate: {},
							removeFixPayments: {},
							removeWarningPayments: {},
							removeDuplicatePayments: {},
							removedFixesAction: {},
							removedWarningsAction: {},
							removedDuplicatesAction: {},
							fileSaveFolder: {
								required: requiredIf(function () {
									return this.groupTypeDetails.bacsAutoFileImportConfig.removedFixesAction === 'CreateNewFile' ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removedWarningsAction === 'CreateNewFile' ||
										this.groupTypeDetails.bacsAutoFileImportConfig.removedDuplicatesAction === 'CreateNewFile'
								})
							}
						}
					}
				}
			case groupTypes.bankline:
				return {
					approvalSettings: ['groupTypeDetails.numberOfApprovers', 'group.approverAmountRanges'],
					group: {
						approverAmountRanges: {
							$each: {
								greaterThan: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 0 : true
									}),
									valid: (value) => {
										var count = _.sumBy(this.group.approverAmountRanges,
											v => +(v.greaterThan === value))
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? count === 1 : true
									}
								},
								numberOfApprovers: {
									required: requiredIf(() => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range'
									}),
									minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value >= 1 : true
									}),
									maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
										return this.groupTypeDetails.paymentApprovalMethod === 'Approval Range' ? value <= 10 : true
									})
								}
							}
						},
						...group
					},
					groupTypeDetails: {
						groupTypeDetails: [
							'groupTypeDetails.bankAccountId',
							'groupTypeDetails.certificateId',
							'groupTypeDetails.paymentLimit',
							'groupTypeDetails.submissionLimit',
							'groupTypeDetails.importSchema',
							'groupTypeDetails.mappingId'
						],
						bankAccountId: { required },
						certificateId: {
							required
						},

						segregateGroupRoles: {},
						deletePaymentData: {},
						deleteHistoricalPayments: {},
						paymentDeletionPeriod: {},
						paymentLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						submissionLimit: { wholepounds: numeric, minValue: minValue(0), maxValue: maxValue(2000000000) },
						importSchema: { required },
						mappingId: {
							required: requiredIf((model) => {
								return model.importSchema === 'Mapping'
							})
						},
						paymentApprovalMethod: {},
						numberOfApprovers: {
							required: requiredIf((model) => {
								return model.paymentApprovalMethod === 'Number of Approvers'
							}),
							minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value >= 1 : true
							}),
							maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
								return this.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers' ? value <= 10 : true
							})
						},
						approvalMethod: {}
					}
				}

			default:
				return {
					group: {},
					groupTypeDetails: {}
				}
		}
	}
}

</script>
