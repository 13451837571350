<template>
  <vue-good-table
    ref="table"
    mode="remote"
    :search-options="{
          enabled: true,
          initialSortBy: { field: 'creationDate', type: 'desc' }
          }"
    :paginationOptions="paginationOptions"
    :sort-options="sortOptions"
    :totalRows="totalRecords"
    :columns="columns"
    :rows="validated"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-search="onSearch"
    :lineNumbers="true"
    :isLoading.sync="isTableLoading"
  >
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <div slot="emptystate">
      <div class="vgt-center-align vgt-text-disabled">No Messages</div>
    </div>

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'ukPayerId' && !hasPayerId">
        <router-link :to="{ name: 'editPayer', params: { ukPayerId: props.row.ukPayerId } }">
          <!-- eslint-disable-line -->
          <span>View Payer</span>
        </router-link>
      </span>
      <span v-else-if="props.column.field == 'payerMessageId'">
        <router-link :to="{ name: 'viewMessage', params: { messageId: props.row.payerMessageId } }">
          <!-- eslint-disable-line -->
          <span>View Message</span>
        </router-link>
      </span>
      <span v-else-if="props.column.field === 'status'">
        <b-badge
          pill
          :variant="getVariant(props.row.status)"
        >{{props.formattedRow[props.column.field]}}</b-badge>
      </span>
      <span v-else>{{props.formattedRow[props.column.field]}}</span>
    </template>

    <div slot="table-actions">
      <button
        @click.prevent="clearTableFilters"
        class="btn btn-link"
        v-b-popover.hover.top.d500="'Clear filters'"
      >
        <span class="fa-stack" style="font-size: 10px;">
          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
        </span>
        <span class="sr-only">Clear filters</span>
      </button>
      <b-button
        @click.prevent="load"
        class
        variant="link"
        v-b-popover.hover.top.d500="'Refresh the data in the table'"
      >
        <i class="fa fa-sync pointer dimmedIcon"></i>
        <span class="sr-only">Refresh Table</span>
      </b-button>
      <b-button
        @click.prevent="exportTable"
        class
        variant="link"
        v-b-popover.hover.top.d500="'Export the message queue'"
      >
        <i class="fa fa-share-square pointer dimmedIcon"></i>
        <span class="sr-only">Export Table</span>
      </b-button>
    </div>
  </vue-good-table>
</template>
<script>
  import axios from 'axios'
  import _ from 'lodash'
  import EventBus from '@/Lib/eventBus'

  import papa from 'papaparse'
  import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
  import loading from '@/Assets/Mixins/LoadingMixin'
  export default {
    mixins: [tableFilterMixin, loading],
    props: {
        sessionId: {
        type: String,
        required: false
      },
    },
    components: {

    },
    data () {
      return {
        validated: [],
        columns: [
          {
            field: 'emailAddress',
            label: 'Email Address',
          },
          {
            field: 'verificationResult',
            label: 'Result',
          },
          {
            field: 'firstName',
            label: 'Given Name',
          },
          {
            field: 'lastName',
            label: 'Family Name',
          },
          {
            field: 'gender',
            label: 'Gender',
          },
          {
            field: 'noReply',
            label: 'Is No Reply',
          },
          {
            field: 'isRole',
            label: 'Role',
          },
          {
            field: 'mxServer',
            label: 'MX Server',
          },
          {
            field: 'esp',
            label: 'Provider',
          },
          {
            field: 'isFree',
            label: 'Free',
          },

        ],
        serverParams: {
        sort: [{ field: 'emailAddress', type: 'asc' }]
      },
      }
    },
    async mounted() {},
    computed: {},
    methods: {
      async load () {
        let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/emailresults/${this.sessionId}`,  { params: { ...this.buildGoodTableQuery() } })
        this.totalRecords = response.data.count
        this.validated = response.data.data
        this.isTableLoading = false
      },
      async exportTable () {
        var query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        query.page = 1
        let response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}bulkvalidation/emailresults/${this.sessionId}`,  { params: { query, forExport:true } })
        if (response) {
          let fileName = 'validated-results.csv'
          var csvString = papa.unparse(response.data)
          var blob = new Blob([csvString])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName)
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/csv' })
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      }
    }
  }
</script>
