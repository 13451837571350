<template>
  <div id="signSubmission">
    <div v-show="actionLoaded && isLoaded && !canDeleteAction">
      <button
        type="button"
        class="btn btn-primary"
        v-on:click="startSignSubmission"
        :disabled="this.enableSign === false"
      >{{ signButtonText }}</button>
      <button
        v-show="enableReturnToViewImportedPayments"
        type="button"
        class="btn btn-outline-secondary ml-3"
        v-on:click="returnToViewImportedPayments"
        :disabled="this.enableSign === false"
      >{{ returnToViewImportedPaymentsButtonText }}</button>
      <button
        type="button"
        class="btn btn-danger pull-right"
        v-on:click="cancelSubmission"
        :disabled="this.enableSign === false && this.canCancel === false"
      >Cancel Submission</button>
    </div>
    <div v-show="canDeleteAction">
      <button
        type="button"
        class="btn btn-danger pull-right"
        v-on:click="deleteAction"
      >Delete Orphaned Action</button>
    </div>
    <div id="websignerContent" v-if="showWebSigner">
      <span v-html="computedWebSignerHtml"></span>
    </div>    
    <div id="eSigner" v-if="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <div v-show="showSmsRequest" class="row form-group"></div>
    <div v-show="showSmsRequest">
      <div class="row form-group">
        <div class="col-md-2">
          <strong><label for="securityCode">Security Code</label></strong>
        </div>
        <div class="col-md-2">
          <input
            id="securityCode"
            ref="securityCodeFocus"
            name="securityCode"
            v-model="securityCode"
            class="form-control"
            @keyup.enter="verifySecurityCode"
          />
        </div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-light pull-right"
            v-on:click="verifySecurityCode"
            :disabled="this.verifyingCode === true"
          >Verify Code</button>
        </div>
      </div>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import groupTypes from '@/Assets/Constants/groupTypes'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import supportLinkMixin from '@/Assets/Mixins/SupportLinkMixin'

// Implementation of a promise while loop i.e. chain promises together until a certain condition is met.
// "data" is value used in "condition"
// Loop continues until "condition" is false
// "action" is the method called for each iteration of the promise.
const promiseWhile = (data, condition, action) => {
  var whilst = (data) => {
    return condition(data) ? action(data).then(whilst) : Promise.resolve(data)
  }
  return whilst(data)
}

export default {
  components: {
    plugin: Plugin
  },
  mixins: [
    bacsMixin,
    supportLinkMixin
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      bacsGroup: {},
      fileNumber: 0,
      fromRecordNumber: 0,
      toRecordNumber: 0,
      browser: '',
      eSignerHtml: '',
      showeSigner: false,
      dataReturned: false,
      paymentBlockSize: 1000, // Default size in case call to get value from bacsEngineConfig fails.
      standard18: '',
      intelligibleSummaries: {},
      totalNumberOfPayments: 0,
      base64Signature: '',
      isLoaded: false,
      paygateType: '',
      includeVol1AndXml: true,
      returnToViewImportedPaymentsButtonText: 'Return to Submission Details',
      submissionTypes: {},
      selectedSubType: 0,
      signButtonText: 'Sign Submission',
      showSmsRequest: false,
      securityCode: '',
      signingInProgress: false,
      verifyingCode: false,
      canCancel: true,
      canProceed: true,
      canDeleteAction: false,
      pluginLoaded: false,
      actionSubClass: '',
      pluginResponse: {},
      usbTokenCertificateThumbprint: '',
      deviceType: '', // Either USB token or Smartcard depending on group.
      currentPage: '',
      componentId: 0, // Either 0 for USB access or 2 for smartcard component in version string returned by plugin.
      expectedVersion: '',
      userPin: '',
      eSignerByteLimit: 360000,
      paymentNetworkType: '',
      nextStatus: '',
      plugin: undefined,
      pluginUpdateNotInstalled: false,
      indirectGroup: false, // Set to true for PGO or indirect collections group.
      webSignerHtml: '<div id="websigner" data-mode="button" data-in_data="DUMMY" data-reply_url="DUMMY" data-certfilter_ku=""192:8""></div>',
      showWebSigner: false,
      useWebSigner: false     
    }
  },
  computed: {
    enableSign: function () {
      return this.isLoaded === true && this.canProceed === true && this.pluginUpdateNotInstalled === false
    },
    actionLoaded () { return this.$store.getters.parentDataLoaded },
    enableReturnToViewImportedPayments () { return this.paygateType === 'Bureau' },
    computedWebSignerHtml () { return this.webSignerHtml }
  },

  methods: {
    async startSignSubmission () {
      this.canProceed = false
      this.canDeleteAction = false

      // First check that the action can proceed i.e. someone else hasn't got in there first and cancelled the submission or something!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: this.nextStatus
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        // console.log('tokentype: ' + this.bacsGroup.groupTypeDetails.tokenType)

        if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard' && this.useWebSigner) {
          // console.log('smartcard and websigner')
          await this.signSubmission()
        } else if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard' || this.bacsGroup.groupTypeDetails.tokenType === 'UsbToken') {
          // console.log('smartcard with plugin or USBtoken')
          // Check for web extension and plugin...
          try {
            await this.$store.commit('setDeviceType', this.deviceType)
            await this.$store.dispatch('checkPlugin')

            if (this.pluginUpdateNotInstalled === false) {
              await this.signSubmission()
            }
          } catch (error) {
            this.$toastr.w(error.message, 'Warning')
          }
        } else {
          // console.log('HSM or commit none or SMS')
          await this.signSubmission()
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    async customerUsesWebSigner () {
      // console.log('customerUsesWebSigner')
      let useWebSignerResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/useWebSigner`, { showload: true })
      this.useWebSigner = useWebSignerResponse.data
      // console.log('useWebSigner: ' + this.useWebSigner)
    },

    async signSubmission () {
      this.canProceed = false
      this.canCancel = false
      this.showWebSigner = false

      if (this.bacsGroup.groupTypeDetails.tokenType === 'Hsm') {
        this.signWithHsm()
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
        if (this.paygateType === 'Online' || (this.bacsGroup.groupType === groupTypes.collections && this.bacsGroup.groupTypeDetails.submissionType === 2)) {
          this.includeVol1AndXml = false
          this.indirectGroup = true
        }

        if (this.useWebSigner) {
          await this.signWithWebSigner()
        } else {
          if (this.browser !== 'IE') {
            this.startSignNonActiveXPlugin()
          } else {
            this.signWithActiveXPlugin()
          }
        }
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'SecurityCode') {
        // PGO only
        this.requestSecurityCode()
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'UsbToken') {
        // PGO only
        this.getUsbTokenThumbprint()
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'None') {
        // PGO only
        this.commitSubmission()
      }
    },

    async signWithWebSigner () {
      this.showWebSigner = true
      let url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/signWithWebSigner?submissionId=${this.submissionId}&actionId=${this.actionId}&includeVol1AndXml=${this.includeVol1AndXml}&indirectGroup=${this.indirectGroup}`
      let response = await axios.get(url)
      this.webSignerHtml = response.data

      this.$nextTick(() => {
        var parseEvent = new CustomEvent('webSignerParse');
        document.dispatchEvent(parseEvent);

        const element = document.getElementById('websigner')
        if (element !== null) {
          element.remove()
        }

        const websignerWarning = '<br/><div class="webSignerWarning">If you don\'t see a \'Sign\' button please install WebSigner. Then press F5 to refresh the page.</div><br/><br/>'

        swal.fire({
          title: 'Sign submission',
          html: websignerWarning + this.webSignerHtml,
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
          showCancelButton: true,
          width: '650px'
        }).then((result => {
          console.log('result: ' + result.isConfirmed)
          if (!result.isConfirmed) {
            console.log('signing cancelled')
            this.unlockSubmission()
            this.canCancel = true
            this.canProceed = true
            // Seems to be an issue with WebSigner and the "websigner" div following signing cancellation by the user.
            // Only need the Cancel option in case WebSigner isn't present so gives the user an opt out.
            // Most reliable solution is to reload the page in this instance.
            window.location.reload()
          }
        }))
      })
    },

    async signWithHsm () {
      if (this.bacsGroup.groupTypeDetails.hsmPinRequired) {
        this.userPin = await this.enterHsmPin()
      }
      var json = this.createJson()
      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/signWithHsm',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        this.$toastr.s('Submission signed.')
        this.navigateToNextPage(response)
      } else {
        this.$snapbar.e(response.data.errorMessage)
        await this.unlockSubmission()
        this.canCancel = true
        this.canProceed = true
      }
    },

    async enterHsmPin () {
      var enteredPin = ''

      var result = await swal.fire({
        title: 'Sign Submission',
        html: 'Enter your HSM PIN to sign the submission',
        icon: 'info',
        allowOutsideClick: false,
        input: 'password',
        inputPlaceholder: 'HSM PIN',
        confirmButtonText: 'Sign'
      })

      enteredPin = result.value
      return enteredPin
    },

    signWithActiveXPlugin () {
      this.plugin = document.PayGatePlugin
      if (this.totalNumberOfPayments < this.paymentBlockSize) {
        // Small submission so get all Standard18 in one go.
        return this.getAllStandard18()
          .then(response => {
            this.plugin.InitSmartCardSigning()
            this.plugin.SubmissionLength = this.standard18.length
            this.plugin.SubmissionData = this.standard18
            this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
            if (this.base64Signature.indexOf('Error') === -1) {
              this.pluginSignComplete()
            } else {
              this.$snapbar.e(this.base64Signature)
              this.unlockSubmission()
              this.canCancel = true
              this.canProceed = true
            }
          })
      } else {
        // Get Standard18 in blocks.
        var json = this.createJson()
        return axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getSubmissionByteLength',
          data: json,
          showload: true
        })
          .then(response => {
            this.plugin.InitSmartCardSigning()
            this.plugin.SubmissionLength = response.data
            this.fileNumber = 1
            this.fromRecordNumber = 1
            this.toRecordNumber = this.paymentBlockSize

            return this.getNextFile()
              .then(() => {
                this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '1.3.14.3.2.26')
                if (this.base64Signature.indexOf('Error') === -1) {
                  this.pluginSignComplete()
                } else {
                  this.$toastr.e(this.base64Signature)
                  this.unlockSubmission()
                  this.canCancel = true
                  this.canProceed = true
                }
              })
          })
          .catch(error => console.log(error))
      }
    },

    startSignNonActiveXPlugin () {
      if (this.totalNumberOfPayments < this.paymentBlockSize) {
        return this.getAllStandard18()
          .then(response => {
            if (this.browser === 'Chrome') {
              this.processSignatureChrome()
            } else if (this.browser === 'Firefox') {
              this.processSignatureFirefox()
            }
          })
      } else {
        // Get Standard18 in blocks.
        this.fileNumber = 1
        this.fromRecordNumber = 1
        this.toRecordNumber = this.paymentBlockSize

        return this.getNextFile(1)
          .then(() => {
            if (this.browser === 'Chrome') {
              this.processSignatureChrome()
            } else if (this.browser === 'Firefox') {
              this.processSignatureFirefox()
            }
          })
      }
    },

    processSignatureFirefox () {
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.pluginSignComplete()
              resolve()
            } else {
              this.canCancel = true
              this.canProceed = true
              this.showSupportLinkSnapbar(this.base64Signature)
              this.unlockSubmission()
              reject(new Error(this.base64Signature))
            }
          } else {
            this.canCancel = true
            this.canProceed = true
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.unlockSubmission()
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })
        // eslint-disable-next-line
        var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.standard18 } })
        document.dispatchEvent(pluginEvent)
      })
    },

    processSignatureChrome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.standard18 }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.pluginSignComplete()
            } else {
              this.showSupportLinkSnapbar(this.base64Signature)
              this.unlockSubmission()
              this.canCancel = true
              this.canProceed = true
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.unlockSubmission()
            this.canCancel = true
            this.canProceed = true
          }
        })
        resolve()
      })
    },

    async pluginSignComplete () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        base64Signature: this.base64Signature
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/signWithPlugin',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        if (this.paygateType === 'Online' || (this.bacsGroup.groupType === groupTypes.collections && this.bacsGroup.groupTypeDetails.submissionType === 2)) {
          this.$toastr.s('File signed.')
        } else {
          this.$toastr.s('Submission signed.')
        }
        this.navigateToNextPage(response)
      } else {
        this.$snapbar.e(response.data.errorMessage)
        this.canCancel = true
        this.canProceed = true
      }
    },

    signWitheSigner () {
      console.log('signWitheSigner1')
      var json = this.createJson()
      return axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/signWitheSigner',
        data: json,
        showload: true
      })
        .then(response => {
          console.log('signWitheSigner2')
          this.eSignerHtml = response.data
          this.showeSigner = true
        })
        .catch(error => console.log(error))
    },

    getAllStandard18 () {
      this.showeSigner = false
      this.eSignerHtml = ''
      this.fileNumber = 1
      this.fromRecordNumber = 1
      this.toRecordNumber = this.paymentBlockSize
      var json = this.createJson()

      return axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18',
        data: json,
        showload: true
      })
        .then(response => {
          this.standard18 = response.data
        })
        .catch(error => console.log(error))
    },

    async getStandard18Block () {
      return new Promise(
        (resolve, reject) => {
          var json = this.createJson()
          return axios({
            method: 'POST',
            url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18Block',
            data: json,
            showload: true
          })
            .then(response => {
              this.updateCounters()
              resolve(response.data)
            })
            .catch(error => console.log(error))
        }
      )
    },

    // Used when generating Standard18 data in blocks so API knows the next section of the submission data to use.
    updateCounters () {
      if (this.toRecordNumber > this.intelligibleSummaries[this.fileNumber - 1].totalRecordCount) {
        // Got all payment data for current file block.
        // Now check whether all files have been processed.
        if (this.fileNumber < this.intelligibleSummaries.length) {
          // Multi block file so start getting data for next file.
          this.fileNumber++
          this.fromRecordNumber = 1
          this.toRecordNumber = this.paymentBlockSize
        } else {
          // No more files to process so don't get anymore payment data.
          this.dataReturned = false
        }
      } else {
        // Haven't got all the payment data yet so update params to get next block of payments.
        this.fromRecordNumber += this.paymentBlockSize
        this.toRecordNumber += this.paymentBlockSize
      }
    },

    // Get the next file block in a multi-file submissions when generating Standard18 data in blocks.
    getNextFile (num) {
      this.dataReturned = true
      return new Promise((resolve, reject) => {
        // The "true" refers to initial value of this.dataReturned.
        // "i" refers to this.dataReturned from "getPaymentData" i.e. keep going until no more payment data is returned.
        return promiseWhile(true, i => i === true, this.getMorePluginStandard18)
          .then(() => resolve(num + 1))
      })
    },

    async getMorePluginStandard18 () {
      const data = await this.getStandard18Block()
      if (this.browser === 'IE') {
        this.plugin.SubmissionData = data
      } else {
        this.standard18 += data
      }

      return new Promise((resolve, reject) => {
        resolve(this.dataReturned)
      })
    },

    createJson () {
      var certThumbprint = ''
      if (this.bacsGroup.groupTypeDetails.certificate !== null && this.bacsGroup.groupTypeDetails.certificate !== undefined) {
        certThumbprint = this.bacsGroup.groupTypeDetails.certificate.thumbprint
      }

      return JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        fileNumber: this.fileNumber,
        fromRecordNumber: this.fromRecordNumber,
        toRecordNumber: this.toRecordNumber,
        certificateThumbprint: certThumbprint,
        paygateType: this.paygateType,
        includeVol1AndXml: this.includeVol1AndXml,
        createAction: true,
        userPin: this.userPin,
        indirectGroup: this.indirectGroup
      })
    },

    async cancelSubmission () {
      this.canDeleteAction = false
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: this.nextStatus
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        this.isLoaded = false
        var cancelReponse = await bacsCommon.cancelSubmissionDialog()
        if (cancelReponse.cancel === true) {
          this.bacsGroup = this.$store.getters.bacsGroup

          json = JSON.stringify({
            submissionId: this.submissionId,
            actionId: this.actionId,
            serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
            fileNumber: 0,
            fromRecordNumber: 0,
            toRecordNumber: 0,
            deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData,
            cancellationReason: cancelReponse.cancellationReason
          })

          await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
          await this.$store.dispatch('cancelCollectionsSubmission', this.submissionId)
          await this.$store.dispatch('updateBureauSubmissionPill')
        } else {
          var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
          await axios.get(endTaskUrl)
          this.isLoaded = true
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    async requestSecurityCode () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/requestSecurityCode`, { showload: true })
      if (response.data.success) {
        this.showSmsRequest = true
        this.$nextTick(() => {
          this.$refs.securityCodeFocus.focus()
        })
      } else {
        this.$toastr.e(response.data.errorMessage)
        this.canProceed = true
        this.canCancel = true
      }
    },

    async verifySecurityCode () {
      this.verifyingCode = true

      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        securityCode: this.securityCode,
        actionSubClass: 'Commit'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifySecurityCode',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        this.$toastr.s('Submission committed.')
        this.navigateToNextPage(response)
      } else {
        this.verifyingCode = false
        this.$snapbar.e(response.data.errorMessage)
        if (response.data.tag !== 'Invalid') {
          await this.unlockSubmission()
          this.showSmsRequest = false
          this.signingInProgress = false
          this.securityCode = ''
          this.canProceed = true
          this.canCancel = true
        }
      }
    },

    async commitSubmission () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/commitSubmission',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        this.$toastr.s('Submission committed.')
        this.navigateToNextPage(response)
      } else {
        this.$snapbar.e(response.data.errorMessage)
        this.canCancel = true
        this.canProceed = true
      }
    },

    async commitWithUsbToken () {
      // Have checked plugin when page loaded.
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        thumbprint: this.usbTokenCertificateThumbprint,
        signAction: true
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/verifyUsbTokenCertificate',
        data: json,
        showload: true
      })

      if (response.data.success === true) {
        this.$toastr.s('Submission committed.')
        this.navigateToNextPage(response)
      } else {
        this.$snapbar.e(response.data.errorMessage)
        this.canCancel = true
        this.canProceed = true
      }
    },

    setComponentAndExtensionId () {
      this.browser = bacsCommon.getBrowser()

      if (this.bacsGroup.groupTypeDetails.tokenType === 'UsbToken') {
        this.componentId = 0
        this.deviceType = 'USB Token'

        if (this.browser === 'IE') {
          this.expectedVersion = '2' // Active X plugin for IE.
        } else {
          this.expectedVersion = '1' // Native messaging plugin for Chrome and Firefox
        }
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
        this.componentId = 2
        this.deviceType = 'SmartCard'

        if (this.browser === 'IE') {
          this.expectedVersion = '3' // Active X plugin for IE.
        } else {
          this.expectedVersion = '1' // Native messaging plugin for Chrome and Firefox
        }
      }
    },

    getUsbTokenThumbprint () {
      if (this.browser === 'IE') {
        this.plugin = document.PayGatePlugin
        this.usbTokenCertificateThumbprint = this.plugin.GetCertificateThumbprint('eTPKCS11.dll')
        if (this.usbTokenCertificateThumbprint.indexOf('Error') === -1) {
          this.commitWithUsbToken()
        } else {
          this.$snapbar.e(this.usbTokenCertificateThumbprint)
          this.unlockSubmission()
          this.canCancel = true
          this.canProceed = true
        }
      } else if (this.browser === 'Firefox') {
        return new Promise((resolve, reject) => {
          document.addEventListener('getPluginResponseWebPage', (response) => {
            var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.commitWithUsbToken()
              resolve()
            } else {
              this.$snapbar.e(pluginResponse.ErrorMessage)
              this.unlockSubmission()
              this.canProceed = true
              this.canCancel = true
              reject(new Error(pluginResponse.ErrorMessage))
            }
          }, { once: true })
          // eslint-disable-next-line
          var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' } })
          document.dispatchEvent(pluginEvent)
        })
      } else if (this.browser === 'Chrome') {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line
          chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'GetThumbprint', DllName: 'eTPKCS11.dll' }, (response) => {
            var pluginResponse = common.parsePluginResponse(response.response)
            if (pluginResponse.CertificateThumbprint !== null) {
              this.usbTokenCertificateThumbprint = pluginResponse.CertificateThumbprint
              this.commitWithUsbToken()
            } else if (pluginResponse.ErrorMessage !== null) {
              this.$snapbar.e(pluginResponse.ErrorMessage)
              this.unlockSubmission()
              this.canProceed = true
              this.canCancel = true
            }
          })
          resolve()
        })
      }
    },

    pluginNotInstalled () {
      var pluginUpdateRequired = this.$store.getters.setPluginUpdateRequired
      this.pluginUpdateNotInstalled = pluginUpdateRequired === undefined ? false : pluginUpdateRequired
      this.canCancel = true
      this.canProceed = true
    },

    navigateToNextPage (response) {
      if (response.data.tag) {
        var routeJson = {}
        routeJson.id = response.data.messageId
        routeJson.actionedObjectId = this.submissionId
        routeJson.actionClass = 'Bacs'
        routeJson.actionSubClass = 'Approve'
        this.$router.replace(returnRouteObject(routeJson))
      } else {
        this.$router.push('/')
      }
    },

    async waitForParent () {
      var looping = true
      while (looping) {
        if (this.actionLoaded === false) {
          await new Promise(resolve => setTimeout(resolve, 500))
        } else {
          looping = false
        }
      }
    },

    async checkEnoughApprovers (totalValue) {
      var numberOfApproversNeeded = 1
      if (this.bacsGroup.groupTypeDetails.paymentApprovalMethod === 'Approval Range') {
        this.signingUser = this.bacsGroup.groupUserRights.find(i => i.sign === true)
        var tmp = []
        this.bacsGroup.approverAmountRanges.forEach(element => {
          if (element.greaterThan <= totalValue) {
            tmp.push(element)
          }
        })

        var maxCheck = Math.max(...tmp.map(o => o.greaterThan))
        var selectedRange = tmp.find(i => i.greaterThan === maxCheck)
        numberOfApproversNeeded = selectedRange.numberOfApprovers
      } else if (this.bacsGroup.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers') {
        numberOfApproversNeeded = this.bacsGroup.groupTypeDetails.numberOfApprovers
      }

      var groupApproverCount = this.bacsGroup.groupUserRights.filter((obj) => obj.approval === true).length
      // Ignore users who cannot approve their own submission if they created this one... there should just be the "create" record at this point.
      var historyResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionHistory?submissionId=${this.submissionId}`, { showload: true })

      // Check user approval limit.
      var approvers = this.bacsGroup.groupUserRights.filter((obj) => obj.approval === true)
      for (var i = 0; i < approvers.length; i++) {
        var cannotApproveThisSub = approvers[i].approveOwnSubmission === false && historyResponse.data[0].userId === approvers[i].userId
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Users/Limits/${approvers[i].userId}`, { showload: true })
        if ((response.data.approvalLimit > 0 && response.data.approvalLimit < totalValue) || cannotApproveThisSub === true) {
          groupApproverCount--
        }
      }

      var enoughApprovers = groupApproverCount >= numberOfApproversNeeded
      if (enoughApprovers === false) {
        var actionText = this.actionSubClass === 'Sign' ? 'sign' : 'commit'
        this.$snapbar.w(`Cannot ${actionText} the submission because there are not enough users with sufficient approval limits who can approve this submission.`)
        this.canProceed = false
      }
    },

    async doesUserHaveToken () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/doesUserHaveToken')
      if (!response.data.success) {
        this.$snapbar.w('You are not assigned to a USB token, please contact your system administrator')
        this.canProceed = false
      }
    },

    async doesUserHaveMobileNumber () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/doesUserHaveMobileNumber')
      if (!response.data.success) {
        this.$snapbar.w('You do not have a mobile phone number configured, please contact your system administrator')
        this.canProceed = false
      }
    },

    async getBacsSubmission () {
      var bacsSubmission = this.$store.getters.bacsSubmission
      this.paymentNetworkType = bacsSubmission.paymentNetworkType
      var eSignerLimitExceeded = bacsSubmission.numberOfBytes > this.eSignerByteLimit
      this.$store.commit('seteSignerLimitExceeded', eSignerLimitExceeded)
    },

    async checkFpsSubmission () {
      if (this.paymentNetworkType === 1) {
        var today = new Date()
        var todayDateFormatted = moment(today).format('YYYY-MM-DD')
        var outOfDateSubmission = false
        var intelligibleSummaries = this.$store.getters.intelligibleSummaries
        intelligibleSummaries.forEach(element => {
          var procDate = moment(element.processingDate).format('YYYY-MM-DD')
          if (todayDateFormatted > procDate) {
            outOfDateSubmission = true
          }
        })

        if (outOfDateSubmission) {
          this.canProceed = false
          this.$snapbar.w('This submission cannot be sent to Vocalink because the processing date for this Faster Payment submission has passed.')
        }
      }
    },

    async unlockSubmission () {
      var unlockResponse = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/RemoveUserSubmissionLock?submissionId=' + this.submissionId)
      console.log('unlockResponse: ' + JSON.stringify(unlockResponse.data))
      if (unlockResponse.data.status === 'Failed') {
        this.$snapbar.e(unlockResponse.data.errorText)
      }
    },

    async deleteAction () {
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.actionId
      await axios.delete(url)
      this.$router.push('/actions')
    },

    async getSubmissionTypes () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getSubmissionTypes?submissionId=` + this.submissionId)
      this.submissionTypes = response.data
      this.selectedSubType = this.submissionTypes[0].value
    }

  },

  async mounted () {
    // console.log('mounted websignerHtml from data: ' + this.webSignerHtml)
    // Init websigner div so it exists when needed.
    // this.webSignerHtml = '<div id="websigner" data-mode="button" data-in_data="DUMMY" data-reply_url="DUMMY" data-certfilter_ku=""192:8""></div>'
    // this.showWebSigner = true;

    await this.waitForParent()
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.bacsGroup = this.$store.getters.bacsGroup
    await this.getSubmissionTypes()
    await this.getBacsSubmission()
    await this.checkFpsSubmission()

    this.actionSubClass = 'Sign'
    this.nextStatus = 'Signed'
    this.paygateType = bacsCommon.getPaygateType(this.bacsGroup.groupType)
    if (this.paygateType === 'Online' || (this.bacsGroup.groupType === groupTypes.collections && this.bacsGroup.groupTypeDetails.submissionType === 2)) {
      this.includeVol1AndXml = false
      this.indirectGroup = true
      this.nextStatus = 'Committed'
      if (this.bacsGroup.groupTypeDetails.tokenType === 'SecurityCode') {
        await this.doesUserHaveMobileNumber()
        this.signButtonText = 'Request Security Code'
        this.actionSubClass = 'Commit'
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'UsbToken') {
        await this.doesUserHaveToken()
        this.signButtonText = 'Commit Submission with USB Token'
        this.actionSubClass = 'Commit'
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'None') {
        this.signButtonText = 'Commit Submission'
        this.actionSubClass = 'Commit'
      } else if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
        this.signButtonText = 'Sign File'
      }
    }

    // Check if smartcard group and init WebSigner if applicable:
    // if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
    //   await this.customerUsesWebSigner()
    //   if (this.useWebSigner) {
    //     let url = `${process.env.VUE_APP_BACS_API_URL}bacs/submission/signWithWebSigner?submissionId=${this.submissionId}&actionId=${this.actionId}&includeVol1AndXml=${this.includeVol1AndXml}&indirectGroup=${this.indirectGroup}`
    //     console.log('websigner URL: ' + url)
    //     let response = await axios.get(url)
    //     this.webSignerHtml = response.data
    //     console.log('mounted webSignerHtml from server: ' + this.webSignerHtml)
    //   }
    // }

    this.currentPage = '/bacsActions/actionWrapper?actionId=' + this.actionId + '&actionedObjectId=' + this.submissionId + '&actionSubClass=' + this.actionSubClass

    var totalValue = 0
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
    for (var i = 0; i < this.intelligibleSummaries.length; i++) {
      this.totalNumberOfPayments += this.intelligibleSummaries[i].totalRecordCount
      totalValue += this.intelligibleSummaries[i].totalValue
    }

    var bacsEngineConfig = this.$store.getters.bacsEngineConfig
    this.paymentBlockSize = bacsEngineConfig.paymentsPerBlock

    await this.checkEnoughApprovers(totalValue)

    await this.customerUsesWebSigner()

    window.setTimeout(() => {
      this.isLoaded = true
    }, 500)

    // Can check this when page mounted as the submission group cannot change at this stage in the submission.
    this.setComponentAndExtensionId()

    var extLoaded = this.$route.query.pluginAccessible
    if (extLoaded === 'true') {
      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true
      this.startSignSubmission()
    }
  },

  destroyed () {
    if (this.browser === 'Firefox') {
      document.removeEventListener('getPluginResponseWebPage', this.initPluginEvent)
      document.removeEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
    }
  }
}
</script>
<style>
  .webSignerWarning {
    color: blue;
    text-align: left; 
  }
  
  .dark-mode {
    .webSignerWarning {
      color: whitesmoke;
      text-align: left; 
    }
  }
</style>